import { PayloadAction } from '@reduxjs/toolkit';
import {
  Appointment,
  AppointmentConcern,
  BookingFlowOption,
  MarkHealthRecordReadTypeEnum,
} from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap';
import AppointmentUtils from 'services/AppointmentUtils';
import PerformanceUtils from 'services/PerformanceUtils';
import { AnyUser } from 'careand-redux/slices/auth';

export interface AppointmentsState {
  activeAppointments: Record<string, Appointment | undefined>;
  incompleteAppointments: Appointment[];
  options: BookingFlowOption[];
  concerns: AppointmentConcern[];
  byId: Record<string, Appointment[] | undefined>;
}

const initialState: AppointmentsState = {
  activeAppointments: {},
  incompleteAppointments: [],
  options: [],
  concerns: [],
  byId: {},
};

const slice = createResettableSlice({
  name: 'Appointments',
  initialState,
  reducers: {
    receiveUpdatedAppointment: (
      state,
      action: PayloadAction<{ appointment: Appointment; ownUser: AnyUser }>,
    ) => {
      const { appointment, ownUser } = action.payload;

      updateAppointment(state, appointment);

      let isOwn = appointment.physician_id === ownUser.id;

      if ('intern_of' in ownUser) {
        isOwn = isOwn || appointment.physician_id === ownUser.intern_of;
      }

      const isActive = isOwn && AppointmentUtils.isActive(appointment);

      if (!appointment.id) {
        return;
      }

      if (isActive) {
        state.activeAppointments[appointment.id] = appointment;
      } else {
        delete state.activeAppointments[appointment.id];
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        api.Appointments.getOwnAppointments.fulfilled,
        unwrapApi((state, data, req) => {
          clearAppoinments(state, req.userId);
          updateAppointments(state, data, true);
        }),
      )
      .addCase(
        api.Appointments.splitAppointment.fulfilled,
        unwrapApi((state, data, req) => {
          updateAppointments(state, data, true);
        }),
      )
      .addCase(api.Appointments.bookAppointment.fulfilled, unwrap(updateAppointment))
      .addCase(api.Appointments.updateAppointment.fulfilled, unwrap(updateAppointment))
      .addCase(
        api.Appointments.getOwnIncompleteAppointments.fulfilled,
        unwrap((state, appts) => {
          state.incompleteAppointments = appts;
        }),
      )
      .addCase(
        api.Appointments.getOwnActiveAppointments.fulfilled,
        unwrap((state, appts) => {
          updateAppointments(state, appts, true);
        }),
      )
      .addCase(api.Appointments.rateAppointment.fulfilled, unwrap(updateAppointment))
      .addCase(api.Appointments.getDayAppointments.fulfilled, unwrap(updateAppointments))
      .addCase(api.Appointments.getAppointment.fulfilled, unwrap(updateAppointment))
      .addCase(api.Appointments.updateAppointmentStatus.fulfilled, unwrap(updateAppointment))
      .addCase(api.Appointments.getUserAppointments.fulfilled, unwrap(updateAppointments))
      .addCase(
        api.Appointments.getBookingFlowOptions.pending,
        unwrap((state) => {
          state.options = [];
        }),
      )
      .addCase(
        api.Appointments.getBookingFlowOptions.fulfilled,
        unwrap((state, options) => {
          state.options = options;
        }),
      )
      .addCase(
        api.Appointments.addBookingFlowOption.fulfilled,
        unwrap((state, option) => {
          PerformanceUtils.upsert(option, state.options);
        }),
      )
      .addCase(
        api.HealthRecords.markHealthRecordRead.fulfilled,
        unwrapApi((state, data, req) => {
          const { patientId, type, id } = req;
          if (type !== MarkHealthRecordReadTypeEnum.Appointment) {
            return;
          }

          const appt = state.byId[patientId]?.find((it) => it.id === id);

          if (appt) {
            appt.read = true;
          }
        }),
      )
      .addCase(
        api.HealthRecords.getCPP.fulfilled,
        unwrap((state, cpp) => {
          updateAppointments(state, cpp.appointments);
        }),
      )
      .addCase(api.Resource.bookTimeOff.fulfilled, unwrap(updateAppointment))
      .addCase(
        api.HealthRecords.markAllHealthRecordsRead.fulfilled,
        unwrapApi((state, data, req) => {
          updateAppointments(state, data.appointments);
        }),
      ),
});

function clearAppoinments(state: AppointmentsState, userId: string) {
  delete state.byId[userId];
}

function updateAppointment(
  state: AppointmentsState,
  appointment: Appointment | undefined,
  isOwn: boolean = false,
) {
  if (!appointment) {
    return;
  }
  const physicianId = appointment.physician_id;
  const patientId = appointment.patient_id;
  const internOf = appointment.intern_of;

  if (physicianId) {
    state.byId[physicianId] = PerformanceUtils.upsert(appointment, state.byId[physicianId] ?? []);
  }
  if (internOf) {
    state.byId[internOf] = PerformanceUtils.upsert(appointment, state.byId[internOf] ?? []);
  }
  if (patientId) {
    state.byId[patientId] = PerformanceUtils.upsert(appointment, state.byId[patientId] ?? []);
  }

  state.incompleteAppointments = PerformanceUtils.editOnly(appointment, state.incompleteAppointments);

  Object.keys(state.byId).forEach((pid) => {
    const belongsToUser = pid === physicianId || pid === patientId || pid === internOf;
    if (!belongsToUser) {
      const userAppointments = state.byId[pid];
      PerformanceUtils.removeFromArrayInPlace(appointment, userAppointments ?? []);
    }
  });

  if (!appointment.id) {
    return;
  }

  const isActive = AppointmentUtils.isActive(appointment);
  if (isActive && isOwn) {
    state.activeAppointments[appointment.id] = appointment;
  }

  if (!isActive) {
    delete state.activeAppointments[appointment.id];
  }
}

function updateAppointments(
  state: AppointmentsState,
  appointments: Appointment[] | undefined,
  isOwn = false,
) {
  appointments?.forEach((it) => updateAppointment(state, it, isOwn));
}

export const appointmentActions = slice.actions;
export default slice.reducer;
