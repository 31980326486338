/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicationReminderDuration
 */
export interface MedicationReminderDuration {
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderDuration
     */
    type?: MedicationReminderDurationTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderDuration
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderDuration
     */
    days?: number;
}


/**
 * @export
 */
export const MedicationReminderDurationTypeEnum = {
    Until: 'until',
    For: 'for',
    Indefinitely: 'indefinitely',
    MedicationEnds: 'medication_ends'
} as const;
export type MedicationReminderDurationTypeEnum = typeof MedicationReminderDurationTypeEnum[keyof typeof MedicationReminderDurationTypeEnum];


/**
 * Check if a given object implements the MedicationReminderDuration interface.
 */
export function instanceOfMedicationReminderDuration(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicationReminderDurationFromJSON(json: any): MedicationReminderDuration {
    return MedicationReminderDurationFromJSONTyped(json, false);
}

export function MedicationReminderDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminderDuration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'days': !exists(json, 'days') ? undefined : json['days'],
    };
}

export function MedicationReminderDurationToJSON(value?: MedicationReminderDuration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'date': value.date,
        'days': value.days,
    };
}

