/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GTPAL
 */
export interface GTPAL {
    /**
     * 
     * @type {string}
     * @memberof GTPAL
     */
    g?: string;
    /**
     * 
     * @type {string}
     * @memberof GTPAL
     */
    t?: string;
    /**
     * 
     * @type {string}
     * @memberof GTPAL
     */
    p?: string;
    /**
     * 
     * @type {string}
     * @memberof GTPAL
     */
    a?: string;
    /**
     * 
     * @type {string}
     * @memberof GTPAL
     */
    l?: string;
}

/**
 * Check if a given object implements the GTPAL interface.
 */
export function instanceOfGTPAL(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GTPALFromJSON(json: any): GTPAL {
    return GTPALFromJSONTyped(json, false);
}

export function GTPALFromJSONTyped(json: any, ignoreDiscriminator: boolean): GTPAL {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'g': !exists(json, 'g') ? undefined : json['g'],
        't': !exists(json, 't') ? undefined : json['t'],
        'p': !exists(json, 'p') ? undefined : json['p'],
        'a': !exists(json, 'a') ? undefined : json['a'],
        'l': !exists(json, 'l') ? undefined : json['l'],
    };
}

export function GTPALToJSON(value?: GTPAL | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'g': value.g,
        't': value.t,
        'p': value.p,
        'a': value.a,
        'l': value.l,
    };
}

