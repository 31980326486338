/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface Corporation
 */
export interface Corporation {
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    stripe_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    contact_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof Corporation
     */
    plan_id?: string;
    /**
     * 
     * @type {Address}
     * @memberof Corporation
     */
    address?: Address;
}

/**
 * Check if a given object implements the Corporation interface.
 */
export function instanceOfCorporation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CorporationFromJSON(json: any): Corporation {
    return CorporationFromJSONTyped(json, false);
}

export function CorporationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Corporation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'stripe_id': !exists(json, 'stripe_id') ? undefined : json['stripe_id'],
        'name': json['name'],
        'contact_name': !exists(json, 'contact_name') ? undefined : json['contact_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'plan_id': !exists(json, 'plan_id') ? undefined : json['plan_id'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
    };
}

export function CorporationToJSON(value?: Corporation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stripe_id': value.stripe_id,
        'name': value.name,
        'contact_name': value.contact_name,
        'email': value.email,
        'phone': value.phone,
        'fax': value.fax,
        'vat': value.vat,
        'plan_id': value.plan_id,
        'address': AddressToJSON(value.address),
    };
}

