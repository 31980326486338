import { api, apiRequestConfig, globalApiConfig } from 'careand-redux/api/ApiThunks';
import { store } from 'careand-redux/store';
import { getUnwrappedDispatch, UnwrappedAppDispatch } from 'careand-redux/useAppDispatch';
import {
  Configuration,
  DeviceTokenDeviceTypeEnum,
  DeviceTokenTokenTypeEnum,
  NotificationsApi,
} from 'generated/api';
import { request } from 'http';
import { Device } from './Native/Device';
import ApiVersion from 'ApiVersion.json';
import { useEffect } from 'react';
import { useAppSelector } from 'careand-redux/store';
import config from 'config/config';
const isPatientApp = !!config.PATIENT;

/**
 * Sends Notification/Push/Device token to BE
 * @param tokenOrNone if no token is provided, app will request native app for the current token
 */
export async function updatePushToken(userId?: string, tokenOrNone?: string) {
  const dispatch = getUnwrappedDispatch(store.dispatch);
  const patientId = userId ?? store.getState().authentication.activeUserId;

  if (!isPatientApp || !patientId) {
    return;
  }
  if (!Device.isMobile()) {
    return;
  }

  const token = tokenOrNone ?? (await Device.requestPushToken());
  if (!token) {
    return;
  }

  if (await Device.isPushTokenSent()) {
    return;
  }

  const res = await dispatch(
    api.Notifications.subscribePatientDevice({
      patientId,
      device_token: {
        token_type: DeviceTokenTokenTypeEnum.Push,
        device_token: token,
        device_type: Device.getDeviceType()!,
      },
    }),
    { meta: true },
  );

  if (res.isSuccess) {
    Device.setPushTokenSent(true);
  }
}

export function removePushToken() {
  const dispatch = getUnwrappedDispatch(store.dispatch);
  const storeState = store.getState();
  const patientId = storeState.authentication.activeUserId;
  const authToken = storeState.authentication.token;

  if (!isPatientApp || !patientId || !Device.isMobile()) {
    return;
  }

  const data = {
    patientId,
    device_token: {
      token_type: DeviceTokenTokenTypeEnum.Push,
      device_token: '',
      device_type: Device.getDeviceType()!,
    },
  };
  let { headers, basePath } = apiRequestConfig.Notifications.unsubscribePatientDevice(data);
  headers = { ...headers, Authorization: `Bearer ${authToken}` };

  Device.requestPushToken()
    .then((pushToken) => {
      if (!pushToken) {
        return;
      }
      data.device_token.device_token = pushToken;
    })
    .then(() =>
      new NotificationsApi(
        new Configuration({
          basePath,
          apiKey: `Bearer ${authToken}`,
          headers,
        }),
      ).unsubscribePatientDevice(data),
    );

  Device.setPushTokenSent(false);
}

export async function updateStaffPushToken(activeUserId?: string) {
  if (!Device.isMobile()) {
    return;
  }

  const dispatch = getUnwrappedDispatch(store.dispatch);
  const userId = activeUserId ?? store.getState().authentication.activeUserId;
  const authToken = store.getState().authentication.token;
  if (!userId) {
    return;
  }

  const pushToken = await Device.requestPushToken();
  if (!pushToken) {
    return;
  }

  //TODO set pushTokenSent or not sent

  await dispatch(
    api.Notifications.subscribePhysicianDevice({
      device_token: {
        device_token: pushToken,
        user_id: userId,
        device_type: Device.getDeviceType()!,
        token_type: 'push',
      },
    }),
  );
}

export async function removeStaffPushToken(activeUserId?: string) {
  if (!Device.isMobile()) {
    return;
  }

  const dispatch = getUnwrappedDispatch(store.dispatch);
  const userId = activeUserId ?? store.getState().authentication.activeUserId;
  const authToken = store.getState().authentication.token;
  if (!userId) {
    return;
  }

  const pushToken = await Device.requestPushToken();
  if (!pushToken) {
    return;
  }

  //TODO set pushTokenSent or not sent

  //TODO

  // await dispatch(api.Notifications.unsubscribePatientDevice({
  //   device_token: {
  //     device_token: pushToken,
  //     user_id: userId,
  //     device_type: Device.getDeviceType()!,
  //     token_type: 'push'
  //   }
  // }))
}

/**
 * Sends Notification/Push/Device token to BE
 * @param tokenOrNone if no token is provided, app will request native app for the current token
 */
export async function updateTwoFactorPushTokens(tokenOrNone?: string) {
  const dispatch = getUnwrappedDispatch(store.dispatch);
  if (!Device.isMobile()) {
    return;
  }

  const token = tokenOrNone ?? (await Device.requestPushToken());
  if (!token) {
    console.error('update2FAPush', 'no device token');
    return;
  }

  const storedUsers = await Device.requestStoredUsersList();
  if (!storedUsers) {
    console.error('update2FAPush', 'no device token');
    return;
  }

  const promises = storedUsers.map(async (user) => {
    const credentials = await Device.requestStoredUserCredentials(user.userId);
    if (!credentials) {
      console.error('update2FAPush', 'no credentials', user.userId);
      return;
    }
    const { username, password } = credentials;
    if (!username || !password) {
      console.error('update2FAPush', 'missing username or password', user.userId);
      return;
    }
    const { code } = user;
    if (!code) {
      console.error('update2FAPush', 'missing 2fa code', user.userId);
      return;
    }

    return dispatch(
      api.Authentication.updateTwoFactorPushToken({
        payload: {
          username,
          password,
          code,
          device_token: {
            device_token: token,
            token_type: 'push',
            device_type: Device.getDeviceType()!,
            api_version: ApiVersion.httpApiVersion,
            client_version: store.getState().device.clientVersion,
            client_name: 'careand-team',
            user_id: credentials.userId,
          },
        },
      }),
      { meta: true },
    );
  });

  (await Promise.all(promises)).forEach((result) => {
    if (!result) {
      console.log('update2FAPush pushtoken failed with undefined');
      return;
    }
  });

  // TODO set push token sent success
}

export const useUpdateTwoFactorPushTokens = () => {
  const permissions = useAppSelector((state) => state.device.permissions);

  useEffect(() => {
    async function update() {
      if (!Device.isMobile()) {
        return;
      }
      if (!permissions.push) {
        await Device.requestPermission('push');
      }
      await updateTwoFactorPushTokens();
    }
    update();
  }, [permissions]);
};
