/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TestEmailEvent
 */
export interface TestEmailEvent {
    /**
     * 
     * @type {string}
     * @memberof TestEmailEvent
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof TestEmailEvent
     */
    subject?: string;
    /**
     * 
     * @type {number}
     * @memberof TestEmailEvent
     */
    date?: number;
}

/**
 * Check if a given object implements the TestEmailEvent interface.
 */
export function instanceOfTestEmailEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TestEmailEventFromJSON(json: any): TestEmailEvent {
    return TestEmailEventFromJSONTyped(json, false);
}

export function TestEmailEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestEmailEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'to': !exists(json, 'to') ? undefined : json['to'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'date': !exists(json, 'date') ? undefined : json['date'],
    };
}

export function TestEmailEventToJSON(value?: TestEmailEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to': value.to,
        'subject': value.subject,
        'date': value.date,
    };
}

