/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';

/**
 * 
 * @export
 * @interface DocumentFileInfo
 */
export interface DocumentFileInfo {
    /**
     * 
     * @type {PatientDocument}
     * @memberof DocumentFileInfo
     */
    document: PatientDocument;
    /**
     * 
     * @type {number}
     * @memberof DocumentFileInfo
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileInfo
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentFileInfo
     */
    type?: string;
}

/**
 * Check if a given object implements the DocumentFileInfo interface.
 */
export function instanceOfDocumentFileInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "document" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function DocumentFileInfoFromJSON(json: any): DocumentFileInfo {
    return DocumentFileInfoFromJSONTyped(json, false);
}

export function DocumentFileInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentFileInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': PatientDocumentFromJSON(json['document']),
        'size': json['size'],
        'url': json['url'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function DocumentFileInfoToJSON(value?: DocumentFileInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': PatientDocumentToJSON(value.document),
        'size': value.size,
        'url': value.url,
        'type': value.type,
    };
}

