/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Appointment,
  AppointmentFee,
  AppointmentSlot,
  BookingFlowOption,
  ErrorResponse,
  Questionnaire,
} from '../models';
import {
    AppointmentFromJSON,
    AppointmentToJSON,
    AppointmentFeeFromJSON,
    AppointmentFeeToJSON,
    AppointmentSlotFromJSON,
    AppointmentSlotToJSON,
    BookingFlowOptionFromJSON,
    BookingFlowOptionToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    QuestionnaireFromJSON,
    QuestionnaireToJSON,
} from '../models';

export interface AddBookingFlowOptionRequest {
    option: BookingFlowOption;
}

export interface AddBookingFlowOptionImageRequest {
    id: string;
    file: Blob;
}

export interface BookAppointmentRequest {
    userId: string;
    appointment: Appointment;
    confirmPayment?: boolean;
    skipPayment?: boolean;
    confirmSlot?: boolean;
}

export interface GetAppointmentRequest {
    appointmentId: string;
}

export interface GetAppointmentSlotsRequest {
    appointment: Appointment;
}

export interface GetBookingFeeRequest {
    userId: string;
    appointment: Appointment;
}

export interface GetBookingFlowOptionImageRequest {
    id: string;
}

export interface GetBookingFlowOptionsRequest {
    all?: boolean;
    patientId?: string;
}

export interface GetDayAppointmentsRequest {
    date: number;
    clinicId?: string;
}

export interface GetICSRequest {
    id: string;
}

export interface GetOwnAppointmentsRequest {
    userId: string;
    limit?: number;
}

export interface GetUserAppointmentsRequest {
    role: GetUserAppointmentsRoleEnum;
    userId: string;
}

export interface RateAppointmentRequest {
    id: string;
    rating: number;
}

export interface SplitAppointmentRequest {
    id: string;
    confirmSlot?: boolean;
    skipPayment?: boolean;
    insertBefore?: boolean;
}

export interface SubmitPortalQuestionnaireRequest {
    id: string;
    code: string;
    questionnaire: Questionnaire;
}

export interface UpdateAppointmentRequest {
    appointment: Appointment;
    confirmSlot?: boolean;
}

export interface UpdateAppointmentStatusRequest {
    id: string;
    status: UpdateAppointmentStatusStatusEnum;
}

/**
 * 
 */
export class AppointmentsApi extends runtime.BaseAPI {

    /**
     * Adds a booking flow option (Roles: admin)
     */
    async addBookingFlowOptionRaw(requestParameters: AddBookingFlowOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BookingFlowOption>> {
        if (requestParameters.option === null || requestParameters.option === undefined) {
            throw new runtime.RequiredError('option','Required parameter requestParameters.option was null or undefined when calling addBookingFlowOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/booking/options`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookingFlowOptionToJSON(requestParameters.option),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookingFlowOptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addBookingFlowOptionRequestOptionsRaw(requestParameters: AddBookingFlowOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.option === null || requestParameters.option === undefined) {
            throw new runtime.RequiredError('option','Required parameter requestParameters.option was null or undefined when calling addBookingFlowOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/booking/options`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: BookingFlowOptionToJSON(requestParameters.option),
        };
        return responseConfig
    }

    /**
     * Adds a booking flow option (Roles: admin)
     */
    async addBookingFlowOption(requestParameters: AddBookingFlowOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BookingFlowOption> {
        const response = await this.addBookingFlowOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the booking flow option image (Roles: admin)
     */
    async addBookingFlowOptionImageRaw(requestParameters: AddBookingFlowOptionImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBookingFlowOptionImage.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addBookingFlowOptionImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/appointments/booking/options/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    addBookingFlowOptionImageRequestOptionsRaw(requestParameters: AddBookingFlowOptionImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBookingFlowOptionImage.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addBookingFlowOptionImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        const responseConfig = {
            path: `/appointments/booking/options/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Gets the booking flow option image (Roles: admin)
     */
    async addBookingFlowOptionImage(requestParameters: AddBookingFlowOptionImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addBookingFlowOptionImageRaw(requestParameters, initOverrides);
    }

    /**
     * Books an appointment (Roles: admin, patient, physician)
     */
    async bookAppointmentRaw(requestParameters: BookAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling bookAppointment.');
        }

        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling bookAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.confirmPayment !== undefined) {
            queryParameters['confirmPayment'] = requestParameters.confirmPayment;
        }

        if (requestParameters.skipPayment !== undefined) {
            queryParameters['skipPayment'] = requestParameters.skipPayment;
        }

        if (requestParameters.confirmSlot !== undefined) {
            queryParameters['confirmSlot'] = requestParameters.confirmSlot;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    bookAppointmentRequestOptionsRaw(requestParameters: BookAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling bookAppointment.');
        }

        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling bookAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.confirmPayment !== undefined) {
            queryParameters['confirmPayment'] = requestParameters.confirmPayment;
        }

        if (requestParameters.skipPayment !== undefined) {
            queryParameters['skipPayment'] = requestParameters.skipPayment;
        }

        if (requestParameters.confirmSlot !== undefined) {
            queryParameters['confirmSlot'] = requestParameters.confirmSlot;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        };
        return responseConfig
    }

    /**
     * Books an appointment (Roles: admin, patient, physician)
     */
    async bookAppointment(requestParameters: BookAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.bookAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets an appointment (Roles: physician, patient, admin)
     */
    async getAppointmentRaw(requestParameters: GetAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling getAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/{appointmentId}`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getAppointmentRequestOptionsRaw(requestParameters: GetAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling getAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/{appointmentId}`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets an appointment (Roles: physician, patient, admin)
     */
    async getAppointment(requestParameters: GetAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.getAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the available appointment slots for a specific day (Roles: admin, physician, patient)
     */
    async getAppointmentSlotsRaw(requestParameters: GetAppointmentSlotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentSlot>>> {
        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling getAppointmentSlots.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/availability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentSlotFromJSON));
    }

    /**
        Request Config Getter
     */
    getAppointmentSlotsRequestOptionsRaw(requestParameters: GetAppointmentSlotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling getAppointmentSlots.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/availability`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        };
        return responseConfig
    }

    /**
     * Gets the available appointment slots for a specific day (Roles: admin, physician, patient)
     */
    async getAppointmentSlots(requestParameters: GetAppointmentSlotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentSlot>> {
        const response = await this.getAppointmentSlotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get booking fee (Roles: admin, patient, physician)
     */
    async getBookingFeeRaw(requestParameters: GetBookingFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentFee>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getBookingFee.');
        }

        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling getBookingFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/user/{userId}/fee`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFeeFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getBookingFeeRequestOptionsRaw(requestParameters: GetBookingFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getBookingFee.');
        }

        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling getBookingFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/user/{userId}/fee`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        };
        return responseConfig
    }

    /**
     * Get booking fee (Roles: admin, patient, physician)
     */
    async getBookingFee(requestParameters: GetBookingFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentFee> {
        const response = await this.getBookingFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the booking flow option image (Roles: patient, admin, physician)
     */
    async getBookingFlowOptionImageRaw(requestParameters: GetBookingFlowOptionImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookingFlowOptionImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/booking/options/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getBookingFlowOptionImageRequestOptionsRaw(requestParameters: GetBookingFlowOptionImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookingFlowOptionImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/booking/options/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the booking flow option image (Roles: patient, admin, physician)
     */
    async getBookingFlowOptionImage(requestParameters: GetBookingFlowOptionImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getBookingFlowOptionImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the booking flow options (Roles: patient, admin, physician)
     */
    async getBookingFlowOptionsRaw(requestParameters: GetBookingFlowOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BookingFlowOption>>> {
        const queryParameters: any = {};

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.patientId !== undefined) {
            queryParameters['patientId'] = requestParameters.patientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/booking/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookingFlowOptionFromJSON));
    }

    /**
        Request Config Getter
     */
    getBookingFlowOptionsRequestOptionsRaw(requestParameters: GetBookingFlowOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        if (requestParameters.patientId !== undefined) {
            queryParameters['patientId'] = requestParameters.patientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/booking/options`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the booking flow options (Roles: patient, admin, physician)
     */
    async getBookingFlowOptions(requestParameters: GetBookingFlowOptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BookingFlowOption>> {
        const response = await this.getBookingFlowOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all appointments for a given day (Roles: physician, admin)
     */
    async getDayAppointmentsRaw(requestParameters: GetDayAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Appointment>>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDayAppointments.');
        }

        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/day/{date}`.replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentFromJSON));
    }

    /**
        Request Config Getter
     */
    getDayAppointmentsRequestOptionsRaw(requestParameters: GetDayAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDayAppointments.');
        }

        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/day/{date}`.replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all appointments for a given day (Roles: physician, admin)
     */
    async getDayAppointments(requestParameters: GetDayAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Appointment>> {
        const response = await this.getDayAppointmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets and appointment\'s ics event (Roles: patient)
     */
    async getICSRaw(requestParameters: GetICSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getICS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/{id}/ics`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getICSRequestOptionsRaw(requestParameters: GetICSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getICS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/{id}/ics`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets and appointment\'s ics event (Roles: patient)
     */
    async getICS(requestParameters: GetICSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getICSRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a physician\'s own active appointments (Roles: physician)
     */
    async getOwnActiveAppointmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Appointment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentFromJSON));
    }

    /**
        Request Config Getter
     */
    getOwnActiveAppointmentsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/active`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a physician\'s own active appointments (Roles: physician)
     */
    async getOwnActiveAppointments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Appointment>> {
        const response = await this.getOwnActiveAppointmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a user\'s own appointments (Roles: patient, physician)
     */
    async getOwnAppointmentsRaw(requestParameters: GetOwnAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Appointment>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getOwnAppointments.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentFromJSON));
    }

    /**
        Request Config Getter
     */
    getOwnAppointmentsRequestOptionsRaw(requestParameters: GetOwnAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getOwnAppointments.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a user\'s own appointments (Roles: patient, physician)
     */
    async getOwnAppointments(requestParameters: GetOwnAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Appointment>> {
        const response = await this.getOwnAppointmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a physician\'s own incomplete appointments (Roles: physician)
     */
    async getOwnIncompleteAppointmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Appointment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/incomplete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentFromJSON));
    }

    /**
        Request Config Getter
     */
    getOwnIncompleteAppointmentsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/incomplete`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a physician\'s own incomplete appointments (Roles: physician)
     */
    async getOwnIncompleteAppointments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Appointment>> {
        const response = await this.getOwnIncompleteAppointmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a user\'s appointment (Roles: admin, physician)
     */
    async getUserAppointmentsRaw(requestParameters: GetUserAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Appointment>>> {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getUserAppointments.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserAppointments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/role/{role}/user/{userId}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentFromJSON));
    }

    /**
        Request Config Getter
     */
    getUserAppointmentsRequestOptionsRaw(requestParameters: GetUserAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getUserAppointments.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserAppointments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/role/{role}/user/{userId}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a user\'s appointment (Roles: admin, physician)
     */
    async getUserAppointments(requestParameters: GetUserAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Appointment>> {
        const response = await this.getUserAppointmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rates the appointment (Roles: patient)
     */
    async rateAppointmentRaw(requestParameters: RateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rateAppointment.');
        }

        if (requestParameters.rating === null || requestParameters.rating === undefined) {
            throw new runtime.RequiredError('rating','Required parameter requestParameters.rating was null or undefined when calling rateAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/{id}/rate/{rating}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"rating"}}`, encodeURIComponent(String(requestParameters.rating))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    rateAppointmentRequestOptionsRaw(requestParameters: RateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rateAppointment.');
        }

        if (requestParameters.rating === null || requestParameters.rating === undefined) {
            throw new runtime.RequiredError('rating','Required parameter requestParameters.rating was null or undefined when calling rateAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/{id}/rate/{rating}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"rating"}}`, encodeURIComponent(String(requestParameters.rating))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Rates the appointment (Roles: patient)
     */
    async rateAppointment(requestParameters: RateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.rateAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Splits an appointment with an RPN (Roles: admin, physician)
     */
    async splitAppointmentRaw(requestParameters: SplitAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Appointment>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling splitAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.confirmSlot !== undefined) {
            queryParameters['confirmSlot'] = requestParameters.confirmSlot;
        }

        if (requestParameters.skipPayment !== undefined) {
            queryParameters['skipPayment'] = requestParameters.skipPayment;
        }

        if (requestParameters.insertBefore !== undefined) {
            queryParameters['insertBefore'] = requestParameters.insertBefore;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/{id}/split`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentFromJSON));
    }

    /**
        Request Config Getter
     */
    splitAppointmentRequestOptionsRaw(requestParameters: SplitAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling splitAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.confirmSlot !== undefined) {
            queryParameters['confirmSlot'] = requestParameters.confirmSlot;
        }

        if (requestParameters.skipPayment !== undefined) {
            queryParameters['skipPayment'] = requestParameters.skipPayment;
        }

        if (requestParameters.insertBefore !== undefined) {
            queryParameters['insertBefore'] = requestParameters.insertBefore;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/{id}/split`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Splits an appointment with an RPN (Roles: admin, physician)
     */
    async splitAppointment(requestParameters: SplitAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Appointment>> {
        const response = await this.splitAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submits a patient questionnaire from the portal (Roles: none, patient)
     */
    async submitPortalQuestionnaireRaw(requestParameters: SubmitPortalQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitPortalQuestionnaire.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling submitPortalQuestionnaire.');
        }

        if (requestParameters.questionnaire === null || requestParameters.questionnaire === undefined) {
            throw new runtime.RequiredError('questionnaire','Required parameter requestParameters.questionnaire was null or undefined when calling submitPortalQuestionnaire.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/{id}/submit-questionnaire/{code}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireToJSON(requestParameters.questionnaire),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    submitPortalQuestionnaireRequestOptionsRaw(requestParameters: SubmitPortalQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitPortalQuestionnaire.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling submitPortalQuestionnaire.');
        }

        if (requestParameters.questionnaire === null || requestParameters.questionnaire === undefined) {
            throw new runtime.RequiredError('questionnaire','Required parameter requestParameters.questionnaire was null or undefined when calling submitPortalQuestionnaire.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/{id}/submit-questionnaire/{code}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireToJSON(requestParameters.questionnaire),
        };
        return responseConfig
    }

    /**
     * Submits a patient questionnaire from the portal (Roles: none, patient)
     */
    async submitPortalQuestionnaire(requestParameters: SubmitPortalQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitPortalQuestionnaireRaw(requestParameters, initOverrides);
    }

    /**
     * Updates an appointment (Roles: admin, physician)
     */
    async updateAppointmentRaw(requestParameters: UpdateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling updateAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.confirmSlot !== undefined) {
            queryParameters['confirmSlot'] = requestParameters.confirmSlot;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateAppointmentRequestOptionsRaw(requestParameters: UpdateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling updateAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.confirmSlot !== undefined) {
            queryParameters['confirmSlot'] = requestParameters.confirmSlot;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments`,
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentToJSON(requestParameters.appointment),
        };
        return responseConfig
    }

    /**
     * Updates an appointment (Roles: admin, physician)
     */
    async updateAppointment(requestParameters: UpdateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.updateAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the appointment\'s status (Roles: admin, physician, patient)
     */
    async updateAppointmentStatusRaw(requestParameters: UpdateAppointmentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAppointmentStatus.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling updateAppointmentStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/appointments/{id}/status/{status}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateAppointmentStatusRequestOptionsRaw(requestParameters: UpdateAppointmentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAppointmentStatus.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling updateAppointmentStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/appointments/{id}/status/{status}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Updates the appointment\'s status (Roles: admin, physician, patient)
     */
    async updateAppointmentStatus(requestParameters: UpdateAppointmentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.updateAppointmentStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetUserAppointmentsRoleEnum = {
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type GetUserAppointmentsRoleEnum = typeof GetUserAppointmentsRoleEnum[keyof typeof GetUserAppointmentsRoleEnum];
/**
 * @export
 */
export const UpdateAppointmentStatusStatusEnum = {
    Cancelled: 'cancelled',
    Completed: 'completed',
    CheckedIn: 'checked_in',
    Started: 'started',
    Scheduling: 'scheduling',
    NoShow: 'no_show'
} as const;
export type UpdateAppointmentStatusStatusEnum = typeof UpdateAppointmentStatusStatusEnum[keyof typeof UpdateAppointmentStatusStatusEnum];
