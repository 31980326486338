/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Appointment,
  BooleanResource,
  ErrorResponse,
  JitsiMeetingPayload,
} from '../models';
import {
    AppointmentFromJSON,
    AppointmentToJSON,
    BooleanResourceFromJSON,
    BooleanResourceToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    JitsiMeetingPayloadFromJSON,
    JitsiMeetingPayloadToJSON,
} from '../models';

export interface ConnectedToTelemedicineRequest {
    appointmentId: string;
    participants?: number;
}

export interface IsActiveVideoAppointmentRequest {
    appointmentId: string;
}

export interface JoinVideoAppointment2Request {
    appointmentId: string;
}

/**
 * 
 */
export class VideoApi extends runtime.BaseAPI {

    /**
     * Connected to Jitsi (Roles: patient, physician, admin)
     */
    async connectedToTelemedicineRaw(requestParameters: ConnectedToTelemedicineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling connectedToTelemedicine.');
        }

        const queryParameters: any = {};

        if (requestParameters.participants !== undefined) {
            queryParameters['participants'] = requestParameters.participants;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/telemedicine/{appointmentId}/connected`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    connectedToTelemedicineRequestOptionsRaw(requestParameters: ConnectedToTelemedicineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling connectedToTelemedicine.');
        }

        const queryParameters: any = {};

        if (requestParameters.participants !== undefined) {
            queryParameters['participants'] = requestParameters.participants;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/telemedicine/{appointmentId}/connected`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Connected to Jitsi (Roles: patient, physician, admin)
     */
    async connectedToTelemedicine(requestParameters: ConnectedToTelemedicineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.connectedToTelemedicineRaw(requestParameters, initOverrides);
    }

    /**
     * Gets the currently active telemedicine appointment (Roles: patient)
     */
    async getActiveVideoAppointmentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment | undefined>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/telemedicine/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getActiveVideoAppointmentRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/telemedicine/active`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the currently active telemedicine appointment (Roles: patient)
     */
    async getActiveVideoAppointment(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment | undefined> {
        const response = await this.getActiveVideoAppointmentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Checks if the telemedicine appointment is active (Roles: patient)
     */
    async isActiveVideoAppointmentRaw(requestParameters: IsActiveVideoAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResource>> {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling isActiveVideoAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/telemedicine/{appointmentId}/active`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    isActiveVideoAppointmentRequestOptionsRaw(requestParameters: IsActiveVideoAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling isActiveVideoAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/telemedicine/{appointmentId}/active`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Checks if the telemedicine appointment is active (Roles: patient)
     */
    async isActiveVideoAppointment(requestParameters: IsActiveVideoAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResource> {
        const response = await this.isActiveVideoAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Joins a video appointment (Roles: none, patient, physician)
     */
    async joinVideoAppointment2Raw(requestParameters: JoinVideoAppointment2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JitsiMeetingPayload>> {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling joinVideoAppointment2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/telemedicine/{appointmentId}/join2`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JitsiMeetingPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    joinVideoAppointment2RequestOptionsRaw(requestParameters: JoinVideoAppointment2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling joinVideoAppointment2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/telemedicine/{appointmentId}/join2`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Joins a video appointment (Roles: none, patient, physician)
     */
    async joinVideoAppointment2(requestParameters: JoinVideoAppointment2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JitsiMeetingPayload> {
        const response = await this.joinVideoAppointment2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
