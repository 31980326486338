/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Authentication } from './Authentication';
import {
    AuthenticationFromJSON,
    AuthenticationFromJSONTyped,
    AuthenticationToJSON,
} from './Authentication';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
} from './Patient';

/**
 * 
 * @export
 * @interface PatientWithAuth
 */
export interface PatientWithAuth {
    /**
     * 
     * @type {Patient}
     * @memberof PatientWithAuth
     */
    patient: Patient;
    /**
     * 
     * @type {Authentication}
     * @memberof PatientWithAuth
     */
    auth: Authentication;
}

/**
 * Check if a given object implements the PatientWithAuth interface.
 */
export function instanceOfPatientWithAuth(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient" in value;
    isInstance = isInstance && "auth" in value;

    return isInstance;
}

export function PatientWithAuthFromJSON(json: any): PatientWithAuth {
    return PatientWithAuthFromJSONTyped(json, false);
}

export function PatientWithAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientWithAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient': PatientFromJSON(json['patient']),
        'auth': AuthenticationFromJSON(json['auth']),
    };
}

export function PatientWithAuthToJSON(value?: PatientWithAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient': PatientToJSON(value.patient),
        'auth': AuthenticationToJSON(value.auth),
    };
}

