import { Corporation, CorporationBatch, CorporationCoupon, CorporationPatient } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrapApi } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface CorporationsState {
  corporations: Corporation[]
  patients: Record<string, CorporationPatient[] | undefined>
  batches: Record<string, CorporationBatch[] | undefined>
  codes: Record<string, CorporationCoupon[] | undefined>
  unpaidBatches: Record<string, string[] | undefined>
  unpaidCount: Record<string, number | undefined>
}

const initialState: CorporationsState = {
  corporations: [],
  patients: {},
  batches: {},
  codes: {},
  unpaidBatches: {},
  unpaidCount: {}
}

const slice = createResettableSlice({
  name: 'Corporations',
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(api.Corporations.getCorporations.fulfilled, unwrapApi((state, data, req) => {
      state.corporations = data
    }))
    .addCase(api.Corporations.getCorporationPatients.fulfilled, unwrapApi((state, data, req) => {
      state.patients[req.id] = data
    }))
    .addCase(api.Corporations.addCorporation.fulfilled, unwrapApi(upsertCorporation))
    .addCase(api.Corporations.getCorporationBatches.fulfilled, unwrapApi((state, data, req) => {
      state.batches[req.id] = data
    }))
    .addCase(api.Corporations.addCorporationBatch.fulfilled, unwrapApi(upsertBatch))
    .addCase(api.Corporations.updateCorporationBatch.fulfilled, unwrapApi(upsertBatch))
    .addCase(api.Corporations.updateCorporation.fulfilled, unwrapApi(upsertCorporation))
    .addCase(api.Corporations.getCorporationCodes.fulfilled, unwrapApi((state, data, req) => {
      state.codes[req.id] = data
    }))
    .addCase(api.Corporations.removeCorporationCode.fulfilled, unwrapApi((state, data, req) => {
      if (!state.codes[req.id]) { return }
      state.codes[req.id] = PerformanceUtils.removeFromArray({ id: req.id }, state.codes[req.id])
    }))
    .addCase(api.Corporations.addPatientToCorporationCode.fulfilled, unwrapApi((state, data, req) => {
      if (!state.codes[req.id]) {
        state.codes[req.id] = []
       }
       PerformanceUtils.upsert(data, state.codes[req.id]!)
    }))
    .addCase(api.Corporations.getUnpaidBatches.fulfilled, unwrapApi((state, data, req) => {
      data.forEach(entry => {
        state.unpaidBatches[entry.id!] = entry.unpaid_batches
        state.unpaidCount[entry.id!] = entry.unpaid_batches?.length ?? 0
      })
    }))
})

function upsertCorporation(state: CorporationsState, corp: Corporation) {
  PerformanceUtils.upsert(corp, state.corporations)
}

function upsertBatch(state: CorporationsState, batch: CorporationBatch, req: { id: string }) {
  if (!state.batches[req.id]) {
    state.batches[req.id] = []
  }
  PerformanceUtils.upsert(batch, state.batches[req.id]!)
}

export const corporationActions = slice.actions
export default slice.reducer
