/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';

/**
 * 
 * @export
 * @interface MultipleDocumentPayload
 */
export interface MultipleDocumentPayload {
    /**
     * 
     * @type {string}
     * @memberof MultipleDocumentPayload
     */
    cover_page?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleDocumentPayload
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleDocumentPayload
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleDocumentPayload
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipleDocumentPayload
     */
    body?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultipleDocumentPayload
     */
    document_ids?: Array<string>;
    /**
     * 
     * @type {PatientDocument}
     * @memberof MultipleDocumentPayload
     */
    document?: PatientDocument;
}

/**
 * Check if a given object implements the MultipleDocumentPayload interface.
 */
export function instanceOfMultipleDocumentPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MultipleDocumentPayloadFromJSON(json: any): MultipleDocumentPayload {
    return MultipleDocumentPayloadFromJSONTyped(json, false);
}

export function MultipleDocumentPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleDocumentPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cover_page': !exists(json, 'cover_page') ? undefined : json['cover_page'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'document_ids': !exists(json, 'document_ids') ? undefined : json['document_ids'],
        'document': !exists(json, 'document') ? undefined : PatientDocumentFromJSON(json['document']),
    };
}

export function MultipleDocumentPayloadToJSON(value?: MultipleDocumentPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cover_page': value.cover_page,
        'fax': value.fax,
        'email': value.email,
        'subject': value.subject,
        'body': value.body,
        'document_ids': value.document_ids,
        'document': PatientDocumentToJSON(value.document),
    };
}

