import { PayloadAction } from '@reduxjs/toolkit';
import { ChatArchive, ChatShortcut, XMPPChat } from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap';
import PerformanceUtils from 'services/PerformanceUtils';

export interface ChatState {
  activeLinkedAccount: Record<string, string | undefined>;
  archives: Record<string, ChatArchive[] | undefined>;
  xmppRooms: XMPPChat[];
  shortcuts: ChatShortcut[];
  unreadCount: Partial<Record<string, number>>;
}

export interface ReduxChatMessage {
  id: string;
  time: number;
  author: string;
  message: string;
  attributes?: Record<string, unknown>; // TODO
}

const initialState: ChatState = {
  activeLinkedAccount: {},
  archives: {},
  xmppRooms: [],
  shortcuts: [],
  unreadCount: {},
};

const slice = createResettableSlice({
  name: 'Chat',
  initialState,
  reducers: {
    receiveXMPPRoom: (state: ChatState, action: PayloadAction<XMPPChat>) => {
      editOrAddXMPPRoom(state, action.payload);
    },
    removeXMPPRoom: (
      state: ChatState,
      action: PayloadAction<{ room: XMPPChat; archive: ChatArchive }>,
    ) => {
      const { room, archive } = action.payload;
      if (room) {
        PerformanceUtils.removeFromArrayInPlace(room, state.xmppRooms, 'room_name');
        if (archive?.patient_id) {
          state.archives[archive.patient_id] = PerformanceUtils.upsert(
            archive,
            state.archives[archive.patient_id] ?? [],
          );
        }
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        api.Chat.getUserArchives.fulfilled,
        unwrapApi((state, archives, req) => {
          state.archives[req.userId] = archives;
        }),
      )
      .addCase(
        api.Chat.addChatShortcut.fulfilled,
        unwrapApi((state, shortcut: ChatShortcut) => {
          state.shortcuts = PerformanceUtils.editOrAdd(shortcut, state.shortcuts);
        }),
      )
      .addCase(
        api.Chat.getChatShortcuts.fulfilled,
        unwrapApi((state, shortcuts: ChatShortcut[]) => {
          state.shortcuts = shortcuts;
        }),
      )

      .addCase(api.Chat.createXMPPHelpdeskChatRoom.fulfilled, unwrapApi(editOrAddXMPPRoom))
      .addCase(api.Chat.claimXMPPHelpdeskChatRoom.fulfilled, unwrapApi(editOrAddXMPPRoom))
      .addCase(
        api.Chat.archiveXMPPChatRoom.fulfilled,
        unwrapApi((state, payload, req) => {
          PerformanceUtils.removeFromArrayInPlace(
            { room_name: req.roomName },
            state.xmppRooms,
            'room_name',
          );
        }),
      )
      .addCase(
        api.Chat.getXMPPHelpdeskChatRooms.fulfilled,
        unwrapApi((state, chats) => {
          state.xmppRooms = chats;
        }),
      )
      .addCase(
        api.Chat.getUnreadCount.fulfilled,
        unwrap((state, data) => {
          if (!data.id) {
            return;
          }
          state.unreadCount[data.id] = data.count || 0;
        }),
      )
      .addCase(
        api.Chat.updateUnreadCount.fulfilled,
        unwrapApi((state, data, req) => {
          if (!req.userId) {
            return;
          }
          state.unreadCount[req.userId] = req.count.value || 0;
        }),
      )
      .addCase(
        api.HealthRecords.countUnreadHealthRecords.fulfilled,
        unwrapApi((state, data, req) => {
          data.forEach((it) => (state.unreadCount[it.id] = it.chat_count));
        }),
      ),
});

function editOrAddXMPPRoom(state: ChatState, chat: XMPPChat) {
  PerformanceUtils.upsert(chat, state.xmppRooms, 'room_name');
}

export const chatActions = slice.actions;
export default slice.reducer;
