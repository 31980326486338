/* eslint-disable @typescript-eslint/no-unused-vars */
import { socketConnection } from 'careand-redux/actions/socketConnection';
import { AppStore, RootState, store } from 'careand-redux/store';
import { authActions } from 'careand-redux/slices/auth';
import io from 'socket.io-client';
import config from 'config/config.json';
import { queueActions } from 'careand-redux/slices/queue';
import {
  Admin,
  Appointment,
  AppointmentNote,
  CallLog,
  ChatArchive,
  Fax,
  HelpdeskMessage,
  LockedDocument,
  Patient,
  PatientDocument, PatientHandover,
  Physician,
  PrescriptionRefill,
  QueueElement,
  ReferralRequest,
  Shift,
  ShiftRequest,
  Task,
  XMPPChat,
} from 'generated/api';
import { chatActions } from 'careand-redux/slices/chat';
import { appointmentActions } from 'careand-redux/slices/appointments';
import { patientActions } from 'careand-redux/slices/patients';
import { practitionerActions } from 'careand-redux/slices/physicians';
import MiscUtils from 'services/MiscUtils';
import { documentActions } from 'careand-redux/slices/documents';
import { hrActions } from 'careand-redux/slices/healthRecords';
import PerformanceUtils from 'services/PerformanceUtils';
import { tasksActions } from 'careand-redux/slices/tasks';
import { shiftsActions } from 'careand-redux/slices/shifts';
import { faxActions } from 'careand-redux/slices/fax';
import { videoActions } from 'careand-redux/slices/ui/video';
import { resourceActions } from 'careand-redux/slices/resource';
import { adminActions } from '../careand-redux/slices/admins';

const { API_URL } = config;

class Socket {
  private socket: any;
  private isConnected = false;

  constructor(private store: AppStore) {}

  public getSocket() {
    return this.socket;
  }

  open = (token: string) => {
    this.socket = io(API_URL, {
      transports: ['websocket'],
      query: `token=${token}`,
    });
    this.registerConnectionListeners();
    store.dispatch(authActions.setToken(token));
  };

  close = (refresh: boolean) => {
    if (this.socket) {
      if (refresh) {
        this.store.dispatch(socketConnection('refreshing'));
      }
      this.socket.close();
      this.socket = null;
    }
  };

  private registerConnectionListeners = () => {

    this.socket.on('connect_error', () => {
      this.store.dispatch(socketConnection('disconnected'));
    });
    this.socket.on('connect_timeout', () => {
      this.store.dispatch(socketConnection('disconnected'));
    });
    this.socket.on('disconnect', () => {
      if (store.getState().resource.socketConnection !== 'refreshing') {
        this.store.dispatch(socketConnection('disconnected'));
        this.isConnected = false;
        this.subscribeToAllEvents(false);
      }
    });
    this.socket.on('connect', () => {
      this.store.dispatch(socketConnection('connected'));
      if (!this.isConnected) {
        this.subscribeToAllEvents();
      }
      this.isConnected = true;
    });
  };

  private subscribeToAllEvents = (connect = true) => {
    this.subscribeToReferralRequestUpdate(connect);
    this.subscribeToQueueUpdate(connect);
    this.subscribeToChatUpdate(connect);
    this.subscribeToAppointmentUpdate(connect);
    this.subscribeToPatientUpdate(connect);
    this.subscribeToPhysicianUpdate(connect);
    this.subscribeToAdminUpdate(connect);
    this.subscribeToNotificationUpdate(connect);
    this.subscribeToDocumentUpdate(connect);
    this.subscribeToNoteUpdate(connect);
    this.subscribeToFaxUpdate(connect);
    this.subscribeToShiftUpdate(connect);
    this.subscribeToShiftRequestUpdate(connect);
    this.subscribeToVideoUpdate(connect);
    this.subscribeToTaskUpdate(connect);
    this.subscribeToRefillUpdate(connect);
    this.subscribeToHelpdeskMessageUpdate(connect);
    this.subscribeToPatientHandoverUpdate(connect);
  };

  private subscribeToReferralRequestUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('referral-request', (data: ReferralRequest) => {
          store.dispatch(hrActions.receiveUpdatedReferralRequest(data));
        });
        this.socket.on('referral-request-removed', (data: ReferralRequest) => {
          store.dispatch(hrActions.receiveRemovedReferralRequest(data));
        });
      } else {
        this.socket.removeListener('referral-request');
        this.socket.removeListener('referral-request-removed');
      }
    }
  };

  private subscribeToQueueUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('queue-updated', (data: QueueElement) => {
          store.dispatch(queueActions.receiveUpdatedQueueElement(data));
        });
        this.socket.on('queue-removed', (data: QueueElement) => {
          store.dispatch(queueActions.receiveRemovedQueueElement(data));
        });
      } else {
        this.socket.removeListener('queue-updated');
        this.socket.removeListener('queue-removed');
      }
    }
  };

  private subscribeToPatientHandoverUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('patient-handover-updated', (data: PatientHandover) => {
          store.dispatch(shiftsActions.receiveUpdatedPatientHandover(data));
        });
        this.socket.on('patient-handover-removed', (data: PatientHandover) => {
          store.dispatch(shiftsActions.receiveRemovedPatientHandover(data));
        });
      } else {
        this.socket.removeListener('patient-handover-updated');
        this.socket.removeListener('patient-handover-removed');
      }
    }
  };

  private subscribeToChatUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('xmpp-chat', (data: { room: XMPPChat }) => {
          store.dispatch(chatActions.receiveXMPPRoom(data.room));
        });
        this.socket.on('xmpp-chat-archived', (data: { room: XMPPChat; archive: ChatArchive }) => {
          store.dispatch(chatActions.removeXMPPRoom(data));
        });
      } else {
        this.socket.removeListener('xmpp-chat');
        this.socket.removeListener('xmpp-chat-archived');
      }
    }
  };

  private subscribeToAppointmentUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('appointment', (data: Appointment | undefined) => {
          const ownUser = this.getOwnUser(store.getState());
          if (data !== undefined && ownUser?.id) {
            store.dispatch(
              appointmentActions.receiveUpdatedAppointment({ appointment: data, ownUser }),
            );
          }
        });
      } else {
        this.socket.removeListener('appointment');
      }
    }
  };

  private subscribeToPatientUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('patient', (data: Patient) => {
          if (data !== undefined) store.dispatch(patientActions.receiveUpdatedPatient(data));
        });
        this.socket.on('patient-registered', (data: Patient) => {
          if (data !== undefined) store.dispatch(patientActions.receiveRegisteredPatient(data));
        });
      } else {
        this.socket.removeListener('patient');
        this.socket.removeListener('patient-registered');
      }
    }
  };

  private subscribeToPhysicianUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('physician', (data: Physician) => {
          if (data !== undefined) store.dispatch(practitionerActions.receiveUpdatedPhysician(data));
        });
      } else {
        this.socket.removeListener('physician');
      }
    }
  };

  private subscribeToAdminUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('admin', (data: Admin) => {
          if (data !== undefined) store.dispatch(adminActions.receiveUpdatedAdmin(data));
        });
      } else {
        this.socket.removeListener('admin');
      }
    }
  };

  private subscribeToNotificationUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on(
          'notification',
          (data: { color?: string; message?: string; title?: string }) => {
            // TODO extract type
            if (data !== undefined) {
              const color = data.color || 'blue';
              const payload = {
                title: data.title || 'Notification',
                message: data.message,
              };
              let options = undefined;
              if (data.message?.trimEnd().endsWith('joined your video appointment.')) {
                options = {
                  duration: 'infinite',
                  timeOut: 0,
                };
              }
              if (color === 'red') MiscUtils.showToastrError(payload.title, payload.message);
              else MiscUtils.showToastrInfo(payload, options);
            }
          },
        );
      } else {
        this.socket.removeListener('notification');
      }
    }
  };

  private subscribeToDocumentUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('document', (data: PatientDocument) => {
          if (data !== undefined) store.dispatch(documentActions.receivedUpdatedDocument(data));
        });
        this.socket.on('document-removed', (data: PatientDocument) => {
          if (data !== undefined) store.dispatch(documentActions.receivedDeletedDocument(data));
        });
        this.socket.on('locked-document', (data: LockedDocument) => {
          if (data !== undefined) store.dispatch(documentActions.receiveLockedDocument(data));
        });
      } else {
        this.socket.removeListener('document');
        this.socket.removeListener('document-removed');
        this.socket.removeListener('locked-document');
      }
    }
  };

  private subscribeToNoteUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('note', (data: AppointmentNote) => {
          if (data !== undefined) store.dispatch(hrActions.receiveUpdatedNote(data));
        });
      } else {
        this.socket.removeListener('note');
      }
    }
  };

  private subscribeToRefillUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('refill', (data: PrescriptionRefill) => {
          if (data !== undefined) store.dispatch(hrActions.receiveUpdatedRefill(data));
        });
      } else {
        this.socket.removeListener('refill');
      }
    }
  };

  private subscribeToTaskUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('task', (data: Task) => {
          if (data !== undefined) {
            const { assigned_user_id, author_id } = data;
            const userTasks = store.getState().tasks.byId[assigned_user_id!] || [];
            const isAssignedToUser = store.getState().authentication.user?.id === assigned_user_id;
            const isFromOwnUser = store.getState().authentication.user?.id === author_id;
            const isNewTask = !PerformanceUtils.existsInArray(data, userTasks);
            if (isAssignedToUser && isNewTask && !isFromOwnUser) {
              MiscUtils.showToastrInfo({
                title: 'Task',
                message: `New task assigned`,
              });
            }
            store.dispatch(tasksActions.receiveUpdatedTask(data));
          }
        });
      } else {
        this.socket.removeListener('task');
      }
    }
  };

  private subscribeToShiftUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('shift', (data: Shift) => {
          if (data !== undefined) {
            store.dispatch(shiftsActions.receiveUpdatedShift(data));
          }
        });
        this.socket.on('shift-removed', (data: Shift) => {
          if (data !== undefined) {
            store.dispatch(shiftsActions.receiveRemovedShift(data));
          }
        });
      } else {
        this.socket.removeListener('shift');
        this.socket.removeListener('shift-removed');
      }
    }
  };

  private subscribeToShiftRequestUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('shift-request', (data: ShiftRequest) => {
          if (data !== undefined) {
            store.dispatch(shiftsActions.receiveUpdatedShiftRequest(data));
          }
        });
      } else {
        this.socket.removeListener('shift-request');
      }
    }
  };

  private subscribeToFaxUpdate = (connect: boolean) => {
    if (this.socket) {
      if (connect) {
        this.socket.on('fax', (data: Fax) => {
          if (data !== undefined) {
            store.dispatch(faxActions.receiveUpdatedFax(data));
          }
        });
      } else {
        this.socket.removeListener('fax');
      }
    }
  };

  private subscribeToVideoUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('call-log', (data: CallLog) => {
          if (data !== undefined) {
            store.dispatch(videoActions.receiveCallLog(data));
          }
        });
      } else {
        this.socket.removeListener('call-log');
      }
    }
  };

  private subscribeToHelpdeskMessageUpdate = (connect: boolean) => {
    if (this.socket !== undefined) {
      if (connect) {
        this.socket.on('helpdesk-message', (data: HelpdeskMessage) => {
          if (data !== undefined) {
            store.dispatch(resourceActions.receiveUpdatedHelpdeskMessage(data));
          }
        });
      } else {
        this.socket.removeListener('helpdesk-message');
      }
    }
  };

  private getOwnUser(state: RootState) {
    return state.authentication.user;
  }
}

const instance = new Socket(store);
export default instance;
