import { PayloadAction } from '@reduxjs/toolkit';
import {
  Admin,
  AppSettings,
  LinkedAccountLoginPayload,
  Patient,
  Physician,
  Token,
  UserRoleEnum,
} from 'generated/api';
import moment from 'moment-timezone';
import { api } from 'careand-redux/api/ApiThunks';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { receivedTwoFactorRequired } from 'careand-redux/actions/receivedTwoFactorRequired';
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { logout } from 'careand-redux/actions/logout';

const DEFAULT_OBJECT = {};

export type AnyUser = Admin | Patient | Physician;

export interface CareAndToken extends JwtPayload {
  linked_accounts?: string[];
  no_refresh_after?: number;
  twoFA?: boolean;
  role?: UserRoleEnum;
}

export interface AuthState {
  token?: string;
  user?: AnyUser;
  activeUserId?: string;
  decodedToken: CareAndToken;
  role?: UserRoleEnum;
  username?: string;
  version?: string;
  loggedIn: boolean;
  savedPath?: string;
  date?: number;
  settings?: AppSettings;
  permissions: string[]; //TODO BE could send enum instead of string
  twoFactorRequired: boolean;
  previousUserId?: string;
}

const initialState: AuthState = {
  loggedIn: false,
  permissions: [],
  twoFactorRequired: false,
  decodedToken: {},
};

interface LoginSocketPayload {
  role: UserRoleEnum;
  auth: Partial<AuthState>;
  username: string;
}

const slice = createResettableSlice({
  name: 'Authentication',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      const token = action.payload;
      state.token = token;
      state.decodedToken = token ? (jwtDecode(token) as CareAndToken) : DEFAULT_OBJECT;
    },
    setVersionNumber: (state, action: PayloadAction<string | undefined>) => {
      state.version = action.payload;
    },
    changeActiveAccount: (state, action: PayloadAction<string>) => {
      handleChangeActiveAccount(state, action.payload);
    },
    receivedForbidden: (state) => {
      handleChangeActiveAccount(state, state.user?.id);
    },
    setLoginPayload: (state, action: PayloadAction<LoginSocketPayload>) => {
      const newAction: HandleLoginAction = {
        ...action.payload.auth,
        role: action.payload.role,
        username: action.payload.username,
      };
      handleLogin(state, newAction, action.payload.role);
      handleLoginSuccess(state, newAction.user?.id);
    },
    setTwoFactorRequired: (state, action: PayloadAction<boolean>) => {
      state.twoFactorRequired = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(receivedTwoFactorRequired, (state) => {
        state.twoFactorRequired = true;
      })
      .addCase(
        api.Authentication.refreshToken.fulfilled,
        unwrap((state, { token }) => {
          const newAction: HandleLoginAction = {
            token,
            user: state.user,
            role: state.role,
            username: state.username,
          };
          if (state.activeUserId && state.activeUserId !== newAction.user?.id) {
            newAction.activeUserId = state.activeUserId;
          }

          handleLogin(state, newAction, state.role);
        }),
      )
      .addCase(
        api.Authentication.patientLogin.fulfilled,
        unwrapApi((state, payload) => {
          handleLogin(state, payload, 'patient');
          handleLoginSuccess(state, payload.user?.id);
        }),
      )
      .addCase(
        api.Authentication.physicianLogin.fulfilled,
        unwrapApi((state, payload) => {
          handleLogin(state, payload, 'physician');
          handleLoginSuccess(state, payload.user?.id);
        }),
      )
      .addCase(
        api.Authentication.adminLogin.fulfilled,
        unwrapApi((state, payload) => {
          handleLogin(state, payload, 'admin');
          handleLoginSuccess(state, payload.user?.id);
        }),
      )
      .addCase(
        api.Authentication.registerPatient.fulfilled,
        unwrapApi((state, payload) => {
          handleLogin(state, payload, 'patient');
        }),
      )
      .addCase(api.Authentication.addLinkedAccount.fulfilled, unwrapApi(handleLinkedAdd))
      .addCase(api.Authentication.registerLinkedAccount.fulfilled, unwrapApi(handleLinkedAdd))
      .addCase(
        api.Patients.updatePatient.fulfilled,
        unwrapApi((state, patient) => {
          if (patient.id === state.user?.id) {
            state.user = patient;
          }
        }),
      )
      .addCase(
        api.Admins.updateAdmin.fulfilled,
        unwrapApi((state, admin) => {
          if (admin.id === state.user?.id) {
            state.user = admin;
          }
        }),
      )
      .addCase(
        api.Physicians.updatePhysician.fulfilled,
        unwrapApi((state, practitioner) => {
          if (practitioner.id === state.user?.id) {
            state.user = practitioner;
          }
        }),
      )
      .addCase(logout.action, (state, action) => {
        state.previousUserId = action.payload?.previousUserId;
        state.permissions = [];
      }),
});

type HandleLoginAction = Partial<AuthState>;
function handleLogin(state: AuthState, action: HandleLoginAction, role: AuthState['role']) {
  const token = action.token || state.token;
  if (action.user) {
    state.date = moment().unix();
    state.username = action.username;
    state.decodedToken = token ? (jwtDecode(token) as CareAndToken) : {};
    state.user = action.user;
    state.permissions = action.permissions || state.permissions;
    state.settings = action.settings || state.settings;
    state.role = role;
    state.token = action.token || state.token;
    state.activeUserId = action.activeUserId || action.user?.id;
    state.loggedIn = true;
  }
}

function handleLoginSuccess(state: AuthState, userId: string | undefined) {
  if (isDifferentUser(state, userId)) {
    window.localStorage.clear();
  } else {
    Object.keys(window.localStorage)
      .filter((key) => key.startsWith('clear.'))
      .forEach((key) => window.localStorage.removeItem(key));
  }
}

function isDifferentUser(state: AuthState, userId: string | undefined): boolean {
  return state.user?.id !== userId || state.previousUserId !== userId;
}

function handleLinkedAdd(state: AuthState, action: LinkedAccountLoginPayload) {
  state.user = action.parent;
  state.activeUserId = action.payload?.user?.id;
}

function handleChangeActiveAccount(state: AuthState, userId: string | undefined) {
  state.activeUserId = userId;
}

export const authActions = slice.actions;
export default slice.reducer;
