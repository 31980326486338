/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocumentTemplate,
  DocumentTemplateCategory,
  DocumentTemplateLite,
  DocumentTemplatePrefilled,
  ErrorResponse,
} from '../models';
import {
    DocumentTemplateFromJSON,
    DocumentTemplateToJSON,
    DocumentTemplateCategoryFromJSON,
    DocumentTemplateCategoryToJSON,
    DocumentTemplateLiteFromJSON,
    DocumentTemplateLiteToJSON,
    DocumentTemplatePrefilledFromJSON,
    DocumentTemplatePrefilledToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface AddDocumentTemplateRequest {
    file: Blob;
    category_id: string;
    description: string;
    clinic_id?: string;
    document_type?: string;
    template_id?: string;
}

export interface AddDocumentTemplateCategoryRequest {
    category: DocumentTemplateCategory;
}

export interface AddDocumentTemplatePrefilledRequest {
    data: DocumentTemplatePrefilled;
}

export interface AddDocumentTemplateRequestRequest {
    file: Blob;
    description: string;
    category_id: string;
    name?: string;
    subcategory?: string;
    subsubcategory?: string;
    instructions?: string;
}

export interface DeleteDocumentTemplateRequest {
    id: string;
}

export interface DeleteDocumentTemplatesPrefilledRequest {
    id: string;
}

export interface GetDocumentTemplateRequest {
    id: string;
}

export interface UpdateDocumentTemplateRequest {
    id: string;
    template: DocumentTemplate;
}

/**
 * 
 */
export class TemplatesApi extends runtime.BaseAPI {

    /**
     * Adds a document template (Roles: physician, admin)
     */
    async addDocumentTemplateRaw(requestParameters: AddDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentTemplateLite>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addDocumentTemplate.');
        }

        if (requestParameters.category_id === null || requestParameters.category_id === undefined) {
            throw new runtime.RequiredError('category_id','Required parameter requestParameters.category_id was null or undefined when calling addDocumentTemplate.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling addDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.category_id !== undefined) {
            formParams.append('category_id', requestParameters.category_id as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.clinic_id !== undefined) {
            formParams.append('clinic_id', requestParameters.clinic_id as any);
        }

        if (requestParameters.document_type !== undefined) {
            formParams.append('document_type', requestParameters.document_type as any);
        }

        if (requestParameters.template_id !== undefined) {
            formParams.append('template_id', requestParameters.template_id as any);
        }

        const response = await this.request({
            path: `/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentTemplateLiteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addDocumentTemplateRequestOptionsRaw(requestParameters: AddDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addDocumentTemplate.');
        }

        if (requestParameters.category_id === null || requestParameters.category_id === undefined) {
            throw new runtime.RequiredError('category_id','Required parameter requestParameters.category_id was null or undefined when calling addDocumentTemplate.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling addDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.category_id !== undefined) {
            formParams.append('category_id', requestParameters.category_id as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.clinic_id !== undefined) {
            formParams.append('clinic_id', requestParameters.clinic_id as any);
        }

        if (requestParameters.document_type !== undefined) {
            formParams.append('document_type', requestParameters.document_type as any);
        }

        if (requestParameters.template_id !== undefined) {
            formParams.append('template_id', requestParameters.template_id as any);
        }


        const responseConfig = {
            path: `/templates`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Adds a document template (Roles: physician, admin)
     */
    async addDocumentTemplate(requestParameters: AddDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentTemplateLite> {
        const response = await this.addDocumentTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new category for DocumentTemplates (Roles: physician, admin)
     */
    async addDocumentTemplateCategoryRaw(requestParameters: AddDocumentTemplateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentTemplateCategory>> {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling addDocumentTemplateCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/category`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateCategoryToJSON(requestParameters.category),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentTemplateCategoryFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addDocumentTemplateCategoryRequestOptionsRaw(requestParameters: AddDocumentTemplateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category','Required parameter requestParameters.category was null or undefined when calling addDocumentTemplateCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/category`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateCategoryToJSON(requestParameters.category),
        };
        return responseConfig
    }

    /**
     * Adds a new category for DocumentTemplates (Roles: physician, admin)
     */
    async addDocumentTemplateCategory(requestParameters: AddDocumentTemplateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentTemplateCategory> {
        const response = await this.addDocumentTemplateCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new prefilled DocumentTemplate (Roles: physician, admin)
     */
    async addDocumentTemplatePrefilledRaw(requestParameters: AddDocumentTemplatePrefilledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentTemplatePrefilled>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addDocumentTemplatePrefilled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/pre-filled`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplatePrefilledToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentTemplatePrefilledFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addDocumentTemplatePrefilledRequestOptionsRaw(requestParameters: AddDocumentTemplatePrefilledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addDocumentTemplatePrefilled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/pre-filled`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplatePrefilledToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Adds a new prefilled DocumentTemplate (Roles: physician, admin)
     */
    async addDocumentTemplatePrefilled(requestParameters: AddDocumentTemplatePrefilledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentTemplatePrefilled> {
        const response = await this.addDocumentTemplatePrefilledRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a Document Template request (Roles: admin, physician)
     */
    async addDocumentTemplateRequestRaw(requestParameters: AddDocumentTemplateRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentTemplateLite>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addDocumentTemplateRequest.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling addDocumentTemplateRequest.');
        }

        if (requestParameters.category_id === null || requestParameters.category_id === undefined) {
            throw new runtime.RequiredError('category_id','Required parameter requestParameters.category_id was null or undefined when calling addDocumentTemplateRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.category_id !== undefined) {
            formParams.append('category_id', requestParameters.category_id as any);
        }

        if (requestParameters.subcategory !== undefined) {
            formParams.append('subcategory', requestParameters.subcategory as any);
        }

        if (requestParameters.subsubcategory !== undefined) {
            formParams.append('subsubcategory', requestParameters.subsubcategory as any);
        }

        if (requestParameters.instructions !== undefined) {
            formParams.append('instructions', requestParameters.instructions as any);
        }

        const response = await this.request({
            path: `/template-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentTemplateLiteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addDocumentTemplateRequestRequestOptionsRaw(requestParameters: AddDocumentTemplateRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addDocumentTemplateRequest.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling addDocumentTemplateRequest.');
        }

        if (requestParameters.category_id === null || requestParameters.category_id === undefined) {
            throw new runtime.RequiredError('category_id','Required parameter requestParameters.category_id was null or undefined when calling addDocumentTemplateRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.category_id !== undefined) {
            formParams.append('category_id', requestParameters.category_id as any);
        }

        if (requestParameters.subcategory !== undefined) {
            formParams.append('subcategory', requestParameters.subcategory as any);
        }

        if (requestParameters.subsubcategory !== undefined) {
            formParams.append('subsubcategory', requestParameters.subsubcategory as any);
        }

        if (requestParameters.instructions !== undefined) {
            formParams.append('instructions', requestParameters.instructions as any);
        }


        const responseConfig = {
            path: `/template-request`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Creates a Document Template request (Roles: admin, physician)
     */
    async addDocumentTemplateRequest(requestParameters: AddDocumentTemplateRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentTemplateLite> {
        const response = await this.addDocumentTemplateRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a specific document template (Roles: physician, admin)
     */
    async deleteDocumentTemplateRaw(requestParameters: DeleteDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteDocumentTemplateRequestOptionsRaw(requestParameters: DeleteDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a specific document template (Roles: physician, admin)
     */
    async deleteDocumentTemplate(requestParameters: DeleteDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a prefilled DocumentTemplates (Roles: physician, admin)
     */
    async deleteDocumentTemplatesPrefilledRaw(requestParameters: DeleteDocumentTemplatesPrefilledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocumentTemplatesPrefilled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/pre-filled/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteDocumentTemplatesPrefilledRequestOptionsRaw(requestParameters: DeleteDocumentTemplatesPrefilledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocumentTemplatesPrefilled.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/pre-filled/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a prefilled DocumentTemplates (Roles: physician, admin)
     */
    async deleteDocumentTemplatesPrefilled(requestParameters: DeleteDocumentTemplatesPrefilledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentTemplatesPrefilledRaw(requestParameters, initOverrides);
    }

    /**
     * Gets a specific document template (Roles: physician, admin)
     */
    async getDocumentTemplateRaw(requestParameters: GetDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentTemplateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getDocumentTemplateRequestOptionsRaw(requestParameters: GetDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a specific document template (Roles: physician, admin)
     */
    async getDocumentTemplate(requestParameters: GetDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentTemplate> {
        const response = await this.getDocumentTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all DocumentTemplate categories (Roles: physician, admin)
     */
    async getDocumentTemplateCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentTemplateCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentTemplateCategoryFromJSON));
    }

    /**
        Request Config Getter
     */
    getDocumentTemplateCategoriesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/category`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all DocumentTemplate categories (Roles: physician, admin)
     */
    async getDocumentTemplateCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentTemplateCategory>> {
        const response = await this.getDocumentTemplateCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all available document templates (Roles: physician, admin)
     */
    async getDocumentTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentTemplateLite>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentTemplateLiteFromJSON));
    }

    /**
        Request Config Getter
     */
    getDocumentTemplatesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all available document templates (Roles: physician, admin)
     */
    async getDocumentTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentTemplateLite>> {
        const response = await this.getDocumentTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all prefilled DocumentTemplates (Roles: physician, admin)
     */
    async getDocumentTemplatesPrefilledRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentTemplatePrefilled>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/pre-filled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentTemplatePrefilledFromJSON));
    }

    /**
        Request Config Getter
     */
    getDocumentTemplatesPrefilledRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/pre-filled`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all prefilled DocumentTemplates (Roles: physician, admin)
     */
    async getDocumentTemplatesPrefilled(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentTemplatePrefilled>> {
        const response = await this.getDocumentTemplatesPrefilledRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates a specific document template (Roles: physician, admin)
     */
    async updateDocumentTemplateRaw(requestParameters: UpdateDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDocumentTemplate.');
        }

        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling updateDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/templates/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateToJSON(requestParameters.template),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentTemplateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateDocumentTemplateRequestOptionsRaw(requestParameters: UpdateDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDocumentTemplate.');
        }

        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling updateDocumentTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/templates/template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateToJSON(requestParameters.template),
        };
        return responseConfig
    }

    /**
     * Updates a specific document template (Roles: physician, admin)
     */
    async updateDocumentTemplate(requestParameters: UpdateDocumentTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentTemplate> {
        const response = await this.updateDocumentTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
