/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrugbankTherapeuticCategory
 */
export interface DrugbankTherapeuticCategory {
    /**
     * 
     * @type {string}
     * @memberof DrugbankTherapeuticCategory
     */
    drugbank_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankTherapeuticCategory
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankTherapeuticCategory
     */
    mesh_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DrugbankTherapeuticCategory
     */
    mesh_tree_numbers?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DrugbankTherapeuticCategory
     */
    atc_code?: string;
    /**
     * 
     * @type {number}
     * @memberof DrugbankTherapeuticCategory
     */
    atc_level?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DrugbankTherapeuticCategory
     */
    synonyms?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DrugbankTherapeuticCategory
     */
    description?: string;
}

/**
 * Check if a given object implements the DrugbankTherapeuticCategory interface.
 */
export function instanceOfDrugbankTherapeuticCategory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DrugbankTherapeuticCategoryFromJSON(json: any): DrugbankTherapeuticCategory {
    return DrugbankTherapeuticCategoryFromJSONTyped(json, false);
}

export function DrugbankTherapeuticCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrugbankTherapeuticCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drugbank_id': !exists(json, 'drugbank_id') ? undefined : json['drugbank_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mesh_id': !exists(json, 'mesh_id') ? undefined : json['mesh_id'],
        'mesh_tree_numbers': !exists(json, 'mesh_tree_numbers') ? undefined : json['mesh_tree_numbers'],
        'atc_code': !exists(json, 'atc_code') ? undefined : json['atc_code'],
        'atc_level': !exists(json, 'atc_level') ? undefined : json['atc_level'],
        'synonyms': !exists(json, 'synonyms') ? undefined : json['synonyms'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function DrugbankTherapeuticCategoryToJSON(value?: DrugbankTherapeuticCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'drugbank_id': value.drugbank_id,
        'name': value.name,
        'mesh_id': value.mesh_id,
        'mesh_tree_numbers': value.mesh_tree_numbers,
        'atc_code': value.atc_code,
        'atc_level': value.atc_level,
        'synonyms': value.synonyms,
        'description': value.description,
    };
}

