/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Admin } from './Admin';
import {
    AdminFromJSON,
    AdminFromJSONTyped,
    AdminToJSON,
} from './Admin';
import type { Physician } from './Physician';
import {
    PhysicianFromJSON,
    PhysicianFromJSONTyped,
    PhysicianToJSON,
} from './Physician';

/**
 * 
 * @export
 * @interface AdminOrPhysician
 */
export interface AdminOrPhysician {
    /**
     * 
     * @type {Physician}
     * @memberof AdminOrPhysician
     */
    physician?: Physician;
    /**
     * 
     * @type {Admin}
     * @memberof AdminOrPhysician
     */
    admin?: Admin;
}

/**
 * Check if a given object implements the AdminOrPhysician interface.
 */
export function instanceOfAdminOrPhysician(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminOrPhysicianFromJSON(json: any): AdminOrPhysician {
    return AdminOrPhysicianFromJSONTyped(json, false);
}

export function AdminOrPhysicianFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminOrPhysician {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'physician': !exists(json, 'physician') ? undefined : PhysicianFromJSON(json['physician']),
        'admin': !exists(json, 'admin') ? undefined : AdminFromJSON(json['admin']),
    };
}

export function AdminOrPhysicianToJSON(value?: AdminOrPhysician | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physician': PhysicianToJSON(value.physician),
        'admin': AdminToJSON(value.admin),
    };
}

