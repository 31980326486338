/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserTracking
 */
export interface UserTracking {
    /**
     * 
     * @type {string}
     * @memberof UserTracking
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTracking
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTracking
     */
    device_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTracking
     */
    screen: string;
    /**
     * 
     * @type {string}
     * @memberof UserTracking
     */
    payload?: string;
    /**
     * 
     * @type {number}
     * @memberof UserTracking
     */
    date?: number;
}

/**
 * Check if a given object implements the UserTracking interface.
 */
export function instanceOfUserTracking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "screen" in value;

    return isInstance;
}

export function UserTrackingFromJSON(json: any): UserTracking {
    return UserTrackingFromJSONTyped(json, false);
}

export function UserTrackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTracking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'device_id': !exists(json, 'device_id') ? undefined : json['device_id'],
        'screen': json['screen'],
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
        'date': !exists(json, 'date') ? undefined : json['date'],
    };
}

export function UserTrackingToJSON(value?: UserTracking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_id': value.user_id,
        'device_id': value.device_id,
        'screen': value.screen,
        'payload': value.payload,
        'date': value.date,
    };
}

