/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LockedDocument
 */
export interface LockedDocument {
    /**
     * 
     * @type {string}
     * @memberof LockedDocument
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LockedDocument
     */
    user_id?: string;
}

/**
 * Check if a given object implements the LockedDocument interface.
 */
export function instanceOfLockedDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function LockedDocumentFromJSON(json: any): LockedDocument {
    return LockedDocumentFromJSONTyped(json, false);
}

export function LockedDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LockedDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function LockedDocumentToJSON(value?: LockedDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_id': value.user_id,
    };
}

