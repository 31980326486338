/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentHistoricalResult
 */
export interface DocumentHistoricalResult {
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    test?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    test_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    result?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    reference_units?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    flag?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentHistoricalResult
     */
    reference?: string;
}

/**
 * Check if a given object implements the DocumentHistoricalResult interface.
 */
export function instanceOfDocumentHistoricalResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentHistoricalResultFromJSON(json: any): DocumentHistoricalResult {
    return DocumentHistoricalResultFromJSONTyped(json, false);
}

export function DocumentHistoricalResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentHistoricalResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'test': !exists(json, 'test') ? undefined : json['test'],
        'test_name': !exists(json, 'test_name') ? undefined : json['test_name'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'reference_units': !exists(json, 'reference_units') ? undefined : json['reference_units'],
        'flag': !exists(json, 'flag') ? undefined : json['flag'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function DocumentHistoricalResultToJSON(value?: DocumentHistoricalResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'test': value.test,
        'test_name': value.test_name,
        'unit': value.unit,
        'result': value.result,
        'reference_units': value.reference_units,
        'flag': value.flag,
        'reference': value.reference,
    };
}

