/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Physician,
  PrimaryPhysicianSuggestion,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PhysicianFromJSON,
    PhysicianToJSON,
    PrimaryPhysicianSuggestionFromJSON,
    PrimaryPhysicianSuggestionToJSON,
} from '../models';

export interface AddPhysicianSignatureRequest {
    id: string;
    file: Blob;
}

export interface GetPhysicianRequest {
    id: string;
}

export interface GetPhysicianSignatureRequest {
    id: string;
}

export interface GetPhysiciansRequest {
    name?: string;
    clinicId?: string;
    limit?: number;
}

export interface GetPrimaryPhysicianSuggestionsRequest {
    clinicId?: string;
}

export interface UpdatePhysicianRequest {
    id: string;
    physician: Physician;
}

/**
 * 
 */
export class PhysiciansApi extends runtime.BaseAPI {

    /**
     * Adds the Physician\'s signature file (Roles: admin, physician)
     */
    async addPhysicianSignatureRaw(requestParameters: AddPhysicianSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Physician>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPhysicianSignature.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addPhysicianSignature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/physicians/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhysicianFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPhysicianSignatureRequestOptionsRaw(requestParameters: AddPhysicianSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPhysicianSignature.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addPhysicianSignature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        const responseConfig = {
            path: `/physicians/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Adds the Physician\'s signature file (Roles: admin, physician)
     */
    async addPhysicianSignature(requestParameters: AddPhysicianSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Physician> {
        const response = await this.addPhysicianSignatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Physician with the specified id (Roles: patient, physician, admin)
     */
    async getPhysicianRaw(requestParameters: GetPhysicianRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Physician>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPhysician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/physicians/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhysicianFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getPhysicianRequestOptionsRaw(requestParameters: GetPhysicianRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPhysician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/physicians/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns the Physician with the specified id (Roles: patient, physician, admin)
     */
    async getPhysician(requestParameters: GetPhysicianRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Physician> {
        const response = await this.getPhysicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the Physician\'s signature file (Roles: admin, physician, patient)
     */
    async getPhysicianSignatureRaw(requestParameters: GetPhysicianSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPhysicianSignature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/physicians/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getPhysicianSignatureRequestOptionsRaw(requestParameters: GetPhysicianSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPhysicianSignature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/physicians/{id}/signature`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the Physician\'s signature file (Roles: admin, physician, patient)
     */
    async getPhysicianSignature(requestParameters: GetPhysicianSignatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getPhysicianSignatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the Physicians that the user has access to (Roles: patient, physician, admin)
     */
    async getPhysiciansRaw(requestParameters: GetPhysiciansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Physician>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/physicians`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhysicianFromJSON));
    }

    /**
        Request Config Getter
     */
    getPhysiciansRequestOptionsRaw(requestParameters: GetPhysiciansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/physicians`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all the Physicians that the user has access to (Roles: patient, physician, admin)
     */
    async getPhysicians(requestParameters: GetPhysiciansRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Physician>> {
        const response = await this.getPhysiciansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get primary physician suggestions
     */
    async getPrimaryPhysicianSuggestionsRaw(requestParameters: GetPrimaryPhysicianSuggestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrimaryPhysicianSuggestion>>> {
        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/primary-suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrimaryPhysicianSuggestionFromJSON));
    }

    /**
        Request Config Getter
     */
    getPrimaryPhysicianSuggestionsRequestOptionsRaw(requestParameters: GetPrimaryPhysicianSuggestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/primary-suggestions`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Get primary physician suggestions
     */
    async getPrimaryPhysicianSuggestions(requestParameters: GetPrimaryPhysicianSuggestionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrimaryPhysicianSuggestion>> {
        const response = await this.getPrimaryPhysicianSuggestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the Physician with the specified id (Roles: physician, admin)
     */
    async updatePhysicianRaw(requestParameters: UpdatePhysicianRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Physician>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePhysician.');
        }

        if (requestParameters.physician === null || requestParameters.physician === undefined) {
            throw new runtime.RequiredError('physician','Required parameter requestParameters.physician was null or undefined when calling updatePhysician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/physicians/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PhysicianToJSON(requestParameters.physician),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhysicianFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updatePhysicianRequestOptionsRaw(requestParameters: UpdatePhysicianRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePhysician.');
        }

        if (requestParameters.physician === null || requestParameters.physician === undefined) {
            throw new runtime.RequiredError('physician','Required parameter requestParameters.physician was null or undefined when calling updatePhysician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/physicians/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PhysicianToJSON(requestParameters.physician),
        };
        return responseConfig
    }

    /**
     * Updates the Physician with the specified id (Roles: physician, admin)
     */
    async updatePhysician(requestParameters: UpdatePhysicianRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Physician> {
        const response = await this.updatePhysicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
