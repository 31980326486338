/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicationReminderWhenValue
 */
export interface MedicationReminderWhenValue {
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderWhenValue
     */
    time?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderWhenValue
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderWhenValue
     */
    week_day?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderWhenValue
     */
    month_day?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderWhenValue
     */
    days_active?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderWhenValue
     */
    days_inactive?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderWhenValue
     */
    frequency?: number;
}

/**
 * Check if a given object implements the MedicationReminderWhenValue interface.
 */
export function instanceOfMedicationReminderWhenValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicationReminderWhenValueFromJSON(json: any): MedicationReminderWhenValue {
    return MedicationReminderWhenValueFromJSONTyped(json, false);
}

export function MedicationReminderWhenValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminderWhenValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : json['time'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'week_day': !exists(json, 'week_day') ? undefined : json['week_day'],
        'month_day': !exists(json, 'month_day') ? undefined : json['month_day'],
        'days_active': !exists(json, 'days_active') ? undefined : json['days_active'],
        'days_inactive': !exists(json, 'days_inactive') ? undefined : json['days_inactive'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
    };
}

export function MedicationReminderWhenValueToJSON(value?: MedicationReminderWhenValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'amount': value.amount,
        'week_day': value.week_day,
        'month_day': value.month_day,
        'days_active': value.days_active,
        'days_inactive': value.days_inactive,
        'frequency': value.frequency,
    };
}

