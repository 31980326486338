/* eslint-disable no-restricted-syntax */
import React, { MutableRefObject, RefObject } from 'react';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

type CommonState = {
  initialPos: [number, number];
  pos: [number, number];
  startTime: number;
  time: number;
  first: boolean;
};

/** Swipe State Machine Model */
export type SwipeState =
  | { state: 'idle' }
  | ({ state: 'began' } & CommonState)
  | ({ state: 'recognized' } & CommonState)
  | ({ state: 'ended' } & CommonState);

export type SwipeUpdate = (state: SwipeState) => void;

/**
 * @param key - swipeableElement key (used to figure if the ref has changed)
 * @param delta - [dx, dy] that's needed for swipe to recognize
 * @param activationRegion - region in view where touch must begin in order to be recognized
 * @param swipeableElement - ref to element that will be tracking touches
 * @param extraCondition - predicate fn, that will be used to check if a swipe can be recognized
 */
type Config = {
  key?: unknown;
  delta?: [number | undefined, number | undefined];
  activationRegion?: { minX?: number; minY?: number; maxX?: number; maxY?: number };
  swipeableElement?: RefObject<HTMLDivElement>;
  extraCondition?: () => boolean;
  swipeHandler?: RefObject<SwipeUpdate | undefined>;
  debugName?: string;
};

/**
 * @param swipeableElement - ref to element that tracks touches, a new one in case none was provided in the config
 * @param swipeUpdated - ref to function that runs on every update
 */
type UseSwipeReturn = {
  swipeableElement: MutableRefObject<HTMLDivElement>;
  swipeHandler: MutableRefObject<SwipeUpdate | undefined>;
};

/**
 * Tracks touch motion in a HTMLElement that can be either be provided via {@link Config}
 * or whose ref can be forwarded using the *swipeUpdated* ref
 *
 * It implements a state machine on top of HTMLElementTouchEvents,
 * and it will call the updateFn with its updates (see {@link SwipeUpdate})
 *
 * @param config {@link Config}
 * @returns ref and updateFn {@link UseSwipeReturn}
 */
export const useSwipe = (config: Config = {}): UseSwipeReturn => {
  const ref =
    config.swipeableElement ?? (useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>);
  const state = useRef<SwipeState>({ state: 'idle' });
  const touchId = useRef<number | undefined>(undefined);
  const swipeUpdated = config.swipeHandler ?? useRef<SwipeUpdate | undefined>();
  const originalBounds = useRef<DOMRect | undefined>();

  useEffect(() => {
    const elem = ref.current;

    function updateState(e: TouchEvent) {
      if (e.type === 'touchcancel' || e.type === 'touchend') {
        if (state.current.state === 'began') {
          state.current = { state: 'idle' };
        } else if (state.current.state === 'recognized') {
          touchId.current = undefined;
          state.current = { ...state.current, state: 'ended', time: Date.now(), first: false };
          swipeUpdated.current?.(state.current);
        }
        return;
      }
      if (e.type === 'touchstart') {
        if (state.current.state === 'recognized') {
          return;
        }
        if (e.touches.length !== 1) {
          return;
        }
        originalBounds.current = elem?.getBoundingClientRect();
        if (config.extraCondition && !config.extraCondition()) {
          state.current = { state: 'idle' };
          touchId.current = undefined;
          swipeUpdated.current?.(state.current);
          return;
        }
        const touch = e.touches[0];
        if (isWithinActivationRegion(touch, config.activationRegion, originalBounds.current)) {
          touchId.current = touch.identifier;
        }
        const time = Date.now();
        state.current = {
          state: 'began',
          pos: [touch.clientX, touch.clientY],
          startTime: time,
          time,
          initialPos: [touch.clientX, touch.clientY],
          first: false,
        };
        return;
      }
      const touch = _(e.touches).find((it) => it.identifier === touchId.current);
      if (!touch) {
        return;
      }
      if (e.type === 'touchmove') {
        if (state.current.state === 'idle' || state.current.state === 'ended') {
          return;
        }
        if (state.current.state === 'began') {
          if (isPastDelta(touch, state.current.pos, config.delta)) {
            state.current = {
              ...state.current,
              state: 'recognized',
              pos: [touch.clientX, touch.clientY],
              time: Date.now(),
              first: true,
            };
            swipeUpdated.current?.(state.current);
          }
          return;
        }
        if (state.current.state === 'recognized') {
          state.current.time = Date.now();
          state.current.pos = [touch.clientX, touch.clientY];
          state.current.first = false;
          swipeUpdated.current?.(state.current);
          return;
        }
      }
    }

    elem?.addEventListener('touchstart', updateState, { passive: true });
    elem?.addEventListener('touchmove', updateState, { passive: true });
    elem?.addEventListener('touchend', updateState, { passive: true });
    elem?.addEventListener('touchcancel', updateState, { passive: true });

    return () => {
      elem?.removeEventListener('touchstart', updateState);
      elem?.removeEventListener('touchmove', updateState);
      elem?.removeEventListener('touchend', updateState);
      elem?.removeEventListener('touchcancel', updateState);
    };
  }, [config.key]);

  return {
    swipeableElement: ref as MutableRefObject<HTMLDivElement>,
    swipeHandler: swipeUpdated as MutableRefObject<SwipeUpdate | undefined>,
  };
};

function isWithinActivationRegion(
  touch: Touch,
  activationRegion: Config['activationRegion'],
  referrence?: DOMRect,
): boolean {
  if (!activationRegion) {
    return true;
  }
  const x = touch.clientX + (referrence?.left ?? 0);
  const y = touch.clientY;

  const refLeft = referrence?.left ?? 0;
  const refTop = referrence?.top ?? 0;

  const minX = refLeft + (activationRegion.minX ?? 0);
  const minY = refTop + (activationRegion.minY ?? 0);
  const maxX = refLeft + (activationRegion.maxX ?? 0);
  const maxY = refTop + (activationRegion.maxY ?? 0);

  return (
    (activationRegion.minX === undefined || x >= minX) &&
    (activationRegion.minY === undefined || y >= minY) &&
    (activationRegion.maxX === undefined || x <= maxX) &&
    (activationRegion.maxY === undefined || y <= maxY)
  );
}

function isPastDelta(touch: Touch, initialPos: [number, number], delta: Config['delta']): boolean {
  const dx = touch.clientX - initialPos[0];
  const dy = touch.clientY - initialPos[1];

  return (
    (delta?.[0] === undefined || (delta[0] > 0 ? dx >= delta[0] : dx <= delta[0])) &&
    (delta?.[1] === undefined || (delta[1] > 0 ? dy >= delta[1] : dy <= delta[1]))
  );
}

export class VelocityTracker {
  constructor(private maxDt: number) {}

  private lastEvents: [number, number, number][] = [];

  private get oldestEvent(): [number, number, number] | undefined {
    const time = this.newestEvent?.[2];
    if (time === undefined) {
      return;
    }
    const oldest = _(this.lastEvents)
      .takeRightWhile((it) => time - it[2] < this.maxDt)
      .first();
    return oldest;
  }

  private get newestEvent(): [number, number, number] | undefined {
    if (this.lastEvents.length > 0) {
      return this.lastEvents[this.lastEvents.length - 1];
    }
  }

  reset() {
    this.lastEvents = [];
  }

  addEvent(event: [number, number], time?: number) {
    this.lastEvents.push([...event, time ?? Date.now()]);
  }

  getVelocity(): [number, number] {
    const old = this.oldestEvent;
    const newest = this.newestEvent;
    if (!old || !newest) {
      return [0, 0];
    }

    const dt = newest[2] - old[2];
    const dx = newest[0] - old[0];
    const dy = newest[1] - old[1];
    if (dt === 0) {
      return [0, 0];
    }

    return [dx / dt, dy / dt];
  }
}
