import { useAppSelector } from 'careand-redux/store';
import { useAppDispatch } from 'careand-redux/useAppDispatch';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { authActions, AuthState } from 'careand-redux/slices/auth';
import { tokenExpired } from 'careand-redux/actions/tokenExpired';
import { logout } from 'careand-redux/actions/logout';
import { api } from 'careand-redux/api/ApiThunks';
import { Device } from 'services/Native/Device';
import config from 'config/config.json';
import Bugsnag from '@bugsnag/browser';

const isPatientApp = !!config.PATIENT;

type Props = {
  version: string;
  closeSocket: (refresh?: boolean) => void;
  openSocket: (token: string) => void;
  onAuthenticationChange: (authenticated: boolean, userId: string | undefined) => void;
  patient: boolean;
};

const AuthenticationController = (props: Props) => {
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const useBiometrics = useAppSelector((state) => !!state.device.useBiometrics);

  const [authenticated, setAuthenticated] = useState<boolean | undefined>(undefined);
  const [storedUserId, setUserId] = useState<string | undefined>(undefined);

  const refreshTokenTimeout = useRef<NodeJS.Timer | undefined>();

  const setAuthenticationToken = (token: string | undefined, refresh = false) => {
    props.closeSocket(refresh);
    if (token && validToken(token)) {
      props.openSocket(token);
    } else {
      if (refreshTokenTimeout.current) {
        clearTimeout(refreshTokenTimeout.current);
      }
      dispatch(authActions.setToken(undefined));
    }
  };

  const setupTokenRefresh = (decodedToken: {}) => {
    if (decodedToken) refreshToken(getTimeLeft(decodedToken));
  };

  const refreshToken = (timestamp: number) => {
    if (refreshTokenTimeout.current) {
      clearTimeout(refreshTokenTimeout.current);
    }

    const timeout = Math.max(timestamp, 0);

    refreshTokenTimeout.current = setTimeout(async () => {
      const token = await dispatch(api.Authentication.refreshToken());
      if (token?.token) {
        const decodedToken = jwtDecode<JwtPayload>(token.token);
        const timeLeft = getTimeLeft(decodedToken);
        refreshToken(timeLeft);
      }
    }, timeout);
  };

  const attemptLoginFromDeviceCredentials = async () => {
    const createFail = () => Promise.resolve(undefined);

    if (!Device.isMobile()) {
      return createFail();
    }

    if (isPatientApp) {
      const credentials = await Device.requestCredentials();
      if (!credentials) {
        return createFail();
      }
      const loginPayload = await dispatch(api.Authentication.patientLogin({ auth: credentials }));
      return loginPayload;
    } else {
      logSucc('[auto-login] from device attempt');
      const activeUserId = await Device.requestActiveUserId();
      if (!activeUserId) {
        logErr('[auto-login] missing activeUserId');
        return;
      }
      logSucc(`[auto-login] ${activeUserId}`);
      const credentials = await Device.requestStoredUserCredentials(activeUserId);
      if (!credentials) {
        logErr(`[auto-login] missing credentials for ${activeUserId}`);
        return;
      }
      const code = (await Device.requestOTPCode({ username: credentials.username })).code;
      if (!code) {
        logErr(`[auto-login] missing code for ${activeUserId}`);
        return;
      }
      const payload = {
        auth: { username: credentials.username, password: credentials.password, code },
      };
      const loginCall =
        api.Authentication[credentials.userType === 'admin' ? 'adminLogin' : 'physicianLogin'];
      //@ts-ignore
      const loginPayload = await dispatch(loginCall(payload));

      if (!loginPayload) {
        logErr(`[auto-login] login: Failed ${activeUserId}`);
      } else {
        logSucc(`[auto-login] login: Success ${activeUserId}`);
      }

      return loginPayload;
    }
  };

  const verifyAuthentication = async () => {
    let token = authentication.token;
    let decodedToken = authentication.decodedToken;

    let prevAuth = authenticated;
    let currentAuth = isAuthenticated(authentication);
    let userId = undefined;

    if (validToken(token)) {
      currentAuth = true;
      userId = authentication.decodedToken.sub;
    }

    if (!validToken(token) || !currentAuth) {
      let decodedToken = token && jwtDecode<JwtPayload>(token);
      currentAuth = false;
      userId = undefined;

      const loginPayload = await attemptLoginFromDeviceCredentials();
      if (loginPayload) {
        setAuthenticationToken(token);
        currentAuth = true;
        token = loginPayload.token;
        userId = loginPayload.user?.id;
        if (token) {
          decodedToken = jwtDecode(token);
        }
      } else {
        dispatch(tokenExpired());
        setAuthenticationToken(undefined);
      }
    }

    const versionChanged = hasVersionChanged(authentication, props.version);
    const incorrectRole = !correctRole(authentication, props.patient);

    if (versionChanged || incorrectRole) {
      dispatch(logout());
      currentAuth = false;
    } else if (!authentication.version) {
      dispatch(authActions.setVersionNumber(props.version));
    }

    currentAuth ||= false;

    if (prevAuth !== currentAuth || userId !== storedUserId) {
      if (currentAuth) {
        setAuthenticationToken(token, true);
        setupTokenRefresh(decodedToken);
      }
    }
    setAuthenticated(currentAuth);
    setUserId(userId);
    props.onAuthenticationChange(currentAuth, userId);
  };

  useEffect(() => {
    verifyAuthentication();
  }, [authentication.decodedToken]);

  useEffect(() => {
    Device.sendWebAppInfo({
      isLoggedIn: authentication.loggedIn,
      biometricAuthentication: useBiometrics,
    });
  }, [authentication.loggedIn, useBiometrics]);

  return null;
};

function correctRole(auth: AuthState, isPatient: boolean) {
  if (!isAuthenticated(auth)) {
    return true;
  }

  const currentRole = auth.role;

  if (!currentRole) {
    return false;
  }

  return (currentRole === 'patient') === isPatient;
}

function hasVersionChanged(auth: AuthState, version: string) {
  return auth.version && version !== auth.version;
}

function isAuthenticated(auth: AuthState) {
  return auth.loggedIn === true && validToken(auth.token);
}

function validToken(token: string | undefined): boolean {
  if (!token) return false;
  let decodedToken = jwtDecode<JwtPayload>(token);
  return !!decodedToken.exp && decodedToken.exp >= moment().unix();
}

function getTimeLeft(decodedToken: JwtPayload) {
  if (!decodedToken.exp) {
    return 0;
  }
  return moment.unix(decodedToken.exp).diff(moment()) / 2;
}

function logErr(...args) {
  console.error(args);
  const msg = args.map((it) => (typeof it === 'string' ? it : JSON.stringify(it))).join(' ');
  Bugsnag.notify(msg);
}

function logSucc(...args) {
  console.log(...args);
}

export default AuthenticationController;
