import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import './style.scss';
import Icon from 'components/Utils/Icon';
import UnreadBadge from 'components/Common/UnreadBadge';
import ProgressMessage from 'components/Utils/ProgressMessage';

const STYLE = {
  padding: 0,
};

const DEFAULT_OBJECT = {};

const GenericBadge = ({
  title,
  info,
  avatar,
  className = "",
  id,
  onClick,
  style = DEFAULT_OBJECT,
  alerts,
  alert,
  icon,
  iconAlt,
  alertIcon,
  alertStyle,
  count,
  avatarVariant,
  loading = false,
  countStyle = DEFAULT_OBJECT,
  children,
}) => (
  <div onClick={onClick} className={'badge-wrapper' + (onClick !== undefined ? ' clickable' : '')}>
    <ListItem
      id={id}
      className={`badge truncate animated fadeIn ${className}`}
      style={{ ...STYLE, ...style }}
    >
      {!!avatar && (
        <ListItemAvatar>
          <Avatar variant={avatarVariant}>{avatar}</Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        disableTypography={true}
        primary={
          <div className="badge-title truncate">
            {icon ? (
              <Icon src={icon} alt={iconAlt} showTooltip={true} className="m-0 mr-1" />
            ) : null}
            {title}
          </div>
        }
        secondary={<div className="info">{info}</div>}
      />
    </ListItem>
    {loading && (
      <ProgressMessage size={20} topRight={true} style={{ right: '20px', top: '15px' }} />
    )}
    {!!count && (
      <UnreadBadge
        unreadText={`${count}`}
        style={{ position: 'absolute', right: '20px', top: '10px', ...countStyle }}
      />
    )}
    {!!alerts && <div className="alerts">{alerts}</div>}
    {!!alert && (
      <Icon
        src={alertIcon || 'circle-exclamation'}
        showTooltip={true}
        iconStyle={
          alertStyle || {
            '--fa-primary-color': 'white',
            '--fa-secondary-color': '#d16f3e',
            '--fa-secondary-opacity': 1.0,
          }
        }
        tooltipPlace="left"
        alt={alert}
        width="20px"
        height="20px"
        style={{ position: 'absolute', right: '21px', top: '35px' }}
      />
    )}
    {children}
  </div>
);

GenericBadge.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.object,
  avatar: PropTypes.object,
  className: PropTypes.string,
  alert: PropTypes.string,
};

export default GenericBadge;
