/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicationReminderTake
 */
export interface MedicationReminderTake {
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderTake
     */
    value?: MedicationReminderTakeValueEnum;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderTake
     */
    date: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderTake
     */
    expected_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderTake
     */
    amount: number;
}


/**
 * @export
 */
export const MedicationReminderTakeValueEnum = {
    Taken: 'taken',
    Missed: 'missed'
} as const;
export type MedicationReminderTakeValueEnum = typeof MedicationReminderTakeValueEnum[keyof typeof MedicationReminderTakeValueEnum];


/**
 * Check if a given object implements the MedicationReminderTake interface.
 */
export function instanceOfMedicationReminderTake(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function MedicationReminderTakeFromJSON(json: any): MedicationReminderTake {
    return MedicationReminderTakeFromJSONTyped(json, false);
}

export function MedicationReminderTakeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminderTake {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'date': json['date'],
        'expected_date': !exists(json, 'expected_date') ? undefined : json['expected_date'],
        'amount': json['amount'],
    };
}

export function MedicationReminderTakeToJSON(value?: MedicationReminderTake | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'date': value.date,
        'expected_date': value.expected_date,
        'amount': value.amount,
    };
}

