/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Address,
  Appointment,
  BlogPost,
  BooleanResource,
  Call,
  CallLog,
  CanadaPostSearch,
  ClinicConfiguration,
  ClinicInfo,
  CommunicationNote,
  DebugMessage,
  ErrorResponse,
  FAQItem,
  ForwardHelpdeskMessagePayload,
  HelpdeskMessage,
  InfoArticle,
  MicrosoftNotification,
  MultipleDocumentPayload,
  PatientListItem,
  Resource,
  StatisticsEvent,
  StringResource,
  TimeOff,
  UserFeedback,
  UserTracking,
} from '../models';
import {
    AddressFromJSON,
    AddressToJSON,
    AppointmentFromJSON,
    AppointmentToJSON,
    BlogPostFromJSON,
    BlogPostToJSON,
    BooleanResourceFromJSON,
    BooleanResourceToJSON,
    CallFromJSON,
    CallToJSON,
    CallLogFromJSON,
    CallLogToJSON,
    CanadaPostSearchFromJSON,
    CanadaPostSearchToJSON,
    ClinicConfigurationFromJSON,
    ClinicConfigurationToJSON,
    ClinicInfoFromJSON,
    ClinicInfoToJSON,
    CommunicationNoteFromJSON,
    CommunicationNoteToJSON,
    DebugMessageFromJSON,
    DebugMessageToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FAQItemFromJSON,
    FAQItemToJSON,
    ForwardHelpdeskMessagePayloadFromJSON,
    ForwardHelpdeskMessagePayloadToJSON,
    HelpdeskMessageFromJSON,
    HelpdeskMessageToJSON,
    InfoArticleFromJSON,
    InfoArticleToJSON,
    MicrosoftNotificationFromJSON,
    MicrosoftNotificationToJSON,
    MultipleDocumentPayloadFromJSON,
    MultipleDocumentPayloadToJSON,
    PatientListItemFromJSON,
    PatientListItemToJSON,
    ResourceFromJSON,
    ResourceToJSON,
    StatisticsEventFromJSON,
    StatisticsEventToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
    TimeOffFromJSON,
    TimeOffToJSON,
    UserFeedbackFromJSON,
    UserFeedbackToJSON,
    UserTrackingFromJSON,
    UserTrackingToJSON,
} from '../models';

export interface AddPatientCommunicationNoteRequest {
    id: string;
    note: CommunicationNote;
}

export interface AddPatientListItemRequest {
    data?: PatientListItem;
}

export interface ArchiveHelpdeskMessageRequest {
    id: string;
}

export interface AssignHelpdeskMessageRequest {
    id: string;
    userId: string;
}

export interface BookTimeOffRequest {
    physicianId: string;
    slot: TimeOff;
}

export interface ClaimHelpdeskMessageRequest {
    id: string;
}

export interface EditClinicConfigurationRequest {
    id: string;
    _configuration: ClinicConfiguration;
}

export interface ForwardHelpdeskMessageRequest {
    id: string;
    data?: ForwardHelpdeskMessagePayload;
}

export interface GetApidazeCallXMLRequest {
    number?: string;
    bridge_status?: string;
    user_id?: string;
    user_role?: string;
    patient_id?: string;
    exiting?: string;
}

export interface GetBlogPostsRequest {
    limit?: number;
}

export interface GetCallsRequest {
    patientId: string;
}

export interface GetClinicConfigurationRequest {
    id: string;
}

export interface GetMicrosoftAuthUrlRequest {
    app: GetMicrosoftAuthUrlAppEnum;
}

export interface GetPatientCommunicationNotesRequest {
    id: string;
}

export interface GetPatientEmailsRequest {
    patientId: string;
}

export interface GetPhotoRequest {
    role: GetPhotoRoleEnum;
    id: string;
}

export interface GetResourceRequest {
    resource: Resource;
}

export interface GetShortUrlRequest {
    id: string;
}

export interface GetSpecificOwnClinicConfigurationRequest {
    key: GetSpecificOwnClinicConfigurationKeyEnum;
}

export interface GetStatisticsRequest {
    type?: GetStatisticsTypeEnum;
    from?: number;
    to?: number;
}

export interface MarkHelpdeskMessageReadRequest {
    id: string;
}

export interface MarkNotJunkHelpdeskMessagesRequest {
    id: string;
}

export interface MicrosoftAuthCompletedRequest {
    app: MicrosoftAuthCompletedAppEnum;
    code: StringResource;
    clinicId?: string;
    email?: string;
}

export interface NewMailNotificationRequest {
    id: string;
    validationToken?: string;
    data?: MicrosoftNotification;
}

export interface PostCodeInServiceAreaRequest {
    postcode: string;
}

export interface RemovePatientListItemRequest {
    id: string;
}

export interface ReplyHelpdeskMessageRequest {
    id: string;
    content: string;
    attachment1?: Blob;
    attachment2?: Blob;
    attachment3?: Blob;
    attachment4?: Blob;
    attachment5?: Blob;
    attachment6?: Blob;
}

export interface RetrieveAddressRequest {
    id: string;
}

export interface SearchAddressRequest {
    searchText: string;
    lastId?: string;
}

export interface SearchKnowledgeBaseRequest {
    query: string;
}

export interface SendPatientEmailRequest {
    patientId: string;
    documentId?: string;
    data?: MultipleDocumentPayload;
}

export interface SendQuestionnaireBySMSRequest {
    id: string;
}

export interface SendUserFeedbackRequest {
    feedback?: UserFeedback;
}

export interface SetClinicInfoRequest {
    info: ClinicInfo;
}

export interface SubmitCallLogRequest {
    log?: CallLog;
}

export interface SubmitDebugMessageRequest {
    message?: DebugMessage;
}

export interface TrackUserRequest {
    info: UserTracking;
}

export interface TransferHelpdeskMessageRequest {
    id: string;
    userId: string;
}

export interface UnarchiveHelpdeskMessageRequest {
    id: string;
}

export interface UploadPhotoRequest {
    role: UploadPhotoRoleEnum;
    id: string;
    file: Blob;
}

/**
 * 
 */
export class ResourceApi extends runtime.BaseAPI {

    /**
     * Adds a patient communication note (Roles: physician, admin)
     */
    async addPatientCommunicationNoteRaw(requestParameters: AddPatientCommunicationNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommunicationNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPatientCommunicationNote.');
        }

        if (requestParameters.note === null || requestParameters.note === undefined) {
            throw new runtime.RequiredError('note','Required parameter requestParameters.note was null or undefined when calling addPatientCommunicationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/communication/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommunicationNoteToJSON(requestParameters.note),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommunicationNoteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPatientCommunicationNoteRequestOptionsRaw(requestParameters: AddPatientCommunicationNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPatientCommunicationNote.');
        }

        if (requestParameters.note === null || requestParameters.note === undefined) {
            throw new runtime.RequiredError('note','Required parameter requestParameters.note was null or undefined when calling addPatientCommunicationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/communication/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CommunicationNoteToJSON(requestParameters.note),
        };
        return responseConfig
    }

    /**
     * Adds a patient communication note (Roles: physician, admin)
     */
    async addPatientCommunicationNote(requestParameters: AddPatientCommunicationNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommunicationNote> {
        const response = await this.addPatientCommunicationNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a patient list item (Roles: physician, admin)
     */
    async addPatientListItemRaw(requestParameters: AddPatientListItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientListItem>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/patient-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientListItemToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientListItemFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPatientListItemRequestOptionsRaw(requestParameters: AddPatientListItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/patient-list`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientListItemToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Adds a patient list item (Roles: physician, admin)
     */
    async addPatientListItem(requestParameters: AddPatientListItemRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientListItem> {
        const response = await this.addPatientListItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives helpdesk messages (Roles: admin)
     */
    async archiveHelpdeskMessageRaw(requestParameters: ArchiveHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    archiveHelpdeskMessageRequestOptionsRaw(requestParameters: ArchiveHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Archives helpdesk messages (Roles: admin)
     */
    async archiveHelpdeskMessage(requestParameters: ArchiveHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.archiveHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assigns a helpdesk message to a patient (Roles: admin)
     */
    async assignHelpdeskMessageRaw(requestParameters: AssignHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assignHelpdeskMessage.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling assignHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/assign/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    assignHelpdeskMessageRequestOptionsRaw(requestParameters: AssignHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assignHelpdeskMessage.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling assignHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/assign/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Assigns a helpdesk message to a patient (Roles: admin)
     */
    async assignHelpdeskMessage(requestParameters: AssignHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.assignHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Books a time off slot for physicians (Roles: admin, physician)
     */
    async bookTimeOffRaw(requestParameters: BookTimeOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Appointment>> {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling bookTimeOff.');
        }

        if (requestParameters.slot === null || requestParameters.slot === undefined) {
            throw new runtime.RequiredError('slot','Required parameter requestParameters.slot was null or undefined when calling bookTimeOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/time-off/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimeOffToJSON(requestParameters.slot),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    bookTimeOffRequestOptionsRaw(requestParameters: BookTimeOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling bookTimeOff.');
        }

        if (requestParameters.slot === null || requestParameters.slot === undefined) {
            throw new runtime.RequiredError('slot','Required parameter requestParameters.slot was null or undefined when calling bookTimeOff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/time-off/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: TimeOffToJSON(requestParameters.slot),
        };
        return responseConfig
    }

    /**
     * Books a time off slot for physicians (Roles: admin, physician)
     */
    async bookTimeOff(requestParameters: BookTimeOffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Appointment> {
        const response = await this.bookTimeOffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Claims a helpdesk message (Roles: admin)
     */
    async claimHelpdeskMessageRaw(requestParameters: ClaimHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling claimHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/claim`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    claimHelpdeskMessageRequestOptionsRaw(requestParameters: ClaimHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling claimHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/claim`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Claims a helpdesk message (Roles: admin)
     */
    async claimHelpdeskMessage(requestParameters: ClaimHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.claimHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits a clinic\' configuration (Roles: admin)
     */
    async editClinicConfigurationRaw(requestParameters: EditClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicConfiguration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editClinicConfiguration.');
        }

        if (requestParameters._configuration === null || requestParameters._configuration === undefined) {
            throw new runtime.RequiredError('_configuration','Required parameter requestParameters._configuration was null or undefined when calling editClinicConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/clinic/{id}/configuration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicConfigurationToJSON(requestParameters._configuration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicConfigurationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    editClinicConfigurationRequestOptionsRaw(requestParameters: EditClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editClinicConfiguration.');
        }

        if (requestParameters._configuration === null || requestParameters._configuration === undefined) {
            throw new runtime.RequiredError('_configuration','Required parameter requestParameters._configuration was null or undefined when calling editClinicConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/clinic/{id}/configuration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ClinicConfigurationToJSON(requestParameters._configuration),
        };
        return responseConfig
    }

    /**
     * Edits a clinic\' configuration (Roles: admin)
     */
    async editClinicConfiguration(requestParameters: EditClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicConfiguration> {
        const response = await this.editClinicConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Forwards a helpdesk message to an external email (Roles: admin)
     */
    async forwardHelpdeskMessageRaw(requestParameters: ForwardHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling forwardHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/forward`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForwardHelpdeskMessagePayloadToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    forwardHelpdeskMessageRequestOptionsRaw(requestParameters: ForwardHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling forwardHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/forward`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ForwardHelpdeskMessagePayloadToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Forwards a helpdesk message to an external email (Roles: admin)
     */
    async forwardHelpdeskMessage(requestParameters: ForwardHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.forwardHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the APIDaze call XML (Roles: none)
     */
    async getApidazeCallXMLRaw(requestParameters: GetApidazeCallXMLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.bridge_status !== undefined) {
            queryParameters['bridge_status'] = requestParameters.bridge_status;
        }

        if (requestParameters.user_id !== undefined) {
            queryParameters['user_id'] = requestParameters.user_id;
        }

        if (requestParameters.user_role !== undefined) {
            queryParameters['user_role'] = requestParameters.user_role;
        }

        if (requestParameters.patient_id !== undefined) {
            queryParameters['patient_id'] = requestParameters.patient_id;
        }

        if (requestParameters.exiting !== undefined) {
            queryParameters['exiting'] = requestParameters.exiting;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/apidaze/call`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
        Request Config Getter
     */
    getApidazeCallXMLRequestOptionsRaw(requestParameters: GetApidazeCallXMLRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.bridge_status !== undefined) {
            queryParameters['bridge_status'] = requestParameters.bridge_status;
        }

        if (requestParameters.user_id !== undefined) {
            queryParameters['user_id'] = requestParameters.user_id;
        }

        if (requestParameters.user_role !== undefined) {
            queryParameters['user_role'] = requestParameters.user_role;
        }

        if (requestParameters.patient_id !== undefined) {
            queryParameters['patient_id'] = requestParameters.patient_id;
        }

        if (requestParameters.exiting !== undefined) {
            queryParameters['exiting'] = requestParameters.exiting;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/apidaze/call`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the APIDaze call XML (Roles: none)
     */
    async getApidazeCallXML(requestParameters: GetApidazeCallXMLRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getApidazeCallXMLRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the APIDaze key (Roles: physician, admin)
     */
    async getApidazeKeyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/apidaze/key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getApidazeKeyRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/apidaze/key`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the APIDaze key (Roles: physician, admin)
     */
    async getApidazeKey(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.getApidazeKeyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get archived helpdesk messages (Roles: admin)
     */
    async getArchivedHelpdeskMessagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HelpdeskMessage>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/archived`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HelpdeskMessageFromJSON));
    }

    /**
        Request Config Getter
     */
    getArchivedHelpdeskMessagesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/archived`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Get archived helpdesk messages (Roles: admin)
     */
    async getArchivedHelpdeskMessages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HelpdeskMessage>> {
        const response = await this.getArchivedHelpdeskMessagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the latest blog posts (Roles: patient)
     */
    async getBlogPostsRaw(requestParameters: GetBlogPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BlogPost>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/blog/posts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BlogPostFromJSON));
    }

    /**
        Request Config Getter
     */
    getBlogPostsRequestOptionsRaw(requestParameters: GetBlogPostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/blog/posts`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the latest blog posts (Roles: patient)
     */
    async getBlogPosts(requestParameters: GetBlogPostsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BlogPost>> {
        const response = await this.getBlogPostsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets calls (Roles: admin, physician)
     */
    async getCallsRaw(requestParameters: GetCallsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Call>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getCalls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/calls/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CallFromJSON));
    }

    /**
        Request Config Getter
     */
    getCallsRequestOptionsRaw(requestParameters: GetCallsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getCalls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/calls/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets calls (Roles: admin, physician)
     */
    async getCalls(requestParameters: GetCallsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Call>> {
        const response = await this.getCallsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a clinic\' configuration (Roles: admin)
     */
    async getClinicConfigurationRaw(requestParameters: GetClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicConfiguration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClinicConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/clinic/{id}/configuration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicConfigurationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getClinicConfigurationRequestOptionsRaw(requestParameters: GetClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClinicConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/clinic/{id}/configuration`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a clinic\' configuration (Roles: admin)
     */
    async getClinicConfiguration(requestParameters: GetClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicConfiguration> {
        const response = await this.getClinicConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the user\'s Care& clinic\'s info (Roles: patient, physician, admin)
     */
    async getClinicInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/clinic-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicInfoFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getClinicInfoRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/clinic-info`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user\'s Care& clinic\'s info (Roles: patient, physician, admin)
     */
    async getClinicInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicInfo> {
        const response = await this.getClinicInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all Care& clinics\' info (Roles: none)
     */
    async getClinicsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClinicInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/clinics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicInfoFromJSON));
    }

    /**
        Request Config Getter
     */
    getClinicsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/clinics`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all Care& clinics\' info (Roles: none)
     */
    async getClinics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClinicInfo>> {
        const response = await this.getClinicsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a list of FAQ items (Roles: none)
     */
    async getFAQRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FAQItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/faq`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FAQItemFromJSON));
    }

    /**
        Request Config Getter
     */
    getFAQRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/faq`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a list of FAQ items (Roles: none)
     */
    async getFAQ(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FAQItem>> {
        const response = await this.getFAQRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get junk helpdesk messages (Roles: admin)
     */
    async getJunkHelpdeskMessagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HelpdeskMessage>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/junk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HelpdeskMessageFromJSON));
    }

    /**
        Request Config Getter
     */
    getJunkHelpdeskMessagesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/junk`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Get junk helpdesk messages (Roles: admin)
     */
    async getJunkHelpdeskMessages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HelpdeskMessage>> {
        const response = await this.getJunkHelpdeskMessagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the Microsoft authentication URL to start the OAuth process (Roles: admin)
     */
    async getMicrosoftAuthUrlRaw(requestParameters: GetMicrosoftAuthUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.app === null || requestParameters.app === undefined) {
            throw new runtime.RequiredError('app','Required parameter requestParameters.app was null or undefined when calling getMicrosoftAuthUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/auth-url/{app}`.replace(`{${"app"}}`, encodeURIComponent(String(requestParameters.app))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getMicrosoftAuthUrlRequestOptionsRaw(requestParameters: GetMicrosoftAuthUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.app === null || requestParameters.app === undefined) {
            throw new runtime.RequiredError('app','Required parameter requestParameters.app was null or undefined when calling getMicrosoftAuthUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/auth-url/{app}`.replace(`{${"app"}}`, encodeURIComponent(String(requestParameters.app))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the Microsoft authentication URL to start the OAuth process (Roles: admin)
     */
    async getMicrosoftAuthUrl(requestParameters: GetMicrosoftAuthUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.getMicrosoftAuthUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a patient\'s communication notes (Roles: physician, admin)
     */
    async getPatientCommunicationNotesRaw(requestParameters: GetPatientCommunicationNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CommunicationNote>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPatientCommunicationNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/communication/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommunicationNoteFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientCommunicationNotesRequestOptionsRaw(requestParameters: GetPatientCommunicationNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPatientCommunicationNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/communication/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a patient\'s communication notes (Roles: physician, admin)
     */
    async getPatientCommunicationNotes(requestParameters: GetPatientCommunicationNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CommunicationNote>> {
        const response = await this.getPatientCommunicationNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all patient emails (Roles: admin, physician)
     */
    async getPatientEmailsRaw(requestParameters: GetPatientEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HelpdeskMessage>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPatientEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HelpdeskMessageFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientEmailsRequestOptionsRaw(requestParameters: GetPatientEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPatientEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all patient emails (Roles: admin, physician)
     */
    async getPatientEmails(requestParameters: GetPatientEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HelpdeskMessage>> {
        const response = await this.getPatientEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all patient list items (Roles: admin, physician)
     */
    async getPatientListItemsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientListItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/patient-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientListItemFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientListItemsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/patient-list`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all patient list items (Roles: admin, physician)
     */
    async getPatientListItems(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientListItem>> {
        const response = await this.getPatientListItemsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a profile photo (Roles: patient, physician, admin)
     */
    async getPhotoRaw(requestParameters: GetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getPhoto.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/profile-photo/{role}/{id}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getPhotoRequestOptionsRaw(requestParameters: GetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getPhoto.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/profile-photo/{role}/{id}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a profile photo (Roles: patient, physician, admin)
     */
    async getPhoto(requestParameters: GetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a resource based on an URL (Roles: admin, physician)
     */
    async getResourceRaw(requestParameters: GetResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling getResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getResourceRequestOptionsRaw(requestParameters: GetResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling getResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        };
        return responseConfig
    }

    /**
     * Gets a resource based on an URL (Roles: admin, physician)
     */
    async getResource(requestParameters: GetResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a short URL resource (Roles: none)
     */
    async getShortUrlRaw(requestParameters: GetShortUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShortUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/url/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getShortUrlRequestOptionsRaw(requestParameters: GetShortUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShortUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/url/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a short URL resource (Roles: none)
     */
    async getShortUrl(requestParameters: GetShortUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getShortUrlRaw(requestParameters, initOverrides);
    }

    /**
     * Gets the user\'s specific clinic configuration based on a key (Roles: admin, physician)
     */
    async getSpecificOwnClinicConfigurationRaw(requestParameters: GetSpecificOwnClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getSpecificOwnClinicConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/clinic-info/configuration/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getSpecificOwnClinicConfigurationRequestOptionsRaw(requestParameters: GetSpecificOwnClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getSpecificOwnClinicConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/clinic-info/configuration/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user\'s specific clinic configuration based on a key (Roles: admin, physician)
     */
    async getSpecificOwnClinicConfiguration(requestParameters: GetSpecificOwnClinicConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.getSpecificOwnClinicConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all BE statistics (Roles: admin)
     */
    async getStatisticsRaw(requestParameters: GetStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StatisticsEvent>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StatisticsEventFromJSON));
    }

    /**
        Request Config Getter
     */
    getStatisticsRequestOptionsRaw(requestParameters: GetStatisticsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/statistics`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all BE statistics (Roles: admin)
     */
    async getStatistics(requestParameters: GetStatisticsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StatisticsEvent>> {
        const response = await this.getStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a description of the subscription (Roles: patient)
     */
    async getSubscriptionInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/subscription-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
        Request Config Getter
     */
    getSubscriptionInfoRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/subscription-info`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a description of the subscription (Roles: patient)
     */
    async getSubscriptionInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSubscriptionInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get unarchived helpdesk messages (Roles: admin)
     */
    async getUnarchivedHelpdeskMessagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HelpdeskMessage>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HelpdeskMessageFromJSON));
    }

    /**
        Request Config Getter
     */
    getUnarchivedHelpdeskMessagesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Get unarchived helpdesk messages (Roles: admin)
     */
    async getUnarchivedHelpdeskMessages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HelpdeskMessage>> {
        const response = await this.getUnarchivedHelpdeskMessagesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Marks helpdesk message read (Roles: admin)
     */
    async markHelpdeskMessageReadRaw(requestParameters: MarkHelpdeskMessageReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markHelpdeskMessageRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/mark-read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    markHelpdeskMessageReadRequestOptionsRaw(requestParameters: MarkHelpdeskMessageReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markHelpdeskMessageRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/mark-read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Marks helpdesk message read (Roles: admin)
     */
    async markHelpdeskMessageRead(requestParameters: MarkHelpdeskMessageReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.markHelpdeskMessageReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks message as not spam (Roles: admin)
     */
    async markNotJunkHelpdeskMessagesRaw(requestParameters: MarkNotJunkHelpdeskMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markNotJunkHelpdeskMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/junk/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    markNotJunkHelpdeskMessagesRequestOptionsRaw(requestParameters: MarkNotJunkHelpdeskMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markNotJunkHelpdeskMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/junk/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Marks message as not spam (Roles: admin)
     */
    async markNotJunkHelpdeskMessages(requestParameters: MarkNotJunkHelpdeskMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.markNotJunkHelpdeskMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Completes the Microsoft OAuth process (Roles: physician)
     */
    async microsoftAuthCompletedRaw(requestParameters: MicrosoftAuthCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.app === null || requestParameters.app === undefined) {
            throw new runtime.RequiredError('app','Required parameter requestParameters.app was null or undefined when calling microsoftAuthCompleted.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling microsoftAuthCompleted.');
        }

        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/auth-completed/{app}`.replace(`{${"app"}}`, encodeURIComponent(String(requestParameters.app))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.code),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    microsoftAuthCompletedRequestOptionsRaw(requestParameters: MicrosoftAuthCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.app === null || requestParameters.app === undefined) {
            throw new runtime.RequiredError('app','Required parameter requestParameters.app was null or undefined when calling microsoftAuthCompleted.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling microsoftAuthCompleted.');
        }

        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/auth-completed/{app}`.replace(`{${"app"}}`, encodeURIComponent(String(requestParameters.app))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.code),
        };
        return responseConfig
    }

    /**
     * Completes the Microsoft OAuth process (Roles: physician)
     */
    async microsoftAuthCompleted(requestParameters: MicrosoftAuthCompletedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.microsoftAuthCompletedRaw(requestParameters, initOverrides);
    }

    /**
     * Receives a notification from Microsoft on received emails (Roles: none)
     */
    async newMailNotificationRaw(requestParameters: NewMailNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling newMailNotification.');
        }

        const queryParameters: any = {};

        if (requestParameters.validationToken !== undefined) {
            queryParameters['validationToken'] = requestParameters.validationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/new-mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MicrosoftNotificationToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    newMailNotificationRequestOptionsRaw(requestParameters: NewMailNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling newMailNotification.');
        }

        const queryParameters: any = {};

        if (requestParameters.validationToken !== undefined) {
            queryParameters['validationToken'] = requestParameters.validationToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/new-mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MicrosoftNotificationToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Receives a notification from Microsoft on received emails (Roles: none)
     */
    async newMailNotification(requestParameters: NewMailNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.newMailNotificationRaw(requestParameters, initOverrides);
    }

    /**
     * Checks if postcode is included in the service area (Roles: none)
     */
    async postCodeInServiceAreaRaw(requestParameters: PostCodeInServiceAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResource>> {
        if (requestParameters.postcode === null || requestParameters.postcode === undefined) {
            throw new runtime.RequiredError('postcode','Required parameter requestParameters.postcode was null or undefined when calling postCodeInServiceArea.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/service-area/valid/{postcode}`.replace(`{${"postcode"}}`, encodeURIComponent(String(requestParameters.postcode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    postCodeInServiceAreaRequestOptionsRaw(requestParameters: PostCodeInServiceAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.postcode === null || requestParameters.postcode === undefined) {
            throw new runtime.RequiredError('postcode','Required parameter requestParameters.postcode was null or undefined when calling postCodeInServiceArea.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/service-area/valid/{postcode}`.replace(`{${"postcode"}}`, encodeURIComponent(String(requestParameters.postcode))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Checks if postcode is included in the service area (Roles: none)
     */
    async postCodeInServiceArea(requestParameters: PostCodeInServiceAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResource> {
        const response = await this.postCodeInServiceAreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a patient list item (Roles: physician, admin)
     */
    async removePatientListItemRaw(requestParameters: RemovePatientListItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePatientListItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/patient-list/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removePatientListItemRequestOptionsRaw(requestParameters: RemovePatientListItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePatientListItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/patient-list/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a patient list item (Roles: physician, admin)
     */
    async removePatientListItem(requestParameters: RemovePatientListItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePatientListItemRaw(requestParameters, initOverrides);
    }

    /**
     * Replies to helpdesk messages (Roles: admin)
     */
    async replyHelpdeskMessageRaw(requestParameters: ReplyHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling replyHelpdeskMessage.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling replyHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        if (requestParameters.attachment1 !== undefined) {
            formParams.append('attachment1', requestParameters.attachment1 as any);
        }

        if (requestParameters.attachment2 !== undefined) {
            formParams.append('attachment2', requestParameters.attachment2 as any);
        }

        if (requestParameters.attachment3 !== undefined) {
            formParams.append('attachment3', requestParameters.attachment3 as any);
        }

        if (requestParameters.attachment4 !== undefined) {
            formParams.append('attachment4', requestParameters.attachment4 as any);
        }

        if (requestParameters.attachment5 !== undefined) {
            formParams.append('attachment5', requestParameters.attachment5 as any);
        }

        if (requestParameters.attachment6 !== undefined) {
            formParams.append('attachment6', requestParameters.attachment6 as any);
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/reply`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    replyHelpdeskMessageRequestOptionsRaw(requestParameters: ReplyHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling replyHelpdeskMessage.');
        }

        if (requestParameters.content === null || requestParameters.content === undefined) {
            throw new runtime.RequiredError('content','Required parameter requestParameters.content was null or undefined when calling replyHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.content !== undefined) {
            formParams.append('content', requestParameters.content as any);
        }

        if (requestParameters.attachment1 !== undefined) {
            formParams.append('attachment1', requestParameters.attachment1 as any);
        }

        if (requestParameters.attachment2 !== undefined) {
            formParams.append('attachment2', requestParameters.attachment2 as any);
        }

        if (requestParameters.attachment3 !== undefined) {
            formParams.append('attachment3', requestParameters.attachment3 as any);
        }

        if (requestParameters.attachment4 !== undefined) {
            formParams.append('attachment4', requestParameters.attachment4 as any);
        }

        if (requestParameters.attachment5 !== undefined) {
            formParams.append('attachment5', requestParameters.attachment5 as any);
        }

        if (requestParameters.attachment6 !== undefined) {
            formParams.append('attachment6', requestParameters.attachment6 as any);
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/reply`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Replies to helpdesk messages (Roles: admin)
     */
    async replyHelpdeskMessage(requestParameters: ReplyHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.replyHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves an address using CanadaPost (Roles: patient, physician, admin)
     */
    async retrieveAddressRaw(requestParameters: RetrieveAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/address/retrieve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    retrieveAddressRequestOptionsRaw(requestParameters: RetrieveAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/address/retrieve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Retrieves an address using CanadaPost (Roles: patient, physician, admin)
     */
    async retrieveAddress(requestParameters: RetrieveAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Address> {
        const response = await this.retrieveAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for an address using CanadaPost (Roles: patient, physician, admin)
     */
    async searchAddressRaw(requestParameters: SearchAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CanadaPostSearch>>> {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText','Required parameter requestParameters.searchText was null or undefined when calling searchAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastId !== undefined) {
            queryParameters['lastId'] = requestParameters.lastId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/address/search/{searchText}`.replace(`{${"searchText"}}`, encodeURIComponent(String(requestParameters.searchText))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CanadaPostSearchFromJSON));
    }

    /**
        Request Config Getter
     */
    searchAddressRequestOptionsRaw(requestParameters: SearchAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.searchText === null || requestParameters.searchText === undefined) {
            throw new runtime.RequiredError('searchText','Required parameter requestParameters.searchText was null or undefined when calling searchAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.lastId !== undefined) {
            queryParameters['lastId'] = requestParameters.lastId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/address/search/{searchText}`.replace(`{${"searchText"}}`, encodeURIComponent(String(requestParameters.searchText))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Searches for an address using CanadaPost (Roles: patient, physician, admin)
     */
    async searchAddress(requestParameters: SearchAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CanadaPostSearch>> {
        const response = await this.searchAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches the knowledge base (Roles: patient, none)
     */
    async searchKnowledgeBaseRaw(requestParameters: SearchKnowledgeBaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InfoArticle>>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling searchKnowledgeBase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/knowledge-base/search/{query}`.replace(`{${"query"}}`, encodeURIComponent(String(requestParameters.query))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfoArticleFromJSON));
    }

    /**
        Request Config Getter
     */
    searchKnowledgeBaseRequestOptionsRaw(requestParameters: SearchKnowledgeBaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling searchKnowledgeBase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/knowledge-base/search/{query}`.replace(`{${"query"}}`, encodeURIComponent(String(requestParameters.query))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Searches the knowledge base (Roles: patient, none)
     */
    async searchKnowledgeBase(requestParameters: SearchKnowledgeBaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InfoArticle>> {
        const response = await this.searchKnowledgeBaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends en email relative to this patient (Roles: physician, admin)
     */
    async sendPatientEmailRaw(requestParameters: SendPatientEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling sendPatientEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleDocumentPayloadToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    sendPatientEmailRequestOptionsRaw(requestParameters: SendPatientEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling sendPatientEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MultipleDocumentPayloadToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Sends en email relative to this patient (Roles: physician, admin)
     */
    async sendPatientEmail(requestParameters: SendPatientEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.sendPatientEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends the questionnaire to the patient by SMS (Roles: admin)
     */
    async sendQuestionnaireBySMSRaw(requestParameters: SendQuestionnaireBySMSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendQuestionnaireBySMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/questionnaire-by-sms/appointment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendQuestionnaireBySMSRequestOptionsRaw(requestParameters: SendQuestionnaireBySMSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendQuestionnaireBySMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/questionnaire-by-sms/appointment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Sends the questionnaire to the patient by SMS (Roles: admin)
     */
    async sendQuestionnaireBySMS(requestParameters: SendQuestionnaireBySMSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendQuestionnaireBySMSRaw(requestParameters, initOverrides);
    }

    /**
     * Sends a user feedback (Roles: none)
     */
    async sendUserFeedbackRaw(requestParameters: SendUserFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/user-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserFeedbackToJSON(requestParameters.feedback),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    sendUserFeedbackRequestOptionsRaw(requestParameters: SendUserFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/user-feedback`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: UserFeedbackToJSON(requestParameters.feedback),
        };
        return responseConfig
    }

    /**
     * Sends a user feedback (Roles: none)
     */
    async sendUserFeedback(requestParameters: SendUserFeedbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.sendUserFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Changes or adds a Care& clinic\'s info (Roles: admin)
     */
    async setClinicInfoRaw(requestParameters: SetClinicInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicInfo>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling setClinicInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/clinic-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClinicInfoToJSON(requestParameters.info),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicInfoFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    setClinicInfoRequestOptionsRaw(requestParameters: SetClinicInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling setClinicInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/clinic-info`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ClinicInfoToJSON(requestParameters.info),
        };
        return responseConfig
    }

    /**
     * Changes or adds a Care& clinic\'s info (Roles: admin)
     */
    async setClinicInfo(requestParameters: SetClinicInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicInfo> {
        const response = await this.setClinicInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submits a telemedicine call log (Roles: patient, physician, admin)
     */
    async submitCallLogRaw(requestParameters: SubmitCallLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Call>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/call-log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CallLogToJSON(requestParameters.log),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CallFromJSON));
    }

    /**
        Request Config Getter
     */
    submitCallLogRequestOptionsRaw(requestParameters: SubmitCallLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/call-log`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CallLogToJSON(requestParameters.log),
        };
        return responseConfig
    }

    /**
     * Submits a telemedicine call log (Roles: patient, physician, admin)
     */
    async submitCallLog(requestParameters: SubmitCallLogRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Call>> {
        const response = await this.submitCallLogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submits a developer debug message (Roles: patient, admin, physician)
     */
    async submitDebugMessageRaw(requestParameters: SubmitDebugMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/debug`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DebugMessageToJSON(requestParameters.message),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    submitDebugMessageRequestOptionsRaw(requestParameters: SubmitDebugMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/debug`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DebugMessageToJSON(requestParameters.message),
        };
        return responseConfig
    }

    /**
     * Submits a developer debug message (Roles: patient, admin, physician)
     */
    async submitDebugMessage(requestParameters: SubmitDebugMessageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitDebugMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Track a user (Roles: none, patient)
     */
    async trackUserRaw(requestParameters: TrackUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserTracking>> {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling trackUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/track`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserTrackingToJSON(requestParameters.info),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTrackingFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    trackUserRequestOptionsRaw(requestParameters: TrackUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.info === null || requestParameters.info === undefined) {
            throw new runtime.RequiredError('info','Required parameter requestParameters.info was null or undefined when calling trackUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/track`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: UserTrackingToJSON(requestParameters.info),
        };
        return responseConfig
    }

    /**
     * Track a user (Roles: none, patient)
     */
    async trackUser(requestParameters: TrackUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserTracking> {
        const response = await this.trackUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Transfers a helpdesk message to a staff (Roles: admin)
     */
    async transferHelpdeskMessageRaw(requestParameters: TransferHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transferHelpdeskMessage.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling transferHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/transfer/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    transferHelpdeskMessageRequestOptionsRaw(requestParameters: TransferHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling transferHelpdeskMessage.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling transferHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/transfer/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Transfers a helpdesk message to a staff (Roles: admin)
     */
    async transferHelpdeskMessage(requestParameters: TransferHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.transferHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unarchives a helpdesk messages (Roles: admin)
     */
    async unarchiveHelpdeskMessageRaw(requestParameters: UnarchiveHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpdeskMessage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unarchiveHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/resource/helpdesk/{id}/unarchive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpdeskMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    unarchiveHelpdeskMessageRequestOptionsRaw(requestParameters: UnarchiveHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unarchiveHelpdeskMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/resource/helpdesk/{id}/unarchive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Unarchives a helpdesk messages (Roles: admin)
     */
    async unarchiveHelpdeskMessage(requestParameters: UnarchiveHelpdeskMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpdeskMessage> {
        const response = await this.unarchiveHelpdeskMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a profile photo in JPEG format (Roles: patient, physician, admin)
     */
    async uploadPhotoRaw(requestParameters: UploadPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling uploadPhoto.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadPhoto.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/resource/profile-photo/{role}/{id}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    uploadPhotoRequestOptionsRaw(requestParameters: UploadPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling uploadPhoto.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadPhoto.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        const responseConfig = {
            path: `/resource/profile-photo/{role}/{id}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Upload a profile photo in JPEG format (Roles: patient, physician, admin)
     */
    async uploadPhoto(requestParameters: UploadPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.uploadPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetMicrosoftAuthUrlAppEnum = {
    Outlook: 'outlook'
} as const;
export type GetMicrosoftAuthUrlAppEnum = typeof GetMicrosoftAuthUrlAppEnum[keyof typeof GetMicrosoftAuthUrlAppEnum];
/**
 * @export
 */
export const GetPhotoRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type GetPhotoRoleEnum = typeof GetPhotoRoleEnum[keyof typeof GetPhotoRoleEnum];
/**
 * @export
 */
export const GetSpecificOwnClinicConfigurationKeyEnum = {
    LifelabsContract: 'lifelabs_contract',
    VitalMonitors: 'vital_monitors'
} as const;
export type GetSpecificOwnClinicConfigurationKeyEnum = typeof GetSpecificOwnClinicConfigurationKeyEnum[keyof typeof GetSpecificOwnClinicConfigurationKeyEnum];
/**
 * @export
 */
export const GetStatisticsTypeEnum = {
    Unsubscription: 'unsubscription',
    Subscription: 'subscription',
    UnsubscriptionPaymentFailed: 'unsubscription-payment_failed'
} as const;
export type GetStatisticsTypeEnum = typeof GetStatisticsTypeEnum[keyof typeof GetStatisticsTypeEnum];
/**
 * @export
 */
export const MicrosoftAuthCompletedAppEnum = {
    Outlook: 'outlook'
} as const;
export type MicrosoftAuthCompletedAppEnum = typeof MicrosoftAuthCompletedAppEnum[keyof typeof MicrosoftAuthCompletedAppEnum];
/**
 * @export
 */
export const UploadPhotoRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type UploadPhotoRoleEnum = typeof UploadPhotoRoleEnum[keyof typeof UploadPhotoRoleEnum];
