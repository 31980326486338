/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorporationUnpaidBatch
 */
export interface CorporationUnpaidBatch {
    /**
     * 
     * @type {string}
     * @memberof CorporationUnpaidBatch
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CorporationUnpaidBatch
     */
    unpaid_batches?: Array<string>;
}

/**
 * Check if a given object implements the CorporationUnpaidBatch interface.
 */
export function instanceOfCorporationUnpaidBatch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CorporationUnpaidBatchFromJSON(json: any): CorporationUnpaidBatch {
    return CorporationUnpaidBatchFromJSONTyped(json, false);
}

export function CorporationUnpaidBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporationUnpaidBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'unpaid_batches': !exists(json, 'unpaid_batches') ? undefined : json['unpaid_batches'],
    };
}

export function CorporationUnpaidBatchToJSON(value?: CorporationUnpaidBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'unpaid_batches': value.unpaid_batches,
    };
}

