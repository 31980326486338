/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { HoursOperation } from './HoursOperation';
import {
    HoursOperationFromJSON,
    HoursOperationFromJSONTyped,
    HoursOperationToJSON,
} from './HoursOperation';

/**
 * 
 * @export
 * @interface ClinicInfo
 */
export interface ClinicInfo {
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    id?: string;
    /**
     * 
     * @type {HoursOperation}
     * @memberof ClinicInfo
     */
    hours?: HoursOperation;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    website?: string;
    /**
     * 
     * @type {Address}
     * @memberof ClinicInfo
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    google_url?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    google_maps_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicInfo
     */
    rooms?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicInfo
     */
    hide?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClinicInfo
     */
    date_start?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    availability?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicInfo
     */
    clinical_director_id?: string;
}

/**
 * Check if a given object implements the ClinicInfo interface.
 */
export function instanceOfClinicInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClinicInfoFromJSON(json: any): ClinicInfo {
    return ClinicInfoFromJSONTyped(json, false);
}

export function ClinicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hours': !exists(json, 'hours') ? undefined : HoursOperationFromJSON(json['hours']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'google_url': !exists(json, 'google_url') ? undefined : json['google_url'],
        'google_maps_url': !exists(json, 'google_maps_url') ? undefined : json['google_maps_url'],
        'rooms': !exists(json, 'rooms') ? undefined : json['rooms'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'date_start': !exists(json, 'date_start') ? undefined : json['date_start'],
        'availability': !exists(json, 'availability') ? undefined : json['availability'],
        'clinical_director_id': !exists(json, 'clinical_director_id') ? undefined : json['clinical_director_id'],
    };
}

export function ClinicInfoToJSON(value?: ClinicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hours': HoursOperationToJSON(value.hours),
        'message': value.message,
        'name': value.name,
        'phone': value.phone,
        'fax': value.fax,
        'email': value.email,
        'website': value.website,
        'address': AddressToJSON(value.address),
        'google_url': value.google_url,
        'google_maps_url': value.google_maps_url,
        'rooms': value.rooms,
        'hide': value.hide,
        'date_start': value.date_start,
        'availability': value.availability,
        'clinical_director_id': value.clinical_director_id,
    };
}

