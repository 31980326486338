/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiagnosticResultLine } from './DiagnosticResultLine';
import {
    DiagnosticResultLineFromJSON,
    DiagnosticResultLineFromJSONTyped,
    DiagnosticResultLineToJSON,
} from './DiagnosticResultLine';
import type { FeeLine } from './FeeLine';
import {
    FeeLineFromJSON,
    FeeLineFromJSONTyped,
    FeeLineToJSON,
} from './FeeLine';
import type { OCRPatientId } from './OCRPatientId';
import {
    OCRPatientIdFromJSON,
    OCRPatientIdFromJSONTyped,
    OCRPatientIdToJSON,
} from './OCRPatientId';
import type { PatientIssueValue } from './PatientIssueValue';
import {
    PatientIssueValueFromJSON,
    PatientIssueValueFromJSONTyped,
    PatientIssueValueToJSON,
} from './PatientIssueValue';
import type { PrescriptionMedication } from './PrescriptionMedication';
import {
    PrescriptionMedicationFromJSON,
    PrescriptionMedicationFromJSONTyped,
    PrescriptionMedicationToJSON,
} from './PrescriptionMedication';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
} from './Referral';
import type { RepeatOptions } from './RepeatOptions';
import {
    RepeatOptionsFromJSON,
    RepeatOptionsFromJSONTyped,
    RepeatOptionsToJSON,
} from './RepeatOptions';
import type { TaskComment } from './TaskComment';
import {
    TaskCommentFromJSON,
    TaskCommentFromJSONTyped,
    TaskCommentToJSON,
} from './TaskComment';

/**
 * 
 * @export
 * @interface PatientDocument
 */
export interface PatientDocument {
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    filename?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    thumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    document_type?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    route?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientDocument
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDocument
     */
    date_updated?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDocument
     */
    date_service?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientDocument
     */
    date_check_referral?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    direction?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    private_comments?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    linked_to?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatientDocument
     */
    linked_documents?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    hide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    template_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    template_metadata?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    physician_id_approved?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    clinic_id_approved?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    clinic_id_archived?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    duplicate_of?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    processing?: boolean;
    /**
     * 
     * @type {Array<DiagnosticResultLine>}
     * @memberof PatientDocument
     */
    results_text?: Array<DiagnosticResultLine>;
    /**
     * 
     * @type {Referral}
     * @memberof PatientDocument
     */
    referral?: Referral;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    referral_no_tracking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    referral_status?: PatientDocumentReferralStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    referral_specialty?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    referral_provider?: string;
    /**
     * 
     * @type {PatientIssueValue}
     * @memberof PatientDocument
     */
    preventative_screening?: PatientIssueValue;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    change_linked_referral_status?: PatientDocumentChangeLinkedReferralStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    send_to_patient?: boolean;
    /**
     * 
     * @type {Array<TaskComment>}
     * @memberof PatientDocument
     */
    referral_comments?: Array<TaskComment>;
    /**
     * 
     * @type {Array<OCRPatientId>}
     * @memberof PatientDocument
     */
    ocr_patient_ids?: Array<OCRPatientId>;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    hl7?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientDocument
     */
    fee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    fee_hst?: boolean;
    /**
     * 
     * @type {Array<FeeLine>}
     * @memberof PatientDocument
     */
    fee_lines?: Array<FeeLine>;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    has_password?: boolean;
    /**
     * 
     * @type {Array<PrescriptionMedication>}
     * @memberof PatientDocument
     */
    medications?: Array<PrescriptionMedication>;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    pending_patient_fill?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    user_task_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    approve_on_filled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    unread_by_physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    fax_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    has_repeat?: boolean;
    /**
     * 
     * @type {RepeatOptions}
     * @memberof PatientDocument
     */
    repeat_options?: RepeatOptions;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    classification?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    notification_text?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientDocument
     */
    reminder_date?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientDocument
     */
    reminder_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientDocument
     */
    pinned?: boolean;
    /**
     * 
     * @type {Array<TaskComment>}
     * @memberof PatientDocument
     */
    general_comments?: Array<TaskComment>;
}


/**
 * @export
 */
export const PatientDocumentReferralStatusEnum = {
    PendingSend: 'Pending Send',
    PendingReply: 'Pending Reply',
    Overdue: 'Overdue',
    Completed: 'Completed',
    Rejected: 'Rejected'
} as const;
export type PatientDocumentReferralStatusEnum = typeof PatientDocumentReferralStatusEnum[keyof typeof PatientDocumentReferralStatusEnum];

/**
 * @export
 */
export const PatientDocumentChangeLinkedReferralStatusEnum = {
    PendingSend: 'Pending Send',
    PendingReply: 'Pending Reply',
    Overdue: 'Overdue',
    Completed: 'Completed',
    Rejected: 'Rejected'
} as const;
export type PatientDocumentChangeLinkedReferralStatusEnum = typeof PatientDocumentChangeLinkedReferralStatusEnum[keyof typeof PatientDocumentChangeLinkedReferralStatusEnum];


/**
 * Check if a given object implements the PatientDocument interface.
 */
export function instanceOfPatientDocument(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatientDocumentFromJSON(json: any): PatientDocument {
    return PatientDocumentFromJSONTyped(json, false);
}

export function PatientDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'document_type': !exists(json, 'document_type') ? undefined : json['document_type'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'date_updated': !exists(json, 'date_updated') ? undefined : json['date_updated'],
        'date_service': !exists(json, 'date_service') ? undefined : json['date_service'],
        'date_check_referral': !exists(json, 'date_check_referral') ? undefined : json['date_check_referral'],
        'direction': !exists(json, 'direction') ? undefined : json['direction'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'private_comments': !exists(json, 'private_comments') ? undefined : json['private_comments'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'linked_to': !exists(json, 'linked_to') ? undefined : json['linked_to'],
        'linked_documents': !exists(json, 'linked_documents') ? undefined : json['linked_documents'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'template_id': !exists(json, 'template_id') ? undefined : json['template_id'],
        'template_metadata': !exists(json, 'template_metadata') ? undefined : json['template_metadata'],
        'physician_id_approved': !exists(json, 'physician_id_approved') ? undefined : json['physician_id_approved'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'clinic_id_approved': !exists(json, 'clinic_id_approved') ? undefined : json['clinic_id_approved'],
        'clinic_id_archived': !exists(json, 'clinic_id_archived') ? undefined : json['clinic_id_archived'],
        'external_id': !exists(json, 'external_id') ? undefined : json['external_id'],
        'duplicate_of': !exists(json, 'duplicate_of') ? undefined : json['duplicate_of'],
        'processing': !exists(json, 'processing') ? undefined : json['processing'],
        'results_text': !exists(json, 'results_text') ? undefined : ((json['results_text'] as Array<any>).map(DiagnosticResultLineFromJSON)),
        'referral': !exists(json, 'referral') ? undefined : ReferralFromJSON(json['referral']),
        'referral_no_tracking': !exists(json, 'referral_no_tracking') ? undefined : json['referral_no_tracking'],
        'referral_status': !exists(json, 'referral_status') ? undefined : json['referral_status'],
        'referral_specialty': !exists(json, 'referral_specialty') ? undefined : json['referral_specialty'],
        'referral_provider': !exists(json, 'referral_provider') ? undefined : json['referral_provider'],
        'preventative_screening': !exists(json, 'preventative_screening') ? undefined : PatientIssueValueFromJSON(json['preventative_screening']),
        'change_linked_referral_status': !exists(json, 'change_linked_referral_status') ? undefined : json['change_linked_referral_status'],
        'send_to_patient': !exists(json, 'send_to_patient') ? undefined : json['send_to_patient'],
        'referral_comments': !exists(json, 'referral_comments') ? undefined : ((json['referral_comments'] as Array<any>).map(TaskCommentFromJSON)),
        'ocr_patient_ids': !exists(json, 'ocr_patient_ids') ? undefined : ((json['ocr_patient_ids'] as Array<any>).map(OCRPatientIdFromJSON)),
        'hl7': !exists(json, 'hl7') ? undefined : json['hl7'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'fee_hst': !exists(json, 'fee_hst') ? undefined : json['fee_hst'],
        'fee_lines': !exists(json, 'fee_lines') ? undefined : ((json['fee_lines'] as Array<any>).map(FeeLineFromJSON)),
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'has_password': !exists(json, 'has_password') ? undefined : json['has_password'],
        'medications': !exists(json, 'medications') ? undefined : ((json['medications'] as Array<any>).map(PrescriptionMedicationFromJSON)),
        'pending_patient_fill': !exists(json, 'pending_patient_fill') ? undefined : json['pending_patient_fill'],
        'user_task_id': !exists(json, 'user_task_id') ? undefined : json['user_task_id'],
        'approve_on_filled': !exists(json, 'approve_on_filled') ? undefined : json['approve_on_filled'],
        'unread_by_physician_id': !exists(json, 'unread_by_physician_id') ? undefined : json['unread_by_physician_id'],
        'fax_id': !exists(json, 'fax_id') ? undefined : json['fax_id'],
        'has_repeat': !exists(json, 'has_repeat') ? undefined : json['has_repeat'],
        'repeat_options': !exists(json, 'repeat_options') ? undefined : RepeatOptionsFromJSON(json['repeat_options']),
        'html': !exists(json, 'html') ? undefined : json['html'],
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'notification_text': !exists(json, 'notification_text') ? undefined : json['notification_text'],
        'reminder_date': !exists(json, 'reminder_date') ? undefined : json['reminder_date'],
        'reminder_text': !exists(json, 'reminder_text') ? undefined : json['reminder_text'],
        'pinned': !exists(json, 'pinned') ? undefined : json['pinned'],
        'general_comments': !exists(json, 'general_comments') ? undefined : ((json['general_comments'] as Array<any>).map(TaskCommentFromJSON)),
    };
}

export function PatientDocumentToJSON(value?: PatientDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'from': value.from,
        'to': value.to,
        'filename': value.filename,
        'thumbnail': value.thumbnail,
        'document_type': value.document_type,
        'route': value.route,
        'date': value.date,
        'date_updated': value.date_updated,
        'date_service': value.date_service,
        'date_check_referral': value.date_check_referral,
        'direction': value.direction,
        'status': value.status,
        'archived': value.archived,
        'title': value.title,
        'comments': value.comments,
        'private_comments': value.private_comments,
        'patient_id': value.patient_id,
        'physician_id': value.physician_id,
        'linked_to': value.linked_to,
        'linked_documents': value.linked_documents,
        'approved': value.approved,
        'hide': value.hide,
        'read': value.read,
        'template_id': value.template_id,
        'template_metadata': value.template_metadata,
        'physician_id_approved': value.physician_id_approved,
        'clinic_id': value.clinic_id,
        'clinic_id_approved': value.clinic_id_approved,
        'clinic_id_archived': value.clinic_id_archived,
        'external_id': value.external_id,
        'duplicate_of': value.duplicate_of,
        'processing': value.processing,
        'results_text': value.results_text === undefined ? undefined : ((value.results_text as Array<any>).map(DiagnosticResultLineToJSON)),
        'referral': ReferralToJSON(value.referral),
        'referral_no_tracking': value.referral_no_tracking,
        'referral_status': value.referral_status,
        'referral_specialty': value.referral_specialty,
        'referral_provider': value.referral_provider,
        'preventative_screening': PatientIssueValueToJSON(value.preventative_screening),
        'change_linked_referral_status': value.change_linked_referral_status,
        'send_to_patient': value.send_to_patient,
        'referral_comments': value.referral_comments === undefined ? undefined : ((value.referral_comments as Array<any>).map(TaskCommentToJSON)),
        'ocr_patient_ids': value.ocr_patient_ids === undefined ? undefined : ((value.ocr_patient_ids as Array<any>).map(OCRPatientIdToJSON)),
        'hl7': value.hl7,
        'fee': value.fee,
        'fee_hst': value.fee_hst,
        'fee_lines': value.fee_lines === undefined ? undefined : ((value.fee_lines as Array<any>).map(FeeLineToJSON)),
        'paid': value.paid,
        'has_password': value.has_password,
        'medications': value.medications === undefined ? undefined : ((value.medications as Array<any>).map(PrescriptionMedicationToJSON)),
        'pending_patient_fill': value.pending_patient_fill,
        'user_task_id': value.user_task_id,
        'approve_on_filled': value.approve_on_filled,
        'unread_by_physician_id': value.unread_by_physician_id,
        'fax_id': value.fax_id,
        'has_repeat': value.has_repeat,
        'repeat_options': RepeatOptionsToJSON(value.repeat_options),
        'html': value.html,
        'classification': value.classification,
        'notification_text': value.notification_text,
        'reminder_date': value.reminder_date,
        'reminder_text': value.reminder_text,
        'pinned': value.pinned,
        'general_comments': value.general_comments === undefined ? undefined : ((value.general_comments as Array<any>).map(TaskCommentToJSON)),
    };
}

