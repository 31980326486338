/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentStamp
 */
export interface DocumentStamp {
    /**
     * 
     * @type {number}
     * @memberof DocumentStamp
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentStamp
     */
    x_percent: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentStamp
     */
    y_percent: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentStamp
     */
    width_percent: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentStamp
     */
    image_base64: string;
}

/**
 * Check if a given object implements the DocumentStamp interface.
 */
export function instanceOfDocumentStamp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "x_percent" in value;
    isInstance = isInstance && "y_percent" in value;
    isInstance = isInstance && "width_percent" in value;
    isInstance = isInstance && "image_base64" in value;

    return isInstance;
}

export function DocumentStampFromJSON(json: any): DocumentStamp {
    return DocumentStampFromJSONTyped(json, false);
}

export function DocumentStampFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentStamp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'x_percent': json['x_percent'],
        'y_percent': json['y_percent'],
        'width_percent': json['width_percent'],
        'image_base64': json['image_base64'],
    };
}

export function DocumentStampToJSON(value?: DocumentStamp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'x_percent': value.x_percent,
        'y_percent': value.y_percent,
        'width_percent': value.width_percent,
        'image_base64': value.image_base64,
    };
}

