/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientIssueHistory } from './PatientIssueHistory';
import {
    PatientIssueHistoryFromJSON,
    PatientIssueHistoryFromJSONTyped,
    PatientIssueHistoryToJSON,
} from './PatientIssueHistory';
import type { PatientIssueValue } from './PatientIssueValue';
import {
    PatientIssueValueFromJSON,
    PatientIssueValueFromJSONTyped,
    PatientIssueValueToJSON,
} from './PatientIssueValue';

/**
 * 
 * @export
 * @interface PatientIssue
 */
export interface PatientIssue {
    /**
     * 
     * @type {string}
     * @memberof PatientIssue
     */
    id?: string;
    /**
     * 
     * @type {PatientIssueValue}
     * @memberof PatientIssue
     */
    value: PatientIssueValue;
    /**
     * 
     * @type {Array<PatientIssueHistory>}
     * @memberof PatientIssue
     */
    history?: Array<PatientIssueHistory>;
}

/**
 * Check if a given object implements the PatientIssue interface.
 */
export function instanceOfPatientIssue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function PatientIssueFromJSON(json: any): PatientIssue {
    return PatientIssueFromJSONTyped(json, false);
}

export function PatientIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': PatientIssueValueFromJSON(json['value']),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(PatientIssueHistoryFromJSON)),
    };
}

export function PatientIssueToJSON(value?: PatientIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value': PatientIssueValueToJSON(value.value),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(PatientIssueHistoryToJSON)),
    };
}

