import {Patient} from "../../../../generated/api";
import PatientUtils from "../../../../services/PatientUtils";

export const HISTORY_TYPES = {
  family_history: 'Family History',
  social_history: 'Social History',
  surgical_history: 'Surgical History',
  ethnicity_history: 'Ethnicity/Race'
};

export const REFERRAL_STATUS = {
  pending_send: 'Pending Send',
  pending_reply: 'Pending Reply',
  overdue: 'Overdue',
  completed: 'Completed',
  rejected: 'Rejected',
} as const;

export const ISSUE_TYPES = {
  current: 'Current Issues',
  past: 'Past Issues',
};

export const MEDICATION_STATUS = {
  continue: {
    name: 'Continue',
  },
  discontinue: {
    name: 'Discontinue',
    reason: true,
  },
  renew: {
    name: 'Renew (Rx)',
  },
  stop: {
    name: 'Stop',
    reason: true,
  },
  hold: {
    name: 'Hold',
    reason: true,
  },
  delete: {
    name: 'Delete',
    confirmDelete: true,
  },
  print: {
    name: 'Print Label',
  },
};

export const LIFESTYLE_FACTORS = [
  'Smoking',
  'Nonsmoker',
  'Ex-smoker',
  'Cannabis',
  'Alcohol',
  'Exercise',
  'Sleep',
  'Illicit drugs',
  'Mental health',
  'Employment',
  'Diet',
  'Caffeine',
  'Pets',
  'Nicotine',
];

export const LIFESTYLE_MAP = [
  { name: 'Smoking', id: 's' },
  { name: 'Nonsmoker', id: 'ns' },
  { name: 'Ex-smoker', id: 'es' },
  { name: 'Cannabis', id: 'c' },
  { name: 'Alcohol', id: 'a' },
  { name: 'Exercise', id: 'e' },
  { name: 'Sleep', id: 'sl' },
  { name: 'Illicit drugs', id: 'id' },
  { name: 'Mental health', id: 'm' },
  { name: 'Employment', id: 'em' },
  { name: 'Diet', id: 'd' },
  { name: 'Caffeine', id: 'ca' },
];

export const DEFAULT_NOTE = `S/
cc: 

HPI:
Onset:


ROS: no fever/chills, fatigue, malaise, weight gain/weight loss, dyspnea, chest pain, or NVD


PMhx
Dx:
Medications:
CAM:
Allergies:
PSx:

FHx:

SocHx
ETOH- 
Smoking- 
Illicit- 
Exercise- 
Work- 
Stress

Ill contacts-

O/e
PE deferred – phone/ video assessment; client aware assessment limited

VSS
Gen- A&O, appears well kempt and stable




A/
1.
2.

P/
Discussed findings above
Rx provided for:
Instructions discussed for use; AE/SEs
Req provided for
Continue nonpharm:
Education provided:
Discussed warning signs and plan
F/u if symptoms worsen`;

export const BASIC_NOTE = `S/

O/

A/

P/
`;

export const ALLERGIES = [
  'NKDA',
  'NKA',
  'Gluten',
  'Lactose',
  'Eggs',
  'Tree nuts',
  'Peanuts',
  'Shellfish',
  'Wheat',
  'Soy',
  'Sulfa',
  'Fish',
  'Pollen',
  'Penicillin',
  'Dust Mites',
  'Mold',
  'Seasonal allergies',
  'Grass',
  'Cats',
  'Cockroaches',
];

export const MEDICATIONS = ['None'];

export const MEDICAL_ISSUES = ['Unremarkable'];

export const AUTOCOMPLETE_HISTORY = {
  relation: ['Father', 'Mother', 'Brother', 'Sister', 'Grandmother', 'Grandfather', 'Son', 'Daughter'],
  preventative: [
    'PAP',
    'Mammography',
    'Hemoccult',
    'Sigmoidoscopy',
    'Colonoscopy',
    'Bone Mineral Density',
    'Audioscope',
  ],
};

export const OTHER_HEALTH_RECORDS = [
  {
    id: 'allergies',
    icon: 'head-side-cough',
    name: 'Allergies',
    fields: ['name', 'notes'],
    autocomplete: { name: ALLERGIES },
  },
  {
    id: 'medical_issues',
    icon: 'book-medical',
    name: 'Medical History',
    fields: ['issue_type', 'name', 'notes'],
    autocomplete: { name: MEDICAL_ISSUES },
    select: {
      inputs: { issue_type: ISSUE_TYPES },
    },
  },
  {
    id: 'medications',
    icon: 'prescription-bottle',
    name: 'Medications',
    fields: ['name', 'notes'],
    autocomplete: { name: MEDICATIONS },
  },
  {
    id: 'history',
    icon: 'clock-rotate-left',
    name: 'History',
    fields: ['history_type', 'name', 'details', 'notes'],
    detailsLabel: (historyType) => {
      switch (historyType) {
        case HISTORY_TYPES.family_history:
          return 'relation'
        default:
          return 'name';
      }
    },
    autocomplete: AUTOCOMPLETE_HISTORY,
    select: {
      inputs: { history_type: HISTORY_TYPES },
    },
  },
  {
    id: 'gtpal',
    icon: 'person-pregnant',
    name: 'GTPAL',
    fields: ['name', 'notes'],
    hide: (patient: Patient) => PatientUtils.getGender(patient) === "Male"
  },
  {
    id: 'preventative_screenings',
    icon: 'microscope',
    name: 'Preventative Screenings',
    fields: ['name', 'notes'],
    autocomplete: { name: AUTOCOMPLETE_HISTORY.preventative },
  },
  {
    id: 'lifestyle_factors',
    icon: 'dumbbell',
    name: 'Lifestyle Factors',
    fields: ['name', 'details', 'notes'],
    autocomplete: { name: LIFESTYLE_FACTORS },
  },
  {
    id: 'consultants',
    icon: 'users-medical',
    name: 'Consultants',
    fields: ['name', 'specialty', 'notes'],
  },
];

export const METRICS = [
  { label: 'T', metric: 'temperature', name: 'Temperature' },
  { label: 'Sitting BP', metric: 'bp/Sitting', name: 'Sitting Blood Pressure', width: '80px' },
  { label: 'Standing BP', metric: 'bp/Standing', name: 'Standing Blood Pressure', width: '100px' },
  { label: 'Supine BP', metric: 'bp/Supine', name: 'Supine Blood Pressure', width: '90px' },
  { label: 'Average BP', metric: 'bp/Average', name: 'Average Blood Pressure', width: '90px' },
  { label: 'HR', metric: 'hr', name: 'Heart Rate' },
  { label: 'RR', metric: 'rr', name: 'Respiratory Rate' },
  { label: 'O2', metric: 'o2', name: 'Oxygen' },
  { label: 'BS', metric: 'glucose', name: 'Blood Sugar' },
  { label: 'HT', metric: 'height', name: 'Height' },
  { label: 'WT', metric: 'weight', name: 'Weight' },
  { label: 'A1C', metric: 'a1c', name: 'Hemoglobin A1C' },
  { label: 'FRS', metric: 'frs', name: 'Framingham Risk Score' },
  { label: 'BMI', metric: 'bmi', name: 'Body Mass Index', disabled: true },
];
