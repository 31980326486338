/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Physician } from './Physician';
import {
    PhysicianFromJSON,
    PhysicianFromJSONTyped,
    PhysicianToJSON,
} from './Physician';

/**
 * 
 * @export
 * @interface PhysicianLoginPayload
 */
export interface PhysicianLoginPayload {
    /**
     * 
     * @type {boolean}
     * @memberof PhysicianLoginPayload
     */
    requires_2fa?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhysicianLoginPayload
     */
    token?: string;
    /**
     * 
     * @type {Physician}
     * @memberof PhysicianLoginPayload
     */
    user?: Physician;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhysicianLoginPayload
     */
    permissions?: Array<string>;
}

/**
 * Check if a given object implements the PhysicianLoginPayload interface.
 */
export function instanceOfPhysicianLoginPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PhysicianLoginPayloadFromJSON(json: any): PhysicianLoginPayload {
    return PhysicianLoginPayloadFromJSONTyped(json, false);
}

export function PhysicianLoginPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhysicianLoginPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requires_2fa': !exists(json, 'requires_2fa') ? undefined : json['requires_2fa'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user': !exists(json, 'user') ? undefined : PhysicianFromJSON(json['user']),
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function PhysicianLoginPayloadToJSON(value?: PhysicianLoginPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requires_2fa': value.requires_2fa,
        'token': value.token,
        'user': PhysicianToJSON(value.user),
        'permissions': value.permissions,
    };
}

