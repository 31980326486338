/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HoursOperationDay } from './HoursOperationDay';
import {
    HoursOperationDayFromJSON,
    HoursOperationDayFromJSONTyped,
    HoursOperationDayToJSON,
} from './HoursOperationDay';

/**
 * 
 * @export
 * @interface HoursOperation
 */
export interface HoursOperation {
    /**
     * 
     * @type {Array<HoursOperationDay>}
     * @memberof HoursOperation
     */
    clinic?: Array<HoursOperationDay>;
    /**
     * 
     * @type {Array<HoursOperationDay>}
     * @memberof HoursOperation
     */
    home?: Array<HoursOperationDay>;
    /**
     * 
     * @type {Array<HoursOperationDay>}
     * @memberof HoursOperation
     */
    video?: Array<HoursOperationDay>;
    /**
     * 
     * @type {Array<HoursOperationDay>}
     * @memberof HoursOperation
     */
    lab?: Array<HoursOperationDay>;
}

/**
 * Check if a given object implements the HoursOperation interface.
 */
export function instanceOfHoursOperation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HoursOperationFromJSON(json: any): HoursOperation {
    return HoursOperationFromJSONTyped(json, false);
}

export function HoursOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HoursOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clinic': !exists(json, 'clinic') ? undefined : ((json['clinic'] as Array<any>).map(HoursOperationDayFromJSON)),
        'home': !exists(json, 'home') ? undefined : ((json['home'] as Array<any>).map(HoursOperationDayFromJSON)),
        'video': !exists(json, 'video') ? undefined : ((json['video'] as Array<any>).map(HoursOperationDayFromJSON)),
        'lab': !exists(json, 'lab') ? undefined : ((json['lab'] as Array<any>).map(HoursOperationDayFromJSON)),
    };
}

export function HoursOperationToJSON(value?: HoursOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clinic': value.clinic === undefined ? undefined : ((value.clinic as Array<any>).map(HoursOperationDayToJSON)),
        'home': value.home === undefined ? undefined : ((value.home as Array<any>).map(HoursOperationDayToJSON)),
        'video': value.video === undefined ? undefined : ((value.video as Array<any>).map(HoursOperationDayToJSON)),
        'lab': value.lab === undefined ? undefined : ((value.lab as Array<any>).map(HoursOperationDayToJSON)),
    };
}

