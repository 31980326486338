/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftStats
 */
export interface ShiftStats {
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    vacation?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    bereavement?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    education?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    stat?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    sick?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    emergency?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftStats
     */
    approved?: number;
}

/**
 * Check if a given object implements the ShiftStats interface.
 */
export function instanceOfShiftStats(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShiftStatsFromJSON(json: any): ShiftStats {
    return ShiftStatsFromJSONTyped(json, false);
}

export function ShiftStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vacation': !exists(json, 'vacation') ? undefined : json['vacation'],
        'bereavement': !exists(json, 'bereavement') ? undefined : json['bereavement'],
        'education': !exists(json, 'education') ? undefined : json['education'],
        'stat': !exists(json, 'stat') ? undefined : json['stat'],
        'sick': !exists(json, 'sick') ? undefined : json['sick'],
        'emergency': !exists(json, 'emergency') ? undefined : json['emergency'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
    };
}

export function ShiftStatsToJSON(value?: ShiftStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vacation': value.vacation,
        'bereavement': value.bereavement,
        'education': value.education,
        'stat': value.stat,
        'sick': value.sick,
        'emergency': value.emergency,
        'approved': value.approved,
    };
}

