import { Patient, TabOrderingSettings } from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap';

export interface PatientsState {
  lastPatients: string[];
  byId: Record<string, Patient | undefined>;
}

const initialState: PatientsState = {
  lastPatients: [],
  byId: {},
};

const slice = createResettableSlice({
  name: 'Patients',
  initialState,
  reducers: {
    patientOpened: unwrap(patientOpened),
    receiveRegisteredPatient: unwrap(addOrEditPatient),
    receiveUpdatedPatient: unwrap(addOrEditPatientIgnoringIfNotFound),
    updatePatientTabOrderingSync: unwrap(
      (state, payload: { order: TabOrderingSettings; id: string }) => {
        const patient = state.byId[payload.id];
        if (!patient) {
          return;
        }
        patient.tab_ordering = payload.order;
      },
    ),
  },
  extraReducers: (builder) =>
    builder
      .addCase(api.Patients.updateOhipNumber.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Patients.removeOhipNumber.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(
        api.Patients.getPatients.fulfilled,
        unwrapApi((state, patients, req) => {
          if (!req?.search) {
            addOrEditPatients(state, patients);
          }
        }),
      )
      .addCase(api.Patients.getMultiplePatients2.fulfilled, unwrapApi(addOrEditPatients))
      .addCase(api.Patients.getMultiplePatients.fulfilled, unwrapApi(addOrEditPatients))
      .addCase(api.Patients.getPatient.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Patients.updatePatient.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.HealthRecords.resetMenstruationData.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Patients.markDeceased.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Patients.unlinkAccounts.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Patients.changePhoneNumber.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Patients.transferAccount.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(api.Authentication.registerPatientByStaff.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(
        api.Authentication.addLinkedAccount.fulfilled,
        unwrapApi((state, payload) => {
          addOrEditPatient(state, payload.parent);
          addOrEditPatient(state, payload.payload?.user);
        }),
      )
      .addCase(
        api.Authentication.registerLinkedAccount.fulfilled,
        unwrapApi((state, payload) => {
          addOrEditPatient(state, payload.parent);
          addOrEditPatient(state, payload.payload?.user);
        }),
      )
      .addCase(api.Corporations.applyCorporationCode.fulfilled, unwrapApi(addOrEditPatient))
      .addCase(
        api.Payments.createSubscription.fulfilled,
        unwrapApi((state, { patient }) => {
          addOrEditPatient(state, patient);
        }),
      ),
});

function addOrEditPatientIgnoringIfNotFound(state: PatientsState, patient: Patient | undefined) {
  if (!patient) {
    return;
  }
  if (state.byId[patient.id] === undefined) {
    return;
  }
  addOrEditPatient(state, patient);
}

function addOrEditPatient(state: PatientsState, patient: Patient | undefined) {
  if (!patient) {
    return;
  }
  state.byId[patient.id] = patient;
}

function addOrEditPatients(state: PatientsState, patients: Patient[]) {
  patients.forEach((patient) => {
    addOrEditPatient(state, patient);
  });
}

function patientOpened(state: PatientsState, patientId: string) {
  const { lastPatients } = state;
  const otherPatients = lastPatients.filter((id) => id !== patientId);
  state.lastPatients = [patientId, ...otherPatients];
}

export const patientActions = slice.actions;
export default slice.reducer;
