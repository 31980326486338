/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
} from './Patient';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface SubscriptionAndPatient
 */
export interface SubscriptionAndPatient {
    /**
     * 
     * @type {Subscription}
     * @memberof SubscriptionAndPatient
     */
    subscription: Subscription;
    /**
     * 
     * @type {Patient}
     * @memberof SubscriptionAndPatient
     */
    patient: Patient;
}

/**
 * Check if a given object implements the SubscriptionAndPatient interface.
 */
export function instanceOfSubscriptionAndPatient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subscription" in value;
    isInstance = isInstance && "patient" in value;

    return isInstance;
}

export function SubscriptionAndPatientFromJSON(json: any): SubscriptionAndPatient {
    return SubscriptionAndPatientFromJSONTyped(json, false);
}

export function SubscriptionAndPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionAndPatient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscription': SubscriptionFromJSON(json['subscription']),
        'patient': PatientFromJSON(json['patient']),
    };
}

export function SubscriptionAndPatientToJSON(value?: SubscriptionAndPatient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscription': SubscriptionToJSON(value.subscription),
        'patient': PatientToJSON(value.patient),
    };
}

