import { FavoritePrescriptionMedication } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrapApi } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface DrugsState {
  favoriteMedications: FavoritePrescriptionMedication[]
}

const initialState: DrugsState = {
  favoriteMedications: []
}

const slice = createResettableSlice({
  name: 'Drugs',
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(api.Drugs.getFavoriteMedications.fulfilled, unwrapApi((state, data) => {
      state.favoriteMedications = data
    }))
    .addCase(api.Drugs.addFavoriteMedication.fulfilled, unwrapApi((state, data) => {
      PerformanceUtils.upsert(data, state.favoriteMedications)
    }))
    .addCase(api.Drugs.removeFavoriteMedication.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state.favoriteMedications)
    }))
})

export const drugsActions = slice.actions
export default slice.reducer
