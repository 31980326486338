/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrugbankIngredientStrength
 */
export interface DrugbankIngredientStrength {
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredientStrength
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredientStrength
     */
    per?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredientStrength
     */
    units?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredientStrength
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredientStrength
     */
    unit?: string;
}

/**
 * Check if a given object implements the DrugbankIngredientStrength interface.
 */
export function instanceOfDrugbankIngredientStrength(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DrugbankIngredientStrengthFromJSON(json: any): DrugbankIngredientStrength {
    return DrugbankIngredientStrengthFromJSONTyped(json, false);
}

export function DrugbankIngredientStrengthFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrugbankIngredientStrength {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'per': !exists(json, 'per') ? undefined : json['per'],
        'units': !exists(json, 'units') ? undefined : json['units'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function DrugbankIngredientStrengthToJSON(value?: DrugbankIngredientStrength | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'per': value.per,
        'units': value.units,
        'number': value.number,
        'unit': value.unit,
    };
}

