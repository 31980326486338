/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Receipt
 */
export interface Receipt {
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    amount_captured?: number;
    /**
     * 
     * @type {number}
     * @memberof Receipt
     */
    amount_refunded?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Receipt
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Receipt
     */
    refunded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    receipt_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof Receipt
     */
    invoice?: string;
}

/**
 * Check if a given object implements the Receipt interface.
 */
export function instanceOfReceipt(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReceiptFromJSON(json: any): Receipt {
    return ReceiptFromJSONTyped(json, false);
}

export function ReceiptFromJSONTyped(json: any, ignoreDiscriminator: boolean): Receipt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'amount_captured': !exists(json, 'amount_captured') ? undefined : json['amount_captured'],
        'amount_refunded': !exists(json, 'amount_refunded') ? undefined : json['amount_refunded'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'refunded': !exists(json, 'refunded') ? undefined : json['refunded'],
        'receipt_url': !exists(json, 'receipt_url') ? undefined : json['receipt_url'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'invoice': !exists(json, 'invoice') ? undefined : json['invoice'],
    };
}

export function ReceiptToJSON(value?: Receipt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'date': value.date,
        'amount': value.amount,
        'amount_captured': value.amount_captured,
        'amount_refunded': value.amount_refunded,
        'paid': value.paid,
        'refunded': value.refunded,
        'receipt_url': value.receipt_url,
        'customer': value.customer,
        'invoice': value.invoice,
    };
}

