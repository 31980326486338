import { PayloadAction } from "@reduxjs/toolkit";

type ReducerFunction<State, T> = (state: State, action: PayloadAction<T>) => State | void
type UnwrappedReducerFunction<State, T> = (state: State, action: T) => State | void

/**
 * Extracts payload from action
 */
export function unwrap<State, T>(fn: UnwrappedReducerFunction<State, T>): ReducerFunction<State, T> {
  return (state, action) => {
    return fn(state, action.payload)
  }
}

type ThunkReducerFunction<State, T, ThunkArg> = (state: State, action: PayloadAction<T> & { meta: { arg: ThunkArg }}) => State | void
type UnwrappedApiReducerFunction<State, Res, Req> = (state: State, payload: Res, req: Req) => State | void

/**
 * Extracts payload and meta.arg from action
 */
export function unwrapApi<State, Res, Req>(fn: UnwrappedApiReducerFunction<State, Res, Req>): ThunkReducerFunction<State, Res, Req> {
  return (state, action) => {
    return fn(state, action.payload, action.meta.arg)
  }
}

export function forEach<State, T, S = void>(fn: (state: State, payload: T, extra: S) => void): (state: State, payload: T[], extra: S) => void
{
  return (state, ls, extra) => {
    ls.forEach(item => {
      fn(state, item, extra)
    })
  }
}
