/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Authentication } from './Authentication';
import {
    AuthenticationFromJSON,
    AuthenticationFromJSONTyped,
    AuthenticationToJSON,
} from './Authentication';
import type { Physician } from './Physician';
import {
    PhysicianFromJSON,
    PhysicianFromJSONTyped,
    PhysicianToJSON,
} from './Physician';

/**
 * 
 * @export
 * @interface PhysicianWithAuth
 */
export interface PhysicianWithAuth {
    /**
     * 
     * @type {Physician}
     * @memberof PhysicianWithAuth
     */
    physician: Physician;
    /**
     * 
     * @type {Authentication}
     * @memberof PhysicianWithAuth
     */
    auth: Authentication;
}

/**
 * Check if a given object implements the PhysicianWithAuth interface.
 */
export function instanceOfPhysicianWithAuth(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "physician" in value;
    isInstance = isInstance && "auth" in value;

    return isInstance;
}

export function PhysicianWithAuthFromJSON(json: any): PhysicianWithAuth {
    return PhysicianWithAuthFromJSONTyped(json, false);
}

export function PhysicianWithAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhysicianWithAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'physician': PhysicianFromJSON(json['physician']),
        'auth': AuthenticationFromJSON(json['auth']),
    };
}

export function PhysicianWithAuthToJSON(value?: PhysicianWithAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physician': PhysicianToJSON(value.physician),
        'auth': AuthenticationToJSON(value.auth),
    };
}

