import { ImmunizationDescription } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrap } from 'careand-redux/utils/unwrap'

export type ImmunizationDescriptionsState = ImmunizationDescription[]

const initialState: ImmunizationDescriptionsState = []

const slice = createResettableSlice({
  name: 'immunizationDescriptions',
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(api.HealthRecords.getImmunizationDescriptions.fulfilled, unwrap((state, descriptions) => descriptions))
})

export default slice.reducer
