/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftBreak
 */
export interface ShiftBreak {
    /**
     * 
     * @type {string}
     * @memberof ShiftBreak
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftBreak
     */
    start_hour?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftBreak
     */
    end_hour?: string;
}

/**
 * Check if a given object implements the ShiftBreak interface.
 */
export function instanceOfShiftBreak(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShiftBreakFromJSON(json: any): ShiftBreak {
    return ShiftBreakFromJSONTyped(json, false);
}

export function ShiftBreakFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftBreak {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'start_hour': !exists(json, 'start_hour') ? undefined : json['start_hour'],
        'end_hour': !exists(json, 'end_hour') ? undefined : json['end_hour'],
    };
}

export function ShiftBreakToJSON(value?: ShiftBreak | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'start_hour': value.start_hour,
        'end_hour': value.end_hour,
    };
}

