/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeEvent
 */
export interface StripeEvent {
    /**
     * 
     * @type {string}
     * @memberof StripeEvent
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof StripeEvent
     */
    created?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StripeEvent
     */
    livemode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeEvent
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeEvent
     */
    object?: string;
    /**
     * 
     * @type {number}
     * @memberof StripeEvent
     */
    pending_webhooks?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeEvent
     */
    api_version?: string;
    /**
     * 
     * @type {object}
     * @memberof StripeEvent
     */
    data?: object;
    /**
     * 
     * @type {object}
     * @memberof StripeEvent
     */
    request?: object;
}

/**
 * Check if a given object implements the StripeEvent interface.
 */
export function instanceOfStripeEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StripeEventFromJSON(json: any): StripeEvent {
    return StripeEventFromJSONTyped(json, false);
}

export function StripeEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'livemode': !exists(json, 'livemode') ? undefined : json['livemode'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'pending_webhooks': !exists(json, 'pending_webhooks') ? undefined : json['pending_webhooks'],
        'api_version': !exists(json, 'api_version') ? undefined : json['api_version'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'request': !exists(json, 'request') ? undefined : json['request'],
    };
}

export function StripeEventToJSON(value?: StripeEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created,
        'livemode': value.livemode,
        'type': value.type,
        'object': value.object,
        'pending_webhooks': value.pending_webhooks,
        'api_version': value.api_version,
        'data': value.data,
        'request': value.request,
    };
}

