import { PayloadAction } from '@reduxjs/toolkit';
import {
  AdministeredDrug,
  AppointmentNote,
  AppointmentNoteDraft,
  CommunicationNote,
  HealthRecords,
  HealthRecordsMetricSummary,
  Immunization,
  InternPendingRecords,
  MedicationReminder,
  MedicationReminderNotification,
  MetricType,
  MTReminderDescription,
  MTUpdate,
  PatientDocument,
  Prescription,
  PrescriptionRefill,
  Referral,
  ReferralRequest,
} from 'generated/api';
import _ from 'lodash';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap';
import { documentActions } from './documents';
import PerformanceUtils from 'services/PerformanceUtils';
import CalendarUtils from 'services/CalendarUtils';

export interface PatientData {
  healthRecords?: HealthRecords;
  prescriptions: Prescription[];
  notes: AppointmentNote[];
  documents: PatientDocument[];
  immunizations: Immunization[];
  administered_drugs: AdministeredDrug[];
  faxes: unknown[];
  referrals: Referral[];
  refills: PrescriptionRefill[];
  referral_requests: ReferralRequest[];
  unreadCount?: number;
  appointmentNoteDrafts: AppointmentNoteDraft[];
  dataSummary: HealthRecordsMetricSummary[];
  menstruation: MTUpdate;
  medicationReminders: Partial<Record<string, MedicationReminder>>;
  medicationReminderNotifications: MedicationReminderNotification[];
  communication_notes: CommunicationNote[];
}

const HR_TYPE_TO_FIELD = {
  AppointmentNote: 'notes',
  Immunization: 'immunizations',
  Prescription: 'prescriptions',
  PatientDocument: 'documents',
  Appointment: null,
} as const;

type HR_TYPE_FIELD = (typeof HR_TYPE_TO_FIELD)[keyof typeof HR_TYPE_TO_FIELD];

export interface HealthRecordsState {
  internPending: InternPendingRecords;
  ownDrafts: AppointmentNoteDraft[];
  refills: PrescriptionRefill[];
  metricTypes: MetricType[];
  byId: Record<string, PatientData | undefined>;
  menstruationRemindersDescriptions: MTReminderDescription[];
  newMedicationReminder?: MedicationReminder;
  referral_requests: ReferralRequest[];
  referral_documents: PatientDocument[];
  appointmentNoteStamps: { name: string; id: string }[];
}

const initialState: HealthRecordsState = {
  internPending: {},
  ownDrafts: [],
  refills: [],
  metricTypes: [],
  byId: {},
  referral_requests: [],
  referral_documents: [],
  menstruationRemindersDescriptions: [],
  appointmentNoteStamps: [],
};

const slice = createResettableSlice({
  name: 'HealthRecords',
  initialState,
  reducers: {
    receiveUpdatedNote: unwrap((state, note: AppointmentNote) => {
      editOrAddPatientData(state, note.patient_id, 'notes', note);
    }),
    receiveUpdatedRefill: unwrap((state, refill: PrescriptionRefill) => {
      editOrAddPatientData(state, refill.patient_id, 'refills', refill);
      state.refills = PerformanceUtils.editOrAdd(refill, state.refills);
    }),
    setNewMedicationReminder: unwrap((state, reminder: MedicationReminder | undefined) => {
      state.newMedicationReminder = reminder;
    }),
    receiveUpdatedReferralRequest: unwrap((state, request: ReferralRequest) => {
      state.referral_requests = PerformanceUtils.editOrAdd(request, state.referral_requests);
      editOrAddPatientData(state, request.patient_id, 'referral_requests', request);
    }),
    receiveRemovedReferralRequest: unwrap((state, request: ReferralRequest) => {
      PerformanceUtils.removeFromArrayInPlace(request, state.referral_requests);
      Object.keys(state.byId).forEach((id) => {
        const hr = state.byId[id];
        if (hr && hr.referral_requests.find(({ id }) => id === request.id)) {
          PerformanceUtils.removeFromArrayInPlace(
            request,
            state.byId[id]?.referral_requests || [],
          );
        }
      });
    }),
  },
  extraReducers: (builder) =>
    builder
      // previously this would've merged the new fields onto the old ones. Is this desired?
      .addCase(api.HealthRecords.getHealthRecords.fulfilled, unwrapApi(updatePatientHealthRecords))
      .addCase(
        api.HealthRecords.markHealthRecordRead.fulfilled,
        unwrapApi((state, data, req) => {
          const { patientId, type, id } = req;
          const field = HR_TYPE_TO_FIELD[type];
          const patientData = state.byId[patientId];

          if (!patientData) {
            return;
          }

          if (type === 'Appointment') {
            patientData.unreadCount = (patientData?.unreadCount ?? 1) - 1;
          }

          if (!field) {
            return;
          }

          const records = patientData[field];
          if (records) {
            const record = _.find(
              records,
              (it) => (it as ElementOf<PatientData[typeof field]>).id === id,
            ) as ElementOf<PatientData[typeof field]>;
            if (record) {
              record.read = true;
              patientData.unreadCount = (patientData.unreadCount ?? 1) - 1;
            }
          }
        }),
      )
      .addCase(
        api.HealthRecords.getCPP.fulfilled,
        unwrapApi((state, cpp, req) => {
          const { patientId } = req;
          replacePatientData(state, patientId, 'healthRecords', cpp.health_records);
          replacePatientData(state, patientId, 'prescriptions', cpp.prescriptions);
          replacePatientData(state, patientId, 'notes', cpp.notes);
          replacePatientData(state, patientId, 'documents', cpp.documents);
          replacePatientData(state, patientId, 'immunizations', cpp.immunizations);
          replacePatientData(state, patientId, 'administered_drugs', cpp.administered_drugs);
          replacePatientData(state, patientId, 'referrals', cpp.referrals);
          replacePatientData(state, patientId, 'refills', cpp.refills);
          replacePatientData(state, patientId, 'referral_requests', cpp.referral_requests);
        }),
      )
      .addCase(
        api.HealthRecords.countUnreadHealthRecords.fulfilled,
        unwrapApi((state, data, req) => {
          data.forEach(
            (entry) =>
              entry.id && replacePatientData(state, entry.id, 'unreadCount', entry.cpp_count),
          );
        }),
      )
      .addCase(
        api.HealthRecords.getMenstruationRemindersDescriptions.fulfilled,
        unwrapApi((state, descriptions) => {
          state.menstruationRemindersDescriptions = descriptions;
        }),
      )
      .addCase(
        api.HealthRecords.getMenstruationData.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.id, 'menstruation', data);
        }),
      )
      .addCase(
        api.HealthRecords.resetMenstruationData.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.id, 'menstruation', undefined);
        }),
      )
      .addCase(
        api.HealthRecords.updateMenstruationTutorialData.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.id, 'menstruation', data);
        }),
      )
      .addCase(
        api.HealthRecords.updateMenstruationDate.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.id, 'menstruation', data);
        }),
      )
      .addCase(
        api.HealthRecords.updateMenstruationDates.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.id, 'menstruation', data);
        }),
      )
      .addCase(
        api.Resource.getPatientCommunicationNotes.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.id, 'communication_notes', data);
        }),
      )
      .addCase(
        api.Resource.addPatientCommunicationNote.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.id, 'communication_notes', data);
        }),
      )
      .addCase(
        api.HealthRecords.updateHealthRecordsInformation.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.addOrEditHealthRecordPatientIssue.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.addOrEditHealthRecordPatientMedication.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.deleteHealthRecordPatientIssue.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.deleteHealthRecordMedication.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.addOrEditHealthRecordHistoryIssue.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.deleteHealthRecordHistoryIssue.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.addOrEditHealthRecordConsultant.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.deleteHealthRecordConsultant.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.addOrEditHealthRecordLifestyleFactor.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.deleteHealthRecordLifestyleFactor.fulfilled,
        unwrapApi(updatePatientHealthRecords),
      )
      .addCase(
        api.HealthRecords.deleteReferral.fulfilled,
        unwrapApi((state, data, req) => {
          const patientData = state.byId[req.patientId];
          if (!patientData) {
            return;
          }
          patientData.referrals = patientData.referrals.filter((it) => it.id !== req.id);
        }),
      )
      .addCase(
        api.HealthRecords.addReferralAsIntern.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'referrals', data);
        }),
      )
      .addCase(
        api.HealthRecords.generateImmunizations.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.patientId, 'immunizations', data);
        }),
      )
      .addCase(
        api.HealthRecords.addAdministeredDrug.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'administered_drugs', data);
        }),
      )
      .addCase(
        api.HealthRecords.removeAdministeredDrug.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.patientId, 'administered_drugs', data ?? []);
        }),
      )
      .addCase(
        api.HealthRecords.addImmunization.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'immunizations', data);
        }),
      )
      .addCase(
        api.HealthRecords.removeImmunization.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.patientId, 'immunizations', data ?? []);
        }),
      )
      .addCase(
        api.HealthRecords.updatePrescription.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, req.patientId, 'prescriptions', data);
        }),
      )
      .addCase(
        api.HealthRecords.addPrescription.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'prescriptions', data);
        }),
      )
      .addCase(
        api.HealthRecords.addPrescriptionRefill.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'refills', data);
        }),
      )
      .addCase(
        api.HealthRecords.editPrescriptionRefill.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'refills', data);
          state.refills = PerformanceUtils.editOrAdd(data, state.refills);
        }),
      )
      .addCase(
        api.HealthRecords.removePrescriptionRefill.fulfilled,
        unwrapApi((state, refill, req) => {
          PerformanceUtils.removeFromArrayInPlace(
            { id: req.id },
            state.byId[req.patientId]?.refills ?? [],
          );
        }),
      )
      .addCase(
        api.HealthRecords.getPrescriptionRefills.fulfilled,
        unwrapApi((state, data, req) => {
          state.refills = data;
        }),
      )
      .addCase(
        api.HealthRecords.addNotesText.fulfilled,
        unwrapApi((state, data, req) => {
          const patientId = data.patient_id;
          editOrAddPatientData(state, patientId, 'notes', data);
          filterPatientData(state, patientId, 'appointmentNoteDrafts', (it) => it.id !== data.id);
        }),
      )
      .addCase(
        api.HealthRecords.editNote.fulfilled,
        unwrapApi((state, data, req) => {
          const patientId = data.patient_id;
          editOrAddPatientData(state, patientId, 'notes', data);
          filterPatientData(state, patientId, 'appointmentNoteDrafts', (it) => it.id !== data.id);
        }),
      )
      .addCase(
        api.HealthRecords.addNotesAttachment.fulfilled,
        unwrapApi((state, data, req) => {
          const patientId = data.patient_id;
          editOrAddPatientData(state, patientId, 'notes', data);
          filterPatientData(state, patientId, 'appointmentNoteDrafts', (it) => it.id !== data.id);
        }),
      )
      .addCase(
        api.HealthRecords.addNotesAttachmentDraft.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, req.patientId, 'appointmentNoteDrafts', data);
        }),
      )
      .addCase(
        api.HealthRecords.getPatientNotesDraft.fulfilled,
        unwrapApi((state, data, req) => {
          if (req.backups) {
            return;
          }
          replacePatientData(state, req.patientId, 'appointmentNoteDrafts', data);
        }),
      )
      .addCase(
        api.HealthRecords.addNotesDraft.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'appointmentNoteDrafts', data);
        }),
      )
      .addCase(
        api.HealthRecords.deleteNotesDraft.fulfilled,
        unwrapApi((state, data, req) => {
          filterPatientData(
            state,
            req.patientId,
            'appointmentNoteDrafts',
            (it) => it.id !== req.id,
          );
        }),
      )
      .addCase(
        api.HealthRecords.getHealthDataSummary.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.patientId, 'dataSummary', data);
        }),
      )
      .addCase(
        api.HealthRecords.exportCPP.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, req.patientId, 'documents', data);
        }),
      )
      .addCase(
        api.HealthRecords.getOwnInternsPendingRecords.fulfilled,
        unwrapApi((state, data, req) => {
          state.internPending = data;
        }),
      )
      .addCase(
        api.HealthRecords.getOwnDrafts.fulfilled,
        unwrapApi((state, data, req) => {
          state.ownDrafts = data;
        }),
      )
      .addCase(
        api.HealthRecords.getReferralRequests.fulfilled,
        unwrapApi((state, data, req) => {
          state.referral_requests = data;
        }),
      )
      .addCase(
        api.HealthRecords.addReferralRequest.fulfilled,
        unwrapApi((state, data, req) => {
          state.referral_requests = PerformanceUtils.editOrAdd(data, state.referral_requests);
          editOrAddPatientData(state, data.patient_id, 'referral_requests', data);
        }),
      )
      .addCase(
        api.HealthRecords.removeReferralRequest.fulfilled,
        unwrapApi((state, data, req) => {
          PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state.referral_requests);
          Object.keys(state.byId).forEach((id) => {
            const hr = state.byId[id];
            if (hr && hr.referral_requests.find(({ id }) => id === req.id)) {
              PerformanceUtils.removeFromArrayInPlace(
                { id: req.id },
                state.byId[id]?.referral_requests || [],
              );
            }
          });
        }),
      )
      .addCase(
        api.HealthRecords.getMetricTypes.fulfilled,
        unwrapApi((state, data, req) => {
          state.metricTypes = data;
        }),
      )
      .addCase(
        api.HealthRecords.editMetricType.fulfilled,
        unwrapApi((state, data, req) => {
          PerformanceUtils.upsert(data, state.metricTypes);
        }),
      )
      .addCase(
        api.HealthRecords.addMedicationReminder.fulfilled,
        unwrap((state, reminder) => {
          const patientData = state.byId[reminder.patient_id];
          if (!patientData) {
            return;
          }
          patientData.medicationReminders[reminder.id!] = reminder;
        }),
      )
      .addCase(
        api.HealthRecords.discontinueMedicationReminder.fulfilled,
        unwrap((state, reminder) => {
          const patientData = state.byId[reminder.patient_id];
          if (!patientData) {
            return;
          }
          patientData.medicationReminders[reminder.id!] = reminder;
        }),
      )
      .addCase(
        api.HealthRecords.updateMedicationReminder.fulfilled,
        unwrapApi((state, reminders) => {
          const patient_id = reminders[0]?.patient_id;
          if (!patient_id) {
            return;
          }

          const patientData = state.byId[patient_id];

          if (!patientData) {
            return;
          }

          reminders.forEach((reminder) => {
            patientData.medicationReminders[reminder.id!] = reminder;
          });
        }),
      )
      .addCase(
        api.HealthRecords.updateMedicationReminderTake.fulfilled,
        unwrapApi((state, reminder, req) => {
          const patient_id = reminder.patient_id;

          const patientData = state.byId[patient_id];

          if (!patientData) {
            return;
          }

          const previousReminder = patientData.medicationReminders[reminder.id!];
          patientData.medicationReminders[reminder.id!] = reminder;
        }),
      )
      .addCase(
        api.HealthRecords.getMedicationReminders.fulfilled,
        unwrapApi((state, reminders, req) => {
          const patientData = state.byId[req.patient_id];
          if (!patientData) {
            return;
          }
          reminders.forEach((reminder) => {
            patientData.medicationReminders[reminder.id!] = reminder;
          });
        }),
      )
      .addCase(api.Documents.updateDocument.fulfilled, updatePatientDocument)
      .addCase(api.Documents.addDocument.fulfilled, updatePatientDocument)
      .addCase(api.Documents.stampDocument.fulfilled, updatePatientDocument)
      .addCase(api.Documents.editDocumentFromHtml.fulfilled, updatePatientDocument)
      .addCase(api.Documents.addDocumentFromHtml.fulfilled, updatePatientDocument)
      .addCase(api.Documents.markDocumentPendingPatientFill.fulfilled, updatePatientDocument)
      .addCase(api.Documents.patientFillDocument.fulfilled, updatePatientDocument)
      .addCase(api.Documents.combineDocuments.fulfilled, updatePatientDocument)
      .addCase(
        api.Documents.deleteDocument.fulfilled,
        unwrapApi((state, data, req) => {
          Object.keys(state.byId).forEach((patientId) => {
            filterPatientData(state, patientId, 'documents', (it) => it.id !== req.documentId);
          });
          PerformanceUtils.removeFromArray({ id: req.documentId }, state.referral_documents);
        }),
      )
      .addCase(
        documentActions.receivedUpdatedDocument,
        unwrap((state, document) => {
          editOrAddPatientData(state, document.patient_id, 'documents', document);
          addReferralDocuments(state, [document]);
        }),
      )
      .addCase(
        documentActions.receivedDeletedDocument,
        unwrap((state, document) => {
          Object.keys(state.byId).forEach((patientId) => {
            filterPatientData(state, patientId, 'documents', (it) => it.id !== document.id);
          });
          PerformanceUtils.removeFromArray(document, state.referral_documents);
        }),
      )

      .addCase(
        api.HealthRecords.getPendingReferrals.fulfilled,
        unwrapApi((state, data, req) => {
          addReferralDocuments(state, data);
        }),
      )
      .addCase(
        api.HealthRecords.updateReferral.fulfilled,
        unwrapApi((state, data, req) => {
          addReferralDocuments(state, [data]);
        }),
      )
      .addCase(
        api.HealthRecords.addReferral.fulfilled,
        unwrapApi((state, data, req) => {
          editOrAddPatientData(state, data.patient_id, 'documents', data);
          addReferralDocuments(state, [data]);
        }),
      )
      .addCase(
        api.HealthRecords.getReferralsByStatus.fulfilled,
        unwrapApi((state, data, req) => {
          addReferralDocuments(state, data);
        }),
      )
      .addCase(
        api.HealthRecords.getMedicationReminderNotifications.fulfilled,
        unwrapApi((state, data, req) => {
          replacePatientData(state, req.patient_id, 'medicationReminderNotifications', data);
        }),
      )
      .addCase(
        api.HealthRecords.addOrEditMedicalOrder.fulfilled,
        unwrapApi((state, data, req) => {
          const patientData = state.byId[req.patient_id];
          if (!patientData) {
            return;
          }
          if (patientData.healthRecords) {
            patientData.healthRecords.medical_orders = data;
          }
        }),
      )
      .addCase(
        api.HealthRecords.deleteMedicalOrder.fulfilled,
        unwrapApi((state, data, req) => {
          const patientData = state.byId[req.patient_id];
          if (!patientData) {
            return;
          }
          if (patientData.healthRecords) {
            patientData.healthRecords.medical_orders = data;
          }
        }),
      )
      .addCase(
        api.HealthRecords.markAllHealthRecordsRead.fulfilled,
        unwrapApi((state, cpp, req) => {
          replacePatientData(state, req.patientId, 'healthRecords', cpp.health_records);
          replacePatientData(state, req.patientId, 'prescriptions', cpp.prescriptions);
          replacePatientData(state, req.patientId, 'notes', cpp.notes);
          replacePatientData(state, req.patientId, 'documents', cpp.documents);
          replacePatientData(state, req.patientId, 'immunizations', cpp.immunizations);
          replacePatientData(state, req.patientId, 'administered_drugs', cpp.administered_drugs);
          replacePatientData(state, req.patientId, 'referrals', cpp.referrals);
          replacePatientData(state, req.patientId, 'refills', cpp.refills);
          const patientData = state.byId[req.patientId];
          if (patientData) {
            patientData.unreadCount = 0;
          }
        }),
      )
      .addCase(
        api.HealthRecords.addOrEditAppointmentNoteStamp.fulfilled,
        unwrap((state, stamp) => {
          const idx = state.appointmentNoteStamps.findIndex((it) => it.id === stamp.id);
          const stampRef = { name: stamp.name, id: stamp.id! };
          if (idx !== -1) {
            state.appointmentNoteStamps[idx] = stampRef;
          } else {
            state.appointmentNoteStamps.push(stampRef);
          }
        }),
      )
      .addCase(
        api.HealthRecords.deleteAppointmentNoteStamp.fulfilled,
        unwrapApi((state, res, req) => {
          state.appointmentNoteStamps = state.appointmentNoteStamps.filter(
            (it) => it.id !== req.id,
          );
        }),
      )
      .addCase(
        api.HealthRecords.getAppointmentNoteStamps.fulfilled,
        unwrap((state, res) => {
          state.appointmentNoteStamps = PerformanceUtils.sortBy(res, ['name'], false);
        }),
      ),
});

function replacePatientData<K extends keyof PatientData>(
  state: HealthRecordsState,
  patientId: string,
  field: K,
  data: PatientData[K] | undefined,
) {
  if (!state.byId[patientId]) {
    state.byId[patientId] = createEmptyPatientData();
  }
  if (!data) {
    return;
  }
  state.byId[patientId]![field] = data;
}

type ElementOf<T> = T extends Array<infer S> ? S : never;

function editOrAddPatientData<K extends keyof PatientData>(
  state: HealthRecordsState,
  patientId: string | undefined,
  field: PatientData[K] extends Array<any> ? K : never,
  data: ElementOf<PatientData[K]>,
) {
  if (!patientId || typeof data === 'undefined') {
    return;
  }
  const patientData = state.byId[patientId];
  if (!patientData) {
    return;
  }
  const existingData = patientData[field] as PatientData[K] & Array<any>;
  PerformanceUtils.upsert(data, existingData);
}

type Predicate<T> = (element: T) => boolean;

// TODO (performance improvement), calls to this could be replaced with PerformanceUtils.removeFromArrayInPlace
function filterPatientData<K extends keyof PatientData>(
  state: HealthRecordsState,
  patientId: string | undefined,
  field: PatientData[K] extends Array<any> ? K : never,
  predicate: Predicate<ElementOf<PatientData[K]>>,
) {
  if (!patientId || typeof predicate === 'undefined') {
    return;
  }
  const patientData = state.byId[patientId];
  if (!patientData) {
    return;
  }
  const existingData = patientData[field] as PatientData[K] & Array<any>;

  const newData = existingData.filter(predicate);

  replacePatientData(state, patientId, field, newData as PatientData[K]);
}

function createEmptyPatientData(): PatientData {
  return {
    healthRecords: undefined,
    prescriptions: [],
    notes: [],
    documents: [],
    immunizations: [],
    administered_drugs: [],
    faxes: [],
    referrals: [],
    refills: [],
    referral_requests: [],
    appointmentNoteDrafts: [],
    dataSummary: [],
    menstruation: {},
    communication_notes: [],
    medicationReminders: {},
    medicationReminderNotifications: [],
  };
}

function updatePatientHealthRecords(
  state: HealthRecordsState,
  data: HealthRecords | undefined,
  req: { patientId: string },
) {
  replacePatientData(state, req.patientId, 'healthRecords', data);
}

function updatePatientDocument(state: HealthRecordsState, action: PayloadAction<PatientDocument>) {
  editOrAddPatientData(state, action.payload.patient_id, 'documents', action.payload);
  addReferralDocuments(state, [action.payload]);
}

function addReferralDocuments(state: HealthRecordsState, documents: PatientDocument[]) {
  let filtered = documents.filter((doc) => !!doc.referral_status);
  filtered.forEach((doc) => {
    PerformanceUtils.upsert(doc, state.referral_documents);
  });
  if (filtered.length > 0) {
    state.referral_documents = PerformanceUtils.sortBy(state.referral_documents);
  }
}

export function groupMedicationReminderNotifications(
  notifications: MedicationReminderNotification[],
) {
  const state: Partial<Record<string, MedicationReminderNotification[]>> = {};

  notifications.forEach((notification) => {
    const dateStr = CalendarUtils.toSortableDateString(notification.date);
    state[dateStr] = state[dateStr] ?? [];
    state[dateStr]!.push(notification);
  });

  return state;
}

export const hrActions = slice.actions;
export default slice.reducer;
