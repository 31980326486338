/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinistryOHIP
 */
export interface MinistryOHIP {
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof MinistryOHIP
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof MinistryOHIP
     */
    dateOfBirth?: number;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    secondName?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    gender?: MinistryOHIPGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    healthNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    versionCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MinistryOHIP
     */
    validCard?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    responseID?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    responseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    responseDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    responseAction?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    statusCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MinistryOHIP
     */
    message?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MinistryOHIP
     */
    expiryDate?: Array<number>;
}


/**
 * @export
 */
export const MinistryOHIPGenderEnum = {
    Male: 'Male',
    Female: 'Female'
} as const;
export type MinistryOHIPGenderEnum = typeof MinistryOHIPGenderEnum[keyof typeof MinistryOHIPGenderEnum];


/**
 * Check if a given object implements the MinistryOHIP interface.
 */
export function instanceOfMinistryOHIP(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MinistryOHIPFromJSON(json: any): MinistryOHIP {
    return MinistryOHIPFromJSONTyped(json, false);
}

export function MinistryOHIPFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinistryOHIP {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'secondName': !exists(json, 'secondName') ? undefined : json['secondName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'healthNumber': !exists(json, 'healthNumber') ? undefined : json['healthNumber'],
        'versionCode': !exists(json, 'versionCode') ? undefined : json['versionCode'],
        'validCard': !exists(json, 'validCard') ? undefined : json['validCard'],
        'responseID': !exists(json, 'responseID') ? undefined : json['responseID'],
        'responseCode': !exists(json, 'responseCode') ? undefined : json['responseCode'],
        'responseDescription': !exists(json, 'responseDescription') ? undefined : json['responseDescription'],
        'responseAction': !exists(json, 'responseAction') ? undefined : json['responseAction'],
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'expiryDate': !exists(json, 'expiryDate') ? undefined : json['expiryDate'],
    };
}

export function MinistryOHIPToJSON(value?: MinistryOHIP | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'date': value.date,
        'dateOfBirth': value.dateOfBirth,
        'firstName': value.firstName,
        'secondName': value.secondName,
        'lastName': value.lastName,
        'gender': value.gender,
        'healthNumber': value.healthNumber,
        'versionCode': value.versionCode,
        'validCard': value.validCard,
        'responseID': value.responseID,
        'responseCode': value.responseCode,
        'responseDescription': value.responseDescription,
        'responseAction': value.responseAction,
        'statusCode': value.statusCode,
        'message': value.message,
        'expiryDate': value.expiryDate,
    };
}

