/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';
import type { StatisticsEvent } from './StatisticsEvent';
import {
    StatisticsEventFromJSON,
    StatisticsEventFromJSONTyped,
    StatisticsEventToJSON,
} from './StatisticsEvent';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface FinancialStatistics
 */
export interface FinancialStatistics {
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof FinancialStatistics
     */
    invoices?: Array<Invoice>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof FinancialStatistics
     */
    subscriptions?: Array<Subscription>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof FinancialStatistics
     */
    overdueSubscriptions?: Array<Subscription>;
    /**
     * 
     * @type {Array<StatisticsEvent>}
     * @memberof FinancialStatistics
     */
    events?: Array<StatisticsEvent>;
}

/**
 * Check if a given object implements the FinancialStatistics interface.
 */
export function instanceOfFinancialStatistics(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FinancialStatisticsFromJSON(json: any): FinancialStatistics {
    return FinancialStatisticsFromJSONTyped(json, false);
}

export function FinancialStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoices': !exists(json, 'invoices') ? undefined : ((json['invoices'] as Array<any>).map(InvoiceFromJSON)),
        'subscriptions': !exists(json, 'subscriptions') ? undefined : ((json['subscriptions'] as Array<any>).map(SubscriptionFromJSON)),
        'overdueSubscriptions': !exists(json, 'overdueSubscriptions') ? undefined : ((json['overdueSubscriptions'] as Array<any>).map(SubscriptionFromJSON)),
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(StatisticsEventFromJSON)),
    };
}

export function FinancialStatisticsToJSON(value?: FinancialStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoices': value.invoices === undefined ? undefined : ((value.invoices as Array<any>).map(InvoiceToJSON)),
        'subscriptions': value.subscriptions === undefined ? undefined : ((value.subscriptions as Array<any>).map(SubscriptionToJSON)),
        'overdueSubscriptions': value.overdueSubscriptions === undefined ? undefined : ((value.overdueSubscriptions as Array<any>).map(SubscriptionToJSON)),
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(StatisticsEventToJSON)),
    };
}

