/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusHistory
 */
export interface StatusHistory {
    /**
     * 
     * @type {string}
     * @memberof StatusHistory
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusHistory
     */
    concern?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusHistory
     */
    appointment_type?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusHistory
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusHistory
     */
    appointment_date?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusHistory
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusHistory
     */
    by_role?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusHistory
     */
    by_id?: string;
}

/**
 * Check if a given object implements the StatusHistory interface.
 */
export function instanceOfStatusHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatusHistoryFromJSON(json: any): StatusHistory {
    return StatusHistoryFromJSONTyped(json, false);
}

export function StatusHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'concern': !exists(json, 'concern') ? undefined : json['concern'],
        'appointment_type': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'appointment_date': !exists(json, 'appointment_date') ? undefined : json['appointment_date'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'by_role': !exists(json, 'by_role') ? undefined : json['by_role'],
        'by_id': !exists(json, 'by_id') ? undefined : json['by_id'],
    };
}

export function StatusHistoryToJSON(value?: StatusHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'concern': value.concern,
        'appointment_type': value.appointment_type,
        'date': value.date,
        'appointment_date': value.appointment_date,
        'physician_id': value.physician_id,
        'by_role': value.by_role,
        'by_id': value.by_id,
    };
}

