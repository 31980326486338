/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImmunizationValue } from './ImmunizationValue';
import {
    ImmunizationValueFromJSON,
    ImmunizationValueFromJSONTyped,
    ImmunizationValueToJSON,
} from './ImmunizationValue';

/**
 * 
 * @export
 * @interface ImmunizationHistory
 */
export interface ImmunizationHistory {
    /**
     * 
     * @type {number}
     * @memberof ImmunizationHistory
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationHistory
     */
    author_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationHistory
     */
    author_role?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationHistory
     */
    action?: string;
    /**
     * 
     * @type {ImmunizationValue}
     * @memberof ImmunizationHistory
     */
    value?: ImmunizationValue;
}

/**
 * Check if a given object implements the ImmunizationHistory interface.
 */
export function instanceOfImmunizationHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImmunizationHistoryFromJSON(json: any): ImmunizationHistory {
    return ImmunizationHistoryFromJSONTyped(json, false);
}

export function ImmunizationHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImmunizationHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'author_id': !exists(json, 'author_id') ? undefined : json['author_id'],
        'author_role': !exists(json, 'author_role') ? undefined : json['author_role'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'value': !exists(json, 'value') ? undefined : ImmunizationValueFromJSON(json['value']),
    };
}

export function ImmunizationHistoryToJSON(value?: ImmunizationHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'author_id': value.author_id,
        'author_role': value.author_role,
        'action': value.action,
        'value': ImmunizationValueToJSON(value.value),
    };
}

