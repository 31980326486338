/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepeatOptions } from './RepeatOptions';
import {
    RepeatOptionsFromJSON,
    RepeatOptionsFromJSONTyped,
    RepeatOptionsToJSON,
} from './RepeatOptions';

/**
 * 
 * @export
 * @interface DocumentTemplateSubmission
 */
export interface DocumentTemplateSubmission {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    template_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    template_metadata?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    physician_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateSubmission
     */
    approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateSubmission
     */
    hide?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    document_type?: string;
    /**
     * 
     * @type {RepeatOptions}
     * @memberof DocumentTemplateSubmission
     */
    repeat_options?: RepeatOptions;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateSubmission
     */
    notification_text?: string;
}

/**
 * Check if a given object implements the DocumentTemplateSubmission interface.
 */
export function instanceOfDocumentTemplateSubmission(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentTemplateSubmissionFromJSON(json: any): DocumentTemplateSubmission {
    return DocumentTemplateSubmissionFromJSONTyped(json, false);
}

export function DocumentTemplateSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTemplateSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'html': !exists(json, 'html') ? undefined : json['html'],
        'template_id': !exists(json, 'template_id') ? undefined : json['template_id'],
        'template_metadata': !exists(json, 'template_metadata') ? undefined : json['template_metadata'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'document_type': !exists(json, 'document_type') ? undefined : json['document_type'],
        'repeat_options': !exists(json, 'repeat_options') ? undefined : RepeatOptionsFromJSON(json['repeat_options']),
        'notification_text': !exists(json, 'notification_text') ? undefined : json['notification_text'],
    };
}

export function DocumentTemplateSubmissionToJSON(value?: DocumentTemplateSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'html': value.html,
        'template_id': value.template_id,
        'template_metadata': value.template_metadata,
        'clinic_id': value.clinic_id,
        'physician_id': value.physician_id,
        'approved': value.approved,
        'hide': value.hide,
        'document_type': value.document_type,
        'repeat_options': RepeatOptionsToJSON(value.repeat_options),
        'notification_text': value.notification_text,
    };
}

