import { z } from "zod"
import { createMessage, NativeMessageSchema } from "./NativeMessage"

export const SafeAreaSchema = z.object({
  top: z.number(),
  left: z.number(),
  right: z.number(),
  bottom: z.number()
})

export type SafeArea = z.infer<typeof SafeAreaSchema>

export const SetupMessageSchema = z.object({
  type: z.literal('setup'),
  content: z.object({
    nativeAppVersion: z.string(),
    safeArea: SafeAreaSchema.partial(),
    biometricAuthentication: z.boolean().optional(),
    biometricsAvailable: z.union([z.literal('Face ID'), z.literal('Touch ID')]).optional()
  })
}).extend(NativeMessageSchema.shape)

export type SetupMessage = z.infer<typeof SetupMessageSchema>
export type BiometricType = Required<SetupMessage['content']>['biometricsAvailable']

export const RequestSetupMessageSchema = z.object({
  type: z.literal('requestSetup')
}).extend(NativeMessageSchema.shape)

export type RequestSetupMessage = z.infer<typeof RequestSetupMessageSchema>

export const createRequestSetupMessage = () => ({
  ...createMessage(),
  type: 'requestSetup'
} satisfies RequestSetupMessage)