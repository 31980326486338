/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorporationCoupon
 */
export interface CorporationCoupon {
    /**
     * 
     * @type {string}
     * @memberof CorporationCoupon
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationCoupon
     */
    corporation_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationCoupon
     */
    batch_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationCoupon
     */
    subscription_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationCoupon
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationCoupon
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof CorporationCoupon
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof CorporationCoupon
     */
    date_used?: number;
}

/**
 * Check if a given object implements the CorporationCoupon interface.
 */
export function instanceOfCorporationCoupon(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CorporationCouponFromJSON(json: any): CorporationCoupon {
    return CorporationCouponFromJSONTyped(json, false);
}

export function CorporationCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporationCoupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'corporation_id': !exists(json, 'corporation_id') ? undefined : json['corporation_id'],
        'batch_id': !exists(json, 'batch_id') ? undefined : json['batch_id'],
        'subscription_id': !exists(json, 'subscription_id') ? undefined : json['subscription_id'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'date_used': !exists(json, 'date_used') ? undefined : json['date_used'],
    };
}

export function CorporationCouponToJSON(value?: CorporationCoupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'corporation_id': value.corporation_id,
        'batch_id': value.batch_id,
        'subscription_id': value.subscription_id,
        'patient_id': value.patient_id,
        'email': value.email,
        'date': value.date,
        'date_used': value.date_used,
    };
}

