/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImmunizationDescription
 */
export interface ImmunizationDescription {
    /**
     * 
     * @type {string}
     * @memberof ImmunizationDescription
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationDescription
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationDescription
     */
    prescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationDescription
     */
    din?: string;
    /**
     * 
     * @type {number}
     * @memberof ImmunizationDescription
     */
    price?: number;
}

/**
 * Check if a given object implements the ImmunizationDescription interface.
 */
export function instanceOfImmunizationDescription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImmunizationDescriptionFromJSON(json: any): ImmunizationDescription {
    return ImmunizationDescriptionFromJSONTyped(json, false);
}

export function ImmunizationDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImmunizationDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'prescription': !exists(json, 'prescription') ? undefined : json['prescription'],
        'din': !exists(json, 'din') ? undefined : json['din'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function ImmunizationDescriptionToJSON(value?: ImmunizationDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'prescription': value.prescription,
        'din': value.din,
        'price': value.price,
    };
}

