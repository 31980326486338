/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PhysicianContact,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PhysicianContactFromJSON,
    PhysicianContactToJSON,
} from '../models';

export interface AddPhysicianContactRequest {
    contact: PhysicianContact;
}

export interface GetPhysicianContactsRequest {
    name?: string;
    limit?: number;
}

export interface RemovePhysicianContactRequest {
    id: string;
}

/**
 * 
 */
export class ContactsApi extends runtime.BaseAPI {

    /**
     * Creates a new PhysicianContact (Roles: admin, physician)
     */
    async addPhysicianContactRaw(requestParameters: AddPhysicianContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhysicianContact>> {
        if (requestParameters.contact === null || requestParameters.contact === undefined) {
            throw new runtime.RequiredError('contact','Required parameter requestParameters.contact was null or undefined when calling addPhysicianContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhysicianContactToJSON(requestParameters.contact),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhysicianContactFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPhysicianContactRequestOptionsRaw(requestParameters: AddPhysicianContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.contact === null || requestParameters.contact === undefined) {
            throw new runtime.RequiredError('contact','Required parameter requestParameters.contact was null or undefined when calling addPhysicianContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/contacts`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PhysicianContactToJSON(requestParameters.contact),
        };
        return responseConfig
    }

    /**
     * Creates a new PhysicianContact (Roles: admin, physician)
     */
    async addPhysicianContact(requestParameters: AddPhysicianContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhysicianContact> {
        const response = await this.addPhysicianContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the physician\'s contacts (Roles: admin, physician)
     */
    async getPhysicianContactsRaw(requestParameters: GetPhysicianContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PhysicianContact>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhysicianContactFromJSON));
    }

    /**
        Request Config Getter
     */
    getPhysicianContactsRequestOptionsRaw(requestParameters: GetPhysicianContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/contacts`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all the physician\'s contacts (Roles: admin, physician)
     */
    async getPhysicianContacts(requestParameters: GetPhysicianContactsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PhysicianContact>> {
        const response = await this.getPhysicianContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a new PhysicianContact (Roles: admin, physician)
     */
    async removePhysicianContactRaw(requestParameters: RemovePhysicianContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePhysicianContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removePhysicianContactRequestOptionsRaw(requestParameters: RemovePhysicianContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePhysicianContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a new PhysicianContact (Roles: admin, physician)
     */
    async removePhysicianContact(requestParameters: RemovePhysicianContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePhysicianContactRaw(requestParameters, initOverrides);
    }

}
