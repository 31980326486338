/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    photo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Admin
     */
    inactive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    clinic_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    vacation_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Admin
     */
    hide_portal?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Admin
     */
    favorite_contacts?: Array<string>;
}

/**
 * Check if a given object implements the Admin interface.
 */
export function instanceOfAdmin(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminFromJSON(json: any): Admin {
    return AdminFromJSONTyped(json, false);
}

export function AdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): Admin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'vacation_days': !exists(json, 'vacation_days') ? undefined : json['vacation_days'],
        'hide_portal': !exists(json, 'hide_portal') ? undefined : json['hide_portal'],
        'favorite_contacts': !exists(json, 'favorite_contacts') ? undefined : json['favorite_contacts'],
    };
}

export function AdminToJSON(value?: Admin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'phone': value.phone,
        'photo': value.photo,
        'inactive': value.inactive,
        'clinic_id': value.clinic_id,
        'vacation_days': value.vacation_days,
        'hide_portal': value.hide_portal,
        'favorite_contacts': value.favorite_contacts,
    };
}

