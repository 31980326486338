/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DrugbankProduct,
  DrugbankProductConcept,
  ErrorResponse,
  FavoritePrescriptionMedication,
  PrescriptionMedication,
} from '../models';
import {
    DrugbankProductFromJSON,
    DrugbankProductToJSON,
    DrugbankProductConceptFromJSON,
    DrugbankProductConceptToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FavoritePrescriptionMedicationFromJSON,
    FavoritePrescriptionMedicationToJSON,
    PrescriptionMedicationFromJSON,
    PrescriptionMedicationToJSON,
} from '../models';

export interface AddFavoriteMedicationRequest {
    medication: PrescriptionMedication;
}

export interface GetProductsRequest {
    id: string;
}

export interface GetRoutesRequest {
    id: string;
}

export interface GetStrengthsRequest {
    id: string;
}

export interface RemoveFavoriteMedicationRequest {
    id: string;
}

export interface SearchDrugRequest {
    name: string;
}

/**
 * 
 */
export class DrugsApi extends runtime.BaseAPI {

    /**
     * Adds a Physician\'s favorite medication (Roles: physician)
     */
    async addFavoriteMedicationRaw(requestParameters: AddFavoriteMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavoritePrescriptionMedication>> {
        if (requestParameters.medication === null || requestParameters.medication === undefined) {
            throw new runtime.RequiredError('medication','Required parameter requestParameters.medication was null or undefined when calling addFavoriteMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/favorites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionMedicationToJSON(requestParameters.medication),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoritePrescriptionMedicationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addFavoriteMedicationRequestOptionsRaw(requestParameters: AddFavoriteMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.medication === null || requestParameters.medication === undefined) {
            throw new runtime.RequiredError('medication','Required parameter requestParameters.medication was null or undefined when calling addFavoriteMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/favorites`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionMedicationToJSON(requestParameters.medication),
        };
        return responseConfig
    }

    /**
     * Adds a Physician\'s favorite medication (Roles: physician)
     */
    async addFavoriteMedication(requestParameters: AddFavoriteMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavoritePrescriptionMedication> {
        const response = await this.addFavoriteMedicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the Physician\'s favorite medications (Roles: physician)
     */
    async getFavoriteMedicationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FavoritePrescriptionMedication>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/favorites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FavoritePrescriptionMedicationFromJSON));
    }

    /**
        Request Config Getter
     */
    getFavoriteMedicationsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/favorites`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the Physician\'s favorite medications (Roles: physician)
     */
    async getFavoriteMedications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FavoritePrescriptionMedication>> {
        const response = await this.getFavoriteMedicationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets prescription products (Roles: physician, admin)
     */
    async getProductsRaw(requestParameters: GetProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrugbankProduct>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/drugbank/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrugbankProductFromJSON));
    }

    /**
        Request Config Getter
     */
    getProductsRequestOptionsRaw(requestParameters: GetProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProducts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/drugbank/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets prescription products (Roles: physician, admin)
     */
    async getProducts(requestParameters: GetProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrugbankProduct>> {
        const response = await this.getProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets routes for a drug (Roles: physician, admin)
     */
    async getRoutesRaw(requestParameters: GetRoutesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrugbankProductConcept>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRoutes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/drugbank/routes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrugbankProductConceptFromJSON));
    }

    /**
        Request Config Getter
     */
    getRoutesRequestOptionsRaw(requestParameters: GetRoutesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRoutes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/drugbank/routes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets routes for a drug (Roles: physician, admin)
     */
    async getRoutes(requestParameters: GetRoutesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrugbankProductConcept>> {
        const response = await this.getRoutesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets strengths for a drug (Roles: physician, admin)
     */
    async getStrengthsRaw(requestParameters: GetStrengthsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrugbankProductConcept>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStrengths.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/drugbank/strengths/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrugbankProductConceptFromJSON));
    }

    /**
        Request Config Getter
     */
    getStrengthsRequestOptionsRaw(requestParameters: GetStrengthsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStrengths.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/drugbank/strengths/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets strengths for a drug (Roles: physician, admin)
     */
    async getStrengths(requestParameters: GetStrengthsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrugbankProductConcept>> {
        const response = await this.getStrengthsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a Physician\'s favorite medication (Roles: physician)
     */
    async removeFavoriteMedicationRaw(requestParameters: RemoveFavoriteMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeFavoriteMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/favorites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removeFavoriteMedicationRequestOptionsRaw(requestParameters: RemoveFavoriteMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeFavoriteMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/favorites/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a Physician\'s favorite medication (Roles: physician)
     */
    async removeFavoriteMedication(requestParameters: RemoveFavoriteMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeFavoriteMedicationRaw(requestParameters, initOverrides);
    }

    /**
     * Search for a drugbank drug (Roles: physician, admin)
     */
    async searchDrugRaw(requestParameters: SearchDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DrugbankProductConcept>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling searchDrug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/drugs/drugbank/search/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DrugbankProductConceptFromJSON));
    }

    /**
        Request Config Getter
     */
    searchDrugRequestOptionsRaw(requestParameters: SearchDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling searchDrug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/drugs/drugbank/search/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Search for a drugbank drug (Roles: physician, admin)
     */
    async searchDrug(requestParameters: SearchDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DrugbankProductConcept>> {
        const response = await this.searchDrugRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
