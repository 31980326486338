/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EfaxConfig } from './EfaxConfig';
import {
    EfaxConfigFromJSON,
    EfaxConfigFromJSONTyped,
    EfaxConfigToJSON,
} from './EfaxConfig';
import type { RingcentralConfig } from './RingcentralConfig';
import {
    RingcentralConfigFromJSON,
    RingcentralConfigFromJSONTyped,
    RingcentralConfigToJSON,
} from './RingcentralConfig';

/**
 * 
 * @export
 * @interface FaxProvider
 */
export interface FaxProvider {
    /**
     * 
     * @type {string}
     * @memberof FaxProvider
     */
    provider_id: FaxProviderProviderIdEnum;
    /**
     * 
     * @type {RingcentralConfig}
     * @memberof FaxProvider
     */
    ringcentral_config?: RingcentralConfig;
    /**
     * 
     * @type {EfaxConfig}
     * @memberof FaxProvider
     */
    efax_config?: EfaxConfig;
}


/**
 * @export
 */
export const FaxProviderProviderIdEnum = {
    Efax: 'Efax',
    Ringcentral: 'Ringcentral'
} as const;
export type FaxProviderProviderIdEnum = typeof FaxProviderProviderIdEnum[keyof typeof FaxProviderProviderIdEnum];


/**
 * Check if a given object implements the FaxProvider interface.
 */
export function instanceOfFaxProvider(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "provider_id" in value;

    return isInstance;
}

export function FaxProviderFromJSON(json: any): FaxProvider {
    return FaxProviderFromJSONTyped(json, false);
}

export function FaxProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaxProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider_id': json['provider_id'],
        'ringcentral_config': !exists(json, 'ringcentral_config') ? undefined : RingcentralConfigFromJSON(json['ringcentral_config']),
        'efax_config': !exists(json, 'efax_config') ? undefined : EfaxConfigFromJSON(json['efax_config']),
    };
}

export function FaxProviderToJSON(value?: FaxProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider_id': value.provider_id,
        'ringcentral_config': RingcentralConfigToJSON(value.ringcentral_config),
        'efax_config': EfaxConfigToJSON(value.efax_config),
    };
}

