import { AnyZodObject, z } from 'zod';

export const ChatUpdated = z.object({
  type: z.literal('chat'),
  object: z.object({
    roomName: z.string(),
    userId: z.string(),
  }),
});

export const JoinTelemedicine = z.object({
  type: z.literal('telemedicine'),
  userId: z.string().optional(),
  object: z.object({
    userId: z.string().optional(),
    id: z.string(),
    jitsi: z.boolean().optional(),
  }),
});

export const AppointmentUpdated = z.object({
  type: z.literal('appointment'),
  object: z.object({
    userId: z.string().optional(),
    id: z.string(),
    check_outdated: z.boolean().optional(),
  }),
});

const HR_IDS = ['prescription', 'immunization', 'document', 'pending_document'] as const;
export const HRUpdated = z.object({
  type: z.enum(HR_IDS),
  userId: z.string().optional(),
  object: z.object({
    userId: z.string().optional(),
    id: z.string(),
  }),
});

const OTHER_IDS = ['subscription', 'version_update', 'health_records', 'menstruation'] as const;

export const Other = z.object({
  type: z.enum(OTHER_IDS),
  userId: z.string().optional(),
});

export const MedicationReminder = z.object({
  type: z.literal('medication-reminder'),
  object: z.object({
    userId: z.string().optional(),
    notifications: z.array(z.object({ date: z.number(), reminderId: z.string() })),
  }),
});

export const DefaultPushNotificationParser = {
  chatUpdated: getParser(ChatUpdated),
  joinTelemedicine: getParser(JoinTelemedicine),
  appointmentUpdated: getParser(AppointmentUpdated),
  hrUpdated: getParser(HRUpdated),
  medicationReminder: getParser(MedicationReminder),
  other: getParser(Other),
} as const;

export type PushNotificationParser = typeof DefaultPushNotificationParser;

// UTILITIES

type Parser<T> = (arg: unknown) => T;

function getParser<T extends AnyZodObject>(arg: T) {
  return arg.parse as Parser<z.infer<typeof arg>>;
}

export function tryParse<T>(parser: Parser<T>, object: unknown): object is T {
  try {
    parser(object);
    return true;
  } catch (e: unknown) {
    return false;
  }
}
