import { createListenerMiddleware } from "@reduxjs/toolkit"
import type { RootState } from "careand-redux/store"
import { getActivePath, navigationActions } from "careand-redux/slices/ui/navigation"

export const navigationListener = createListenerMiddleware()

navigationListener.startListening({
  predicate: (action) => !!action.type?.startsWith('Navigation/') && action.type !== navigationActions.pathnameChanged.type,
  effect: (action, api) => {
    const oldState = api.getOriginalState() as RootState
    const state = api.getState() as RootState
    const oldPath = getActivePath(oldState.navigation)
    const path = getActivePath(state.navigation)

    if (oldPath !== path) {
      const [pathname, query] = path.split('?')
      const url = new URL(window.location.toString())
      url.pathname = pathname
      url.search = query ?? ''
      const newUrl = url.toString()

      if (action.type === navigationActions.replaced.type) {
        window.history.replaceState({}, '', newUrl)
      } else {
        window.history.pushState({}, '', newUrl)
      }
    }
  }
})
