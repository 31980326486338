/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CanadaPostSearch
 */
export interface CanadaPostSearch {
    /**
     * 
     * @type {string}
     * @memberof CanadaPostSearch
     */
    Id?: string;
    /**
     * 
     * @type {string}
     * @memberof CanadaPostSearch
     */
    Text?: string;
    /**
     * 
     * @type {string}
     * @memberof CanadaPostSearch
     */
    Highlight?: string;
    /**
     * 
     * @type {string}
     * @memberof CanadaPostSearch
     */
    Cursor?: string;
    /**
     * 
     * @type {string}
     * @memberof CanadaPostSearch
     */
    Description?: string;
    /**
     * 
     * @type {string}
     * @memberof CanadaPostSearch
     */
    Next?: string;
}

/**
 * Check if a given object implements the CanadaPostSearch interface.
 */
export function instanceOfCanadaPostSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CanadaPostSearchFromJSON(json: any): CanadaPostSearch {
    return CanadaPostSearchFromJSONTyped(json, false);
}

export function CanadaPostSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanadaPostSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Id': !exists(json, 'Id') ? undefined : json['Id'],
        'Text': !exists(json, 'Text') ? undefined : json['Text'],
        'Highlight': !exists(json, 'Highlight') ? undefined : json['Highlight'],
        'Cursor': !exists(json, 'Cursor') ? undefined : json['Cursor'],
        'Description': !exists(json, 'Description') ? undefined : json['Description'],
        'Next': !exists(json, 'Next') ? undefined : json['Next'],
    };
}

export function CanadaPostSearchToJSON(value?: CanadaPostSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.Id,
        'Text': value.Text,
        'Highlight': value.Highlight,
        'Cursor': value.Cursor,
        'Description': value.Description,
        'Next': value.Next,
    };
}

