/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultantHistory } from './ConsultantHistory';
import {
    ConsultantHistoryFromJSON,
    ConsultantHistoryFromJSONTyped,
    ConsultantHistoryToJSON,
} from './ConsultantHistory';
import type { PhysicianContact } from './PhysicianContact';
import {
    PhysicianContactFromJSON,
    PhysicianContactFromJSONTyped,
    PhysicianContactToJSON,
} from './PhysicianContact';

/**
 * 
 * @export
 * @interface Consultant
 */
export interface Consultant {
    /**
     * 
     * @type {string}
     * @memberof Consultant
     */
    id?: string;
    /**
     * 
     * @type {PhysicianContact}
     * @memberof Consultant
     */
    value: PhysicianContact;
    /**
     * 
     * @type {Array<ConsultantHistory>}
     * @memberof Consultant
     */
    history?: Array<ConsultantHistory>;
}

/**
 * Check if a given object implements the Consultant interface.
 */
export function instanceOfConsultant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ConsultantFromJSON(json: any): Consultant {
    return ConsultantFromJSONTyped(json, false);
}

export function ConsultantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Consultant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': PhysicianContactFromJSON(json['value']),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(ConsultantHistoryFromJSON)),
    };
}

export function ConsultantToJSON(value?: Consultant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value': PhysicianContactToJSON(value.value),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(ConsultantHistoryToJSON)),
    };
}

