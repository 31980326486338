/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HealthMetric } from './HealthMetric';
import {
    HealthMetricFromJSON,
    HealthMetricFromJSONTyped,
    HealthMetricToJSON,
} from './HealthMetric';

/**
 * 
 * @export
 * @interface AppointmentNoteValue
 */
export interface AppointmentNoteValue {
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteValue
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteValue
     */
    index_patient?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteValue
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteValue
     */
    date?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentNoteValue
     */
    hide?: boolean;
    /**
     * 
     * @type {Array<HealthMetric>}
     * @memberof AppointmentNoteValue
     */
    metrics?: Array<HealthMetric>;
}

/**
 * Check if a given object implements the AppointmentNoteValue interface.
 */
export function instanceOfAppointmentNoteValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AppointmentNoteValueFromJSON(json: any): AppointmentNoteValue {
    return AppointmentNoteValueFromJSONTyped(json, false);
}

export function AppointmentNoteValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentNoteValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'index_patient': !exists(json, 'index_patient') ? undefined : json['index_patient'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'metrics': !exists(json, 'metrics') ? undefined : ((json['metrics'] as Array<any>).map(HealthMetricFromJSON)),
    };
}

export function AppointmentNoteValueToJSON(value?: AppointmentNoteValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'index_patient': value.index_patient,
        'notes': value.notes,
        'date': value.date,
        'hide': value.hide,
        'metrics': value.metrics === undefined ? undefined : ((value.metrics as Array<any>).map(HealthMetricToJSON)),
    };
}

