/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TabOrderingSettings
 */
export interface TabOrderingSettings {
    /**
     * 
     * @type {Array<string>}
     * @memberof TabOrderingSettings
     */
    shown: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TabOrderingSettings
     */
    hidden: Array<string>;
}

/**
 * Check if a given object implements the TabOrderingSettings interface.
 */
export function instanceOfTabOrderingSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shown" in value;
    isInstance = isInstance && "hidden" in value;

    return isInstance;
}

export function TabOrderingSettingsFromJSON(json: any): TabOrderingSettings {
    return TabOrderingSettingsFromJSONTyped(json, false);
}

export function TabOrderingSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TabOrderingSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shown': json['shown'],
        'hidden': json['hidden'],
    };
}

export function TabOrderingSettingsToJSON(value?: TabOrderingSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shown': value.shown,
        'hidden': value.hidden,
    };
}

