/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeviceToken,
  ErrorResponse,
  UserNotification,
} from '../models';
import {
    DeviceTokenFromJSON,
    DeviceTokenToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UserNotificationFromJSON,
    UserNotificationToJSON,
} from '../models';

export interface GetUserNotificationsRequest {
    userId: string;
}

export interface SubscribePatientDeviceRequest {
    patientId: string;
    device_token: DeviceToken;
}

export interface SubscribePhysicianDeviceRequest {
    device_token: DeviceToken;
}

export interface UnsubscribePatientDeviceRequest {
    patientId: string;
    device_token: DeviceToken;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Gets a user\'s notifications (Roles: physician, admin)
     */
    async getUserNotificationsRaw(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserNotification>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/notifications/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserNotificationFromJSON));
    }

    /**
        Request Config Getter
     */
    getUserNotificationsRequestOptionsRaw(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/notifications/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a user\'s notifications (Roles: physician, admin)
     */
    async getUserNotifications(requestParameters: GetUserNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserNotification>> {
        const response = await this.getUserNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Subscribes device for the patient\'s notifications (Roles: patient)
     */
    async subscribePatientDeviceRaw(requestParameters: SubscribePatientDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling subscribePatientDevice.');
        }

        if (requestParameters.device_token === null || requestParameters.device_token === undefined) {
            throw new runtime.RequiredError('device_token','Required parameter requestParameters.device_token was null or undefined when calling subscribePatientDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/notifications/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenToJSON(requestParameters.device_token),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    subscribePatientDeviceRequestOptionsRaw(requestParameters: SubscribePatientDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling subscribePatientDevice.');
        }

        if (requestParameters.device_token === null || requestParameters.device_token === undefined) {
            throw new runtime.RequiredError('device_token','Required parameter requestParameters.device_token was null or undefined when calling subscribePatientDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/notifications/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenToJSON(requestParameters.device_token),
        };
        return responseConfig
    }

    /**
     * Subscribes device for the patient\'s notifications (Roles: patient)
     */
    async subscribePatientDevice(requestParameters: SubscribePatientDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.subscribePatientDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Subscribes device for the physician\'s notifications (Roles: physician)
     */
    async subscribePhysicianDeviceRaw(requestParameters: SubscribePhysicianDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.device_token === null || requestParameters.device_token === undefined) {
            throw new runtime.RequiredError('device_token','Required parameter requestParameters.device_token was null or undefined when calling subscribePhysicianDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/notifications/physician`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenToJSON(requestParameters.device_token),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    subscribePhysicianDeviceRequestOptionsRaw(requestParameters: SubscribePhysicianDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.device_token === null || requestParameters.device_token === undefined) {
            throw new runtime.RequiredError('device_token','Required parameter requestParameters.device_token was null or undefined when calling subscribePhysicianDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/notifications/physician`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenToJSON(requestParameters.device_token),
        };
        return responseConfig
    }

    /**
     * Subscribes device for the physician\'s notifications (Roles: physician)
     */
    async subscribePhysicianDevice(requestParameters: SubscribePhysicianDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.subscribePhysicianDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribes device from the patient\'s notifications (Roles: patient)
     */
    async unsubscribePatientDeviceRaw(requestParameters: UnsubscribePatientDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling unsubscribePatientDevice.');
        }

        if (requestParameters.device_token === null || requestParameters.device_token === undefined) {
            throw new runtime.RequiredError('device_token','Required parameter requestParameters.device_token was null or undefined when calling unsubscribePatientDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/notifications/patient/{patientId}/unsubscribe`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenToJSON(requestParameters.device_token),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    unsubscribePatientDeviceRequestOptionsRaw(requestParameters: UnsubscribePatientDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling unsubscribePatientDevice.');
        }

        if (requestParameters.device_token === null || requestParameters.device_token === undefined) {
            throw new runtime.RequiredError('device_token','Required parameter requestParameters.device_token was null or undefined when calling unsubscribePatientDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/notifications/patient/{patientId}/unsubscribe`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenToJSON(requestParameters.device_token),
        };
        return responseConfig
    }

    /**
     * Unsubscribes device from the patient\'s notifications (Roles: patient)
     */
    async unsubscribePatientDevice(requestParameters: UnsubscribePatientDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unsubscribePatientDeviceRaw(requestParameters, initOverrides);
    }

}
