import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { initialize, change } from 'redux-form';
import printJS from 'print-js';
import PerformanceUtils from 'services/PerformanceUtils';
import disconnectSound from 'assets/audio/disconnect.mp3';
import harpSound from 'assets/audio/harp.mp3';
import blopSound from 'assets/audio/blop.mp3';
import messageReceivedSound from 'assets/audio/message-received.mp3';
import swooshSound from 'assets/audio/swoosh.mp3';
import klaxonSound from 'assets/audio/klaxon.mp3';
import { toastr } from 'react-redux-toastr';
import config from '../config/config.json';
import { toast } from 'components/Toasts';
import buildVersion from '../config/buildVersion.json';
import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag.start({
  apiKey: 'daa4dce0f1c2826d2b585c40e09804b2',
  appVersion: buildVersion.buildVersion,
  plugins: [new BugsnagPluginReact()],
});

const COMMON_MIME_TYPES = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'image/tiff': 'tif',
  'audio/mpeg': 'mp3',
  'audio/mp3': 'mp3',
  'audio/x-wav': 'wav',
  'video/mp4': 'mp4',
  'video/webm': 'webm',
  'application/pdf': 'pdf',
};

class MiscUtils {
  static printUrl(url) {
    printJS(url);
  }

  static bytesToMegabytes(bytes) {
    return bytes / 1000000;
  }

  static async readBlobWithFileReader(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', reject);
      reader.readAsDataURL(blob);
    });
  }

  static getBlobUrl(blob) {
    if (blob) {
      let urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    }
  }

  static async getLocalUrlFromUrl(url) {
    return MiscUtils.urlToBlob(url).then((blob) => MiscUtils.getBlobUrl(blob));
  }

  static dataUrlToBlobUrl(dataUrl) {
    const [typeInfo, base64Data] = dataUrl.split(',');
    const mimeType = typeInfo.match(/data:(.*)/)[1];
    const isBase64 = typeInfo.indexOf('base64') >= 0;
    const decodedData = isBase64 ? atob(base64Data) : decodeURIComponent(base64Data);
    const byteNumbers = new Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      byteNumbers[i] = decodedData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: mimeType});
    return URL.createObjectURL(blob);
  }

  static fileExtensionFromMime(mimeType) {
    return COMMON_MIME_TYPES[mimeType] || null;
  }

  static async urlToBlob(url, type) {
    let response = await fetch(url);
    const reader = response.body.getReader();
    let chunks = [];
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      chunks.push(value);
    }
    return new Blob(chunks, { type });
  }

  static async urlToFile(url, type, filename) {
    return fetch(url)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        return new File([blob], filename, { type });
      });
  }

  static playAudio(filename, volume = 0.25) {
    const audio = new Audio(filename);
    audio.volume = volume;
    try {
      audio.play();
    } catch (err) {}
  }

  static playKlaxonSound() {
    MiscUtils.playAudio(klaxonSound, 1);
  }

  static playDisconnectSound() {
    MiscUtils.playAudio(disconnectSound);
  }

  static playNewChat() {
    MiscUtils.playAudio(blopSound);
  }

  static playSentDocumentSound() {
    MiscUtils.playAudio(swooshSound);
  }

  static playQueueSound() {
    MiscUtils.playAudio(harpSound);
  }

  static playMessageSound() {
    MiscUtils.playAudio(messageReceivedSound);
  }

  static showSuccess(title, text = title + ' success!') {
    if (config.PATIENT === true) {
      toast.success(text);
    } else {
      toastr.success(title, text);
    }
  }

  static showToastrError(title, message) {
    if (config.PATIENT === true) {
      toast.error(message);
    } else {
      toastr.error(title, message);
    }
  }

  static openExternalLink(url) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noreferrer noopener';
    a.click();
  }

  static getFullAddress(address, includeNotes = false, hideFields = []) {
    if (address === undefined) return '';

    address = PerformanceUtils.clone(address);
    hideFields.forEach((field) => {
      delete address[field];
    });

    let fields = [
      address.line1,
      address.line2,
      address.postcode,
      address.city,
      address.state,
      address.country,
    ];

    if (includeNotes && address.notes) fields.push('Notes: ' + address.notes);

    fields = fields.filter((item) => item !== undefined && item !== '');
    return fields.join(', ');
  }

  static showBrowserNotification(notification) {
    if (window.Notification && Notification.permission !== 'denied') {
      Notification.requestPermission((status) => {
        // status is "granted", if accepted by user
        if (status === 'granted' && window.document.hidden) {
          //Show browser native notification
          new Notification(notification.title, { body: notification.message });
        } else {
          MiscUtils.showToastrInfo(notification);
        }
      });
    } else {
      MiscUtils.showToastrInfo(notification);
    }
  }

  static getBrowserFingerprint(cb) {
    const fpPromise = FingerprintJS.load();
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        cb(result.visitorId);
      });
  }

  static showClinicMessage(message) {
    let options = {
      attention: true,
      timeOut: 'infinite',
      removeOnHover: false,
    };
    MiscUtils.showToastrInfo({ title: 'Care& Family Health Message', message }, options);
  }

  static showToastrInfo(
    notification,
    options = {
      timeOut: 60 * 1000,
      showCloseButton: false,
      closeOnToastrClick: true,
    },
  ) {
    if (config.PATIENT === true) {
      toast.info(notification.message, { duration: options.timeOut });
    } else {
      toastr.info(notification.title, notification.message, options);
    }
  }

  static changeForm(props, values = {}) {
    if (!!props.initialize) {
      props.initialize(values);
    } else {
      initialize(props.form, values);
    }
  }

  static smallChangeForm(form, values) {
    return change(form, values);
  }

  static smallChangeForm2(form, id, value) {
    return change(form, id, value);
  }

  static resetForm(props) {
    MiscUtils.changeForm(props, {});
  }

  static getBugsnagClient() {
    return bugsnagClient;
  }
}

export default MiscUtils;
