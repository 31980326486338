/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HoursOperationDay
 */
export interface HoursOperationDay {
    /**
     * 
     * @type {number}
     * @memberof HoursOperationDay
     */
    open?: number;
    /**
     * 
     * @type {number}
     * @memberof HoursOperationDay
     */
    close?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HoursOperationDay
     */
    shut?: boolean;
}

/**
 * Check if a given object implements the HoursOperationDay interface.
 */
export function instanceOfHoursOperationDay(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HoursOperationDayFromJSON(json: any): HoursOperationDay {
    return HoursOperationDayFromJSONTyped(json, false);
}

export function HoursOperationDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): HoursOperationDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'open': !exists(json, 'open') ? undefined : json['open'],
        'close': !exists(json, 'close') ? undefined : json['close'],
        'shut': !exists(json, 'shut') ? undefined : json['shut'],
    };
}

export function HoursOperationDayToJSON(value?: HoursOperationDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'open': value.open,
        'close': value.close,
        'shut': value.shut,
    };
}

