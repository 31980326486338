import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import GenericBadge from 'components/Utils/GenericBadge';
import AdminUtils from 'services/AdminUtils';
import { AdminImage } from 'components/Common/UserImage';
import './style.scss';
import { useAppSelector } from 'careand-redux/store';

const AdminBadge = ({ admin }) => {
  const clinicId = admin?.clinic_id;
  const clinics = useAppSelector((state) => state.resource.clinics);

  const clinic = useMemo(() => {
    return clinics.find((clinic) => clinic.id === clinicId);
  }, [clinics, clinicId]);

  return (
    admin !== undefined && (
      <GenericBadge
        id={admin ? 'admin-badge-' + admin.id : undefined}
        key={admin ? admin.id : 'no-admin'}
        className={getClassname(admin)}
        title={AdminUtils.getName(admin)}
        avatar={<AdminImage user={admin} size={40} />}
        info={<span>{'Admin' + (clinic ? ` (${clinic?.name})` : '')}</span>}
      />
    )
  );
};

function getClassname(admin = {}) {
  let className = 'admin-badge';
  if (admin.inactive === true) className += ' inactive';
  return className;
}

AdminBadge.propTypes = {
  admin: PropTypes.object,
  clinics: PropTypes.object,
};

export default AdminBadge;
