/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceToken } from './DeviceToken';
import {
    DeviceTokenFromJSON,
    DeviceTokenFromJSONTyped,
    DeviceTokenToJSON,
} from './DeviceToken';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role: UserRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    inactive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    permissions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    token_2fa?: string;
    /**
     * 
     * @type {DeviceToken}
     * @memberof User
     */
    push_token_2fa?: DeviceToken;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    sms_2fa?: boolean;
}


/**
 * @export
 */
export const UserRoleEnum = {
    Admin: 'admin',
    Patient: 'patient',
    Physician: 'physician',
    Kiosk: 'kiosk'
} as const;
export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'password': json['password'],
        'role': json['role'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'token_2fa': !exists(json, 'token_2fa') ? undefined : json['token_2fa'],
        'push_token_2fa': !exists(json, 'push_token_2fa') ? undefined : DeviceTokenFromJSON(json['push_token_2fa']),
        'sms_2fa': !exists(json, 'sms_2fa') ? undefined : json['sms_2fa'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'password': value.password,
        'role': value.role,
        'inactive': value.inactive,
        'permissions': value.permissions,
        'token_2fa': value.token_2fa,
        'push_token_2fa': DeviceTokenToJSON(value.push_token_2fa),
        'sms_2fa': value.sms_2fa,
    };
}

