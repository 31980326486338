/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MTDate } from './MTDate';
import {
    MTDateFromJSON,
    MTDateFromJSONTyped,
    MTDateToJSON,
} from './MTDate';

/**
 * 
 * @export
 * @interface MTCycle
 */
export interface MTCycle {
    /**
     * 
     * @type {Array<MTDate>}
     * @memberof MTCycle
     */
    dates?: Array<MTDate>;
    /**
     * 
     * @type {number}
     * @memberof MTCycle
     */
    ovulation_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MTCycle
     */
    ovulation_start_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MTCycle
     */
    ovulation_end_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MTCycle
     */
    menstruation_start_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MTCycle
     */
    menstruation_end_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MTCycle
     */
    cycle_end_date?: number;
}

/**
 * Check if a given object implements the MTCycle interface.
 */
export function instanceOfMTCycle(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MTCycleFromJSON(json: any): MTCycle {
    return MTCycleFromJSONTyped(json, false);
}

export function MTCycleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MTCycle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dates': !exists(json, 'dates') ? undefined : ((json['dates'] as Array<any>).map(MTDateFromJSON)),
        'ovulation_date': !exists(json, 'ovulation_date') ? undefined : json['ovulation_date'],
        'ovulation_start_date': !exists(json, 'ovulation_start_date') ? undefined : json['ovulation_start_date'],
        'ovulation_end_date': !exists(json, 'ovulation_end_date') ? undefined : json['ovulation_end_date'],
        'menstruation_start_date': !exists(json, 'menstruation_start_date') ? undefined : json['menstruation_start_date'],
        'menstruation_end_date': !exists(json, 'menstruation_end_date') ? undefined : json['menstruation_end_date'],
        'cycle_end_date': !exists(json, 'cycle_end_date') ? undefined : json['cycle_end_date'],
    };
}

export function MTCycleToJSON(value?: MTCycle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dates': value.dates === undefined ? undefined : ((value.dates as Array<any>).map(MTDateToJSON)),
        'ovulation_date': value.ovulation_date,
        'ovulation_start_date': value.ovulation_start_date,
        'ovulation_end_date': value.ovulation_end_date,
        'menstruation_start_date': value.menstruation_start_date,
        'menstruation_end_date': value.menstruation_end_date,
        'cycle_end_date': value.cycle_end_date,
    };
}

