/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicationReminderNotification
 */
export interface MedicationReminderNotification {
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderNotification
     */
    reminderId: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderNotification
     */
    patientId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedicationReminderNotification
     */
    ruleIdx: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderNotification
     */
    date: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationReminderNotification
     */
    isPrn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderNotification
     */
    pills_left?: number;
}

/**
 * Check if a given object implements the MedicationReminderNotification interface.
 */
export function instanceOfMedicationReminderNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reminderId" in value;
    isInstance = isInstance && "patientId" in value;
    isInstance = isInstance && "ruleIdx" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function MedicationReminderNotificationFromJSON(json: any): MedicationReminderNotification {
    return MedicationReminderNotificationFromJSONTyped(json, false);
}

export function MedicationReminderNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminderNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reminderId': json['reminderId'],
        'patientId': json['patientId'],
        'ruleIdx': json['ruleIdx'],
        'date': json['date'],
        'isPrn': !exists(json, 'isPrn') ? undefined : json['isPrn'],
        'pills_left': !exists(json, 'pills_left') ? undefined : json['pills_left'],
    };
}

export function MedicationReminderNotificationToJSON(value?: MedicationReminderNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reminderId': value.reminderId,
        'patientId': value.patientId,
        'ruleIdx': value.ruleIdx,
        'date': value.date,
        'isPrn': value.isPrn,
        'pills_left': value.pills_left,
    };
}

