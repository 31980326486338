/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Appointment } from './Appointment';
import {
    AppointmentFromJSON,
    AppointmentFromJSONTyped,
    AppointmentToJSON,
} from './Appointment';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';
import type { PhysicianRoster } from './PhysicianRoster';
import {
    PhysicianRosterFromJSON,
    PhysicianRosterFromJSONTyped,
    PhysicianRosterToJSON,
} from './PhysicianRoster';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
} from './Shift';
import type { StatisticsEvent } from './StatisticsEvent';
import {
    StatisticsEventFromJSON,
    StatisticsEventFromJSONTyped,
    StatisticsEventToJSON,
} from './StatisticsEvent';
import type { StatisticsValues } from './StatisticsValues';
import {
    StatisticsValuesFromJSON,
    StatisticsValuesFromJSONTyped,
    StatisticsValuesToJSON,
} from './StatisticsValues';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface ClinicStatisticsNew
 */
export interface ClinicStatisticsNew {
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof ClinicStatisticsNew
     */
    appointments?: Array<Appointment>;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof ClinicStatisticsNew
     */
    invoices?: Array<Invoice>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof ClinicStatisticsNew
     */
    subscriptions?: Array<Subscription>;
    /**
     * 
     * @type {Array<StatisticsValues>}
     * @memberof ClinicStatisticsNew
     */
    subscriptionStats?: Array<StatisticsValues>;
    /**
     * 
     * @type {Array<StatisticsEvent>}
     * @memberof ClinicStatisticsNew
     */
    events?: Array<StatisticsEvent>;
    /**
     * 
     * @type {Array<PhysicianRoster>}
     * @memberof ClinicStatisticsNew
     */
    rosters?: Array<PhysicianRoster>;
    /**
     * 
     * @type {Array<Shift>}
     * @memberof ClinicStatisticsNew
     */
    shifts?: Array<Shift>;
    /**
     * 
     * @type {Array<StatisticsValues>}
     * @memberof ClinicStatisticsNew
     */
    blocked?: Array<StatisticsValues>;
    /**
     * 
     * @type {Array<StatisticsEvent>}
     * @memberof ClinicStatisticsNew
     */
    clinicChanges?: Array<StatisticsEvent>;
}

/**
 * Check if a given object implements the ClinicStatisticsNew interface.
 */
export function instanceOfClinicStatisticsNew(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClinicStatisticsNewFromJSON(json: any): ClinicStatisticsNew {
    return ClinicStatisticsNewFromJSONTyped(json, false);
}

export function ClinicStatisticsNewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicStatisticsNew {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointments': !exists(json, 'appointments') ? undefined : ((json['appointments'] as Array<any>).map(AppointmentFromJSON)),
        'invoices': !exists(json, 'invoices') ? undefined : ((json['invoices'] as Array<any>).map(InvoiceFromJSON)),
        'subscriptions': !exists(json, 'subscriptions') ? undefined : ((json['subscriptions'] as Array<any>).map(SubscriptionFromJSON)),
        'subscriptionStats': !exists(json, 'subscriptionStats') ? undefined : ((json['subscriptionStats'] as Array<any>).map(StatisticsValuesFromJSON)),
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(StatisticsEventFromJSON)),
        'rosters': !exists(json, 'rosters') ? undefined : ((json['rosters'] as Array<any>).map(PhysicianRosterFromJSON)),
        'shifts': !exists(json, 'shifts') ? undefined : ((json['shifts'] as Array<any>).map(ShiftFromJSON)),
        'blocked': !exists(json, 'blocked') ? undefined : ((json['blocked'] as Array<any>).map(StatisticsValuesFromJSON)),
        'clinicChanges': !exists(json, 'clinicChanges') ? undefined : ((json['clinicChanges'] as Array<any>).map(StatisticsEventFromJSON)),
    };
}

export function ClinicStatisticsNewToJSON(value?: ClinicStatisticsNew | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointments': value.appointments === undefined ? undefined : ((value.appointments as Array<any>).map(AppointmentToJSON)),
        'invoices': value.invoices === undefined ? undefined : ((value.invoices as Array<any>).map(InvoiceToJSON)),
        'subscriptions': value.subscriptions === undefined ? undefined : ((value.subscriptions as Array<any>).map(SubscriptionToJSON)),
        'subscriptionStats': value.subscriptionStats === undefined ? undefined : ((value.subscriptionStats as Array<any>).map(StatisticsValuesToJSON)),
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(StatisticsEventToJSON)),
        'rosters': value.rosters === undefined ? undefined : ((value.rosters as Array<any>).map(PhysicianRosterToJSON)),
        'shifts': value.shifts === undefined ? undefined : ((value.shifts as Array<any>).map(ShiftToJSON)),
        'blocked': value.blocked === undefined ? undefined : ((value.blocked as Array<any>).map(StatisticsValuesToJSON)),
        'clinicChanges': value.clinicChanges === undefined ? undefined : ((value.clinicChanges as Array<any>).map(StatisticsEventToJSON)),
    };
}

