/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppointmentNoteHealthRecords } from './AppointmentNoteHealthRecords';
import {
    AppointmentNoteHealthRecordsFromJSON,
    AppointmentNoteHealthRecordsFromJSONTyped,
    AppointmentNoteHealthRecordsToJSON,
} from './AppointmentNoteHealthRecords';
import type { AppointmentNoteHistory } from './AppointmentNoteHistory';
import {
    AppointmentNoteHistoryFromJSON,
    AppointmentNoteHistoryFromJSONTyped,
    AppointmentNoteHistoryToJSON,
} from './AppointmentNoteHistory';
import type { AppointmentNoteValue } from './AppointmentNoteValue';
import {
    AppointmentNoteValueFromJSON,
    AppointmentNoteValueFromJSONTyped,
    AppointmentNoteValueToJSON,
} from './AppointmentNoteValue';

/**
 * 
 * @export
 * @interface AppointmentNote
 */
export interface AppointmentNote {
    /**
     * 
     * @type {string}
     * @memberof AppointmentNote
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNote
     */
    author_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNote
     */
    author_role?: string;
    /**
     * 
     * @type {AppointmentNoteValue}
     * @memberof AppointmentNote
     */
    value: AppointmentNoteValue;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNote
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNote
     */
    item_type: AppointmentNoteItemTypeEnum;
    /**
     * 
     * @type {Array<AppointmentNoteHistory>}
     * @memberof AppointmentNote
     */
    history?: Array<AppointmentNoteHistory>;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentNote
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNote
     */
    waiting_approval?: string;
    /**
     * 
     * @type {AppointmentNoteHealthRecords}
     * @memberof AppointmentNote
     */
    health_records?: AppointmentNoteHealthRecords;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentNote
     */
    documents?: Array<string>;
}


/**
 * @export
 */
export const AppointmentNoteItemTypeEnum = {
    Text: 'text',
    Attachment: 'attachment'
} as const;
export type AppointmentNoteItemTypeEnum = typeof AppointmentNoteItemTypeEnum[keyof typeof AppointmentNoteItemTypeEnum];


/**
 * Check if a given object implements the AppointmentNote interface.
 */
export function instanceOfAppointmentNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "item_type" in value;

    return isInstance;
}

export function AppointmentNoteFromJSON(json: any): AppointmentNote {
    return AppointmentNoteFromJSONTyped(json, false);
}

export function AppointmentNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'author_id': !exists(json, 'author_id') ? undefined : json['author_id'],
        'author_role': !exists(json, 'author_role') ? undefined : json['author_role'],
        'value': AppointmentNoteValueFromJSON(json['value']),
        'patient_id': json['patient_id'],
        'item_type': json['item_type'],
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(AppointmentNoteHistoryFromJSON)),
        'read': !exists(json, 'read') ? undefined : json['read'],
        'waiting_approval': !exists(json, 'waiting_approval') ? undefined : json['waiting_approval'],
        'health_records': !exists(json, 'health_records') ? undefined : AppointmentNoteHealthRecordsFromJSON(json['health_records']),
        'documents': !exists(json, 'documents') ? undefined : json['documents'],
    };
}

export function AppointmentNoteToJSON(value?: AppointmentNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'author_id': value.author_id,
        'author_role': value.author_role,
        'value': AppointmentNoteValueToJSON(value.value),
        'patient_id': value.patient_id,
        'item_type': value.item_type,
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(AppointmentNoteHistoryToJSON)),
        'read': value.read,
        'waiting_approval': value.waiting_approval,
        'health_records': AppointmentNoteHealthRecordsToJSON(value.health_records),
        'documents': value.documents,
    };
}

