/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback } from 'react';
import { Action, AnyAction, AsyncThunkAction, Dispatch, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from './store';
import { isResponseError } from './api/SerializedReponseError';
import { handleResponseErrorCurried, handleResponseSuccess } from 'services/ApiResponseUtils';

type PromiseEither<T extends Promise<any>, Fallback> = T extends Promise<infer S>
  ? Promise<S | Fallback>
  : T;
type MaybePromise<T extends Promise<any>> = PromiseEither<T, undefined>;

type ErrorHandler = (err: unknown) => void;

type ErrorOptions = {
  title?: string;
  errorTitle?: string;
  errorText?: string;
  successTitle?: string;
  successText?: string;
};

type ErrorOptionsWithMeta = ErrorOptions & { meta: true };

type DispatchOptions = ErrorOptions | ErrorOptionsWithMeta | ErrorHandler;

export type ApiMeta<T> = {
  status: number;
  isSuccess: boolean;
  error?: unknown;
  result?: T;
};

/**
 * The ThunkDispatch type modified so that thunkActions are automatically unwrapped
 */
export interface UnwrappedThunkDispatch<State, ExtraThunkArg, BasicAction extends Action> {
  /** AsyncThunk + options */
  <TAction extends AsyncThunkAction<any, any, any>>(
    thunkAction: TAction,
    options?: ErrorOptions,
  ): MaybePromise<ReturnType<ReturnType<TAction>['unwrap']>>;
  /** AsyncThunk + options (getMeta)*/
  <TAction extends AsyncThunkAction<any, any, any>>(
    thunkAction: TAction,
    options?: ErrorOptionsWithMeta,
  ): Promise<ApiMeta<Awaited<ReturnType<ReturnType<TAction>['unwrap']>>>>;
  /** AsyncThunk + options + handler */
  <TAction extends AsyncThunkAction<any, any, any>>(
    thunkAction: TAction,
    options: ErrorOptions,
    errorMapper: ErrorHandler,
  ): MaybePromise<ReturnType<ReturnType<TAction>['unwrap']>>;
  /** AsyncThunk + options + handler (getMeta)*/
  <TAction extends AsyncThunkAction<any, any, any>>(
    thunkAction: TAction,
    options: ErrorOptionsWithMeta,
    errorMapper: ErrorHandler,
  ): Promise<ApiMeta<Awaited<ReturnType<ReturnType<TAction>['unwrap']>>>>;
  /** AsyncThunk + handler */
  <TAction extends AsyncThunkAction<any, any, any>, ResFallback>(
    thunkAction: TAction,
    errorMapper: ErrorHandler,
  ): MaybePromise<ReturnType<ReturnType<TAction>['unwrap']>>;
  /** action */
  <Action extends BasicAction>(action: Action): Action;
  /* Thunk */
  <ReturnType>(action: ThunkAction<ReturnType, State, ExtraThunkArg, BasicAction>): ReturnType;
  /** union of all above */
  <Ret extends { unwrap(): Promise<any> }, Action extends BasicAction>(
    action: Action | ThunkAction<Ret, State, ExtraThunkArg, BasicAction>,
    options: DispatchOptions,
  ): Action | MaybePromise<ReturnType<Ret['unwrap']>>;
}

export type UnwrappedAppDispatch = UnwrappedThunkDispatch<RootState, undefined, AnyAction> &
  Dispatch<AnyAction>;

/**
 * Returns a dispatch function that unwraps async thunks automatically
 */
export const getUnwrappedDispatch = (next: AppDispatch): UnwrappedAppDispatch => {
  return (action: any, arg1?: DispatchOptions, arg2?: ErrorHandler) => {
    const rawResult = next(action);
    if (isAsyncThunkActionResult(rawResult)) {
      const newResult = rawResult.then(
        (result: any) => {
          const { payload, meta, type } = result;
          const { status = -1 } = meta;
          const isSuccess = isSuccessStatus(status);

          if (isSuccess) {
            handleSuccessIfNeeded(arg1);
          } else {
            handleErrorIfNeeded(payload, arg1, arg2);
          }

          if (shouldReturnMeta(arg1)) {
            return {
              status,
              isSuccess,
              result: payload,
              error: !isSuccess ? payload : undefined,
            } as ApiMeta<unknown>;
          }

          if (isSuccess) {
            return payload;
          }

          return undefined;
        },
        (err: unknown) => {
          // this is only called if something very wrong happened
          let status = -1;
          if (isResponseError(err)) {
            status = err.response.status;
          } else {
            throw err;
          }

          handleErrorIfNeeded(err, arg1, arg2);

          if (shouldReturnMeta(arg1)) {
            return {
              status,
              isSuccess: isSuccessStatus(status),
              result: undefined,
              error: err,
            } as ApiMeta<unknown>;
          }

          return undefined;
        },
      );
      return newResult;
    }
    return rawResult;
  };
};

function shouldReturnMeta(arg: DispatchOptions | undefined) {
  return (arg as any)?.meta === true;
}

function handleSuccessIfNeeded(arg: DispatchOptions | undefined) {
  if (!arg || typeof arg === 'function') {
    return;
  }
  const title = arg.successTitle ?? arg.title;
  const text = arg.successText ?? `${title} success!`;

  if (title) {
    handleResponseSuccess(title, text);
  }
}

function handleErrorIfNeeded(
  error: unknown,
  arg1: DispatchOptions | undefined,
  arg2: ErrorHandler | undefined,
) {
  const errHandler = typeof arg1 === 'function' ? arg1 : arg2;
  if (errHandler) {
    errHandler(error);
  }
  if (arg1 && typeof arg1 === 'object') {
    const title = arg1.errorTitle ?? arg1.title;
    if (title) {
      handleResponseErrorCurried(title, arg1.errorText)(error);
    }
  }
}

function isAsyncThunkActionResult(a: any): a is Promise<any> {
  return a instanceof Promise && 'unwrap' in a;
}

function isSuccessStatus(status: number | undefined): boolean {
  return status !== undefined && status < 300 && status > 0;
}

export const useAppDispatch = () => {
  const dispatch = useDispatch() as AppDispatch;
  return useCallback(getUnwrappedDispatch(dispatch), [dispatch]);
};
