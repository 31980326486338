/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShiftBreak } from './ShiftBreak';
import {
    ShiftBreakFromJSON,
    ShiftBreakFromJSONTyped,
    ShiftBreakToJSON,
} from './ShiftBreak';

/**
 * 
 * @export
 * @interface ShiftTemplate
 */
export interface ShiftTemplate {
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    start_hour?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    end_hour?: string;
    /**
     * 
     * @type {Array<ShiftBreak>}
     * @memberof ShiftTemplate
     */
    breaks?: Array<ShiftBreak>;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    lunch_start_hour?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    lunch_end_hour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShiftTemplate
     */
    non_working?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    non_working_reason?: string;
}

/**
 * Check if a given object implements the ShiftTemplate interface.
 */
export function instanceOfShiftTemplate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShiftTemplateFromJSON(json: any): ShiftTemplate {
    return ShiftTemplateFromJSONTyped(json, false);
}

export function ShiftTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'start_hour': !exists(json, 'start_hour') ? undefined : json['start_hour'],
        'end_hour': !exists(json, 'end_hour') ? undefined : json['end_hour'],
        'breaks': !exists(json, 'breaks') ? undefined : ((json['breaks'] as Array<any>).map(ShiftBreakFromJSON)),
        'lunch_start_hour': !exists(json, 'lunch_start_hour') ? undefined : json['lunch_start_hour'],
        'lunch_end_hour': !exists(json, 'lunch_end_hour') ? undefined : json['lunch_end_hour'],
        'non_working': !exists(json, 'non_working') ? undefined : json['non_working'],
        'non_working_reason': !exists(json, 'non_working_reason') ? undefined : json['non_working_reason'],
    };
}

export function ShiftTemplateToJSON(value?: ShiftTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'start_hour': value.start_hour,
        'end_hour': value.end_hour,
        'breaks': value.breaks === undefined ? undefined : ((value.breaks as Array<any>).map(ShiftBreakToJSON)),
        'lunch_start_hour': value.lunch_start_hour,
        'lunch_end_hour': value.lunch_end_hour,
        'non_working': value.non_working,
        'non_working_reason': value.non_working_reason,
    };
}

