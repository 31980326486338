import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Popper from '@material-ui/core/Popper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Icon from 'components/Utils/Icon';

const styles = (theme) => ({
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    width: 'max-content',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  input: {
    display: 'flex',
    marginTop: 4,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  root: {
    flexGrow: 1,
    height: 250,
    width: 200,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      classes={{ root: 'truncate' }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input + ' default-auto-complete',
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
      onChange={props.selectProps.onUpdateInput}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.selectProps.placeholder || props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

class DefaultAutoComplete extends React.Component {
  constructor() {
    super();
    this.anchorEl = React.createRef();
  }

  MenuWithPopper = (props) => {
    let ref = this.anchorEl?.current;
    return (
      !!ref && (
        <Popper
          anchorEl={ref}
          open={!!ref}
          style={{
            zIndex: '10000',
            marginLeft: '-290px',
          }}
        >
          {Menu(props)}
        </Popper>
      )
    );
  };

  render() {
    const {
      dataSource,
      options,
      label,
      placeholder,
      style,
      input,
      id,
      onUpdateInput,
      onChange,
      allowClear,
      clearable = false,
      popOut = false,
      loading = false,
      maxMenuHeight,
    } = this.props.field || this.props;
    const { classes, theme } = this.props;

    let value = input?.value || '';

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const finalOptions = (() => {
      if (options) {
        return options;
      }
      return (dataSource || []).map((it) => {
        if (typeof it === 'object') {
          return it;
        }
        return { value: it, label: it };
      });
    })();

    const defaultOnOpen = () => {
      if (clearable) {
        if (input) input.onChange('');
        else onChange('');
      }

      if (this.props.onMenuOpen) this.props.onMenuOpen();
    };

    value = value.label || value;

    return (
      <div
        style={{
          marginTop: '20px',
          marginRight: '20px',
          width: '200px',
          ...(style || {}),
        }}
        ref={this.anchorEl}
        className={'default-auto-complete' + (loading ? ' is-loading' : '')}
      >
        {label && (
          <InputLabel shrink id="label">
            {label}
          </InputLabel>
        )}
        <div className="auto-complete-wrapper">
          <Select
            placeholder={this.props.placeholder}
            id={this.props.id || id}
            className="auto-complete"
            classes={classes}
            styles={selectStyles}
            clearable={true}
            options={finalOptions}
            anchorEl={this.anchorEl}
            isClearable
            components={{
              Control,
              Menu: popOut ? this.MenuWithPopper : Menu,
              NoOptionsMessage,
              Option,
              Placeholder,
              SingleValue,
              ValueContainer,
              ...(this.props.components || {}),
            }}
            maxMenuHeight={maxMenuHeight}
            value={value}
            inputValue={value}
            onUpdateInput={(event) => {
              let value = event.target.value;
              if (this.props.onUpdateInput) this.props.onUpdateInput(value);
              else if (input) input.onChange(value);
              else onUpdateInput(value);
            }}
            onChange={(event = {}) => {
              if (event && event.value && event.value === event.label) event = event.value;

              if (input) input.onChange(event);
              else onChange(event);
            }}
            onMenuOpen={defaultOnOpen.bind(this)}
          />
          {(!!allowClear || !!input.allowClear) && (
            <Icon
              src="circle-xmark"
              fontAwesomeStyle="fas"
              className="clear-icon"
              alt="Clear"
              showTooltip={true}
              onClick={() => {
                if (input) input.onChange('');
                else onChange('');
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

DefaultAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onUpdateInput: PropTypes.func,
  field: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(DefaultAutoComplete);
