import moment from 'moment-timezone';
import CalendarUtils from './CalendarUtils';
import PerformanceUtils from 'services/PerformanceUtils';
import _ from 'lodash';
import StaffUtils from 'services/StaffUtils';
import PatientUtils from 'services/PatientUtils';
import {
  HISTORY_TYPES,
  ISSUE_TYPES,
  MEDICATION_STATUS,
} from 'components/HealthRecords/AppointmentNotes/NewAppointmentNote/constants';

const BLOOD_TYPES = ['N/A', 'O-', 'O+', 'A-', 'A+', 'B-', 'B+', 'AB-', 'AB+'];

const ORIGIN_SRC = {
  'Apple Health': ['fab', 'apple'],
  'Practitioner': 'user-doctor',
  'Lab': 'vial',
  'Device':'mobile',
  'Android Health': ['fab', 'android'],
}

class CPPUtils {
  calculateBMI(height, weight) {
    if (height && weight) {
      let bmi = weight / (height / 100.0) ** 2;
      return parseFloat(bmi).toFixed(1);
    }
    return undefined;
  }

  getMetricOriginSrc(origin) {
    return ORIGIN_SRC[origin] || 'chart-line';
  }

  orderMetrics(metrics) {
    let copy = _.sortBy(PerformanceUtils.clone(metrics), 'metric.name');
    let priority = ['Lab', 'Practitioner'];
    let result = [];
    priority.forEach((origin) => {
      result = [...result, ...copy.filter((d) => d.origin === origin)];
      copy = copy.filter((d) => d.origin !== origin);
    });
    return [...result, ...copy];
  }

  getBloodTypes() {
    return BLOOD_TYPES;
  }

  getFormName(icon, patientId) {
    return `cpp${icon}${patientId}`;
  }

  getAppointmentFormName(patientId) {
    return `NewAppointment-${patientId}`;
  }

  getGTPAL(values = {}) {
    return Object.keys(values).length > 0 && Object.keys(values)
      .filter((key) => !!values[key])
      .map((key) => `${values[key]}${key.toUpperCase()}`).join(',  ')
  }

  getAppointmentNoteEditElement(note, draftId) {
    let metrics = {};
    (note.value.metrics || []).forEach((metric) => {
      metrics[metric.name] = metric.value;
    });

    return {
      itemId: note.id,
      authorId: note.author_id,
      ...note.value,
      ...metrics,
      draftId,
      item_type: note.item_type,
      health_records: note.health_records,
      documents: note.documents,
      originalNote: note,
    };
  }

  getImmunizationEditElement(immunization) {
    return {
      itemId: immunization.id,
      ...immunization.value,
    };
  }

  getAppointmentEditElement(appointment) {
    return {
      ...appointment,
      time: appointment.date,
    };
  }

  getImmunizationDescription(value) {
    let text = value.name;

    if (value.description) text += ' for ' + value.description;
    if (value.prescription) text += '. Rx: ' + value.prescription;

    if (value.lot) text += ', lot ' + value.lot;

    if (value.given_date) text += '. Given in ' + CalendarUtils.format(value.given_date);

    if (this.isPendingImmunization({ value })) {
      text += `. Scheduled for ${CalendarUtils.format(value.scheduled_date)}`;
    }

    return text;
  }

  getPrescriptionMedicationSummary(medication) {
    let prnDisabled = !medication.as_needed && !medication.prn;

    let result =
      medication.name +
      ' ' +
      (medication.dosage ? medication.dosage + ' ' : '') +
      (medication.dosage_unit ? medication.dosage_unit + ' ' : '') +
      (medication.route ? medication.route + ' ' : '') +
      (medication.frequency ? medication.frequency + ' ' : '') +
      (!prnDisabled ? 'PRN ' : '') +
      (medication.time ? 'for ' + medication.time + ' ' : '') +
      (medication.time_unit ? medication.time_unit + ' ' : '') +
      (medication.refills ? 'refills ' + medication.refills + ' ' : '') +
      (medication.quantity ? 'Quantity: ' + medication.quantity + ' ' : '') +
      (medication.quantity_unit ? medication.quantity_unit + ' ' : '') +
      (medication.comments ? medication.comments + ' ' : '');
    return result.trim();
  }

  isPendingImmunization(immunization) {
    return (
      immunization.value.scheduled_date !== undefined && immunization.value.given_date === undefined
    );
  }

  isOverdueImmunization(immunization) {
    return (
      this.isPendingImmunization(immunization) &&
      immunization.value.scheduled_date < moment().unix()
    );
  }

  isTranscribedImmunization(immunization) {
    return immunization.value.transcribed === true;
  }

  isGivenImmunization(immunization) {
    return !!immunization.value.given_date;
  }

  isWithinOneYearImmunization(immunization) {
    return (
      !this.isPendingImmunization(immunization) ||
      moment().add(1, 'year').isAfter(moment.unix(immunization.value.scheduled_date))
    );
  }

  getImmunizationIcon(immunization) {
    if (!this.isPendingImmunization(immunization)) return { src: 'check', className: 'green' };
    else if (this.isOverdueImmunization(immunization))
      return { src: 'circle-exclamation', className: 'red', fontAwesomeStyle: 'fas' };
    else if (this.isWithinOneYearImmunization(immunization))
      return { src: 'calendar-exclamation', className: 'blue' };
    else if (this.isPendingImmunization(immunization)) return { src: 'clock' };
    return {};
  }

  getAdministeredDrugIcon(drug) {
    if (this.isOverdueImmunization(drug))
      return { src: 'circle-exclamation', className: 'red', fontAwesomeStyle: 'fas' };
    else if (this.isPendingImmunization(drug)) return { src: 'clock' };
    return {};
  }

  getImmunizationIconDescription(immunization) {
    if (this.isTranscribedImmunization(immunization)) return 'Transcribed';
    else if (!this.isPendingImmunization(immunization))
      return 'Administered by Care& Family Health';
    else if (this.isOverdueImmunization(immunization)) return 'Overdue';
    else if (this.isWithinOneYearImmunization(immunization)) return 'Coming up soon';
    else if (this.isPendingImmunization(immunization)) return 'Due';
    return undefined;
  }

  filterScheduledAndOverdueImmunizations(immunizations) {
    let result = immunizations.filter((immunization) => {
      return (
        this.isOverdueImmunization(immunization) ||
        (this.isPendingImmunization(immunization) && this.isWithinOneYearImmunization(immunization))
      );
    });

    return PerformanceUtils.sortBy(result, ['value.scheduled_date'], false);
  }

  /**
   * @returns {PatientMedication[]}
   * */
  getActiveMedications = (medications) => {
    return medications.filter((medication) => this.isActiveMedication(medication));
  };

  getInactiveMedications(medications) {
    return medications.filter((medication) => !this.isActiveMedication(medication));
  }

  isActiveMedication = (medication) => {
    if (!this.isHealthRecordItemEnabled(medication)) return false;
    let { status } = medication.value;

    return (
      status !== MEDICATION_STATUS.discontinue.name &&
      status !== MEDICATION_STATUS.stop.name &&
      status !== MEDICATION_STATUS.hold.name
    );
  };

  getActiveHealthRecords(healthRecords = {}, key, filter = () => true, describe = (value) => value.name) {
    return (healthRecords[key] || []).reduce((acc, item) => {
      if (this.isHealthRecordItemEnabled(item) && filter(item.value)) {
        const description = describe(item.value);
        if (description?.trim()) {
          acc.push(description);
        }
      }
      return acc;
    }, []);
  }

  getPreviousPharmacies(patient, prescriptions = []) {
    let pharmacies = prescriptions.reduce((acc, prescription) => {
      if (prescription.pharmacy !== undefined) acc.push(prescription.pharmacy);
      return acc;
    }, []);

    if (patient.default_pharmacy) {
      pharmacies.unshift(patient.default_pharmacy);
    }

    return _.uniqBy(pharmacies, 'id') || [];
  }

  isHealthRecordItemEnabled(item) {
    return !item.history || item.history[0].action !== 'Removed';
  }

  calculateDrugLastsUntil(time, timeUnit, refills = 0, startDate = moment().unix()) {
    if (!time || !timeUnit) return;

    let newTime = time * (refills + 1);
    let result = moment.unix(startDate).add(newTime, timeUnit).unix();
    if (!Number.isNaN(result)) return result;
  }

  calculateDueDate(lastPeriod) {
    let momentDate = moment.unix(lastPeriod);
    let dueDate = momentDate.add(280, 'days');
    return dueDate.unix();
  }

  calculateWeeksPregnant(lastPeriod) {
    let momentDate = moment.unix(lastPeriod);
    let weeksPassed = moment().diff(momentDate, 'weeks');
    let daysPassed = moment().add(-weeksPassed, 'weeks').diff(momentDate, 'days');
    let result = `${weeksPassed}w`;
    if (daysPassed >= 1) result += ` + ${daysPassed}d`;
    return result;
  }

  checkMacro(value, macroData = {}) {
    if (!value) return value;

    if (value.includes('T#') && !macroData['T#']) {
      value = value.replace('T#', CalendarUtils.format(moment().unix(), 'h:mm A'));
    } else if (value.includes('D#') && !macroData['D#']) {
      value = value.replace('D#', CalendarUtils.format(moment().unix(), 'YYYY/MM/DD'));
    } else {
      Object.keys(macroData).forEach((key) => {
        if (value.includes(key) && macroData[key]) {
          value = value.replace(key, macroData[key]);
        }
      });
    }

    return value;
  }

  getPatientMeasurements(patientHealthRecords) {
    const patientSummary = patientHealthRecords?.dataSummary || [];
    let weight = patientSummary?.find((val) => val.metric.id === 'weight')?.last_value?.value1;
    weight = weight && parseFloat(weight);
    let height = patientSummary?.find((val) => val.metric.id === 'height')?.last_value?.value1;
    height = height && parseFloat(height);

    return { height, weight };
  }

  getMacroData(patientHealthRecords) {
    const patientSummary = patientHealthRecords?.dataSummary || [];
    let weight = patientSummary?.find((val) => val.metric.id === 'weight')?.last_value?.value1;
    let height = patientSummary?.find((val) => val.metric.id === 'height')?.last_value?.value1;

    let allergies =
      (patientHealthRecords?.healthRecords?.allergies || [])
        .filter((allergy) => this.isHealthRecordItemEnabled(allergy))
        .map((allergy) => allergy.value.name)
        .join(', ') || 'NKA';

    let medications = (patientHealthRecords?.healthRecords?.medications || [])
      .filter((medication) => this.isHealthRecordItemEnabled(medication))
      .map((medication) => medication.value.prescription_medication?.name || medication.value.name)
      .join(', ');

    return { 'H#': height, 'W#': weight, 'A#': allergies, 'M#': medications };
  }

  describeMedication(item) {
    let valueString = '';

    let medication = item.value;

    let drugName =
      medication?.prescription_medication?.drugbank_product?.prescribable_name ||
      medication?.prescription_medication?.drugbank_product?.name ||
      medication?.prescription_medication?.name ||
      medication?.name;

    let routeIndex = -1;
    let route = medication?.prescription_medication?.drugbank_product?.route;
    if (route) {
      routeIndex = drugName.indexOf(route);
      if (routeIndex > -1) {
        drugName = drugName.substring(0, routeIndex);
      }
    }

    if (medication?.prescription_medication) {
      const { dosage, dosage_unit, frequency } = medication.prescription_medication;
      valueString += drugName;
      let takeInstructions = [dosage, dosage_unit, frequency].filter((d) => d).join(' ');
      if (takeInstructions) valueString += ` - ${takeInstructions}`;
    } else {
      valueString += drugName;
    }
    return valueString;
  }

  getLatestNotes(notes = [], count = 5) {
    return notes
      .slice()
      .reverse()
      .slice(0, count)
      .map(
        ({ value }) => `${CalendarUtils.fromNow(value.date)}\n\n${value.value}`,
      )
      .join("\n ----- \n");
  }

  getReferralCPPInfo(records) {
    let keys = ['medical_issues', 'medications', 'allergies', 'history', 'preventative_screenings'];

    let newText = ''; //text + '\n';

    keys.forEach((key) => {
      let keyString = key.replace('_', ' ');
      keyString = keyString.charAt(0).toUpperCase() + keyString.slice(1) + ':';

      let data = records?.[key] || [];

      if (key === 'history') {
        // don't add a title
        data = PerformanceUtils.sortBy(data, ['value.history_type'], false).filter(
          (item) => item.value.history_type === HISTORY_TYPES.family_history,
        );
      } else if (key === 'medications') {
      } else if (key === 'medical_issues') {
        data = PerformanceUtils.clone(data).map((item) => {
          if (!item.value.issue_type) item.value.issue_type = ISSUE_TYPES.current;
          return item;
        });
        data = PerformanceUtils.sortBy(data, ['value.issue_type'], false);
      }

      if (
        data.length > 0 &&
        key !== 'history' &&
        key !== 'medical_issues' &&
        key !== 'medications'
      ) {
        newText += '\n' + keyString + '\n';
      } else {
        if (key === 'allergies') newText += '\n' + keyString + ' NKDA\n';
      }

      let currentHistoryType;

      if (key === 'medications') {
        //filter active medications
        let active = this.getActiveMedications(data);
        const describeMedication = this.describeMedication.bind(this);

        function printMedications(title, medications = []) {
          if (medications.length > 0) {
            newText += `\n${title}:\n`;
            medications.forEach((item) => {
              let description = describeMedication(item);
              if (description) newText += `${description}\n`;
            });
          }
        }

        printMedications('Active Medications', active);
      } else {

        if (key === "medical_issues" && data.length > 0) newText += "\nPHMx:";

        data.forEach((item) => {
          const { name, details, specialty, history_type, issue_type, date, notes } = item.value;

          if (this.isHealthRecordItemEnabled(item)) {
            let valueString = '';

            let type = history_type || issue_type;

            if (type && type !== currentHistoryType) {
              currentHistoryType = type;
              valueString += `\n${type}:\n`;
            }

            if (type) valueString += '-';

            valueString += name;
            if (details) valueString += `, ${details}`;
            if (key !== 'medications' && notes) valueString += `, ${notes}`;
            if (specialty) valueString += ` (${specialty})`;
            if (date) {
              valueString += ` (${moment.unix(date).format('YYYY/MM/DD')})`;
            }

            newText += `${valueString}\n`;
          }
        });
      }
    });
    return newText;
  }

  /**
   * @param {import('generated/api').PatientMedication} medication
   * @param {number} daysThreshold - a change in days to the last_until
   * @returns {[true, number] | [false, number | undefined]}
   * */
  medicationStillLasts(medication, daysThreshold = 0) {
    const time = medication.value?.prescription_medication?.time;
    const timeUnit = medication.value?.prescription_medication?.time_unit;
    const refills = _.toInteger(medication.value?.prescription_medication?.refills);

    if (!time || !timeUnit) {
      return [false, undefined];
    }

    const date = this.calculateDrugLastsUntil(time, timeUnit, refills, medication.value?.date);

    const cutoffDate = date && moment.unix(date).add(daysThreshold, 'days').unix();

    return [!!date && moment().unix() < cutoffDate, date];
  }

  /**
   * @param {import('generated/api').PatientMedication} medication
   * */
  medicationAllowsRefillRequests(medication) {
    return !medication.value?.prescription_medication?.disable_refills;
  }

  getReferralText(patient, physician, cppText) {
    let age = PatientUtils.getAge(patient);
    let gender = (PatientUtils.getGender(patient) || '').toLowerCase();
    let physicianName = StaffUtils.getName(physician);

    return `Dear Colleague: 

Please see this pleasant ${age} old ${gender} for __________________.
${cppText}
Please see attachments for recent investigations completed.

Thank you for your involvement in this client’s care. We look forward to hearing from you.

If you require any additional information, please do not hesitate to contact us immediately.

Kind regards,
${physicianName}`;
  }
}

export default new CPPUtils();
