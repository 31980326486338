/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskComment
 */
export interface TaskComment {
    /**
     * 
     * @type {string}
     * @memberof TaskComment
     */
    author_id: string;
    /**
     * 
     * @type {number}
     * @memberof TaskComment
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof TaskComment
     */
    text: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskComment
     */
    read_by?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskComment
     */
    notifications_disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskComment
     */
    email_content?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskComment
     */
    document_id?: string;
}

/**
 * Check if a given object implements the TaskComment interface.
 */
export function instanceOfTaskComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "author_id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function TaskCommentFromJSON(json: any): TaskComment {
    return TaskCommentFromJSONTyped(json, false);
}

export function TaskCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author_id': json['author_id'],
        'date': json['date'],
        'text': json['text'],
        'read_by': !exists(json, 'read_by') ? undefined : json['read_by'],
        'notifications_disabled': !exists(json, 'notifications_disabled') ? undefined : json['notifications_disabled'],
        'email_content': !exists(json, 'email_content') ? undefined : json['email_content'],
        'document_id': !exists(json, 'document_id') ? undefined : json['document_id'],
    };
}

export function TaskCommentToJSON(value?: TaskComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author_id': value.author_id,
        'date': value.date,
        'text': value.text,
        'read_by': value.read_by,
        'notifications_disabled': value.notifications_disabled,
        'email_content': value.email_content,
        'document_id': value.document_id,
    };
}

