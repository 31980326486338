/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentConcernRestriction
 */
export interface AppointmentConcernRestriction {
    /**
     * 
     * @type {number}
     * @memberof AppointmentConcernRestriction
     */
    weekDay?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcernRestriction
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcernRestriction
     */
    end?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcernRestriction
     */
    disabled?: boolean;
}

/**
 * Check if a given object implements the AppointmentConcernRestriction interface.
 */
export function instanceOfAppointmentConcernRestriction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentConcernRestrictionFromJSON(json: any): AppointmentConcernRestriction {
    return AppointmentConcernRestrictionFromJSONTyped(json, false);
}

export function AppointmentConcernRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentConcernRestriction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weekDay': !exists(json, 'weekDay') ? undefined : json['weekDay'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function AppointmentConcernRestrictionToJSON(value?: AppointmentConcernRestriction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weekDay': value.weekDay,
        'start': value.start,
        'end': value.end,
        'disabled': value.disabled,
    };
}

