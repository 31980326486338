import { Appointment } from "generated/api"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrap } from 'careand-redux/utils/unwrap'
import PerformanceUtils from "services/PerformanceUtils"

export type EditedAppointmentEventsState = EditedAppointmentEvent[]

type EditedAppointmentEvent = {
  appointment_id: string,
  type: string,
  edited: boolean,
  dropzone: boolean,
  start: number,
  end: number,
  text: string,
  title: string,
  appointment: Appointment,
}

const initialState: EditedAppointmentEventsState = []

const slice = createResettableSlice({
  name: 'editedAppointmentEvents',
  initialState,
  reducers: {
    clearEditedAppointmentEvents: () => [],
    editAppointmentEvent: unwrap((state: EditedAppointmentEventsState, event: EditedAppointmentEvent) => {
      PerformanceUtils.upsert(event, state, 'appointment_id')
    })
  }
})

export const editedAppointmentActions = slice.actions
export default slice.reducer
