/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TaskComment } from './TaskComment';
import {
    TaskCommentFromJSON,
    TaskCommentFromJSONTyped,
    TaskCommentToJSON,
} from './TaskComment';

/**
 * A TODO task for Physicians and Admins
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    assigned_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    author_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    patient_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    due_date?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    archived?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    priority?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    documents?: Array<string>;
    /**
     * 
     * @type {Array<TaskComment>}
     * @memberof Task
     */
    comments?: Array<TaskComment>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Task
     */
    read_by?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    dont_notify_documents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    pinned?: boolean;
}

/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'title': json['title'],
        'details': !exists(json, 'details') ? undefined : json['details'],
        'assigned_user_id': !exists(json, 'assigned_user_id') ? undefined : json['assigned_user_id'],
        'author_id': !exists(json, 'author_id') ? undefined : json['author_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'due_date': !exists(json, 'due_date') ? undefined : json['due_date'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'documents': !exists(json, 'documents') ? undefined : json['documents'],
        'comments': !exists(json, 'comments') ? undefined : ((json['comments'] as Array<any>).map(TaskCommentFromJSON)),
        'read_by': !exists(json, 'read_by') ? undefined : json['read_by'],
        'dont_notify_documents': !exists(json, 'dont_notify_documents') ? undefined : json['dont_notify_documents'],
        'pinned': !exists(json, 'pinned') ? undefined : json['pinned'],
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'clinic_id': value.clinic_id,
        'title': value.title,
        'details': value.details,
        'assigned_user_id': value.assigned_user_id,
        'author_id': value.author_id,
        'status': value.status,
        'patient_id': value.patient_id,
        'date': value.date,
        'due_date': value.due_date,
        'archived': value.archived,
        'priority': value.priority,
        'documents': value.documents,
        'comments': value.comments === undefined ? undefined : ((value.comments as Array<any>).map(TaskCommentToJSON)),
        'read_by': value.read_by,
        'dont_notify_documents': value.dont_notify_documents,
        'pinned': value.pinned,
    };
}

