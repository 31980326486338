/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultipleValuesPayload
 */
export interface MultipleValuesPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof MultipleValuesPayload
     */
    values: Array<string>;
}

/**
 * Check if a given object implements the MultipleValuesPayload interface.
 */
export function instanceOfMultipleValuesPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function MultipleValuesPayloadFromJSON(json: any): MultipleValuesPayload {
    return MultipleValuesPayloadFromJSONTyped(json, false);
}

export function MultipleValuesPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleValuesPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': json['values'],
    };
}

export function MultipleValuesPayloadToJSON(value?: MultipleValuesPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': value.values,
    };
}

