import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PhysicianUtils from 'services/PhysicianUtils';
import { PhysicianImage } from 'components/Common/UserImage';
import GenericBadge from 'components/Utils/GenericBadge';
import './style.scss';
import { useAppSelector } from 'careand-redux/store';

const PhysicianBadge = ({ physician, onClick }) => {
  const clinicId = physician?.clinic_id;
  const clinics = useAppSelector((state) => state.resource.clinics);

  const clinic = useMemo(() => {
    return clinics.find((clinic) => clinic.id === clinicId);
  }, [clinics, clinicId]);

  return (
    !!physician && (
      <GenericBadge
        id={physician ? 'physician-badge-' + physician.id : undefined}
        key={physician ? physician.id : 'no-physician'}
        className={getClassname(physician)}
        title={PhysicianUtils.getName(physician) || ''}
        avatar={<PhysicianImage user={physician} size={40} />}
        info={<InfoText physician={physician} clinic={clinic} />}
        onClick={onClick}
      />
    )
  );
};

function getClassname(physician = {}) {
  let className = 'physician-badge';
  if (physician.inactive === true) className += ' inactive';
  return className;
}

const InfoText = ({ physician, clinic }) => {
  let specialty =
    PhysicianUtils.getSpecialty(physician) || physician.physician_type || 'Practitioner';
  return <span className="truncate">{specialty + (clinic ? ` (${clinic?.name})` : '')}</span>;
};

PhysicianBadge.propTypes = {
  physician: PropTypes.object,
  onClick: PropTypes.func,
  clinics: PropTypes.object,
};

export default PhysicianBadge;
