/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppointmentConcern } from './AppointmentConcern';
import {
    AppointmentConcernFromJSON,
    AppointmentConcernFromJSONTyped,
    AppointmentConcernToJSON,
} from './AppointmentConcern';

/**
 * 
 * @export
 * @interface BookingFlowOption
 */
export interface BookingFlowOption {
    /**
     * 
     * @type {string}
     * @memberof BookingFlowOption
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingFlowOption
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingFlowOption
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BookingFlowOption
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof BookingFlowOption
     */
    order?: number;
    /**
     * 
     * @type {Array<AppointmentConcern>}
     * @memberof BookingFlowOption
     */
    concerns?: Array<AppointmentConcern>;
}

/**
 * Check if a given object implements the BookingFlowOption interface.
 */
export function instanceOfBookingFlowOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function BookingFlowOptionFromJSON(json: any): BookingFlowOption {
    return BookingFlowOptionFromJSONTyped(json, false);
}

export function BookingFlowOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingFlowOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'title': json['title'],
        'text': json['text'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'concerns': !exists(json, 'concerns') ? undefined : ((json['concerns'] as Array<any>).map(AppointmentConcernFromJSON)),
    };
}

export function BookingFlowOptionToJSON(value?: BookingFlowOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'disabled': value.disabled,
        'title': value.title,
        'text': value.text,
        'order': value.order,
        'concerns': value.concerns === undefined ? undefined : ((value.concerns as Array<any>).map(AppointmentConcernToJSON)),
    };
}

