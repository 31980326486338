/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GTPAL } from './GTPAL';
import {
    GTPALFromJSON,
    GTPALFromJSONTyped,
    GTPALToJSON,
} from './GTPAL';

/**
 * 
 * @export
 * @interface HealthRecordsInformation
 */
export interface HealthRecordsInformation {
    /**
     * 
     * @type {string}
     * @memberof HealthRecordsInformation
     */
    blood_type?: string;
    /**
     * 
     * @type {GTPAL}
     * @memberof HealthRecordsInformation
     */
    gtpal?: GTPAL;
}

/**
 * Check if a given object implements the HealthRecordsInformation interface.
 */
export function instanceOfHealthRecordsInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HealthRecordsInformationFromJSON(json: any): HealthRecordsInformation {
    return HealthRecordsInformationFromJSONTyped(json, false);
}

export function HealthRecordsInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthRecordsInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blood_type': !exists(json, 'blood_type') ? undefined : json['blood_type'],
        'gtpal': !exists(json, 'gtpal') ? undefined : GTPALFromJSON(json['gtpal']),
    };
}

export function HealthRecordsInformationToJSON(value?: HealthRecordsInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blood_type': value.blood_type,
        'gtpal': GTPALToJSON(value.gtpal),
    };
}

