import React, { MouseEventHandler } from 'react';
import AdminUtils from 'services/AdminUtils';
import PhysicianUtils from 'services/PhysicianUtils';
import PhysicianBadge from 'components/Physician/PhysicianBadge';
import AdminBadge from 'components/Admin/AdminBadge';
import PerformanceUtils from 'services/PerformanceUtils';
import moment from 'moment-timezone';
import CalendarUtils from 'services/CalendarUtils';
import { Shift, Physician, Admin } from 'generated/api';
import { RootState } from 'careand-redux/store';

export const PERMISSIONS = {
  corporate: 'corporate',
  shift_manager: 'shift_manager',
  super_admin: 'super_admin',
  statistics: 'statistics',
} as const;

export type Permission = keyof typeof PERMISSIONS;

export const DEFAULT_VACATION_DAYS = 14;

type Staff = Admin | Physician;

class StaffUtils {
  shiftPillsToEvents(
    shifts: Shift[] = [],
    physicians: Record<string, Physician> = {},
    admins: Record<string, Admin> = {},
    type: string = 'shift-pill',
    overrideTexts: Record<string, string> = {},
  ) {
    if (shifts.length === 0) return shifts;

    return shifts.map((shift) => {
      let startDate = moment.unix(shift.start);
      let endDate = moment.unix(shift.end);

      let startString = CalendarUtils.format(shift.start, 'h:mm A');
      let endString = CalendarUtils.format(shift.end, 'h:mm A');

      let name = this.getName(physicians[shift.user_id!] || admins[shift.user_id!]);
      let text = `${name}: ${startString} - ${endString}`;

      return {
        type,
        text,
        name,
        shift,
        overrideText: overrideTexts[shift.id!],
        start: startDate.toDate(),
        end: endDate.toDate(),
      };
    });
  }

  smallShiftPillsToEvents(
    shifts: Shift[] = [],
    physicians: Record<string, Physician> = {},
    admins: Record<string, Admin> = {},
    type: string = 'small-shift-pill',
  ) {
    if (shifts.length === 0) return shifts;

    let perDay: Record<string, Shift[]> = {};

    shifts.forEach((shift) => {
      let day = CalendarUtils.format(shift.start, 'YYYYMMDD');
      perDay[day] = perDay[day] || [];
      perDay[day].push(shift);
    });

    return Object.keys(perDay).map((day) => {
      let dayShifts = perDay[day];
      let firstShift = dayShifts[0];
      let startDate = moment.unix(firstShift.start);
      let endDate = moment.unix(firstShift.end);
      return {
        type,
        text: day,
        shifts: dayShifts,
        names: dayShifts.map((shift) => {
          return this.getName(physicians[shift.user_id!] || admins[shift.user_id!]);
        }),
        start: startDate.toDate(),
        end: endDate.toDate(),
      };
    });
  }

  canBookTimeOff(authenticationState: RootState['authentication']) {
    const { permissions, role } = authenticationState;
    const isAdmin = role === 'admin';
    return (
      isAdmin ||
      this.checkPermissions(permissions, [PERMISSIONS.shift_manager, PERMISSIONS.super_admin])
    );
  }

  isAdmin(user: Staff = {}) {
    return user.name !== undefined;
  }

  isPhysician(user: Staff = {}) {
    return user.first_name !== undefined || user.last_name !== undefined;
  }

  getName(user: Staff = {}) {
    if (this.isAdmin(user)) return AdminUtils.getName(user);
    else if (this.isPhysician(user)) return PhysicianUtils.getName(user);
  }

  getFirstName(user: Staff = {}) {
    if (this.isAdmin(user)) return (AdminUtils.getName(user) || '').split(' ')[0];
    else if (this.isPhysician(user)) return (PhysicianUtils.getName(user) || '').split(' ')[0];
  }

  filterActiveUsers(users: Staff[] = [], clinicId?: string) {
    if (!Array.isArray(users)) users = PerformanceUtils.objectToArray(users);
    return users.filter((user) => this.isActiveUser(user, clinicId));
  }

  isActiveUser(user: Staff, clinicId?: string) {
    let isActive = true;
    if (clinicId) isActive = user.clinic_id === clinicId;
    return isActive && !user.inactive && !user.hide_portal;
  }

  checkPermissions(permissions: string[] = [], allowedPermissions: string[] = []) {
    let found = false;
    allowedPermissions.forEach((permission) => {
      if (permissions.includes(permission)) {
        found = true;
      }
    });
    return found;
  }

  filterClinic(users: Staff[] = [], clinicId?: string, onlyActive = false) {
    return users.filter(
      (user) => user.clinic_id === clinicId && (onlyActive ? !user.inactive : true),
    );
  }
}

export const StaffBadge = ({ user, onClick }: { user: Staff; onClick: MouseEventHandler }) => (
  <>
    {StaffUtilsInstance.isAdmin(user) && <AdminBadge admin={user} onClick={onClick} />}
    {StaffUtilsInstance.isPhysician(user) && <PhysicianBadge physician={user} onClick={onClick} />}
  </>
);

const StaffUtilsInstance = new StaffUtils();
export default StaffUtilsInstance;
