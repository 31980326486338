/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenAIMessage
 */
export interface OpenAIMessage {
    /**
     * 
     * @type {string}
     * @memberof OpenAIMessage
     */
    role: OpenAIMessageRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof OpenAIMessage
     */
    content: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenAIMessage
     */
    annotations?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OpenAIMessage
     */
    date?: number;
}


/**
 * @export
 */
export const OpenAIMessageRoleEnum = {
    System: 'system',
    User: 'user',
    Assistant: 'assistant'
} as const;
export type OpenAIMessageRoleEnum = typeof OpenAIMessageRoleEnum[keyof typeof OpenAIMessageRoleEnum];


/**
 * Check if a given object implements the OpenAIMessage interface.
 */
export function instanceOfOpenAIMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function OpenAIMessageFromJSON(json: any): OpenAIMessage {
    return OpenAIMessageFromJSONTyped(json, false);
}

export function OpenAIMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenAIMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'content': json['content'],
        'annotations': !exists(json, 'annotations') ? undefined : json['annotations'],
        'date': !exists(json, 'date') ? undefined : json['date'],
    };
}

export function OpenAIMessageToJSON(value?: OpenAIMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'content': value.content,
        'annotations': value.annotations,
        'date': value.date,
    };
}

