import {PatientHandover, Shift, ShiftRequest, ShiftRequestStatusEnum, ShiftTemplate} from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrapApi, unwrap } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface ShiftsState {
  shifts: Shift[]
  templates: ShiftTemplate[]
  requests: ShiftRequest[]
  pendingRequests: ShiftRequest[]
  handovers: PatientHandover[]
}

const initialState: ShiftsState = {
  shifts: [],
  templates: [],
  requests: [],
  pendingRequests: [],
  handovers: []
}

const slice = createResettableSlice({
  name: 'Shifts',
  initialState,
  reducers: {
    receiveUpdatedShift: unwrap(upsertShift),
    receiveRemovedShift: unwrap(removeShift),
    receiveUpdatedShiftRequest: unwrap(editOrAddShiftRequest),
    receiveUpdatedPatientHandover: unwrap(editOrAddPatientHandover),
    receiveRemovedPatientHandover: unwrap(removePatientHandover),
  },
  extraReducers: builder => builder
    .addCase(api.Shifts.removeClinicShiftTemplate.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state.templates)
    }))
    .addCase(api.Shifts.addClinicShiftTemplate.fulfilled, unwrapApi((state, data) => {
      PerformanceUtils.upsert(data, state.templates)
    }))
    .addCase(api.Shifts.getShiftTemplates.fulfilled, unwrapApi((state, data) => {
      state.templates = data
    }))
    .addCase(api.Shifts.getPatientHandovers.fulfilled, unwrapApi((state, data) => {
      state.handovers = data
    }))
    .addCase(api.Shifts.addPatientHandover.fulfilled, unwrap(editOrAddPatientHandover))
    .addCase(api.Shifts.removePatientHandover.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({id: req.id}, state.handovers)
    }))
    .addCase(api.Shifts.addOrEditShifts.fulfilled, unwrap(upsertShifts))
    .addCase(api.Shifts.getClinicShifts.fulfilled, unwrap(upsertShifts))
    .addCase(api.Shifts.getDayShifts.fulfilled, unwrap(upsertShifts))
    .addCase(api.Shifts.deleteShift.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({ id: req.shiftId }, state.shifts)
    }))
    .addCase(api.Shifts.getShiftRequests.fulfilled, unwrapApi((state, data) => {
      state.requests = data
    }))
    .addCase(api.Shifts.cancelShift.fulfilled, unwrapApi(upsertShift))
    .addCase(api.Shifts.addShiftRequest.fulfilled, unwrapApi(upsertRequest))
    .addCase(api.Shifts.addRequestShifts.fulfilled, unwrapApi(upsertRequest))
    .addCase(api.Shifts.revertRequestShifts.fulfilled, unwrapApi(upsertRequest))
    .addCase(api.Shifts.approveShiftRequest.fulfilled, unwrapApi((state, data) => {
      editOrAddShiftRequest(state, data)
    }))
    .addCase(api.Shifts.getPendingShiftRequests.fulfilled, unwrapApi((state, data) => {
      state.pendingRequests = data
    }))
})

function editOrAddShiftRequest(state: ShiftsState, request: ShiftRequest) {
  const isPending = request.status === ShiftRequestStatusEnum.Pending
  PerformanceUtils.upsert(request, state.requests)

  if (isPending) {
    PerformanceUtils.upsert(request, state.pendingRequests)
  } else {
    PerformanceUtils.removeFromArrayInPlace(request, state.pendingRequests)
  }
}

function editOrAddPatientHandover(state: ShiftsState, handover: PatientHandover) {
  PerformanceUtils.upsert(handover, state.handovers)
}

function removePatientHandover(state: ShiftsState, handover: PatientHandover) {
  PerformanceUtils.removeFromArrayInPlace(handover, state.handovers)
}

function upsertShift(state: ShiftsState, shift: Shift) {
  PerformanceUtils.upsert(shift, state.shifts)
}

function removeShift(state: ShiftsState, shift: Shift) {
  PerformanceUtils.removeFromArrayInPlace(shift, state.shifts)
}

function upsertShifts(state: ShiftsState, shifts: Shift[]) {
  shifts.forEach(shift => upsertShift(state, shift));
}
function upsertRequest(state: ShiftsState, shiftReq: ShiftRequest) {
  PerformanceUtils.upsert(shiftReq, state.requests)
}

export default slice.reducer
export const shiftsActions = slice.actions
