/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicationReminderWhenValue } from './MedicationReminderWhenValue';
import {
    MedicationReminderWhenValueFromJSON,
    MedicationReminderWhenValueFromJSONTyped,
    MedicationReminderWhenValueToJSON,
} from './MedicationReminderWhenValue';

/**
 * 
 * @export
 * @interface MedicationReminderWhen
 */
export interface MedicationReminderWhen {
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderWhen
     */
    type: MedicationReminderWhenTypeEnum;
    /**
     * 
     * @type {Array<MedicationReminderWhenValue>}
     * @memberof MedicationReminderWhen
     */
    values?: Array<MedicationReminderWhenValue>;
}


/**
 * @export
 */
export const MedicationReminderWhenTypeEnum = {
    EveryDay: 'everyDay',
    XTimesAWeek: 'xTimesAWeek',
    XTimesAMonth: 'xTimesAMonth',
    Cycle: 'cycle',
    Prn: 'PRN',
    EveryXDays: 'everyXDays',
    EveryXHours: 'everyXHours'
} as const;
export type MedicationReminderWhenTypeEnum = typeof MedicationReminderWhenTypeEnum[keyof typeof MedicationReminderWhenTypeEnum];


/**
 * Check if a given object implements the MedicationReminderWhen interface.
 */
export function instanceOfMedicationReminderWhen(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function MedicationReminderWhenFromJSON(json: any): MedicationReminderWhen {
    return MedicationReminderWhenFromJSONTyped(json, false);
}

export function MedicationReminderWhenFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminderWhen {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(MedicationReminderWhenValueFromJSON)),
    };
}

export function MedicationReminderWhenToJSON(value?: MedicationReminderWhen | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(MedicationReminderWhenValueToJSON)),
    };
}

