/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DrugbankProduct } from './DrugbankProduct';
import {
    DrugbankProductFromJSON,
    DrugbankProductFromJSONTyped,
    DrugbankProductToJSON,
} from './DrugbankProduct';

/**
 * 
 * @export
 * @interface PrescriptionMedication
 */
export interface PrescriptionMedication {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    dosage?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    dosage_unit?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    quantity?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    quantity_unit?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    frequency?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    as_needed?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionMedication
     */
    prn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    time_unit?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    refills?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    comments?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionMedication
     */
    enabled?: boolean;
    /**
     * 
     * @type {DrugbankProduct}
     * @memberof PrescriptionMedication
     */
    drugbank_product?: DrugbankProduct;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionMedication
     */
    document_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrescriptionMedication
     */
    disable_refills?: boolean;
}

/**
 * Check if a given object implements the PrescriptionMedication interface.
 */
export function instanceOfPrescriptionMedication(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function PrescriptionMedicationFromJSON(json: any): PrescriptionMedication {
    return PrescriptionMedicationFromJSONTyped(json, false);
}

export function PrescriptionMedicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionMedication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'dosage': !exists(json, 'dosage') ? undefined : json['dosage'],
        'dosage_unit': !exists(json, 'dosage_unit') ? undefined : json['dosage_unit'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'quantity_unit': !exists(json, 'quantity_unit') ? undefined : json['quantity_unit'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'as_needed': !exists(json, 'as_needed') ? undefined : json['as_needed'],
        'prn': !exists(json, 'prn') ? undefined : json['prn'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'time_unit': !exists(json, 'time_unit') ? undefined : json['time_unit'],
        'refills': !exists(json, 'refills') ? undefined : json['refills'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'drugbank_product': !exists(json, 'drugbank_product') ? undefined : DrugbankProductFromJSON(json['drugbank_product']),
        'document_id': !exists(json, 'document_id') ? undefined : json['document_id'],
        'disable_refills': !exists(json, 'disable_refills') ? undefined : json['disable_refills'],
    };
}

export function PrescriptionMedicationToJSON(value?: PrescriptionMedication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'dosage': value.dosage,
        'dosage_unit': value.dosage_unit,
        'quantity': value.quantity,
        'quantity_unit': value.quantity_unit,
        'route': value.route,
        'frequency': value.frequency,
        'as_needed': value.as_needed,
        'prn': value.prn,
        'time': value.time,
        'time_unit': value.time_unit,
        'refills': value.refills,
        'comments': value.comments,
        'enabled': value.enabled,
        'drugbank_product': DrugbankProductToJSON(value.drugbank_product),
        'document_id': value.document_id,
        'disable_refills': value.disable_refills,
    };
}

