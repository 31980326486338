import _ from 'lodash';
import fastCompareIsEqual from 'react-fast-compare';

class PerformanceUtils {

  clone<T>(object: T): T {
    return _.cloneDeep(object);
  }

  objectToArray<T extends Record<string, any>>(object?: T): Array<T[keyof T]> {
    return Object.values(object ?? {});
  }

  arrayToObject<T extends Record<string, any>>(array: T[] = [], key: keyof T = 'id'): Record<string, T> {
    return array.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
  }

  /**
   * inserts or updates document in array in-place
   * using *key* to check for equality
   * @warning Ensure you're either passing in an existing list or assigning the return value somewhere
   * @returns the same array provided
   */
  upsert<T extends Record<string, any>>(document: T, collection: T[], key: keyof T = 'id'): T[] {
    const index = collection.findIndex(it => it[key] === document[key]);
    if (index !== -1) {
      collection[index] = document;
    } else {
      collection.push(document);
    }
    return collection;
  }

  editOrAdd<T extends Record<string, any>>(document: T, collection: T[] = [], key: keyof T = 'id'): T[] {
    const index = collection.findIndex(it => it[key] === document[key]);
    if (index !== -1) {
      collection[index] = document;
    } else {
      collection.unshift(document);
    }
    return collection;
  }

  editOnly<T extends Record<string, any>>(document: T, collection: T[] = [], key: keyof T = 'id'): T[] {
    const index = collection.findIndex(it => it[key] === document[key]);
    if (index !== -1) {
      collection[index] = document;
    }
    return collection;
  }

  removeFromArray<T extends Record<any, any>>(document: T, collection: T[] = [], key: keyof T = 'id'): T[] {
    return collection.filter(d => {
      return d[key] !== document[key];
    });
  }

  removeFromArrayInPlace<T extends Record<any, any>, K extends keyof T>(document: { [key in K]: T[key] }, collection: T[], key: K | 'id' = 'id'): void {
    _.remove(collection, it => it[key] === document[key as K])
  }

  // TODO consider naming groupById
  orderById<T extends Record<string, any>, K extends keyof T>(data: T[], field?: K): Record<T[K], T | undefined> {
    const key = field ?? 'id'
    const result: Partial<Record<T[K], T>> = {};

    data.forEach(elem => {
      const newKey = elem[key]
      if (newKey !== undefined) {
        result[newKey] = elem
      }
    })

    return result as Record<T[K], T>
  }

  sortBy<T>(data: T[] = [], fields = ['date'], reverse = true): T[] {
    let sorted = _.sortBy(data, fields);
    if (reverse) sorted = _.reverse(sorted);
    return sorted;
  }

  capitalizeFirstLetter<T extends string>(stringOrUndef?: T): Capitalize<T> {
    const string = stringOrUndef ?? ''
    return string.charAt(0).toUpperCase() + string.slice(1) as Capitalize<T>;
  }

  capitalize(string = ''): string {
    return string
      .split(' ')
      .map(this.capitalizeFirstLetter)
      .join(' ');
  }

  isEqual(foo: any, bar: any): boolean {
    return fastCompareIsEqual(foo, bar);
  }

  existsInArray(document: any, array: any[], compareIndex = 'id'): boolean {
    let filtered = array.filter(item => item[compareIndex] === document[compareIndex]);
    return filtered.length > 0;
  }

  /**
   * Recursively looks for keys in object that are undefined and deletes them
   */
  removeUndefinedKeys(obj: unknown) {
    if (obj === undefined || obj === null || typeof obj !== 'object') {
      return
    }
    if (Array.isArray(obj)) {
      obj.forEach(value => this.removeUndefinedKeys(value))
      return
    }
    Object.keys(obj).forEach(key => {
      const value = obj[key as keyof typeof obj]
      if (value === undefined) {
        delete obj[key as keyof typeof obj]
      } else if (value !== null && typeof value === 'object') {
        this.removeUndefinedKeys(value)
      }
    })
  }
}

export default new PerformanceUtils();
