import { PhysicianContact } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrapApi } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface ContactsState {
  searchTerm?: string
  contacts: PhysicianContact[]
}

const initialState: ContactsState = {
  contacts: []
}

const slice = createResettableSlice({
  name: 'Contacts',
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(api.Contacts.getPhysicianContacts.fulfilled, unwrapApi((state, data) => {
      state.contacts = data
    }))
    .addCase(api.Contacts.addPhysicianContact.fulfilled, unwrapApi((state, data) => {
      PerformanceUtils.upsert(data, state.contacts)
    }))
    .addCase(api.Contacts.removePhysicianContact.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state.contacts)
    }))
})

export const contactsActions = slice.actions
export default slice.reducer
