/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImmunizationHistory } from './ImmunizationHistory';
import {
    ImmunizationHistoryFromJSON,
    ImmunizationHistoryFromJSONTyped,
    ImmunizationHistoryToJSON,
} from './ImmunizationHistory';
import type { ImmunizationValue } from './ImmunizationValue';
import {
    ImmunizationValueFromJSON,
    ImmunizationValueFromJSONTyped,
    ImmunizationValueToJSON,
} from './ImmunizationValue';

/**
 * 
 * @export
 * @interface Immunization
 */
export interface Immunization {
    /**
     * 
     * @type {string}
     * @memberof Immunization
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Immunization
     */
    patient_id: string;
    /**
     * 
     * @type {ImmunizationValue}
     * @memberof Immunization
     */
    value: ImmunizationValue;
    /**
     * 
     * @type {Array<ImmunizationHistory>}
     * @memberof Immunization
     */
    history?: Array<ImmunizationHistory>;
    /**
     * 
     * @type {boolean}
     * @memberof Immunization
     */
    read?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Immunization
     */
    scheduled?: boolean;
}

/**
 * Check if a given object implements the Immunization interface.
 */
export function instanceOfImmunization(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function ImmunizationFromJSON(json: any): Immunization {
    return ImmunizationFromJSONTyped(json, false);
}

export function ImmunizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Immunization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': json['patient_id'],
        'value': ImmunizationValueFromJSON(json['value']),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(ImmunizationHistoryFromJSON)),
        'read': !exists(json, 'read') ? undefined : json['read'],
        'scheduled': !exists(json, 'scheduled') ? undefined : json['scheduled'],
    };
}

export function ImmunizationToJSON(value?: Immunization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'value': ImmunizationValueToJSON(value.value),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(ImmunizationHistoryToJSON)),
        'read': value.read,
        'scheduled': value.scheduled,
    };
}

