import {
  DocumentTemplate,
  DocumentTemplateCategory,
  DocumentTemplatePrefilled,
} from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrapApi } from 'careand-redux/utils/unwrap';
import PerformanceUtils from 'services/PerformanceUtils';
import _ from 'lodash';

export interface TemplatesState {
  templates: DocumentTemplate[];
  templatesPrefilled: DocumentTemplatePrefilled[];
  templateCategories: DocumentTemplateCategory[];
}

const initialState: TemplatesState = {
  templates: [],
  templatesPrefilled: [],
  templateCategories: [],
};

const slice = createResettableSlice({
  name: 'Templates',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        api.Templates.getDocumentTemplateCategories.fulfilled,
        unwrapApi((state, categories) => {
          const sorted = PerformanceUtils.sortBy(categories, ['name'], false);
          state.templateCategories = sorted;
        }),
      )
      .addCase(
        api.Templates.addDocumentTemplateCategory.fulfilled,
        unwrapApi((state, category) => {
          const updated = PerformanceUtils.upsert(category, state.templateCategories);
          state.templateCategories = PerformanceUtils.sortBy(updated, ['name'], false);
        }),
      )
      .addCase(
        api.Templates.getDocumentTemplates.fulfilled,
        unwrapApi((state, templates) => {
          state.templates = sortTemplates(templates);
        }),
      )
      .addCase(
        api.Templates.getDocumentTemplatesPrefilled.fulfilled,
        unwrapApi((state, templates) => {
          state.templatesPrefilled = templates;
        }),
      )
      .addCase(
        api.Templates.addDocumentTemplatePrefilled.fulfilled,
        unwrapApi((state, template) => {
          state.templatesPrefilled = PerformanceUtils.upsert(template, state.templatesPrefilled);
        }),
      )
      .addCase(
        api.Templates.deleteDocumentTemplatesPrefilled.fulfilled,
        unwrapApi((state, payload, req) => {
          state.templatesPrefilled = PerformanceUtils.removeFromArray(
            { id: req.id },
            state.templatesPrefilled,
          );
        }),
      )
      .addCase(api.Templates.getDocumentTemplate.fulfilled, unwrapApi(insertTemplate))
      .addCase(api.Templates.addDocumentTemplate.fulfilled, unwrapApi(insertTemplate))
      .addCase(api.Templates.updateDocumentTemplate.fulfilled, unwrapApi(insertTemplate))
      .addCase(
        api.Templates.deleteDocumentTemplate.fulfilled,
        unwrapApi((state, payload, req) => {
          state.templates = PerformanceUtils.removeFromArray({ id: req.id }, state.templates);
        }),
      )
      .addCase(api.Templates.addDocumentTemplateRequest.fulfilled, unwrapApi(insertTemplate)),
});

function insertTemplate(state: TemplatesState, template: DocumentTemplate) {
  state.templates = sortTemplates(PerformanceUtils.upsert(template, state.templates));
}

function sortTemplates(templates: DocumentTemplate[]) {
  function includes(templates: DocumentTemplate[], search: string, condition = true) {
    search = search.toLowerCase();
    return templates.filter(
      (template) => template.description?.toLowerCase().includes(search) === condition,
    );
  }

  let sorted = PerformanceUtils.sortBy(templates, ['description', 'secondary_name'], false);

  //nipissing
  let month = includes(sorted, 'month');
  month.sort((template1, template2) => {
    let split1 = template1.description?.split(' ') || [];
    let split2 = template2.description?.split(' ') || [];
    return Number.parseInt(split1[0]) - Number.parseInt(split2[0]);
  });

  sorted = includes(sorted, 'month', false);

  let year = includes(sorted, 'years old');
  sorted = includes(sorted, 'years old', false);

  //greig
  let ages = _.reverse(includes(sorted, 'ages '));
  sorted = includes(sorted, 'ages ', false);

  return [...month, ...year, ...ages, ...sorted];
}

export const templateActions = slice.actions;
export default slice.reducer;
