/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CorporationInvoice
 */
export interface CorporationInvoice {
    /**
     * 
     * @type {number}
     * @memberof CorporationInvoice
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof CorporationInvoice
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationInvoice
     */
    number?: string;
}

/**
 * Check if a given object implements the CorporationInvoice interface.
 */
export function instanceOfCorporationInvoice(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CorporationInvoiceFromJSON(json: any): CorporationInvoice {
    return CorporationInvoiceFromJSONTyped(json, false);
}

export function CorporationInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporationInvoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'number': !exists(json, 'number') ? undefined : json['number'],
    };
}

export function CorporationInvoiceToJSON(value?: CorporationInvoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'amount': value.amount,
        'number': value.number,
    };
}

