/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Invoice,
  Receipt,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InvoiceFromJSON,
    InvoiceToJSON,
    ReceiptFromJSON,
    ReceiptToJSON,
} from '../models';

export interface GetInvoicePdfRequest {
    patientId: string;
    invoiceId: string;
}

export interface GetInvoicesRequest {
    patientId: string;
}

export interface GetReceiptPdfRequest {
    patientId: string;
    receiptId: string;
}

export interface GetReceiptsRequest {
    patientId: string;
}

/**
 * 
 */
export class InvoicesApi extends runtime.BaseAPI {

    /**
     * Gets the PDF for the invoice (Roles: patient, admin)
     */
    async getInvoicePdfRaw(requestParameters: GetInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getInvoicePdf.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoicePdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/invoices/{patientId}/pdf/{invoiceId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getInvoicePdfRequestOptionsRaw(requestParameters: GetInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getInvoicePdf.');
        }

        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoicePdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/invoices/{patientId}/pdf/{invoiceId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the PDF for the invoice (Roles: patient, admin)
     */
    async getInvoicePdf(requestParameters: GetInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getInvoicePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all Invoices for a given patient (Roles: patient, admin)
     */
    async getInvoicesRaw(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invoice>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getInvoices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/invoices/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceFromJSON));
    }

    /**
        Request Config Getter
     */
    getInvoicesRequestOptionsRaw(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getInvoices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/invoices/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all Invoices for a given patient (Roles: patient, admin)
     */
    async getInvoices(requestParameters: GetInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invoice>> {
        const response = await this.getInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the PDF for the receipt (Roles: patient, admin)
     */
    async getReceiptPdfRaw(requestParameters: GetReceiptPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getReceiptPdf.');
        }

        if (requestParameters.receiptId === null || requestParameters.receiptId === undefined) {
            throw new runtime.RequiredError('receiptId','Required parameter requestParameters.receiptId was null or undefined when calling getReceiptPdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/invoices/receipts/{patientId}/pdf/{receiptId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"receiptId"}}`, encodeURIComponent(String(requestParameters.receiptId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getReceiptPdfRequestOptionsRaw(requestParameters: GetReceiptPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getReceiptPdf.');
        }

        if (requestParameters.receiptId === null || requestParameters.receiptId === undefined) {
            throw new runtime.RequiredError('receiptId','Required parameter requestParameters.receiptId was null or undefined when calling getReceiptPdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/invoices/receipts/{patientId}/pdf/{receiptId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"receiptId"}}`, encodeURIComponent(String(requestParameters.receiptId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the PDF for the receipt (Roles: patient, admin)
     */
    async getReceiptPdf(requestParameters: GetReceiptPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getReceiptPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all Receipts for a given patient (Roles: patient, admin)
     */
    async getReceiptsRaw(requestParameters: GetReceiptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Receipt>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getReceipts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/invoices/receipts/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReceiptFromJSON));
    }

    /**
        Request Config Getter
     */
    getReceiptsRequestOptionsRaw(requestParameters: GetReceiptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getReceipts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/invoices/receipts/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all Receipts for a given patient (Roles: patient, admin)
     */
    async getReceipts(requestParameters: GetReceiptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Receipt>> {
        const response = await this.getReceiptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
