/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JitsiMeetingPayload
 */
export interface JitsiMeetingPayload {
    /**
     * 
     * @type {string}
     * @memberof JitsiMeetingPayload
     */
    patient_name?: string;
    /**
     * 
     * @type {string}
     * @memberof JitsiMeetingPayload
     */
    room_name?: string;
}

/**
 * Check if a given object implements the JitsiMeetingPayload interface.
 */
export function instanceOfJitsiMeetingPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JitsiMeetingPayloadFromJSON(json: any): JitsiMeetingPayload {
    return JitsiMeetingPayloadFromJSONTyped(json, false);
}

export function JitsiMeetingPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): JitsiMeetingPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient_name': !exists(json, 'patient_name') ? undefined : json['patient_name'],
        'room_name': !exists(json, 'room_name') ? undefined : json['room_name'],
    };
}

export function JitsiMeetingPayloadToJSON(value?: JitsiMeetingPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient_name': value.patient_name,
        'room_name': value.room_name,
    };
}

