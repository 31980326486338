/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentFee
 */
export interface AppointmentFee {
    /**
     * 
     * @type {number}
     * @memberof AppointmentFee
     */
    ohip_value?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentFee
     */
    non_ohip_value?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentFee
     */
    booking_value?: number;
}

/**
 * Check if a given object implements the AppointmentFee interface.
 */
export function instanceOfAppointmentFee(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentFeeFromJSON(json: any): AppointmentFee {
    return AppointmentFeeFromJSONTyped(json, false);
}

export function AppointmentFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ohip_value': !exists(json, 'ohip_value') ? undefined : json['ohip_value'],
        'non_ohip_value': !exists(json, 'non_ohip_value') ? undefined : json['non_ohip_value'],
        'booking_value': !exists(json, 'booking_value') ? undefined : json['booking_value'],
    };
}

export function AppointmentFeeToJSON(value?: AppointmentFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ohip_value': value.ohip_value,
        'non_ohip_value': value.non_ohip_value,
        'booking_value': value.booking_value,
    };
}

