/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CouponMetadata } from './CouponMetadata';
import {
    CouponMetadataFromJSON,
    CouponMetadataFromJSONTyped,
    CouponMetadataToJSON,
} from './CouponMetadata';

/**
 * Credit Card object from Stripe
 * @export
 * @interface CreditCard
 */
export interface CreditCard {
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    exp_month?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    exp_year?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    last4?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    object?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    fingerprint?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    funding?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCard
     */
    _default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    cvc_check?: string;
    /**
     * 
     * @type {CouponMetadata}
     * @memberof CreditCard
     */
    metadata?: CouponMetadata;
}

/**
 * Check if a given object implements the CreditCard interface.
 */
export function instanceOfCreditCard(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreditCardFromJSON(json: any): CreditCard {
    return CreditCardFromJSONTyped(json, false);
}

export function CreditCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'exp_month': !exists(json, 'exp_month') ? undefined : json['exp_month'],
        'exp_year': !exists(json, 'exp_year') ? undefined : json['exp_year'],
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'funding': !exists(json, 'funding') ? undefined : json['funding'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'cvc_check': !exists(json, 'cvc_check') ? undefined : json['cvc_check'],
        'metadata': !exists(json, 'metadata') ? undefined : CouponMetadataFromJSON(json['metadata']),
    };
}

export function CreditCardToJSON(value?: CreditCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'brand': value.brand,
        'country': value.country,
        'exp_month': value.exp_month,
        'exp_year': value.exp_year,
        'last4': value.last4,
        'name': value.name,
        'object': value.object,
        'customer': value.customer,
        'fingerprint': value.fingerprint,
        'funding': value.funding,
        'default': value._default,
        'cvc_check': value.cvc_check,
        'metadata': CouponMetadataToJSON(value.metadata),
    };
}

