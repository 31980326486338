import React from 'react';
import TextField from '@material-ui/core/TextField';

const DefaultTextField = (props) => {
  const {
    id,
    value,
    onChange,
    onClick,
    name,
    className,
    label,
    disabled,
    style,
    onKeyDown,
    onBlur,
    type,
    field = { input: {} },
    autoFocus,
    ref,
    error,
    dataLpignore,
    placeholder,
    multiline = false,
    autoComplete,
    inputProps,
    inputLabelProps,
    htmlInputProps,
    tabIndex,
    onFocus,
  } = props;

  const errorText = field.meta && (!field.meta.pristine ? field.meta.error : '');

  return (
    <TextField
      data-lpignore={dataLpignore}
      autoFocus={autoFocus}
      value={value}
      onClick={onClick}
      onChange={onChange}
      name={name || field.input.name}
      type={type}
      label={label || field.label}
      disabled={disabled}
      onKeyDown={onKeyDown}
      style={style || { width: '100%' }}
      placeholder={placeholder}
      multiline={multiline}
      {...field.input}
      {...field}
      helperText={errorText}
      error={!!errorText}
      className={`default-text-field ${className || (field || {}).className || ''}`}
      id={id || (name || field.input.name || '').replace(/\./g, '-') + '-field'}
      ref={ref}
      onBlur={onBlur}
      autoComplete={autoComplete}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      inputProps={htmlInputProps}
      tabIndex={tabIndex}
      onFocus={onFocus}
    />
  );
};

export default DefaultTextField;
