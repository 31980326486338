/* eslint-disable no-restricted-syntax */
import { ResponseError } from "generated/api";

interface SerializedResponse {
  headers: Record<string, string>
  status: number
  jsonBody: unknown
  usesBody: boolean
  jsonBodyMessage?: string
}

interface SerializedResponseError {
  isResponseError: true,
  response: SerializedResponse,
  message?: string
  name?: string
  stack?: string
  cause?: unknown
}

export const isResponseError = (error: unknown): error is SerializedResponseError => {
  return (error as SerializedResponseError).isResponseError === true && !!(error as SerializedResponseError).response
}

export const withResponseError = (error: unknown, fn: (error: SerializedResponseError) => void) => {
  if (isResponseError(error)) {
    fn(error)
  }
}

export const serialize = async (error: ResponseError): Promise<SerializedResponseError> => {
  const headers: Record<string, string> = {}
  for (const [key, value] of error.response.headers.entries()) {
    headers[key] = value
  }

  let jsonBody: unknown
  try {
    jsonBody = await error.response.json()
  } catch (e: unknown) {
    // NOOP
  }

  const jsonBodyMessage = (jsonBody as any)?.message

  return {
    isResponseError: true,
    response: {
      headers,
      status: error.response.status,
      jsonBody,
      usesBody: !!jsonBody,
      jsonBodyMessage
    },
    message: error.message,
    name: error.name,
    stack: error.stack,
    cause: error.cause
  }
}