/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminLoginPayload,
  Authentication,
  EmailVerification,
  ErrorResponse,
  LinkedAccountLoginPayload,
  NewCredentials,
  PasswordChange,
  PasswordVerification,
  Patient,
  PatientByStaffRegisterPayload,
  PatientLoginPayload,
  PatientRegisterPayload,
  PhysicianLoginPayload,
  StaffRegisterPayload,
  StringResource,
  StringResources,
  Token,
  TwoFactorAuthPayload,
  TwoFactorPushTokenPayload,
  TwoFactorValidateLoginPayload,
} from '../models';
import {
    AdminLoginPayloadFromJSON,
    AdminLoginPayloadToJSON,
    AuthenticationFromJSON,
    AuthenticationToJSON,
    EmailVerificationFromJSON,
    EmailVerificationToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LinkedAccountLoginPayloadFromJSON,
    LinkedAccountLoginPayloadToJSON,
    NewCredentialsFromJSON,
    NewCredentialsToJSON,
    PasswordChangeFromJSON,
    PasswordChangeToJSON,
    PasswordVerificationFromJSON,
    PasswordVerificationToJSON,
    PatientFromJSON,
    PatientToJSON,
    PatientByStaffRegisterPayloadFromJSON,
    PatientByStaffRegisterPayloadToJSON,
    PatientLoginPayloadFromJSON,
    PatientLoginPayloadToJSON,
    PatientRegisterPayloadFromJSON,
    PatientRegisterPayloadToJSON,
    PhysicianLoginPayloadFromJSON,
    PhysicianLoginPayloadToJSON,
    StaffRegisterPayloadFromJSON,
    StaffRegisterPayloadToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
    StringResourcesFromJSON,
    StringResourcesToJSON,
    TokenFromJSON,
    TokenToJSON,
    TwoFactorAuthPayloadFromJSON,
    TwoFactorAuthPayloadToJSON,
    TwoFactorPushTokenPayloadFromJSON,
    TwoFactorPushTokenPayloadToJSON,
    TwoFactorValidateLoginPayloadFromJSON,
    TwoFactorValidateLoginPayloadToJSON,
} from '../models';

export interface AddLinkedAccountRequest {
    auth: Authentication;
}

export interface AdminLoginRequest {
    auth: Authentication;
}

export interface ChangePasswordRequest {
    id: string;
    role: ChangePasswordRoleEnum;
    passwords: PasswordChange;
}

export interface CheckAvailableUsernameRequest {
    username: string;
    role: CheckAvailableUsernameRoleEnum;
}

export interface CompleteTwoFactorAuthenticationRequest {
    payload: TwoFactorAuthPayload;
}

export interface GenerateCredentialsAuthenticationCodeRequest {
    patientId: string;
}

export interface GetPermissionsRequest {
    id: string;
}

export interface GetTwoFactorCodeRequest {
    id: string;
}

export interface GetUsernameRequest {
    userId: string;
}

export interface HasTwoFactorAuthenticationRequest {
    id: string;
}

export interface PatientLoginRequest {
    auth: Authentication;
}

export interface PhysicianLoginRequest {
    auth: Authentication;
}

export interface RecoverPasswordRequest {
    username: string;
    role: RecoverPasswordRoleEnum;
}

export interface RecoverUsernameByPhoneRequest {
    phone: string;
    role: RecoverUsernameByPhoneRoleEnum;
}

export interface RegisterLinkedAccountRequest {
    payload: PatientRegisterPayload;
}

export interface RegisterPatientRequest {
    payload: PatientRegisterPayload;
}

export interface RegisterPatientByStaffRequest {
    payload: PatientByStaffRegisterPayload;
}

export interface RegisterStaffRequest {
    payload: StaffRegisterPayload;
}

export interface RemoveTwoFactorAuthenticationRequest {
    id: string;
}

export interface SendEmailVerificationRequest {
    id: string;
}

export interface SendPhoneVerificationRequest {
    phone: string;
}

export interface SetCredentialsRequest {
    patientId: string;
    newCredentials: NewCredentials;
}

export interface SetPermissionsRequest {
    id: string;
    permissions: StringResources;
}

export interface UpdateTwoFactorPushTokenRequest {
    payload: TwoFactorPushTokenPayload;
}

export interface ValidateTwoFactorLoginRequest {
    payload: TwoFactorValidateLoginPayload;
}

export interface VerifyEmailRequest {
    verification: EmailVerification;
}

export interface VerifyPasswordRecoveryRequest {
    username: string;
    role: VerifyPasswordRecoveryRoleEnum;
    authData: PasswordVerification;
}

export interface VerifyUsernameRecoveryRequest {
    phone: string;
    role: VerifyUsernameRecoveryRoleEnum;
    code: StringResource;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Adds a linked account (Roles: patient)
     */
    async addLinkedAccountRaw(requestParameters: AddLinkedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkedAccountLoginPayload>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling addLinkedAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/linked-accounts/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedAccountLoginPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addLinkedAccountRequestOptionsRaw(requestParameters: AddLinkedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling addLinkedAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/linked-accounts/add`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        };
        return responseConfig
    }

    /**
     * Adds a linked account (Roles: patient)
     */
    async addLinkedAccount(requestParameters: AddLinkedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkedAccountLoginPayload> {
        const response = await this.addLinkedAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allow a admin to log in and receive a JWT Token (Roles: admin, none)
     */
    async adminLoginRaw(requestParameters: AdminLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminLoginPayload>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling adminLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/login/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminLoginPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    adminLoginRequestOptionsRaw(requestParameters: AdminLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling adminLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/login/admin`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        };
        return responseConfig
    }

    /**
     * Allow a admin to log in and receive a JWT Token (Roles: admin, none)
     */
    async adminLogin(requestParameters: AdminLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminLoginPayload> {
        const response = await this.adminLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Changes the User\'s password (Roles: patient, physician, admin)
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePassword.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling changePassword.');
        }

        if (requestParameters.passwords === null || requestParameters.passwords === undefined) {
            throw new runtime.RequiredError('passwords','Required parameter requestParameters.passwords was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/change-password/{role}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeToJSON(requestParameters.passwords),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    changePasswordRequestOptionsRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePassword.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling changePassword.');
        }

        if (requestParameters.passwords === null || requestParameters.passwords === undefined) {
            throw new runtime.RequiredError('passwords','Required parameter requestParameters.passwords was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/change-password/{role}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeToJSON(requestParameters.passwords),
        };
        return responseConfig
    }

    /**
     * Changes the User\'s password (Roles: patient, physician, admin)
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Checks if a username is available (Roles: none)
     */
    async checkAvailableUsernameRaw(requestParameters: CheckAvailableUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling checkAvailableUsername.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling checkAvailableUsername.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/register/available-username/{role}/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    checkAvailableUsernameRequestOptionsRaw(requestParameters: CheckAvailableUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling checkAvailableUsername.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling checkAvailableUsername.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/register/available-username/{role}/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Checks if a username is available (Roles: none)
     */
    async checkAvailableUsername(requestParameters: CheckAvailableUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkAvailableUsernameRaw(requestParameters, initOverrides);
    }

    /**
     * Completes the 2FA setup process (Roles: admin, physician)
     */
    async completeTwoFactorAuthenticationRaw(requestParameters: CompleteTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling completeTwoFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorAuthPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    completeTwoFactorAuthenticationRequestOptionsRaw(requestParameters: CompleteTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling completeTwoFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/complete`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorAuthPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Completes the 2FA setup process (Roles: admin, physician)
     */
    async completeTwoFactorAuthentication(requestParameters: CompleteTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.completeTwoFactorAuthenticationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates an authentication code to create credentials (Roles: admin)
     */
    async generateCredentialsAuthenticationCodeRaw(requestParameters: GenerateCredentialsAuthenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling generateCredentialsAuthenticationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/generate-authentication/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    generateCredentialsAuthenticationCodeRequestOptionsRaw(requestParameters: GenerateCredentialsAuthenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling generateCredentialsAuthenticationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/generate-authentication/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Generates an authentication code to create credentials (Roles: admin)
     */
    async generateCredentialsAuthenticationCode(requestParameters: GenerateCredentialsAuthenticationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.generateCredentialsAuthenticationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the user\'s permissions (Roles: admin)
     */
    async getPermissionsRaw(requestParameters: GetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResources>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourcesFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getPermissionsRequestOptionsRaw(requestParameters: GetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user\'s permissions (Roles: admin)
     */
    async getPermissions(requestParameters: GetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResources> {
        const response = await this.getPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a 2-FA OTP for the account (Roles: admin)
     */
    async getTwoFactorCodeRaw(requestParameters: GetTwoFactorCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTwoFactorCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/{id}/code`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getTwoFactorCodeRequestOptionsRaw(requestParameters: GetTwoFactorCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTwoFactorCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/{id}/code`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a 2-FA OTP for the account (Roles: admin)
     */
    async getTwoFactorCode(requestParameters: GetTwoFactorCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.getTwoFactorCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a user\'s username (Roles: admin)
     */
    async getUsernameRaw(requestParameters: GetUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUsername.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/username/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getUsernameRequestOptionsRaw(requestParameters: GetUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUsername.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/username/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a user\'s username (Roles: admin)
     */
    async getUsername(requestParameters: GetUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.getUsernameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks if a user has 2FA active (Roles: admin, physician)
     */
    async hasTwoFactorAuthenticationRaw(requestParameters: HasTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hasTwoFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    hasTwoFactorAuthenticationRequestOptionsRaw(requestParameters: HasTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling hasTwoFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Checks if a user has 2FA active (Roles: admin, physician)
     */
    async hasTwoFactorAuthentication(requestParameters: HasTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.hasTwoFactorAuthenticationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allow a patient to log in and receive a JWT Token. If authenticated as another patient, it will add the account as a linked account. (Roles: patient, none)
     */
    async patientLoginRaw(requestParameters: PatientLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientLoginPayload>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling patientLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/login/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientLoginPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    patientLoginRequestOptionsRaw(requestParameters: PatientLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling patientLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/login/patient`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        };
        return responseConfig
    }

    /**
     * Allow a patient to log in and receive a JWT Token. If authenticated as another patient, it will add the account as a linked account. (Roles: patient, none)
     */
    async patientLogin(requestParameters: PatientLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientLoginPayload> {
        const response = await this.patientLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allow a physician to log in and receive a JWT Token. Must verify account first. (Roles: physician, none)
     */
    async physicianLoginRaw(requestParameters: PhysicianLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhysicianLoginPayload>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling physicianLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/login/physician`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhysicianLoginPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    physicianLoginRequestOptionsRaw(requestParameters: PhysicianLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling physicianLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/login/physician`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        };
        return responseConfig
    }

    /**
     * Allow a physician to log in and receive a JWT Token. Must verify account first. (Roles: physician, none)
     */
    async physicianLogin(requestParameters: PhysicianLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhysicianLoginPayload> {
        const response = await this.physicianLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends a password recovery email with a verification code (Roles: none)
     */
    async recoverPasswordRaw(requestParameters: RecoverPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling recoverPassword.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling recoverPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/recover-password/{role}/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    recoverPasswordRequestOptionsRaw(requestParameters: RecoverPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling recoverPassword.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling recoverPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/recover-password/{role}/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Sends a password recovery email with a verification code (Roles: none)
     */
    async recoverPassword(requestParameters: RecoverPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recoverPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Sends an SMS with a verification code to verify request (Roles: none)
     */
    async recoverUsernameByPhoneRaw(requestParameters: RecoverUsernameByPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling recoverUsernameByPhone.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling recoverUsernameByPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/recover-username/{role}/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    recoverUsernameByPhoneRequestOptionsRaw(requestParameters: RecoverUsernameByPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling recoverUsernameByPhone.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling recoverUsernameByPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/recover-username/{role}/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Sends an SMS with a verification code to verify request (Roles: none)
     */
    async recoverUsernameByPhone(requestParameters: RecoverUsernameByPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recoverUsernameByPhoneRaw(requestParameters, initOverrides);
    }

    /**
     * Refreshes an authentication token (Roles: admin, patient, physician)
     */
    async refreshTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/refresh-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    refreshTokenRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/refresh-token`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Refreshes an authentication token (Roles: admin, patient, physician)
     */
    async refreshToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.refreshTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Registers a new Patient and adds the new account as a linked account (Roles: patient)
     */
    async registerLinkedAccountRaw(requestParameters: RegisterLinkedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkedAccountLoginPayload>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerLinkedAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/linked-accounts/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientRegisterPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedAccountLoginPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    registerLinkedAccountRequestOptionsRaw(requestParameters: RegisterLinkedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerLinkedAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/linked-accounts/register`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientRegisterPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Registers a new Patient and adds the new account as a linked account (Roles: patient)
     */
    async registerLinkedAccount(requestParameters: RegisterLinkedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkedAccountLoginPayload> {
        const response = await this.registerLinkedAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registers a new Patient. If authenticated as a Patient, it will add the new account as a linked account (Roles: patient, none)
     */
    async registerPatientRaw(requestParameters: RegisterPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientLoginPayload>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerPatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/register/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientRegisterPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientLoginPayloadFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    registerPatientRequestOptionsRaw(requestParameters: RegisterPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerPatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/register/patient`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientRegisterPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Registers a new Patient. If authenticated as a Patient, it will add the new account as a linked account (Roles: patient, none)
     */
    async registerPatient(requestParameters: RegisterPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientLoginPayload> {
        const response = await this.registerPatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registers a new Patient (Roles: admin)
     */
    async registerPatientByStaffRaw(requestParameters: RegisterPatientByStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerPatientByStaff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/register/patient-by-staff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientByStaffRegisterPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    registerPatientByStaffRequestOptionsRaw(requestParameters: RegisterPatientByStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerPatientByStaff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/register/patient-by-staff`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientByStaffRegisterPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Registers a new Patient (Roles: admin)
     */
    async registerPatientByStaff(requestParameters: RegisterPatientByStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.registerPatientByStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Registers a new Staff (Roles: admin)
     */
    async registerStaffRaw(requestParameters: RegisterStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerStaff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/register/staff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffRegisterPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    registerStaffRequestOptionsRaw(requestParameters: RegisterStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling registerStaff.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/register/staff`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StaffRegisterPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Registers a new Staff (Roles: admin)
     */
    async registerStaff(requestParameters: RegisterStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerStaffRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a user\'s 2-Factor Authentication (Roles: admin, physician)
     */
    async removeTwoFactorAuthenticationRaw(requestParameters: RemoveTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeTwoFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removeTwoFactorAuthenticationRequestOptionsRaw(requestParameters: RemoveTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeTwoFactorAuthentication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a user\'s 2-Factor Authentication (Roles: admin, physician)
     */
    async removeTwoFactorAuthentication(requestParameters: RemoveTwoFactorAuthenticationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeTwoFactorAuthenticationRaw(requestParameters, initOverrides);
    }

    /**
     * Triggers a mail verification (Roles: admin)
     */
    async sendEmailVerificationRaw(requestParameters: SendEmailVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendEmailVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/send-email-verification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendEmailVerificationRequestOptionsRaw(requestParameters: SendEmailVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendEmailVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/send-email-verification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Triggers a mail verification (Roles: admin)
     */
    async sendEmailVerification(requestParameters: SendEmailVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmailVerificationRaw(requestParameters, initOverrides);
    }

    /**
     * Sends SMS verification code that can be used to validate the User\'s phone number (Roles: none)
     */
    async sendPhoneVerificationRaw(requestParameters: SendPhoneVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling sendPhoneVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/phone/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendPhoneVerificationRequestOptionsRaw(requestParameters: SendPhoneVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling sendPhoneVerification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/phone/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Sends SMS verification code that can be used to validate the User\'s phone number (Roles: none)
     */
    async sendPhoneVerification(requestParameters: SendPhoneVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendPhoneVerificationRaw(requestParameters, initOverrides);
    }

    /**
     * Sets the patient\'s credentials with an authentication code (Roles: none)
     */
    async setCredentialsRaw(requestParameters: SetCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling setCredentials.');
        }

        if (requestParameters.newCredentials === null || requestParameters.newCredentials === undefined) {
            throw new runtime.RequiredError('newCredentials','Required parameter requestParameters.newCredentials was null or undefined when calling setCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/generate-authentication/{patientId}/set-credentials`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCredentialsToJSON(requestParameters.newCredentials),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    setCredentialsRequestOptionsRaw(requestParameters: SetCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling setCredentials.');
        }

        if (requestParameters.newCredentials === null || requestParameters.newCredentials === undefined) {
            throw new runtime.RequiredError('newCredentials','Required parameter requestParameters.newCredentials was null or undefined when calling setCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/generate-authentication/{patientId}/set-credentials`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: NewCredentialsToJSON(requestParameters.newCredentials),
        };
        return responseConfig
    }

    /**
     * Sets the patient\'s credentials with an authentication code (Roles: none)
     */
    async setCredentials(requestParameters: SetCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setCredentialsRaw(requestParameters, initOverrides);
    }

    /**
     * Sets the user\'s permissions (Roles: admin)
     */
    async setPermissionsRaw(requestParameters: SetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setPermissions.');
        }

        if (requestParameters.permissions === null || requestParameters.permissions === undefined) {
            throw new runtime.RequiredError('permissions','Required parameter requestParameters.permissions was null or undefined when calling setPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourcesToJSON(requestParameters.permissions),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    setPermissionsRequestOptionsRaw(requestParameters: SetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setPermissions.');
        }

        if (requestParameters.permissions === null || requestParameters.permissions === undefined) {
            throw new runtime.RequiredError('permissions','Required parameter requestParameters.permissions was null or undefined when calling setPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/permissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourcesToJSON(requestParameters.permissions),
        };
        return responseConfig
    }

    /**
     * Sets the user\'s permissions (Roles: admin)
     */
    async setPermissions(requestParameters: SetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPermissionsRaw(requestParameters, initOverrides);
    }

    /**
     * Gives the user a TOTP URI to setup the account\'s 2FA (Roles: admin, physician)
     */
    async startTwoFactorAuthenticationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/start`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    startTwoFactorAuthenticationRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/start`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gives the user a TOTP URI to setup the account\'s 2FA (Roles: admin, physician)
     */
    async startTwoFactorAuthentication(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.startTwoFactorAuthenticationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the staff app\'s push notification for 2fa login (Roles: none)
     */
    async updateTwoFactorPushTokenRaw(requestParameters: UpdateTwoFactorPushTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling updateTwoFactorPushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/update-push-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorPushTokenPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    updateTwoFactorPushTokenRequestOptionsRaw(requestParameters: UpdateTwoFactorPushTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling updateTwoFactorPushToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/update-push-token`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorPushTokenPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Updates the staff app\'s push notification for 2fa login (Roles: none)
     */
    async updateTwoFactorPushToken(requestParameters: UpdateTwoFactorPushTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTwoFactorPushTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Grants access or not to a web portal login (Roles: none)
     */
    async validateTwoFactorLoginRaw(requestParameters: ValidateTwoFactorLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling validateTwoFactorLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/2fa/validate-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorValidateLoginPayloadToJSON(requestParameters.payload),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    validateTwoFactorLoginRequestOptionsRaw(requestParameters: ValidateTwoFactorLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling validateTwoFactorLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/2fa/validate-login`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: TwoFactorValidateLoginPayloadToJSON(requestParameters.payload),
        };
        return responseConfig
    }

    /**
     * Grants access or not to a web portal login (Roles: none)
     */
    async validateTwoFactorLogin(requestParameters: ValidateTwoFactorLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateTwoFactorLoginRaw(requestParameters, initOverrides);
    }

    /**
     * Verifies an email address (Roles: none, patient)
     */
    async verifyEmailRaw(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.verification === null || requestParameters.verification === undefined) {
            throw new runtime.RequiredError('verification','Required parameter requestParameters.verification was null or undefined when calling verifyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/auth/verify-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationToJSON(requestParameters.verification),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    verifyEmailRequestOptionsRaw(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.verification === null || requestParameters.verification === undefined) {
            throw new runtime.RequiredError('verification','Required parameter requestParameters.verification was null or undefined when calling verifyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/auth/verify-email`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: EmailVerificationToJSON(requestParameters.verification),
        };
        return responseConfig
    }

    /**
     * Verifies an email address (Roles: none, patient)
     */
    async verifyEmail(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Changes the User\'s password if provided with the correct verification code (Roles: none)
     */
    async verifyPasswordRecoveryRaw(requestParameters: VerifyPasswordRecoveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling verifyPasswordRecovery.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling verifyPasswordRecovery.');
        }

        if (requestParameters.authData === null || requestParameters.authData === undefined) {
            throw new runtime.RequiredError('authData','Required parameter requestParameters.authData was null or undefined when calling verifyPasswordRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/recover-password/{role}/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordVerificationToJSON(requestParameters.authData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    verifyPasswordRecoveryRequestOptionsRaw(requestParameters: VerifyPasswordRecoveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling verifyPasswordRecovery.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling verifyPasswordRecovery.');
        }

        if (requestParameters.authData === null || requestParameters.authData === undefined) {
            throw new runtime.RequiredError('authData','Required parameter requestParameters.authData was null or undefined when calling verifyPasswordRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/recover-password/{role}/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PasswordVerificationToJSON(requestParameters.authData),
        };
        return responseConfig
    }

    /**
     * Changes the User\'s password if provided with the correct verification code (Roles: none)
     */
    async verifyPasswordRecovery(requestParameters: VerifyPasswordRecoveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyPasswordRecoveryRaw(requestParameters, initOverrides);
    }

    /**
     * List\'s the usernames associated with the phone number (Roles: none)
     */
    async verifyUsernameRecoveryRaw(requestParameters: VerifyUsernameRecoveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StringResource>>> {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling verifyUsernameRecovery.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling verifyUsernameRecovery.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling verifyUsernameRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/recover-username/{role}/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.code),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StringResourceFromJSON));
    }

    /**
        Request Config Getter
     */
    verifyUsernameRecoveryRequestOptionsRaw(requestParameters: VerifyUsernameRecoveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling verifyUsernameRecovery.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling verifyUsernameRecovery.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling verifyUsernameRecovery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/recover-username/{role}/{phone}`.replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.code),
        };
        return responseConfig
    }

    /**
     * List\'s the usernames associated with the phone number (Roles: none)
     */
    async verifyUsernameRecovery(requestParameters: VerifyUsernameRecoveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StringResource>> {
        const response = await this.verifyUsernameRecoveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ChangePasswordRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type ChangePasswordRoleEnum = typeof ChangePasswordRoleEnum[keyof typeof ChangePasswordRoleEnum];
/**
 * @export
 */
export const CheckAvailableUsernameRoleEnum = {
    Patient: 'patient'
} as const;
export type CheckAvailableUsernameRoleEnum = typeof CheckAvailableUsernameRoleEnum[keyof typeof CheckAvailableUsernameRoleEnum];
/**
 * @export
 */
export const RecoverPasswordRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type RecoverPasswordRoleEnum = typeof RecoverPasswordRoleEnum[keyof typeof RecoverPasswordRoleEnum];
/**
 * @export
 */
export const RecoverUsernameByPhoneRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type RecoverUsernameByPhoneRoleEnum = typeof RecoverUsernameByPhoneRoleEnum[keyof typeof RecoverUsernameByPhoneRoleEnum];
/**
 * @export
 */
export const VerifyPasswordRecoveryRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type VerifyPasswordRecoveryRoleEnum = typeof VerifyPasswordRecoveryRoleEnum[keyof typeof VerifyPasswordRecoveryRoleEnum];
/**
 * @export
 */
export const VerifyUsernameRecoveryRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type VerifyUsernameRecoveryRoleEnum = typeof VerifyUsernameRecoveryRoleEnum[keyof typeof VerifyUsernameRecoveryRoleEnum];
