/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OHIPNumber
 */
export interface OHIPNumber {
    /**
     * 
     * @type {string}
     * @memberof OHIPNumber
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof OHIPNumber
     */
    version?: string;
}

/**
 * Check if a given object implements the OHIPNumber interface.
 */
export function instanceOfOHIPNumber(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;

    return isInstance;
}

export function OHIPNumberFromJSON(json: any): OHIPNumber {
    return OHIPNumberFromJSONTyped(json, false);
}

export function OHIPNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): OHIPNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function OHIPNumberToJSON(value?: OHIPNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'version': value.version,
    };
}

