import React from 'react';
import Icon from 'components/Utils/Icon';
import AnimatedTransition from 'hoc/AnimatedTransition';

const DefaultPatientModal = ({
                               content,
                               onRequestClose,
                               isOpen,
                               wrap = false,
                               padding = true,
                               title,
                               style = {},
                               headerContent,
                               fullHeight = false,
                               scroll = true,
                               partial = false,
                               className,
                               topBar = true,
                               id
                             }) => {

  let finalClassName = "patient-modal";
  if (fullHeight) finalClassName += " full-height";
  if (partial) finalClassName += " partial";
  if (className) finalClassName += ` ${className}`;

  const animation = partial ? 'partial-modal' : 'modal'

  return (
    <AnimatedTransition animation={animation} show={isOpen}>
      <div className={finalClassName} id={id}>
        {partial ? <div className="modal-bg" onClick={onRequestClose}/> : null}
        <ModalContent
          topBar={topBar}
          wrap={wrap}
          title={title}
          style={style}
          onRequestClose={onRequestClose}
          padding={padding}
          content={content}
          headerContent={headerContent}
          scroll={scroll}
        />
      </div>
    </AnimatedTransition>
  );
};

const ModalContent = ({
                        topBar,
                        wrap,
                        title,
                        style,
                        onRequestClose,
                        padding,
                        content,
                        headerContent,
                        scroll
                      }) =>
  wrap ? (
    <div
      className="modal-content"
      id={title?.replace(/ /g, '_') + '-modal'}
      style={style}
    >
      {topBar && <div className="patient-top-bar">
        {onRequestClose ? <div className="left">
          <Icon src="chevron-down" height="20px" onClick={onRequestClose}/>
        </div> : null}
        <div className="title">
          {headerContent || title}
        </div>
        {onRequestClose && <div className="right"/>}
      </div>}
      <div className={`modal-content-wrapper${scroll ? " scroll-list" : ""}`}>
        {padding ? <div className="padded-box">{content}</div> : content}
      </div>
    </div>
  ) : (
    content
  );

export default DefaultPatientModal;
