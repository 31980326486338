/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CorporationInvoice } from './CorporationInvoice';
import {
    CorporationInvoiceFromJSON,
    CorporationInvoiceFromJSONTyped,
    CorporationInvoiceToJSON,
} from './CorporationInvoice';

/**
 * 
 * @export
 * @interface CorporationBatch
 */
export interface CorporationBatch {
    /**
     * 
     * @type {string}
     * @memberof CorporationBatch
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporationBatch
     */
    corporation_id?: string;
    /**
     * 
     * @type {number}
     * @memberof CorporationBatch
     */
    date?: number;
    /**
     * 
     * @type {Array<CorporationInvoice>}
     * @memberof CorporationBatch
     */
    invoices?: Array<CorporationInvoice>;
    /**
     * 
     * @type {boolean}
     * @memberof CorporationBatch
     */
    disabled?: boolean;
}

/**
 * Check if a given object implements the CorporationBatch interface.
 */
export function instanceOfCorporationBatch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CorporationBatchFromJSON(json: any): CorporationBatch {
    return CorporationBatchFromJSONTyped(json, false);
}

export function CorporationBatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporationBatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'corporation_id': !exists(json, 'corporation_id') ? undefined : json['corporation_id'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'invoices': !exists(json, 'invoices') ? undefined : ((json['invoices'] as Array<any>).map(CorporationInvoiceFromJSON)),
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function CorporationBatchToJSON(value?: CorporationBatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'corporation_id': value.corporation_id,
        'date': value.date,
        'invoices': value.invoices === undefined ? undefined : ((value.invoices as Array<any>).map(CorporationInvoiceToJSON)),
        'disabled': value.disabled,
    };
}

