import imageFemale from "assets/img/icons/avatar-female.svg";
import imageMale from "assets/img/icons/avatar-male.svg";
import {PhysicianPhysicianTypeEnum} from "generated/api";

class PhysicianUtils {
  constructor() {
    this.PHYSICIAN_SKILLS = {
      iud: "IUD",
    };
  }

  getName(physician, errorPlaceholder = undefined) {
    let error = undefined;

    if (!physician) return error;

    let name = "";

    if (physician.first_name !== undefined) name = physician.first_name;

    if (physician.last_name !== undefined) name += " " + physician.last_name;

    name = name.trim();

    if (name === "") name = error;

    if (name === error && errorPlaceholder !== undefined)
      name = errorPlaceholder;

    return name;
  }

  getFirstName(physician, errorPlaceholder = undefined) {
    let error = undefined;

    if (!physician) return error;

    let name = "";

    if (physician.first_name !== undefined) name = physician.first_name;

    name = name.trim();

    if (name === "") name = error;

    if (name === error && errorPlaceholder !== undefined)
      name = errorPlaceholder;

    return name;
  }

  getInitials(physician) {
    let name = (this.getName(physician) || "").trim();
    let split = name.split(" ");
    split = split.map((word) => word.charAt(0));
    return split.join("");
  }

  getSpecialty(physician) {
    let specialty = "";

    if (physician && physician.specialty) specialty = physician.specialty;

    return specialty;
  }

  getNameWithSpecialty(physician, placeholder = "Unassigned Practitioner") {
    if (!physician) return placeholder;
    return (
      this.getName(physician) +
      (this.getSpecialty(physician) ? ` (${this.getSpecialty(physician)})` : "")
    );
  }

  getPhotoUrls(physician) {
    return [
      physician?.photo,
      this.getGender(physician) === "Female" ? imageFemale : imageMale,
    ];
  }

  getGender(physician) {
    let gender = "Unknown Gender";

    if (physician === undefined) return gender;

    if (physician.gender !== undefined) gender = physician.gender;

    return gender;
  }

  isNursePractitioner(physician) {
    return (
      !physician.intern_of &&
      (!physician.physician_type ||
        physician.physician_type === PhysicianPhysicianTypeEnum.NursePractitioner)
    );
  }
}

export default new PhysicianUtils();
