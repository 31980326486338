import { HealthData, HealthRecordsMetricSummary, MetricType, MetricTypeAppleHealth } from "generated/api"
import { z } from "zod"
import { createMessage, NativeMessage, NativeMessageSchema } from "./NativeMessage"

export const AppleHealthUpdateMessageSchema = z.object({
  type: z.literal('appleHealthUpdate'),

  hasAppleHealth: z.boolean(),
  primaryAppleHealthUser: z.string().optional(),
  permissions: z.array(z.string())
}).extend(NativeMessageSchema.shape)

export type AppleHealthUpdateMessage = z.infer<typeof AppleHealthUpdateMessageSchema>

export const RequestAppleHealthPermissionsMessageSchema = z.object({
  type: z.literal('requestAppleHealthPermissions'),
  appleHealthMetrics: z.array(z.object({
    id: z.string().optional(),
    unit: z.string().optional(),
    metric_type: z.string().optional()
  }))
}).extend(NativeMessageSchema.shape)
export type RequestAppleHealthPermissionMessage = z.infer<typeof RequestAppleHealthPermissionsMessageSchema>

export const createRequestAppleHealthPermissionMessageSchema = (metrics: MetricTypeAppleHealth[]) => {
  return {
    ...createMessage(),
    type: 'requestAppleHealthPermissions',
    appleHealthMetrics: metrics
  } satisfies RequestAppleHealthPermissionMessage
}

export const SetAppleHealthPrimaryUserMessageSchema = z.object({
  type: z.literal('setAppleHealthPrimaryUser'),
  primaryAppleHealthUser: z.string(),
}).extend(NativeMessageSchema.shape)

export type SetAppleHealthPrimaryUserMessage = z.infer<typeof SetAppleHealthPrimaryUserMessageSchema>

export const createSetAppleHealthPrimaryUserMessage = (user: string) => {
  return {
    ...createMessage(),
    type: 'setAppleHealthPrimaryUser',
    primaryAppleHealthUser: user
  } satisfies SetAppleHealthPrimaryUserMessage
}

export const RequestAppleHealthSyncMessageSchema = z.object({
  type: z.literal('requestAppleHealthSync'),

  userId: z.string(),
  summaries: z.any(), // HealthRecordsMetricSummary[]
  metricTypes: z.any() // MetricType[]
}).extend(NativeMessageSchema.shape)

export type RequestAppleHealthSyncMessage = z.infer<typeof RequestAppleHealthSyncMessageSchema>

export const createRequestAppleHealthSyncMessage = (userId: string, summaries: HealthRecordsMetricSummary[], metricTypes: MetricType[]) => {
  return {
    ...createMessage(),
    type: 'requestAppleHealthSync',
    userId,
    summaries,
    metricTypes
  } satisfies RequestAppleHealthSyncMessage
}

export type RequestAppleHealthSyncResultMessage = NativeMessage & {
  type: 'requestAppleHealthSyncResult'
  data: HealthData[]
}
