/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprovePatientDocuments,
  BooleanResource,
  DocumentEditAction,
  DocumentFileInfo,
  DocumentStamp,
  DocumentTemplateSubmission,
  DocumentsHistoricalResult,
  ErrorResponse,
  LockedDocument,
  MultipleValuesPayload,
  PatientDocument,
  PatientDocumentAndTemplate,
  StringResource,
} from '../models';
import {
    ApprovePatientDocumentsFromJSON,
    ApprovePatientDocumentsToJSON,
    BooleanResourceFromJSON,
    BooleanResourceToJSON,
    DocumentEditActionFromJSON,
    DocumentEditActionToJSON,
    DocumentFileInfoFromJSON,
    DocumentFileInfoToJSON,
    DocumentStampFromJSON,
    DocumentStampToJSON,
    DocumentTemplateSubmissionFromJSON,
    DocumentTemplateSubmissionToJSON,
    DocumentsHistoricalResultFromJSON,
    DocumentsHistoricalResultToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    LockedDocumentFromJSON,
    LockedDocumentToJSON,
    MultipleValuesPayloadFromJSON,
    MultipleValuesPayloadToJSON,
    PatientDocumentFromJSON,
    PatientDocumentToJSON,
    PatientDocumentAndTemplateFromJSON,
    PatientDocumentAndTemplateToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
} from '../models';

export interface AddDocumentRequest {
    id: string;
    file: Blob;
    title: string;
    document_type: AddDocumentDocumentTypeEnum;
    approved?: string;
}

export interface AddDocumentFromHtmlRequest {
    patientId: string;
    document?: DocumentTemplateSubmission;
}

export interface AddUnassignedDocumentRequest {
    file: Blob;
}

export interface ApproveDocumentsRequest {
    approveObject: ApprovePatientDocuments;
}

export interface CombineDocumentsRequest {
    ids: MultipleValuesPayload;
}

export interface ConvertDocumentToPDFRequest {
    documentId: string;
}

export interface DecryptDocumentRequest {
    documentId: string;
    password?: StringResource;
}

export interface DeleteDocumentRequest {
    documentId: string;
}

export interface DownloadDocumentFromLinkRequest {
    id: string;
    password: string;
    filename: string;
}

export interface EditDocumentRequest {
    documentId: string;
    actions: Array<DocumentEditAction>;
}

export interface EditDocumentFromHtmlRequest {
    documentId: string;
    patientId: string;
    html: string;
    metadata: string;
}

export interface GetArchivedDocumentsRequest {
    limit?: number;
}

export interface GetClinicPendingDocumentsRequest {
    clinicId?: string;
}

export interface GetDocumentRequest {
    documentId: string;
}

export interface GetDocumentFileRequest {
    documentId: string;
}

export interface GetDocumentFileInfoRequest {
    documentId: string;
}

export interface GetDocumentFlagsRequest {
    documentId: string;
}

export interface GetDocumentHistoricalResultsRequest {
    documentId: string;
    convertToNumber?: boolean;
}

export interface GetDocumentPendingPatientFillRequest {
    documentId: string;
}

export interface GetDocumentThumbnailRequest {
    documentId: string;
}

export interface GetMultipleDocumentsRequest {
    ids: MultipleValuesPayload;
}

export interface GetPatientDocumentsRequest {
    id: string;
}

export interface GetPhysicianApprovedDocumentsRequest {
    hidden?: boolean;
    limit?: number;
}

export interface MarkDocumentPendingPatientFillRequest {
    patientId: string;
    documentId: string;
    pending?: boolean;
    fill_action?: MarkDocumentPendingPatientFillFillActionEnum;
}

export interface PatientFillDocumentRequest {
    documentId: string;
    documentTemplateSubmission?: DocumentTemplateSubmission;
}

export interface PayDocumentRequest {
    documentId: string;
    price?: StringResource;
}

export interface PreviewDocumentFromHtmlRequest {
    patientId: string;
    document?: DocumentTemplateSubmission;
}

export interface SetLockedDocumentRequest {
    id: string;
    data: BooleanResource;
}

export interface StampDocumentRequest {
    documentId: string;
    stamps: Array<DocumentStamp>;
}

export interface TranslateDocumentRequest {
    id: string;
    language_code?: string;
}

export interface UpdateDocumentRequest {
    document?: PatientDocument;
}

/**
 * 
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * Adds a document (Roles: admin, physician)
     */
    async addDocumentRaw(requestParameters: AddDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addDocument.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addDocument.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling addDocument.');
        }

        if (requestParameters.document_type === null || requestParameters.document_type === undefined) {
            throw new runtime.RequiredError('document_type','Required parameter requestParameters.document_type was null or undefined when calling addDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.approved !== undefined) {
            formParams.append('approved', requestParameters.approved as any);
        }

        if (requestParameters.document_type !== undefined) {
            formParams.append('document_type', requestParameters.document_type as any);
        }

        const response = await this.request({
            path: `/documents/patient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addDocumentRequestOptionsRaw(requestParameters: AddDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addDocument.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addDocument.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling addDocument.');
        }

        if (requestParameters.document_type === null || requestParameters.document_type === undefined) {
            throw new runtime.RequiredError('document_type','Required parameter requestParameters.document_type was null or undefined when calling addDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.approved !== undefined) {
            formParams.append('approved', requestParameters.approved as any);
        }

        if (requestParameters.document_type !== undefined) {
            formParams.append('document_type', requestParameters.document_type as any);
        }


        const responseConfig = {
            path: `/documents/patient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Adds a document (Roles: admin, physician)
     */
    async addDocument(requestParameters: AddDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.addDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a document from an HTML template (Roles: admin, physician)
     */
    async addDocumentFromHtmlRaw(requestParameters: AddDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addDocumentFromHtml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/html/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateSubmissionToJSON(requestParameters.document),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addDocumentFromHtmlRequestOptionsRaw(requestParameters: AddDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addDocumentFromHtml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/html/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateSubmissionToJSON(requestParameters.document),
        };
        return responseConfig
    }

    /**
     * Adds a document from an HTML template (Roles: admin, physician)
     */
    async addDocumentFromHtml(requestParameters: AddDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.addDocumentFromHtmlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an unassigned document (Roles: admin)
     */
    async addUnassignedDocumentRaw(requestParameters: AddUnassignedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addUnassignedDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/documents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addUnassignedDocumentRequestOptionsRaw(requestParameters: AddUnassignedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addUnassignedDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        const responseConfig = {
            path: `/documents`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Adds an unassigned document (Roles: admin)
     */
    async addUnassignedDocument(requestParameters: AddUnassignedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.addUnassignedDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve documents (Roles:physician)
     */
    async approveDocumentsRaw(requestParameters: ApproveDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        if (requestParameters.approveObject === null || requestParameters.approveObject === undefined) {
            throw new runtime.RequiredError('approveObject','Required parameter requestParameters.approveObject was null or undefined when calling approveDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprovePatientDocumentsToJSON(requestParameters.approveObject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    approveDocumentsRequestOptionsRaw(requestParameters: ApproveDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.approveObject === null || requestParameters.approveObject === undefined) {
            throw new runtime.RequiredError('approveObject','Required parameter requestParameters.approveObject was null or undefined when calling approveDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/approve`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ApprovePatientDocumentsToJSON(requestParameters.approveObject),
        };
        return responseConfig
    }

    /**
     * Approve documents (Roles:physician)
     */
    async approveDocuments(requestParameters: ApproveDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.approveDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Combines the PDFs of multiple documents (Roles: admin, physician)
     */
    async combineDocumentsRaw(requestParameters: CombineDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling combineDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/combine`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    combineDocumentsRequestOptionsRaw(requestParameters: CombineDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling combineDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/combine`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        };
        return responseConfig
    }

    /**
     * Combines the PDFs of multiple documents (Roles: admin, physician)
     */
    async combineDocuments(requestParameters: CombineDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.combineDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Converts a document to PDF (Roles: admin, physician)
     */
    async convertDocumentToPDFRaw(requestParameters: ConvertDocumentToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling convertDocumentToPDF.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/convert`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    convertDocumentToPDFRequestOptionsRaw(requestParameters: ConvertDocumentToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling convertDocumentToPDF.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/convert`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Converts a document to PDF (Roles: admin, physician)
     */
    async convertDocumentToPDF(requestParameters: ConvertDocumentToPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.convertDocumentToPDFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a document PDF\'s encryption (Roles: admin, physician)
     */
    async decryptDocumentRaw(requestParameters: DecryptDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling decryptDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/decrypt`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.password),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    decryptDocumentRequestOptionsRaw(requestParameters: DecryptDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling decryptDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/decrypt`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.password),
        };
        return responseConfig
    }

    /**
     * Removes a document PDF\'s encryption (Roles: admin, physician)
     */
    async decryptDocument(requestParameters: DecryptDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.decryptDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a document (Roles: physician, admin)
     */
    async deleteDocumentRaw(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteDocumentRequestOptionsRaw(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a document (Roles: physician, admin)
     */
    async deleteDocument(requestParameters: DeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Gets a document (Roles: patient)
     */
    async downloadDocumentFromLinkRaw(requestParameters: DownloadDocumentFromLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadDocumentFromLink.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling downloadDocumentFromLink.');
        }

        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling downloadDocumentFromLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/download/{id}/{password}/{filename}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"password"}}`, encodeURIComponent(String(requestParameters.password))).replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    downloadDocumentFromLinkRequestOptionsRaw(requestParameters: DownloadDocumentFromLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadDocumentFromLink.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling downloadDocumentFromLink.');
        }

        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling downloadDocumentFromLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/download/{id}/{password}/{filename}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"password"}}`, encodeURIComponent(String(requestParameters.password))).replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters.filename))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document (Roles: patient)
     */
    async downloadDocumentFromLink(requestParameters: DownloadDocumentFromLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.downloadDocumentFromLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits a document -- deletes pages and/or spins-off new documents (Roles: admin, physician)
     */
    async editDocumentRaw(requestParameters: EditDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling editDocument.');
        }

        if (requestParameters.actions === null || requestParameters.actions === undefined) {
            throw new runtime.RequiredError('actions','Required parameter requestParameters.actions was null or undefined when calling editDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.actions.map(DocumentEditActionToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    editDocumentRequestOptionsRaw(requestParameters: EditDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling editDocument.');
        }

        if (requestParameters.actions === null || requestParameters.actions === undefined) {
            throw new runtime.RequiredError('actions','Required parameter requestParameters.actions was null or undefined when calling editDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.actions.map(DocumentEditActionToJSON),
        };
        return responseConfig
    }

    /**
     * Edits a document -- deletes pages and/or spins-off new documents (Roles: admin, physician)
     */
    async editDocument(requestParameters: EditDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.editDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits a document from its template and metadata (Roles: admin, physician)
     */
    async editDocumentFromHtmlRaw(requestParameters: EditDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling editDocumentFromHtml.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling editDocumentFromHtml.');
        }

        if (requestParameters.html === null || requestParameters.html === undefined) {
            throw new runtime.RequiredError('html','Required parameter requestParameters.html was null or undefined when calling editDocumentFromHtml.');
        }

        if (requestParameters.metadata === null || requestParameters.metadata === undefined) {
            throw new runtime.RequiredError('metadata','Required parameter requestParameters.metadata was null or undefined when calling editDocumentFromHtml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.html !== undefined) {
            formParams.append('html', requestParameters.html as any);
        }

        if (requestParameters.metadata !== undefined) {
            formParams.append('metadata', requestParameters.metadata as any);
        }

        const response = await this.request({
            path: `/documents/{documentId}/html/{patientId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    editDocumentFromHtmlRequestOptionsRaw(requestParameters: EditDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling editDocumentFromHtml.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling editDocumentFromHtml.');
        }

        if (requestParameters.html === null || requestParameters.html === undefined) {
            throw new runtime.RequiredError('html','Required parameter requestParameters.html was null or undefined when calling editDocumentFromHtml.');
        }

        if (requestParameters.metadata === null || requestParameters.metadata === undefined) {
            throw new runtime.RequiredError('metadata','Required parameter requestParameters.metadata was null or undefined when calling editDocumentFromHtml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.html !== undefined) {
            formParams.append('html', requestParameters.html as any);
        }

        if (requestParameters.metadata !== undefined) {
            formParams.append('metadata', requestParameters.metadata as any);
        }


        const responseConfig = {
            path: `/documents/{documentId}/html/{patientId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Edits a document from its template and metadata (Roles: admin, physician)
     */
    async editDocumentFromHtml(requestParameters: EditDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.editDocumentFromHtmlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all archived documents (Roles: physician, admin)
     */
    async getArchivedDocumentsRaw(requestParameters: GetArchivedDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/archived`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getArchivedDocumentsRequestOptionsRaw(requestParameters: GetArchivedDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/archived`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all archived documents (Roles: physician, admin)
     */
    async getArchivedDocuments(requestParameters: GetArchivedDocumentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getArchivedDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all pending (non-approved) documents for the user\'s clinic (Roles: physician, admin)
     */
    async getClinicPendingDocumentsRaw(requestParameters: GetClinicPendingDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/pending/clinic`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getClinicPendingDocumentsRequestOptionsRaw(requestParameters: GetClinicPendingDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/pending/clinic`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all pending (non-approved) documents for the user\'s clinic (Roles: physician, admin)
     */
    async getClinicPendingDocuments(requestParameters: GetClinicPendingDocumentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getClinicPendingDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document (Roles: admin, physician, patient)
     */
    async getDocumentRaw(requestParameters: GetDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getDocumentRequestOptionsRaw(requestParameters: GetDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document (Roles: admin, physician, patient)
     */
    async getDocument(requestParameters: GetDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.getDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document (Roles: patient)
     */
    async getDocumentFileRaw(requestParameters: GetDocumentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/get-file`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getDocumentFileRequestOptionsRaw(requestParameters: GetDocumentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/get-file`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document (Roles: patient)
     */
    async getDocumentFile(requestParameters: GetDocumentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getDocumentFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document info (Roles: patient)
     */
    async getDocumentFileInfoRaw(requestParameters: GetDocumentFileInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentFileInfo>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentFileInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/get-file/info`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFileInfoFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getDocumentFileInfoRequestOptionsRaw(requestParameters: GetDocumentFileInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentFileInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/get-file/info`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document info (Roles: patient)
     */
    async getDocumentFileInfo(requestParameters: GetDocumentFileInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentFileInfo> {
        const response = await this.getDocumentFileInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document\'s flags
     */
    async getDocumentFlagsRaw(requestParameters: GetDocumentFlagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentFlags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/flags/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getDocumentFlagsRequestOptionsRaw(requestParameters: GetDocumentFlagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentFlags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/flags/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document\'s flags
     */
    async getDocumentFlags(requestParameters: GetDocumentFlagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.getDocumentFlagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document\'s historical results
     */
    async getDocumentHistoricalResultsRaw(requestParameters: GetDocumentHistoricalResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DocumentsHistoricalResult>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentHistoricalResults.');
        }

        const queryParameters: any = {};

        if (requestParameters.convertToNumber !== undefined) {
            queryParameters['convertToNumber'] = requestParameters.convertToNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/historical-results/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentsHistoricalResultFromJSON));
    }

    /**
        Request Config Getter
     */
    getDocumentHistoricalResultsRequestOptionsRaw(requestParameters: GetDocumentHistoricalResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentHistoricalResults.');
        }

        const queryParameters: any = {};

        if (requestParameters.convertToNumber !== undefined) {
            queryParameters['convertToNumber'] = requestParameters.convertToNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/historical-results/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document\'s historical results
     */
    async getDocumentHistoricalResults(requestParameters: GetDocumentHistoricalResultsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DocumentsHistoricalResult>> {
        const response = await this.getDocumentHistoricalResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document pending patient fill
     */
    async getDocumentPendingPatientFillRaw(requestParameters: GetDocumentPendingPatientFillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocumentAndTemplate>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentPendingPatientFill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/pending-patient-fill/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentAndTemplateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getDocumentPendingPatientFillRequestOptionsRaw(requestParameters: GetDocumentPendingPatientFillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentPendingPatientFill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/pending-patient-fill/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document pending patient fill
     */
    async getDocumentPendingPatientFill(requestParameters: GetDocumentPendingPatientFillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocumentAndTemplate> {
        const response = await this.getDocumentPendingPatientFillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a document thumbnail
     */
    async getDocumentThumbnailRaw(requestParameters: GetDocumentThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentThumbnail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/thumbnail/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getDocumentThumbnailRequestOptionsRaw(requestParameters: GetDocumentThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentThumbnail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/thumbnail/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a document thumbnail
     */
    async getDocumentThumbnail(requestParameters: GetDocumentThumbnailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getDocumentThumbnailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets multiple documents (Roles: admin, physician)
     */
    async getMultipleDocumentsRaw(requestParameters: GetMultipleDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getMultipleDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getMultipleDocumentsRequestOptionsRaw(requestParameters: GetMultipleDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getMultipleDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/multiple`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        };
        return responseConfig
    }

    /**
     * Gets multiple documents (Roles: admin, physician)
     */
    async getMultipleDocuments(requestParameters: GetMultipleDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getMultipleDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all patient documents (Roles: physician, admin, patient)
     */
    async getPatientDocumentsRaw(requestParameters: GetPatientDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPatientDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/patient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientDocumentsRequestOptionsRaw(requestParameters: GetPatientDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPatientDocuments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/patient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all patient documents (Roles: physician, admin, patient)
     */
    async getPatientDocuments(requestParameters: GetPatientDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getPatientDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all pending (non-approved) documents (Roles: physician, admin)
     */
    async getPendingDocumentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/pending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getPendingDocumentsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/pending`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all pending (non-approved) documents (Roles: physician, admin)
     */
    async getPendingDocuments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getPendingDocumentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all approved documents for the user (Roles: physician)
     */
    async getPhysicianApprovedDocumentsRaw(requestParameters: GetPhysicianApprovedDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        const queryParameters: any = {};

        if (requestParameters.hidden !== undefined) {
            queryParameters['hidden'] = requestParameters.hidden;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/approved/physician`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getPhysicianApprovedDocumentsRequestOptionsRaw(requestParameters: GetPhysicianApprovedDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.hidden !== undefined) {
            queryParameters['hidden'] = requestParameters.hidden;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/approved/physician`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all approved documents for the user (Roles: physician)
     */
    async getPhysicianApprovedDocuments(requestParameters: GetPhysicianApprovedDocumentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getPhysicianApprovedDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all pending (non-approved) documents for the user (Roles: physician)
     */
    async getPhysicianPendingDocumentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/pending/physician`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getPhysicianPendingDocumentsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/pending/physician`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all pending (non-approved) documents for the user (Roles: physician)
     */
    async getPhysicianPendingDocuments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getPhysicianPendingDocumentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Marks a patient document as needing to be filled
     */
    async markDocumentPendingPatientFillRaw(requestParameters: MarkDocumentPendingPatientFillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markDocumentPendingPatientFill.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling markDocumentPendingPatientFill.');
        }

        const queryParameters: any = {};

        if (requestParameters.pending !== undefined) {
            queryParameters['pending'] = requestParameters.pending;
        }

        if (requestParameters.fill_action !== undefined) {
            queryParameters['fill_action'] = requestParameters.fill_action;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/patient/{patientId}/pending/{documentId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    markDocumentPendingPatientFillRequestOptionsRaw(requestParameters: MarkDocumentPendingPatientFillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markDocumentPendingPatientFill.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling markDocumentPendingPatientFill.');
        }

        const queryParameters: any = {};

        if (requestParameters.pending !== undefined) {
            queryParameters['pending'] = requestParameters.pending;
        }

        if (requestParameters.fill_action !== undefined) {
            queryParameters['fill_action'] = requestParameters.fill_action;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/patient/{patientId}/pending/{documentId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Marks a patient document as needing to be filled
     */
    async markDocumentPendingPatientFill(requestParameters: MarkDocumentPendingPatientFillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.markDocumentPendingPatientFillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a document that was marked as pending fill
     */
    async patientFillDocumentRaw(requestParameters: PatientFillDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling patientFillDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/filled`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateSubmissionToJSON(requestParameters.documentTemplateSubmission),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    patientFillDocumentRequestOptionsRaw(requestParameters: PatientFillDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling patientFillDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/filled`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateSubmissionToJSON(requestParameters.documentTemplateSubmission),
        };
        return responseConfig
    }

    /**
     * Updates a document that was marked as pending fill
     */
    async patientFillDocument(requestParameters: PatientFillDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.patientFillDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Charges a patient based on the document\'s fee (Roles: admin, physician)
     */
    async payDocumentRaw(requestParameters: PayDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling payDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/pay`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.price),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    payDocumentRequestOptionsRaw(requestParameters: PayDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling payDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/pay`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.price),
        };
        return responseConfig
    }

    /**
     * Charges a patient based on the document\'s fee (Roles: admin, physician)
     */
    async payDocument(requestParameters: PayDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.payDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Previews a document from an HTML template (Roles: admin, physician)
     */
    async previewDocumentFromHtmlRaw(requestParameters: PreviewDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling previewDocumentFromHtml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/html/{patientId}/preview`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateSubmissionToJSON(requestParameters.document),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    previewDocumentFromHtmlRequestOptionsRaw(requestParameters: PreviewDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling previewDocumentFromHtml.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/html/{patientId}/preview`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: DocumentTemplateSubmissionToJSON(requestParameters.document),
        };
        return responseConfig
    }

    /**
     * Previews a document from an HTML template (Roles: admin, physician)
     */
    async previewDocumentFromHtml(requestParameters: PreviewDocumentFromHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewDocumentFromHtmlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets document\'s lock status (Roles: admin, physician)
     */
    async setLockedDocumentRaw(requestParameters: SetLockedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LockedDocument>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setLockedDocument.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setLockedDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/locked/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BooleanResourceToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LockedDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    setLockedDocumentRequestOptionsRaw(requestParameters: SetLockedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setLockedDocument.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setLockedDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/locked/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: BooleanResourceToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Sets document\'s lock status (Roles: admin, physician)
     */
    async setLockedDocument(requestParameters: SetLockedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LockedDocument> {
        const response = await this.setLockedDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Stamps an image to the patient document (Roles: physician)
     */
    async stampDocumentRaw(requestParameters: StampDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling stampDocument.');
        }

        if (requestParameters.stamps === null || requestParameters.stamps === undefined) {
            throw new runtime.RequiredError('stamps','Required parameter requestParameters.stamps was null or undefined when calling stampDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/{documentId}/stamp-image`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.stamps.map(DocumentStampToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    stampDocumentRequestOptionsRaw(requestParameters: StampDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling stampDocument.');
        }

        if (requestParameters.stamps === null || requestParameters.stamps === undefined) {
            throw new runtime.RequiredError('stamps','Required parameter requestParameters.stamps was null or undefined when calling stampDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/{documentId}/stamp-image`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.stamps.map(DocumentStampToJSON),
        };
        return responseConfig
    }

    /**
     * Stamps an image to the patient document (Roles: physician)
     */
    async stampDocument(requestParameters: StampDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.stampDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Translates a document (Roles: admin, physician)
     */
    async translateDocumentRaw(requestParameters: TranslateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling translateDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.language_code !== undefined) {
            queryParameters['language_code'] = requestParameters.language_code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents/translate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    translateDocumentRequestOptionsRaw(requestParameters: TranslateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling translateDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.language_code !== undefined) {
            queryParameters['language_code'] = requestParameters.language_code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents/translate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Translates a document (Roles: admin, physician)
     */
    async translateDocument(requestParameters: TranslateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.translateDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a document (Roles: admin, physician)
     */
    async updateDocumentRaw(requestParameters: UpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/documents`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PatientDocumentToJSON(requestParameters.document),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateDocumentRequestOptionsRaw(requestParameters: UpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/documents`,
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientDocumentToJSON(requestParameters.document),
        };
        return responseConfig
    }

    /**
     * Updates a document (Roles: admin, physician)
     */
    async updateDocument(requestParameters: UpdateDocumentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.updateDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AddDocumentDocumentTypeEnum = {
    Referral: 'Referral',
    DiagnosticResult: 'Diagnostic Result',
    DiagnosticRequisition: 'Diagnostic Requisition',
    Prescription: 'Prescription',
    Template: 'Template',
    Other: 'Other',
    ConsultNote: 'Consult Note',
    PharmacyAuthorizationRequest: 'Pharmacy Authorization Request',
    Demographics: 'Demographics',
    ImmunizationRecord: 'Immunization Record',
    AppointmentNotification: 'Appointment Notification',
    HistoricalRecords: 'Historical Records'
} as const;
export type AddDocumentDocumentTypeEnum = typeof AddDocumentDocumentTypeEnum[keyof typeof AddDocumentDocumentTypeEnum];
/**
 * @export
 */
export const MarkDocumentPendingPatientFillFillActionEnum = {
    Nothing: 'nothing',
    Task: 'task',
    Approve: 'approve'
} as const;
export type MarkDocumentPendingPatientFillFillActionEnum = typeof MarkDocumentPendingPatientFillFillActionEnum[keyof typeof MarkDocumentPendingPatientFillFillActionEnum];
