/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatChannelAttributes
 */
export interface ChatChannelAttributes {
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    channel_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    appointment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    requester_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    requester_role?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_recipient_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_recipient_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_recipient_role?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_recipient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_recipient_photo?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_greeting_message?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_waiting_message?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    patient_recipient_unavailable_message?: string;
    /**
     * 
     * @type {number}
     * @memberof ChatChannelAttributes
     */
    user_read?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    save_to?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    environment?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatChannelAttributes
     */
    clinic_id?: string;
}

/**
 * Check if a given object implements the ChatChannelAttributes interface.
 */
export function instanceOfChatChannelAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChatChannelAttributesFromJSON(json: any): ChatChannelAttributes {
    return ChatChannelAttributesFromJSONTyped(json, false);
}

export function ChatChannelAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatChannelAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'channel_type': !exists(json, 'channel_type') ? undefined : json['channel_type'],
        'appointment_id': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'requester_id': !exists(json, 'requester_id') ? undefined : json['requester_id'],
        'requester_role': !exists(json, 'requester_role') ? undefined : json['requester_role'],
        'patient_recipient_name': !exists(json, 'patient_recipient_name') ? undefined : json['patient_recipient_name'],
        'patient_recipient_type': !exists(json, 'patient_recipient_type') ? undefined : json['patient_recipient_type'],
        'patient_recipient_role': !exists(json, 'patient_recipient_role') ? undefined : json['patient_recipient_role'],
        'patient_recipient_id': !exists(json, 'patient_recipient_id') ? undefined : json['patient_recipient_id'],
        'patient_recipient_photo': !exists(json, 'patient_recipient_photo') ? undefined : json['patient_recipient_photo'],
        'patient_greeting_message': !exists(json, 'patient_greeting_message') ? undefined : json['patient_greeting_message'],
        'patient_waiting_message': !exists(json, 'patient_waiting_message') ? undefined : json['patient_waiting_message'],
        'patient_recipient_unavailable_message': !exists(json, 'patient_recipient_unavailable_message') ? undefined : json['patient_recipient_unavailable_message'],
        'user_read': !exists(json, 'user_read') ? undefined : json['user_read'],
        'save_to': !exists(json, 'save_to') ? undefined : json['save_to'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
    };
}

export function ChatChannelAttributesToJSON(value?: ChatChannelAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient_id': value.patient_id,
        'channel_type': value.channel_type,
        'appointment_id': value.appointment_id,
        'requester_id': value.requester_id,
        'requester_role': value.requester_role,
        'patient_recipient_name': value.patient_recipient_name,
        'patient_recipient_type': value.patient_recipient_type,
        'patient_recipient_role': value.patient_recipient_role,
        'patient_recipient_id': value.patient_recipient_id,
        'patient_recipient_photo': value.patient_recipient_photo,
        'patient_greeting_message': value.patient_greeting_message,
        'patient_waiting_message': value.patient_waiting_message,
        'patient_recipient_unavailable_message': value.patient_recipient_unavailable_message,
        'user_read': value.user_read,
        'save_to': value.save_to,
        'environment': value.environment,
        'clinic_id': value.clinic_id,
    };
}

