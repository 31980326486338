/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountInformation } from './AccountInformation';
import {
    AccountInformationFromJSON,
    AccountInformationFromJSONTyped,
    AccountInformationToJSON,
} from './AccountInformation';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { EmergencyContact } from './EmergencyContact';
import {
    EmergencyContactFromJSON,
    EmergencyContactFromJSONTyped,
    EmergencyContactToJSON,
} from './EmergencyContact';
import type { MTProfile } from './MTProfile';
import {
    MTProfileFromJSON,
    MTProfileFromJSONTyped,
    MTProfileToJSON,
} from './MTProfile';
import type { MedicationTrackerSettings } from './MedicationTrackerSettings';
import {
    MedicationTrackerSettingsFromJSON,
    MedicationTrackerSettingsFromJSONTyped,
    MedicationTrackerSettingsToJSON,
} from './MedicationTrackerSettings';
import type { MinistryOHIP } from './MinistryOHIP';
import {
    MinistryOHIPFromJSON,
    MinistryOHIPFromJSONTyped,
    MinistryOHIPToJSON,
} from './MinistryOHIP';
import type { NotificationSettings } from './NotificationSettings';
import {
    NotificationSettingsFromJSON,
    NotificationSettingsFromJSONTyped,
    NotificationSettingsToJSON,
} from './NotificationSettings';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';
import type { Pharmacy } from './Pharmacy';
import {
    PharmacyFromJSON,
    PharmacyFromJSONTyped,
    PharmacyToJSON,
} from './Pharmacy';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';
import type { TabOrderingSettings } from './TabOrderingSettings';
import {
    TabOrderingSettingsFromJSON,
    TabOrderingSettingsFromJSONTyped,
    TabOrderingSettingsToJSON,
} from './TabOrderingSettings';

/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    preferred_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    preferred_pronoun?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    phone2?: string;
    /**
     * 
     * @type {MinistryOHIP}
     * @memberof Patient
     */
    ministry_ohip?: MinistryOHIP;
    /**
     * 
     * @type {Address}
     * @memberof Patient
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    stripe_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    matomo_id?: string;
    /**
     * 
     * @type {Array<AccountInformation>}
     * @memberof Patient
     */
    linked_accounts?: Array<AccountInformation>;
    /**
     * 
     * @type {Array<AccountInformation>}
     * @memberof Patient
     */
    linked_to_accounts?: Array<AccountInformation>;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    primary_physician?: string;
    /**
     * 
     * @type {NotificationSettings}
     * @memberof Patient
     */
    notification_settings: NotificationSettings;
    /**
     * 
     * @type {EmergencyContact}
     * @memberof Patient
     */
    emergency_contact?: EmergencyContact;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    tutorial_completed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    mobile_app_dismissed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    date_registered?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    email_verified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    inactive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    alert?: string;
    /**
     * 
     * @type {Pharmacy}
     * @memberof Patient
     */
    default_pharmacy?: Pharmacy;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    disable_home_appointments?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    track_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    corporation_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    date_deceased?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    subscriber?: boolean;
    /**
     * 
     * @type {Subscription}
     * @memberof Patient
     */
    subscription?: Subscription;
    /**
     * 
     * @type {MTProfile}
     * @memberof Patient
     */
    menstruation_profile?: MTProfile;
    /**
     * 
     * @type {Array<string>}
     * @memberof Patient
     */
    widgets?: Array<PatientWidgetsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Patient
     */
    metrics_hidden?: Array<string>;
    /**
     * 
     * @type {Array<PatientDocument>}
     * @memberof Patient
     */
    pending_documents?: Array<PatientDocument>;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    has_selected_primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    timezone?: string;
    /**
     * 
     * @type {MedicationTrackerSettings}
     * @memberof Patient
     */
    medication_tracker_settings?: MedicationTrackerSettings;
    /**
     * 
     * @type {TabOrderingSettings}
     * @memberof Patient
     */
    tab_ordering?: TabOrderingSettings;
    /**
     * 
     * @type {boolean}
     * @memberof Patient
     */
    is_subscribed?: boolean;
}


/**
 * @export
 */
export const PatientWidgetsEnum = {
    MenstruationTracker: 'menstruation_tracker'
} as const;
export type PatientWidgetsEnum = typeof PatientWidgetsEnum[keyof typeof PatientWidgetsEnum];


/**
 * Check if a given object implements the Patient interface.
 */
export function instanceOfPatient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "notification_settings" in value;

    return isInstance;
}

export function PatientFromJSON(json: any): Patient {
    return PatientFromJSONTyped(json, false);
}

export function PatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Patient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'preferred_name': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'preferred_pronoun': !exists(json, 'preferred_pronoun') ? undefined : json['preferred_pronoun'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'phone2': !exists(json, 'phone2') ? undefined : json['phone2'],
        'ministry_ohip': !exists(json, 'ministry_ohip') ? undefined : MinistryOHIPFromJSON(json['ministry_ohip']),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'stripe_id': !exists(json, 'stripe_id') ? undefined : json['stripe_id'],
        'matomo_id': !exists(json, 'matomo_id') ? undefined : json['matomo_id'],
        'linked_accounts': !exists(json, 'linked_accounts') ? undefined : ((json['linked_accounts'] as Array<any>).map(AccountInformationFromJSON)),
        'linked_to_accounts': !exists(json, 'linked_to_accounts') ? undefined : ((json['linked_to_accounts'] as Array<any>).map(AccountInformationFromJSON)),
        'primary_physician': !exists(json, 'primary_physician') ? undefined : json['primary_physician'],
        'notification_settings': NotificationSettingsFromJSON(json['notification_settings']),
        'emergency_contact': !exists(json, 'emergency_contact') ? undefined : EmergencyContactFromJSON(json['emergency_contact']),
        'tutorial_completed': !exists(json, 'tutorial_completed') ? undefined : json['tutorial_completed'],
        'mobile_app_dismissed': !exists(json, 'mobile_app_dismissed') ? undefined : json['mobile_app_dismissed'],
        'date_registered': !exists(json, 'date_registered') ? undefined : json['date_registered'],
        'email_verified': !exists(json, 'email_verified') ? undefined : json['email_verified'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'alert': !exists(json, 'alert') ? undefined : json['alert'],
        'default_pharmacy': !exists(json, 'default_pharmacy') ? undefined : PharmacyFromJSON(json['default_pharmacy']),
        'disable_home_appointments': !exists(json, 'disable_home_appointments') ? undefined : json['disable_home_appointments'],
        'track_id': !exists(json, 'track_id') ? undefined : json['track_id'],
        'corporation_id': !exists(json, 'corporation_id') ? undefined : json['corporation_id'],
        'date_deceased': !exists(json, 'date_deceased') ? undefined : json['date_deceased'],
        'subscriber': !exists(json, 'subscriber') ? undefined : json['subscriber'],
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionFromJSON(json['subscription']),
        'menstruation_profile': !exists(json, 'menstruation_profile') ? undefined : MTProfileFromJSON(json['menstruation_profile']),
        'widgets': !exists(json, 'widgets') ? undefined : json['widgets'],
        'metrics_hidden': !exists(json, 'metrics_hidden') ? undefined : json['metrics_hidden'],
        'pending_documents': !exists(json, 'pending_documents') ? undefined : ((json['pending_documents'] as Array<any>).map(PatientDocumentFromJSON)),
        'has_selected_primary': !exists(json, 'has_selected_primary') ? undefined : json['has_selected_primary'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'medication_tracker_settings': !exists(json, 'medication_tracker_settings') ? undefined : MedicationTrackerSettingsFromJSON(json['medication_tracker_settings']),
        'tab_ordering': !exists(json, 'tab_ordering') ? undefined : TabOrderingSettingsFromJSON(json['tab_ordering']),
        'is_subscribed': !exists(json, 'is_subscribed') ? undefined : json['is_subscribed'],
    };
}

export function PatientToJSON(value?: Patient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'preferred_name': value.preferred_name,
        'preferred_pronoun': value.preferred_pronoun,
        'phone': value.phone,
        'phone2': value.phone2,
        'ministry_ohip': MinistryOHIPToJSON(value.ministry_ohip),
        'address': AddressToJSON(value.address),
        'photo': value.photo,
        'stripe_id': value.stripe_id,
        'matomo_id': value.matomo_id,
        'linked_accounts': value.linked_accounts === undefined ? undefined : ((value.linked_accounts as Array<any>).map(AccountInformationToJSON)),
        'linked_to_accounts': value.linked_to_accounts === undefined ? undefined : ((value.linked_to_accounts as Array<any>).map(AccountInformationToJSON)),
        'primary_physician': value.primary_physician,
        'notification_settings': NotificationSettingsToJSON(value.notification_settings),
        'emergency_contact': EmergencyContactToJSON(value.emergency_contact),
        'tutorial_completed': value.tutorial_completed,
        'mobile_app_dismissed': value.mobile_app_dismissed,
        'date_registered': value.date_registered,
        'email_verified': value.email_verified,
        'inactive': value.inactive,
        'clinic_id': value.clinic_id,
        'alert': value.alert,
        'default_pharmacy': PharmacyToJSON(value.default_pharmacy),
        'disable_home_appointments': value.disable_home_appointments,
        'track_id': value.track_id,
        'corporation_id': value.corporation_id,
        'date_deceased': value.date_deceased,
        'subscriber': value.subscriber,
        'subscription': SubscriptionToJSON(value.subscription),
        'menstruation_profile': MTProfileToJSON(value.menstruation_profile),
        'widgets': value.widgets,
        'metrics_hidden': value.metrics_hidden,
        'pending_documents': value.pending_documents === undefined ? undefined : ((value.pending_documents as Array<any>).map(PatientDocumentToJSON)),
        'has_selected_primary': value.has_selected_primary,
        'timezone': value.timezone,
        'medication_tracker_settings': MedicationTrackerSettingsToJSON(value.medication_tracker_settings),
        'tab_ordering': TabOrderingSettingsToJSON(value.tab_ordering),
        'is_subscribed': value.is_subscribed,
    };
}

