/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetricValue
 */
export interface MetricValue {
    /**
     * 
     * @type {number}
     * @memberof MetricValue
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    value1: string;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    value2?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    metric_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    note_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    result_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricValue
     */
    type?: string;
}

/**
 * Check if a given object implements the MetricValue interface.
 */
export function instanceOfMetricValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "value1" in value;

    return isInstance;
}

export function MetricValueFromJSON(json: any): MetricValue {
    return MetricValueFromJSONTyped(json, false);
}

export function MetricValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'value1': json['value1'],
        'value2': !exists(json, 'value2') ? undefined : json['value2'],
        'metric_type': !exists(json, 'metric_type') ? undefined : json['metric_type'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'note_id': !exists(json, 'note_id') ? undefined : json['note_id'],
        'result_id': !exists(json, 'result_id') ? undefined : json['result_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MetricValueToJSON(value?: MetricValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'value1': value.value1,
        'value2': value.value2,
        'metric_type': value.metric_type,
        'origin': value.origin,
        'note_id': value.note_id,
        'result_id': value.result_id,
        'type': value.type,
    };
}

