import { Pharmacy } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrap, unwrapApi } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface PharmaciesState {
  pharmacies: Pharmacy[]
  searchTerm?: string
}

const initialState: PharmaciesState = {
  pharmacies: []
}

const slice = createResettableSlice({
  name: 'Pharmacies',
  initialState,
  reducers: {
  },
  extraReducers: builder => builder
    .addCase(api.Pharmacies.getPharmacies.fulfilled, unwrapApi((state, pharmacies, req) => {
      state.pharmacies = pharmacies
      state.searchTerm = req.name
    }))
    .addCase(api.Pharmacies.addPharmacy.fulfilled, unwrap((state, pharmacy) => {
      PerformanceUtils.upsert(pharmacy, state.pharmacies)
    }))
})

export const pharmacyActions = slice.actions
export default slice.reducer
