/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmergencyContact
 */
export interface EmergencyContact {
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    relation?: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    contact?: string;
}

/**
 * Check if a given object implements the EmergencyContact interface.
 */
export function instanceOfEmergencyContact(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmergencyContactFromJSON(json: any): EmergencyContact {
    return EmergencyContactFromJSONTyped(json, false);
}

export function EmergencyContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmergencyContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'relation': !exists(json, 'relation') ? undefined : json['relation'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
    };
}

export function EmergencyContactToJSON(value?: EmergencyContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'relation': value.relation,
        'contact': value.contact,
    };
}

