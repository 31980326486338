/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClinicPaymentPlan,
  CreditCard,
  ErrorResponse,
  Invoice,
  PaymentCharge,
  PaymentPlan,
  StringResource,
  StripeEvent,
  Subscription,
  SubscriptionAndPatient,
  Token,
  UnsubscribePayload,
} from '../models';
import {
    ClinicPaymentPlanFromJSON,
    ClinicPaymentPlanToJSON,
    CreditCardFromJSON,
    CreditCardToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    InvoiceFromJSON,
    InvoiceToJSON,
    PaymentChargeFromJSON,
    PaymentChargeToJSON,
    PaymentPlanFromJSON,
    PaymentPlanToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
    StripeEventFromJSON,
    StripeEventToJSON,
    SubscriptionFromJSON,
    SubscriptionToJSON,
    SubscriptionAndPatientFromJSON,
    SubscriptionAndPatientToJSON,
    TokenFromJSON,
    TokenToJSON,
    UnsubscribePayloadFromJSON,
    UnsubscribePayloadToJSON,
} from '../models';

export interface AddCreditCardRequest {
    patientId: string;
    card: Token;
}

export interface AddDefaultPermissionRequest {
    name: string;
}

export interface ApplyDiscountPromoCodeRequest {
    patientId: string;
}

export interface ApplyPromoCodeRequest {
    patientId: string;
    promoCode: string;
}

export interface ChargePatientRequest {
    patientId: string;
    charge: PaymentCharge;
}

export interface CreateSubscriptionRequest {
    paymentPlanId: string;
    patientId: string;
    signatureData?: StringResource;
}

export interface DeleteCreditCardRequest {
    patientId: string;
    cardId: string;
}

export interface EditPaymentPlansRequest {
    plans: Array<ClinicPaymentPlan>;
}

export interface GetCreditCardsRequest {
    patientId: string;
}

export interface GetPaymentPlansRequest {
    patientId: string;
}

export interface GetUserSubscriptionRequest {
    userId: string;
}

export interface MakeCreditCardDefaultRequest {
    patientId: string;
    cardId: string;
}

export interface ReceiveStripeEventRequest {
    event: StripeEvent;
}

export interface RemoveDefaultPermissionRequest {
    name: string;
}

export interface RetryPaymentRequest {
    patientId: string;
}

export interface SendPromoCodeEmailRequest {
    patientId: string;
}

export interface UnsubscribeRequest {
    userId: string;
}

export interface UnsubscribePatientRequest {
    userId: string;
    data: UnsubscribePayload;
}

export interface VerifyCreditCardRequest {
    card: Token;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Adds a credit card to a patient\'s profile (Roles: patient, admin)
     */
    async addCreditCardRaw(requestParameters: AddCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CreditCard>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addCreditCard.');
        }

        if (requestParameters.card === null || requestParameters.card === undefined) {
            throw new runtime.RequiredError('card','Required parameter requestParameters.card was null or undefined when calling addCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/credit-card/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenToJSON(requestParameters.card),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditCardFromJSON));
    }

    /**
        Request Config Getter
     */
    addCreditCardRequestOptionsRaw(requestParameters: AddCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addCreditCard.');
        }

        if (requestParameters.card === null || requestParameters.card === undefined) {
            throw new runtime.RequiredError('card','Required parameter requestParameters.card was null or undefined when calling addCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/credit-card/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: TokenToJSON(requestParameters.card),
        };
        return responseConfig
    }

    /**
     * Adds a credit card to a patient\'s profile (Roles: patient, admin)
     */
    async addCreditCard(requestParameters: AddCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CreditCard>> {
        const response = await this.addCreditCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a default permission for payment plans (Roles: admin)
     */
    async addDefaultPermissionRaw(requestParameters: AddDefaultPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClinicPaymentPlan>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling addDefaultPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/payment-plans/permissions/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicPaymentPlanFromJSON));
    }

    /**
        Request Config Getter
     */
    addDefaultPermissionRequestOptionsRaw(requestParameters: AddDefaultPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling addDefaultPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/payment-plans/permissions/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Adds a default permission for payment plans (Roles: admin)
     */
    async addDefaultPermission(requestParameters: AddDefaultPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClinicPaymentPlan>> {
        const response = await this.addDefaultPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Applies the discount promo code (Roles: admin)
     */
    async applyDiscountPromoCodeRaw(requestParameters: ApplyDiscountPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling applyDiscountPromoCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/promo-codes/{patientId}/apply-discout`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    applyDiscountPromoCodeRequestOptionsRaw(requestParameters: ApplyDiscountPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling applyDiscountPromoCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/promo-codes/{patientId}/apply-discout`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Applies the discount promo code (Roles: admin)
     */
    async applyDiscountPromoCode(requestParameters: ApplyDiscountPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applyDiscountPromoCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Applies a promo code (Roles: patient, admin)
     */
    async applyPromoCodeRaw(requestParameters: ApplyPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentPlan>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling applyPromoCode.');
        }

        if (requestParameters.promoCode === null || requestParameters.promoCode === undefined) {
            throw new runtime.RequiredError('promoCode','Required parameter requestParameters.promoCode was null or undefined when calling applyPromoCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/promo-codes/{patientId}/{promoCode}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"promoCode"}}`, encodeURIComponent(String(requestParameters.promoCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentPlanFromJSON));
    }

    /**
        Request Config Getter
     */
    applyPromoCodeRequestOptionsRaw(requestParameters: ApplyPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling applyPromoCode.');
        }

        if (requestParameters.promoCode === null || requestParameters.promoCode === undefined) {
            throw new runtime.RequiredError('promoCode','Required parameter requestParameters.promoCode was null or undefined when calling applyPromoCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/promo-codes/{patientId}/{promoCode}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"promoCode"}}`, encodeURIComponent(String(requestParameters.promoCode))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Applies a promo code (Roles: patient, admin)
     */
    async applyPromoCode(requestParameters: ApplyPromoCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentPlan>> {
        const response = await this.applyPromoCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Charges a patient (Roles: admin)
     */
    async chargePatientRaw(requestParameters: ChargePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling chargePatient.');
        }

        if (requestParameters.charge === null || requestParameters.charge === undefined) {
            throw new runtime.RequiredError('charge','Required parameter requestParameters.charge was null or undefined when calling chargePatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patient/{patientId}/charge-patient`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentChargeToJSON(requestParameters.charge),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    chargePatientRequestOptionsRaw(requestParameters: ChargePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling chargePatient.');
        }

        if (requestParameters.charge === null || requestParameters.charge === undefined) {
            throw new runtime.RequiredError('charge','Required parameter requestParameters.charge was null or undefined when calling chargePatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patient/{patientId}/charge-patient`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PaymentChargeToJSON(requestParameters.charge),
        };
        return responseConfig
    }

    /**
     * Charges a patient (Roles: admin)
     */
    async chargePatient(requestParameters: ChargePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invoice> {
        const response = await this.chargePatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Subscribes a patient and returns a SubscriptionAndPatient (Roles: patient)
     */
    async createSubscriptionRaw(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionAndPatient>> {
        if (requestParameters.paymentPlanId === null || requestParameters.paymentPlanId === undefined) {
            throw new runtime.RequiredError('paymentPlanId','Required parameter requestParameters.paymentPlanId was null or undefined when calling createSubscription.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling createSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/{paymentPlanId}/create-subscription/{patientId}`.replace(`{${"paymentPlanId"}}`, encodeURIComponent(String(requestParameters.paymentPlanId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.signatureData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionAndPatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    createSubscriptionRequestOptionsRaw(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.paymentPlanId === null || requestParameters.paymentPlanId === undefined) {
            throw new runtime.RequiredError('paymentPlanId','Required parameter requestParameters.paymentPlanId was null or undefined when calling createSubscription.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling createSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/{paymentPlanId}/create-subscription/{patientId}`.replace(`{${"paymentPlanId"}}`, encodeURIComponent(String(requestParameters.paymentPlanId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.signatureData),
        };
        return responseConfig
    }

    /**
     * Subscribes a patient and returns a SubscriptionAndPatient (Roles: patient)
     */
    async createSubscription(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionAndPatient> {
        const response = await this.createSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a credit card (Roles: patient, admin)
     */
    async deleteCreditCardRaw(requestParameters: DeleteCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteCreditCard.');
        }

        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling deleteCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/credit-card/{cardId}/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteCreditCardRequestOptionsRaw(requestParameters: DeleteCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteCreditCard.');
        }

        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling deleteCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/credit-card/{cardId}/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a credit card (Roles: patient, admin)
     */
    async deleteCreditCard(requestParameters: DeleteCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCreditCardRaw(requestParameters, initOverrides);
    }

    /**
     * Edits a set of Care& Payment Plans (Roles: admin)
     */
    async editPaymentPlansRaw(requestParameters: EditPaymentPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClinicPaymentPlan>>> {
        if (requestParameters.plans === null || requestParameters.plans === undefined) {
            throw new runtime.RequiredError('plans','Required parameter requestParameters.plans was null or undefined when calling editPaymentPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/payment-plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.plans.map(ClinicPaymentPlanToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicPaymentPlanFromJSON));
    }

    /**
        Request Config Getter
     */
    editPaymentPlansRequestOptionsRaw(requestParameters: EditPaymentPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.plans === null || requestParameters.plans === undefined) {
            throw new runtime.RequiredError('plans','Required parameter requestParameters.plans was null or undefined when calling editPaymentPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/payment-plans`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.plans.map(ClinicPaymentPlanToJSON),
        };
        return responseConfig
    }

    /**
     * Edits a set of Care& Payment Plans (Roles: admin)
     */
    async editPaymentPlans(requestParameters: EditPaymentPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClinicPaymentPlan>> {
        const response = await this.editPaymentPlansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all available payment plans (Roles: admin)
     */
    async getAllPaymentPlansRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClinicPaymentPlan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/payment-plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicPaymentPlanFromJSON));
    }

    /**
        Request Config Getter
     */
    getAllPaymentPlansRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/payment-plans`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all available payment plans (Roles: admin)
     */
    async getAllPaymentPlans(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClinicPaymentPlan>> {
        const response = await this.getAllPaymentPlansRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all credit cards from a patient\'s profile (Roles: admin)
     */
    async getCreditCardsRaw(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CreditCard>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getCreditCards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/credit-card/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditCardFromJSON));
    }

    /**
        Request Config Getter
     */
    getCreditCardsRequestOptionsRaw(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getCreditCards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/credit-card/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all credit cards from a patient\'s profile (Roles: admin)
     */
    async getCreditCards(requestParameters: GetCreditCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CreditCard>> {
        const response = await this.getCreditCardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets overdue subscriptions (Roles: admin)
     */
    async getOverdueSubscriptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Subscription>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/overdue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionFromJSON));
    }

    /**
        Request Config Getter
     */
    getOverdueSubscriptionsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/overdue`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets overdue subscriptions (Roles: admin)
     */
    async getOverdueSubscriptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Subscription>> {
        const response = await this.getOverdueSubscriptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all available payment plans (Roles: patient, admin)
     */
    async getPaymentPlansRaw(requestParameters: GetPaymentPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PaymentPlan>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPaymentPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/payment-plans/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentPlanFromJSON));
    }

    /**
        Request Config Getter
     */
    getPaymentPlansRequestOptionsRaw(requestParameters: GetPaymentPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPaymentPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/payment-plans/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all available payment plans (Roles: patient, admin)
     */
    async getPaymentPlans(requestParameters: GetPaymentPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PaymentPlan>> {
        const response = await this.getPaymentPlansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a user\'s subscription (Roles: patient, physician, admin)
     */
    async getUserSubscriptionRaw(requestParameters: GetUserSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/subscriptions/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getUserSubscriptionRequestOptionsRaw(requestParameters: GetUserSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/subscriptions/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a user\'s subscription (Roles: patient, physician, admin)
     */
    async getUserSubscription(requestParameters: GetUserSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.getUserSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Makes a credit card the default one (Roles: patient)
     */
    async makeCreditCardDefaultRaw(requestParameters: MakeCreditCardDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CreditCard>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling makeCreditCardDefault.');
        }

        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling makeCreditCardDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/credit-card/{cardId}/patient/{patientId}/make-default`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditCardFromJSON));
    }

    /**
        Request Config Getter
     */
    makeCreditCardDefaultRequestOptionsRaw(requestParameters: MakeCreditCardDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling makeCreditCardDefault.');
        }

        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling makeCreditCardDefault.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/credit-card/{cardId}/patient/{patientId}/make-default`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Makes a credit card the default one (Roles: patient)
     */
    async makeCreditCardDefault(requestParameters: MakeCreditCardDefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CreditCard>> {
        const response = await this.makeCreditCardDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Receives a stripe event (Roles: none)
     */
    async receiveStripeEventRaw(requestParameters: ReceiveStripeEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling receiveStripeEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/stripe-event`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StripeEventToJSON(requestParameters.event),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    receiveStripeEventRequestOptionsRaw(requestParameters: ReceiveStripeEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling receiveStripeEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/stripe-event`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StripeEventToJSON(requestParameters.event),
        };
        return responseConfig
    }

    /**
     * Receives a stripe event (Roles: none)
     */
    async receiveStripeEvent(requestParameters: ReceiveStripeEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.receiveStripeEventRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a default permission for payment plans (Roles: admin)
     */
    async removeDefaultPermissionRaw(requestParameters: RemoveDefaultPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClinicPaymentPlan>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling removeDefaultPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/payment-plans/permissions/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicPaymentPlanFromJSON));
    }

    /**
        Request Config Getter
     */
    removeDefaultPermissionRequestOptionsRaw(requestParameters: RemoveDefaultPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling removeDefaultPermission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/payment-plans/permissions/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a default permission for payment plans (Roles: admin)
     */
    async removeDefaultPermission(requestParameters: RemoveDefaultPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClinicPaymentPlan>> {
        const response = await this.removeDefaultPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retries a failed payment (Roles: patient)
     */
    async retryPaymentRaw(requestParameters: RetryPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling retryPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/retry-payment/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    retryPaymentRequestOptionsRaw(requestParameters: RetryPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling retryPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/retry-payment/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Retries a failed payment (Roles: patient)
     */
    async retryPayment(requestParameters: RetryPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.retryPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends a promo code email (Roles: admin)
     */
    async sendPromoCodeEmailRaw(requestParameters: SendPromoCodeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling sendPromoCodeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/promo-codes/{patientId}/send-email`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendPromoCodeEmailRequestOptionsRaw(requestParameters: SendPromoCodeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling sendPromoCodeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/promo-codes/{patientId}/send-email`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Sends a promo code email (Roles: admin)
     */
    async sendPromoCodeEmail(requestParameters: SendPromoCodeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendPromoCodeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Unsubscribes a patient (Roles: patient, admin)
     */
    async unsubscribeRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling unsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/subscriptions/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    unsubscribeRequestOptionsRaw(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling unsubscribe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/subscriptions/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Unsubscribes a patient (Roles: patient, admin)
     */
    async unsubscribe(requestParameters: UnsubscribeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.unsubscribeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unsubscribes a patient with a reason (Roles: patient)
     */
    async unsubscribePatientRaw(requestParameters: UnsubscribePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling unsubscribePatient.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling unsubscribePatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscriptions/subscriptions/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnsubscribePayloadToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    unsubscribePatientRequestOptionsRaw(requestParameters: UnsubscribePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling unsubscribePatient.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling unsubscribePatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/subscriptions/subscriptions/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: UnsubscribePayloadToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Unsubscribes a patient with a reason (Roles: patient)
     */
    async unsubscribePatient(requestParameters: UnsubscribePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.unsubscribePatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verifies a credit card (Roles: admin, patient)
     */
    async verifyCreditCardRaw(requestParameters: VerifyCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.card === null || requestParameters.card === undefined) {
            throw new runtime.RequiredError('card','Required parameter requestParameters.card was null or undefined when calling verifyCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/credit-card/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenToJSON(requestParameters.card),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    verifyCreditCardRequestOptionsRaw(requestParameters: VerifyCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.card === null || requestParameters.card === undefined) {
            throw new runtime.RequiredError('card','Required parameter requestParameters.card was null or undefined when calling verifyCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/credit-card/verify`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: TokenToJSON(requestParameters.card),
        };
        return responseConfig
    }

    /**
     * Verifies a credit card (Roles: admin, patient)
     */
    async verifyCreditCard(requestParameters: VerifyCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyCreditCardRaw(requestParameters, initOverrides);
    }

}
