/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImmunizationValue
 */
export interface ImmunizationValue {
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    prescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    dosage?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    lot?: string;
    /**
     * 
     * @type {number}
     * @memberof ImmunizationValue
     */
    expiry_date?: number;
    /**
     * 
     * @type {number}
     * @memberof ImmunizationValue
     */
    given_date?: number;
    /**
     * 
     * @type {number}
     * @memberof ImmunizationValue
     */
    scheduled_date?: number;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    given_by?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    din?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    site?: string;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    generate_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImmunizationValue
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ImmunizationValue
     */
    transcribed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImmunizationValue
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImmunizationValue
     */
    paid?: boolean;
}

/**
 * Check if a given object implements the ImmunizationValue interface.
 */
export function instanceOfImmunizationValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ImmunizationValueFromJSON(json: any): ImmunizationValue {
    return ImmunizationValueFromJSONTyped(json, false);
}

export function ImmunizationValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImmunizationValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'prescription': !exists(json, 'prescription') ? undefined : json['prescription'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'dosage': !exists(json, 'dosage') ? undefined : json['dosage'],
        'lot': !exists(json, 'lot') ? undefined : json['lot'],
        'expiry_date': !exists(json, 'expiry_date') ? undefined : json['expiry_date'],
        'given_date': !exists(json, 'given_date') ? undefined : json['given_date'],
        'scheduled_date': !exists(json, 'scheduled_date') ? undefined : json['scheduled_date'],
        'given_by': !exists(json, 'given_by') ? undefined : json['given_by'],
        'din': !exists(json, 'din') ? undefined : json['din'],
        'site': !exists(json, 'site') ? undefined : json['site'],
        'generate_id': !exists(json, 'generate_id') ? undefined : json['generate_id'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'transcribed': !exists(json, 'transcribed') ? undefined : json['transcribed'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
    };
}

export function ImmunizationValueToJSON(value?: ImmunizationValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'prescription': value.prescription,
        'description': value.description,
        'route': value.route,
        'dosage': value.dosage,
        'lot': value.lot,
        'expiry_date': value.expiry_date,
        'given_date': value.given_date,
        'scheduled_date': value.scheduled_date,
        'given_by': value.given_by,
        'din': value.din,
        'site': value.site,
        'generate_id': value.generate_id,
        'attachments': value.attachments,
        'transcribed': value.transcribed,
        'notes': value.notes,
        'paid': value.paid,
    };
}

