import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppleHealthUpdateMessage } from 'services/Native/messages/AppleHealth';
import { DevicePermission } from 'services/Native/messages/RequestPermission';
import type { BiometricType, SafeArea } from 'services/Native/messages/Setup';
import { SafeStoredUser } from 'services/Native/messages/StoredUsers';

export interface DeviceState {
  safeArea: SafeArea;
  appleHealth: {
    hasAppleHealth: boolean;
    primaryUser?: string;
    permissions: string[];
  };
  permissions: Partial<Record<DevicePermission, boolean | null>>;
  dismissedWidgets: string[];
  useBiometrics: boolean;
  biometricsAvailable?: BiometricType;
  switchAccountConfirmation?: string;
  storedUsers: SafeStoredUser[];
  clientVersion?: string;
}

const initialState: DeviceState = {
  safeArea: { top: 0, left: 0, right: 0, bottom: 0 },
  permissions: {},
  appleHealth: {
    hasAppleHealth: false,
    permissions: [],
  },
  dismissedWidgets: [],
  useBiometrics: false,
  biometricsAvailable: undefined,
  storedUsers: [],
};

// NOTICE: this slice is not resettable
// we want to save device data across login/logout
const slice = createSlice({
  name: 'Device',
  initialState,
  reducers: {
    dismissWidget: (state, action: PayloadAction<string>) => {
      state.dismissedWidgets.push(action.payload);
    },
    safeAreaChanged: (state, action: PayloadAction<Partial<SafeArea>>) => {
      _.assign(state.safeArea, action.payload);
    },
    permissionsUpdated: (state, action: PayloadAction<DeviceState['permissions']>) => {
      Object.keys(action.payload).forEach((key) => {
        state.permissions[key as DevicePermission] = action.payload[key as DevicePermission];
      });
    },
    appleHealthUpdateReceived: (state, action: PayloadAction<AppleHealthUpdateMessage>) => {
      const message = action.payload;
      state.appleHealth.permissions = message.permissions;
      state.appleHealth.hasAppleHealth = message.hasAppleHealth;
      state.appleHealth.primaryUser = message.primaryAppleHealthUser;
    },
    biometricsChanged: (state, action: PayloadAction<boolean>) => {
      state.useBiometrics = action.payload;
    },
    biometricsAvailabilityChanged: (state, action: PayloadAction<BiometricType | undefined>) => {
      state.biometricsAvailable = action.payload;
    },
    switchAccountConfirmationRequested: (state, action: PayloadAction<string | undefined>) => {
      state.switchAccountConfirmation = action.payload;
    },
    storedUsersChanged: (state, action: PayloadAction<SafeStoredUser[]>) => {
      state.storedUsers = action.payload;
    },
    clientVersionChanged: (state, action: PayloadAction<string>) => {
      state.clientVersion = action.payload;
    },
  },
  extraReducers: (builder) => builder,
});

export const deviceActions = slice.actions;
export default slice.reducer;
