/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentNoteStamp
 */
export interface AppointmentNoteStamp {
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteStamp
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteStamp
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteStamp
     */
    content: string;
}

/**
 * Check if a given object implements the AppointmentNoteStamp interface.
 */
export function instanceOfAppointmentNoteStamp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function AppointmentNoteStampFromJSON(json: any): AppointmentNoteStamp {
    return AppointmentNoteStampFromJSONTyped(json, false);
}

export function AppointmentNoteStampFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentNoteStamp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'content': json['content'],
    };
}

export function AppointmentNoteStampToJSON(value?: AppointmentNoteStamp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'content': value.content,
    };
}

