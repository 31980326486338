/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentConcernModal
 */
export interface AppointmentConcernModal {
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcernModal
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcernModal
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcernModal
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcernModal
     */
    option_cancel?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcernModal
     */
    option_confirm?: string;
}

/**
 * Check if a given object implements the AppointmentConcernModal interface.
 */
export function instanceOfAppointmentConcernModal(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentConcernModalFromJSON(json: any): AppointmentConcernModal {
    return AppointmentConcernModalFromJSONTyped(json, false);
}

export function AppointmentConcernModalFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentConcernModal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'option_cancel': !exists(json, 'option_cancel') ? undefined : json['option_cancel'],
        'option_confirm': !exists(json, 'option_confirm') ? undefined : json['option_confirm'],
    };
}

export function AppointmentConcernModalToJSON(value?: AppointmentConcernModal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'title': value.title,
        'text': value.text,
        'option_cancel': value.option_cancel,
        'option_confirm': value.option_confirm,
    };
}

