/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatChannelAttributes } from './ChatChannelAttributes';
import {
    ChatChannelAttributesFromJSON,
    ChatChannelAttributesFromJSONTyped,
    ChatChannelAttributesToJSON,
} from './ChatChannelAttributes';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * 
 * @export
 * @interface ChatArchive
 */
export interface ChatArchive {
    /**
     * 
     * @type {string}
     * @memberof ChatArchive
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatArchive
     */
    room_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatArchive
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatArchive
     */
    staff_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ChatArchive
     */
    date_start?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatArchive
     */
    date_end?: number;
    /**
     * 
     * @type {ChatChannelAttributes}
     * @memberof ChatArchive
     */
    attributes?: ChatChannelAttributes;
    /**
     * 
     * @type {Array<Message>}
     * @memberof ChatArchive
     */
    messages?: Array<Message>;
}

/**
 * Check if a given object implements the ChatArchive interface.
 */
export function instanceOfChatArchive(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChatArchiveFromJSON(json: any): ChatArchive {
    return ChatArchiveFromJSONTyped(json, false);
}

export function ChatArchiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatArchive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'room_name': !exists(json, 'room_name') ? undefined : json['room_name'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'staff_id': !exists(json, 'staff_id') ? undefined : json['staff_id'],
        'date_start': !exists(json, 'date_start') ? undefined : json['date_start'],
        'date_end': !exists(json, 'date_end') ? undefined : json['date_end'],
        'attributes': !exists(json, 'attributes') ? undefined : ChatChannelAttributesFromJSON(json['attributes']),
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageFromJSON)),
    };
}

export function ChatArchiveToJSON(value?: ChatArchive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'room_name': value.room_name,
        'patient_id': value.patient_id,
        'staff_id': value.staff_id,
        'date_start': value.date_start,
        'date_end': value.date_end,
        'attributes': ChatChannelAttributesToJSON(value.attributes),
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageToJSON)),
    };
}

