/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentCharge
 */
export interface PaymentCharge {
    /**
     * 
     * @type {string}
     * @memberof PaymentCharge
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCharge
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentCharge
     */
    memo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentCharge
     */
    tax_exempt?: boolean;
}

/**
 * Check if a given object implements the PaymentCharge interface.
 */
export function instanceOfPaymentCharge(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentChargeFromJSON(json: any): PaymentCharge {
    return PaymentChargeFromJSONTyped(json, false);
}

export function PaymentChargeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentCharge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'tax_exempt': !exists(json, 'tax_exempt') ? undefined : json['tax_exempt'],
    };
}

export function PaymentChargeToJSON(value?: PaymentCharge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'description': value.description,
        'memo': value.memo,
        'tax_exempt': value.tax_exempt,
    };
}

