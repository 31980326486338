/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OldVersionScreen from 'components/Utils/OldVersionScreen';

import RouteUtils from 'services/RouteUtils';
import PerformanceUtils from 'services/PerformanceUtils';

import HTML5 from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { Device } from 'services/Native/Device';

import 'css/_common.scss';
import 'css/v2/_v2.scss';
import { useAppSelector } from 'careand-redux/store';
import { useAutoUpdateSafeAreas } from 'careand-redux/listeners/SafeAreaListener';
import useNativeEventsHandler from 'scenes/HomePatient2/NativeEventsHandler';

const AppStaff = React.lazy(() => import('scenes/App/AppStaff'));
const AppPatient2 = React.lazy(() => import('scenes/App/AppPatient2'));

const PUBLIC_ROUTES = PerformanceUtils.objectToArray(RouteUtils.publicRoutes());
const PRIVATE_ROUTES = PerformanceUtils.objectToArray(RouteUtils.routes());

const App = ({ patient, authenticated, environment, version }) => {
  const oldVersion = useAppSelector((state) => state.ui.oldVersion);
  const isNativeMobile = Device.isMobile();

  useNativeEventsHandler(!!patient);
  useAutoUpdateSafeAreas();

  return (
    <React.Suspense fallback={null}>
      {/* Patient App handles oldVersion internally */}
      {oldVersion && !patient && <OldVersionScreen />}
      {(!oldVersion || patient) &&
        (patient ? (
          <AppPatient2
            environment={environment}
            authenticated={authenticated}
            version={version}
            publicRoutes={PUBLIC_ROUTES}
            privateRoutes={PRIVATE_ROUTES}
            isNativeMobile={isNativeMobile}
          />
        ) : (
          <AppStaff
            authenticated={authenticated}
            version={version}
            publicRoutes={PUBLIC_ROUTES}
            privateRoutes={PRIVATE_ROUTES}
            isNativeMobile={isNativeMobile}
          />
        ))}
    </React.Suspense>
  );
};

App.propTypes = {
  authenticated: PropTypes.bool,
  patient: PropTypes.bool,
  environment: PropTypes.string,
  version: PropTypes.string,
};

export default DragDropContext(HTML5)(App);
