/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageAttributes } from './MessageAttributes';
import {
    MessageAttributesFromJSON,
    MessageAttributesFromJSONTyped,
    MessageAttributesToJSON,
} from './MessageAttributes';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    time: number;
    /**
     * 
     * @type {MessageAttributes}
     * @memberof Message
     */
    attributes?: MessageAttributes;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': json['author'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'time': json['time'],
        'attributes': !exists(json, 'attributes') ? undefined : MessageAttributesFromJSON(json['attributes']),
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'message': value.message,
        'time': value.time,
        'attributes': MessageAttributesToJSON(value.attributes),
    };
}

