/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AIFeedbackRecords } from './AIFeedbackRecords';
import {
    AIFeedbackRecordsFromJSON,
    AIFeedbackRecordsFromJSONTyped,
    AIFeedbackRecordsToJSON,
} from './AIFeedbackRecords';

/**
 * 
 * @export
 * @interface AIFeedback
 */
export interface AIFeedback {
    /**
     * 
     * @type {string}
     * @memberof AIFeedback
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFeedback
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFeedback
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFeedback
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof AIFeedback
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof AIFeedback
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof AIFeedback
     */
    new_note?: string;
    /**
     * 
     * @type {AIFeedbackRecords}
     * @memberof AIFeedback
     */
    records?: AIFeedbackRecords;
}

/**
 * Check if a given object implements the AIFeedback interface.
 */
export function instanceOfAIFeedback(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AIFeedbackFromJSON(json: any): AIFeedback {
    return AIFeedbackFromJSONTyped(json, false);
}

export function AIFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'new_note': !exists(json, 'new_note') ? undefined : json['new_note'],
        'records': !exists(json, 'records') ? undefined : AIFeedbackRecordsFromJSON(json['records']),
    };
}

export function AIFeedbackToJSON(value?: AIFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'physician_id': value.physician_id,
        'patient_id': value.patient_id,
        'text': value.text,
        'date': value.date,
        'note': value.note,
        'new_note': value.new_note,
        'records': AIFeedbackRecordsToJSON(value.records),
    };
}

