/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationDetails } from './CancellationDetails';
import {
    CancellationDetailsFromJSON,
    CancellationDetailsFromJSONTyped,
    CancellationDetailsToJSON,
} from './CancellationDetails';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';
import type { PaymentPlan } from './PaymentPlan';
import {
    PaymentPlanFromJSON,
    PaymentPlanFromJSONTyped,
    PaymentPlanToJSON,
} from './PaymentPlan';
import type { PaymentPlanMetadata } from './PaymentPlanMetadata';
import {
    PaymentPlanMetadataFromJSON,
    PaymentPlanMetadataFromJSONTyped,
    PaymentPlanMetadataToJSON,
} from './PaymentPlanMetadata';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    object?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    billing?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    cancel_at_period_end?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    canceled_at?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    cancel_at?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    current_period_end?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    current_period_start?: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    customer?: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    days_until_due?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    ended_at?: number;
    /**
     * 
     * @type {PaymentPlan}
     * @memberof Subscription
     */
    plan?: PaymentPlan;
    /**
     * 
     * @type {PaymentPlanMetadata}
     * @memberof Subscription
     */
    metadata?: PaymentPlanMetadata;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    start?: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    tax_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    trial_end?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    trial_start?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    billing_cycle_anchor?: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    latest_invoice?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    clinic_id?: string;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof Subscription
     */
    invoices?: Array<Invoice>;
    /**
     * 
     * @type {CancellationDetails}
     * @memberof Subscription
     */
    cancellation_details?: CancellationDetails;
}

/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'billing': !exists(json, 'billing') ? undefined : json['billing'],
        'cancel_at_period_end': !exists(json, 'cancel_at_period_end') ? undefined : json['cancel_at_period_end'],
        'canceled_at': !exists(json, 'canceled_at') ? undefined : json['canceled_at'],
        'cancel_at': !exists(json, 'cancel_at') ? undefined : json['cancel_at'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'current_period_end': !exists(json, 'current_period_end') ? undefined : json['current_period_end'],
        'current_period_start': !exists(json, 'current_period_start') ? undefined : json['current_period_start'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'days_until_due': !exists(json, 'days_until_due') ? undefined : json['days_until_due'],
        'ended_at': !exists(json, 'ended_at') ? undefined : json['ended_at'],
        'plan': !exists(json, 'plan') ? undefined : PaymentPlanFromJSON(json['plan']),
        'metadata': !exists(json, 'metadata') ? undefined : PaymentPlanMetadataFromJSON(json['metadata']),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tax_percent': !exists(json, 'tax_percent') ? undefined : json['tax_percent'],
        'trial_end': !exists(json, 'trial_end') ? undefined : json['trial_end'],
        'trial_start': !exists(json, 'trial_start') ? undefined : json['trial_start'],
        'billing_cycle_anchor': !exists(json, 'billing_cycle_anchor') ? undefined : json['billing_cycle_anchor'],
        'latest_invoice': !exists(json, 'latest_invoice') ? undefined : json['latest_invoice'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'invoices': !exists(json, 'invoices') ? undefined : ((json['invoices'] as Array<any>).map(InvoiceFromJSON)),
        'cancellation_details': !exists(json, 'cancellation_details') ? undefined : CancellationDetailsFromJSON(json['cancellation_details']),
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'object': value.object,
        'billing': value.billing,
        'cancel_at_period_end': value.cancel_at_period_end,
        'canceled_at': value.canceled_at,
        'cancel_at': value.cancel_at,
        'created': value.created,
        'current_period_end': value.current_period_end,
        'current_period_start': value.current_period_start,
        'customer': value.customer,
        'days_until_due': value.days_until_due,
        'ended_at': value.ended_at,
        'plan': PaymentPlanToJSON(value.plan),
        'metadata': PaymentPlanMetadataToJSON(value.metadata),
        'quantity': value.quantity,
        'start': value.start,
        'status': value.status,
        'tax_percent': value.tax_percent,
        'trial_end': value.trial_end,
        'trial_start': value.trial_start,
        'billing_cycle_anchor': value.billing_cycle_anchor,
        'latest_invoice': value.latest_invoice,
        'patient_id': value.patient_id,
        'clinic_id': value.clinic_id,
        'invoices': value.invoices === undefined ? undefined : ((value.invoices as Array<any>).map(InvoiceToJSON)),
        'cancellation_details': CancellationDetailsToJSON(value.cancellation_details),
    };
}

