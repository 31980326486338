/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DrugbankIngredient } from './DrugbankIngredient';
import {
    DrugbankIngredientFromJSON,
    DrugbankIngredientFromJSONTyped,
    DrugbankIngredientToJSON,
} from './DrugbankIngredient';
import type { DrugbankProductLabeller } from './DrugbankProductLabeller';
import {
    DrugbankProductLabellerFromJSON,
    DrugbankProductLabellerFromJSONTyped,
    DrugbankProductLabellerToJSON,
} from './DrugbankProductLabeller';
import type { DrugbankTherapeuticCategory } from './DrugbankTherapeuticCategory';
import {
    DrugbankTherapeuticCategoryFromJSON,
    DrugbankTherapeuticCategoryFromJSONTyped,
    DrugbankTherapeuticCategoryToJSON,
} from './DrugbankTherapeuticCategory';

/**
 * 
 * @export
 * @interface DrugbankProduct
 */
export interface DrugbankProduct {
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    ndc_product_code?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    dpd_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    ema_product_code?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    ema_ma_number?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    prescribable_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    started_marketing_on?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    ended_marketing_on?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    approved_on?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    schedule?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    dosage_form?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    application_number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    vaccine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    allergenic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    cosmetic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    generic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    otc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProduct
     */
    country?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProduct
     */
    mixture?: boolean;
    /**
     * 
     * @type {DrugbankProductLabeller}
     * @memberof DrugbankProduct
     */
    labeller?: DrugbankProductLabeller;
    /**
     * 
     * @type {Array<DrugbankIngredient>}
     * @memberof DrugbankProduct
     */
    ingredients?: Array<DrugbankIngredient>;
    /**
     * 
     * @type {Array<DrugbankTherapeuticCategory>}
     * @memberof DrugbankProduct
     */
    therapeutic_categories?: Array<DrugbankTherapeuticCategory>;
}

/**
 * Check if a given object implements the DrugbankProduct interface.
 */
export function instanceOfDrugbankProduct(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DrugbankProductFromJSON(json: any): DrugbankProduct {
    return DrugbankProductFromJSONTyped(json, false);
}

export function DrugbankProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrugbankProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ndc_product_code': !exists(json, 'ndc_product_code') ? undefined : json['ndc_product_code'],
        'dpd_id': !exists(json, 'dpd_id') ? undefined : json['dpd_id'],
        'ema_product_code': !exists(json, 'ema_product_code') ? undefined : json['ema_product_code'],
        'ema_ma_number': !exists(json, 'ema_ma_number') ? undefined : json['ema_ma_number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'prescribable_name': !exists(json, 'prescribable_name') ? undefined : json['prescribable_name'],
        'started_marketing_on': !exists(json, 'started_marketing_on') ? undefined : json['started_marketing_on'],
        'ended_marketing_on': !exists(json, 'ended_marketing_on') ? undefined : json['ended_marketing_on'],
        'approved_on': !exists(json, 'approved_on') ? undefined : json['approved_on'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
        'dosage_form': !exists(json, 'dosage_form') ? undefined : json['dosage_form'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'application_number': !exists(json, 'application_number') ? undefined : json['application_number'],
        'vaccine': !exists(json, 'vaccine') ? undefined : json['vaccine'],
        'allergenic': !exists(json, 'allergenic') ? undefined : json['allergenic'],
        'cosmetic': !exists(json, 'cosmetic') ? undefined : json['cosmetic'],
        'generic': !exists(json, 'generic') ? undefined : json['generic'],
        'otc': !exists(json, 'otc') ? undefined : json['otc'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'mixture': !exists(json, 'mixture') ? undefined : json['mixture'],
        'labeller': !exists(json, 'labeller') ? undefined : DrugbankProductLabellerFromJSON(json['labeller']),
        'ingredients': !exists(json, 'ingredients') ? undefined : ((json['ingredients'] as Array<any>).map(DrugbankIngredientFromJSON)),
        'therapeutic_categories': !exists(json, 'therapeutic_categories') ? undefined : ((json['therapeutic_categories'] as Array<any>).map(DrugbankTherapeuticCategoryFromJSON)),
    };
}

export function DrugbankProductToJSON(value?: DrugbankProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ndc_product_code': value.ndc_product_code,
        'dpd_id': value.dpd_id,
        'ema_product_code': value.ema_product_code,
        'ema_ma_number': value.ema_ma_number,
        'name': value.name,
        'prescribable_name': value.prescribable_name,
        'started_marketing_on': value.started_marketing_on,
        'ended_marketing_on': value.ended_marketing_on,
        'approved_on': value.approved_on,
        'schedule': value.schedule,
        'dosage_form': value.dosage_form,
        'route': value.route,
        'application_number': value.application_number,
        'vaccine': value.vaccine,
        'allergenic': value.allergenic,
        'cosmetic': value.cosmetic,
        'generic': value.generic,
        'otc': value.otc,
        'approved': value.approved,
        'country': value.country,
        'mixture': value.mixture,
        'labeller': DrugbankProductLabellerToJSON(value.labeller),
        'ingredients': value.ingredients === undefined ? undefined : ((value.ingredients as Array<any>).map(DrugbankIngredientToJSON)),
        'therapeutic_categories': value.therapeutic_categories === undefined ? undefined : ((value.therapeutic_categories as Array<any>).map(DrugbankTherapeuticCategoryToJSON)),
    };
}

