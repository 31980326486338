/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { PhysicianPreferences } from './PhysicianPreferences';
import {
    PhysicianPreferencesFromJSON,
    PhysicianPreferencesFromJSONTyped,
    PhysicianPreferencesToJSON,
} from './PhysicianPreferences';

/**
 * 
 * @export
 * @interface Physician
 */
export interface Physician {
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    middle_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    lowercase_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    billing_number?: string;
    /**
     * 
     * @type {Address}
     * @memberof Physician
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    gender?: PhysicianGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof Physician
     */
    birthdate?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    languages?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    identification?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    license?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    college_registration?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    service_area?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    checked_in?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    photo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    signature?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    specialty?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    inactive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    favorite_templates?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    intern_of?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    default_room?: string;
    /**
     * 
     * @type {string}
     * @memberof Physician
     */
    physician_type?: PhysicianPhysicianTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Physician
     */
    vacation_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    telemedicine_only?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    only_new_concerns?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    blacklisted_patients?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    favorite_contacts?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    favorite_patients?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    hide_portal?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    pinned_patient_ids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Physician
     */
    skills?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    is_not_valid_primary?: boolean;
    /**
     * 
     * @type {PhysicianPreferences}
     * @memberof Physician
     */
    preferences?: PhysicianPreferences;
    /**
     * 
     * @type {boolean}
     * @memberof Physician
     */
    locum?: boolean;
}


/**
 * @export
 */
export const PhysicianGenderEnum = {
    Male: 'Male',
    Female: 'Female'
} as const;
export type PhysicianGenderEnum = typeof PhysicianGenderEnum[keyof typeof PhysicianGenderEnum];

/**
 * @export
 */
export const PhysicianPhysicianTypeEnum = {
    NursePractitioner: 'Nurse Practitioner',
    Phlebotomist: 'Phlebotomist',
    Nurse: 'Nurse'
} as const;
export type PhysicianPhysicianTypeEnum = typeof PhysicianPhysicianTypeEnum[keyof typeof PhysicianPhysicianTypeEnum];


/**
 * Check if a given object implements the Physician interface.
 */
export function instanceOfPhysician(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function PhysicianFromJSON(json: any): Physician {
    return PhysicianFromJSONTyped(json, false);
}

export function PhysicianFromJSONTyped(json: any, ignoreDiscriminator: boolean): Physician {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middle_name': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'lowercase_name': !exists(json, 'lowercase_name') ? undefined : json['lowercase_name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'billing_number': !exists(json, 'billing_number') ? undefined : json['billing_number'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthdate': !exists(json, 'birthdate') ? undefined : json['birthdate'],
        'languages': !exists(json, 'languages') ? undefined : json['languages'],
        'identification': !exists(json, 'identification') ? undefined : json['identification'],
        'license': !exists(json, 'license') ? undefined : json['license'],
        'college_registration': !exists(json, 'college_registration') ? undefined : json['college_registration'],
        'service_area': !exists(json, 'service_area') ? undefined : json['service_area'],
        'checked_in': !exists(json, 'checked_in') ? undefined : json['checked_in'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'specialty': !exists(json, 'specialty') ? undefined : json['specialty'],
        'inactive': !exists(json, 'inactive') ? undefined : json['inactive'],
        'favorite_templates': !exists(json, 'favorite_templates') ? undefined : json['favorite_templates'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'intern_of': !exists(json, 'intern_of') ? undefined : json['intern_of'],
        'default_room': !exists(json, 'default_room') ? undefined : json['default_room'],
        'physician_type': !exists(json, 'physician_type') ? undefined : json['physician_type'],
        'vacation_days': !exists(json, 'vacation_days') ? undefined : json['vacation_days'],
        'telemedicine_only': !exists(json, 'telemedicine_only') ? undefined : json['telemedicine_only'],
        'only_new_concerns': !exists(json, 'only_new_concerns') ? undefined : json['only_new_concerns'],
        'blacklisted_patients': !exists(json, 'blacklisted_patients') ? undefined : json['blacklisted_patients'],
        'favorite_contacts': !exists(json, 'favorite_contacts') ? undefined : json['favorite_contacts'],
        'favorite_patients': !exists(json, 'favorite_patients') ? undefined : json['favorite_patients'],
        'hide_portal': !exists(json, 'hide_portal') ? undefined : json['hide_portal'],
        'pinned_patient_ids': !exists(json, 'pinned_patient_ids') ? undefined : json['pinned_patient_ids'],
        'skills': !exists(json, 'skills') ? undefined : json['skills'],
        'is_not_valid_primary': !exists(json, 'is_not_valid_primary') ? undefined : json['is_not_valid_primary'],
        'preferences': !exists(json, 'preferences') ? undefined : PhysicianPreferencesFromJSON(json['preferences']),
        'locum': !exists(json, 'locum') ? undefined : json['locum'],
    };
}

export function PhysicianToJSON(value?: Physician | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'first_name': value.first_name,
        'middle_name': value.middle_name,
        'last_name': value.last_name,
        'lowercase_name': value.lowercase_name,
        'title': value.title,
        'billing_number': value.billing_number,
        'address': AddressToJSON(value.address),
        'phone': value.phone,
        'fax': value.fax,
        'gender': value.gender,
        'birthdate': value.birthdate,
        'languages': value.languages,
        'identification': value.identification,
        'license': value.license,
        'college_registration': value.college_registration,
        'service_area': value.service_area,
        'checked_in': value.checked_in,
        'photo': value.photo,
        'signature': value.signature,
        'specialty': value.specialty,
        'inactive': value.inactive,
        'favorite_templates': value.favorite_templates,
        'clinic_id': value.clinic_id,
        'intern_of': value.intern_of,
        'default_room': value.default_room,
        'physician_type': value.physician_type,
        'vacation_days': value.vacation_days,
        'telemedicine_only': value.telemedicine_only,
        'only_new_concerns': value.only_new_concerns,
        'blacklisted_patients': value.blacklisted_patients,
        'favorite_contacts': value.favorite_contacts,
        'favorite_patients': value.favorite_patients,
        'hide_portal': value.hide_portal,
        'pinned_patient_ids': value.pinned_patient_ids,
        'skills': value.skills,
        'is_not_valid_primary': value.is_not_valid_primary,
        'preferences': PhysicianPreferencesToJSON(value.preferences),
        'locum': value.locum,
    };
}

