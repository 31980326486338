/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChatArchive,
  ChatShortcut,
  ErrorResponse,
  IntegerResource,
  StringResource,
  UnreadCount,
  XMPPAuthentication,
  XMPPChat,
} from '../models';
import {
    ChatArchiveFromJSON,
    ChatArchiveToJSON,
    ChatShortcutFromJSON,
    ChatShortcutToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    IntegerResourceFromJSON,
    IntegerResourceToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
    UnreadCountFromJSON,
    UnreadCountToJSON,
    XMPPAuthenticationFromJSON,
    XMPPAuthenticationToJSON,
    XMPPChatFromJSON,
    XMPPChatToJSON,
} from '../models';

export interface AddChatShortcutRequest {
    shortcut: ChatShortcut;
}

export interface ArchiveXMPPChatRoomRequest {
    roomName: string;
}

export interface ClaimXMPPHelpdeskChatRoomRequest {
    roomName: string;
}

export interface CreateXMPPHelpdeskChatRoomRequest {
    patientId: string;
}

export interface CreateXMPPOnDemandChatRoomRequest {
    patientId: string;
}

export interface GetUnreadCountRequest {
    userId: string;
}

export interface GetUserArchivesRequest {
    userId: string;
    role: GetUserArchivesRoleEnum;
}

export interface GetXMPPChatAuthRequest {
    userId: string;
}

export interface GetXMPPFileRequest {
    name: string;
    roomName: string;
}

export interface TransferXMPPChatRequest {
    roomName: string;
    userId: string;
}

export interface UpdateUnreadCountRequest {
    userId: string;
    count: IntegerResource;
}

export interface UploadXMPPFileRequest {
    roomName: string;
    file: Blob;
}

/**
 * 
 */
export class ChatApi extends runtime.BaseAPI {

    /**
     * Adds a chat shortcut (Roles: physician, admin)
     */
    async addChatShortcutRaw(requestParameters: AddChatShortcutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatShortcut>> {
        if (requestParameters.shortcut === null || requestParameters.shortcut === undefined) {
            throw new runtime.RequiredError('shortcut','Required parameter requestParameters.shortcut was null or undefined when calling addChatShortcut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/shortcuts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatShortcutToJSON(requestParameters.shortcut),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatShortcutFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addChatShortcutRequestOptionsRaw(requestParameters: AddChatShortcutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.shortcut === null || requestParameters.shortcut === undefined) {
            throw new runtime.RequiredError('shortcut','Required parameter requestParameters.shortcut was null or undefined when calling addChatShortcut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/shortcuts`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ChatShortcutToJSON(requestParameters.shortcut),
        };
        return responseConfig
    }

    /**
     * Adds a chat shortcut (Roles: physician, admin)
     */
    async addChatShortcut(requestParameters: AddChatShortcutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatShortcut> {
        const response = await this.addChatShortcutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archives an xmpp helpdesk chat room (Roles: admin, physician)
     */
    async archiveXMPPChatRoomRaw(requestParameters: ArchiveXMPPChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XMPPChat>> {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling archiveXMPPChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/helpdesk/room/{roomName}`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XMPPChatFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    archiveXMPPChatRoomRequestOptionsRaw(requestParameters: ArchiveXMPPChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling archiveXMPPChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/helpdesk/room/{roomName}`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Archives an xmpp helpdesk chat room (Roles: admin, physician)
     */
    async archiveXMPPChatRoom(requestParameters: ArchiveXMPPChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XMPPChat> {
        const response = await this.archiveXMPPChatRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Claims an xmpp helpdesk chat room (Roles: admin, physician)
     */
    async claimXMPPHelpdeskChatRoomRaw(requestParameters: ClaimXMPPHelpdeskChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XMPPChat>> {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling claimXMPPHelpdeskChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/helpdesk/room/{roomName}`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XMPPChatFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    claimXMPPHelpdeskChatRoomRequestOptionsRaw(requestParameters: ClaimXMPPHelpdeskChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling claimXMPPHelpdeskChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/helpdesk/room/{roomName}`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Claims an xmpp helpdesk chat room (Roles: admin, physician)
     */
    async claimXMPPHelpdeskChatRoom(requestParameters: ClaimXMPPHelpdeskChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XMPPChat> {
        const response = await this.claimXMPPHelpdeskChatRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a XMPP helpdesk chat (Roles: patient)
     */
    async createXMPPHelpdeskChatRoomRaw(requestParameters: CreateXMPPHelpdeskChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XMPPChat>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling createXMPPHelpdeskChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/helpdesk/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XMPPChatFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    createXMPPHelpdeskChatRoomRequestOptionsRaw(requestParameters: CreateXMPPHelpdeskChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling createXMPPHelpdeskChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/helpdesk/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Creates a XMPP helpdesk chat (Roles: patient)
     */
    async createXMPPHelpdeskChatRoom(requestParameters: CreateXMPPHelpdeskChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XMPPChat> {
        const response = await this.createXMPPHelpdeskChatRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a XMPP on-demand chat (Roles: admin, physician)
     */
    async createXMPPOnDemandChatRoomRaw(requestParameters: CreateXMPPOnDemandChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XMPPChat>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling createXMPPOnDemandChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/ondemand/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XMPPChatFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    createXMPPOnDemandChatRoomRequestOptionsRaw(requestParameters: CreateXMPPOnDemandChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling createXMPPOnDemandChatRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/ondemand/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Creates a XMPP on-demand chat (Roles: admin, physician)
     */
    async createXMPPOnDemandChatRoom(requestParameters: CreateXMPPOnDemandChatRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XMPPChat> {
        const response = await this.createXMPPOnDemandChatRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all chat shortcuts (Roles: physician, admin)
     */
    async getChatShortcutsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChatShortcut>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/shortcuts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatShortcutFromJSON));
    }

    /**
        Request Config Getter
     */
    getChatShortcutsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/shortcuts`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all chat shortcuts (Roles: physician, admin)
     */
    async getChatShortcuts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChatShortcut>> {
        const response = await this.getChatShortcutsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets own active XMPP helpdesk chats (Roles: admin, physician, patient)
     */
    async getOwnXMPPHelpdeskChatRoomsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<XMPPChat>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/own/rooms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(XMPPChatFromJSON));
    }

    /**
        Request Config Getter
     */
    getOwnXMPPHelpdeskChatRoomsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/own/rooms`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets own active XMPP helpdesk chats (Roles: admin, physician, patient)
     */
    async getOwnXMPPHelpdeskChatRooms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<XMPPChat>> {
        const response = await this.getOwnXMPPHelpdeskChatRoomsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a patient\'s unread count (Roles: patient)
     */
    async getUnreadCountRaw(requestParameters: GetUnreadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UnreadCount>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUnreadCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/unread-count/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UnreadCountFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getUnreadCountRequestOptionsRaw(requestParameters: GetUnreadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUnreadCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/unread-count/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a patient\'s unread count (Roles: patient)
     */
    async getUnreadCount(requestParameters: GetUnreadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UnreadCount> {
        const response = await this.getUnreadCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a user\'s chat archives (Roles: patient, admin, physician)
     */
    async getUserArchivesRaw(requestParameters: GetUserArchivesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChatArchive>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserArchives.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getUserArchives.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/{role}/{userId}/archives`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatArchiveFromJSON));
    }

    /**
        Request Config Getter
     */
    getUserArchivesRequestOptionsRaw(requestParameters: GetUserArchivesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserArchives.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getUserArchives.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/{role}/{userId}/archives`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Get a user\'s chat archives (Roles: patient, admin, physician)
     */
    async getUserArchives(requestParameters: GetUserArchivesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChatArchive>> {
        const response = await this.getUserArchivesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the xmpp credentials (Roles: patient, physician, admin)
     */
    async getXMPPChatAuthRaw(requestParameters: GetXMPPChatAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XMPPAuthentication>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getXMPPChatAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/{userId}/auth`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XMPPAuthenticationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getXMPPChatAuthRequestOptionsRaw(requestParameters: GetXMPPChatAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getXMPPChatAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/{userId}/auth`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the xmpp credentials (Roles: patient, physician, admin)
     */
    async getXMPPChatAuth(requestParameters: GetXMPPChatAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XMPPAuthentication> {
        const response = await this.getXMPPChatAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets an XMPP file (Roles: admin, physician, patient)
     */
    async getXMPPFileRaw(requestParameters: GetXMPPFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getXMPPFile.');
        }

        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling getXMPPFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/file/{roomName}/file/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))).replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getXMPPFileRequestOptionsRaw(requestParameters: GetXMPPFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getXMPPFile.');
        }

        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling getXMPPFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/file/{roomName}/file/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))).replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets an XMPP file (Roles: admin, physician, patient)
     */
    async getXMPPFile(requestParameters: GetXMPPFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getXMPPFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets active XMPP helpdesk chats (Roles: admin, physician)
     */
    async getXMPPHelpdeskChatRoomsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<XMPPChat>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/helpdesk/rooms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(XMPPChatFromJSON));
    }

    /**
        Request Config Getter
     */
    getXMPPHelpdeskChatRoomsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/helpdesk/rooms`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets active XMPP helpdesk chats (Roles: admin, physician)
     */
    async getXMPPHelpdeskChatRooms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<XMPPChat>> {
        const response = await this.getXMPPHelpdeskChatRoomsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Transfers an XMPP chat to a different user (Roles: admin)
     */
    async transferXMPPChatRaw(requestParameters: TransferXMPPChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<XMPPChat>> {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling transferXMPPChat.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling transferXMPPChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp/helpdesk/room/{roomName}/transfer/{userId}`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => XMPPChatFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    transferXMPPChatRequestOptionsRaw(requestParameters: TransferXMPPChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling transferXMPPChat.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling transferXMPPChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp/helpdesk/room/{roomName}/transfer/{userId}`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Transfers an XMPP chat to a different user (Roles: admin)
     */
    async transferXMPPChat(requestParameters: TransferXMPPChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<XMPPChat> {
        const response = await this.transferXMPPChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a patient\'s unread count (Roles: patient)
     */
    async updateUnreadCountRaw(requestParameters: UpdateUnreadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUnreadCount.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling updateUnreadCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/unread-count/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegerResourceToJSON(requestParameters.count),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    updateUnreadCountRequestOptionsRaw(requestParameters: UpdateUnreadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUnreadCount.');
        }

        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling updateUnreadCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/unread-count/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: IntegerResourceToJSON(requestParameters.count),
        };
        return responseConfig
    }

    /**
     * Updates a patient\'s unread count (Roles: patient)
     */
    async updateUnreadCount(requestParameters: UpdateUnreadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUnreadCountRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads an XMPP file (Roles: admin, physician, patient)
     */
    async uploadXMPPFileRaw(requestParameters: UploadXMPPFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling uploadXMPPFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadXMPPFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/chat/xmpp/file/{roomName}/file`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    uploadXMPPFileRequestOptionsRaw(requestParameters: UploadXMPPFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.roomName === null || requestParameters.roomName === undefined) {
            throw new runtime.RequiredError('roomName','Required parameter requestParameters.roomName was null or undefined when calling uploadXMPPFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadXMPPFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        const responseConfig = {
            path: `/chat/xmpp/file/{roomName}/file`.replace(`{${"roomName"}}`, encodeURIComponent(String(requestParameters.roomName))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Uploads an XMPP file (Roles: admin, physician, patient)
     */
    async uploadXMPPFile(requestParameters: UploadXMPPFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.uploadXMPPFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * XMPP Callback (Roles: none)
     */
    async xmppCallbackRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/chat/xmpp-callback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    xmppCallbackRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/chat/xmpp-callback`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * XMPP Callback (Roles: none)
     */
    async xmppCallback(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.xmppCallbackRaw(initOverrides);
    }

}

/**
 * @export
 */
export const GetUserArchivesRoleEnum = {
    Admin: 'admin',
    Physician: 'physician',
    Patient: 'patient'
} as const;
export type GetUserArchivesRoleEnum = typeof GetUserArchivesRoleEnum[keyof typeof GetUserArchivesRoleEnum];
