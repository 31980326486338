import { z } from "zod"
import { createMessage, NativeMessageSchema } from "./NativeMessage"

export const RequestPermissionMessageSchema = z.object({
  type: z.literal('requestPermission'),
  permission: z.union([z.literal('video'), z.literal('mic'), z.literal('push'), z.literal('location')])
}).extend(NativeMessageSchema.shape)

export type RequestPermissionMessage = z.infer<typeof RequestPermissionMessageSchema>

export type DevicePermission = RequestPermissionMessage['permission']

export const createRequestPermissionMessage = (permission: DevicePermission) => ({
  ...createMessage(),
  type: 'requestPermission',
  permission
} satisfies RequestPermissionMessage)