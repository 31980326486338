import { JitsiMeetingPayload } from 'generated/api';
import { createMessage, NativeMessage } from './NativeMessage';

export interface ShowTelemedicineMessage extends NativeMessage {
  type: 'showTelemedicine';
  jitsiPayload?: JitsiMeetingPayload;
}

export const createShowTelemedicineMessage = (jitsiPayload?: JitsiMeetingPayload) =>
  ({
    ...createMessage(),
    type: 'showTelemedicine',
    jitsiPayload,
  }) satisfies ShowTelemedicineMessage;
