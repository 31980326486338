/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HistoryIssueHistory } from './HistoryIssueHistory';
import {
    HistoryIssueHistoryFromJSON,
    HistoryIssueHistoryFromJSONTyped,
    HistoryIssueHistoryToJSON,
} from './HistoryIssueHistory';
import type { HistoryIssueValue } from './HistoryIssueValue';
import {
    HistoryIssueValueFromJSON,
    HistoryIssueValueFromJSONTyped,
    HistoryIssueValueToJSON,
} from './HistoryIssueValue';

/**
 * 
 * @export
 * @interface HistoryIssue
 */
export interface HistoryIssue {
    /**
     * 
     * @type {string}
     * @memberof HistoryIssue
     */
    id?: string;
    /**
     * 
     * @type {HistoryIssueValue}
     * @memberof HistoryIssue
     */
    value: HistoryIssueValue;
    /**
     * 
     * @type {Array<HistoryIssueHistory>}
     * @memberof HistoryIssue
     */
    history?: Array<HistoryIssueHistory>;
}

/**
 * Check if a given object implements the HistoryIssue interface.
 */
export function instanceOfHistoryIssue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function HistoryIssueFromJSON(json: any): HistoryIssue {
    return HistoryIssueFromJSONTyped(json, false);
}

export function HistoryIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryIssue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': HistoryIssueValueFromJSON(json['value']),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(HistoryIssueHistoryFromJSON)),
    };
}

export function HistoryIssueToJSON(value?: HistoryIssue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value': HistoryIssueValueToJSON(value.value),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(HistoryIssueHistoryToJSON)),
    };
}

