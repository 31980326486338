/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AIFeedback,
  AWSCredentials,
  ErrorResponse,
  OpenAIConversation,
  OpenAIMessage,
  StringResource,
} from '../models';
import {
    AIFeedbackFromJSON,
    AIFeedbackToJSON,
    AWSCredentialsFromJSON,
    AWSCredentialsToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OpenAIConversationFromJSON,
    OpenAIConversationToJSON,
    OpenAIMessageFromJSON,
    OpenAIMessageToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
} from '../models';

export interface ChatAssistantRequest {
    streamingKey?: string;
    conversation?: OpenAIConversation;
}

export interface PromptRequest {
    streamingKey?: string;
    documentId?: string;
    prompt?: StringResource;
}

export interface SendFeedbackRequest {
    feedback?: AIFeedback;
}

export interface SpeechToTextRequest {
    file: Blob;
    model?: SpeechToTextModelEnum;
    prompt?: string;
}

/**
 * 
 */
export class AIApi extends runtime.BaseAPI {

    /**
     * Chats with an assistant
     */
    async chatAssistantRaw(requestParameters: ChatAssistantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenAIMessage>> {
        const queryParameters: any = {};

        if (requestParameters.streamingKey !== undefined) {
            queryParameters['streamingKey'] = requestParameters.streamingKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ai/assistant/chat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OpenAIConversationToJSON(requestParameters.conversation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpenAIMessageFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    chatAssistantRequestOptionsRaw(requestParameters: ChatAssistantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.streamingKey !== undefined) {
            queryParameters['streamingKey'] = requestParameters.streamingKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ai/assistant/chat`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: OpenAIConversationToJSON(requestParameters.conversation),
        };
        return responseConfig
    }

    /**
     * Chats with an assistant
     */
    async chatAssistant(requestParameters: ChatAssistantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenAIMessage> {
        const response = await this.chatAssistantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * gives AWS Transcribe credentials (Roles: admin, physician)
     */
    async getAWSTranscribeCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AWSCredentials>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ai/awsTranscribeCredentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AWSCredentialsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getAWSTranscribeCredentialsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ai/awsTranscribeCredentials`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * gives AWS Transcribe credentials (Roles: admin, physician)
     */
    async getAWSTranscribeCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AWSCredentials> {
        const response = await this.getAWSTranscribeCredentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Executes a prompt
     */
    async promptRaw(requestParameters: PromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        const queryParameters: any = {};

        if (requestParameters.streamingKey !== undefined) {
            queryParameters['streamingKey'] = requestParameters.streamingKey;
        }

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ai/prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.prompt),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    promptRequestOptionsRaw(requestParameters: PromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.streamingKey !== undefined) {
            queryParameters['streamingKey'] = requestParameters.streamingKey;
        }

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ai/prompt`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.prompt),
        };
        return responseConfig
    }

    /**
     * Executes a prompt
     */
    async prompt(requestParameters: PromptRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.promptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends staff feedback about a prompt result
     */
    async sendFeedbackRaw(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ai/send-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIFeedbackToJSON(requestParameters.feedback),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    sendFeedbackRequestOptionsRaw(requestParameters: SendFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ai/send-feedback`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AIFeedbackToJSON(requestParameters.feedback),
        };
        return responseConfig
    }

    /**
     * Sends staff feedback about a prompt result
     */
    async sendFeedback(requestParameters: SendFeedbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.sendFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Converts Speech to Text (Roles: admin, physician)
     */
    async speechToTextRaw(requestParameters: SpeechToTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringResource>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling speechToText.');
        }

        const queryParameters: any = {};

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/ai/speech-to-text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringResourceFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    speechToTextRequestOptionsRaw(requestParameters: SpeechToTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling speechToText.');
        }

        const queryParameters: any = {};

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        const responseConfig = {
            path: `/ai/speech-to-text`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Converts Speech to Text (Roles: admin, physician)
     */
    async speechToText(requestParameters: SpeechToTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringResource> {
        const response = await this.speechToTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const SpeechToTextModelEnum = {
    Whisper1: 'whisper-1'
} as const;
export type SpeechToTextModelEnum = typeof SpeechToTextModelEnum[keyof typeof SpeechToTextModelEnum];
