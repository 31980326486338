/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Reminder,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ReminderFromJSON,
    ReminderToJSON,
} from '../models';

export interface AddReminderRequest {
    userId: string;
    reminder: Reminder;
}

export interface DeleteReminderRequest {
    userId: string;
    id: string;
}

export interface GetRemindersRequest {
    userId: string;
}

/**
 * 
 */
export class RemindersApi extends runtime.BaseAPI {

    /**
     * Adds a Reminder for the user (Roles: physician, admin)
     */
    async addReminderRaw(requestParameters: AddReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Reminder>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addReminder.');
        }

        if (requestParameters.reminder === null || requestParameters.reminder === undefined) {
            throw new runtime.RequiredError('reminder','Required parameter requestParameters.reminder was null or undefined when calling addReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/reminders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReminderToJSON(requestParameters.reminder),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReminderFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addReminderRequestOptionsRaw(requestParameters: AddReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addReminder.');
        }

        if (requestParameters.reminder === null || requestParameters.reminder === undefined) {
            throw new runtime.RequiredError('reminder','Required parameter requestParameters.reminder was null or undefined when calling addReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/reminders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ReminderToJSON(requestParameters.reminder),
        };
        return responseConfig
    }

    /**
     * Adds a Reminder for the user (Roles: physician, admin)
     */
    async addReminder(requestParameters: AddReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Reminder> {
        const response = await this.addReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a reminder (Roles: physician, admin)
     */
    async deleteReminderRaw(requestParameters: DeleteReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteReminder.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/reminders/{userId}/{id}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteReminderRequestOptionsRaw(requestParameters: DeleteReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteReminder.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/reminders/{userId}/{id}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a reminder (Roles: physician, admin)
     */
    async deleteReminder(requestParameters: DeleteReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReminderRaw(requestParameters, initOverrides);
    }

    /**
     * Returns all Reminders for the user (Roles: physician, admin)
     */
    async getRemindersRaw(requestParameters: GetRemindersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Reminder>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getReminders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/reminders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReminderFromJSON));
    }

    /**
        Request Config Getter
     */
    getRemindersRequestOptionsRaw(requestParameters: GetRemindersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getReminders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/reminders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all Reminders for the user (Roles: physician, admin)
     */
    async getReminders(requestParameters: GetRemindersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Reminder>> {
        const response = await this.getRemindersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
