/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DrugbankProductConcept } from './DrugbankProductConcept';
import {
    DrugbankProductConceptFromJSON,
    DrugbankProductConceptFromJSONTyped,
    DrugbankProductConceptToJSON,
} from './DrugbankProductConcept';
import type { MedicationReminderDuration } from './MedicationReminderDuration';
import {
    MedicationReminderDurationFromJSON,
    MedicationReminderDurationFromJSONTyped,
    MedicationReminderDurationToJSON,
} from './MedicationReminderDuration';
import type { MedicationReminderTake } from './MedicationReminderTake';
import {
    MedicationReminderTakeFromJSON,
    MedicationReminderTakeFromJSONTyped,
    MedicationReminderTakeToJSON,
} from './MedicationReminderTake';
import type { MedicationReminderWhen } from './MedicationReminderWhen';
import {
    MedicationReminderWhenFromJSON,
    MedicationReminderWhenFromJSONTyped,
    MedicationReminderWhenToJSON,
} from './MedicationReminderWhen';

/**
 * 
 * @export
 * @interface MedicationReminder
 */
export interface MedicationReminder {
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    name: string;
    /**
     * 
     * @type {DrugbankProductConcept}
     * @memberof MedicationReminder
     */
    drugbank_product?: DrugbankProductConcept;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    amount_unit?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    dosage?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    dosage_unit?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    start_date?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    units_total?: number;
    /**
     * 
     * @type {Array<MedicationReminderTake>}
     * @memberof MedicationReminder
     */
    takes: Array<MedicationReminderTake>;
    /**
     * 
     * @type {Array<MedicationReminderWhen>}
     * @memberof MedicationReminder
     */
    when: Array<MedicationReminderWhen>;
    /**
     * 
     * @type {MedicationReminderDuration}
     * @memberof MedicationReminder
     */
    duration: MedicationReminderDuration;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    discontinued_since?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminder
     */
    patient_medication_id?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    refills?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    pills_per_box?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationReminder
     */
    get_refill_reminders?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedicationReminder
     */
    pills_left_in_box?: number;
}

/**
 * Check if a given object implements the MedicationReminder interface.
 */
export function instanceOfMedicationReminder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "takes" in value;
    isInstance = isInstance && "when" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "timezone" in value;

    return isInstance;
}

export function MedicationReminderFromJSON(json: any): MedicationReminder {
    return MedicationReminderFromJSONTyped(json, false);
}

export function MedicationReminderFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': json['patient_id'],
        'name': json['name'],
        'drugbank_product': !exists(json, 'drugbank_product') ? undefined : DrugbankProductConceptFromJSON(json['drugbank_product']),
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'amount_unit': !exists(json, 'amount_unit') ? undefined : json['amount_unit'],
        'dosage': !exists(json, 'dosage') ? undefined : json['dosage'],
        'dosage_unit': !exists(json, 'dosage_unit') ? undefined : json['dosage_unit'],
        'start_date': !exists(json, 'start_date') ? undefined : json['start_date'],
        'units_total': !exists(json, 'units_total') ? undefined : json['units_total'],
        'takes': ((json['takes'] as Array<any>).map(MedicationReminderTakeFromJSON)),
        'when': ((json['when'] as Array<any>).map(MedicationReminderWhenFromJSON)),
        'duration': MedicationReminderDurationFromJSON(json['duration']),
        'discontinued_since': !exists(json, 'discontinued_since') ? undefined : json['discontinued_since'],
        'timezone': json['timezone'],
        'patient_medication_id': !exists(json, 'patient_medication_id') ? undefined : json['patient_medication_id'],
        'refills': !exists(json, 'refills') ? undefined : json['refills'],
        'pills_per_box': !exists(json, 'pills_per_box') ? undefined : json['pills_per_box'],
        'get_refill_reminders': !exists(json, 'get_refill_reminders') ? undefined : json['get_refill_reminders'],
        'pills_left_in_box': !exists(json, 'pills_left_in_box') ? undefined : json['pills_left_in_box'],
    };
}

export function MedicationReminderToJSON(value?: MedicationReminder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'name': value.name,
        'drugbank_product': DrugbankProductConceptToJSON(value.drugbank_product),
        'icon': value.icon,
        'color': value.color,
        'amount': value.amount,
        'amount_unit': value.amount_unit,
        'dosage': value.dosage,
        'dosage_unit': value.dosage_unit,
        'start_date': value.start_date,
        'units_total': value.units_total,
        'takes': ((value.takes as Array<any>).map(MedicationReminderTakeToJSON)),
        'when': ((value.when as Array<any>).map(MedicationReminderWhenToJSON)),
        'duration': MedicationReminderDurationToJSON(value.duration),
        'discontinued_since': value.discontinued_since,
        'timezone': value.timezone,
        'patient_medication_id': value.patient_medication_id,
        'refills': value.refills,
        'pills_per_box': value.pills_per_box,
        'get_refill_reminders': value.get_refill_reminders,
        'pills_left_in_box': value.pills_left_in_box,
    };
}

