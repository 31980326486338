/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewCredentials
 */
export interface NewCredentials {
    /**
     * 
     * @type {string}
     * @memberof NewCredentials
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof NewCredentials
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof NewCredentials
     */
    sms_code: string;
    /**
     * 
     * @type {string}
     * @memberof NewCredentials
     */
    admin_code: string;
}

/**
 * Check if a given object implements the NewCredentials interface.
 */
export function instanceOfNewCredentials(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "sms_code" in value;
    isInstance = isInstance && "admin_code" in value;

    return isInstance;
}

export function NewCredentialsFromJSON(json: any): NewCredentials {
    return NewCredentialsFromJSONTyped(json, false);
}

export function NewCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
        'sms_code': json['sms_code'],
        'admin_code': json['admin_code'],
    };
}

export function NewCredentialsToJSON(value?: NewCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'sms_code': value.sms_code,
        'admin_code': value.admin_code,
    };
}

