/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface Pharmacy
 */
export interface Pharmacy {
    /**
     * 
     * @type {string}
     * @memberof Pharmacy
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Pharmacy
     */
    name: string;
    /**
     * 
     * @type {Address}
     * @memberof Pharmacy
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof Pharmacy
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Pharmacy
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Pharmacy
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Pharmacy
     */
    search?: string;
}

/**
 * Check if a given object implements the Pharmacy interface.
 */
export function instanceOfPharmacy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function PharmacyFromJSON(json: any): Pharmacy {
    return PharmacyFromJSONTyped(json, false);
}

export function PharmacyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pharmacy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'address': AddressFromJSON(json['address']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'search': !exists(json, 'search') ? undefined : json['search'],
    };
}

export function PharmacyToJSON(value?: Pharmacy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': AddressToJSON(value.address),
        'phone': value.phone,
        'email': value.email,
        'fax': value.fax,
        'search': value.search,
    };
}

