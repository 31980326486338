import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const UnreadBadge = ({ unreadCount, unreadText, squished, style = {} }) => (
  <>
    {unreadCount >= 1 && (
      <div className={`unread-badge ${squished ? 'squished' : ''}`} style={style}>
        <span key={unreadCount}>{unreadCount >= 100 ? '99+' : unreadCount}</span>
      </div>
    )}
    {!!unreadText && (
      <div className={`unread-badge ${squished ? 'squished' : ''}`} style={style}>
        <span key={unreadText}>{unreadText}</span>
      </div>
    )}
  </>
);

UnreadBadge.propTypes = {
  unreadCount: PropTypes.number,
  unreadText: PropTypes.string,
  squished: PropTypes.bool,
};

export default UnreadBadge;
