import { z } from 'zod'
import { createMessage, NativeMessageSchema } from './NativeMessage'

export const WebAppInfoMessageSchema = z.object({
  type: z.literal('webAppInfo'),
  content: z.object({
    biometricAuthentication: z.boolean(),
    isLoggedIn: z.boolean()
  })
}).extend(NativeMessageSchema.shape)

export type WebAppInfoMessage = z.infer<typeof WebAppInfoMessageSchema>

export const createWebAppInfoMessage = (content: WebAppInfoMessage['content']) => {
  return {
    ...createMessage(),
    type: 'webAppInfo',
    content
  } satisfies WebAppInfoMessage
}