/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaffRegisterPayload
 */
export interface StaffRegisterPayload {
    /**
     * 
     * @type {string}
     * @memberof StaffRegisterPayload
     */
    user_type: StaffRegisterPayloadUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StaffRegisterPayload
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof StaffRegisterPayload
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof StaffRegisterPayload
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof StaffRegisterPayload
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof StaffRegisterPayload
     */
    clinic_id: string;
}


/**
 * @export
 */
export const StaffRegisterPayloadUserTypeEnum = {
    Admin: 'admin',
    Physician: 'physician'
} as const;
export type StaffRegisterPayloadUserTypeEnum = typeof StaffRegisterPayloadUserTypeEnum[keyof typeof StaffRegisterPayloadUserTypeEnum];


/**
 * Check if a given object implements the StaffRegisterPayload interface.
 */
export function instanceOfStaffRegisterPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user_type" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "first_name" in value;
    isInstance = isInstance && "last_name" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "clinic_id" in value;

    return isInstance;
}

export function StaffRegisterPayloadFromJSON(json: any): StaffRegisterPayload {
    return StaffRegisterPayloadFromJSONTyped(json, false);
}

export function StaffRegisterPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffRegisterPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_type': json['user_type'],
        'email': json['email'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'phone': json['phone'],
        'clinic_id': json['clinic_id'],
    };
}

export function StaffRegisterPayloadToJSON(value?: StaffRegisterPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_type': value.user_type,
        'email': value.email,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'phone': value.phone,
        'clinic_id': value.clinic_id,
    };
}

