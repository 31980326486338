/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentPlan } from './PaymentPlan';
import {
    PaymentPlanFromJSON,
    PaymentPlanFromJSONTyped,
    PaymentPlanToJSON,
} from './PaymentPlan';
import type { PaymentPlanPermission } from './PaymentPlanPermission';
import {
    PaymentPlanPermissionFromJSON,
    PaymentPlanPermissionFromJSONTyped,
    PaymentPlanPermissionToJSON,
} from './PaymentPlanPermission';

/**
 * 
 * @export
 * @interface ClinicPaymentPlan
 */
export interface ClinicPaymentPlan {
    /**
     * 
     * @type {string}
     * @memberof ClinicPaymentPlan
     */
    id: string;
    /**
     * 
     * @type {PaymentPlan}
     * @memberof ClinicPaymentPlan
     */
    payment_plan?: PaymentPlan;
    /**
     * 
     * @type {Array<PaymentPlanPermission>}
     * @memberof ClinicPaymentPlan
     */
    permissions?: Array<PaymentPlanPermission>;
}

/**
 * Check if a given object implements the ClinicPaymentPlan interface.
 */
export function instanceOfClinicPaymentPlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ClinicPaymentPlanFromJSON(json: any): ClinicPaymentPlan {
    return ClinicPaymentPlanFromJSONTyped(json, false);
}

export function ClinicPaymentPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicPaymentPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'payment_plan': !exists(json, 'payment_plan') ? undefined : PaymentPlanFromJSON(json['payment_plan']),
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PaymentPlanPermissionFromJSON)),
    };
}

export function ClinicPaymentPlanToJSON(value?: ClinicPaymentPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'payment_plan': PaymentPlanToJSON(value.payment_plan),
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PaymentPlanPermissionToJSON)),
    };
}

