/* eslint-disable no-undef */
import { createShowTelemedicineMessage } from './messages/ShowTelemedicine';
import config from 'config/config.json';
import { RequestOptions } from 'careand-redux/api/ApiThunks';
import { createOpenFileMessage } from './messages/OpenFile';
import { createRequestSetupMessage } from './messages/Setup';
import { createRequestPermissionMessage, DevicePermission } from './messages/RequestPermission';
import { StatusReplyMessage, StatusReplyMessageSchema } from './messages/StatusReply';
import { NativeMessage } from './messages/NativeMessage';
import {
  createRequestAppleHealthPermissionMessageSchema,
  createRequestAppleHealthSyncMessage,
  createSetAppleHealthPrimaryUserMessage,
  RequestAppleHealthSyncResultMessage,
} from './messages/AppleHealth';
import {
  DeviceTokenDeviceTypeEnum,
  HealthRecordsMetricSummary,
  JitsiMeetingPayload,
  MetricType,
  MetricTypeAppleHealth,
  UserRoleEnum,
} from 'generated/api';
import {
  createRequestCredentialsMessage,
  createStoreCredentialsMessage,
  RequestCredentialsMessage,
  RequestCredentialsMessageSchema,
} from './messages/RequestCredentials';
import { createWebAppInfoMessage, WebAppInfoMessage } from './messages/WebAppInfo';
import { createUnreadCountUpdatedMessage } from './messages/UnreadCountUpdated';
import {
  createNotificationTokenRequest,
  createNotificationTokenSentMessage,
  NotificationTokenSentMessageSchema,
  NotificationTokenUpdatedMessageSchema,
} from './messages/NotificationTokenUpdated';
import {
  createRequestActiveUserIdMessage,
  createRequestDeleteUserMessage,
  createRequestStoredUserCredentialsMessage,
  createRequestStoredUsersMessage,
  createRequestStoreUserMessage,
  RequestActiveUserIdMessageSchema,
  RequestStoredUserCredentialsMessageSchema,
  RequestStoredUsersMessageSchema,
  StoredUser,
} from './messages/StoredUsers';
import {
  createRequestOTPCodeMessage,
  RequestOTPCodeMessageSchema,
} from './messages/ActivateTwoFactor';

const IS_PRODUCTION_BUILD = config.ENVIRONMENT === 'production';

type SerializableMessage = string | boolean | undefined | string | object;

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        careAndMessageHandler?: {
          postMessage(msg: SerializableMessage): void;
        };
      };
    };
  }
  interface Android {
    postMessage(msg: SerializableMessage): void;
  }

  const Android: Android;
}

/**
 * Sends message to iOS Native App
 * @requires Device to have registered a messageHandler through webkit api
 */
const iOSMessageHandler = (msg: SerializableMessage) => {
  window.webkit?.messageHandlers?.careAndMessageHandler?.postMessage(msg);
};

/**
 * Sends message to Android Native App
 * @requires Device to have registered messageHandler through the Android WebView api
 */
const androidMessageHandler = (msg: SerializableMessage) => {
  let parsedMessage;
  if (
    typeof msg === 'number' ||
    typeof msg === 'bigint' ||
    typeof msg === 'boolean' ||
    typeof msg === 'undefined'
  ) {
    parsedMessage = `${msg}`;
  } else if (typeof msg === 'string') {
    parsedMessage = msg;
  } else if (typeof msg === 'object') {
    parsedMessage = JSON.stringify(msg);
  } else {
    throw new Error(`Android does not support passing messages of type: ${typeof msg}`);
  }
  Android.postMessage(parsedMessage);
};

/**
 * Handles communication with Native Devices
 */
export class Device {
  static async openFile(
    authenticationToken: string,
    request: RequestOptions,
    filename?: string,
    options: { timeout?: number } = {},
  ) {
    const Authorization = `Bearer ${authenticationToken}`;
    const requestWithAuth = { ...request, headers: { ...request.headers, Authorization } };

    const msg = createOpenFileMessage(requestWithAuth, filename);

    this.postMessage(msg);

    return waitForReply(msg, options.timeout);
  }

  static requestSetup(): boolean {
    const msg = createRequestSetupMessage();

    return this.postMessage(msg);
  }

  static requestToStoreUser(user: StoredUser, overwrite = false) {
    const msg = createRequestStoreUserMessage(user, overwrite);

    const promise = waitForCustomReply(msg, 5_000, (reply: unknown) => {
      const result = RequestStoredUsersMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === msg.id;
    })
      .then(RequestStoredUsersMessageSchema.parse)
      .then((it) => it.users)
      .catch(() => undefined);

    this.postMessage(msg);
    return promise;
  }

  static requestToDeleteUser(userId: string) {
    const msg = createRequestDeleteUserMessage(userId);

    this.postMessage(msg);
  }

  static requestOTPCode(data: { token?: string; username?: string }) {
    const msg = createRequestOTPCodeMessage(data);
    const timeout = 5_000;

    const promise = waitForCustomReply(msg, timeout, (reply: unknown) => {
      const result = RequestOTPCodeMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === msg.id;
    })
      .then(RequestOTPCodeMessageSchema.parse)
      .then((it) => ({ code: it.code }))
      .catch(() => undefined);

    this.postMessage(msg);

    return promise;
  }

  static requestActiveUserId() {
    const msg = createRequestActiveUserIdMessage(undefined, false);
    const timeout = 5_000;

    const promise = waitForCustomReply(msg, timeout, (reply: unknown) => {
      const result = RequestActiveUserIdMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === msg.id;
    })
      .then(RequestActiveUserIdMessageSchema.parse)
      .then((it) => it.userId)
      .catch(() => undefined);

    this.postMessage(msg);

    return promise;
  }

  static requestSetActiveUserId(userId: string | undefined) {
    const msg = createRequestActiveUserIdMessage(userId, true);

    this.postMessage(msg);
  }

  static requestStoredUserCredentials(userId: string) {
    const msg = createRequestStoredUserCredentialsMessage(userId);
    const timeout = 5_000;

    const promise = waitForCustomReply(msg, timeout, (reply: unknown) => {
      const result = RequestStoredUserCredentialsMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === msg.id;
    })
      .then(RequestStoredUserCredentialsMessageSchema.parse)
      .then((it) => it.response)
      .catch(() => undefined);

    this.postMessage(msg);

    return promise;
  }

  static requestStoredUsersList() {
    const msg = createRequestStoredUsersMessage();
    const timeout = 5_000;

    const promise = waitForCustomReply(msg, timeout, (reply: unknown) => {
      const result = RequestStoredUsersMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === msg.id;
    })
      .then(RequestStoredUsersMessageSchema.parse)
      .then((it) => it.users)
      .catch(() => undefined);

    this.postMessage(msg);

    return promise;
  }

  static requestPermission(permission: DevicePermission, options: { timeout?: number } = {}) {
    const msg = createRequestPermissionMessage(permission);

    const promise = waitForReply(msg, options.timeout);

    this.postMessage(msg);

    return promise;
  }

  static requestAppleHealthReadPermission(metrics: MetricTypeAppleHealth[]) {
    const msg = createRequestAppleHealthPermissionMessageSchema(metrics);

    const promise = waitForReply(msg, 60_000);
    this.postMessage(msg);
    return promise;
  }

  static requestCredentials(): Promise<RequestCredentialsMessage['credentials']> {
    const request = createRequestCredentialsMessage();
    const timeout = 5_000;

    const promise = waitForCustomReply(request, timeout, (reply: unknown) => {
      const result = RequestCredentialsMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === request.id;
    })
      .then(RequestCredentialsMessageSchema.parse)
      .then((it) => it.credentials)
      .catch(() => undefined);

    this.postMessage(request);

    return promise;
  }

  static sendWebAppInfo(partialContent: Partial<WebAppInfoMessage['content']> = {}) {
    let content: WebAppInfoMessage['content'] = {
      biometricAuthentication: partialContent.biometricAuthentication ?? false,
      isLoggedIn: partialContent.isLoggedIn ?? false,
    };
    const msg = createWebAppInfoMessage(content);
    this.postMessage(msg);
  }

  static storeCredentials(credentials: RequestCredentialsMessage['credentials']) {
    const request = createStoreCredentialsMessage(credentials);
    console.log('clear credentials');
    this.postMessage(request);
  }

  static setAppleHealthPrimaryUser(user: string) {
    const msg = createSetAppleHealthPrimaryUserMessage(user);

    this.postMessage(msg);
  }

  static setPushTokenSent(sent: boolean) {
    const msg = createNotificationTokenSentMessage({ sent });
    this.postMessage(msg);
  }

  static async isPushTokenSent() {
    const msg = createNotificationTokenSentMessage({ request: true });

    const promise = waitForCustomReply(msg, 5_000, (reply: unknown) => {
      const result = NotificationTokenSentMessageSchema.safeParse(reply);
      if (!result.success) {
        return false;
      }
      return result.data.replyTo === msg.id;
    })
      .then(NotificationTokenSentMessageSchema.parse)
      .then((it) => it.sent ?? false);

    this.postMessage(msg);

    return promise;
  }

  static requestAppleHealthSync(
    userId: string,
    summaries: HealthRecordsMetricSummary[],
    metrics: MetricType[],
  ) {
    const msg = createRequestAppleHealthSyncMessage(userId, summaries, metrics);

    const promise = waitForCustomReply(msg, 120_000, (msg: unknown) => {
      return (msg as any)?.type === 'requestAppleHealthSyncResult';
    }) as Promise<RequestAppleHealthSyncResultMessage>;
    this.postMessage(msg);
    return promise;
  }

  static showTelemedicine(jitsiPayload?: JitsiMeetingPayload) {
    const msg = createShowTelemedicineMessage(jitsiPayload);

    return this.postMessage(msg);
  }

  static sendUnreadCountUpdate(count: number) {
    if (count < 0) {
      return false;
    }
    const msg = createUnreadCountUpdatedMessage(count);
    return this.postMessage(msg);
  }

  static requestPushToken() {
    const msg = createNotificationTokenRequest();
    const promise = waitForCustomReply(msg, 2_000, (reply) => {
      const result = NotificationTokenUpdatedMessageSchema.safeParse(reply);
      return result.success && result.data.replyTo === msg.id;
    })
      .then(NotificationTokenUpdatedMessageSchema.parse)
      .then((it) => it.token);

    this.postMessage(msg);
    return promise;
  }

  /**
   *  Sends a message to a Native Device if registered
   */
  private static postMessage(msg: SerializableMessage) {
    const messageHandler = Device.getMessageHandler();

    if (messageHandler) {
      messageHandler(msg);
    }

    if (!!messageHandler && !IS_PRODUCTION_BUILD) {
      console.log('[NativeHandler] sent =>', msg);
    }

    return !!messageHandler;
  }

  static isIOS() {
    try {
      return !!window.webkit?.messageHandlers?.careAndMessageHandler;
    } catch (e: unknown) {
      return false;
    }
  }

  static isAndroid() {
    try {
      return !!Android;
    } catch (e: unknown) {
      return false;
    }
  }

  static isMobile() {
    return Device.isIOS() || Device.isAndroid();
  }

  static getDeviceType(): DeviceTokenDeviceTypeEnum | undefined {
    if (Device.isIOS()) {
      return DeviceTokenDeviceTypeEnum.Ios;
    }
    if (Device.isAndroid()) {
      return DeviceTokenDeviceTypeEnum.Android;
    }
    return undefined;
  }

  private static getMessageHandler() {
    if (Device.isAndroid()) {
      return androidMessageHandler;
    }
    if (Device.isIOS()) {
      return iOSMessageHandler;
    }
  }
}

export async function waitForReply(originalMessage: NativeMessage, timeout?: number) {
  const start = Date.now();
  return new Promise<StatusReplyMessage>((resolve, reject) => {
    let hasResolved = false;

    if (timeout !== undefined) {
      setTimeout(() => {
        if (!hasResolved) {
          hasResolved = true;
          reject(new Error('native message reply timeout'));
        }
      }, timeout);
    }

    const callback = (msg: unknown) => {
      let reply = StatusReplyMessageSchema.safeParse(msg);
      if (reply.success && reply.data.replyTo === originalMessage.id) {
        return reply;
      }
    };
    function listener(event: Event) {
      let now = Date.now();
      if (timeout !== undefined && start + timeout < now) {
        if (!IS_PRODUCTION_BUILD) {
          console.log('[native reply]', originalMessage, 'timedout');
        }
        reject(new Error('native message timeout'));
        hasResolved = true;
        return;
      }
      if (event instanceof CustomEvent) {
        const reply = callback(event.detail);
        if (reply?.success) {
          if (!IS_PRODUCTION_BUILD) {
            console.log('[native reply]', event.detail, 'resolved');
          }
          resolve(reply.data);
          hasResolved = true;
          return;
        }
      }
      if (!IS_PRODUCTION_BUILD) {
        console.log('[native reply]', originalMessage, 're-registered');
      }
      window.addEventListener('nativeEvent', listener, { once: true });
    }

    window.addEventListener('nativeEvent', listener, { once: true });
  });
}

export async function waitForCustomReply(
  originalMessage: NativeMessage,
  timeout: number | undefined,
  predicate: (message: unknown) => boolean,
) {
  const start = Date.now();

  return new Promise<unknown>((resolve, reject) => {
    let hasResolved = false;

    if (timeout !== undefined) {
      setTimeout(() => {
        if (!hasResolved) {
          hasResolved = true;
          reject(new Error('native message reply timeout'));
        }
      }, timeout);
    }

    function listener(event: Event) {
      let now = Date.now();
      if (timeout !== undefined && start + timeout < now) {
        if (!IS_PRODUCTION_BUILD) {
          console.log('[native reply]', originalMessage, 'timedout');
        }
        reject(new Error('native message timeout'));
        hasResolved = true;
        return;
      }
      if (event instanceof CustomEvent) {
        const accept = predicate(event.detail);
        if (accept) {
          if (!IS_PRODUCTION_BUILD) {
            console.log('[native custom reply]', event.detail, 'resolved');
          }
          resolve(event.detail);
          hasResolved = true;
          return;
        }
      }
      if (!IS_PRODUCTION_BUILD) {
        console.log('[native custom reply]', originalMessage, 're-registered');
      }
      window.addEventListener('nativeEvent', listener, { once: true });
    }

    window.addEventListener('nativeEvent', listener, { once: true });
  });
}

/*
 * Adds window.careand.debug.*
 * functions to help with development and debugging
 */
if (config.ENVIRONMENT === 'development') {
  function emitNativeEvent(data: any) {
    const event = new CustomEvent('nativeEvent', { detail: data });
    window.dispatchEvent(event);
  }
  function emitPush(content: any) {
    const message = {
      id: `${Math.random()}`,
      type: 'pushNotificationReceived',
      content,
    };
    emitNativeEvent(message);
  }
  function simulateAndroid() {
    //@ts-ignore
    Device.isAndroid = function () {
      return true;
    };
    //@ts-ignore
    Device.isIOS = function () {
      return false;
    };
    //@ts-ignore
    Device.getMessageHandler = function () {
      return () => {};
    };
  }
  function simulateIOS() {
    //@ts-ignore
    Device.isAndroid = function () {
      return false;
    };
    //@ts-ignore
    Device.isIOS = function () {
      return true;
    };
    //@ts-ignore
    Device.getMessageHandler = function () {
      return () => {};
    };
  }
  //@ts-ignore
  window.careand = {
    debug: {
      emitNativeEvent,
      simulateAndroid,
      simulateIOS,
      emitPush,
    },
  };
}
