/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pharmacy } from './Pharmacy';
import {
    PharmacyFromJSON,
    PharmacyFromJSONTyped,
    PharmacyToJSON,
} from './Pharmacy';
import type { PrescriptionMedication } from './PrescriptionMedication';
import {
    PrescriptionMedicationFromJSON,
    PrescriptionMedicationFromJSONTyped,
    PrescriptionMedicationToJSON,
} from './PrescriptionMedication';

/**
 * 
 * @export
 * @interface PrescriptionRefill
 */
export interface PrescriptionRefill {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    status?: PrescriptionRefillStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    prescription_id: string;
    /**
     * 
     * @type {Array<PrescriptionMedication>}
     * @memberof PrescriptionRefill
     */
    medications: Array<PrescriptionMedication>;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionRefill
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionRefill
     */
    physician_note?: string;
    /**
     * 
     * @type {Pharmacy}
     * @memberof PrescriptionRefill
     */
    pharmacy?: Pharmacy;
}


/**
 * @export
 */
export const PrescriptionRefillStatusEnum = {
    Pending: 'pending',
    Completed: 'completed',
    Rejected: 'rejected'
} as const;
export type PrescriptionRefillStatusEnum = typeof PrescriptionRefillStatusEnum[keyof typeof PrescriptionRefillStatusEnum];


/**
 * Check if a given object implements the PrescriptionRefill interface.
 */
export function instanceOfPrescriptionRefill(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "prescription_id" in value;
    isInstance = isInstance && "medications" in value;

    return isInstance;
}

export function PrescriptionRefillFromJSON(json: any): PrescriptionRefill {
    return PrescriptionRefillFromJSONTyped(json, false);
}

export function PrescriptionRefillFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionRefill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': json['patient_id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'prescription_id': json['prescription_id'],
        'medications': ((json['medications'] as Array<any>).map(PrescriptionMedicationFromJSON)),
        'date': !exists(json, 'date') ? undefined : json['date'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'physician_note': !exists(json, 'physician_note') ? undefined : json['physician_note'],
        'pharmacy': !exists(json, 'pharmacy') ? undefined : PharmacyFromJSON(json['pharmacy']),
    };
}

export function PrescriptionRefillToJSON(value?: PrescriptionRefill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'physician_id': value.physician_id,
        'clinic_id': value.clinic_id,
        'status': value.status,
        'prescription_id': value.prescription_id,
        'medications': ((value.medications as Array<any>).map(PrescriptionMedicationToJSON)),
        'date': value.date,
        'note': value.note,
        'physician_note': value.physician_note,
        'pharmacy': PharmacyToJSON(value.pharmacy),
    };
}

