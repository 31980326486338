/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';

/**
 * 
 * @export
 * @interface InvoiceDiscount
 */
export interface InvoiceDiscount {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscount
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscount
     */
    object?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscount
     */
    customer?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDiscount
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDiscount
     */
    end?: number;
    /**
     * 
     * @type {Coupon}
     * @memberof InvoiceDiscount
     */
    coupon?: Coupon;
}

/**
 * Check if a given object implements the InvoiceDiscount interface.
 */
export function instanceOfInvoiceDiscount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceDiscountFromJSON(json: any): InvoiceDiscount {
    return InvoiceDiscountFromJSONTyped(json, false);
}

export function InvoiceDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
        'coupon': !exists(json, 'coupon') ? undefined : CouponFromJSON(json['coupon']),
    };
}

export function InvoiceDiscountToJSON(value?: InvoiceDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'object': value.object,
        'customer': value.customer,
        'start': value.start,
        'end': value.end,
        'coupon': CouponToJSON(value.coupon),
    };
}

