/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Authentication,
  ErrorResponse,
  MinistryOHIP,
  MultipleValuesPayload,
  OHIPNumber,
  Patient,
  StringResource,
} from '../models';
import {
    AuthenticationFromJSON,
    AuthenticationToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MinistryOHIPFromJSON,
    MinistryOHIPToJSON,
    MultipleValuesPayloadFromJSON,
    MultipleValuesPayloadToJSON,
    OHIPNumberFromJSON,
    OHIPNumberToJSON,
    PatientFromJSON,
    PatientToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
} from '../models';

export interface ChangePhoneNumberRequest {
    id: string;
    phone: string;
    code: StringResource;
}

export interface CheckOhipNumberRequest {
    ohip?: OHIPNumber;
}

export interface ExportPatientsRequest {
    auth: Authentication;
}

export interface GetMultiplePatientsRequest {
    ids: string;
}

export interface GetMultiplePatients2Request {
    ids: MultipleValuesPayload;
}

export interface GetPatientRequest {
    id: string;
}

export interface GetPatientsRequest {
    search?: string;
    limit?: number;
    clinicId?: string;
}

export interface GetPhysicianPatientsRequest {
    physicianId: string;
    search?: string;
}

export interface MarkDeceasedRequest {
    patientId: string;
    date: number;
}

export interface RemoveOhipNumberRequest {
    patientId: string;
}

export interface SendSMSRequest {
    id: string;
    message: StringResource;
}

export interface TransferAccountRequest {
    from: string;
    to: string;
}

export interface UnlinkAccountsRequest {
    parentId: string;
    linkedId: string;
}

export interface UpdateOhipNumberRequest {
    patientId: string;
    ohip?: OHIPNumber;
}

export interface UpdatePatientRequest {
    id: string;
    patient: Patient;
}

/**
 * 
 */
export class PatientsApi extends runtime.BaseAPI {

    /**
     * Confirms the phone number and changes it for a patient (Roles: patient)
     */
    async changePhoneNumberRaw(requestParameters: ChangePhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePhoneNumber.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling changePhoneNumber.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling changePhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/{id}/change-phone/{phone}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.code),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    changePhoneNumberRequestOptionsRaw(requestParameters: ChangePhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling changePhoneNumber.');
        }

        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling changePhoneNumber.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling changePhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/{id}/change-phone/{phone}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"phone"}}`, encodeURIComponent(String(requestParameters.phone))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.code),
        };
        return responseConfig
    }

    /**
     * Confirms the phone number and changes it for a patient (Roles: patient)
     */
    async changePhoneNumber(requestParameters: ChangePhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.changePhoneNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks a OHIP number (Roles: admin)
     */
    async checkOhipNumberRaw(requestParameters: CheckOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MinistryOHIP>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ohip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OHIPNumberToJSON(requestParameters.ohip),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MinistryOHIPFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    checkOhipNumberRequestOptionsRaw(requestParameters: CheckOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ohip`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: OHIPNumberToJSON(requestParameters.ohip),
        };
        return responseConfig
    }

    /**
     * Checks a OHIP number (Roles: admin)
     */
    async checkOhipNumber(requestParameters: CheckOhipNumberRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MinistryOHIP> {
        const response = await this.checkOhipNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports a CSV with patient information (Roles: admin)
     */
    async exportPatientsRaw(requestParameters: ExportPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling exportPatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
        Request Config Getter
     */
    exportPatientsRequestOptionsRaw(requestParameters: ExportPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling exportPatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/export`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationToJSON(requestParameters.auth),
        };
        return responseConfig
    }

    /**
     * Exports a CSV with patient information (Roles: admin)
     */
    async exportPatients(requestParameters: ExportPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Patients with the specified comma-separated ids (Roles: physician, admin)
     */
    async getMultiplePatientsRaw(requestParameters: GetMultiplePatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Patient>>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getMultiplePatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/multiple/{ids}`.replace(`{${"ids"}}`, encodeURIComponent(String(requestParameters.ids))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientFromJSON));
    }

    /**
        Request Config Getter
     */
    getMultiplePatientsRequestOptionsRaw(requestParameters: GetMultiplePatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getMultiplePatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/multiple/{ids}`.replace(`{${"ids"}}`, encodeURIComponent(String(requestParameters.ids))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns the Patients with the specified comma-separated ids (Roles: physician, admin)
     */
    async getMultiplePatients(requestParameters: GetMultiplePatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Patient>> {
        const response = await this.getMultiplePatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Patients with the specified comma-separated ids (Roles: physician, admin)
     */
    async getMultiplePatients2Raw(requestParameters: GetMultiplePatients2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Patient>>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getMultiplePatients2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientFromJSON));
    }

    /**
        Request Config Getter
     */
    getMultiplePatients2RequestOptionsRaw(requestParameters: GetMultiplePatients2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getMultiplePatients2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/multiple`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        };
        return responseConfig
    }

    /**
     * Returns the Patients with the specified comma-separated ids (Roles: physician, admin)
     */
    async getMultiplePatients2(requestParameters: GetMultiplePatients2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Patient>> {
        const response = await this.getMultiplePatients2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Patient with the specified id (Roles: patient, physician, admin)
     */
    async getPatientRaw(requestParameters: GetPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getPatientRequestOptionsRaw(requestParameters: GetPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns the Patient with the specified id (Roles: patient, physician, admin)
     */
    async getPatient(requestParameters: GetPatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.getPatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the Patients that the user has access to (Roles: patient, physician, admin)
     */
    async getPatientsRaw(requestParameters: GetPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Patient>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientsRequestOptionsRaw(requestParameters: GetPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all the Patients that the user has access to (Roles: patient, physician, admin)
     */
    async getPatients(requestParameters: GetPatientsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Patient>> {
        const response = await this.getPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Physician\'s patients (Roles: physician, admin)
     */
    async getPhysicianPatientsRaw(requestParameters: GetPhysicianPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Patient>>> {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling getPhysicianPatients.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/physician/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientFromJSON));
    }

    /**
        Request Config Getter
     */
    getPhysicianPatientsRequestOptionsRaw(requestParameters: GetPhysicianPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling getPhysicianPatients.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/physician/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns the Physician\'s patients (Roles: physician, admin)
     */
    async getPhysicianPatients(requestParameters: GetPhysicianPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Patient>> {
        const response = await this.getPhysicianPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks a patient as deceased (Roles: physician)
     */
    async markDeceasedRaw(requestParameters: MarkDeceasedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markDeceased.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling markDeceased.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patient/{patientId}/mark-deceased/{date}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    markDeceasedRequestOptionsRaw(requestParameters: MarkDeceasedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markDeceased.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling markDeceased.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patient/{patientId}/mark-deceased/{date}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Marks a patient as deceased (Roles: physician)
     */
    async markDeceased(requestParameters: MarkDeceasedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.markDeceasedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a patient\'s OHIP number (Roles: patient, admin, physician)
     */
    async removeOhipNumberRaw(requestParameters: RemoveOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removeOhipNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ohip/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    removeOhipNumberRequestOptionsRaw(requestParameters: RemoveOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removeOhipNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ohip/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a patient\'s OHIP number (Roles: patient, admin, physician)
     */
    async removeOhipNumber(requestParameters: RemoveOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.removeOhipNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends an SMS to the patient (Roles: physician, admin)
     */
    async sendSMSRaw(requestParameters: SendSMSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendSMS.');
        }

        if (requestParameters.message === null || requestParameters.message === undefined) {
            throw new runtime.RequiredError('message','Required parameter requestParameters.message was null or undefined when calling sendSMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/{id}/sms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.message),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendSMSRequestOptionsRaw(requestParameters: SendSMSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendSMS.');
        }

        if (requestParameters.message === null || requestParameters.message === undefined) {
            throw new runtime.RequiredError('message','Required parameter requestParameters.message was null or undefined when calling sendSMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/{id}/sms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.message),
        };
        return responseConfig
    }

    /**
     * Sends an SMS to the patient (Roles: physician, admin)
     */
    async sendSMS(requestParameters: SendSMSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendSMSRaw(requestParameters, initOverrides);
    }

    /**
     * Transfers a patient\'s account information to another (Roles: admin)
     */
    async transferAccountRaw(requestParameters: TransferAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling transferAccount.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling transferAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patient/transfer-account/{from}/{to}`.replace(`{${"from"}}`, encodeURIComponent(String(requestParameters.from))).replace(`{${"to"}}`, encodeURIComponent(String(requestParameters.to))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    transferAccountRequestOptionsRaw(requestParameters: TransferAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling transferAccount.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling transferAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patient/transfer-account/{from}/{to}`.replace(`{${"from"}}`, encodeURIComponent(String(requestParameters.from))).replace(`{${"to"}}`, encodeURIComponent(String(requestParameters.to))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Transfers a patient\'s account information to another (Roles: admin)
     */
    async transferAccount(requestParameters: TransferAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.transferAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unlinks accounts (patient, admin)
     */
    async unlinkAccountsRaw(requestParameters: UnlinkAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.parentId === null || requestParameters.parentId === undefined) {
            throw new runtime.RequiredError('parentId','Required parameter requestParameters.parentId was null or undefined when calling unlinkAccounts.');
        }

        if (requestParameters.linkedId === null || requestParameters.linkedId === undefined) {
            throw new runtime.RequiredError('linkedId','Required parameter requestParameters.linkedId was null or undefined when calling unlinkAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/{parentId}/linked-accounts/{linkedId}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters.parentId))).replace(`{${"linkedId"}}`, encodeURIComponent(String(requestParameters.linkedId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    unlinkAccountsRequestOptionsRaw(requestParameters: UnlinkAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.parentId === null || requestParameters.parentId === undefined) {
            throw new runtime.RequiredError('parentId','Required parameter requestParameters.parentId was null or undefined when calling unlinkAccounts.');
        }

        if (requestParameters.linkedId === null || requestParameters.linkedId === undefined) {
            throw new runtime.RequiredError('linkedId','Required parameter requestParameters.linkedId was null or undefined when calling unlinkAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/{parentId}/linked-accounts/{linkedId}`.replace(`{${"parentId"}}`, encodeURIComponent(String(requestParameters.parentId))).replace(`{${"linkedId"}}`, encodeURIComponent(String(requestParameters.linkedId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Unlinks accounts (patient, admin)
     */
    async unlinkAccounts(requestParameters: UnlinkAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.unlinkAccountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a patient\'s OHIP number (Roles: patient, admin, physician)
     */
    async updateOhipNumberRaw(requestParameters: UpdateOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updateOhipNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ohip/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OHIPNumberToJSON(requestParameters.ohip),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateOhipNumberRequestOptionsRaw(requestParameters: UpdateOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updateOhipNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/ohip/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: OHIPNumberToJSON(requestParameters.ohip),
        };
        return responseConfig
    }

    /**
     * Updates a patient\'s OHIP number (Roles: patient, admin, physician)
     */
    async updateOhipNumber(requestParameters: UpdateOhipNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.updateOhipNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the Patient with the specified id (Roles: patient, physician, admin)
     */
    async updatePatientRaw(requestParameters: UpdatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePatient.');
        }

        if (requestParameters.patient === null || requestParameters.patient === undefined) {
            throw new runtime.RequiredError('patient','Required parameter requestParameters.patient was null or undefined when calling updatePatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PatientToJSON(requestParameters.patient),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updatePatientRequestOptionsRaw(requestParameters: UpdatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePatient.');
        }

        if (requestParameters.patient === null || requestParameters.patient === undefined) {
            throw new runtime.RequiredError('patient','Required parameter requestParameters.patient was null or undefined when calling updatePatient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientToJSON(requestParameters.patient),
        };
        return responseConfig
    }

    /**
     * Updates the Patient with the specified id (Roles: patient, physician, admin)
     */
    async updatePatient(requestParameters: UpdatePatientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.updatePatientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
