import { z } from 'zod'
import { createReply, NativeMessage, NativeMessageSchema } from './NativeMessage'

export const StatusReplyMessageSchema = z.object({
  type: z.literal('statusReply'),
  content: z.object({
    success: z.boolean(),
    error: z.string().optional()
  })
}).extend(NativeMessageSchema.shape)

export type StatusReplyMessage = z.infer<typeof StatusReplyMessageSchema>

export const createStatusReplyMessage = (originalMessage: NativeMessage, success: boolean, error?: string) => {
  return {
    ...createReply(originalMessage),
    type: 'statusReply',
    content: {
      success,
      error
    }
  } satisfies StatusReplyMessage
}