import { PayloadAction } from '@reduxjs/toolkit';
import { Appointment } from 'generated/api';
import { endpointIsLoading } from 'careand-redux/actions/endpointLoading';
import PerformanceUtils from 'services/PerformanceUtils';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { showOldVersion } from 'careand-redux/actions/showOldVersion';
import { unwrap } from 'careand-redux/utils/unwrap';

export type HomeMode = 'home' | 'search';

export interface UIState {
  screenTitle?: string;
  loading: boolean;
  loadingData: number;
  loadingEndpoints: Record<string, boolean | undefined>;
  oldVersion: boolean;
  sideMenuActive: boolean;
  bubbles: Bubble[];
  activeBubble?: Bubble;
  pinned: Record<string, PinnedDocument[] | undefined>;
  activeLinkedAccount: Record<string, string | undefined>;
  phone?: {
    number: string;
    patientId: string;
  };
  homeMode: HomeMode;
  homeSearch: string;
  reorderingTabBar: boolean;
  popupRegistry: Partial<Record<string, boolean>>;
  uiOptions: string[];
  assistant: boolean;
  assistantUnread: number;
  assistantMessage?: string
}

type PinnedTemplate = {
  type: 'template';
  id: string;
  noteForm: string;
  documentId: string;
  title?: string;
  overrideTitle?: string;
  defaultSavedDocumentCategory?: string;
  overrideId?: string;
};
type PinnedPatientDocument = {
  type: 'document';
  patientId: string;
  id: string;
};
type PinnedVitals = {
  type: 'vitals_monitor';
  id: 'vitals';
  patientId: string;
};
type PinnedSendMultipleDocuments = {
  type: 'send_multiple_documents';
  patientId: string;
};

type PinnedReqsList = {
  type: 'reqs_list';
  patientId: string;
};

export type PinnedDocument =
  | PinnedTemplate
  | PinnedPatientDocument
  | PinnedVitals
  | PinnedSendMultipleDocuments
  | PinnedReqsList;

type PinnedDocumentAction = PinnedDocument & { patientId: string };

interface RemovePinnedDocumentAction {
  patientId: string;
  id: string;
}

export interface Bubble {
  id: string;
  chatId?: string;
  appointment_id?: string;
  appointment_type?: string;
  patient_id?: string;
  booked_by?: string;
  patient_in_room?: boolean;
  appointment?: Appointment;
  description?: string;
  type?: string;
  chat?: boolean;
  assistant?: boolean;
  date?: number;
  payload?: BubblePayload;
  token?: string;
  manual?: boolean;
}

export interface BubblePayload {
  room?: string;
}

export interface ToggleAssistantPayload {
  assistant: boolean;
  assistantMessage?: string;
}

export type Phone = Required<UIState>['phone'];

const initialState: UIState = {
  loading: false,
  loadingData: 0,
  loadingEndpoints: {},
  oldVersion: false,
  sideMenuActive: false,
  bubbles: [],
  pinned: {},
  activeLinkedAccount: {},
  homeMode: 'home',
  homeSearch: '',
  reorderingTabBar: false,
  uiOptions: [],
  popupRegistry: {},
  assistant: false,
  assistantUnread: 0,
  assistantMessage: ''
};

const uiSlice = createResettableSlice({
  name: 'UI',
  initialState,
  reducers: {
    toggleUIOption: unwrap((state, value: string) => {
      let exists = state.uiOptions.indexOf(value) > -1;
      if (exists) {
        state.uiOptions = state.uiOptions.filter((val) => val !== value);
      } else {
        state.uiOptions.push(value);
      }
    }),
    setAssistantUnread: unwrap((state, value: number) => {
      state.assistantUnread = value;
    }),
    toggleAssistant: ((state, action: PayloadAction<ToggleAssistantPayload>) => {
      let {assistant, assistantMessage} = action.payload;
      state.assistant = assistant !== undefined ? assistant : !state.assistant;
      state.assistantMessage = assistantMessage;
    }),
    setReorderingTabBar: unwrap((state, reordering: boolean) => {
      state.reorderingTabBar = reordering;
    }),
    toggleReorderingTabBar: (state) => {
      state.reorderingTabBar = !state.reorderingTabBar;
    },
    updateHomeSearch: (state: UIState, action: PayloadAction<string>) => {
      state.homeSearch = action.payload;
    },
    setHomeMode: (state, action: PayloadAction<HomeMode>) => {
      state.homeMode = action.payload;
    },
    setBubbles: (state, action: PayloadAction<Bubble[]>) => {
      state.bubbles = action.payload;
    },
    setActiveBubble: (state, action: PayloadAction<Bubble>) => {
      state.activeBubble = action.payload;
    },
    setActiveLinkedAccount: (state, action: PayloadAction<string>) => {
      state.activeLinkedAccount[state.activeBubble!.id] = action.payload;
    },
    setScreenTitle: (state, action: PayloadAction<string>) => {
      state.screenTitle = action.payload;
    },
    removePinnedDocument: (state, action: PayloadAction<RemovePinnedDocumentAction>) => {
      const { id, patientId } = action.payload;
      state.pinned[patientId] = PerformanceUtils.removeFromArray({ id }, state.pinned[patientId]);
    },
    addPinnedDocument: (state: UIState, action: PayloadAction<PinnedDocumentAction>) => {
      const payload = action.payload;
      state.pinned[payload.patientId] = PerformanceUtils.editOrAdd(
        action.payload,
        state.pinned[payload.patientId],
      );
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingData: (state, action: PayloadAction<boolean>) => {
      state.loadingData += action.payload ? 1 : -1;
    },
    setSideMenu: (state, action: PayloadAction<boolean>) => {
      state.sideMenuActive = action.payload;
    },
    toggleSideMenu: (state) => {
      state.sideMenuActive = !state.sideMenuActive;
    },
    openPhoneScreen: (state, action: PayloadAction<Phone>) => {
      state.phone = action.payload;
    },
    closePhoneScreen: (state) => {
      state.phone = undefined;
    },
    popupToggled: (state, action: PayloadAction<{ popup: string; open?: boolean }>) => {
      const { popup, open } = action.payload;
      if (typeof open === undefined) {
        state.popupRegistry[popup] = !(state.popupRegistry[popup] ?? false);
      } else {
        state.popupRegistry[popup] = open;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(endpointIsLoading, (state, action) => {
        const isLoading = action.payload.loading ?? true;
        state.loadingData += isLoading ? 1 : -1;
        if (isLoading) {
          state.loadingEndpoints[action.payload.name] = isLoading;
        } else {
          delete state.loadingEndpoints[action.payload.name];
        }
      })
      .addCase(showOldVersion, (state) => {
        state.oldVersion = true;
      });
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
