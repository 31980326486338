import _ from "lodash"
import { Fax, GetFaxesByStatusStatusEnum } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrap, unwrapApi } from "careand-redux/utils/unwrap"
import MiscUtils from "services/MiscUtils"
import PerformanceUtils from "services/PerformanceUtils"

export interface FaxState {
  failed: Fax[]
  sent: Fax[]
  queued: Fax[]
  submitted: Fax[]
  patients: Record<string, Fax[] | undefined>
  ignoredFailedFaxes: string[],
  ignoredSubmittedFaxes: string[],
}

const initialState: FaxState = {
  failed: [],
  sent: [],
  queued: [],
  submitted: [],
  patients: {},
  ignoredFailedFaxes: [],
  ignoredSubmittedFaxes: [],
}

const FAX_STATUS_TO_INDEX = {
  [GetFaxesByStatusStatusEnum.Queued]: 'queued',
  [GetFaxesByStatusStatusEnum.Submitted]: 'submitted',
  [GetFaxesByStatusStatusEnum.Sent]: 'sent',
  [GetFaxesByStatusStatusEnum.SendingFailed]: 'failed',
} as const

const slice = createResettableSlice({
  name: 'Fax',
  initialState,
  reducers: {
    receiveUpdatedFax: unwrap(editOrAddFax),
    addIgnoredFailedFaxes: unwrap((state, faxes: Fax[]) => {
      state.ignoredFailedFaxes = _.uniq([...state.ignoredFailedFaxes, ...faxes.map(it => it.id)])
    }),
    addIgnoredSubmittedFaxes: unwrap((state, faxes: Fax[]) => {
      state.ignoredSubmittedFaxes = _.uniq([...state.ignoredSubmittedFaxes, ...faxes.map(it => it.id)])
    })
  },
  extraReducers: builder => builder
    .addCase(api.Fax.getFaxesByStatus.fulfilled, unwrapApi((state, data, req) => {
      const index = FAX_STATUS_TO_INDEX[req.status]
      state[index] = data
    }))
    .addCase(api.Fax.getPatientFaxes.fulfilled, unwrapApi((state, faxes, req) => {
      state.patients[req.patientId] = faxes;
    }))
    .addCase(api.Fax.sendFileFax.fulfilled, unwrapApi((state, fax) => {
      MiscUtils.playSentDocumentSound()
      editOrAddFax(state, fax)
    }))
    .addCase(api.Fax.faxMultipleDocuments.fulfilled, unwrapApi((state, fax) => {
      MiscUtils.playSentDocumentSound()
      editOrAddFax(state, fax)
    }))
    .addCase(api.Fax.resendFax.fulfilled, unwrapApi((state, fax, req) => {
      MiscUtils.playSentDocumentSound()
      PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state.failed)
    }))
    .addCase(api.Fax.archiveFax.fulfilled, unwrapApi((state, fax) => {
      MiscUtils.playSentDocumentSound()
      editOrAddFax(state, fax)
    }))
})

function editOrAddFax(state: FaxState, fax: Fax) {
  // TODO if the fax is exclusively in one place there's no need to try to remove from the others
  // OR, we could just check fax.state_history and remove from there
  PerformanceUtils.removeFromArrayInPlace(fax, state.failed)
  PerformanceUtils.removeFromArrayInPlace(fax, state.sent)
  PerformanceUtils.removeFromArrayInPlace(fax, state.queued)
  PerformanceUtils.removeFromArrayInPlace(fax, state.submitted)

  let index = FAX_STATUS_TO_INDEX[fax.status];
  PerformanceUtils.editOrAdd(fax, state[index]);

  if (state.patients[fax.patient_id] === undefined) {
    state.patients[fax.patient_id] = []
  }

  PerformanceUtils.upsert(fax, state.patients[fax.patient_id]!)
}

export const faxActions = slice.actions
export default slice.reducer
