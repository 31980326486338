/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PhysicianContact } from './PhysicianContact';
import {
    PhysicianContactFromJSON,
    PhysicianContactFromJSONTyped,
    PhysicianContactToJSON,
} from './PhysicianContact';
import type { TaskComment } from './TaskComment';
import {
    TaskCommentFromJSON,
    TaskCommentFromJSONTyped,
    TaskCommentToJSON,
} from './TaskComment';

/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    clinic_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    urgent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    text?: string;
    /**
     * 
     * @type {PhysicianContact}
     * @memberof Referral
     */
    send_to?: PhysicianContact;
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    date_overdue?: number;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    sent_by?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Referral
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    waiting_approval?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Referral
     */
    hide?: boolean;
    /**
     * 
     * @type {Array<TaskComment>}
     * @memberof Referral
     */
    referral_comments?: Array<TaskComment>;
}

/**
 * Check if a given object implements the Referral interface.
 */
export function instanceOfReferral(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;

    return isInstance;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'patient_id': json['patient_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'urgent': !exists(json, 'urgent') ? undefined : json['urgent'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'send_to': !exists(json, 'send_to') ? undefined : PhysicianContactFromJSON(json['send_to']),
        'date': !exists(json, 'date') ? undefined : json['date'],
        'date_overdue': !exists(json, 'date_overdue') ? undefined : json['date_overdue'],
        'sent_by': !exists(json, 'sent_by') ? undefined : json['sent_by'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'waiting_approval': !exists(json, 'waiting_approval') ? undefined : json['waiting_approval'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'referral_comments': !exists(json, 'referral_comments') ? undefined : ((json['referral_comments'] as Array<any>).map(TaskCommentFromJSON)),
    };
}

export function ReferralToJSON(value?: Referral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'physician_id': value.physician_id,
        'patient_id': value.patient_id,
        'clinic_id': value.clinic_id,
        'urgent': value.urgent,
        'text': value.text,
        'send_to': PhysicianContactToJSON(value.send_to),
        'date': value.date,
        'date_overdue': value.date_overdue,
        'sent_by': value.sent_by,
        'attachments': value.attachments,
        'waiting_approval': value.waiting_approval,
        'hide': value.hide,
        'referral_comments': value.referral_comments === undefined ? undefined : ((value.referral_comments as Array<any>).map(TaskCommentToJSON)),
    };
}

