/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MTReminder } from './MTReminder';
import {
    MTReminderFromJSON,
    MTReminderFromJSONTyped,
    MTReminderToJSON,
} from './MTReminder';
import type { MTStatsDescription } from './MTStatsDescription';
import {
    MTStatsDescriptionFromJSON,
    MTStatsDescriptionFromJSONTyped,
    MTStatsDescriptionToJSON,
} from './MTStatsDescription';

/**
 * 
 * @export
 * @interface MTProfile
 */
export interface MTProfile {
    /**
     * 
     * @type {number}
     * @memberof MTProfile
     */
    estimated_luteal_phase_length?: number;
    /**
     * 
     * @type {number}
     * @memberof MTProfile
     */
    average_cycle_length?: number;
    /**
     * 
     * @type {number}
     * @memberof MTProfile
     */
    average_menstruation_length?: number;
    /**
     * 
     * @type {number}
     * @memberof MTProfile
     */
    estimated_fertility_phase_length?: number;
    /**
     * 
     * @type {MTStatsDescription}
     * @memberof MTProfile
     */
    flow_duration?: MTStatsDescription;
    /**
     * 
     * @type {MTStatsDescription}
     * @memberof MTProfile
     */
    cycle_regularity?: MTStatsDescription;
    /**
     * 
     * @type {MTStatsDescription}
     * @memberof MTProfile
     */
    cycle_length?: MTStatsDescription;
    /**
     * 
     * @type {Array<MTReminder>}
     * @memberof MTProfile
     */
    reminders?: Array<MTReminder>;
}

/**
 * Check if a given object implements the MTProfile interface.
 */
export function instanceOfMTProfile(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MTProfileFromJSON(json: any): MTProfile {
    return MTProfileFromJSONTyped(json, false);
}

export function MTProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): MTProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimated_luteal_phase_length': !exists(json, 'estimated_luteal_phase_length') ? undefined : json['estimated_luteal_phase_length'],
        'average_cycle_length': !exists(json, 'average_cycle_length') ? undefined : json['average_cycle_length'],
        'average_menstruation_length': !exists(json, 'average_menstruation_length') ? undefined : json['average_menstruation_length'],
        'estimated_fertility_phase_length': !exists(json, 'estimated_fertility_phase_length') ? undefined : json['estimated_fertility_phase_length'],
        'flow_duration': !exists(json, 'flow_duration') ? undefined : MTStatsDescriptionFromJSON(json['flow_duration']),
        'cycle_regularity': !exists(json, 'cycle_regularity') ? undefined : MTStatsDescriptionFromJSON(json['cycle_regularity']),
        'cycle_length': !exists(json, 'cycle_length') ? undefined : MTStatsDescriptionFromJSON(json['cycle_length']),
        'reminders': !exists(json, 'reminders') ? undefined : ((json['reminders'] as Array<any>).map(MTReminderFromJSON)),
    };
}

export function MTProfileToJSON(value?: MTProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimated_luteal_phase_length': value.estimated_luteal_phase_length,
        'average_cycle_length': value.average_cycle_length,
        'average_menstruation_length': value.average_menstruation_length,
        'estimated_fertility_phase_length': value.estimated_fertility_phase_length,
        'flow_duration': MTStatsDescriptionToJSON(value.flow_duration),
        'cycle_regularity': MTStatsDescriptionToJSON(value.cycle_regularity),
        'cycle_length': MTStatsDescriptionToJSON(value.cycle_length),
        'reminders': value.reminders === undefined ? undefined : ((value.reminders as Array<any>).map(MTReminderToJSON)),
    };
}

