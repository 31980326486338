import { z } from "zod"
import { createMessage, NativeMessageSchema } from "./NativeMessage"

export const UnreadCountUpdatedMessageSchema = z.object({
  type: z.literal('unreadCountUpdated'),

  unreadCount: z.number()
}).extend(NativeMessageSchema.shape)

export type UnreadCountUpdatedMessage = z.infer<typeof UnreadCountUpdatedMessageSchema>

export function createUnreadCountUpdatedMessage(unreadCount: number) {
  return {
    ...createMessage(),
    type: 'unreadCountUpdated',
    unreadCount
  } satisfies UnreadCountUpdatedMessage
}