import { PayloadAction } from "@reduxjs/toolkit"
import { MinistryOHIP } from "generated/api"
import { createResettableSlice } from "careand-redux/utils/makeResettable"

export interface RegistrationState {
  ministry_ohip?: MinistryOHIP
  credentials?: RegistrationCredentials
  clinic_id?: string
  preferred_name?: string
  preferred_pronoun?: string
  track_id?: string,
  phone?: string
}

export interface RegistrationCredentials {
  username: string,
  password: string,
  email: string,
}

const initialState: RegistrationState = {}

const slice = createResettableSlice({
  name: 'Registration',
  initialState,
  reducers: {
    setTrackId: (state, action: PayloadAction<string>) => {
      state.track_id = action.payload
    },
    saveRegistrationCredentials: (state, action: PayloadAction<RegistrationCredentials>) => {
      state.credentials = action.payload
    },
    saveRegistrationPreferredName: (state, action: PayloadAction<string>) => {
      state.preferred_name = action.payload
    },
    saveRegistrationPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    saveRegistrationPreferredPronoun: (state, action: PayloadAction<string>) => {
      state.preferred_pronoun = action.payload
    },
    saveRegistrationClinic: (state, action: PayloadAction<string>) => {
      state.clinic_id = action.payload
    },
    saveRegistrationPersonalData: (state, action: PayloadAction<MinistryOHIP>) => {
      state.ministry_ohip = action.payload
    },
    registerPatientCompleted: () => initialState,
    registerLinkedAccountCompleted: () => initialState
  },
})

export const registrationActions = slice.actions
export default slice.reducer
