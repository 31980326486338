/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppSettings
 */
export interface AppSettings {
    /**
     * 
     * @type {number}
     * @memberof AppSettings
     */
    token_refresh?: number;
    /**
     * 
     * @type {number}
     * @memberof AppSettings
     */
    prescription_timeout?: number;
    /**
     * 
     * @type {number}
     * @memberof AppSettings
     */
    diagnostic_requisition_timeout?: number;
    /**
     * 
     * @type {number}
     * @memberof AppSettings
     */
    chat_text_cap?: number;
}

/**
 * Check if a given object implements the AppSettings interface.
 */
export function instanceOfAppSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppSettingsFromJSON(json: any): AppSettings {
    return AppSettingsFromJSONTyped(json, false);
}

export function AppSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token_refresh': !exists(json, 'token_refresh') ? undefined : json['token_refresh'],
        'prescription_timeout': !exists(json, 'prescription_timeout') ? undefined : json['prescription_timeout'],
        'diagnostic_requisition_timeout': !exists(json, 'diagnostic_requisition_timeout') ? undefined : json['diagnostic_requisition_timeout'],
        'chat_text_cap': !exists(json, 'chat_text_cap') ? undefined : json['chat_text_cap'],
    };
}

export function AppSettingsToJSON(value?: AppSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token_refresh': value.token_refresh,
        'prescription_timeout': value.prescription_timeout,
        'diagnostic_requisition_timeout': value.diagnostic_requisition_timeout,
        'chat_text_cap': value.chat_text_cap,
    };
}

