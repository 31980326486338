/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppSettings } from './AppSettings';
import {
    AppSettingsFromJSON,
    AppSettingsFromJSONTyped,
    AppSettingsToJSON,
} from './AppSettings';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
} from './Patient';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface PatientLoginPayload
 */
export interface PatientLoginPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientLoginPayload
     */
    token?: string;
    /**
     * 
     * @type {Patient}
     * @memberof PatientLoginPayload
     */
    user?: Patient;
    /**
     * 
     * @type {Subscription}
     * @memberof PatientLoginPayload
     */
    subscription?: Subscription;
    /**
     * 
     * @type {AppSettings}
     * @memberof PatientLoginPayload
     */
    settings?: AppSettings;
}

/**
 * Check if a given object implements the PatientLoginPayload interface.
 */
export function instanceOfPatientLoginPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatientLoginPayloadFromJSON(json: any): PatientLoginPayload {
    return PatientLoginPayloadFromJSONTyped(json, false);
}

export function PatientLoginPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientLoginPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user': !exists(json, 'user') ? undefined : PatientFromJSON(json['user']),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionFromJSON(json['subscription']),
        'settings': !exists(json, 'settings') ? undefined : AppSettingsFromJSON(json['settings']),
    };
}

export function PatientLoginPayloadToJSON(value?: PatientLoginPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'user': PatientToJSON(value.user),
        'subscription': SubscriptionToJSON(value.subscription),
        'settings': AppSettingsToJSON(value.settings),
    };
}

