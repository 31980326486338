import { combineReducers } from '@reduxjs/toolkit'
import ui from 'careand-redux/slices/ui/ui'
import immunizationDescriptions from 'careand-redux/slices/immunizationDescriptions'
import editedAppointmentEvents from 'careand-redux/slices/editedAppointments'
import patients from 'careand-redux/slices/patients'
import documents from 'careand-redux/slices/documents'
import resource from 'careand-redux/slices/resource'
import video from 'careand-redux/slices/ui/video'
import chat from 'careand-redux/slices/chat'
import corporations from 'careand-redux/slices/corporations'
import shifts from 'careand-redux/slices/shifts'
import fax from 'careand-redux/slices/fax'
import templates from 'careand-redux/slices/templates'
import authentication from 'careand-redux/slices/auth'
import payments from 'careand-redux/slices/payments'
import healthRecords from 'careand-redux/slices/healthRecords'
import appointments from 'careand-redux/slices/appointments'
import admins from 'careand-redux/slices/admins'
import physicians from 'careand-redux/slices/physicians'
import contacts from 'careand-redux/slices/contacts'
import pharmacies from 'careand-redux/slices/pharmacies'
import drugs from 'careand-redux/slices/drugs'
import invoices from 'careand-redux/slices/invoices'
import reminders from 'careand-redux/slices/reminders'
import tasks from 'careand-redux/slices/tasks'
import queue from 'careand-redux/slices/queue'
import booking from 'careand-redux/slices/booking'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as formReducer } from 'redux-form'
import registration from "careand-redux/slices/registration";
import device from 'careand-redux/slices/device'
import navigation from './slices/ui/navigation'

export const rootReducer = combineReducers({
  navigation,
  device,
  ui,
  immunizationDescriptions,
  editedAppointmentEvents,
  booking,
  registration,
  patients,
  documents,
  resource,
  video,
  chat,
  corporations,
  shifts,
  fax,
  templates,
  authentication,
  payments,
  healthRecords,
  appointments,
  admins,
  physicians,
  contacts,
  pharmacies,
  drugs,
  invoices,
  reminders,
  tasks,
  queue,
  form: formReducer,
  toastr: toastrReducer
})
