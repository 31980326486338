/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { ThunkDispatch } from "@reduxjs/toolkit";
import { ResponseError } from "generated/api";
import { AnyAction } from "redux";
import { receivedForbidden } from "careand-redux/actions/receivedForbidden";
import { receivedTwoFactorRequired } from "careand-redux/actions/receivedTwoFactorRequired";
import { showOldVersion } from "careand-redux/actions/showOldVersion";
import { tokenExpired } from "careand-redux/actions/tokenExpired";

// WARNING: cannot import RootState and AppDispatch because of dependency cycles
type ThunkErrorMiddleware = (error: unknown, getState: () => unknown, dispatch: ThunkDispatch<any, any, AnyAction>) => boolean

export const handleTokenExpired: ThunkErrorMiddleware = (error, getState, dispatch): boolean => {
  if (error instanceof ResponseError && error.response.status === 401) {
    dispatch(tokenExpired())
    return true
  }
  return false
}

export const handleError418: ThunkErrorMiddleware = (error, getState, dispatch): boolean => {
  if (error instanceof ResponseError && error.response.status === 418) {
    dispatch(showOldVersion())
    return true
  }
  return false
}

export const handleForbidden: ThunkErrorMiddleware = (error, getState, dispatch) => {
  if (error instanceof ResponseError && error.response.status === 403 && (getState() as any).authentication.role === 'patient') {
    dispatch(receivedForbidden());
    return true
  }
  return false
}

export const handle420: ThunkErrorMiddleware = (error, getState, dispatch) => {
  if (error instanceof ResponseError && error.response.status === 420) {
    dispatch(receivedTwoFactorRequired())
    return true
  }
  return false
}
/// Returns a ThunkMiddleware that runs middlewares until it finds one that returns true
export const firstOf = (...middlewares: ThunkErrorMiddleware[]): ThunkErrorMiddleware => {
  return (error, getState, dispatch) => {
    for (const m of middlewares) {
      if (m(error, getState, dispatch)) {
        return true
      }
    }
    return false
  }
}

export const defaultErrorMiddleware = firstOf(handleTokenExpired, handleError418, handleForbidden, handle420)

export default {}
