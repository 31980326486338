/* eslint-disable no-extend-native */

declare global {
  interface Promise<T> {
    peek(callback: (arg: T) => void): Promise<T>;
  }
}

Promise.prototype.peek = function <T>(this: Promise<T>, callback: (arg: T) => void) {
  return this.then((it) => {
    callback(it);
    return it;
  });
};

export default {};
