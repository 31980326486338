/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CallLog
 */
export interface CallLog {
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CallLog
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    physician_id: string;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    appointment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    role?: CallLogRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    status: CallLogStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    type: CallLogTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CallLog
     */
    device?: CallLogDeviceEnum;
}


/**
 * @export
 */
export const CallLogRoleEnum = {
    Patient: 'patient',
    Physician: 'physician'
} as const;
export type CallLogRoleEnum = typeof CallLogRoleEnum[keyof typeof CallLogRoleEnum];

/**
 * @export
 */
export const CallLogStatusEnum = {
    Started: 'started',
    Ringing: 'ringing',
    StoppedRinging: 'stopped_ringing',
    Declined: 'declined',
    Answered: 'answered',
    Connected: 'connected',
    Ended: 'ended',
    Error: 'error',
    Voicemail: 'voicemail'
} as const;
export type CallLogStatusEnum = typeof CallLogStatusEnum[keyof typeof CallLogStatusEnum];

/**
 * @export
 */
export const CallLogTypeEnum = {
    Phone: 'phone',
    Video: 'video'
} as const;
export type CallLogTypeEnum = typeof CallLogTypeEnum[keyof typeof CallLogTypeEnum];

/**
 * @export
 */
export const CallLogDeviceEnum = {
    Portal: 'portal',
    Ios: 'ios',
    Android: 'android',
    Unknown: 'unknown'
} as const;
export type CallLogDeviceEnum = typeof CallLogDeviceEnum[keyof typeof CallLogDeviceEnum];


/**
 * Check if a given object implements the CallLog interface.
 */
export function instanceOfCallLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "physician_id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CallLogFromJSON(json: any): CallLog {
    return CallLogFromJSONTyped(json, false);
}

export function CallLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'patient_id': json['patient_id'],
        'physician_id': json['physician_id'],
        'appointment_id': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'status': json['status'],
        'type': json['type'],
        'device': !exists(json, 'device') ? undefined : json['device'],
    };
}

export function CallLogToJSON(value?: CallLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date,
        'patient_id': value.patient_id,
        'physician_id': value.physician_id,
        'appointment_id': value.appointment_id,
        'role': value.role,
        'status': value.status,
        'type': value.type,
        'device': value.device,
    };
}

