/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetricType } from './MetricType';
import {
    MetricTypeFromJSON,
    MetricTypeFromJSONTyped,
    MetricTypeToJSON,
} from './MetricType';
import type { MetricValue } from './MetricValue';
import {
    MetricValueFromJSON,
    MetricValueFromJSONTyped,
    MetricValueToJSON,
} from './MetricValue';

/**
 * 
 * @export
 * @interface HealthRecordsMetricAggregated
 */
export interface HealthRecordsMetricAggregated {
    /**
     * 
     * @type {string}
     * @memberof HealthRecordsMetricAggregated
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthRecordsMetricAggregated
     */
    patient_id: string;
    /**
     * 
     * @type {MetricType}
     * @memberof HealthRecordsMetricAggregated
     */
    metric?: MetricType;
    /**
     * 
     * @type {Array<MetricValue>}
     * @memberof HealthRecordsMetricAggregated
     */
    values?: Array<MetricValue>;
}

/**
 * Check if a given object implements the HealthRecordsMetricAggregated interface.
 */
export function instanceOfHealthRecordsMetricAggregated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;

    return isInstance;
}

export function HealthRecordsMetricAggregatedFromJSON(json: any): HealthRecordsMetricAggregated {
    return HealthRecordsMetricAggregatedFromJSONTyped(json, false);
}

export function HealthRecordsMetricAggregatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthRecordsMetricAggregated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': json['patient_id'],
        'metric': !exists(json, 'metric') ? undefined : MetricTypeFromJSON(json['metric']),
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(MetricValueFromJSON)),
    };
}

export function HealthRecordsMetricAggregatedToJSON(value?: HealthRecordsMetricAggregated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'metric': MetricTypeToJSON(value.metric),
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(MetricValueToJSON)),
    };
}

