import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {BookAppointmentRequest, CallLog} from "generated/api";
import {Bubble} from "careand-redux/slices/ui/ui";
import {createResettableSlice} from "careand-redux/utils/makeResettable";

export interface VideoState {
  patientId?: string,
  token?: string,
  status?: string,
  reason?: string,
  visible: boolean,
  bubble?: Bubble,
  callLog?: CallLog
}

const initialState: VideoState = {
  visible: false
}

const videoSlice = createResettableSlice({
  name: 'Video',
  initialState,
  reducers: {
    receiveCallLog: (state: VideoState, action: PayloadAction<CallLog>) => {
      state.callLog = action.payload;
    },
    openVideoScreen: (state: VideoState, action: PayloadAction<Bubble | undefined>) => {
      const bubble = action.payload;
      state.bubble = bubble;
      state.visible = !!bubble;
      state.status = bubble ? 'start_call' : "end_call";

      delete state.callLog;
    },
    setVideoCallStatus: (state: VideoState, action: PayloadAction<{ status: string, reason?: string }>) => {
      state.status = action.payload.status;
      state.reason = action.payload.reason;
    }
  },
})

export const videoActions = videoSlice.actions
export default videoSlice.reducer

type t = BookAppointmentRequest
