import { SwipeUpdate, VelocityTracker } from "hooks/useSwipe"
import _ from "lodash"
import { RefObject } from "react"

export const getPopupSwipeHandler = (fgRef: RefObject<HTMLDivElement>, bgRef: RefObject<HTMLDivElement>, containerRef: RefObject<HTMLDivElement>, setOpen: (value: boolean) => void): SwipeUpdate => {
  const velocityTracker = new VelocityTracker(150)

  let originalYOffset: number | undefined
  let originalDelta: number | undefined

  return (state) => {
    if (!fgRef.current || !bgRef.current || !containerRef.current) {
      return
    }
    const fg = fgRef.current
    const bg = bgRef.current
    const container = containerRef.current
    let isTracking = !container.classList.contains('is-animated')

    let y: number | undefined
    if ('pos' in state && originalYOffset) {
      y = state.pos[1] - (originalYOffset ?? 0)
      if (originalDelta === undefined) {
        originalDelta = y
      }
    }

    let fraction: number | undefined = undefined
    if (y !== undefined) {
      fraction = y / fg.clientHeight
    }

    if (state.state === 'recognized') {
      if (state.first) {
        originalYOffset = fgRef.current!.getBoundingClientRect().top
        container.classList.remove('is-animated')
        isTracking = true
        originalDelta = y
      }
      if (isTracking && fraction !== undefined && y !== undefined) {
        const actualTransform = y - (originalDelta ?? 0)
        fg.style.transform = `translateY(max(${actualTransform}px, 0px))`
        bg.style.opacity = `${0.2 - ((fraction ?? 0) * 0.2)}`
        velocityTracker.addEvent([0, y])
      }
    }
    if (state.state === 'ended' && isTracking) {
      const [, vy] = velocityTracker.getVelocity()
      velocityTracker.reset()

      const cancel = vy < 0
      const y = getDyFromTransform(fg)
      const timeLeft = calculateTransitionTiming(vy, y, fg.clientHeight, cancel)
      const clampedTimeLeft = _.clamp(timeLeft, 150, 400)

      container.style.setProperty('--transition-duration', `${clampedTimeLeft}ms`)
      container.classList.add('is-animated')
      if (cancel) {
        container.classList.add('is-not-hidden')
      } else {
        setOpen(false)
      }

      fg.style.transform = ''
      bg.style.opacity = ''
      originalDelta = undefined
    }
  }
}

/**
 * Calculates the x offset of the menu fg from its transform
 */
function getDyFromTransform(element: HTMLElement) {
  const transform = window.getComputedStyle(element).transform
  const regex = /matrix\(-?\d+\.?\d*, -?\d+\.?\d*, -?\d+\.?\d*, -?\d+\.?\d*, -?\d+\.?\d*, (-?\d+\.?\d*)\)/
  const result = regex.exec(transform)
  try {
    return parseFloat(result?.[1] ?? "0")
  } catch (e: unknown) {}
  return 0
}
/**
 * Calculates how much time the popup should take to close given the velocity the swipe ended with
 */
function calculateTransitionTiming(velocity: number, clientY: number, clientHeight: number, open: boolean) {
  let dY: number
  if (open) {
    dY = -clientY
  } else {
    dY = clientHeight - clientY
  }

  if (velocity === 0) { return 0 }

  const t = dY / velocity

  return t
}