/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
} from './Patient';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface CorporationPatient
 */
export interface CorporationPatient {
    /**
     * 
     * @type {Patient}
     * @memberof CorporationPatient
     */
    patient?: Patient;
    /**
     * 
     * @type {Subscription}
     * @memberof CorporationPatient
     */
    subscription?: Subscription;
}

/**
 * Check if a given object implements the CorporationPatient interface.
 */
export function instanceOfCorporationPatient(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CorporationPatientFromJSON(json: any): CorporationPatient {
    return CorporationPatientFromJSONTyped(json, false);
}

export function CorporationPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): CorporationPatient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient': !exists(json, 'patient') ? undefined : PatientFromJSON(json['patient']),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionFromJSON(json['subscription']),
    };
}

export function CorporationPatientToJSON(value?: CorporationPatient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient': PatientToJSON(value.patient),
        'subscription': SubscriptionToJSON(value.subscription),
    };
}

