/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Question } from './Question';
import {
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface Questionnaire
 */
export interface Questionnaire {
    /**
     * 
     * @type {Array<Question>}
     * @memberof Questionnaire
     */
    questions?: Array<Question>;
}

/**
 * Check if a given object implements the Questionnaire interface.
 */
export function instanceOfQuestionnaire(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QuestionnaireFromJSON(json: any): Questionnaire {
    return QuestionnaireFromJSONTyped(json, false);
}

export function QuestionnaireFromJSONTyped(json: any, ignoreDiscriminator: boolean): Questionnaire {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(QuestionFromJSON)),
    };
}

export function QuestionnaireToJSON(value?: Questionnaire | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(QuestionToJSON)),
    };
}

