import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'careand-redux/api/ApiThunks';
import { unwrap } from 'careand-redux/utils/unwrap';
import { TabOrderingSettings } from 'generated/api';

export type NavigationActionType = 'push' | 'pop' | 'replace';

export const Tabs = {
  Home: 'home',
  Appointments: 'appointments',
  HR: 'hr',
  Profile: 'profile',
  Billing: 'billing',
  Settings: 'settings',
  Chat: 'chats',
  MedicationTracker: 'medication-tracker',
  MenstruationTracker: 'menstruation-tracker',
  Notifications: 'notifications',
} as const;
export type Tab = (typeof Tabs)[keyof typeof Tabs];

export const DefaultTabOrdering: TabOrderingSettings = {
  shown: [Tabs.Home, Tabs.Appointments, Tabs.HR, Tabs.Profile],
  hidden: [
    Tabs.MenstruationTracker,
    Tabs.MedicationTracker,
    Tabs.Chat,
    Tabs.Billing,
    Tabs.Settings,
    Tabs.Notifications,
  ],
};

export const Stacks = {
  Master: 'Master',
  ...Tabs,
} as const;
export type Stack = (typeof Stacks)[keyof typeof Stacks];

export interface NavigationState {
  stacks: Record<Stack, NavStackState>;
  reorderingTabs: TabOrderingSettings;
  activeTab: Tab;
  isAuthenticatedRoute?: boolean
}

export interface NavStackState {
  idx: number;
  stack: string[];
  lastAction?: NavigationActionType;
  state: RouteState[];
}

export interface RouteState {
  scroll?: number;
}

const getInitialState = (): NavigationState => {
  const initialState = {
    activeTab: Tabs.Home,
    reorderingTabs: { shown: [], hidden: [] },
    stacks: {
      Master: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      home: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      appointments: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      hr: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      profile: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      settings: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      billing: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      chats: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      [Tabs.MedicationTracker]: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      [Tabs.MenstruationTracker]: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
      [Tabs.Notifications]: {
        idx: 0,
        stack: ['/'],
        state: [{}],
      },
    },
  };
  const url = window.location.pathname + window.location.search;

  pathnameChanged(initialState, url);

  return initialState;
};

const slice = createSlice({
  name: 'Navigation',
  initialState: getInitialState(),
  extraReducers: (builder) => builder,
  reducers: {
    shownTabsReordered: unwrap((state, tabs: TabOrderingSettings) => {
      state.reorderingTabs = tabs;
    }),
    setAuthenticated: unwrap((state, isAuthenticated: boolean) => {
      state.isAuthenticatedRoute = isAuthenticated
    }),
    pushedOntoStack: (
      state,
      action: PayloadAction<{ stack: Stack; route: string; asFirst?: boolean; scroll?: number }>,
    ) => {
      const { route, asFirst, scroll } = action.payload;
      const stack = state.stacks[action.payload.stack];
      const current = stack.stack[stack.idx];
      const hasNext = stack.idx < stack.stack.length - 1;

      const prevStack =
        state.stacks.Master.stack[state.stacks.Master.idx] === '/'
          ? state.activeTab
          : Stacks.Master;
      state.stacks[prevStack].state[state.stacks[prevStack].idx].scroll = scroll;

      stack.lastAction = 'push';

      if (asFirst) {
        stack.stack = [route];
        stack.idx = 0;
        stack.state = [{}];
      }

      if (route === current) {
        return;
      }

      if (!hasNext) {
        stack.stack.push(route);
        stack.state.push({});
        stack.idx += 1;
        return;
      }

      const next = stack.stack[stack.idx + 1];
      if (next === route) {
        stack.idx += 1;
        stack.state[stack.idx].scroll = 0;
        return;
      }

      // if we're not at the endIdx we need to drop the rest of the stack
      // and push the new page
      const newStack = [...stack.stack.slice(0, stack.idx + 1), route];
      const newState = [...stack.state.slice(0, stack.idx + 1), {}];
      stack.stack = newStack;
      stack.state = newState;
      stack.idx += 1;
    },
    poppedFromStack: (state, action: PayloadAction<Stack>) => {
      const stack = state.stacks[action.payload];
      const newIdx = stack.idx - 1;
      if (newIdx < 0) return;

      stack.lastAction = 'pop';
      stack.idx = newIdx;
    },
    poppedStackTo: (
      state,
      action: PayloadAction<{ to: string; stack: Stack; animation: NavigationActionType }>,
    ) => {
      // TODO may need to be redone if we pass queries
      const { to, stack, animation } = action.payload;
      const stackState = state.stacks[stack];
      const idx = stackState.stack.indexOf(to);
      if (idx != -1) {
        stackState.stack = stackState.stack.slice(0, idx + 1);
        stackState.state = stackState.state.slice(0, idx + 1);
        stackState.idx = idx;
      } else if (to !== stackState.stack[0]) {
        stackState.stack = [stackState.stack[0], to];
        stackState.state = [...stackState.state, {}];
        stackState.idx = 1;
      } else {
        stackState.stack = [to];
        stackState.state = [{}];
        stackState.idx = 0;
      }
      stackState.lastAction = animation;
    },
    poppedStackToRoot: (state, action: PayloadAction<Stack>) => {
      const stack = state.stacks[action.payload];
      stack.idx = 0;
      stack.stack = [stack.stack[0]];
      stack.state = [stack.state[0]];
      stack.lastAction = 'pop';
    },
    activeTabChanged: (state, action: PayloadAction<{ tab: Tab; scroll?: number }>) => {
      const { tab, scroll } = action.payload;

      const masterRoute = state.stacks.Master.stack[state.stacks.Master.idx];

      let stackNeedingScrollUpdate: NavStackState;

      if (masterRoute === '/') {
        stackNeedingScrollUpdate = state.stacks[state.activeTab];
      } else {
        stackNeedingScrollUpdate = state.stacks.Master;
      }

      stackNeedingScrollUpdate.state[stackNeedingScrollUpdate.idx].scroll = scroll;

      state.activeTab = tab;
    },
    pushedAsFirst: (
      state,
      action: PayloadAction<{
        stack: Stack;
        to: string;
        root?: string;
        animation: NavigationActionType;
      }>,
    ) => {
      const { to, root, animation } = action.payload;
      const stackName = action.payload.stack;
      const stackState = state.stacks[stackName];

      const newStack = [];
      const newState = [];
      if (root) {
        newStack.push(root);
        newState.push({});
      }
      newStack.push(to);
      newState.push({});

      stackState.stack = newStack;
      stackState.state = newState;
      stackState.lastAction = animation;
      stackState.idx = root ? 1 : 0;
    },
    replaced: (
      state,
      action: PayloadAction<{ stack: Stack; to: string; animation: NavigationActionType }>,
    ) => {
      const { to, animation } = action.payload;
      const stackName = action.payload.stack;
      const stackState = state.stacks[stackName];

      const newStack = stackState.stack.slice(0, stackState.idx);
      const newState = stackState.state.slice(0, stackState.idx);
      newStack.push(to);
      newState.push({});

      stackState.stack = newStack;
      stackState.idx = newStack.length - 1;
      stackState.state = newState;
      stackState.lastAction = animation;
    },
    activeAccountChanged: (state) => {
      return resetState(state, true);
    },
    resetState: (state) => {
      return resetState(state, false);
    },
    pathnameChanged: unwrap(pathnameChanged),
  },
});

function resetState(state: NavigationState, keepActiveTab = true) {
  const newState = getInitialState();
  if (keepActiveTab) newState.activeTab = state.activeTab;
  newState.stacks.Master.lastAction = 'pop';
  return newState;
}

function pathnameChanged(state: NavigationState, path: string) {
  const [pathname, query] = path.split('?');
  const parts = pathname.split('/');
  const tabs = Object.values(Tabs);
  const isTabRoute = parts[1] && (tabs as string[]).includes(parts[1]);

  if (isTabRoute) {
    const route = '/' + parts.slice(2).join('/') + (query ? `?${query}` : '');
    const tab = parts[1] as Tab;
    const stackState = state.stacks[tab];
    const { stack, idx } = stackState;
    const routeState = stackState.state;

    // update active tab
    state.activeTab = tab;

    // update master if needed
    if (state.stacks.Master.stack[state.stacks.Master.idx] !== '/') {
      state.stacks.Master.stack.push('/');
      state.stacks.Master.state.push({});
      state.stacks.Master.idx += 1;
      state.stacks.Master.lastAction = 'push';
    }

    // noop if is currentRoute
    if (stack[idx] === route) {
      return;
    }

    // goBack if is prevRoute
    if (stack[idx - 1] === route) {
      stackState.idx -= 1;
      stackState.lastAction = 'pop';
      return;
    }

    // push otherwise
    stack.push(route);
    routeState.push({});
    stackState.idx += 1;
    stackState.lastAction = 'push';
    return;
  }

  // is Master route
  const masterStack = state.stacks.Master;
  const current = masterStack.stack[masterStack.idx];
  const prev = masterStack.stack[masterStack.idx - 1];
  const route = `${pathname}?${query}`;

  // no change
  if (pathname === current) {
    return;
  }
  // seems like back/Pop
  if (pathname === prev) {
    masterStack.idx -= 1;
    masterStack.lastAction = 'pop';
    return;
  }

  // seems like forward
  const hasNext = masterStack.idx + 1 < masterStack.stack.length;
  if (hasNext) {
    const next = masterStack.stack[masterStack.idx + 1];
    if (next === pathname) {
      masterStack.idx += 1;
    }
    return;
  }

  const newStack = [...masterStack.stack.slice(0, masterStack.idx + 1), route];
  const newState = [...masterStack.state.slice(0, masterStack.idx + 1), {}];
  masterStack.stack = newStack;
  masterStack.state = newState;
  masterStack.idx += 1;
  masterStack.lastAction = 'push';
}

export const pathnameChangedTransformer = pathnameChanged;

export function getActivePath(state: NavigationState) {
  const activeTab = state.activeTab;
  const masterRoute = state.stacks.Master.stack[state.stacks.Master.idx];
  const tabRoute = state.stacks[activeTab].stack[state.stacks[activeTab].idx];

  let route: string;
  if (masterRoute === '/') {
    route = `/${activeTab}${tabRoute}`;
  } else {
    route = masterRoute;
  }
  return route;
}

export function parseQuery(path: string): {} {
  const obj = {};
  const idx = path.lastIndexOf('?');
  if (idx === -1) {
    return obj;
  }
  const query = path.substring(idx);
  const params = new URLSearchParams(query);
  for (const entry of params.entries()) {
    (obj as any)[entry[0]] = entry[1];
  }
  return obj;
}

export function getActiveStack(state: NavigationState) {
  if (state.stacks.Master.stack[state.stacks.Master.idx] === '/') {
    return state.activeTab;
  }
  return Stacks.Master;
}

export function getCurrentRoute(state: NavigationState, stack: Stack): string {
  const stackState = state.stacks[stack];
  return stackState.stack[stackState.idx];
}

export const navigationActions = slice.actions;
export default slice.reducer;
