/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Questionnaire } from './Questionnaire';
import {
    QuestionnaireFromJSON,
    QuestionnaireFromJSONTyped,
    QuestionnaireToJSON,
} from './Questionnaire';
import type { StatusHistory } from './StatusHistory';
import {
    StatusHistoryFromJSON,
    StatusHistoryFromJSONTyped,
    StatusHistoryToJSON,
} from './StatusHistory';

/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    patient_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Appointment
     */
    patient_ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    intern_of?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    type: AppointmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    telemedicine_method?: AppointmentTelemedicineMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_end?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_booking?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_checked_in?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_called_in?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_finished?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_canceled?: number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    day?: string;
    /**
     * 
     * @type {Address}
     * @memberof Appointment
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    complaints?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    rating?: number;
    /**
     * 
     * @type {Array<StatusHistory>}
     * @memberof Appointment
     */
    status_history?: Array<StatusHistory>;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    booked_by?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    concern_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    concern_text?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    concern_minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    blocked_minutes_before?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    blocked_minutes_after?: number;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    fee?: number;
    /**
     * 
     * @type {Questionnaire}
     * @memberof Appointment
     */
    questionnaire?: Questionnaire;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    document_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    cancel_cutoff?: number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    linked_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    skip_notifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    feedback?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    date_feedback?: number;
}


/**
 * @export
 */
export const AppointmentTypeEnum = {
    Video: 'Video',
    Home: 'Home',
    Clinic: 'Clinic',
    Blocked: 'Blocked',
    Lab: 'Lab'
} as const;
export type AppointmentTypeEnum = typeof AppointmentTypeEnum[keyof typeof AppointmentTypeEnum];

/**
 * @export
 */
export const AppointmentTelemedicineMethodEnum = {
    Video: 'Video',
    Phone: 'Phone'
} as const;
export type AppointmentTelemedicineMethodEnum = typeof AppointmentTelemedicineMethodEnum[keyof typeof AppointmentTelemedicineMethodEnum];


/**
 * Check if a given object implements the Appointment interface.
 */
export function instanceOfAppointment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function AppointmentFromJSON(json: any): Appointment {
    return AppointmentFromJSONTyped(json, false);
}

export function AppointmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Appointment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'patient_ids': !exists(json, 'patient_ids') ? undefined : json['patient_ids'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'intern_of': !exists(json, 'intern_of') ? undefined : json['intern_of'],
        'type': json['type'],
        'telemedicine_method': !exists(json, 'telemedicine_method') ? undefined : json['telemedicine_method'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'date_end': !exists(json, 'date_end') ? undefined : json['date_end'],
        'date_booking': !exists(json, 'date_booking') ? undefined : json['date_booking'],
        'date_checked_in': !exists(json, 'date_checked_in') ? undefined : json['date_checked_in'],
        'date_called_in': !exists(json, 'date_called_in') ? undefined : json['date_called_in'],
        'date_finished': !exists(json, 'date_finished') ? undefined : json['date_finished'],
        'date_canceled': !exists(json, 'date_canceled') ? undefined : json['date_canceled'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'complaints': !exists(json, 'complaints') ? undefined : json['complaints'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'status_history': !exists(json, 'status_history') ? undefined : ((json['status_history'] as Array<any>).map(StatusHistoryFromJSON)),
        'booked_by': !exists(json, 'booked_by') ? undefined : json['booked_by'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'concern_id': !exists(json, 'concern_id') ? undefined : json['concern_id'],
        'concern_text': !exists(json, 'concern_text') ? undefined : json['concern_text'],
        'concern_minutes': !exists(json, 'concern_minutes') ? undefined : json['concern_minutes'],
        'blocked_minutes_before': !exists(json, 'blocked_minutes_before') ? undefined : json['blocked_minutes_before'],
        'blocked_minutes_after': !exists(json, 'blocked_minutes_after') ? undefined : json['blocked_minutes_after'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'questionnaire': !exists(json, 'questionnaire') ? undefined : QuestionnaireFromJSON(json['questionnaire']),
        'document_id': !exists(json, 'document_id') ? undefined : json['document_id'],
        'cancel_cutoff': !exists(json, 'cancel_cutoff') ? undefined : json['cancel_cutoff'],
        'linked_id': !exists(json, 'linked_id') ? undefined : json['linked_id'],
        'skip_notifications': !exists(json, 'skip_notifications') ? undefined : json['skip_notifications'],
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'date_feedback': !exists(json, 'date_feedback') ? undefined : json['date_feedback'],
    };
}

export function AppointmentToJSON(value?: Appointment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'patient_ids': value.patient_ids,
        'physician_id': value.physician_id,
        'intern_of': value.intern_of,
        'type': value.type,
        'telemedicine_method': value.telemedicine_method,
        'date': value.date,
        'date_end': value.date_end,
        'date_booking': value.date_booking,
        'date_checked_in': value.date_checked_in,
        'date_called_in': value.date_called_in,
        'date_finished': value.date_finished,
        'date_canceled': value.date_canceled,
        'day': value.day,
        'address': AddressToJSON(value.address),
        'complaints': value.complaints,
        'notes': value.notes,
        'status': value.status,
        'rating': value.rating,
        'status_history': value.status_history === undefined ? undefined : ((value.status_history as Array<any>).map(StatusHistoryToJSON)),
        'booked_by': value.booked_by,
        'read': value.read,
        'clinic_id': value.clinic_id,
        'concern_id': value.concern_id,
        'concern_text': value.concern_text,
        'concern_minutes': value.concern_minutes,
        'blocked_minutes_before': value.blocked_minutes_before,
        'blocked_minutes_after': value.blocked_minutes_after,
        'fee': value.fee,
        'questionnaire': QuestionnaireToJSON(value.questionnaire),
        'document_id': value.document_id,
        'cancel_cutoff': value.cancel_cutoff,
        'linked_id': value.linked_id,
        'skip_notifications': value.skip_notifications,
        'feedback': value.feedback,
        'date_feedback': value.date_feedback,
    };
}

