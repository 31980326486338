/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  QueueElement,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    QueueElementFromJSON,
    QueueElementToJSON,
} from '../models';

export interface AddQueueElementRequest {
    element?: QueueElement;
}

export interface DeleteQueueElementRequest {
    id: string;
}

export interface UpdateQueueElementRequest {
    id: string;
    element?: QueueElement;
}

/**
 * 
 */
export class QueueApi extends runtime.BaseAPI {

    /**
     * Adds a new queue element (Roles: admin, physician)
     */
    async addQueueElementRaw(requestParameters: AddQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueueElement>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/queue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueueElementToJSON(requestParameters.element),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueueElementFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addQueueElementRequestOptionsRaw(requestParameters: AddQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/queue`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: QueueElementToJSON(requestParameters.element),
        };
        return responseConfig
    }

    /**
     * Adds a new queue element (Roles: admin, physician)
     */
    async addQueueElement(requestParameters: AddQueueElementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueueElement> {
        const response = await this.addQueueElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a queue element (Roles: admin, physician)
     */
    async deleteQueueElementRaw(requestParameters: DeleteQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteQueueElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/queue/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteQueueElementRequestOptionsRaw(requestParameters: DeleteQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteQueueElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/queue/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a queue element (Roles: admin, physician)
     */
    async deleteQueueElement(requestParameters: DeleteQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteQueueElementRaw(requestParameters, initOverrides);
    }

    /**
     * Gets all the active queue elements (Roles: admin, physician)
     */
    async getQueueRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<QueueElement>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/queue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QueueElementFromJSON));
    }

    /**
        Request Config Getter
     */
    getQueueRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/queue`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all the active queue elements (Roles: admin, physician)
     */
    async getQueue(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<QueueElement>> {
        const response = await this.getQueueRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates a queue element (Roles: admin, physician)
     */
    async updateQueueElementRaw(requestParameters: UpdateQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueueElement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateQueueElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/queue/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: QueueElementToJSON(requestParameters.element),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueueElementFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateQueueElementRequestOptionsRaw(requestParameters: UpdateQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateQueueElement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/queue/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: QueueElementToJSON(requestParameters.element),
        };
        return responseConfig
    }

    /**
     * Updates a queue element (Roles: admin, physician)
     */
    async updateQueueElement(requestParameters: UpdateQueueElementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueueElement> {
        const response = await this.updateQueueElementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
