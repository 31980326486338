/* eslint-disable no-restricted-syntax */
class RouteUtils {
  static routes() {
    return {
      outlook: '/outlook-callback/:app?',
      admin: '/admin/:adminId/:tab?',
      clinics: '/clinics/:tab?/:param?',
      shifts: '/clinics/shifts',
      corporate: '/clinics/corporate-clients',
      contacts: '/clinics/contacts',
      pharmacies: '/clinics/pharmacies',
      healthMetrics: '/clinics/health-metrics',
      paymentPlans: '/clinics/payment-plans',
      overdueSubscriptions: '/clinics/overdue-subscriptions',
      templates: '/clinics/templates',
      clinicDetails: '/clinics/clinic-details',
      bookingOptions: '/clinics/booking-options',
      clinicStatistics: '/clinics/stats',
      clinicFinancials: '/clinics/financials',
      clinicStatisticsDashboard: '/clinics/stats/:section?',
      practitioners: '/practitioners',
      practitioner: '/practitioner/:practitionerId/:tab?',
      staff: '/staff/:staffId',
      staffSearch: '/staff',
      patients: '/patients',
      patient: '/patient/:patientId/:tab?/:elementId?',
      profile: '/profile',
      appointments: '/appointments/:elementId?',
      settings: '/settings',
      billing: '/billing',
      healthRecords: '/health-records/:tab?',
      schedule: '/schedule',
      documents: '/documents',
      referrals: '/referrals',
      subscribe: '/subscribe/:step?',
      subscribeBooking: '/subscribe-booking/:id/:step?',
      bubble: '/bubble/:bubbleId/:tab?/:elementId?',
      tasks: '/tasks',
      messages: '/messages/:chat?',
      root: '/',
      test: '/test',
    } as const;
  }

  static publicRoutes() {
    return {
      login: '/login',
      telemedicine: '/telemedicine/:id',
      forgotPassword: '/login/forgot-password',
      forgotUsername: '/login/forgot-username',
      register: '/register/:step?',
      registerClinic: '/register-clinic/:step?',
      newCredentials: '/new-credentials',
      patientTemplate: '/patient-template',
      drawSignature: '/draw-signature',
      error: '/error',
    } as const;
  }

  static getRoute(route: string, params = {}) {
    for (let key in params) {
      route = route.replace(key, params[key as keyof typeof params] || '');
    }
    return route.replace(/\/:\w*\?/g, '');
  }

  static getCPPRoute(patientId: string, icon = '', bubble: any, element = '') {
    let route = '';
    let params: any = {
      '/:tab?': icon ? '/' + icon.replace('icon-', '') : '',
      '/:elementId?': element ? '/' + element : '',
    };

    if (bubble) {
      route = RouteUtils.routes().bubble;
      params = {
        ...params,
        ':bubbleId': bubble.id,
      };
    } else {
      route = RouteUtils.routes().patient;
      params = {
        ...params,
        ':patientId': patientId,
      };
    }
    return RouteUtils.getRoute(route, params);
  }

  static getPatientSidebarHealthRecordsTabs() {
    return {
      records: {
        name: 'records',
        icon: 'book-medical',
        label: 'Summary',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'records',
        }),
      },
      notes: {
        name: 'notes',
        icon: 'chart-line',
        label: 'Encounter Notes',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'notes',
        }),
      },
      immunizations: {
        name: 'immunizations',
        icon: 'vial-virus',
        label: 'Immunizations',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'immunizations',
        }),
      },
      prescriptions: {
        name: 'prescriptions',
        icon: 'prescription',
        label: 'Prescriptions',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'prescriptions',
        }),
      },
      referrals: {
        name: 'referrals',
        icon: 'user-doctor',
        label: 'Referrals',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'referrals',
        }),
      },
      diagnostics: {
        name: 'diagnostics',
        icon: 'chart-line',
        label: 'Diagnostics',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'diagnostics',
        }),
      },
      documents: {
        name: 'documents',
        icon: 'files-medical',
        label: 'Other Documents',
        route: RouteUtils.getRoute(RouteUtils.routes().healthRecords, {
          ':tab?': 'documents',
        }),
      },
    };
  }

  static getPatientSidebarTabs() {
    return {
      home: {
        name: 'home',
        icon: 'house-blank',
        label: 'Home',
        route: RouteUtils.routes().root,
      },
      appointments: {
        name: 'appointments',
        icon: 'calendar-clock',
        label: 'Appointments',
        route: RouteUtils.routes().appointments,
      },
      messages: {
        name: 'messages',
        icon: 'messages',
        label: 'Messages',
        route: RouteUtils.routes().messages,
      },
      records: {
        name: 'records',
        icon: 'files-medical',
        label: 'Health Records',
        route: RouteUtils.routes().healthRecords,
      },
      profile: {
        name: 'profile',
        icon: 'user',
        label: 'Profile',
        route: RouteUtils.routes().profile,
      },
      settings: {
        name: 'settings',
        icon: 'gear',
        label: 'Settings',
        route: RouteUtils.routes().settings,
      },
      billing: {
        name: 'billing',
        icon: 'wallet',
        label: 'Billing',
        route: RouteUtils.routes().billing,
      },
    };
  }
}

export default RouteUtils;
