/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShiftBreak } from './ShiftBreak';
import {
    ShiftBreakFromJSON,
    ShiftBreakFromJSONTyped,
    ShiftBreakToJSON,
} from './ShiftBreak';

/**
 * 
 * @export
 * @interface Shift
 */
export interface Shift {
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    date_string?: string;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    end: number;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    user_role?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    clinic_id: string;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    lunch_start?: number;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    lunch_end?: number;
    /**
     * 
     * @type {Array<ShiftBreak>}
     * @memberof Shift
     */
    breaks?: Array<ShiftBreak>;
    /**
     * 
     * @type {boolean}
     * @memberof Shift
     */
    non_working?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    non_working_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shift
     */
    approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shift
     */
    rejected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    half_day?: string;
}

/**
 * Check if a given object implements the Shift interface.
 */
export function instanceOfShift(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "clinic_id" in value;

    return isInstance;
}

export function ShiftFromJSON(json: any): Shift {
    return ShiftFromJSONTyped(json, false);
}

export function ShiftFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shift {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'date_string': !exists(json, 'date_string') ? undefined : json['date_string'],
        'start': json['start'],
        'end': json['end'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user_role': !exists(json, 'user_role') ? undefined : json['user_role'],
        'clinic_id': json['clinic_id'],
        'lunch_start': !exists(json, 'lunch_start') ? undefined : json['lunch_start'],
        'lunch_end': !exists(json, 'lunch_end') ? undefined : json['lunch_end'],
        'breaks': !exists(json, 'breaks') ? undefined : ((json['breaks'] as Array<any>).map(ShiftBreakFromJSON)),
        'non_working': !exists(json, 'non_working') ? undefined : json['non_working'],
        'non_working_reason': !exists(json, 'non_working_reason') ? undefined : json['non_working_reason'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'rejected': !exists(json, 'rejected') ? undefined : json['rejected'],
        'half_day': !exists(json, 'half_day') ? undefined : json['half_day'],
    };
}

export function ShiftToJSON(value?: Shift | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'date_string': value.date_string,
        'start': value.start,
        'end': value.end,
        'user_id': value.user_id,
        'user_role': value.user_role,
        'clinic_id': value.clinic_id,
        'lunch_start': value.lunch_start,
        'lunch_end': value.lunch_end,
        'breaks': value.breaks === undefined ? undefined : ((value.breaks as Array<any>).map(ShiftBreakToJSON)),
        'non_working': value.non_working,
        'non_working_reason': value.non_working_reason,
        'notes': value.notes,
        'approved': value.approved,
        'rejected': value.rejected,
        'half_day': value.half_day,
    };
}

