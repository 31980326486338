import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const SELECT_FIELD_STYLE = {
  width: '100%',
}

const DefaultSelectField = ({
  id,
  name,
  label,
  placeholder,
  inputs,
  inputElements,
  width = '250px',
  style = {},
  value,
  onChange,
  disabled,
  className = "",
  multiple = false,
  error
}) => {

  if (placeholder) {
    style = {...style, '--data-placeholder': `"${placeholder}"`}
  }

  if (error) {
    className += ' has-error'
  }

  return (
  <FormControl className={`default-select-field ${className}`} style={{ ...SELECT_FIELD_STYLE, width, ...style }}>
    <InputLabel>{label}</InputLabel>
    <Select
      id={id || 'select-' + (label || placeholder).replace(/ /g, '_')}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      multiple={multiple}
      value={value}
      onChange={onChange}
      defaultValue={value}
    >
      {inputElements ||
        inputs.map((type) => (
          <MenuItem
            id={`menu-item-${type}`}
            className={type}
            value={type}
            key={type}
          >
            {type}
          </MenuItem>
        ))}
    </Select>
    <div className="error-message">{error}</div>
  </FormControl>
)
};

export default DefaultSelectField;
