/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pharmacy } from './Pharmacy';
import {
    PharmacyFromJSON,
    PharmacyFromJSONTyped,
    PharmacyToJSON,
} from './Pharmacy';
import type { PrescriptionMedication } from './PrescriptionMedication';
import {
    PrescriptionMedicationFromJSON,
    PrescriptionMedicationFromJSONTyped,
    PrescriptionMedicationToJSON,
} from './PrescriptionMedication';

/**
 * 
 * @export
 * @interface Prescription
 */
export interface Prescription {
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    physician_id: string;
    /**
     * 
     * @type {Array<PrescriptionMedication>}
     * @memberof Prescription
     */
    medications: Array<PrescriptionMedication>;
    /**
     * 
     * @type {number}
     * @memberof Prescription
     */
    date?: number;
    /**
     * 
     * @type {Pharmacy}
     * @memberof Prescription
     */
    pharmacy?: Pharmacy;
    /**
     * 
     * @type {Array<string>}
     * @memberof Prescription
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    sent?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Prescription
     */
    hide?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Prescription
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    additional_information?: string;
    /**
     * 
     * @type {string}
     * @memberof Prescription
     */
    waiting_approval?: string;
}

/**
 * Check if a given object implements the Prescription interface.
 */
export function instanceOfPrescription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "physician_id" in value;
    isInstance = isInstance && "medications" in value;

    return isInstance;
}

export function PrescriptionFromJSON(json: any): Prescription {
    return PrescriptionFromJSONTyped(json, false);
}

export function PrescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': json['patient_id'],
        'physician_id': json['physician_id'],
        'medications': ((json['medications'] as Array<any>).map(PrescriptionMedicationFromJSON)),
        'date': !exists(json, 'date') ? undefined : json['date'],
        'pharmacy': !exists(json, 'pharmacy') ? undefined : PharmacyFromJSON(json['pharmacy']),
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'sent': !exists(json, 'sent') ? undefined : json['sent'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'additional_information': !exists(json, 'additional_information') ? undefined : json['additional_information'],
        'waiting_approval': !exists(json, 'waiting_approval') ? undefined : json['waiting_approval'],
    };
}

export function PrescriptionToJSON(value?: Prescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'physician_id': value.physician_id,
        'medications': ((value.medications as Array<any>).map(PrescriptionMedicationToJSON)),
        'date': value.date,
        'pharmacy': PharmacyToJSON(value.pharmacy),
        'attachments': value.attachments,
        'sent': value.sent,
        'hide': value.hide,
        'read': value.read,
        'additional_information': value.additional_information,
        'waiting_approval': value.waiting_approval,
    };
}

