/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HelpdeskMessageContent } from './HelpdeskMessageContent';
import {
    HelpdeskMessageContentFromJSON,
    HelpdeskMessageContentFromJSONTyped,
    HelpdeskMessageContentToJSON,
} from './HelpdeskMessageContent';

/**
 * 
 * @export
 * @interface HelpdeskMessage
 */
export interface HelpdeskMessage {
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof HelpdeskMessage
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    staff_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    clinic_id?: string;
    /**
     * 
     * @type {Array<HelpdeskMessageContent>}
     * @memberof HelpdeskMessage
     */
    messages?: Array<HelpdeskMessageContent>;
    /**
     * 
     * @type {boolean}
     * @memberof HelpdeskMessage
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessage
     */
    document_id?: string;
}

/**
 * Check if a given object implements the HelpdeskMessage interface.
 */
export function instanceOfHelpdeskMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "subject" in value;
    isInstance = isInstance && "from" in value;

    return isInstance;
}

export function HelpdeskMessageFromJSON(json: any): HelpdeskMessage {
    return HelpdeskMessageFromJSONTyped(json, false);
}

export function HelpdeskMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelpdeskMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subject': json['subject'],
        'from': json['from'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'staff_id': !exists(json, 'staff_id') ? undefined : json['staff_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(HelpdeskMessageContentFromJSON)),
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'document_id': !exists(json, 'document_id') ? undefined : json['document_id'],
    };
}

export function HelpdeskMessageToJSON(value?: HelpdeskMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'subject': value.subject,
        'from': value.from,
        'date': value.date,
        'patient_id': value.patient_id,
        'staff_id': value.staff_id,
        'clinic_id': value.clinic_id,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(HelpdeskMessageContentToJSON)),
        'archived': value.archived,
        'document_id': value.document_id,
    };
}

