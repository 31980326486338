/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpenAIMessage } from './OpenAIMessage';
import {
    OpenAIMessageFromJSON,
    OpenAIMessageFromJSONTyped,
    OpenAIMessageToJSON,
} from './OpenAIMessage';

/**
 * 
 * @export
 * @interface OpenAIConversation
 */
export interface OpenAIConversation {
    /**
     * 
     * @type {string}
     * @memberof OpenAIConversation
     */
    context?: string;
    /**
     * 
     * @type {Array<OpenAIMessage>}
     * @memberof OpenAIConversation
     */
    messages: Array<OpenAIMessage>;
}

/**
 * Check if a given object implements the OpenAIConversation interface.
 */
export function instanceOfOpenAIConversation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "messages" in value;

    return isInstance;
}

export function OpenAIConversationFromJSON(json: any): OpenAIConversation {
    return OpenAIConversationFromJSONTyped(json, false);
}

export function OpenAIConversationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenAIConversation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : json['context'],
        'messages': ((json['messages'] as Array<any>).map(OpenAIMessageFromJSON)),
    };
}

export function OpenAIConversationToJSON(value?: OpenAIConversation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'messages': ((value.messages as Array<any>).map(OpenAIMessageToJSON)),
    };
}

