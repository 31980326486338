
import { RequestOptions } from "careand-redux/api/ApiThunks"
import { z } from "zod"
import { createMessage, NativeMessageSchema } from "./NativeMessage"
import { URLRequest, URLRequestSchema } from "./URLRequest"

export const OpenFileMessageSchema = z.object({
  type: z.literal('openFile'),
  content: z.object({
    request: URLRequestSchema,
    filename: z.string().optional()
  })
}).extend(NativeMessageSchema.shape)

export type OpenFileMessage = z.infer<typeof OpenFileMessageSchema>

export const createOpenFileMessage = (request: URLRequest, filename: OpenFileMessage['content']['filename']) => ({
  ...createMessage(),
  type: 'openFile',
  content: {
    request,
    filename
  }
} satisfies OpenFileMessage)
