/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentTemplate } from './DocumentTemplate';
import {
    DocumentTemplateFromJSON,
    DocumentTemplateFromJSONTyped,
    DocumentTemplateToJSON,
} from './DocumentTemplate';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';

/**
 * 
 * @export
 * @interface PatientDocumentAndTemplate
 */
export interface PatientDocumentAndTemplate {
    /**
     * 
     * @type {PatientDocument}
     * @memberof PatientDocumentAndTemplate
     */
    document: PatientDocument;
    /**
     * 
     * @type {DocumentTemplate}
     * @memberof PatientDocumentAndTemplate
     */
    template: DocumentTemplate;
}

/**
 * Check if a given object implements the PatientDocumentAndTemplate interface.
 */
export function instanceOfPatientDocumentAndTemplate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "document" in value;
    isInstance = isInstance && "template" in value;

    return isInstance;
}

export function PatientDocumentAndTemplateFromJSON(json: any): PatientDocumentAndTemplate {
    return PatientDocumentAndTemplateFromJSONTyped(json, false);
}

export function PatientDocumentAndTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientDocumentAndTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'document': PatientDocumentFromJSON(json['document']),
        'template': DocumentTemplateFromJSON(json['template']),
    };
}

export function PatientDocumentAndTemplateToJSON(value?: PatientDocumentAndTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document': PatientDocumentToJSON(value.document),
        'template': DocumentTemplateToJSON(value.template),
    };
}

