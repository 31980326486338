/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceToken
 */
export interface DeviceToken {
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    device_token: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    token_type: DeviceTokenTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    device_type: DeviceTokenDeviceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    api_version?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    client_version?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    client_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    user_id?: string;
}


/**
 * @export
 */
export const DeviceTokenTokenTypeEnum = {
    Push: 'push',
    Voip: 'voip'
} as const;
export type DeviceTokenTokenTypeEnum = typeof DeviceTokenTokenTypeEnum[keyof typeof DeviceTokenTokenTypeEnum];

/**
 * @export
 */
export const DeviceTokenDeviceTypeEnum = {
    Ios: 'ios',
    Android: 'android'
} as const;
export type DeviceTokenDeviceTypeEnum = typeof DeviceTokenDeviceTypeEnum[keyof typeof DeviceTokenDeviceTypeEnum];


/**
 * Check if a given object implements the DeviceToken interface.
 */
export function instanceOfDeviceToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "device_token" in value;
    isInstance = isInstance && "token_type" in value;
    isInstance = isInstance && "device_type" in value;

    return isInstance;
}

export function DeviceTokenFromJSON(json: any): DeviceToken {
    return DeviceTokenFromJSONTyped(json, false);
}

export function DeviceTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'device_token': json['device_token'],
        'token_type': json['token_type'],
        'device_type': json['device_type'],
        'api_version': !exists(json, 'api_version') ? undefined : json['api_version'],
        'client_version': !exists(json, 'client_version') ? undefined : json['client_version'],
        'client_name': !exists(json, 'client_name') ? undefined : json['client_name'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function DeviceTokenToJSON(value?: DeviceToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'device_token': value.device_token,
        'token_type': value.token_type,
        'device_type': value.device_type,
        'api_version': value.api_version,
        'client_version': value.client_version,
        'client_name': value.client_name,
        'user_id': value.user_id,
    };
}

