/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MTStatsDescription
 */
export interface MTStatsDescription {
    /**
     * 
     * @type {string}
     * @memberof MTStatsDescription
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MTStatsDescription
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof MTStatsDescription
     */
    normal: boolean;
    /**
     * 
     * @type {string}
     * @memberof MTStatsDescription
     */
    categorization: MTStatsDescriptionCategorizationEnum;
    /**
     * 
     * @type {number}
     * @memberof MTStatsDescription
     */
    amount: number;
}


/**
 * @export
 */
export const MTStatsDescriptionCategorizationEnum = {
    Normal: 'Normal',
    Frequent: 'Frequent',
    Regular: 'Regular',
    Irregular: 'Irregular',
    Infrequent: 'Infrequent',
    Prolonged: 'Prolonged'
} as const;
export type MTStatsDescriptionCategorizationEnum = typeof MTStatsDescriptionCategorizationEnum[keyof typeof MTStatsDescriptionCategorizationEnum];


/**
 * Check if a given object implements the MTStatsDescription interface.
 */
export function instanceOfMTStatsDescription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "normal" in value;
    isInstance = isInstance && "categorization" in value;
    isInstance = isInstance && "amount" in value;

    return isInstance;
}

export function MTStatsDescriptionFromJSON(json: any): MTStatsDescription {
    return MTStatsDescriptionFromJSONTyped(json, false);
}

export function MTStatsDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MTStatsDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'normal': json['normal'],
        'categorization': json['categorization'],
        'amount': json['amount'],
    };
}

export function MTStatsDescriptionToJSON(value?: MTStatsDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'normal': value.normal,
        'categorization': value.categorization,
        'amount': value.amount,
    };
}

