/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceToken } from './DeviceToken';
import {
    DeviceTokenFromJSON,
    DeviceTokenFromJSONTyped,
    DeviceTokenToJSON,
} from './DeviceToken';

/**
 * 
 * @export
 * @interface TwoFactorPushTokenPayload
 */
export interface TwoFactorPushTokenPayload {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorPushTokenPayload
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorPushTokenPayload
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorPushTokenPayload
     */
    code?: string;
    /**
     * 
     * @type {DeviceToken}
     * @memberof TwoFactorPushTokenPayload
     */
    device_token?: DeviceToken;
}

/**
 * Check if a given object implements the TwoFactorPushTokenPayload interface.
 */
export function instanceOfTwoFactorPushTokenPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TwoFactorPushTokenPayloadFromJSON(json: any): TwoFactorPushTokenPayload {
    return TwoFactorPushTokenPayloadFromJSONTyped(json, false);
}

export function TwoFactorPushTokenPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwoFactorPushTokenPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'device_token': !exists(json, 'device_token') ? undefined : DeviceTokenFromJSON(json['device_token']),
    };
}

export function TwoFactorPushTokenPayloadToJSON(value?: TwoFactorPushTokenPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'code': value.code,
        'device_token': DeviceTokenToJSON(value.device_token),
    };
}

