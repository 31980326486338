import { PayloadAction } from "@reduxjs/toolkit"
import { MinistryOHIP, Reminder } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrapApi } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface RemindersState {
  [userId: string]: Reminder[] | undefined
}

const initialState: RemindersState = {}

const slice = createResettableSlice({
  name: 'Reminders',
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(api.Reminders.getReminders.fulfilled, unwrapApi((state, data, req) => {
      state[req.userId] = data
    }))
    .addCase(api.Reminders.addReminder.fulfilled, unwrapApi((state, reminder, req) => {
      if (!state[req.userId]) {
        state[req.userId] = []
      }
      PerformanceUtils.upsert(reminder, state[req.userId]!)
    }))
    .addCase(api.Reminders.deleteReminder.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state[req.userId] ?? [])
    }))
})

export const remindersActions = slice.actions
export default slice.reducer
