import { deviceActions } from 'careand-redux/slices/device';
import {
  getUnwrappedDispatch,
  UnwrappedAppDispatch,
  useAppDispatch,
} from 'careand-redux/useAppDispatch';
import React, { useEffect } from 'react';
import { Device } from 'services/Native/Device';
import { SetupMessageSchema } from 'services/Native/messages/Setup';
import { store } from 'careand-redux/store';
import { PermissionsUpdatedMessageSchema } from 'services/Native/messages/PermissionsUpdated';
import { NativeMessage } from 'services/Native/messages/NativeMessage';
import { StatusReplyMessageSchema } from 'services/Native/messages/StatusReply';
import { AppleHealthUpdateMessageSchema } from 'services/Native/messages/AppleHealth';
import { L } from 'chart.js/dist/chunks/helpers.core';
import { updateDevice } from 'careand-redux/api/ApiThunks';
import { updateDocumentSafeArea } from 'careand-redux/listeners/SafeAreaListener';
import { LogoutMessageSchema } from 'services/Native/messages/Logout';
import { logout } from 'careand-redux/actions/logout';
import { NotificationTokenUpdatedMessageSchema } from 'services/Native/messages/NotificationTokenUpdated';
import { updatePushToken, updateTwoFactorPushTokens } from 'services/updatePushToken';
import { PushNotificationReceivedMessageSchema } from 'services/Native/messages/PushNotificationReceived';
import { handlePushNotification } from 'services/Native/messages/PushNotifications/PushNotificationHandler';
import {
  RequestStoredUsersMessageSchema,
  StoredUserSchema,
} from 'services/Native/messages/StoredUsers';
import Config from 'config/config.json';
const isPatientPortal = !!Config.PATIENT;

function listenToNativeEvents() {
  const log = (...args: unknown[]) => console.log('[NativeEvents]', ...args);
  const logErr = (...args: unknown[]) => console.error('[NativeEvents]', ...args);

  const cb = (e: Event) => {
    const dispatch = getUnwrappedDispatch(store.dispatch);
    if (!(e instanceof CustomEvent)) {
      return;
    }
    const detail = e.detail as unknown;

    log('Got Native Event', detail);

    if (handleSetup(detail, dispatch)) {
      log('Setup Handled');
      return;
    }
    if (handlePermissionsUpdated(detail, dispatch)) {
      log('PermissionsUpdate Handled');
      return;
    }
    if (handleAppleHealthUpdated(detail, dispatch)) {
      log('AppleHealthUpdate Handled');
      return;
    }
    if (handleLogout(detail, dispatch)) {
      log('LogoutMessage Handled');
      return;
    }
    if (handleNotificationTokenUpdated(detail, dispatch)) {
      log('NotifcationTokenUpdated Handled');
      return;
    }
    if (handlePushNotificationReceived(detail, dispatch)) {
      return;
    }
    if (handleStoredUsersRequest(detail, dispatch)) {
      return;
    }
    logErr('Unhandled');
  };

  window.addEventListener('nativeEvent', cb);
  return () => window.removeEventListener('nativeEvent', cb);
}

export default (isPatient: boolean) => {
  useEffect(() => {
    const unsub = listenToNativeEvents();
    Device.requestSetup();
    return unsub;
  }, []);
};

// /* HANDLERS */

function handleSetup(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = SetupMessageSchema.safeParse(event);
  if (result.success) {
    const safeArea = result.data.content.safeArea;
    dispatch(deviceActions.safeAreaChanged(safeArea ?? {}));
    if (typeof result.data.content.biometricAuthentication === 'boolean') {
      dispatch(deviceActions.biometricsChanged(result.data.content.biometricAuthentication));
    }
    if (typeof result.data.content.biometricsAvailable === 'string') {
      dispatch(
        deviceActions.biometricsAvailabilityChanged(result.data.content.biometricsAvailable),
      );
    }
    updateDocumentSafeArea(safeArea);

    const version = result.data.content.nativeAppVersion;
    dispatch(deviceActions.clientVersionChanged(version));

    if (Device.isIOS()) {
      updateDevice('ios', version, isPatientPortal);
    } else if (Device.isAndroid()) {
      updateDevice('android', version, isPatientPortal);
    }
  }

  return result.success;
}

function handlePermissionsUpdated(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = PermissionsUpdatedMessageSchema.safeParse(event);
  if (!result.success) {
    return false;
  }

  const { permissions } = result.data;

  dispatch(deviceActions.permissionsUpdated(permissions));

  return true;
}

function handleAppleHealthUpdated(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = AppleHealthUpdateMessageSchema.safeParse(event);
  if (!result.success) {
    return false;
  }

  dispatch(deviceActions.appleHealthUpdateReceived(result.data));

  return true;
}

function handleLogout(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = LogoutMessageSchema.safeParse(event);
  if (!result.success) {
    return false;
  }
  dispatch(logout());

  return true;
}

function handleNotificationTokenUpdated(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = NotificationTokenUpdatedMessageSchema.safeParse(event);
  if (!result.success) {
    return false;
  }
  if (!result.data.token) {
    return true;
  }
  if (!result.data.hasChanged) {
    return true;
  }

  updatePushToken(undefined, result.data.token);

  if (!isPatientPortal) {
    updateTwoFactorPushTokens(result.data.token);
  }

  return true;
}

function handleStoredUsersRequest(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = RequestStoredUsersMessageSchema.safeParse(event);
  if (!result.success) {
    return false;
  }
  const users = result.data.users;
  if (users) {
    dispatch(deviceActions.storedUsersChanged(users));
  }
  return true;
}

function handlePushNotificationReceived(event: unknown, dispatch: UnwrappedAppDispatch) {
  const result = PushNotificationReceivedMessageSchema.safeParse(event);

  if (!result.success) {
    return false;
  }

  handlePushNotification(result.data.content);

  return true;
}
