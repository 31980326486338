import React, { ReactNode, Ref } from  "react";
import { CSSTransition } from "react-transition-group";
import './style.scss'

export type Animation = 'modal' | 'partial-modal'

// WARNING: whenever you wish to update an animation timing you must also update it in the .scss
const animationDuration: Record<Animation, number> = {
  modal: 350,
  'partial-modal': 350
}

interface AnimatedTransitionProps {
  show?: boolean
  key?: any
  animation: Animation
  children: ReactNode
  nodeRef?: Ref<HTMLElement>
  mountOnEnter?: boolean
  unmountOnExit?: boolean
  appear?: boolean
}

export default ({ show, key, animation, children, nodeRef, mountOnEnter, unmountOnExit, appear }: AnimatedTransitionProps) => {

  return <CSSTransition
      in={show}
      key={key}
      nodeRef={nodeRef}
      classNames={`animated-transition-${animation}`}
      mountOnEnter={mountOnEnter ?? true}
      unmountOnExit={unmountOnExit ?? true}
      timeout={animationDuration[animation]}
      appear={appear ?? true}
      >
        { children }
    </CSSTransition>
}
