import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const DefaultTooltip = ({
                                 title,
                                 placement = 'right',
                                 classes,
                                 children,
                                 open,
                                 arrow = false,
                               }) => {
  return !!title ? (
    <Tooltip
      arrow={arrow}
      open={open}
      placement={placement}
      title={title}
      classes={classes}
      //PopperProps={{disablePortal: true}}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default DefaultTooltip;
