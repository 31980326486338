import { ActionReducerMapBuilder, createAction, createSlice, CreateSliceOptions, Slice, SliceCaseReducers } from "@reduxjs/toolkit";
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers";

/** @warning you cannot add this action your slice, this must be done ONLY through createResettableSlice */
export const resetAction = createAction('ResettableSlices/reset')

const addResetActionToBuilder = <State,>(initialState: State, builder: ActionReducerMapBuilder<NoInfer<State>>) => {
  builder.addCase(resetAction, () => initialState)
}

const addResetActionToConfig = <State, CaseReducers extends SliceCaseReducers<State>, Name extends string = string>(config: CreateSliceOptions<State, CaseReducers, Name>) => {
  const extra = config.extraReducers
  const initialState = typeof config.initialState === 'object' ? config.initialState : (config.initialState as any)()

  if (typeof extra === 'object') {
    extra[resetAction().type] = () => initialState
  } else if (typeof extra === 'function') {
    config.extraReducers = (builder: ActionReducerMapBuilder<NoInfer<State>>) => {
      extra(builder)
      addResetActionToBuilder(initialState, builder)
    }
  } else {
    config.extraReducers = (builder: ActionReducerMapBuilder<NoInfer<State>>) => {
      addResetActionToBuilder(initialState, builder)
    }
  }
}

/**
 * adds resetAction as an extraReducer to the slice
 * resetAction will reset the reducer to its initialState
 */
export function createResettableSlice<State, CaseReducers extends SliceCaseReducers<State>, Name extends string = string>(options: CreateSliceOptions<State, CaseReducers, Name>): Slice<State, CaseReducers, Name> {
  addResetActionToConfig(options)
  return createSlice(options)
}
