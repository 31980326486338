import { BlogPost, Call, ClinicInfo, HelpdeskMessage, InfoArticle } from 'generated/api';
import { socketConnection, SocketConnectionStatus } from 'careand-redux/actions/socketConnection';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrapApi, unwrap } from 'careand-redux/utils/unwrap';
import MiscUtils from 'services/MiscUtils';
import PerformanceUtils from 'services/PerformanceUtils';

export interface ResourceState {
  socketConnection?: SocketConnectionStatus;
  clinic?: ClinicInfo;
  clinics: ClinicInfo[];
  calls: Record<string, Call[] | undefined>;
  helpdeskMessages: HelpdeskMessage[];
  patientHelpdeskMessages: Record<string, HelpdeskMessage[] | undefined>;
  postcode?: { valid: boolean; postcode: string };
  blogPosts: BlogPost[];
  knowledgeBase: InfoArticle[];
}

const initialState: ResourceState = {
  clinics: [],
  calls: {},
  helpdeskMessages: [],
  patientHelpdeskMessages: {},
  blogPosts: [],
  knowledgeBase: [],
};

const slice = createResettableSlice({
  name: 'Resource',
  initialState,
  reducers: {
    receiveUpdatedHelpdeskMessage: unwrap(upsertHelpdeskMessage),
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        api.Resource.setClinicInfo.fulfilled,
        unwrapApi((state, clinic) => {
          const isSameClinic = state.clinic?.id === clinic.id;
          if (isSameClinic) {
            state.clinic = clinic;
          }
          PerformanceUtils.upsert(clinic, state.clinics);
        }),
      )
      .addCase(
        api.Resource.searchKnowledgeBase.fulfilled,
        unwrapApi((state, articles) => {
          state.knowledgeBase = articles;
        }),
      )
      .addCase(
        api.Resource.getClinics.fulfilled,
        unwrapApi((state, clinics) => {
          state.clinics = clinics;
        }),
      )
      .addCase(
        api.Resource.getClinicInfo.fulfilled,
        unwrapApi((state, clinic) => {
          state.clinic = clinic;
          if (clinic) {
            PerformanceUtils.upsert(clinic, state.clinics);
          }
        }),
      )
      .addCase(
        api.Resource.getCalls.fulfilled,
        unwrapApi((state, calls, req) => {
          state.calls[req.patientId] = calls;
        }),
      )
      .addCase(
        api.Resource.submitCallLog.fulfilled,
        unwrapApi((state, calls, req) => {
          state.calls[req.log.patient_id] = calls;
        }),
      )
      .addCase(
        api.Resource.postCodeInServiceArea.fulfilled,
        unwrapApi((state, data, req) => {
          state.postcode = {
            postcode: req.postcode,
            valid: data.value,
          };
        }),
      )
      .addCase(api.Resource.claimHelpdeskMessage.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(api.Resource.replyHelpdeskMessage.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(api.Resource.unarchiveHelpdeskMessage.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(api.Resource.assignHelpdeskMessage.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(api.Resource.transferHelpdeskMessage.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(api.Resource.archiveHelpdeskMessage.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(api.Resource.markHelpdeskMessageRead.fulfilled, unwrap(upsertHelpdeskMessage))
      .addCase(
        api.Resource.getUnarchivedHelpdeskMessages.fulfilled,
        unwrap((state, data) => {
          state.helpdeskMessages = data;
        }),
      )
      .addCase(
        api.Resource.getBlogPosts.fulfilled,
        unwrap((state, data) => {
          state.blogPosts = data;
        }),
      )
      .addCase(
        api.Resource.sendPatientEmail.fulfilled,
        unwrapApi((state, msg, req) => {
          MiscUtils.playSentDocumentSound();
          if (!msg) {
            return
          }
          if (state.patientHelpdeskMessages[req.patientId] === undefined) {
            state.patientHelpdeskMessages[req.patientId] = [];
          }
          PerformanceUtils.upsert(msg, state.patientHelpdeskMessages[req.patientId]!);
        }),
      )
      .addCase(
        api.Resource.forwardHelpdeskMessage.fulfilled,
        unwrapApi((state, msg, req) => {
          MiscUtils.playSentDocumentSound();
          if (msg.patient_id) {
            if (state.patientHelpdeskMessages[msg.patient_id] === undefined) {
              state.patientHelpdeskMessages[msg.patient_id] = [];
            }
            PerformanceUtils.upsert(msg, state.patientHelpdeskMessages[msg.patient_id]!);
          }
        }),
      )
      .addCase(
        api.Resource.getPatientEmails.fulfilled,
        unwrapApi((state, data, req) => {
          state.patientHelpdeskMessages[req.patientId] = data;
        }),
      )
      .addCase(
        socketConnection,
        unwrap((state, status) => {
          state.socketConnection = status;
        }),
      ),
});

function upsertHelpdeskMessage(state: ResourceState, msg: HelpdeskMessage) {
  PerformanceUtils.upsert(msg, state.helpdeskMessages);
}

export const resourceActions = slice.actions;
export default slice.reducer;
