/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeOff
 */
export interface TimeOff {
    /**
     * 
     * @type {number}
     * @memberof TimeOff
     */
    date_start: number;
    /**
     * 
     * @type {number}
     * @memberof TimeOff
     */
    date_end: number;
    /**
     * 
     * @type {string}
     * @memberof TimeOff
     */
    reason?: string;
}

/**
 * Check if a given object implements the TimeOff interface.
 */
export function instanceOfTimeOff(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date_start" in value;
    isInstance = isInstance && "date_end" in value;

    return isInstance;
}

export function TimeOffFromJSON(json: any): TimeOff {
    return TimeOffFromJSONTyped(json, false);
}

export function TimeOffFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeOff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_start': json['date_start'],
        'date_end': json['date_end'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function TimeOffToJSON(value?: TimeOff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_start': value.date_start,
        'date_end': value.date_end,
        'reason': value.reason,
    };
}

