/* eslint-disable prefer-template */
import {isResponseError} from "careand-redux/api/SerializedReponseError";
import MiscUtils from "./MiscUtils";

export const handleResponseSuccess = (title: string, text = title + ' success!') => {
  return MiscUtils.showSuccess(title, text);
}

export const handleResponseError = (error: unknown, title: string, text?: string) => {
  if (text) {
    MiscUtils.showToastrError(title, text);
  } else if (!error) {
    MiscUtils.showToastrError(title, `${title} not found`);
  } else if (error && isResponseError(error) && error.response?.jsonBody) {
    const body = error.response?.jsonBody as any
    let message = body?.message

    if (message?.message) {
      message = message.message
    }

    if (body.results) {
      const errors = body?.results?.errors?.map((error: any) => {
        return error.message;
      }) ?? []
      message += ':\n\n' + errors.join('\n');
    }
    MiscUtils.showToastrError(title, message)
  } else if (isResponseError(error) && error.message) {
    MiscUtils.showToastrError(title, error.message)
  } else {
    const legacyResponse = error as any // legacy, to ensure compatibility with responses from other sources such as stripe
    let message: string = ''
    if (legacyResponse?.error?.response?.body) {
      const body = legacyResponse.error.response.body

      if (body.message.message) {
        message = body.message.message
      }

      if (body.results) {
        const errors = body.results.errors.map((error: { message: any; } | undefined) => {
          return error?.message ?? '';
        });
        message += ':\n\n' + errors.join('\n');
      }
    }
    MiscUtils.showToastrError(title, message)
  }
}

export const handleResponseErrorCurried = (title: string, text?: string) => (error: unknown) => handleResponseError(error, title, text)
