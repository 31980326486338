/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DrugbankIngredient } from './DrugbankIngredient';
import {
    DrugbankIngredientFromJSON,
    DrugbankIngredientFromJSONTyped,
    DrugbankIngredientToJSON,
} from './DrugbankIngredient';
import type { DrugbankProductConceptRegions } from './DrugbankProductConceptRegions';
import {
    DrugbankProductConceptRegionsFromJSON,
    DrugbankProductConceptRegionsFromJSONTyped,
    DrugbankProductConceptRegionsToJSON,
} from './DrugbankProductConceptRegions';
import type { DrugbankRXNorm } from './DrugbankRXNorm';
import {
    DrugbankRXNormFromJSON,
    DrugbankRXNormFromJSONTyped,
    DrugbankRXNormToJSON,
} from './DrugbankRXNorm';

/**
 * 
 * @export
 * @interface DrugbankProductConcept
 */
export interface DrugbankProductConcept {
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    display_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    drugbank_pcid?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    brand?: string;
    /**
     * 
     * @type {number}
     * @memberof DrugbankProductConcept
     */
    level?: number;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    route?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    form?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    strengths?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    standing?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    standing_updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankProductConcept
     */
    standing_active_since?: string;
    /**
     * 
     * @type {DrugbankProductConceptRegions}
     * @memberof DrugbankProductConcept
     */
    regions?: DrugbankProductConceptRegions;
    /**
     * 
     * @type {Array<DrugbankRXNorm>}
     * @memberof DrugbankProductConcept
     */
    rxnorm_concepts?: Array<DrugbankRXNorm>;
    /**
     * 
     * @type {Array<DrugbankIngredient>}
     * @memberof DrugbankProductConcept
     */
    ingredients?: Array<DrugbankIngredient>;
}

/**
 * Check if a given object implements the DrugbankProductConcept interface.
 */
export function instanceOfDrugbankProductConcept(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DrugbankProductConceptFromJSON(json: any): DrugbankProductConcept {
    return DrugbankProductConceptFromJSONTyped(json, false);
}

export function DrugbankProductConceptFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrugbankProductConcept {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'drugbank_pcid': !exists(json, 'drugbank_pcid') ? undefined : json['drugbank_pcid'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'form': !exists(json, 'form') ? undefined : json['form'],
        'strengths': !exists(json, 'strengths') ? undefined : json['strengths'],
        'standing': !exists(json, 'standing') ? undefined : json['standing'],
        'standing_updated_at': !exists(json, 'standing_updated_at') ? undefined : json['standing_updated_at'],
        'standing_active_since': !exists(json, 'standing_active_since') ? undefined : json['standing_active_since'],
        'regions': !exists(json, 'regions') ? undefined : DrugbankProductConceptRegionsFromJSON(json['regions']),
        'rxnorm_concepts': !exists(json, 'rxnorm_concepts') ? undefined : ((json['rxnorm_concepts'] as Array<any>).map(DrugbankRXNormFromJSON)),
        'ingredients': !exists(json, 'ingredients') ? undefined : ((json['ingredients'] as Array<any>).map(DrugbankIngredientFromJSON)),
    };
}

export function DrugbankProductConceptToJSON(value?: DrugbankProductConcept | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'display_name': value.display_name,
        'drugbank_pcid': value.drugbank_pcid,
        'brand': value.brand,
        'level': value.level,
        'route': value.route,
        'form': value.form,
        'strengths': value.strengths,
        'standing': value.standing,
        'standing_updated_at': value.standing_updated_at,
        'standing_active_since': value.standing_active_since,
        'regions': DrugbankProductConceptRegionsToJSON(value.regions),
        'rxnorm_concepts': value.rxnorm_concepts === undefined ? undefined : ((value.rxnorm_concepts as Array<any>).map(DrugbankRXNormToJSON)),
        'ingredients': value.ingredients === undefined ? undefined : ((value.ingredients as Array<any>).map(DrugbankIngredientToJSON)),
    };
}

