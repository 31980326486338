/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HealthDataValue } from './HealthDataValue';
import {
    HealthDataValueFromJSON,
    HealthDataValueFromJSONTyped,
    HealthDataValueToJSON,
} from './HealthDataValue';

/**
 * 
 * @export
 * @interface HealthData
 */
export interface HealthData {
    /**
     * 
     * @type {string}
     * @memberof HealthData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HealthData
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthData
     */
    unit?: string;
    /**
     * 
     * @type {Array<HealthDataValue>}
     * @memberof HealthData
     */
    values?: Array<HealthDataValue>;
}

/**
 * Check if a given object implements the HealthData interface.
 */
export function instanceOfHealthData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function HealthDataFromJSON(json: any): HealthData {
    return HealthDataFromJSONTyped(json, false);
}

export function HealthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(HealthDataValueFromJSON)),
    };
}

export function HealthDataToJSON(value?: HealthData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'unit': value.unit,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(HealthDataValueToJSON)),
    };
}

