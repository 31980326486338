import _ from 'lodash';
import { getAtKeypath } from 'services/keypath';
import { RootState, useAppSelector } from './store';
import {
  MedicationReminderNotification,
  PatientMedication,
  PhysicianPhysicianTypeEnum,
  UserRoleEnum,
} from 'generated/api';
import { createSelector } from '@reduxjs/toolkit';
import { groupMedicationReminderNotifications, PatientData } from './slices/healthRecords';
import CPPUtils from 'services/CPPUtils';
import { PERMISSIONS } from 'services/StaffUtils';
import { useMemo } from 'react';

export const AuthenticatedPatient = (state: RootState) => {
  const id = state.authentication.decodedToken.sub;
  return id ? state.patients.byId[id] : undefined;
};

export const ActivePatient = (state: RootState) => {
  const id = state.authentication.activeUserId;
  return id ? state.patients.byId[id] : undefined;
};

export const GetStaff = (state: RootState, id?: string, shouldFallback = true) => {
  const fallback = id ? undefined : state.authentication.user;
  if (!id && shouldFallback) id = state.authentication.activeUserId;
  if (id) {
    return state.physicians.byId[id] || state.admins.byId[id] || fallback;
  }
};

export const ActivePhysician = (state: RootState) => {
  const id = state.authentication.activeUserId;
  if (id) {
    return state.physicians.byId[id] || state.authentication.user;
  }
};

export const InternOf = (state: RootState) => {
  let activePhysician = ActivePhysician(state);
  return activePhysician?.intern_of;
};

export const ActiveUserHr = (state: RootState) => {
  if (!state.authentication.activeUserId) {
    return undefined;
  }
  return state.healthRecords.byId[state.authentication.activeUserId];
};

export const IsTypePhlebotomist = (state: RootState) => {
  const userId = state.authentication.activeUserId;
  if (!userId) return false;
  return state.physicians.byId[userId]?.physician_type === PhysicianPhysicianTypeEnum.Phlebotomist;
};

export const IsTypeNurse = (state: RootState) => {
  const userId = state.authentication.activeUserId;
  if (!userId) return false;
  return state.physicians.byId[userId]?.physician_type === PhysicianPhysicianTypeEnum.Nurse;
};

export const IsTypeNP = (state: RootState) => {
  const userId = state.authentication.activeUserId;
  if (!userId) return false;
  let user = state.physicians.byId[userId];
  const isPhysician = state.authentication.role === UserRoleEnum.Physician;
  return (
    isPhysician &&
    [undefined, PhysicianPhysicianTypeEnum.NursePractitioner].includes(user?.physician_type)
  );
};

export const Get =
  <K extends string>(keypath: K) =>
  (state: RootState) =>
    getAtKeypath(state, keypath);

export const IsPatient = (state: RootState) => {
  return state.authentication.role === UserRoleEnum.Patient;
};

export const IsAdmin = (state: RootState) => {
  return state.authentication.role === UserRoleEnum.Admin;
};

export const IsPhysician = (state: RootState) => {
  return state.authentication.role === UserRoleEnum.Physician;
};

/// returns MedTracker Notifications for relevant accounts
export const GetUserAndLinkedMedicationReminderNotifications = createSelector(
  (state: RootState) => state.authentication.activeUserId,
  AuthenticatedPatient,
  (state: RootState) => state.healthRecords.byId,
  (activeUserId, authPatient, hr) => {
    const takes = _.memoize(
      (notification: MedicationReminderNotification) =>
        new Set(
          (hr[notification.patientId]?.medicationReminders?.[notification.reminderId]?.takes ?? [])
            .filter((it) => it.value === 'taken')
            .map((it) => it.expected_date || it.date),
        ),
    );
    const isDiscontinued = (notification: MedicationReminderNotification) => {
      const rems = hr?.[notification.patientId]?.medicationReminders;
      const rem = rems?.[notification.reminderId];
      return rem && rem.discontinued_since && rem.discontinued_since < notification.date;
    };
    const isNotDiscontinuedOrHasTake = (notification: MedicationReminderNotification) => {
      const val = !isDiscontinued(notification) || takes(notification).has(notification.date);
      return val;
    };
    const isActiveMaster = activeUserId === authPatient?.id;
    const ids = isActiveMaster
      ? [activeUserId, ...(authPatient?.medication_tracker_settings?.follow_linked_accounts ?? [])]
      : [activeUserId];
    const allNotifications = _(ids)
      .filter()
      .flatMap((userId) => hr[userId!]?.medicationReminderNotifications ?? [])
      .filter(isNotDiscontinuedOrHasTake)
      .filter()
      .value();
    return groupMedicationReminderNotifications(allNotifications);
  },
);

/// returns MedTracker Notifications for relevant accounts
export const GetUserAndLinkedMedicationReminders = createSelector(
  (state: RootState) => state.authentication.activeUserId,
  AuthenticatedPatient,
  (state: RootState) => state.healthRecords.byId,
  (activeUserId, authPatient, hr) => {
    const isActiveMaster = activeUserId === authPatient?.id;
    const ids = isActiveMaster
      ? [activeUserId, ...(authPatient?.medication_tracker_settings?.follow_linked_accounts ?? [])]
      : [activeUserId];
    const reminders: PatientData['medicationReminders'] = {};
    _(ids)
      .filter()
      .map((id) => hr[id!]?.medicationReminders ?? {})
      .flatMap((it) => Object.values(it))
      .filter()
      .forEach((reminder) => {
        reminders[reminder!.id!] = reminder!;
      });
    return reminders;
  },
);

export type PatientMedicationWithPatientId = {
  patientId: string;
  medication: PatientMedication;
};

export const GetPatientMedicationForMedicationTrackerLinkedAccounts = createSelector(
  (state: RootState) => state.authentication.activeUserId,
  AuthenticatedPatient,
  (state: RootState) => state.healthRecords.byId,
  (state: RootState) => state.patients.byId,
  (activeUserId, authPatient, hr, patients) => {
    const ids = [
      activeUserId,
      ...(authPatient?.medication_tracker_settings?.follow_linked_accounts ?? []),
    ];
    return _(ids)
      .filter()
      .flatMap((id) =>
        (hr[id!]?.healthRecords?.medications ?? [])
          .map(
            (med) =>
              ({
                patientId: id!,
                medication: med,
              }) as PatientMedicationWithPatientId,
          )
          .filter(
            (med) =>
              !patients[med.patientId]?.medication_tracker_settings?.dismiss_medications?.includes(
                med.medication.id,
              ),
          ),
      )
      .filter(
        (it) =>
          CPPUtils.isActiveMedication(it.medication) && it.medication.value.prescription_medication,
      )
      .orderBy(['value.date'], ['desc'])
      .value();
  },
);

export const PendingTemplateRequestsCount = (state: RootState) => {
  if (!state.authentication.permissions.includes(PERMISSIONS.super_admin)) {
    return 0;
  }
  return state.templates.templates.reduce((acc, template) => {
    return acc + (template?.is_pending ? 1 : 0);
  }, 0);
};

export const TotalUnreadCount = (patientId: string) => (state: RootState) => {
  return (
    (state.chat.unreadCount[patientId] || 0) +
    (state.healthRecords.byId[patientId]?.unreadCount || 0)
  );
};

export const TotalUnreadCountWithLinked = createSelector(
  ActivePatient,
  (state: RootState) => state.healthRecords.byId,
  (state: RootState) => state.chat.unreadCount,
  (activePatient, hr, chatUnread) => {
    if (!activePatient) {
      return 0;
    }
    const ids = [activePatient.id, ...(activePatient.linked_accounts?.map((it) => it.id) || [])];

    return ids.reduce((acc, pid) => {
      return acc + (chatUnread[pid] || 0) + (hr[pid]?.unreadCount || 0);
    }, 0);
  },
);
