/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentTemplate
 */
export interface DocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    secondary_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    fields?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentTemplate
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    pdf?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    document_type?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentTemplate
     */
    order?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplate
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    clinic_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplate
     */
    hide_document?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplate
     */
    allow_patient_fill_multiple_times?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplate
     */
    change_date_when_edited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplate
     */
    is_pending?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    instructions?: string;
}

/**
 * Check if a given object implements the DocumentTemplate interface.
 */
export function instanceOfDocumentTemplate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentTemplateFromJSON(json: any): DocumentTemplate {
    return DocumentTemplateFromJSONTyped(json, false);
}

export function DocumentTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'secondary_name': !exists(json, 'secondary_name') ? undefined : json['secondary_name'],
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'pdf': !exists(json, 'pdf') ? undefined : json['pdf'],
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
        'document_type': !exists(json, 'document_type') ? undefined : json['document_type'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'hide_document': !exists(json, 'hide_document') ? undefined : json['hide_document'],
        'allow_patient_fill_multiple_times': !exists(json, 'allow_patient_fill_multiple_times') ? undefined : json['allow_patient_fill_multiple_times'],
        'change_date_when_edited': !exists(json, 'change_date_when_edited') ? undefined : json['change_date_when_edited'],
        'is_pending': !exists(json, 'is_pending') ? undefined : json['is_pending'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
    };
}

export function DocumentTemplateToJSON(value?: DocumentTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'secondary_name': value.secondary_name,
        'fields': value.fields,
        'images': value.images,
        'pdf': value.pdf,
        'category_id': value.category_id,
        'document_type': value.document_type,
        'order': value.order,
        'active': value.active,
        'clinic_id': value.clinic_id,
        'hide_document': value.hide_document,
        'allow_patient_fill_multiple_times': value.allow_patient_fill_multiple_times,
        'change_date_when_edited': value.change_date_when_edited,
        'is_pending': value.is_pending,
        'instructions': value.instructions,
    };
}

