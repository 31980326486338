import _ from 'lodash';
import { api } from 'careand-redux/api/ApiThunks';
import { AuthenticatedPatient } from 'careand-redux/selectors';
import { navigationActions, Stacks, Tab } from 'careand-redux/slices/ui/navigation';
import { AppDispatch, RootState, store } from 'careand-redux/store';
import { Patient, TabOrderingSettings } from 'generated/api';
import { getUnwrappedDispatch } from 'careand-redux/useAppDispatch';

export function goToLoginIfNeeded() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const isAuthenticated = getState().navigation.isAuthenticatedRoute

    if (isAuthenticated) {
        navigationActions.pushedAsFirst({ stack: Stacks.Master, to: '/login', animation: 'push' })
    }
  };
}

export function refreshPatients() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const ids = Object.keys(getState().patients.byId);
    if (ids.length > 0) {
      dispatch(api.Patients.getMultiplePatients2({ ids: { values: ids } }));
    }
  };
}

export function dismissMedicationFromTracking(patientMedicationId: string) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const activeUserId = state.authentication.activeUserId!;
    const patient = state.patients.byId[activeUserId];

    if (!patient) {
      return;
    }

    const updated = {
      ...patient,
      medication_tracker_settings: {
        ...(patient.medication_tracker_settings ?? {}),
        dismiss_medications: [
          ...(patient.medication_tracker_settings?.dismiss_medications ?? []),
          patientMedicationId,
        ],
      },
    } satisfies Patient;

    dispatch(api.Patients.updatePatient({ patient: updated, id: patient.id }));
  };
}

export function getMasterAndLinkedPatientsCPP() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const masterPatient = AuthenticatedPatient(state);
    const ids = [masterPatient?.id, ...(masterPatient?.linked_accounts?.map((it) => it.id) || [])];

    _(ids)
      .filter()
      .forEach((patientId) => patientId && dispatch(api.HealthRecords.getCPP({ patientId })));
  };
}

export const refreshMasterAndLinkedCPPDebounced = _.throttle(
  () => {
    const dispatch = getUnwrappedDispatch(store.dispatch);
    dispatch(getMasterAndLinkedPatientsCPP());
  },
  10_000,
  { leading: true, trailing: false },
);
