/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HistoryIssueValue } from './HistoryIssueValue';
import {
    HistoryIssueValueFromJSON,
    HistoryIssueValueFromJSONTyped,
    HistoryIssueValueToJSON,
} from './HistoryIssueValue';

/**
 * 
 * @export
 * @interface HistoryIssueHistory
 */
export interface HistoryIssueHistory {
    /**
     * 
     * @type {number}
     * @memberof HistoryIssueHistory
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof HistoryIssueHistory
     */
    author_role?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryIssueHistory
     */
    author_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryIssueHistory
     */
    action: string;
    /**
     * 
     * @type {HistoryIssueValue}
     * @memberof HistoryIssueHistory
     */
    value: HistoryIssueValue;
}

/**
 * Check if a given object implements the HistoryIssueHistory interface.
 */
export function instanceOfHistoryIssueHistory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function HistoryIssueHistoryFromJSON(json: any): HistoryIssueHistory {
    return HistoryIssueHistoryFromJSONTyped(json, false);
}

export function HistoryIssueHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryIssueHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'author_role': !exists(json, 'author_role') ? undefined : json['author_role'],
        'author_id': !exists(json, 'author_id') ? undefined : json['author_id'],
        'action': json['action'],
        'value': HistoryIssueValueFromJSON(json['value']),
    };
}

export function HistoryIssueHistoryToJSON(value?: HistoryIssueHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'author_role': value.author_role,
        'author_id': value.author_id,
        'action': value.action,
        'value': HistoryIssueValueToJSON(value.value),
    };
}

