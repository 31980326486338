/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatientHandoverPatient } from './PatientHandoverPatient';
import {
    PatientHandoverPatientFromJSON,
    PatientHandoverPatientFromJSONTyped,
    PatientHandoverPatientToJSON,
} from './PatientHandoverPatient';
import type { TaskComment } from './TaskComment';
import {
    TaskCommentFromJSON,
    TaskCommentFromJSONTyped,
    TaskCommentToJSON,
} from './TaskComment';

/**
 * 
 * @export
 * @interface PatientHandover
 */
export interface PatientHandover {
    /**
     * 
     * @type {string}
     * @memberof PatientHandover
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientHandover
     */
    physician_id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientHandover
     */
    assigned_to_id: string;
    /**
     * 
     * @type {string}
     * @memberof PatientHandover
     */
    clinic_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientHandover
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientHandover
     */
    clinic_id_archived?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientHandover
     */
    date_start: number;
    /**
     * 
     * @type {number}
     * @memberof PatientHandover
     */
    date_end: number;
    /**
     * 
     * @type {Array<PatientHandoverPatient>}
     * @memberof PatientHandover
     */
    patients?: Array<PatientHandoverPatient>;
    /**
     * 
     * @type {Array<TaskComment>}
     * @memberof PatientHandover
     */
    comments?: Array<TaskComment>;
}

/**
 * Check if a given object implements the PatientHandover interface.
 */
export function instanceOfPatientHandover(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "physician_id" in value;
    isInstance = isInstance && "assigned_to_id" in value;
    isInstance = isInstance && "clinic_id" in value;
    isInstance = isInstance && "date_start" in value;
    isInstance = isInstance && "date_end" in value;

    return isInstance;
}

export function PatientHandoverFromJSON(json: any): PatientHandover {
    return PatientHandoverFromJSONTyped(json, false);
}

export function PatientHandoverFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientHandover {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'physician_id': json['physician_id'],
        'assigned_to_id': json['assigned_to_id'],
        'clinic_id': json['clinic_id'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'clinic_id_archived': !exists(json, 'clinic_id_archived') ? undefined : json['clinic_id_archived'],
        'date_start': json['date_start'],
        'date_end': json['date_end'],
        'patients': !exists(json, 'patients') ? undefined : ((json['patients'] as Array<any>).map(PatientHandoverPatientFromJSON)),
        'comments': !exists(json, 'comments') ? undefined : ((json['comments'] as Array<any>).map(TaskCommentFromJSON)),
    };
}

export function PatientHandoverToJSON(value?: PatientHandover | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'physician_id': value.physician_id,
        'assigned_to_id': value.assigned_to_id,
        'clinic_id': value.clinic_id,
        'archived': value.archived,
        'clinic_id_archived': value.clinic_id_archived,
        'date_start': value.date_start,
        'date_end': value.date_end,
        'patients': value.patients === undefined ? undefined : ((value.patients as Array<any>).map(PatientHandoverPatientToJSON)),
        'comments': value.comments === undefined ? undefined : ((value.comments as Array<any>).map(TaskCommentToJSON)),
    };
}

