import { authActions } from "careand-redux/slices/auth";
import { deviceActions } from "careand-redux/slices/device";
import { store, useAppSelector } from "careand-redux/store";
import { useAppDispatch } from "careand-redux/useAppDispatch";
import { PatientImage } from "components/Common/UserImage";
import DefaultButton from "components/Utils/DefaultButton";
import DefaultPopup from "components/Utils/DefaultPopup";
import React, { useMemo } from "react"
import PatientUtils from "services/PatientUtils";

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export function ConfirmSwitchAccountPopup () {
  const dispatch = useAppDispatch()
  const patientId = useAppSelector(state => state.device.switchAccountConfirmation)

  const closePopup = () => {
    dispatch(deviceActions.switchAccountConfirmationRequested(undefined))
  }

  const handler = useMemo(() => [!!patientId, closePopup] as const, [patientId])

  const patient = useAppSelector(state => (patientId !== undefined ? state.patients.byId[patientId] : undefined))

  const onClickDismiss = () => {
    closePopup()
  }

  const onClickConfirm = () => {
    dispatch(authActions.changeActiveAccount(patientId!))
    closePopup()
  }

  return <DefaultPopup title='Switch Account' openHandler={handler as Writeable<typeof handler>}>
      <div className='column gap-2 items-center'>
        <PatientImage user={patient} size={110}/>
        <span>{PatientUtils.getName(patient, undefined, false)}</span>
        <span className='mb-3'>{PatientUtils.getOhipNumber(patient, 'No OHIP Card')}</span>
        <DefaultButton name='Switch' onClick={onClickConfirm}/>
        <DefaultButton className='light' name='Dismiss' onClick={onClickDismiss}/>
      </div>
  </DefaultPopup>
}

export function getAccountSwitchPromise(userId: string) {
  const promise = new Promise<boolean>((resolve) => {
    const unsub = store.subscribe(() => {
      const state = store.getState()
      if (state.authentication.activeUserId === userId) {
        resolve(true)
        unsub()
      } else if (state.device.switchAccountConfirmation === undefined) {
        resolve(false)
        unsub()
      }
    })
  })
  return promise
}