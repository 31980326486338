/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Physician } from './Physician';
import {
    PhysicianFromJSON,
    PhysicianFromJSONTyped,
    PhysicianToJSON,
} from './Physician';

/**
 * 
 * @export
 * @interface PrimaryPhysicianSuggestion
 */
export interface PrimaryPhysicianSuggestion {
    /**
     * 
     * @type {Physician}
     * @memberof PrimaryPhysicianSuggestion
     */
    physician: Physician;
    /**
     * 
     * @type {string}
     * @memberof PrimaryPhysicianSuggestion
     */
    availability: PrimaryPhysicianSuggestionAvailabilityEnum;
    /**
     * 
     * @type {string}
     * @memberof PrimaryPhysicianSuggestion
     */
    usual_schedule?: string;
}


/**
 * @export
 */
export const PrimaryPhysicianSuggestionAvailabilityEnum = {
    High: 'high',
    Medium: 'medium',
    Low: 'low'
} as const;
export type PrimaryPhysicianSuggestionAvailabilityEnum = typeof PrimaryPhysicianSuggestionAvailabilityEnum[keyof typeof PrimaryPhysicianSuggestionAvailabilityEnum];


/**
 * Check if a given object implements the PrimaryPhysicianSuggestion interface.
 */
export function instanceOfPrimaryPhysicianSuggestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "physician" in value;
    isInstance = isInstance && "availability" in value;

    return isInstance;
}

export function PrimaryPhysicianSuggestionFromJSON(json: any): PrimaryPhysicianSuggestion {
    return PrimaryPhysicianSuggestionFromJSONTyped(json, false);
}

export function PrimaryPhysicianSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryPhysicianSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'physician': PhysicianFromJSON(json['physician']),
        'availability': json['availability'],
        'usual_schedule': !exists(json, 'usual_schedule') ? undefined : json['usual_schedule'],
    };
}

export function PrimaryPhysicianSuggestionToJSON(value?: PrimaryPhysicianSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physician': PhysicianToJSON(value.physician),
        'availability': value.availability,
        'usual_schedule': value.usual_schedule,
    };
}

