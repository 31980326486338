/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Consultant } from './Consultant';
import {
    ConsultantFromJSON,
    ConsultantFromJSONTyped,
    ConsultantToJSON,
} from './Consultant';
import type { HealthRecordsInformation } from './HealthRecordsInformation';
import {
    HealthRecordsInformationFromJSON,
    HealthRecordsInformationFromJSONTyped,
    HealthRecordsInformationToJSON,
} from './HealthRecordsInformation';
import type { HistoryIssue } from './HistoryIssue';
import {
    HistoryIssueFromJSON,
    HistoryIssueFromJSONTyped,
    HistoryIssueToJSON,
} from './HistoryIssue';
import type { LifestyleFactor } from './LifestyleFactor';
import {
    LifestyleFactorFromJSON,
    LifestyleFactorFromJSONTyped,
    LifestyleFactorToJSON,
} from './LifestyleFactor';
import type { MedicalOrder } from './MedicalOrder';
import {
    MedicalOrderFromJSON,
    MedicalOrderFromJSONTyped,
    MedicalOrderToJSON,
} from './MedicalOrder';
import type { PatientIssue } from './PatientIssue';
import {
    PatientIssueFromJSON,
    PatientIssueFromJSONTyped,
    PatientIssueToJSON,
} from './PatientIssue';
import type { PatientMedication } from './PatientMedication';
import {
    PatientMedicationFromJSON,
    PatientMedicationFromJSONTyped,
    PatientMedicationToJSON,
} from './PatientMedication';

/**
 * 
 * @export
 * @interface HealthRecords
 */
export interface HealthRecords {
    /**
     * 
     * @type {string}
     * @memberof HealthRecords
     */
    patient_id: string;
    /**
     * 
     * @type {HealthRecordsInformation}
     * @memberof HealthRecords
     */
    information?: HealthRecordsInformation;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof HealthRecords
     */
    notes?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof HealthRecords
     */
    medical_issues?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof HealthRecords
     */
    gtpal?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof HealthRecords
     */
    allergies?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<PatientMedication>}
     * @memberof HealthRecords
     */
    medications?: Array<PatientMedication>;
    /**
     * 
     * @type {Array<LifestyleFactor>}
     * @memberof HealthRecords
     */
    lifestyle_factors?: Array<LifestyleFactor>;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof HealthRecords
     */
    preventative_screenings?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<HistoryIssue>}
     * @memberof HealthRecords
     */
    history?: Array<HistoryIssue>;
    /**
     * 
     * @type {Array<Consultant>}
     * @memberof HealthRecords
     */
    consultants?: Array<Consultant>;
    /**
     * 
     * @type {Array<MedicalOrder>}
     * @memberof HealthRecords
     */
    medical_orders?: Array<MedicalOrder>;
}

/**
 * Check if a given object implements the HealthRecords interface.
 */
export function instanceOfHealthRecords(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;

    return isInstance;
}

export function HealthRecordsFromJSON(json: any): HealthRecords {
    return HealthRecordsFromJSONTyped(json, false);
}

export function HealthRecordsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthRecords {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient_id': json['patient_id'],
        'information': !exists(json, 'information') ? undefined : HealthRecordsInformationFromJSON(json['information']),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(PatientIssueFromJSON)),
        'medical_issues': !exists(json, 'medical_issues') ? undefined : ((json['medical_issues'] as Array<any>).map(PatientIssueFromJSON)),
        'gtpal': !exists(json, 'gtpal') ? undefined : ((json['gtpal'] as Array<any>).map(PatientIssueFromJSON)),
        'allergies': !exists(json, 'allergies') ? undefined : ((json['allergies'] as Array<any>).map(PatientIssueFromJSON)),
        'medications': !exists(json, 'medications') ? undefined : ((json['medications'] as Array<any>).map(PatientMedicationFromJSON)),
        'lifestyle_factors': !exists(json, 'lifestyle_factors') ? undefined : ((json['lifestyle_factors'] as Array<any>).map(LifestyleFactorFromJSON)),
        'preventative_screenings': !exists(json, 'preventative_screenings') ? undefined : ((json['preventative_screenings'] as Array<any>).map(PatientIssueFromJSON)),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(HistoryIssueFromJSON)),
        'consultants': !exists(json, 'consultants') ? undefined : ((json['consultants'] as Array<any>).map(ConsultantFromJSON)),
        'medical_orders': !exists(json, 'medical_orders') ? undefined : ((json['medical_orders'] as Array<any>).map(MedicalOrderFromJSON)),
    };
}

export function HealthRecordsToJSON(value?: HealthRecords | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient_id': value.patient_id,
        'information': HealthRecordsInformationToJSON(value.information),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(PatientIssueToJSON)),
        'medical_issues': value.medical_issues === undefined ? undefined : ((value.medical_issues as Array<any>).map(PatientIssueToJSON)),
        'gtpal': value.gtpal === undefined ? undefined : ((value.gtpal as Array<any>).map(PatientIssueToJSON)),
        'allergies': value.allergies === undefined ? undefined : ((value.allergies as Array<any>).map(PatientIssueToJSON)),
        'medications': value.medications === undefined ? undefined : ((value.medications as Array<any>).map(PatientMedicationToJSON)),
        'lifestyle_factors': value.lifestyle_factors === undefined ? undefined : ((value.lifestyle_factors as Array<any>).map(LifestyleFactorToJSON)),
        'preventative_screenings': value.preventative_screenings === undefined ? undefined : ((value.preventative_screenings as Array<any>).map(PatientIssueToJSON)),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(HistoryIssueToJSON)),
        'consultants': value.consultants === undefined ? undefined : ((value.consultants as Array<any>).map(ConsultantToJSON)),
        'medical_orders': value.medical_orders === undefined ? undefined : ((value.medical_orders as Array<any>).map(MedicalOrderToJSON)),
    };
}

