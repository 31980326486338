/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiagnosticResultLine
 */
export interface DiagnosticResultLine {
    /**
     * 
     * @type {string}
     * @memberof DiagnosticResultLine
     */
    test?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticResultLine
     */
    flag?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticResultLine
     */
    result?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosticResultLine
     */
    reference_units?: string;
}

/**
 * Check if a given object implements the DiagnosticResultLine interface.
 */
export function instanceOfDiagnosticResultLine(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiagnosticResultLineFromJSON(json: any): DiagnosticResultLine {
    return DiagnosticResultLineFromJSONTyped(json, false);
}

export function DiagnosticResultLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiagnosticResultLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'test': !exists(json, 'test') ? undefined : json['test'],
        'flag': !exists(json, 'flag') ? undefined : json['flag'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'reference_units': !exists(json, 'reference_units') ? undefined : json['reference_units'],
    };
}

export function DiagnosticResultLineToJSON(value?: DiagnosticResultLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'test': value.test,
        'flag': value.flag,
        'result': value.result,
        'reference_units': value.reference_units,
    };
}

