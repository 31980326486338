import moment from 'moment-timezone';
import PerformanceUtils from 'services/PerformanceUtils';

class CalendarUtils {

  momentToDate(unixDate) {
    if (Number.isInteger(unixDate)) unixDate = moment.unix(unixDate);
    return unixDate.toDate();
  }

  format(unixTime, timeFormat = 'YYYY-MM-DD', timezone) {
    return moment
      .unix(unixTime)
      .tz(timezone ?? this.getTargetTimezone())
      .format(timeFormat);
  }

  fromNow(unixTime) {
    return moment.unix(unixTime).tz(this.getTargetTimezone()).fromNow();
  }

  setToHour(date, hour, minute = 0) {
    return moment(new Date(date.year(), date.month(), date.date(), hour, minute, 0));
  }

  joinDateAndTime(date, time) {
    if (!date || !time) return undefined;

    date = moment.unix(date);
    time = moment.unix(time);

    date.hour(time.hour());
    date.minute(time.minute());
    date.second(time.second());

    let convertedDate = this.convertFromTargetTimezone(date);
    if (convertedDate.date() < date.date()) {
      date.date(date.date() + 1);
    }

    return date.unix();
  }

  convertFromTargetTimezone(datetime, target = this.getTargetTimezone()) {
    const m = moment.tz(datetime, target);
    return moment(new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0));
  }

  convertToTargetTimezone(date, target = this.getTargetTimezone()) {
    const dateM = moment.tz(date, this.getCurrentTimezone());
    return moment.tz(
      {
        year: dateM.year(),
        month: dateM.month(),
        date: dateM.date(),
        hour: dateM.hour(),
        minute: dateM.minute(),
      },
      target,
    );
  }

  getCurrentTimezone() {
    return CURRENT_TIMEZONE;
  }

  getTargetTimezone() {
    return TARGET_TIMEZONE;
  }

  isToday(momentDate) {
    return this.isSameDay(momentDate, moment());
  }

  isSameDay(momentDate1, momentDate2) {
    let format = 'YYYYMMDD';
    return momentDate1.utc().format(format) === momentDate2.utc().format(format);
  }

  formatMilitaryTime(militaryTime, timeFormat = 'h:mm A') {
    let time = '' + militaryTime;
    if (time.length === 1) time = '0' + time;
    if (time.length === 3) time = '0' + time;
    while (time.length < 4) time = time + '0';
    return moment(time, 'HHmm').format(timeFormat);
  }

  getMilitaryTime(dateUnix, timezone) {
    const date = moment.unix(dateUnix);
    if (timezone) {
      date.tz(timezone);
    }
    return date.format('HHmm');
  }

  militaryTimeToUnix(dateUnix, militaryTime, timezone = this.getTargetTimezone()) {
    // console.log('t', timezone)
    const MILITARY_FORMAT = 'HHmm';
    militaryTime = militaryTime + '';
    if (militaryTime.length === 1) militaryTime = '0' + militaryTime;
    if (militaryTime.length === 3) militaryTime = '0' + militaryTime;
    while (militaryTime.length < 4) militaryTime = militaryTime + '0';

    let date = moment.unix(dateUnix);

    let militaryMoment = moment(militaryTime, MILITARY_FORMAT);
    date.hour(militaryMoment.hour());
    date.minute(militaryMoment.minute());
    date.second(militaryMoment.second());

    date = this.convertToTargetTimezone(date, timezone);

    return date.unix();
  }

  filterShifts(shifts, date, clinicId) {
    const formattedDate = this.format(date.unix(), 'YYYYMMDD');
    const filteredShifts = shifts.filter(shift => {
      const matchesDate = shift.date_string === formattedDate;
      const matchesClinic = !clinicId || shift.clinic_id === clinicId;
      return matchesDate && matchesClinic;
    });
    return PerformanceUtils.sortBy(filteredShifts, ['physician_id']);
  }

  calculateAgeNumber(unixDoB, unixTime = moment().unix(), format = 'years') {
    let birthday = moment.unix(unixDoB);
    let targetDate = moment.unix(unixTime);
    return targetDate.diff(birthday, format);
  }

  getUTCDay(unix) {
    let value = moment.unix(unix).utc();
    value.hour(12);
    return this.convertFromTargetTimezone(value).unix();
  }

  calculateAge(unixDoB, unixTime = moment().unix(), detailed = true) {
    let age;

    let years = this.calculateAgeNumber(unixDoB, unixTime, 'years');
    let months = this.calculateAgeNumber(unixDoB, unixTime, 'months');

    if (months <= 1) {
      let days = this.calculateAgeNumber(unixDoB, unixTime, 'days');
      age = days + ' d';
    } else if (years === 0) {
      age = months + ' mo';
    } else if (years >= 18) {
      age = years + ' yr';
    } else {
      months -= years * 12;
      age = years + ' yr';
      if (detailed) age += ' + ' + months + ' mo';
    }

    return age;
  }

  toSortableDateString = (t, timezone = this.getCurrentTimezone()) => {
    if (moment.isMoment(t)) {
      t = t.clone().tz(timezone);
    } else {
      t = moment.unix(t).tz(timezone);
    }
    return t.format('YYYYMMDD');
  };
}

const CURRENT_TIMEZONE = moment.tz.guess();
const TARGET_TIMEZONE = 'America/Toronto';

export default new CalendarUtils();
