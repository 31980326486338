import moment from 'moment-timezone';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import MiscUtils from 'services/MiscUtils';
import CalendarUtils from 'services/CalendarUtils';
import imageFemale from 'assets/img/icons/avatar-female.svg';
import imageMale from 'assets/img/icons/avatar-male.svg';

class PatientUtils {
  getName(patient = {}, errorPlaceholder = undefined, includeShortName = true, fullName = true) {
    let error = errorPlaceholder;

    if (!patient) return error;

    let name = '';

    let { firstName, secondName, lastName } = patient.ministry_ohip || {};

    if (!!firstName) name = firstName;
    if (fullName && !!secondName) name += ' ' + secondName;
    if (!!lastName) name += ' ' + lastName;
    if (includeShortName) {
      let elements = [];
      if (!!patient.preferred_name) {
        elements.push(patient.preferred_name.trim());
      }
      if (!!patient.preferred_pronoun) {
        elements.push(patient.preferred_pronoun.trim());
      }
      if (elements.length > 0) {
        name += ' (' + elements.join(', ') + ')';
      }
    }

    name = name.trim();

    if (name === '') name = error;
    if (name === error && errorPlaceholder !== undefined) name = errorPlaceholder;

    if (!name) name = 'Unknown Name';

    return name;
  }

  matomoPushInstruction(eventName, ...vals) {
    if (window._paq) {
      window._paq.push([
        eventName,
       ...vals,
      ]);
    }
  }

  async getMatomoVisitorId() {
    if (window._paq) {
      return new Promise((resolve) => {
        window._paq.push([
          function () {
            resolve(this.getVisitorId());
          },
        ]);
      })
    }
  }

  getPreferredName(patient = {}) {
    if (patient.preferred_name) return patient.preferred_name;
    return this.getName(patient);
  }

  getShortName(patient, errorPlaceholder = undefined) {
    let error = undefined;
    let name = this.getName(patient, undefined, false);

    if (name !== undefined) {
      let split = name.split(' ');

      name = split[0];

      if (split.length > 1) name += ' ' + split[split.length - 1].charAt(0) + '.';

      name = name.trim();

      if (name === '') name = error;
    }

    if (name === error && errorPlaceholder !== undefined) name = errorPlaceholder;

    return name;
  }

  getInitials(patient = {}) {
    let { firstName, lastName } = patient.ministry_ohip || {};
    return (firstName || ' ')[0] + (lastName || ' ')[0];
  }

  getAge(patient) {
    let age = 'Unknown Age';

    if (patient === undefined) return age;

    let { dateOfBirth } = patient.ministry_ohip || {};
    let { date_deceased } = patient;

    if (dateOfBirth !== undefined && dateOfBirth !== 0) {
      age = CalendarUtils.calculateAge(dateOfBirth, date_deceased);
    }

    return age;
  }

  getAgeAsNumber(patient) {
    let age = undefined;

    if (patient === undefined) return age;

    let { dateOfBirth } = patient.ministry_ohip || {};
    let { date_deceased } = patient;

    if (dateOfBirth !== undefined && dateOfBirth !== 0) {
      age = CalendarUtils.calculateAgeNumber(dateOfBirth, date_deceased);
    }

    return age;
  }

  getBirthdate(patient, format = 'Do MMMM YYYY', includeAge = true) {
    let date = 'Unknown';

    if (patient === undefined) return date;

    let { dateOfBirth } = patient.ministry_ohip || {};

    if (dateOfBirth !== undefined && dateOfBirth !== 0) {
      let value = CalendarUtils.getUTCDay(dateOfBirth);
      date = CalendarUtils.format(value, format);
      if (includeAge) date += ` (${this.getAge(patient)})`;
    }

    return date;
  }

  getOhipNumber(patient = {}, placeholder) {
    return this.getOhipNumberFromCard(patient.ministry_ohip, undefined, placeholder);
  }

  getOhipNumberFromCard(ministry_ohip, undefinedOnError = false, placeholder = 'N/A') {
    let ohip = placeholder;

    if (ministry_ohip === undefined) {
      if (undefinedOnError) return undefined;
      return ohip;
    }

    let { healthNumber, versionCode } = ministry_ohip || {};

    if (healthNumber !== undefined) ohip = healthNumber;
    if (versionCode !== undefined) ohip += versionCode;

    return ohip;
  }

  getPhotoUrls(patient) {
    return [
      patient?.photo,
      this.getGender(patient) === 'Female' ? imageFemale : imageMale,
    ];
  }

  getAddress(patient) {
    let address = '';

    if (patient && patient.address) {
      let elements = [];
      if (patient.address.line1) elements.push(patient.address.line1);
      if (patient.address.line2) elements.push(patient.address.line2);
      if (patient.address.postcode) elements.push(patient.address.postcode);
      if (patient.address.city) elements.push(patient.address.city);
      if (patient.address.state) elements.push(patient.address.state);
      if (patient.address.country) elements.push(patient.address.country);

      address = elements.join(', ');
    }

    return address;
  }

  getContact(patient, includeSecondary = true) {
    let result = '';

    if (patient.phone) {
      let phone = parsePhoneNumberFromString(patient.phone);
      if (phone) phone = phone.formatNational();
      if (phone) {
        result = phone;
      }
    }

    if (includeSecondary && patient.phone2) {
      let phone = parsePhoneNumberFromString(patient.phone2);
      if (phone) phone = phone.formatNational();
      if (result) result += ' / ';
      if (phone) {
        result += phone;
      }
    }

    if (!result) result = 'N/A';

    return result;
  }

  getGender(patient, short = false) {
    let result = 'Unknown Gender';
    if (patient === undefined) return result;
    let { gender } = patient.ministry_ohip || {};
    if (gender !== undefined) result = gender;
    if (short) result = result.charAt(0);
    return result;
  }

  /** @param {Appointment[]} appointments*/
  countUnreadAppointments(appointments) {
    return appointments.reduce((acc, next) => {
      return acc + (next.read ? 0 : 1);
    }, 0);
  }

  countUnreadHealthRecords(healthRecords) {
    const EXCLUDE = [
      'dataSummary',
      'refills',
      'medicationReminderNotifications',
      'medicationReminders',
      'menstruation',
      'administered_drugs',
    ];
    let count = 0;
    for (let index in healthRecords) {
      if (!EXCLUDE.includes(index)) count += this.countUnread(healthRecords[index]);
    }
    return count;
  }

  countUnread(data = []) {
    if (!data.forEach) return 0;
    return data
      .map(({read}) => read ? 0 : 1)
      .reduce((acc, next) => acc + next, 0);
  }

  hasValidOhipCard(patient) {
    return patient?.ministry_ohip?.validCard === true && !this.hasCardExpired(patient);
  }

  hasValidAddress(patient) {
    return patient.address !== undefined && !!patient.address.line1 && !!patient.address.postcode;
  }

  hasCardExpired(patient) {
    let expiry = patient?.ministry_ohip?.expiryDate || [];
    expiry = expiry[0];
    if (expiry) return expiry < moment().unix();
    return false;
  }

  ohipCardNotice(patient) {
    if (patient?.ministry_ohip?.responseID === 'HAS_NOTICE') {
      return `${patient.ministry_ohip.responseDescription}. ${patient.ministry_ohip.responseAction}`;
    }
  }

  showNotificationIfOhipInvalid(patient) {
    const isExpired = this.hasCardExpired(patient);
    const notice = this.ohipCardNotice(patient);
    if (isExpired) {
      let name = this.getName(patient);
      let date = CalendarUtils.format(patient.ministry_ohip.expiryDate);
      MiscUtils.showToastrInfo({
        title: 'OHIP Expired',
        message: `${name}'s OHIP card expired on ${date}`,
      });
    }
    if (!!notice) {
      MiscUtils.showToastrInfo({
        title: 'OHIP Notice',
        message: notice,
      });
    }
  }
}

export default new PatientUtils();
