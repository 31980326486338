/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TwoFactorValidateLoginPayload
 */
export interface TwoFactorValidateLoginPayload {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorValidateLoginPayload
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorValidateLoginPayload
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorValidateLoginPayload
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TwoFactorValidateLoginPayload
     */
    approve?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorValidateLoginPayload
     */
    login_session?: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorValidateLoginPayload
     */
    login_for?: string;
}

/**
 * Check if a given object implements the TwoFactorValidateLoginPayload interface.
 */
export function instanceOfTwoFactorValidateLoginPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TwoFactorValidateLoginPayloadFromJSON(json: any): TwoFactorValidateLoginPayload {
    return TwoFactorValidateLoginPayloadFromJSONTyped(json, false);
}

export function TwoFactorValidateLoginPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwoFactorValidateLoginPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'approve': !exists(json, 'approve') ? undefined : json['approve'],
        'login_session': !exists(json, 'login_session') ? undefined : json['login_session'],
        'login_for': !exists(json, 'login_for') ? undefined : json['login_for'],
    };
}

export function TwoFactorValidateLoginPayloadToJSON(value?: TwoFactorValidateLoginPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'code': value.code,
        'approve': value.approve,
        'login_session': value.login_session,
        'login_for': value.login_for,
    };
}

