/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Corporation,
  CorporationBatch,
  CorporationBatchAdd,
  CorporationCoupon,
  CorporationPatient,
  CorporationUnpaidBatch,
  ErrorResponse,
  Patient,
} from '../models';
import {
    CorporationFromJSON,
    CorporationToJSON,
    CorporationBatchFromJSON,
    CorporationBatchToJSON,
    CorporationBatchAddFromJSON,
    CorporationBatchAddToJSON,
    CorporationCouponFromJSON,
    CorporationCouponToJSON,
    CorporationPatientFromJSON,
    CorporationPatientToJSON,
    CorporationUnpaidBatchFromJSON,
    CorporationUnpaidBatchToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PatientFromJSON,
    PatientToJSON,
} from '../models';

export interface AddCorporationRequest {
    corporation: Corporation;
}

export interface AddCorporationBatchRequest {
    id: string;
    data: CorporationBatchAdd;
}

export interface AddPatientToCorporationCodeRequest {
    id: string;
    codeId: string;
    patientId: string;
}

export interface ApplyCorporationCodeRequest {
    code: string;
    patientId: string;
}

export interface GetCorporationBatchesRequest {
    id: string;
}

export interface GetCorporationCodesRequest {
    id: string;
}

export interface GetCorporationPatientsRequest {
    id: string;
}

export interface RemoveCorporationCodeRequest {
    id: string;
    codeId: string;
}

export interface UpdateCorporationRequest {
    corporation: Corporation;
}

export interface UpdateCorporationBatchRequest {
    id: string;
    batchId: string;
    batch: CorporationBatch;
}

/**
 * 
 */
export class CorporationsApi extends runtime.BaseAPI {

    /**
     * Adds a corporation (Roles: admin)
     */
    async addCorporationRaw(requestParameters: AddCorporationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>> {
        if (requestParameters.corporation === null || requestParameters.corporation === undefined) {
            throw new runtime.RequiredError('corporation','Required parameter requestParameters.corporation was null or undefined when calling addCorporation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CorporationToJSON(requestParameters.corporation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addCorporationRequestOptionsRaw(requestParameters: AddCorporationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.corporation === null || requestParameters.corporation === undefined) {
            throw new runtime.RequiredError('corporation','Required parameter requestParameters.corporation was null or undefined when calling addCorporation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CorporationToJSON(requestParameters.corporation),
        };
        return responseConfig
    }

    /**
     * Adds a corporation (Roles: admin)
     */
    async addCorporation(requestParameters: AddCorporationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation> {
        const response = await this.addCorporationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new batch of users to the corporation (Roles: admin)
     */
    async addCorporationBatchRaw(requestParameters: AddCorporationBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CorporationBatch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addCorporationBatch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addCorporationBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/batches`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CorporationBatchAddToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationBatchFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addCorporationBatchRequestOptionsRaw(requestParameters: AddCorporationBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addCorporationBatch.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addCorporationBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/batches`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CorporationBatchAddToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Adds a new batch of users to the corporation (Roles: admin)
     */
    async addCorporationBatch(requestParameters: AddCorporationBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CorporationBatch> {
        const response = await this.addCorporationBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a corporation code (Roles: admin)
     */
    async addPatientToCorporationCodeRaw(requestParameters: AddPatientToCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CorporationCoupon>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPatientToCorporationCode.');
        }

        if (requestParameters.codeId === null || requestParameters.codeId === undefined) {
            throw new runtime.RequiredError('codeId','Required parameter requestParameters.codeId was null or undefined when calling addPatientToCorporationCode.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addPatientToCorporationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/codes/{codeId}/patient/{patientId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"codeId"}}`, encodeURIComponent(String(requestParameters.codeId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationCouponFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPatientToCorporationCodeRequestOptionsRaw(requestParameters: AddPatientToCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPatientToCorporationCode.');
        }

        if (requestParameters.codeId === null || requestParameters.codeId === undefined) {
            throw new runtime.RequiredError('codeId','Required parameter requestParameters.codeId was null or undefined when calling addPatientToCorporationCode.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addPatientToCorporationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/codes/{codeId}/patient/{patientId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"codeId"}}`, encodeURIComponent(String(requestParameters.codeId))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Adds a corporation code (Roles: admin)
     */
    async addPatientToCorporationCode(requestParameters: AddPatientToCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CorporationCoupon> {
        const response = await this.addPatientToCorporationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Applies a corporation code (Roles: patient)
     */
    async applyCorporationCodeRaw(requestParameters: ApplyCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling applyCorporationCode.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling applyCorporationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/codes/{code}/patient/{patientId}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    applyCorporationCodeRequestOptionsRaw(requestParameters: ApplyCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling applyCorporationCode.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling applyCorporationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/codes/{code}/patient/{patientId}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Applies a corporation code (Roles: patient)
     */
    async applyCorporationCode(requestParameters: ApplyCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.applyCorporationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all corporation batches (Roles: admin)
     */
    async getCorporationBatchesRaw(requestParameters: GetCorporationBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CorporationBatch>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCorporationBatches.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/batches`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorporationBatchFromJSON));
    }

    /**
        Request Config Getter
     */
    getCorporationBatchesRequestOptionsRaw(requestParameters: GetCorporationBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCorporationBatches.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/batches`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all corporation batches (Roles: admin)
     */
    async getCorporationBatches(requestParameters: GetCorporationBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CorporationBatch>> {
        const response = await this.getCorporationBatchesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all corporation codes (Roles: admin)
     */
    async getCorporationCodesRaw(requestParameters: GetCorporationCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CorporationCoupon>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCorporationCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/codes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorporationCouponFromJSON));
    }

    /**
        Request Config Getter
     */
    getCorporationCodesRequestOptionsRaw(requestParameters: GetCorporationCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCorporationCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/codes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all corporation codes (Roles: admin)
     */
    async getCorporationCodes(requestParameters: GetCorporationCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CorporationCoupon>> {
        const response = await this.getCorporationCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all patients of a corporation (Roles: admin)
     */
    async getCorporationPatientsRaw(requestParameters: GetCorporationPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CorporationPatient>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCorporationPatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/patients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorporationPatientFromJSON));
    }

    /**
        Request Config Getter
     */
    getCorporationPatientsRequestOptionsRaw(requestParameters: GetCorporationPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCorporationPatients.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/patients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all patients of a corporation (Roles: admin)
     */
    async getCorporationPatients(requestParameters: GetCorporationPatientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CorporationPatient>> {
        const response = await this.getCorporationPatientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all corporations (Roles: admin)
     */
    async getCorporationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Corporation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorporationFromJSON));
    }

    /**
        Request Config Getter
     */
    getCorporationsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all corporations (Roles: admin)
     */
    async getCorporations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Corporation>> {
        const response = await this.getCorporationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all unpaid batches (Roles: admin)
     */
    async getUnpaidBatchesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CorporationUnpaidBatch>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/unpaid-batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CorporationUnpaidBatchFromJSON));
    }

    /**
        Request Config Getter
     */
    getUnpaidBatchesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/unpaid-batches`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all unpaid batches (Roles: admin)
     */
    async getUnpaidBatches(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CorporationUnpaidBatch>> {
        const response = await this.getUnpaidBatchesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Removes a corporation code (Roles: admin)
     */
    async removeCorporationCodeRaw(requestParameters: RemoveCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeCorporationCode.');
        }

        if (requestParameters.codeId === null || requestParameters.codeId === undefined) {
            throw new runtime.RequiredError('codeId','Required parameter requestParameters.codeId was null or undefined when calling removeCorporationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/codes/{codeId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"codeId"}}`, encodeURIComponent(String(requestParameters.codeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removeCorporationCodeRequestOptionsRaw(requestParameters: RemoveCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeCorporationCode.');
        }

        if (requestParameters.codeId === null || requestParameters.codeId === undefined) {
            throw new runtime.RequiredError('codeId','Required parameter requestParameters.codeId was null or undefined when calling removeCorporationCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/codes/{codeId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"codeId"}}`, encodeURIComponent(String(requestParameters.codeId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a corporation code (Roles: admin)
     */
    async removeCorporationCode(requestParameters: RemoveCorporationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeCorporationCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Updates a corporation (Roles: admin)
     */
    async updateCorporationRaw(requestParameters: UpdateCorporationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>> {
        if (requestParameters.corporation === null || requestParameters.corporation === undefined) {
            throw new runtime.RequiredError('corporation','Required parameter requestParameters.corporation was null or undefined when calling updateCorporation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CorporationToJSON(requestParameters.corporation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateCorporationRequestOptionsRaw(requestParameters: UpdateCorporationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.corporation === null || requestParameters.corporation === undefined) {
            throw new runtime.RequiredError('corporation','Required parameter requestParameters.corporation was null or undefined when calling updateCorporation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation`,
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CorporationToJSON(requestParameters.corporation),
        };
        return responseConfig
    }

    /**
     * Updates a corporation (Roles: admin)
     */
    async updateCorporation(requestParameters: UpdateCorporationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation> {
        const response = await this.updateCorporationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a corporation batch (Roles: admin)
     */
    async updateCorporationBatchRaw(requestParameters: UpdateCorporationBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CorporationBatch>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCorporationBatch.');
        }

        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling updateCorporationBatch.');
        }

        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling updateCorporationBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/corporation/{id}/batches/{batchId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CorporationBatchToJSON(requestParameters.batch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationBatchFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateCorporationBatchRequestOptionsRaw(requestParameters: UpdateCorporationBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCorporationBatch.');
        }

        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling updateCorporationBatch.');
        }

        if (requestParameters.batch === null || requestParameters.batch === undefined) {
            throw new runtime.RequiredError('batch','Required parameter requestParameters.batch was null or undefined when calling updateCorporationBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/corporation/{id}/batches/{batchId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CorporationBatchToJSON(requestParameters.batch),
        };
        return responseConfig
    }

    /**
     * Updates a corporation batch (Roles: admin)
     */
    async updateCorporationBatch(requestParameters: UpdateCorporationBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CorporationBatch> {
        const response = await this.updateCorporationBatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
