import iOSParser from './iOSPushNotifications';
import androidParser from './androidPushNotifications';
import { Device } from 'services/Native/Device';
import { tryParse } from './PushNotifications';
import { getNavStackHandler, useNavStack } from 'careand-redux/slices/ui/hooks';
import { store } from 'careand-redux/store';
import { getUnwrappedDispatch } from 'careand-redux/useAppDispatch';
import { navigationActions, Stacks, Tabs } from 'careand-redux/slices/ui/navigation';
import { api } from 'careand-redux/api/ApiThunks';
import { U } from 'chart.js/dist/chunks/helpers.core';
import { deviceActions } from 'careand-redux/slices/device';
import { getAccountSwitchPromise } from 'scenes/HomePatient2/SwitchProfile/ConfirmationPopup';
import { AuthenticatedPatient } from 'careand-redux/selectors';
import { JitsiMeetingPayload } from 'generated/api';

export async function handlePushNotification(push: unknown) {
  console.log('handling push:', push);
  if (!Device.isMobile()) {
    return;
  }
  const parser = getParser()!;
  const state = store.getState();
  const { loggedIn, activeUserId, user } = state.authentication;

  if (!loggedIn) {
    return;
  }

  const dispatch = getUnwrappedDispatch(store.dispatch);
  const masterNav = getNavStackHandler(Stacks.Master, dispatch);

  /// Returns wether the account has been switch to the correct one
  async function switchToLinkedAccountIfNeeded(userId: string | undefined) {
    if (!userId || userId === activeUserId) {
      return true;
    }
    const activeIsMaster = user?.id === activeUserId;
    const linkedAccounts = (user?.id && state.patients.byId?.[user.id]?.linked_accounts) || [];
    const isLinkedAccount = linkedAccounts.find((it) => it.id === userId) !== undefined;

    const shouldSwitch =
      (activeIsMaster && isLinkedAccount) || (!activeIsMaster && userId === user?.id);

    if (shouldSwitch) {
      await new Promise((fulfill) => {
        fulfill(0);
      });
      dispatch(deviceActions.switchAccountConfirmationRequested(userId!));
      return getAccountSwitchPromise(userId!);
    }

    return true;
  }

  if (tryParse(parser.appointmentUpdated, push)) {
    const apptsNav = getNavStackHandler(Stacks.Appointments, dispatch);

    if (!(await switchToLinkedAccountIfNeeded(push.object.userId))) {
      return;
    }

    apptsNav.popToRoot();
    apptsNav.push(`/${push.object.id}`);
    dispatch(navigationActions.activeTabChanged({ tab: Tabs.Appointments }));
    masterNav.popTo('/');
  } else if (tryParse(parser.chatUpdated, push)) {
    if (!(await switchToLinkedAccountIfNeeded(push.object.userId))) {
      return;
    }
    masterNav.push(`/chat/${push.object.roomName}`);
  } else if (tryParse(parser.hrUpdated, push)) {
    if (!(await switchToLinkedAccountIfNeeded(push.object.userId))) {
      return;
    }

    const hrNav = getNavStackHandler(Stacks.HR, dispatch);
    let type = push.type + 's';
    if (push.type === 'pending_document') {
      type = 'documents';
    }

    let actualId = push.object.id;

    const cpp = await dispatch(api.HealthRecords.getCPP({ patientId: activeUserId! }));
    if (push.type === 'prescription') {
      actualId = cpp?.prescriptions?.[0].id ?? push.object.id;
    }

    hrNav.popToRoot();
    hrNav.push(`/records/${push.object.userId}/${type}/${actualId}`);
    dispatch(navigationActions.activeTabChanged({ tab: Tabs.HR }));
    masterNav.popTo('/');
  } else if (tryParse(parser.joinTelemedicine, push)) {
    const appointmentId = push.object.id;
    let payload = await dispatch(
      api.Video.joinVideoAppointment2({ appointmentId: appointmentId }),
      {
        errorTitle: 'Join Appointment',
      },
    );
    Device.showTelemedicine(payload);
  } else if (tryParse(parser.other, push)) {
    const hrNav = getNavStackHandler(Stacks.HR, dispatch);
    const menstruationNav = getNavStackHandler(Stacks.MenstruationTracker, dispatch);
    switch (push.type) {
      case 'health_records':
        dispatch(navigationActions.activeTabChanged({ tab: Tabs.HR }));
        masterNav.popTo('/');
        break;
      case 'menstruation':
        menstruationNav.popToRoot();
        dispatch(navigationActions.activeTabChanged({ tab: Tabs.MenstruationTracker }));
        break;
      case 'subscription':
        masterNav.popTo('/');
        masterNav.push('/billing');
        break;
      case 'version_update':
        break;
      default:
        break;
    }
  } else if (tryParse(parser.medicationReminder, push)) {
    const userId = push.object.userId;
    const activeUserId = store.getState().authentication.activeUserId;
    const authenticatedUser = AuthenticatedPatient(store.getState());
    const isFromFollowingLinked = (
      authenticatedUser?.medication_tracker_settings?.follow_linked_accounts ?? []
    )?.includes(push.object.userId);
    const isActiveMaster = activeUserId === authenticatedUser?.id;

    const shouldNotSwitch = userId === activeUserId || (isActiveMaster && isFromFollowingLinked);

    if (!shouldNotSwitch) {
      await new Promise((fulfill) => {
        fulfill(0);
      });
      dispatch(deviceActions.switchAccountConfirmationRequested(userId!));
      const switched = await getAccountSwitchPromise(userId!);
      if (!switched) {
        return;
      }
    }

    dispatch(navigationActions.activeTabChanged({ tab: Tabs.Home }));
    masterNav.popTo('/');
  } else {
    //is none
  }
}

function getParser() {
  if (Device.isAndroid()) {
    return androidParser;
  }
  if (Device.isIOS()) {
    return iOSParser;
  }
}
