/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfaxConfig
 */
export interface EfaxConfig {
    /**
     * 
     * @type {string}
     * @memberof EfaxConfig
     */
    account_id: string;
    /**
     * 
     * @type {string}
     * @memberof EfaxConfig
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EfaxConfig
     */
    password: string;
}

/**
 * Check if a given object implements the EfaxConfig interface.
 */
export function instanceOfEfaxConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "account_id" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;

    return isInstance;
}

export function EfaxConfigFromJSON(json: any): EfaxConfig {
    return EfaxConfigFromJSONTyped(json, false);
}

export function EfaxConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfaxConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account_id': json['account_id'],
        'username': json['username'],
        'password': json['password'],
    };
}

export function EfaxConfigToJSON(value?: EfaxConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account_id': value.account_id,
        'username': value.username,
        'password': value.password,
    };
}

