import { Invoice, Receipt } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrapApi } from "careand-redux/utils/unwrap"
import PerformanceUtils from "services/PerformanceUtils"

export interface InvoicesState {
  invoices: Record<string, Invoice[] | undefined>
  receipts: Record<string, Receipt[] | undefined>
}

const initialState: InvoicesState = {
  invoices: {},
  receipts: {}
}

const slice = createResettableSlice({
  name: 'Invoices',
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(api.Invoices.getInvoices.fulfilled, unwrapApi((state, invoices, req) => {
      state.invoices[req.patientId] = invoices
    }))
    .addCase(api.Invoices.getReceipts.fulfilled, unwrapApi((state, receipts, req) => {
      state.receipts[req.patientId] = receipts
    }))
    .addCase(api.Payments.chargePatient.fulfilled, unwrapApi((state, invoice, req) => {
      if (state.invoices[req.patientId] === undefined) {
        state.invoices[req.patientId] = []
      }
      PerformanceUtils.upsert(invoice, state.invoices[req.patientId]!)
    }))

})

export const invoiceActions = slice.actions
export default slice.reducer
