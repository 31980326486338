/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Admin } from './Admin';
import {
    AdminFromJSON,
    AdminFromJSONTyped,
    AdminToJSON,
} from './Admin';
import type { Authentication } from './Authentication';
import {
    AuthenticationFromJSON,
    AuthenticationFromJSONTyped,
    AuthenticationToJSON,
} from './Authentication';

/**
 * 
 * @export
 * @interface AdminWithAuth
 */
export interface AdminWithAuth {
    /**
     * 
     * @type {Admin}
     * @memberof AdminWithAuth
     */
    admin: Admin;
    /**
     * 
     * @type {Authentication}
     * @memberof AdminWithAuth
     */
    auth: Authentication;
}

/**
 * Check if a given object implements the AdminWithAuth interface.
 */
export function instanceOfAdminWithAuth(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "admin" in value;
    isInstance = isInstance && "auth" in value;

    return isInstance;
}

export function AdminWithAuthFromJSON(json: any): AdminWithAuth {
    return AdminWithAuthFromJSONTyped(json, false);
}

export function AdminWithAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminWithAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'admin': AdminFromJSON(json['admin']),
        'auth': AuthenticationFromJSON(json['auth']),
    };
}

export function AdminWithAuthToJSON(value?: AdminWithAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'admin': AdminToJSON(value.admin),
        'auth': AuthenticationToJSON(value.auth),
    };
}

