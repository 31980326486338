import { LockedDocument, PatientDocument } from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrapApi, unwrap } from 'careand-redux/utils/unwrap';
import PerformanceUtils from 'services/PerformanceUtils';

export interface DocumentsState {
  flags: Record<string, string | undefined>;
  locked: Record<string, string | undefined>;
  pending: PatientDocument[];
  all: PatientDocument[];
  byId: Record<string, PatientDocument[] | undefined>;
}

const initialState: DocumentsState = {
  flags: {},
  locked: {},
  pending: [],
  all: [],
  byId: {},
};

const slice = createResettableSlice({
  name: 'Documents',
  initialState,
  reducers: {
    receivedUpdatedDocument: unwrap(editOrAddDocument),
    receivedDeletedDocument: unwrap((state, data: PatientDocument) => {
      removeDocument(state, data.id!);
    }),
    receiveLockedDocument: unwrap((state, data: LockedDocument) => {
      state.locked[data.id] = data.user_id;
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        api.Documents.setLockedDocument.fulfilled,
        unwrapApi((state, data) => {
          state.locked[data.id] = data.user_id;
        }),
      )
      .addCase(
        api.Documents.getDocumentFlags.fulfilled,
        unwrapApi((state, data, req) => {
          state.flags[req.documentId] = data.value;
        }),
      )
      .addCase(api.Documents.getPatientDocuments.fulfilled, unwrap(addDocuments))
      .addCase(api.Documents.getClinicPendingDocuments.fulfilled, unwrap(addDocuments))
      .addCase(api.Documents.getPhysicianPendingDocuments.fulfilled, unwrap(addDocuments))
      .addCase(api.Documents.getArchivedDocuments.fulfilled, unwrap(addDocuments))
      .addCase(api.Documents.stampDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.approveDocuments.fulfilled, unwrap(addDocuments))
      .addCase(
        api.Documents.deleteDocument.fulfilled,
        unwrapApi((state, data, req) => {
          removeDocument(state, req.documentId);
        }),
      )
      .addCase(api.Documents.addDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.decryptDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.updateDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.addDocumentFromHtml.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.editDocumentFromHtml.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.payDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.getDocument.fulfilled, unwrapApi(editOrAddDocument))
      .addCase(api.Documents.getMultipleDocuments.fulfilled, unwrap(addDocuments))
      .addCase(api.Documents.addUnassignedDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.combineDocuments.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.editDocument.fulfilled, unwrap(addDocuments))
      .addCase(api.Documents.patientFillDocument.fulfilled, unwrap(editOrAddDocument))
      .addCase(api.Documents.markDocumentPendingPatientFill.fulfilled, unwrap(editOrAddDocument)),
});

function addDocuments(state: DocumentsState, documents: PatientDocument[]) {
  documents.forEach((document) => editOrAddDocument(state, document));
}

function removeDocument(state: DocumentsState, documentId: string) {
  Object.keys(state.byId).forEach((patientId) => {
    PerformanceUtils.removeFromArrayInPlace({ id: documentId }, state.byId[patientId]!);
  });
  PerformanceUtils.removeFromArrayInPlace({ id: documentId }, state.all);
  PerformanceUtils.removeFromArrayInPlace({ id: documentId }, state.pending);
}

function editOrAddDocument(state: DocumentsState, document: PatientDocument) {
  upsertInPatient(state, document);
  updatePending(state, document);
  PerformanceUtils.upsert(document, state.all);
}

function upsertInPatient(state: DocumentsState, document: PatientDocument) {
  const patientId = document.patient_id;
  if (!patientId) {
    return;
  }
  if (!state.byId[patientId]) {
    state.byId[patientId] = [];
  }
  PerformanceUtils.upsert(document, state.byId[patientId]!);
}

function updatePending(state: DocumentsState, document: PatientDocument) {
  if (document.approved === false) {
    PerformanceUtils.upsert(document, state.pending);
  } else {
    PerformanceUtils.removeFromArrayInPlace(document, state.pending);
  }
}

export const documentActions = slice.actions;
export default slice.reducer;
