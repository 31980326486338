import { toast as hotToast } from "react-hot-toast"
import { z } from "zod"

export const ToastOptionsSchema = z.object({
  type: z.union([z.literal('success'), z.literal('error'), z.literal('info')]),
  message: z.string(),
  duration: z.union([z.number(), z.literal('infinite')]).optional(),
  className: z.string().optional()
})

export type ToastOptions = z.infer<typeof ToastOptionsSchema>
export type ToastType = ToastOptions['type']

type ExtraOptions = Omit<ToastOptions, 'type' | 'message'>
const DEFAULT_TOAST_DURATION = 4_000

export const toast = {
  success: getToastCreator('success'),
  error: getToastCreator('error'),
  info: getToastCreator('info')
}

function getToastCreator(type: ToastType) {
  return (message: string, options?: ExtraOptions) => {
    let duration = options?.duration ?? 4000
    if ( duration === 'infinite' ) {
      duration = Number.POSITIVE_INFINITY
    }
    return hotToast(process({ type, message, ...options }), { duration })
  }
}

function process(options: ToastOptions): string {
  return JSON.stringify(options)
}