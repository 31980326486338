import 'core-js/actual/array/at';
import React from 'react';
import ReactDOM from 'react-dom/client';
import AppWrapper from './AppWrapper.jsx';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'react-loading-skeleton/dist/skeleton.css';
import 'drag-drop-touch';

import {
  faCircleEnvelope,
  faCircleCalendar,
  faArchive,
  faUserPlus,
} from '@fortawesome/pro-thin-svg-icons';
import {
  faApple,
  faAndroid,
  faGoogle,
  faStripe,
  faAppStoreIos,
  faFirefoxBrowser,
  faInternetExplorer,
  faEdge,
  faSafari,
  faChrome,
  faCcMastercard,
  faCcAmex,
  faCcDinersClub,
  faCcJcb,
  faCcVisa,
  faCcDiscover,
} from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import {
  faMemo,
  faFileExport,
  faPhone,
  faFax,
  faLocationPin,
  faHeartPulse,
  faStar,
  faTrash,
  faCircle,
  faCheck,
  faVideo,
  faMessage,
  faLocationDot,
  faEllipsisVertical,
  faMagnifyingGlass,
  faSkullCrossbones,
  faCircleCheck,
  faCircleXmark,
  faTableList,
  faXmark,
  faPrint,
  faBell,
  faFilePlus,
  faEnvelope,
  faLock,
  faAngleRight,
  faCircleExclamation,
  faVirus,
  faTruckMedical,
  faHospital,
  faSyringe,
  faVialVirus,
  faCalendarClock as fasCalendarClock,
  faCircleInfo,
  faBallotCheck,
  faPerson,
  faStethoscope,
  faCircleQuestion,
  faPaperPlane,
  faShare,
  faRepeat,
  faCirclePlus,
  faInboxFull,
  faKey,
  faAddressBook,
  faFilePdf,
  faLinkSimpleSlash,
  faWandSparkles,
  faUserPen,
  faUserSlash,
  faFiles,
  faCircleX as fasCircleX,
  faAlarmSnooze as fasSnooze,
  faPencil as fasPencil,
  faEllipsis,
  faBars,
  faFileCircleCheck as fasFileCircleCheck,
  faArrowProgress,
  faBroom,
  faChartLine,
  faLaptopMobile,
  faChartLineUp,
  faDollar as fasDollar,
  faMicrochipAi
} from '@fortawesome/pro-solid-svg-icons';
import {
  faPrescriptionBottleMedical as fadPrescriptionBottleMedical,
  faHouseChimneyMedical,
  faWatchFitness,
  faFlaskVial,
  faCircleExclamation as fadCircleExclamation,
  faCheckCircle,
  faShuffle,
  faCirclePhoneHangup,
  faCirclePhone,
  faMessage as fadMessage,
  faBoxArchive,
  faUser,
  faDoorOpen,
  faVirus as fadVirus,
  faVirusSlash,
  faTrash as fadTrash,
  faCalendarClock as fadCalendarClock,
  faLocationDot as fadLocationDot,
  faTriangleExclamation,
  faHourglass,
  faFlask,
  faHeart,
  faWeightScale,
  faRulerVertical,
  faHeartPulse as fadHeartPulse,
  faFaceExhaling,
  faPersonWalking,
  faTemperatureHalf,
  faChartLine as fadChartLine,
  faNotesMedical as fadNotesMedical,
  faMemo as fadMemo,
  faPrescription as fadPrescription,
  faStethoscope as fadStethoscope,
  faUserDoctor as fadUserDoctor,
  faFileCircleCheck as fadFileCircleCheck,
  faMemoPad as fadMemoPad,
  faVialVirus as fadVialVirus,
  faSyringe as fadSyringe,
  faCircleNotch as fadCircleNotch,
  faCalendarHeart as fadCalendarHeart,
  faCalendarCheck as fadCalendarCheck,
  faVideo as fadVideo,
  faFilesMedical as fadFilesMedical,
  faCapsules as fadCapsules,
  faTablets as fadTablets,
  faPrescriptionBottle as fadPrescriptionBottle,
  faBottleDroplet as fadBottleDroplet,
  faEyeDropperHalf as fadEyeDropperHalf,
  faGlass as fadGlass,
  faInhaler as fadInhaler,
  faSpoon as fadSpoon,
  faSprayCan as fadSprayCan,
  faMapLocationDot as fadMapLocationDot,
} from '@fortawesome/pro-duotone-svg-icons';
import 'services/typeExtensions';

library.add(far);
//FAS
library.add(
  faMemo,
  faFileExport,
  faPhone,
  faFax,
  faLocationPin,
  faHeartPulse,
  faStar,
  faTrash,
  faCircle,
  faCheck,
  faVideo,
  faMessage,
  faLocationDot,
  faEllipsisVertical,
  faEllipsis,
  faMagnifyingGlass,
  faSkullCrossbones,
  faCircleCheck,
  faCircleXmark,
  faTableList,
  faXmark,
  faPrint,
  faBell,
  faFilePlus,
  faEnvelope,
  faLock,
  faAngleRight,
  faCircleExclamation,
  faVirus,
  faTruckMedical,
  faHospital,
  faSyringe,
  faVialVirus,
  fasCalendarClock,
  faCircleInfo,
  faBallotCheck,
  faPerson,
  faStethoscope,
  faCircleQuestion,
  faPaperPlane,
  faShare,
  faCirclePlus,
  faInboxFull,
  faKey,
  faAddressBook,
  faFilePdf,
  faLinkSimpleSlash,
  faWandSparkles,
  faUserPen,
  faUserSlash,
  faFiles,
  fasCircleX,
  fasSnooze,
  fasPencil,
  faBars,
  fasFileCircleCheck,
  faRepeat,
  faArrowProgress,
  faBroom,
  faLaptopMobile,
  fasDollar,
  faChartLine,
  faChartLineUp,
  faMicrochipAi
);
//FAD
library.add(
  fadFileCircleCheck,
  fadPrescriptionBottleMedical,
  faHouseChimneyMedical,
  faWatchFitness,
  faFlaskVial,
  fadCircleExclamation,
  faCheckCircle,
  faShuffle,
  faCirclePhoneHangup,
  faCirclePhone,
  fadMessage,
  faBoxArchive,
  faUser,
  faDoorOpen,
  fadVirus,
  faVirusSlash,
  fadTrash,
  fadCalendarClock,
  fadLocationDot,
  faTriangleExclamation,
  faHourglass,
  faFlask,
  faHeart,
  faWeightScale,
  faRulerVertical,
  fadHeartPulse,
  faFaceExhaling,
  faPersonWalking,
  faTemperatureHalf,
  fadChartLine,
  fadNotesMedical,
  fadMemoPad,
  fadMemo,
  fadUserDoctor,
  fadCircleNotch,
  fadSyringe,
  fadVialVirus,
  fadPrescription,
  fadStethoscope,
  fadCalendarHeart,
  fadCalendarCheck,
  fadVideo,
  fadFilesMedical,
  fadCapsules,
  fadTablets,
  fadPrescriptionBottle,
  fadBottleDroplet,
  fadEyeDropperHalf,
  fadGlass,
  fadInhaler,
  fadSpoon,
  fadSprayCan,
  fadMapLocationDot,
);
//FAT
library.add(faCircleEnvelope, faCircleCalendar, faArchive, faUserPlus);
//FAB
library.add(
  faApple,
  faAndroid,
  faGoogle,
  faStripe,
  faAppStoreIos,
  faFirefoxBrowser,
  faInternetExplorer,
  faEdge,
  faSafari,
  faChrome,
  faCcMastercard,
  faCcAmex,
  faCcDinersClub,
  faCcJcb,
  faCcVisa,
  faCcDiscover,
);

ReactDOM.createRoot(document.getElementById('root')).render(<AppWrapper />);
