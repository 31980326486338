import { store, useAppSelector } from "careand-redux/store"
import { ApiThunk } from "careand-redux/api/ApiThunks"
import { OperationId, SimpleApiKey } from "careand-redux/api/ApiTypeUtils"
import { getActiveStack, navigationActions, NavigationActionType, Stack, Stacks, Tab } from "./navigation"
import { UnwrappedAppDispatch, useAppDispatch } from "careand-redux/useAppDispatch"
import { MutableRefObject, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ScrollData, ScrollRestorationContext } from "services/ScrollRestoration"

export const useIsLoading = <Tag extends SimpleApiKey, OpId extends OperationId<Tag>>(thunk: ApiThunk<Tag, OpId>) => {
  const isLoading = useAppSelector(state => state.ui.loadingEndpoints[thunk.typePrefix])
  return isLoading ?? false
}

export const useSetLoading = <Tag extends SimpleApiKey, OpId extends OperationId<Tag>>(thunk: ApiThunk<Tag, OpId>, callback: ((loading: boolean) => void) | undefined) => {
  const isLoading = useIsLoading(thunk)
  useEffect(() => {
    callback?.(!!isLoading)
  }, [isLoading])
  return isLoading
}

export const useLoadingCounter = () => {
  const [loadingCount, setLoadingCount] = useState(0)

  const isLoading = loadingCount > 0

  const setLoading = useCallback((loading: boolean) => {
    setLoadingCount(prev => Math.max(prev + (loading ? 1 : -1), 0))
  }, [setLoadingCount])

  return [isLoading, setLoading] as const
}

export const getNavStackHandler = (stack: Stack | 'current', dispatch: UnwrappedAppDispatch, scrollRef?: MutableRefObject<ScrollData>) => {
  const push =(route: string, options?: { asFirst?: boolean }) => {
    const asFirst = options?.asFirst
    dispatch(navigationActions.pushedOntoStack({ stack: getStack(stack), route, asFirst, scroll: scrollRef?.current.write }))
  }
  const pop = () => {
    dispatch(navigationActions.poppedFromStack(getStack(stack)))
  }
  const popToRoot = () => {
    dispatch(navigationActions.poppedStackToRoot(getStack(stack)))
  }
  const popTo = (page: string, options: { animation: NavigationActionType } = { animation: 'pop' }) => {
    dispatch(navigationActions.poppedStackTo({ to: page, stack: getStack(stack), animation: options.animation }))
  }
  const pushAsFirst = (page: string, options?: { animation?: NavigationActionType, root?: string }) => {
    const optionsOrDefault = { animation: 'push' as NavigationActionType , ...options}
    dispatch(navigationActions.pushedAsFirst({stack: getStack(stack), to: page, ...optionsOrDefault}))
  }
  const replace = (page: string, options?: { animation?: NavigationActionType }) => {
    const optionsOrDefault = { animation: 'push' as NavigationActionType, ...options}
    dispatch(navigationActions.replaced({stack: getStack(stack), to:page, ...optionsOrDefault}))
  }
  return { push, pop, popToRoot, popTo, pushAsFirst, replace }
}

export type NavStackHandler = ReturnType<typeof getNavStackHandler>

export const useNavStack = (stack: Stack | 'current') => {
  const dispatch = useAppDispatch()
  const scroll = useContext(ScrollRestorationContext)

  const handler = useMemo(() => getNavStackHandler(stack, dispatch, scroll), [stack, dispatch, scroll])
  return handler
}

export function getStack(option: Stack | 'current'): Stack {
  if (option === 'current') {
    return getActiveStack(store.getState().navigation)
  }
  return option
}

export const useNavTab = () => {
  const dispatch = useAppDispatch()
  const scroll = useContext(ScrollRestorationContext)

  const switchTab = useCallback((tab: Tab) => {
    dispatch(navigationActions.activeTabChanged({ tab, scroll: scroll.current.write }))

  }, [dispatch])

  return { switchTab }
}
