/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MinistryOHIP } from './MinistryOHIP';
import {
    MinistryOHIPFromJSON,
    MinistryOHIPFromJSONTyped,
    MinistryOHIPToJSON,
} from './MinistryOHIP';

/**
 * 
 * @export
 * @interface PatientByStaffRegisterPayload
 */
export interface PatientByStaffRegisterPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientByStaffRegisterPayload
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PatientByStaffRegisterPayload
     */
    email: string;
    /**
     * 
     * @type {MinistryOHIP}
     * @memberof PatientByStaffRegisterPayload
     */
    ministry_ohip: MinistryOHIP;
}

/**
 * Check if a given object implements the PatientByStaffRegisterPayload interface.
 */
export function instanceOfPatientByStaffRegisterPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "ministry_ohip" in value;

    return isInstance;
}

export function PatientByStaffRegisterPayloadFromJSON(json: any): PatientByStaffRegisterPayload {
    return PatientByStaffRegisterPayloadFromJSONTyped(json, false);
}

export function PatientByStaffRegisterPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientByStaffRegisterPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': json['phone'],
        'email': json['email'],
        'ministry_ohip': MinistryOHIPFromJSON(json['ministry_ohip']),
    };
}

export function PatientByStaffRegisterPayloadToJSON(value?: PatientByStaffRegisterPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'email': value.email,
        'ministry_ohip': MinistryOHIPToJSON(value.ministry_ohip),
    };
}

