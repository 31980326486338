/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { AsyncThunk, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { ApiResponse, Configuration, ResponseError } from 'generated/api';
import {
  ApiInstance,
  OperationFunction,
  OperationId,
  OpFirstParam,
  ResponseType,
  SimpleApiKey,
  simplifiedApi,
  SimplifiedApi,
} from './ApiTypeUtils';
import { defaultErrorMiddleware } from './ApiThunkMiddleware';
import moment from 'moment-timezone';
import { serialize } from 'careand-redux/api/SerializedReponseError';
import apiVersion from 'ApiVersion.json';
import config from 'config/config.json';
import { endpointIsLoading } from 'careand-redux/actions/endpointLoading';
import PerformanceUtils from 'services/PerformanceUtils';
import type { RequestOpts } from 'generated/api';
import version from 'config/version.json';

type ApiThunkConfig = {
  dispatch: Dispatch;
  state: unknown;
  fulfilledMeta: ApiMeta;
  rejectedMeta: ApiMeta;
};

export type ApiThunk<Tag extends SimpleApiKey, OpId extends OperationId<Tag>> = AsyncThunk<
  ResponseType<Tag, OpId>,
  OpFirstParam<OperationFunction<Tag, OpId>>,
  ApiThunkConfig
>;

type TagThunks<Tag extends SimpleApiKey> = {
  [OpId in OperationId<Tag>]: ApiThunk<Tag, OpId>;
};

type AllThunks = {
  [Tag in SimpleApiKey]: TagThunks<Tag>;
};

type MemoizedApis = {
  [Tag in keyof SimplifiedApi]: ApiInstance<Tag>;
};

export type RequestOptions = RequestOpts & {
  basePath?: string;
};

type ApiRequestConfigGetter<Tag extends SimpleApiKey, OpId extends OperationId<Tag>> = (
  args: OpFirstParam<OperationFunction<Tag, OpId>>,
) => RequestOptions;

export type AllApiRequestConfigGetters = {
  [Tag in SimpleApiKey]: {
    [OpId in OperationId<Tag>]: ApiRequestConfigGetter<Tag, OpId>;
  };
};

/* RUNTIME */

const { API_URL, PROTOCOL, ENVIRONMENT } = config;
const PATIENT = (config as { PATIENT?: boolean })?.PATIENT || false;

const API_KEY_HOLDER: { apiKey?: string } = {
  apiKey: undefined,
};

export const globalApiConfig = new Configuration({
  basePath: `${PROTOCOL}://${API_URL}/api`,
  apiKey: (name: string) => API_KEY_HOLDER.apiKey ?? '',
  headers: {
    'api-version': apiVersion.httpApiVersion,
    'client-version': version['client-version'],
    'client-name': PATIENT ? 'patient-portal' : 'portal',
  },
});

export const updateDevice = (
  type: 'android' | 'ios' | 'portal',
  version: string,
  isPatientPortal: boolean,
) => {
  const suffix = isPatientPortal ? 'patient' : 'team';
  let clientName: string;
  switch (type) {
    case 'android':
      clientName = `android-${suffix}`;
      break;
    case 'ios':
      clientName = `ios-${suffix}`;
      break;
    case 'portal':
      clientName = 'patient-portal';
      break;
    default:
      clientName = 'portal';
  }
  globalApiConfig!.headers!['client-name'] = clientName;
  globalApiConfig!.headers!['client-version'] = version;
};

const memoizedApis = ((): MemoizedApis => {
  const result = _.mapValues(simplifiedApi, (Klass) => new Klass(globalApiConfig));
  return result as MemoizedApis;
})();

export type ApiMeta = { status: number };

// 2. Generate Thunks
const generateSingleThunk = <Tag extends SimpleApiKey, OpId extends OperationId<Tag>>(
  tag: Tag,
  opId: OpId,
): ApiThunk<Tag, OpId> => {
  return createAsyncThunk<
    ResponseType<Tag, OpId>,
    OpFirstParam<OperationFunction<Tag, OpId>>,
    ApiThunkConfig
  >(`${tag}/${opId}`, async (params, thunksApi) => {
    const typePrefix = `${tag}/${opId}`;
    const token = (thunksApi.getState() as any)?.authentication?.token;
    API_KEY_HOLDER.apiKey = token ? `Bearer ${token}` : undefined;

    const time = moment();
    let url = 'url?';
    let status = -1;
    let rejectValue: unknown;

    thunksApi.dispatch(endpointIsLoading({ name: typePrefix }));

    try {
      const api = memoizedApis[tag];
      const reqParams = params === undefined ? [] : [params];
      const response = (await (api as any)[`${opId}Raw`](...reqParams)) as ApiResponse<
        ResponseType<Tag, OpId>
      >;
      url = response.raw.url;
      status = response.raw.status;

      const meta = { status };

      if (status === 204) {
        return thunksApi.fulfillWithValue(undefined, meta);
      }
      const value = (await response.value()) as ResponseType<Tag, OpId>;

      PerformanceUtils.removeUndefinedKeys(value);

      return thunksApi.fulfillWithValue(value, meta) as any;
    } catch (err: unknown) {
      if (err instanceof ResponseError) {
        url = err.response.url;
        status = err.response.status;

        const serializedError = await serialize(err);
        rejectValue = serializedError;
      } else {
        rejectValue = err;
      }
      const meta = { status };
      defaultErrorMiddleware(err, thunksApi.getState as any, thunksApi.dispatch as any);
      return thunksApi.rejectWithValue(rejectValue, meta);
    } finally {
      const delta = moment().diff(time, 'milliseconds');
      thunksApi.dispatch(endpointIsLoading({ name: typePrefix, loading: false }));
    }
  });
};

const generateTagThunks = <Tag extends SimpleApiKey>(tag: Tag) => {
  const allMethodNames = Object.getOwnPropertyNames(memoizedApis[tag].constructor.prototype);
  const ops: OperationId<Tag>[] = _(allMethodNames)
    .filter(
      (it) =>
        it !== 'constructor' &&
        !it.endsWith('Raw') &&
        !(it.startsWith('with') && it.endsWith('Middleware')),
    )
    .value() as OperationId<Tag>[];
  const res: Partial<TagThunks<Tag>> = {};
  ops.forEach((opId) => {
    res[opId] = generateSingleThunk(tag, opId);
  });
  return res as TagThunks<Tag>;
};
const generateAllThunks = (): AllThunks => {
  const result = _.mapValues(memoizedApis, (value, key) => generateTagThunks(key as SimpleApiKey));
  return result as AllThunks;
};

const generateAllRequestConfigGetters = (): AllApiRequestConfigGetters => {
  function generateForTag<Tag extends SimpleApiKey>(tag: Tag): AllApiRequestConfigGetters[Tag] {
    const allMethodNames = Object.getOwnPropertyNames(memoizedApis[tag].constructor.prototype);
    const ops: OperationId<Tag>[] = _(allMethodNames)
      .filter(
        (it) =>
          it !== 'constructor' &&
          !it.endsWith('Raw') &&
          !(it.startsWith('with') && it.endsWith('Middleware')),
      )
      .value() as OperationId<Tag>[];
    const res: Partial<AllApiRequestConfigGetters[Tag]> = {};
    ops.forEach((opId) => {
      res[opId] = ((args: any) => {
        return {
          ...(memoizedApis[tag] as any).constructor.prototype[`${opId}RequestOptionsRaw`](args),
          basePath: `${PROTOCOL}://${API_URL}/api`,
          headers: { ...globalApiConfig.headers },
        };
      }) as any;
    });
    return res as AllApiRequestConfigGetters[Tag];
  }
  const result = _.mapValues(memoizedApis, (value, key) => generateForTag(key as SimpleApiKey));

  return result as AllApiRequestConfigGetters;
};

export const api = generateAllThunks();

export const apiRequestConfig = generateAllRequestConfigGetters();
