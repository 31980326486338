import { DefaultPushNotificationParser, PushNotificationParser } from "./PushNotifications";

//TODO this is just an example
const parser: PushNotificationParser = {
  hrUpdated: DefaultPushNotificationParser.hrUpdated,
  other: DefaultPushNotificationParser.other,
  chatUpdated: DefaultPushNotificationParser.chatUpdated,
  joinTelemedicine: DefaultPushNotificationParser.joinTelemedicine,
  appointmentUpdated: DefaultPushNotificationParser.appointmentUpdated
}

export default parser