import imageMale from 'assets/img/icons/avatar-male.svg';

class AdminUtils {
  getName(admin) {
    let error = 'Unknown Name';

    if (!admin) return error;

    let name = '';

    if (admin.name !== undefined) name = admin.name;

    name = name.trim();

    if (name === '') name = error;

    return name;
  }

  getPhotoUrls(admin) {
    return [admin?.photo, imageMale];
  }
}

export default new AdminUtils();
