/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookingOptionFee
 */
export interface BookingOptionFee {
    /**
     * 
     * @type {string}
     * @memberof BookingOptionFee
     */
    plan_id?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingOptionFee
     */
    fee?: number;
}

/**
 * Check if a given object implements the BookingOptionFee interface.
 */
export function instanceOfBookingOptionFee(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BookingOptionFeeFromJSON(json: any): BookingOptionFee {
    return BookingOptionFeeFromJSONTyped(json, false);
}

export function BookingOptionFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingOptionFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plan_id': !exists(json, 'plan_id') ? undefined : json['plan_id'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
    };
}

export function BookingOptionFeeToJSON(value?: BookingOptionFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan_id': value.plan_id,
        'fee': value.fee,
    };
}

