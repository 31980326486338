/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CouponMetadata } from './CouponMetadata';
import {
    CouponMetadataFromJSON,
    CouponMetadataFromJSONTyped,
    CouponMetadataToJSON,
} from './CouponMetadata';

/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    object?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    amount_off?: number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    duration?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    duration_in_months?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    livemode?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    max_redemptions?: number;
    /**
     * 
     * @type {CouponMetadata}
     * @memberof Coupon
     */
    metadata?: CouponMetadata;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    percent_off?: number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    percent_off_precise?: number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    redeem_by?: number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    times_redeemed?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    valid?: boolean;
}

/**
 * Check if a given object implements the Coupon interface.
 */
export function instanceOfCoupon(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CouponFromJSON(json: any): Coupon {
    return CouponFromJSONTyped(json, false);
}

export function CouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'amount_off': !exists(json, 'amount_off') ? undefined : json['amount_off'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'duration_in_months': !exists(json, 'duration_in_months') ? undefined : json['duration_in_months'],
        'livemode': !exists(json, 'livemode') ? undefined : json['livemode'],
        'max_redemptions': !exists(json, 'max_redemptions') ? undefined : json['max_redemptions'],
        'metadata': !exists(json, 'metadata') ? undefined : CouponMetadataFromJSON(json['metadata']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'percent_off': !exists(json, 'percent_off') ? undefined : json['percent_off'],
        'percent_off_precise': !exists(json, 'percent_off_precise') ? undefined : json['percent_off_precise'],
        'redeem_by': !exists(json, 'redeem_by') ? undefined : json['redeem_by'],
        'times_redeemed': !exists(json, 'times_redeemed') ? undefined : json['times_redeemed'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
    };
}

export function CouponToJSON(value?: Coupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'object': value.object,
        'amount_off': value.amount_off,
        'created': value.created,
        'currency': value.currency,
        'duration': value.duration,
        'duration_in_months': value.duration_in_months,
        'livemode': value.livemode,
        'max_redemptions': value.max_redemptions,
        'metadata': CouponMetadataToJSON(value.metadata),
        'name': value.name,
        'percent_off': value.percent_off,
        'percent_off_precise': value.percent_off_precise,
        'redeem_by': value.redeem_by,
        'times_redeemed': value.times_redeemed,
        'valid': value.valid,
    };
}

