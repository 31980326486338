/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PhysicianContact } from './PhysicianContact';
import {
    PhysicianContactFromJSON,
    PhysicianContactFromJSONTyped,
    PhysicianContactToJSON,
} from './PhysicianContact';

/**
 * 
 * @export
 * @interface ReferralRequest
 */
export interface ReferralRequest {
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    physician_id: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    clinic_id: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof ReferralRequest
     */
    instructions?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralRequest
     */
    urgent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferralRequest
     */
    hide?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferralRequest
     */
    date?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferralRequest
     */
    document_ids?: Array<string>;
    /**
     * 
     * @type {PhysicianContact}
     * @memberof ReferralRequest
     */
    send_to?: PhysicianContact;
}

/**
 * Check if a given object implements the ReferralRequest interface.
 */
export function instanceOfReferralRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "physician_id" in value;
    isInstance = isInstance && "clinic_id" in value;
    isInstance = isInstance && "patient_id" in value;

    return isInstance;
}

export function ReferralRequestFromJSON(json: any): ReferralRequest {
    return ReferralRequestFromJSONTyped(json, false);
}

export function ReferralRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'physician_id': json['physician_id'],
        'clinic_id': json['clinic_id'],
        'patient_id': json['patient_id'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'urgent': !exists(json, 'urgent') ? undefined : json['urgent'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'document_ids': !exists(json, 'document_ids') ? undefined : json['document_ids'],
        'send_to': !exists(json, 'send_to') ? undefined : PhysicianContactFromJSON(json['send_to']),
    };
}

export function ReferralRequestToJSON(value?: ReferralRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'physician_id': value.physician_id,
        'clinic_id': value.clinic_id,
        'patient_id': value.patient_id,
        'instructions': value.instructions,
        'urgent': value.urgent,
        'hide': value.hide,
        'date': value.date,
        'document_ids': value.document_ids,
        'send_to': PhysicianContactToJSON(value.send_to),
    };
}

