/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoArticle
 */
export interface InfoArticle {
    /**
     * 
     * @type {string}
     * @memberof InfoArticle
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoArticle
     */
    alt_title?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoArticle
     */
    mesh?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoArticle
     */
    organization?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoArticle
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoArticle
     */
    snippet?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InfoArticle
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the InfoArticle interface.
 */
export function instanceOfInfoArticle(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfoArticleFromJSON(json: any): InfoArticle {
    return InfoArticleFromJSONTyped(json, false);
}

export function InfoArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoArticle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'alt_title': !exists(json, 'alt_title') ? undefined : json['alt_title'],
        'mesh': !exists(json, 'mesh') ? undefined : json['mesh'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'snippet': !exists(json, 'snippet') ? undefined : json['snippet'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function InfoArticleToJSON(value?: InfoArticle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'alt_title': value.alt_title,
        'mesh': value.mesh,
        'organization': value.organization,
        'summary': value.summary,
        'snippet': value.snippet,
        'tags': value.tags,
    };
}

