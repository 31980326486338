/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentPlanMetadata } from './PaymentPlanMetadata';
import {
    PaymentPlanMetadataFromJSON,
    PaymentPlanMetadataFromJSONTyped,
    PaymentPlanMetadataToJSON,
} from './PaymentPlanMetadata';
import type { PaymentPlanPermission } from './PaymentPlanPermission';
import {
    PaymentPlanPermissionFromJSON,
    PaymentPlanPermissionFromJSONTyped,
    PaymentPlanPermissionToJSON,
} from './PaymentPlanPermission';

/**
 * 
 * @export
 * @interface PaymentPlan
 */
export interface PaymentPlan {
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    object?: string;
    /**
     * 
     * @type {PaymentPlanMetadata}
     * @memberof PaymentPlan
     */
    metadata?: PaymentPlanMetadata;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    statement_descriptor?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    trial_period_days?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    interval?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    interval_count?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    livemode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentPlan
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    billing_scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    product?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    usage_type?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    nickname?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPlan
     */
    discount?: number;
    /**
     * 
     * @type {Array<PaymentPlanPermission>}
     * @memberof PaymentPlan
     */
    permissions?: Array<PaymentPlanPermission>;
}

/**
 * Check if a given object implements the PaymentPlan interface.
 */
export function instanceOfPaymentPlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function PaymentPlanFromJSON(json: any): PaymentPlan {
    return PaymentPlanFromJSONTyped(json, false);
}

export function PaymentPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'metadata': !exists(json, 'metadata') ? undefined : PaymentPlanMetadataFromJSON(json['metadata']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'statement_descriptor': !exists(json, 'statement_descriptor') ? undefined : json['statement_descriptor'],
        'trial_period_days': !exists(json, 'trial_period_days') ? undefined : json['trial_period_days'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'interval_count': !exists(json, 'interval_count') ? undefined : json['interval_count'],
        'livemode': !exists(json, 'livemode') ? undefined : json['livemode'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'billing_scheme': !exists(json, 'billing_scheme') ? undefined : json['billing_scheme'],
        'product': !exists(json, 'product') ? undefined : json['product'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'usage_type': !exists(json, 'usage_type') ? undefined : json['usage_type'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'permissions': !exists(json, 'permissions') ? undefined : ((json['permissions'] as Array<any>).map(PaymentPlanPermissionFromJSON)),
    };
}

export function PaymentPlanToJSON(value?: PaymentPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'object': value.object,
        'metadata': PaymentPlanMetadataToJSON(value.metadata),
        'amount': value.amount,
        'statement_descriptor': value.statement_descriptor,
        'trial_period_days': value.trial_period_days,
        'created': value.created,
        'currency': value.currency,
        'interval': value.interval,
        'interval_count': value.interval_count,
        'livemode': value.livemode,
        'active': value.active,
        'billing_scheme': value.billing_scheme,
        'product': value.product,
        'name': value.name,
        'usage_type': value.usage_type,
        'nickname': value.nickname,
        'discount': value.discount,
        'permissions': value.permissions === undefined ? undefined : ((value.permissions as Array<any>).map(PaymentPlanPermissionToJSON)),
    };
}

