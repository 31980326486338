import React from 'react';
import './style.scss';
import logo from "assets/img/logo-white-horizontal.webp"

const OldVersionScreen = () => (
  <div className="old-version-screen">
    <img
      alt="Care& Family Health"
      src={logo}
      width="230"
      className="mb-5"
    />
    <span className="center-text white-text centered">
      You are currently using an outdated version of the Care& Family Health web app.
      Please refresh this page or clear your browser's cache in order to access
      our service.
    </span>
  </div>
);

export default OldVersionScreen;
