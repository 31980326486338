/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdministeredDrug,
  AppointmentNote,
  AppointmentNoteDraft,
  AppointmentNoteStamp,
  CPP,
  CPPAndChatUnreadCount,
  Consultant,
  ErrorResponse,
  GetAppointmentNoteStamps200ResponseInner,
  HealthData,
  HealthRecords,
  HealthRecordsInformation,
  HealthRecordsMetricAggregated,
  HealthRecordsMetricSummary,
  HistoryIssue,
  Immunization,
  ImmunizationDescription,
  InternPendingRecords,
  LifestyleFactor,
  MTDate,
  MTReminderDescription,
  MTTutorialData,
  MTUpdate,
  MedicalOrder,
  MedicationReminder,
  MedicationReminderNotification,
  MedicationReminderTake,
  MetricType,
  MetricValue,
  MultipleValuesPayload,
  Patient,
  PatientDocument,
  PatientIssue,
  PatientMedication,
  Prescription,
  PrescriptionRefill,
  Referral,
  ReferralRequest,
  Resource,
} from '../models';
import {
    AdministeredDrugFromJSON,
    AdministeredDrugToJSON,
    AppointmentNoteFromJSON,
    AppointmentNoteToJSON,
    AppointmentNoteDraftFromJSON,
    AppointmentNoteDraftToJSON,
    AppointmentNoteStampFromJSON,
    AppointmentNoteStampToJSON,
    CPPFromJSON,
    CPPToJSON,
    CPPAndChatUnreadCountFromJSON,
    CPPAndChatUnreadCountToJSON,
    ConsultantFromJSON,
    ConsultantToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    GetAppointmentNoteStamps200ResponseInnerFromJSON,
    GetAppointmentNoteStamps200ResponseInnerToJSON,
    HealthDataFromJSON,
    HealthDataToJSON,
    HealthRecordsFromJSON,
    HealthRecordsToJSON,
    HealthRecordsInformationFromJSON,
    HealthRecordsInformationToJSON,
    HealthRecordsMetricAggregatedFromJSON,
    HealthRecordsMetricAggregatedToJSON,
    HealthRecordsMetricSummaryFromJSON,
    HealthRecordsMetricSummaryToJSON,
    HistoryIssueFromJSON,
    HistoryIssueToJSON,
    ImmunizationFromJSON,
    ImmunizationToJSON,
    ImmunizationDescriptionFromJSON,
    ImmunizationDescriptionToJSON,
    InternPendingRecordsFromJSON,
    InternPendingRecordsToJSON,
    LifestyleFactorFromJSON,
    LifestyleFactorToJSON,
    MTDateFromJSON,
    MTDateToJSON,
    MTReminderDescriptionFromJSON,
    MTReminderDescriptionToJSON,
    MTTutorialDataFromJSON,
    MTTutorialDataToJSON,
    MTUpdateFromJSON,
    MTUpdateToJSON,
    MedicalOrderFromJSON,
    MedicalOrderToJSON,
    MedicationReminderFromJSON,
    MedicationReminderToJSON,
    MedicationReminderNotificationFromJSON,
    MedicationReminderNotificationToJSON,
    MedicationReminderTakeFromJSON,
    MedicationReminderTakeToJSON,
    MetricTypeFromJSON,
    MetricTypeToJSON,
    MetricValueFromJSON,
    MetricValueToJSON,
    MultipleValuesPayloadFromJSON,
    MultipleValuesPayloadToJSON,
    PatientFromJSON,
    PatientToJSON,
    PatientDocumentFromJSON,
    PatientDocumentToJSON,
    PatientIssueFromJSON,
    PatientIssueToJSON,
    PatientMedicationFromJSON,
    PatientMedicationToJSON,
    PrescriptionFromJSON,
    PrescriptionToJSON,
    PrescriptionRefillFromJSON,
    PrescriptionRefillToJSON,
    ReferralFromJSON,
    ReferralToJSON,
    ReferralRequestFromJSON,
    ReferralRequestToJSON,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface AddAdministeredDrugRequest {
    drug: AdministeredDrug;
}

export interface AddHealthDataRequest {
    data: HealthData;
    patientId?: string;
}

export interface AddImmunizationRequest {
    immunization: Immunization;
    chargeAmount?: string;
}

export interface AddMedicationReminderRequest {
    data: MedicationReminder;
}

export interface AddNotesAttachmentRequest {
    patientId: string;
    file: Blob;
    notes?: string;
    hide?: boolean;
}

export interface AddNotesAttachmentDraftRequest {
    patientId: string;
    file: Blob;
    notes?: string;
}

export interface AddNotesDraftRequest {
    draft: AppointmentNoteDraft;
}

export interface AddNotesTextRequest {
    note: AppointmentNote;
}

export interface AddOrEditAppointmentNoteStampRequest {
    stamp: AppointmentNoteStamp;
}

export interface AddOrEditHealthRecordConsultantRequest {
    patientId: string;
    consultants: Array<Consultant>;
}

export interface AddOrEditHealthRecordHistoryIssueRequest {
    patientId: string;
    issues: Array<HistoryIssue>;
}

export interface AddOrEditHealthRecordLifestyleFactorRequest {
    patientId: string;
    factors: Array<LifestyleFactor>;
}

export interface AddOrEditHealthRecordPatientIssueRequest {
    patientId: string;
    field: AddOrEditHealthRecordPatientIssueFieldEnum;
    issues: Array<PatientIssue>;
}

export interface AddOrEditHealthRecordPatientMedicationRequest {
    patientId: string;
    medications: Array<PatientMedication>;
}

export interface AddOrEditMedicalOrderRequest {
    patient_id: string;
    medical_order: MedicalOrder;
}

export interface AddPrescriptionRequest {
    prescription: Prescription;
    fax?: boolean;
}

export interface AddPrescriptionRefillRequest {
    refill: PrescriptionRefill;
}

export interface AddReferralRequest {
    referral: Referral;
    fax?: boolean;
    email?: boolean;
}

export interface AddReferralAsInternRequest {
    referral: Referral;
}

export interface AddReferralRequestRequest {
    request: ReferralRequest;
}

export interface CountUnreadHealthRecordsRequest {
    patientId: string;
}

export interface DeleteAppointmentNoteStampRequest {
    id: string;
}

export interface DeleteHealthRecordConsultantRequest {
    patientId: string;
    consultant: Consultant;
}

export interface DeleteHealthRecordHistoryIssueRequest {
    patientId: string;
    issue: HistoryIssue;
}

export interface DeleteHealthRecordLifestyleFactorRequest {
    patientId: string;
    factor: LifestyleFactor;
}

export interface DeleteHealthRecordMedicationRequest {
    patientId: string;
    issue: PatientMedication;
}

export interface DeleteHealthRecordPatientIssueRequest {
    patientId: string;
    field: DeleteHealthRecordPatientIssueFieldEnum;
    issue: PatientIssue;
}

export interface DeleteMedicalOrderRequest {
    patient_id: string;
    date: number;
}

export interface DeleteNoteRequest {
    id: string;
}

export interface DeleteNotesDraftRequest {
    patientId: string;
    id: string;
}

export interface DeleteReferralRequest {
    patientId: string;
    id: string;
}

export interface DiscontinueMedicationReminderRequest {
    id: string;
}

export interface EditMetricTypeRequest {
    data: MetricType;
}

export interface EditNoteRequest {
    id: string;
    note: AppointmentNote;
}

export interface EditPrescriptionRefillRequest {
    id: string;
    patientId: string;
    refill: PrescriptionRefill;
}

export interface ExportAdministeredDrugsRequest {
    patientId: string;
}

export interface ExportCPPRequest {
    patientId: string;
    from?: number;
    to?: number;
    excludeNotes?: boolean;
    excludeDocuments?: boolean;
    excludeSummary?: boolean;
}

export interface ExportImmunizationsRequest {
    patientId: string;
}

export interface GenerateImmunizationsRequest {
    patientId: string;
}

export interface GetAppointmentNoteStampRequest {
    id: string;
}

export interface GetCPPRequest {
    patientId: string;
}

export interface GetHealthDataRequest {
    patientId: string;
}

export interface GetHealthDataSummaryRequest {
    patientId: string;
}

export interface GetHealthRecordsRequest {
    patientId: string;
}

export interface GetLatestMetricValueRequest {
    patientId: string;
    metric: string;
    origin?: GetLatestMetricValueOriginEnum;
}

export interface GetMedicationReminderNotificationsRequest {
    patient_id: string;
    from?: number;
    to?: number;
    include_discontinued?: boolean;
}

export interface GetMedicationRemindersRequest {
    patient_id: string;
}

export interface GetMenstruationDataRequest {
    id: string;
}

export interface GetNotesAttachmentRequest {
    appointmentNoteId: string;
}

export interface GetNotesDraftAttachmentRequest {
    draftId: string;
}

export interface GetPatientNotesDraftRequest {
    patientId: string;
    backups?: boolean;
}

export interface GetPrescriptionAttachmentRequest {
    patientId: string;
    prescriptionId: string;
    resource: Resource;
}

export interface GetPrescriptionPDFRequest {
    patientId: string;
    prescriptionId: string;
}

export interface GetPrescriptionRefillsRequest {
    clinicId?: string;
}

export interface GetReferralsByStatusRequest {
    status: string;
}

export interface GetSpecificHealthDataRequest {
    patientId: string;
    metric: string;
    type?: string;
    origin?: string;
}

export interface MarkAllHealthRecordsReadRequest {
    patientId: string;
}

export interface MarkHealthRecordReadRequest {
    patientId: string;
    type: MarkHealthRecordReadTypeEnum;
    id: string;
}

export interface PreviewPrescriptionRequest {
    prescription: Prescription;
}

export interface PreviewReferralRequest {
    referral: Referral;
}

export interface RemoveAdministeredDrugRequest {
    id: string;
    patientId: string;
}

export interface RemoveImmunizationRequest {
    id: string;
    patientId: string;
}

export interface RemovePrescriptionRefillRequest {
    id: string;
    patientId: string;
}

export interface RemoveReferralRequestRequest {
    id: string;
}

export interface ResetMenstruationDataRequest {
    id: string;
}

export interface SearchHealthRecordsRequest {
    term: string;
    physicianId: string;
    ids: MultipleValuesPayload;
}

export interface SnoozeMedicationReminderRequest {
    duration: number;
    reminder: MedicationReminderNotification;
}

export interface UpdateHealthRecordsInformationRequest {
    patientId: string;
    information: HealthRecordsInformation;
}

export interface UpdateMedicationReminderRequest {
    data: MedicationReminder;
}

export interface UpdateMedicationReminderTakeRequest {
    id: string;
    take: MedicationReminderTake;
}

export interface UpdateMenstruationDateRequest {
    id: string;
    date: MTDate;
}

export interface UpdateMenstruationDatesRequest {
    id: string;
    dates: Array<MTDate>;
}

export interface UpdateMenstruationTutorialDataRequest {
    id: string;
    data: MTTutorialData;
}

export interface UpdatePrescriptionRequest {
    patientId: string;
    prescriptionId: string;
    prescription: Prescription;
}

export interface UpdateReferralRequest {
    patientId: string;
    id: string;
    document: PatientDocument;
}

/**
 * 
 */
export class HealthRecordsApi extends runtime.BaseAPI {

    /**
     * Adds a new Administered Drug health record (Roles: physician, admin)
     */
    async addAdministeredDrugRaw(requestParameters: AddAdministeredDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdministeredDrug>> {
        if (requestParameters.drug === null || requestParameters.drug === undefined) {
            throw new runtime.RequiredError('drug','Required parameter requestParameters.drug was null or undefined when calling addAdministeredDrug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/administered-drugs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdministeredDrugToJSON(requestParameters.drug),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdministeredDrugFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addAdministeredDrugRequestOptionsRaw(requestParameters: AddAdministeredDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.drug === null || requestParameters.drug === undefined) {
            throw new runtime.RequiredError('drug','Required parameter requestParameters.drug was null or undefined when calling addAdministeredDrug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/administered-drugs`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AdministeredDrugToJSON(requestParameters.drug),
        };
        return responseConfig
    }

    /**
     * Adds a new Administered Drug health record (Roles: physician, admin)
     */
    async addAdministeredDrug(requestParameters: AddAdministeredDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdministeredDrug> {
        const response = await this.addAdministeredDrugRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds HealthData (Roles: patient, physician, admin)
     */
    async addHealthDataRaw(requestParameters: AddHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecordsMetricAggregated>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addHealthData.');
        }

        const queryParameters: any = {};

        if (requestParameters.patientId !== undefined) {
            queryParameters['patientId'] = requestParameters.patientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/health-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HealthDataToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsMetricAggregatedFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addHealthDataRequestOptionsRaw(requestParameters: AddHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addHealthData.');
        }

        const queryParameters: any = {};

        if (requestParameters.patientId !== undefined) {
            queryParameters['patientId'] = requestParameters.patientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/health-data`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: HealthDataToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Adds HealthData (Roles: patient, physician, admin)
     */
    async addHealthData(requestParameters: AddHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecordsMetricAggregated> {
        const response = await this.addHealthDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new Immunization health record (Roles: physician, admin)
     */
    async addImmunizationRaw(requestParameters: AddImmunizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Immunization>> {
        if (requestParameters.immunization === null || requestParameters.immunization === undefined) {
            throw new runtime.RequiredError('immunization','Required parameter requestParameters.immunization was null or undefined when calling addImmunization.');
        }

        const queryParameters: any = {};

        if (requestParameters.chargeAmount !== undefined) {
            queryParameters['chargeAmount'] = requestParameters.chargeAmount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/immunizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImmunizationToJSON(requestParameters.immunization),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImmunizationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addImmunizationRequestOptionsRaw(requestParameters: AddImmunizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.immunization === null || requestParameters.immunization === undefined) {
            throw new runtime.RequiredError('immunization','Required parameter requestParameters.immunization was null or undefined when calling addImmunization.');
        }

        const queryParameters: any = {};

        if (requestParameters.chargeAmount !== undefined) {
            queryParameters['chargeAmount'] = requestParameters.chargeAmount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/immunizations`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ImmunizationToJSON(requestParameters.immunization),
        };
        return responseConfig
    }

    /**
     * Adds a new Immunization health record (Roles: physician, admin)
     */
    async addImmunization(requestParameters: AddImmunizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Immunization> {
        const response = await this.addImmunizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a medication reminder (Roles: patient)
     */
    async addMedicationReminderRaw(requestParameters: AddMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MedicationReminder>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicationReminderFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addMedicationReminderRequestOptionsRaw(requestParameters: AddMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling addMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Adds a medication reminder (Roles: patient)
     */
    async addMedicationReminder(requestParameters: AddMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MedicationReminder> {
        const response = await this.addMedicationReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an appointment note attachment (Roles: physician)
     */
    async addNotesAttachmentRaw(requestParameters: AddNotesAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNote>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addNotesAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addNotesAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.notes !== undefined) {
            formParams.append('notes', requestParameters.notes as any);
        }

        if (requestParameters.hide !== undefined) {
            formParams.append('hide', requestParameters.hide as any);
        }

        const response = await this.request({
            path: `/health-records/{patientId}/notes/attachment`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addNotesAttachmentRequestOptionsRaw(requestParameters: AddNotesAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addNotesAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addNotesAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.notes !== undefined) {
            formParams.append('notes', requestParameters.notes as any);
        }

        if (requestParameters.hide !== undefined) {
            formParams.append('hide', requestParameters.hide as any);
        }


        const responseConfig = {
            path: `/health-records/{patientId}/notes/attachment`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Adds an appointment note attachment (Roles: physician)
     */
    async addNotesAttachment(requestParameters: AddNotesAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNote> {
        const response = await this.addNotesAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an appointment note attachment draft (Roles: physician)
     */
    async addNotesAttachmentDraftRaw(requestParameters: AddNotesAttachmentDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNoteDraft>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addNotesAttachmentDraft.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addNotesAttachmentDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.notes !== undefined) {
            formParams.append('notes', requestParameters.notes as any);
        }

        const response = await this.request({
            path: `/health-records/{patientId}/notes/attachment/draft`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteDraftFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addNotesAttachmentDraftRequestOptionsRaw(requestParameters: AddNotesAttachmentDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addNotesAttachmentDraft.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling addNotesAttachmentDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.notes !== undefined) {
            formParams.append('notes', requestParameters.notes as any);
        }


        const responseConfig = {
            path: `/health-records/{patientId}/notes/attachment/draft`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Adds an appointment note attachment draft (Roles: physician)
     */
    async addNotesAttachmentDraft(requestParameters: AddNotesAttachmentDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNoteDraft> {
        const response = await this.addNotesAttachmentDraftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an appointment note draft (Roles: physician)
     */
    async addNotesDraftRaw(requestParameters: AddNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNoteDraft>> {
        if (requestParameters.draft === null || requestParameters.draft === undefined) {
            throw new runtime.RequiredError('draft','Required parameter requestParameters.draft was null or undefined when calling addNotesDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteDraftToJSON(requestParameters.draft),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteDraftFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addNotesDraftRequestOptionsRaw(requestParameters: AddNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.draft === null || requestParameters.draft === undefined) {
            throw new runtime.RequiredError('draft','Required parameter requestParameters.draft was null or undefined when calling addNotesDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/draft`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteDraftToJSON(requestParameters.draft),
        };
        return responseConfig
    }

    /**
     * Adds an appointment note draft (Roles: physician)
     */
    async addNotesDraft(requestParameters: AddNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNoteDraft> {
        const response = await this.addNotesDraftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an appointment note text (Roles: physician)
     */
    async addNotesTextRaw(requestParameters: AddNotesTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNote>> {
        if (requestParameters.note === null || requestParameters.note === undefined) {
            throw new runtime.RequiredError('note','Required parameter requestParameters.note was null or undefined when calling addNotesText.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/text`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteToJSON(requestParameters.note),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addNotesTextRequestOptionsRaw(requestParameters: AddNotesTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.note === null || requestParameters.note === undefined) {
            throw new runtime.RequiredError('note','Required parameter requestParameters.note was null or undefined when calling addNotesText.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/text`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteToJSON(requestParameters.note),
        };
        return responseConfig
    }

    /**
     * Adds an appointment note text (Roles: physician)
     */
    async addNotesText(requestParameters: AddNotesTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNote> {
        const response = await this.addNotesTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds or edits an appointment note stamp
     */
    async addOrEditAppointmentNoteStampRaw(requestParameters: AddOrEditAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNoteStamp>> {
        if (requestParameters.stamp === null || requestParameters.stamp === undefined) {
            throw new runtime.RequiredError('stamp','Required parameter requestParameters.stamp was null or undefined when calling addOrEditAppointmentNoteStamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/stamp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteStampToJSON(requestParameters.stamp),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteStampFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addOrEditAppointmentNoteStampRequestOptionsRaw(requestParameters: AddOrEditAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.stamp === null || requestParameters.stamp === undefined) {
            throw new runtime.RequiredError('stamp','Required parameter requestParameters.stamp was null or undefined when calling addOrEditAppointmentNoteStamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/stamp`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteStampToJSON(requestParameters.stamp),
        };
        return responseConfig
    }

    /**
     * Adds or edits an appointment note stamp
     */
    async addOrEditAppointmentNoteStamp(requestParameters: AddOrEditAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNoteStamp> {
        const response = await this.addOrEditAppointmentNoteStampRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates or adds a new Consultant in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordConsultantRaw(requestParameters: AddOrEditHealthRecordConsultantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordConsultant.');
        }

        if (requestParameters.consultants === null || requestParameters.consultants === undefined) {
            throw new runtime.RequiredError('consultants','Required parameter requestParameters.consultants was null or undefined when calling addOrEditHealthRecordConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/consultant`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.consultants.map(ConsultantToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addOrEditHealthRecordConsultantRequestOptionsRaw(requestParameters: AddOrEditHealthRecordConsultantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordConsultant.');
        }

        if (requestParameters.consultants === null || requestParameters.consultants === undefined) {
            throw new runtime.RequiredError('consultants','Required parameter requestParameters.consultants was null or undefined when calling addOrEditHealthRecordConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/consultant`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.consultants.map(ConsultantToJSON),
        };
        return responseConfig
    }

    /**
     * Updates or adds a new Consultant in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordConsultant(requestParameters: AddOrEditHealthRecordConsultantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.addOrEditHealthRecordConsultantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates or adds a new History Issue in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordHistoryIssueRaw(requestParameters: AddOrEditHealthRecordHistoryIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordHistoryIssue.');
        }

        if (requestParameters.issues === null || requestParameters.issues === undefined) {
            throw new runtime.RequiredError('issues','Required parameter requestParameters.issues was null or undefined when calling addOrEditHealthRecordHistoryIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/history-issue`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.issues.map(HistoryIssueToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addOrEditHealthRecordHistoryIssueRequestOptionsRaw(requestParameters: AddOrEditHealthRecordHistoryIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordHistoryIssue.');
        }

        if (requestParameters.issues === null || requestParameters.issues === undefined) {
            throw new runtime.RequiredError('issues','Required parameter requestParameters.issues was null or undefined when calling addOrEditHealthRecordHistoryIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/history-issue`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.issues.map(HistoryIssueToJSON),
        };
        return responseConfig
    }

    /**
     * Updates or adds a new History Issue in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordHistoryIssue(requestParameters: AddOrEditHealthRecordHistoryIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.addOrEditHealthRecordHistoryIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates or adds a new Lifestyle Factor in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordLifestyleFactorRaw(requestParameters: AddOrEditHealthRecordLifestyleFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordLifestyleFactor.');
        }

        if (requestParameters.factors === null || requestParameters.factors === undefined) {
            throw new runtime.RequiredError('factors','Required parameter requestParameters.factors was null or undefined when calling addOrEditHealthRecordLifestyleFactor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/lifestyle-factor`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.factors.map(LifestyleFactorToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addOrEditHealthRecordLifestyleFactorRequestOptionsRaw(requestParameters: AddOrEditHealthRecordLifestyleFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordLifestyleFactor.');
        }

        if (requestParameters.factors === null || requestParameters.factors === undefined) {
            throw new runtime.RequiredError('factors','Required parameter requestParameters.factors was null or undefined when calling addOrEditHealthRecordLifestyleFactor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/lifestyle-factor`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.factors.map(LifestyleFactorToJSON),
        };
        return responseConfig
    }

    /**
     * Updates or adds a new Lifestyle Factor in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordLifestyleFactor(requestParameters: AddOrEditHealthRecordLifestyleFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.addOrEditHealthRecordLifestyleFactorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates or adds a new Patient Issue in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordPatientIssueRaw(requestParameters: AddOrEditHealthRecordPatientIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordPatientIssue.');
        }

        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling addOrEditHealthRecordPatientIssue.');
        }

        if (requestParameters.issues === null || requestParameters.issues === undefined) {
            throw new runtime.RequiredError('issues','Required parameter requestParameters.issues was null or undefined when calling addOrEditHealthRecordPatientIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/patient-issue/{field}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"field"}}`, encodeURIComponent(String(requestParameters.field))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.issues.map(PatientIssueToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addOrEditHealthRecordPatientIssueRequestOptionsRaw(requestParameters: AddOrEditHealthRecordPatientIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordPatientIssue.');
        }

        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling addOrEditHealthRecordPatientIssue.');
        }

        if (requestParameters.issues === null || requestParameters.issues === undefined) {
            throw new runtime.RequiredError('issues','Required parameter requestParameters.issues was null or undefined when calling addOrEditHealthRecordPatientIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/patient-issue/{field}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"field"}}`, encodeURIComponent(String(requestParameters.field))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.issues.map(PatientIssueToJSON),
        };
        return responseConfig
    }

    /**
     * Updates or adds a new Patient Issue in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordPatientIssue(requestParameters: AddOrEditHealthRecordPatientIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.addOrEditHealthRecordPatientIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates or adds a new Medication in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordPatientMedicationRaw(requestParameters: AddOrEditHealthRecordPatientMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordPatientMedication.');
        }

        if (requestParameters.medications === null || requestParameters.medications === undefined) {
            throw new runtime.RequiredError('medications','Required parameter requestParameters.medications was null or undefined when calling addOrEditHealthRecordPatientMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/patient-issue/medications`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.medications.map(PatientMedicationToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addOrEditHealthRecordPatientMedicationRequestOptionsRaw(requestParameters: AddOrEditHealthRecordPatientMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling addOrEditHealthRecordPatientMedication.');
        }

        if (requestParameters.medications === null || requestParameters.medications === undefined) {
            throw new runtime.RequiredError('medications','Required parameter requestParameters.medications was null or undefined when calling addOrEditHealthRecordPatientMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/patient-issue/medications`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.medications.map(PatientMedicationToJSON),
        };
        return responseConfig
    }

    /**
     * Updates or adds a new Medication in a particular Health Records element (Roles: physician, admin)
     */
    async addOrEditHealthRecordPatientMedication(requestParameters: AddOrEditHealthRecordPatientMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.addOrEditHealthRecordPatientMedicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds or edits a medical order to the patient\'s records
     */
    async addOrEditMedicalOrderRaw(requestParameters: AddOrEditMedicalOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MedicalOrder>>> {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling addOrEditMedicalOrder.');
        }

        if (requestParameters.medical_order === null || requestParameters.medical_order === undefined) {
            throw new runtime.RequiredError('medical_order','Required parameter requestParameters.medical_order was null or undefined when calling addOrEditMedicalOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patient_id}/medical-order`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patient_id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalOrderToJSON(requestParameters.medical_order),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicalOrderFromJSON));
    }

    /**
        Request Config Getter
     */
    addOrEditMedicalOrderRequestOptionsRaw(requestParameters: AddOrEditMedicalOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling addOrEditMedicalOrder.');
        }

        if (requestParameters.medical_order === null || requestParameters.medical_order === undefined) {
            throw new runtime.RequiredError('medical_order','Required parameter requestParameters.medical_order was null or undefined when calling addOrEditMedicalOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patient_id}/medical-order`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patient_id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MedicalOrderToJSON(requestParameters.medical_order),
        };
        return responseConfig
    }

    /**
     * Adds or edits a medical order to the patient\'s records
     */
    async addOrEditMedicalOrder(requestParameters: AddOrEditMedicalOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MedicalOrder>> {
        const response = await this.addOrEditMedicalOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new Prescription health record (Roles: physician, admin)
     */
    async addPrescriptionRaw(requestParameters: AddPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Prescription>> {
        if (requestParameters.prescription === null || requestParameters.prescription === undefined) {
            throw new runtime.RequiredError('prescription','Required parameter requestParameters.prescription was null or undefined when calling addPrescription.');
        }

        const queryParameters: any = {};

        if (requestParameters.fax !== undefined) {
            queryParameters['fax'] = requestParameters.fax;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/prescriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionToJSON(requestParameters.prescription),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPrescriptionRequestOptionsRaw(requestParameters: AddPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.prescription === null || requestParameters.prescription === undefined) {
            throw new runtime.RequiredError('prescription','Required parameter requestParameters.prescription was null or undefined when calling addPrescription.');
        }

        const queryParameters: any = {};

        if (requestParameters.fax !== undefined) {
            queryParameters['fax'] = requestParameters.fax;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/prescriptions`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionToJSON(requestParameters.prescription),
        };
        return responseConfig
    }

    /**
     * Adds a new Prescription health record (Roles: physician, admin)
     */
    async addPrescription(requestParameters: AddPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Prescription> {
        const response = await this.addPrescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new Prescription refill health record (Roles: patient)
     */
    async addPrescriptionRefillRaw(requestParameters: AddPrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrescriptionRefill>> {
        if (requestParameters.refill === null || requestParameters.refill === undefined) {
            throw new runtime.RequiredError('refill','Required parameter requestParameters.refill was null or undefined when calling addPrescriptionRefill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/prescription-refill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionRefillToJSON(requestParameters.refill),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionRefillFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPrescriptionRefillRequestOptionsRaw(requestParameters: AddPrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.refill === null || requestParameters.refill === undefined) {
            throw new runtime.RequiredError('refill','Required parameter requestParameters.refill was null or undefined when calling addPrescriptionRefill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/prescription-refill`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionRefillToJSON(requestParameters.refill),
        };
        return responseConfig
    }

    /**
     * Adds a new Prescription refill health record (Roles: patient)
     */
    async addPrescriptionRefill(requestParameters: AddPrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrescriptionRefill> {
        const response = await this.addPrescriptionRefillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new Referral health record (Roles: physician, admin)
     */
    async addReferralRaw(requestParameters: AddReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling addReferral.');
        }

        const queryParameters: any = {};

        if (requestParameters.fax !== undefined) {
            queryParameters['fax'] = requestParameters.fax;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referrals/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addReferralRequestOptionsRaw(requestParameters: AddReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling addReferral.');
        }

        const queryParameters: any = {};

        if (requestParameters.fax !== undefined) {
            queryParameters['fax'] = requestParameters.fax;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referrals/`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        };
        return responseConfig
    }

    /**
     * Adds a new Referral health record (Roles: physician, admin)
     */
    async addReferral(requestParameters: AddReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.addReferralRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new Referral health record as an intern (Roles: physician)
     */
    async addReferralAsInternRaw(requestParameters: AddReferralAsInternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Referral>> {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling addReferralAsIntern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referrals/intern`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addReferralAsInternRequestOptionsRaw(requestParameters: AddReferralAsInternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling addReferralAsIntern.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referrals/intern`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        };
        return responseConfig
    }

    /**
     * Adds a new Referral health record as an intern (Roles: physician)
     */
    async addReferralAsIntern(requestParameters: AddReferralAsInternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Referral> {
        const response = await this.addReferralAsInternRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a Referral Request (Roles: physician)
     */
    async addReferralRequestRaw(requestParameters: AddReferralRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferralRequest>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling addReferralRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referral-request/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferralRequestFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addReferralRequestRequestOptionsRaw(requestParameters: AddReferralRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling addReferralRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referral-request/`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ReferralRequestToJSON(requestParameters.request),
        };
        return responseConfig
    }

    /**
     * Adds a Referral Request (Roles: physician)
     */
    async addReferralRequest(requestParameters: AddReferralRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferralRequest> {
        const response = await this.addReferralRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Patient\'s count of unread health records (Roles: patient)
     */
    async countUnreadHealthRecordsRaw(requestParameters: CountUnreadHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CPPAndChatUnreadCount>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling countUnreadHealthRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/cpp/unread-count`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CPPAndChatUnreadCountFromJSON));
    }

    /**
        Request Config Getter
     */
    countUnreadHealthRecordsRequestOptionsRaw(requestParameters: CountUnreadHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling countUnreadHealthRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/cpp/unread-count`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns the Patient\'s count of unread health records (Roles: patient)
     */
    async countUnreadHealthRecords(requestParameters: CountUnreadHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CPPAndChatUnreadCount>> {
        const response = await this.countUnreadHealthRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes all medication reminders (Roles: patient)
     */
    async deleteAllMedicationRemindersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteAllMedicationRemindersRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder/delete`,
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes all medication reminders (Roles: patient)
     */
    async deleteAllMedicationReminders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAllMedicationRemindersRaw(initOverrides);
    }

    /**
     * deletes an appointment note stamp
     */
    async deleteAppointmentNoteStampRaw(requestParameters: DeleteAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAppointmentNoteStamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/stamp/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteAppointmentNoteStampRequestOptionsRaw(requestParameters: DeleteAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAppointmentNoteStamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/stamp/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * deletes an appointment note stamp
     */
    async deleteAppointmentNoteStamp(requestParameters: DeleteAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAppointmentNoteStampRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a Consultant in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordConsultantRaw(requestParameters: DeleteHealthRecordConsultantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordConsultant.');
        }

        if (requestParameters.consultant === null || requestParameters.consultant === undefined) {
            throw new runtime.RequiredError('consultant','Required parameter requestParameters.consultant was null or undefined when calling deleteHealthRecordConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/consultant`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultantToJSON(requestParameters.consultant),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    deleteHealthRecordConsultantRequestOptionsRaw(requestParameters: DeleteHealthRecordConsultantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordConsultant.');
        }

        if (requestParameters.consultant === null || requestParameters.consultant === undefined) {
            throw new runtime.RequiredError('consultant','Required parameter requestParameters.consultant was null or undefined when calling deleteHealthRecordConsultant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/consultant`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ConsultantToJSON(requestParameters.consultant),
        };
        return responseConfig
    }

    /**
     * Removes a Consultant in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordConsultant(requestParameters: DeleteHealthRecordConsultantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.deleteHealthRecordConsultantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a History Issue in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordHistoryIssueRaw(requestParameters: DeleteHealthRecordHistoryIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordHistoryIssue.');
        }

        if (requestParameters.issue === null || requestParameters.issue === undefined) {
            throw new runtime.RequiredError('issue','Required parameter requestParameters.issue was null or undefined when calling deleteHealthRecordHistoryIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/history-issue`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryIssueToJSON(requestParameters.issue),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    deleteHealthRecordHistoryIssueRequestOptionsRaw(requestParameters: DeleteHealthRecordHistoryIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordHistoryIssue.');
        }

        if (requestParameters.issue === null || requestParameters.issue === undefined) {
            throw new runtime.RequiredError('issue','Required parameter requestParameters.issue was null or undefined when calling deleteHealthRecordHistoryIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/history-issue`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: HistoryIssueToJSON(requestParameters.issue),
        };
        return responseConfig
    }

    /**
     * Removes a History Issue in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordHistoryIssue(requestParameters: DeleteHealthRecordHistoryIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.deleteHealthRecordHistoryIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a Lifestyle Factor in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordLifestyleFactorRaw(requestParameters: DeleteHealthRecordLifestyleFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordLifestyleFactor.');
        }

        if (requestParameters.factor === null || requestParameters.factor === undefined) {
            throw new runtime.RequiredError('factor','Required parameter requestParameters.factor was null or undefined when calling deleteHealthRecordLifestyleFactor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/lifestyle-factor`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: LifestyleFactorToJSON(requestParameters.factor),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    deleteHealthRecordLifestyleFactorRequestOptionsRaw(requestParameters: DeleteHealthRecordLifestyleFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordLifestyleFactor.');
        }

        if (requestParameters.factor === null || requestParameters.factor === undefined) {
            throw new runtime.RequiredError('factor','Required parameter requestParameters.factor was null or undefined when calling deleteHealthRecordLifestyleFactor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/lifestyle-factor`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: LifestyleFactorToJSON(requestParameters.factor),
        };
        return responseConfig
    }

    /**
     * Removes a Lifestyle Factor in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordLifestyleFactor(requestParameters: DeleteHealthRecordLifestyleFactorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.deleteHealthRecordLifestyleFactorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a Medication in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordMedicationRaw(requestParameters: DeleteHealthRecordMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordMedication.');
        }

        if (requestParameters.issue === null || requestParameters.issue === undefined) {
            throw new runtime.RequiredError('issue','Required parameter requestParameters.issue was null or undefined when calling deleteHealthRecordMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/medication`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PatientMedicationToJSON(requestParameters.issue),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    deleteHealthRecordMedicationRequestOptionsRaw(requestParameters: DeleteHealthRecordMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordMedication.');
        }

        if (requestParameters.issue === null || requestParameters.issue === undefined) {
            throw new runtime.RequiredError('issue','Required parameter requestParameters.issue was null or undefined when calling deleteHealthRecordMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/medication`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientMedicationToJSON(requestParameters.issue),
        };
        return responseConfig
    }

    /**
     * Removes a Medication in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordMedication(requestParameters: DeleteHealthRecordMedicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.deleteHealthRecordMedicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a Patient Issue in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordPatientIssueRaw(requestParameters: DeleteHealthRecordPatientIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordPatientIssue.');
        }

        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling deleteHealthRecordPatientIssue.');
        }

        if (requestParameters.issue === null || requestParameters.issue === undefined) {
            throw new runtime.RequiredError('issue','Required parameter requestParameters.issue was null or undefined when calling deleteHealthRecordPatientIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/patient-issue/{field}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"field"}}`, encodeURIComponent(String(requestParameters.field))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PatientIssueToJSON(requestParameters.issue),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    deleteHealthRecordPatientIssueRequestOptionsRaw(requestParameters: DeleteHealthRecordPatientIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteHealthRecordPatientIssue.');
        }

        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field','Required parameter requestParameters.field was null or undefined when calling deleteHealthRecordPatientIssue.');
        }

        if (requestParameters.issue === null || requestParameters.issue === undefined) {
            throw new runtime.RequiredError('issue','Required parameter requestParameters.issue was null or undefined when calling deleteHealthRecordPatientIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/patient-issue/{field}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"field"}}`, encodeURIComponent(String(requestParameters.field))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientIssueToJSON(requestParameters.issue),
        };
        return responseConfig
    }

    /**
     * Removes a Patient Issue in a particular Health Records element (Roles: physician, admin)
     */
    async deleteHealthRecordPatientIssue(requestParameters: DeleteHealthRecordPatientIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.deleteHealthRecordPatientIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a medical order
     */
    async deleteMedicalOrderRaw(requestParameters: DeleteMedicalOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MedicalOrder>>> {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling deleteMedicalOrder.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling deleteMedicalOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patient_id}/medical-order`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patient_id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicalOrderFromJSON));
    }

    /**
        Request Config Getter
     */
    deleteMedicalOrderRequestOptionsRaw(requestParameters: DeleteMedicalOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling deleteMedicalOrder.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling deleteMedicalOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patient_id}/medical-order`.replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patient_id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a medical order
     */
    async deleteMedicalOrder(requestParameters: DeleteMedicalOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MedicalOrder>> {
        const response = await this.deleteMedicalOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an appointment note (Roles: physician, admin)
     */
    async deleteNoteRaw(requestParameters: DeleteNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    deleteNoteRequestOptionsRaw(requestParameters: DeleteNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes an appointment note (Roles: physician, admin)
     */
    async deleteNote(requestParameters: DeleteNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNote> {
        const response = await this.deleteNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an appointment note draft (Roles: physician)
     */
    async deleteNotesDraftRaw(requestParameters: DeleteNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteNotesDraft.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotesDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/notes/draft/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteNotesDraftRequestOptionsRaw(requestParameters: DeleteNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteNotesDraft.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotesDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/notes/draft/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes an appointment note draft (Roles: physician)
     */
    async deleteNotesDraft(requestParameters: DeleteNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNotesDraftRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a referral (Roles: physician)
     */
    async deleteReferralRaw(requestParameters: DeleteReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteReferral.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/referrals/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteReferralRequestOptionsRaw(requestParameters: DeleteReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling deleteReferral.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/referrals/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a referral (Roles: physician)
     */
    async deleteReferral(requestParameters: DeleteReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReferralRaw(requestParameters, initOverrides);
    }

    /**
     * Discontinues a medication reminder (Roles: patient)
     */
    async discontinueMedicationReminderRaw(requestParameters: DiscontinueMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MedicationReminder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling discontinueMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicationReminderFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    discontinueMedicationReminderRequestOptionsRaw(requestParameters: DiscontinueMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling discontinueMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Discontinues a medication reminder (Roles: patient)
     */
    async discontinueMedicationReminder(requestParameters: DiscontinueMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MedicationReminder> {
        const response = await this.discontinueMedicationReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits or adds a MetricType (Roles: admin)
     */
    async editMetricTypeRaw(requestParameters: EditMetricTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetricType>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling editMetricType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/metric-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetricTypeToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricTypeFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    editMetricTypeRequestOptionsRaw(requestParameters: EditMetricTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling editMetricType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/metric-types`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MetricTypeToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Edits or adds a MetricType (Roles: admin)
     */
    async editMetricType(requestParameters: EditMetricTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetricType> {
        const response = await this.editMetricTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits an appointment note (Roles: physician)
     */
    async editNoteRaw(requestParameters: EditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNote>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editNote.');
        }

        if (requestParameters.note === null || requestParameters.note === undefined) {
            throw new runtime.RequiredError('note','Required parameter requestParameters.note was null or undefined when calling editNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteToJSON(requestParameters.note),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    editNoteRequestOptionsRaw(requestParameters: EditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editNote.');
        }

        if (requestParameters.note === null || requestParameters.note === undefined) {
            throw new runtime.RequiredError('note','Required parameter requestParameters.note was null or undefined when calling editNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentNoteToJSON(requestParameters.note),
        };
        return responseConfig
    }

    /**
     * Edits an appointment note (Roles: physician)
     */
    async editNote(requestParameters: EditNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNote> {
        const response = await this.editNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edits a Prescription refill health record (Roles: physician)
     */
    async editPrescriptionRefillRaw(requestParameters: EditPrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrescriptionRefill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editPrescriptionRefill.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling editPrescriptionRefill.');
        }

        if (requestParameters.refill === null || requestParameters.refill === undefined) {
            throw new runtime.RequiredError('refill','Required parameter requestParameters.refill was null or undefined when calling editPrescriptionRefill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/prescription-refill/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionRefillToJSON(requestParameters.refill),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionRefillFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    editPrescriptionRefillRequestOptionsRaw(requestParameters: EditPrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editPrescriptionRefill.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling editPrescriptionRefill.');
        }

        if (requestParameters.refill === null || requestParameters.refill === undefined) {
            throw new runtime.RequiredError('refill','Required parameter requestParameters.refill was null or undefined when calling editPrescriptionRefill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/prescription-refill/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionRefillToJSON(requestParameters.refill),
        };
        return responseConfig
    }

    /**
     * Edits a Prescription refill health record (Roles: physician)
     */
    async editPrescriptionRefill(requestParameters: EditPrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrescriptionRefill> {
        const response = await this.editPrescriptionRefillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports a PDF with the patient administered drugs (Roles: physician, admin, patient)
     */
    async exportAdministeredDrugsRaw(requestParameters: ExportAdministeredDrugsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling exportAdministeredDrugs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/administered-drugs/export`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    exportAdministeredDrugsRequestOptionsRaw(requestParameters: ExportAdministeredDrugsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling exportAdministeredDrugs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/administered-drugs/export`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Exports a PDF with the patient administered drugs (Roles: physician, admin, patient)
     */
    async exportAdministeredDrugs(requestParameters: ExportAdministeredDrugsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportAdministeredDrugsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports a Patient\'s CPP (Roles: physician, admin)
     */
    async exportCPPRaw(requestParameters: ExportCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling exportCPP.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.excludeNotes !== undefined) {
            queryParameters['excludeNotes'] = requestParameters.excludeNotes;
        }

        if (requestParameters.excludeDocuments !== undefined) {
            queryParameters['excludeDocuments'] = requestParameters.excludeDocuments;
        }

        if (requestParameters.excludeSummary !== undefined) {
            queryParameters['excludeSummary'] = requestParameters.excludeSummary;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/cpp/export`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    exportCPPRequestOptionsRaw(requestParameters: ExportCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling exportCPP.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.excludeNotes !== undefined) {
            queryParameters['excludeNotes'] = requestParameters.excludeNotes;
        }

        if (requestParameters.excludeDocuments !== undefined) {
            queryParameters['excludeDocuments'] = requestParameters.excludeDocuments;
        }

        if (requestParameters.excludeSummary !== undefined) {
            queryParameters['excludeSummary'] = requestParameters.excludeSummary;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/cpp/export`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Exports a Patient\'s CPP (Roles: physician, admin)
     */
    async exportCPP(requestParameters: ExportCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.exportCPPRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Exports a PDF with the patient immunizations (Roles: physician, admin, patient)
     */
    async exportImmunizationsRaw(requestParameters: ExportImmunizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling exportImmunizations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/immunizations/export`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    exportImmunizationsRequestOptionsRaw(requestParameters: ExportImmunizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling exportImmunizations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/immunizations/export`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Exports a PDF with the patient immunizations (Roles: physician, admin, patient)
     */
    async exportImmunizations(requestParameters: ExportImmunizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.exportImmunizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates all future immunizations (Roles: physician, admin)
     */
    async generateImmunizationsRaw(requestParameters: GenerateImmunizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Immunization>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling generateImmunizations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/immunizations/generate`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImmunizationFromJSON));
    }

    /**
        Request Config Getter
     */
    generateImmunizationsRequestOptionsRaw(requestParameters: GenerateImmunizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling generateImmunizations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/immunizations/generate`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Generates all future immunizations (Roles: physician, admin)
     */
    async generateImmunizations(requestParameters: GenerateImmunizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Immunization>> {
        const response = await this.generateImmunizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets an appointment note stamp
     */
    async getAppointmentNoteStampRaw(requestParameters: GetAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentNoteStamp>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAppointmentNoteStamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/stamp/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentNoteStampFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getAppointmentNoteStampRequestOptionsRaw(requestParameters: GetAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAppointmentNoteStamp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/stamp/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets an appointment note stamp
     */
    async getAppointmentNoteStamp(requestParameters: GetAppointmentNoteStampRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentNoteStamp> {
        const response = await this.getAppointmentNoteStampRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * lists all stamps
     */
    async getAppointmentNoteStampsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetAppointmentNoteStamps200ResponseInner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/stamp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAppointmentNoteStamps200ResponseInnerFromJSON));
    }

    /**
        Request Config Getter
     */
    getAppointmentNoteStampsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/stamp`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * lists all stamps
     */
    async getAppointmentNoteStamps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetAppointmentNoteStamps200ResponseInner>> {
        const response = await this.getAppointmentNoteStampsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a Patient\'s CPP (Roles: physician, patient, admin)
     */
    async getCPPRaw(requestParameters: GetCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CPP>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getCPP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/cpp`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CPPFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getCPPRequestOptionsRaw(requestParameters: GetCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getCPP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/cpp`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a Patient\'s CPP (Roles: physician, patient, admin)
     */
    async getCPP(requestParameters: GetCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CPP> {
        const response = await this.getCPPRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets HealthData (Roles: patient, physician)
     */
    async getHealthDataRaw(requestParameters: GetHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HealthRecordsMetricAggregated>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getHealthData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/health-data`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HealthRecordsMetricAggregatedFromJSON));
    }

    /**
        Request Config Getter
     */
    getHealthDataRequestOptionsRaw(requestParameters: GetHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getHealthData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/health-data`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets HealthData (Roles: patient, physician)
     */
    async getHealthData(requestParameters: GetHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HealthRecordsMetricAggregated>> {
        const response = await this.getHealthDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets HealthData for a specific metric (Roles: patient, physician, admin)
     */
    async getHealthDataSummaryRaw(requestParameters: GetHealthDataSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HealthRecordsMetricSummary>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getHealthDataSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/health-data/summary`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HealthRecordsMetricSummaryFromJSON));
    }

    /**
        Request Config Getter
     */
    getHealthDataSummaryRequestOptionsRaw(requestParameters: GetHealthDataSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getHealthDataSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/health-data/summary`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets HealthData for a specific metric (Roles: patient, physician, admin)
     */
    async getHealthDataSummary(requestParameters: GetHealthDataSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HealthRecordsMetricSummary>> {
        const response = await this.getHealthDataSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a Patient\'s generic health records (Roles: physician)
     */
    async getHealthRecordsRaw(requestParameters: GetHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getHealthRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getHealthRecordsRequestOptionsRaw(requestParameters: GetHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getHealthRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a Patient\'s generic health records (Roles: physician)
     */
    async getHealthRecords(requestParameters: GetHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.getHealthRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a description of the available immunizations (Roles: physician, admin)
     */
    async getImmunizationDescriptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImmunizationDescription>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/immunizations/descriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImmunizationDescriptionFromJSON));
    }

    /**
        Request Config Getter
     */
    getImmunizationDescriptionsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/immunizations/descriptions`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a description of the available immunizations (Roles: physician, admin)
     */
    async getImmunizationDescriptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImmunizationDescription>> {
        const response = await this.getImmunizationDescriptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets latest HealthData for a specific metric and origin (Roles: patient)
     */
    async getLatestMetricValueRaw(requestParameters: GetLatestMetricValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MetricValue>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getLatestMetricValue.');
        }

        if (requestParameters.metric === null || requestParameters.metric === undefined) {
            throw new runtime.RequiredError('metric','Required parameter requestParameters.metric was null or undefined when calling getLatestMetricValue.');
        }

        const queryParameters: any = {};

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/health-data/{metric}/latest`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"metric"}}`, encodeURIComponent(String(requestParameters.metric))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MetricValueFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getLatestMetricValueRequestOptionsRaw(requestParameters: GetLatestMetricValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getLatestMetricValue.');
        }

        if (requestParameters.metric === null || requestParameters.metric === undefined) {
            throw new runtime.RequiredError('metric','Required parameter requestParameters.metric was null or undefined when calling getLatestMetricValue.');
        }

        const queryParameters: any = {};

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/health-data/{metric}/latest`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"metric"}}`, encodeURIComponent(String(requestParameters.metric))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets latest HealthData for a specific metric and origin (Roles: patient)
     */
    async getLatestMetricValue(requestParameters: GetLatestMetricValueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MetricValue> {
        const response = await this.getLatestMetricValueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets medication reminder notifications (Roles: patient)
     */
    async getMedicationReminderNotificationsRaw(requestParameters: GetMedicationReminderNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MedicationReminderNotification>>> {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling getMedicationReminderNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.patient_id !== undefined) {
            queryParameters['patient_id'] = requestParameters.patient_id;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.include_discontinued !== undefined) {
            queryParameters['include_discontinued'] = requestParameters.include_discontinued;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicationReminderNotificationFromJSON));
    }

    /**
        Request Config Getter
     */
    getMedicationReminderNotificationsRequestOptionsRaw(requestParameters: GetMedicationReminderNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling getMedicationReminderNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.patient_id !== undefined) {
            queryParameters['patient_id'] = requestParameters.patient_id;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        if (requestParameters.include_discontinued !== undefined) {
            queryParameters['include_discontinued'] = requestParameters.include_discontinued;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder/notifications`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets medication reminder notifications (Roles: patient)
     */
    async getMedicationReminderNotifications(requestParameters: GetMedicationReminderNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MedicationReminderNotification>> {
        const response = await this.getMedicationReminderNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all medication reminders (Roles: patient)
     */
    async getMedicationRemindersRaw(requestParameters: GetMedicationRemindersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MedicationReminder>>> {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling getMedicationReminders.');
        }

        const queryParameters: any = {};

        if (requestParameters.patient_id !== undefined) {
            queryParameters['patient_id'] = requestParameters.patient_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicationReminderFromJSON));
    }

    /**
        Request Config Getter
     */
    getMedicationRemindersRequestOptionsRaw(requestParameters: GetMedicationRemindersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patient_id === null || requestParameters.patient_id === undefined) {
            throw new runtime.RequiredError('patient_id','Required parameter requestParameters.patient_id was null or undefined when calling getMedicationReminders.');
        }

        const queryParameters: any = {};

        if (requestParameters.patient_id !== undefined) {
            queryParameters['patient_id'] = requestParameters.patient_id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all medication reminders (Roles: patient)
     */
    async getMedicationReminders(requestParameters: GetMedicationRemindersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MedicationReminder>> {
        const response = await this.getMedicationRemindersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets latest menstruation data (Roles: patient)
     */
    async getMenstruationDataRaw(requestParameters: GetMenstruationDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MTUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenstruationData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/menstruation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MTUpdateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getMenstruationDataRequestOptionsRaw(requestParameters: GetMenstruationDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMenstruationData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/menstruation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets latest menstruation data (Roles: patient)
     */
    async getMenstruationData(requestParameters: GetMenstruationDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MTUpdate> {
        const response = await this.getMenstruationDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets available menstruation reminders (Roles: patient)
     */
    async getMenstruationRemindersDescriptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MTReminderDescription>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/menstruation/reminders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MTReminderDescriptionFromJSON));
    }

    /**
        Request Config Getter
     */
    getMenstruationRemindersDescriptionsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/menstruation/reminders`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets available menstruation reminders (Roles: patient)
     */
    async getMenstruationRemindersDescriptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MTReminderDescription>> {
        const response = await this.getMenstruationRemindersDescriptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets all MetricTypes (Roles: patient, physician, admin)
     */
    async getMetricTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetricType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/metric-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetricTypeFromJSON));
    }

    /**
        Request Config Getter
     */
    getMetricTypesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/metric-types`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all MetricTypes (Roles: patient, physician, admin)
     */
    async getMetricTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetricType>> {
        const response = await this.getMetricTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a notes\' attachment (Roles: physician, patient, admin)
     */
    async getNotesAttachmentRaw(requestParameters: GetNotesAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.appointmentNoteId === null || requestParameters.appointmentNoteId === undefined) {
            throw new runtime.RequiredError('appointmentNoteId','Required parameter requestParameters.appointmentNoteId was null or undefined when calling getNotesAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/attachment/{appointmentNoteId}`.replace(`{${"appointmentNoteId"}}`, encodeURIComponent(String(requestParameters.appointmentNoteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getNotesAttachmentRequestOptionsRaw(requestParameters: GetNotesAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.appointmentNoteId === null || requestParameters.appointmentNoteId === undefined) {
            throw new runtime.RequiredError('appointmentNoteId','Required parameter requestParameters.appointmentNoteId was null or undefined when calling getNotesAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/attachment/{appointmentNoteId}`.replace(`{${"appointmentNoteId"}}`, encodeURIComponent(String(requestParameters.appointmentNoteId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a notes\' attachment (Roles: physician, patient, admin)
     */
    async getNotesAttachment(requestParameters: GetNotesAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getNotesAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a note draft\'s attachment (Roles: physician, patient, admin)
     */
    async getNotesDraftAttachmentRaw(requestParameters: GetNotesDraftAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.draftId === null || requestParameters.draftId === undefined) {
            throw new runtime.RequiredError('draftId','Required parameter requestParameters.draftId was null or undefined when calling getNotesDraftAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/draft/attachment/{draftId}`.replace(`{${"draftId"}}`, encodeURIComponent(String(requestParameters.draftId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getNotesDraftAttachmentRequestOptionsRaw(requestParameters: GetNotesDraftAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.draftId === null || requestParameters.draftId === undefined) {
            throw new runtime.RequiredError('draftId','Required parameter requestParameters.draftId was null or undefined when calling getNotesDraftAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/draft/attachment/{draftId}`.replace(`{${"draftId"}}`, encodeURIComponent(String(requestParameters.draftId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a note draft\'s attachment (Roles: physician, patient, admin)
     */
    async getNotesDraftAttachment(requestParameters: GetNotesDraftAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getNotesDraftAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets own note draft (Roles: physician)
     */
    async getOwnDraftsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentNoteDraft>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/notes/draft/own`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentNoteDraftFromJSON));
    }

    /**
        Request Config Getter
     */
    getOwnDraftsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/notes/draft/own`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets own note draft (Roles: physician)
     */
    async getOwnDrafts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentNoteDraft>> {
        const response = await this.getOwnDraftsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a all pending records by the practitioner\'s interns (Roles: physician)
     */
    async getOwnInternsPendingRecordsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InternPendingRecords>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/interns/pending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternPendingRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getOwnInternsPendingRecordsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/interns/pending`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a all pending records by the practitioner\'s interns (Roles: physician)
     */
    async getOwnInternsPendingRecords(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InternPendingRecords> {
        const response = await this.getOwnInternsPendingRecordsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets a patient\'s appointment note drafts (Roles: physician)
     */
    async getPatientNotesDraftRaw(requestParameters: GetPatientNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentNoteDraft>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPatientNotesDraft.');
        }

        const queryParameters: any = {};

        if (requestParameters.backups !== undefined) {
            queryParameters['backups'] = requestParameters.backups;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/notes/draft`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentNoteDraftFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientNotesDraftRequestOptionsRaw(requestParameters: GetPatientNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPatientNotesDraft.');
        }

        const queryParameters: any = {};

        if (requestParameters.backups !== undefined) {
            queryParameters['backups'] = requestParameters.backups;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/notes/draft`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets a patient\'s appointment note drafts (Roles: physician)
     */
    async getPatientNotesDraft(requestParameters: GetPatientNotesDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentNoteDraft>> {
        const response = await this.getPatientNotesDraftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets pending Referral health records (Roles: physician, admin)
     */
    async getPendingReferralsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referrals/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getPendingReferralsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referrals/`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets pending Referral health records (Roles: physician, admin)
     */
    async getPendingReferrals(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getPendingReferralsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the attachment of the prescription (Roles: patient, physician, admin)
     */
    async getPrescriptionAttachmentRaw(requestParameters: GetPrescriptionAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPrescriptionAttachment.');
        }

        if (requestParameters.prescriptionId === null || requestParameters.prescriptionId === undefined) {
            throw new runtime.RequiredError('prescriptionId','Required parameter requestParameters.prescriptionId was null or undefined when calling getPrescriptionAttachment.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling getPrescriptionAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/prescriptions/{prescriptionId}/get-attachment`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"prescriptionId"}}`, encodeURIComponent(String(requestParameters.prescriptionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getPrescriptionAttachmentRequestOptionsRaw(requestParameters: GetPrescriptionAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPrescriptionAttachment.');
        }

        if (requestParameters.prescriptionId === null || requestParameters.prescriptionId === undefined) {
            throw new runtime.RequiredError('prescriptionId','Required parameter requestParameters.prescriptionId was null or undefined when calling getPrescriptionAttachment.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling getPrescriptionAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/prescriptions/{prescriptionId}/get-attachment`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"prescriptionId"}}`, encodeURIComponent(String(requestParameters.prescriptionId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        };
        return responseConfig
    }

    /**
     * Gets the attachment of the prescription (Roles: patient, physician, admin)
     */
    async getPrescriptionAttachment(requestParameters: GetPrescriptionAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getPrescriptionAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the pdf of the prescription (Roles: patient, physician, admin)
     */
    async getPrescriptionPDFRaw(requestParameters: GetPrescriptionPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPrescriptionPDF.');
        }

        if (requestParameters.prescriptionId === null || requestParameters.prescriptionId === undefined) {
            throw new runtime.RequiredError('prescriptionId','Required parameter requestParameters.prescriptionId was null or undefined when calling getPrescriptionPDF.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/prescriptions/{prescriptionId}/pdf`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"prescriptionId"}}`, encodeURIComponent(String(requestParameters.prescriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    getPrescriptionPDFRequestOptionsRaw(requestParameters: GetPrescriptionPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPrescriptionPDF.');
        }

        if (requestParameters.prescriptionId === null || requestParameters.prescriptionId === undefined) {
            throw new runtime.RequiredError('prescriptionId','Required parameter requestParameters.prescriptionId was null or undefined when calling getPrescriptionPDF.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/prescriptions/{prescriptionId}/pdf`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"prescriptionId"}}`, encodeURIComponent(String(requestParameters.prescriptionId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the pdf of the prescription (Roles: patient, physician, admin)
     */
    async getPrescriptionPDF(requestParameters: GetPrescriptionPDFRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getPrescriptionPDFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all Prescription refills (Roles: physician)
     */
    async getPrescriptionRefillsRaw(requestParameters: GetPrescriptionRefillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrescriptionRefill>>> {
        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/prescription-refill`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrescriptionRefillFromJSON));
    }

    /**
        Request Config Getter
     */
    getPrescriptionRefillsRequestOptionsRaw(requestParameters: GetPrescriptionRefillsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/prescription-refill`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all Prescription refills (Roles: physician)
     */
    async getPrescriptionRefills(requestParameters: GetPrescriptionRefillsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrescriptionRefill>> {
        const response = await this.getPrescriptionRefillsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the Referral Requests for the user\'s clinic (Roles: admin)
     */
    async getReferralRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferralRequest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referral-request/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferralRequestFromJSON));
    }

    /**
        Request Config Getter
     */
    getReferralRequestsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referral-request/`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the Referral Requests for the user\'s clinic (Roles: admin)
     */
    async getReferralRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferralRequest>> {
        const response = await this.getReferralRequestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets Referral health records by status (Roles: physician, admin)
     */
    async getReferralsByStatusRaw(requestParameters: GetReferralsByStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientDocument>>> {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling getReferralsByStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referrals/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientDocumentFromJSON));
    }

    /**
        Request Config Getter
     */
    getReferralsByStatusRequestOptionsRaw(requestParameters: GetReferralsByStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling getReferralsByStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referrals/status`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets Referral health records by status (Roles: physician, admin)
     */
    async getReferralsByStatus(requestParameters: GetReferralsByStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientDocument>> {
        const response = await this.getReferralsByStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets HealthData for a specific metric (Roles: patient, physician, admin)
     */
    async getSpecificHealthDataRaw(requestParameters: GetSpecificHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecordsMetricAggregated>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getSpecificHealthData.');
        }

        if (requestParameters.metric === null || requestParameters.metric === undefined) {
            throw new runtime.RequiredError('metric','Required parameter requestParameters.metric was null or undefined when calling getSpecificHealthData.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/health-data/{metric}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"metric"}}`, encodeURIComponent(String(requestParameters.metric))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsMetricAggregatedFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getSpecificHealthDataRequestOptionsRaw(requestParameters: GetSpecificHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getSpecificHealthData.');
        }

        if (requestParameters.metric === null || requestParameters.metric === undefined) {
            throw new runtime.RequiredError('metric','Required parameter requestParameters.metric was null or undefined when calling getSpecificHealthData.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.origin !== undefined) {
            queryParameters['origin'] = requestParameters.origin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/health-data/{metric}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"metric"}}`, encodeURIComponent(String(requestParameters.metric))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets HealthData for a specific metric (Roles: patient, physician, admin)
     */
    async getSpecificHealthData(requestParameters: GetSpecificHealthDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecordsMetricAggregated> {
        const response = await this.getSpecificHealthDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks all health records as read (Roles: patient)
     */
    async markAllHealthRecordsReadRaw(requestParameters: MarkAllHealthRecordsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CPP>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markAllHealthRecordsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/mark-read`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CPPFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    markAllHealthRecordsReadRequestOptionsRaw(requestParameters: MarkAllHealthRecordsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markAllHealthRecordsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/mark-read`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Marks all health records as read (Roles: patient)
     */
    async markAllHealthRecordsRead(requestParameters: MarkAllHealthRecordsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CPP> {
        const response = await this.markAllHealthRecordsReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Marks a health record element as read (Roles: patient)
     */
    async markHealthRecordReadRaw(requestParameters: MarkHealthRecordReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markHealthRecordRead.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling markHealthRecordRead.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markHealthRecordRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/mark-read/{type}/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    markHealthRecordReadRequestOptionsRaw(requestParameters: MarkHealthRecordReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling markHealthRecordRead.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling markHealthRecordRead.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markHealthRecordRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/mark-read/{type}/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Marks a health record element as read (Roles: patient)
     */
    async markHealthRecordRead(requestParameters: MarkHealthRecordReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markHealthRecordReadRaw(requestParameters, initOverrides);
    }

    /**
     * Generates a PDF preview of the Prescription (Roles: physician)
     */
    async previewPrescriptionRaw(requestParameters: PreviewPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.prescription === null || requestParameters.prescription === undefined) {
            throw new runtime.RequiredError('prescription','Required parameter requestParameters.prescription was null or undefined when calling previewPrescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/prescriptions/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionToJSON(requestParameters.prescription),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    previewPrescriptionRequestOptionsRaw(requestParameters: PreviewPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.prescription === null || requestParameters.prescription === undefined) {
            throw new runtime.RequiredError('prescription','Required parameter requestParameters.prescription was null or undefined when calling previewPrescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/prescriptions/preview`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionToJSON(requestParameters.prescription),
        };
        return responseConfig
    }

    /**
     * Generates a PDF preview of the Prescription (Roles: physician)
     */
    async previewPrescription(requestParameters: PreviewPrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewPrescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates a PDF preview of the Referral (Roles: physician)
     */
    async previewReferralRaw(requestParameters: PreviewReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling previewReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referrals/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
        Request Config Getter
     */
    previewReferralRequestOptionsRaw(requestParameters: PreviewReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.referral === null || requestParameters.referral === undefined) {
            throw new runtime.RequiredError('referral','Required parameter requestParameters.referral was null or undefined when calling previewReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referrals/preview`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ReferralToJSON(requestParameters.referral),
        };
        return responseConfig
    }

    /**
     * Generates a PDF preview of the Referral (Roles: physician)
     */
    async previewReferral(requestParameters: PreviewReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.previewReferralRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an Administered Drug health record (Roles: physician)
     */
    async removeAdministeredDrugRaw(requestParameters: RemoveAdministeredDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdministeredDrug>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeAdministeredDrug.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removeAdministeredDrug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/administered-drugs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdministeredDrugFromJSON));
    }

    /**
        Request Config Getter
     */
    removeAdministeredDrugRequestOptionsRaw(requestParameters: RemoveAdministeredDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeAdministeredDrug.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removeAdministeredDrug.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/administered-drugs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes an Administered Drug health record (Roles: physician)
     */
    async removeAdministeredDrug(requestParameters: RemoveAdministeredDrugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdministeredDrug>> {
        const response = await this.removeAdministeredDrugRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an Immunization health record (Roles: physician)
     */
    async removeImmunizationRaw(requestParameters: RemoveImmunizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Immunization>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeImmunization.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removeImmunization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/immunizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImmunizationFromJSON));
    }

    /**
        Request Config Getter
     */
    removeImmunizationRequestOptionsRaw(requestParameters: RemoveImmunizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeImmunization.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removeImmunization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/immunizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes an Immunization health record (Roles: physician)
     */
    async removeImmunization(requestParameters: RemoveImmunizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Immunization>> {
        const response = await this.removeImmunizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rejects a new Prescription refill (Roles: physician)
     */
    async removePrescriptionRefillRaw(requestParameters: RemovePrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrescriptionRefill>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePrescriptionRefill.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removePrescriptionRefill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/prescription-refill/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionRefillFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    removePrescriptionRefillRequestOptionsRaw(requestParameters: RemovePrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePrescriptionRefill.');
        }

        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling removePrescriptionRefill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/prescription-refill/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Rejects a new Prescription refill (Roles: physician)
     */
    async removePrescriptionRefill(requestParameters: RemovePrescriptionRefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrescriptionRefill> {
        const response = await this.removePrescriptionRefillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a new Referral health record (Roles: physician, admin)
     */
    async removeReferralRequestRaw(requestParameters: RemoveReferralRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeReferralRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/referral-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removeReferralRequestRequestOptionsRaw(requestParameters: RemoveReferralRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeReferralRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/referral-request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a new Referral health record (Roles: physician, admin)
     */
    async removeReferralRequest(requestParameters: RemoveReferralRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeReferralRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Resets menstruation data (Roles: patient)
     */
    async resetMenstruationDataRaw(requestParameters: ResetMenstruationDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resetMenstruationData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/menstruation/{id}/reset`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    resetMenstruationDataRequestOptionsRaw(requestParameters: ResetMenstruationDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resetMenstruationData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/menstruation/{id}/reset`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Resets menstruation data (Roles: patient)
     */
    async resetMenstruationData(requestParameters: ResetMenstruationDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.resetMenstruationDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches the HR for patients on the practitioner\'s roster (Roles: physician)
     */
    async searchHealthRecordsRaw(requestParameters: SearchHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HealthRecords>>> {
        if (requestParameters.term === null || requestParameters.term === undefined) {
            throw new runtime.RequiredError('term','Required parameter requestParameters.term was null or undefined when calling searchHealthRecords.');
        }

        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling searchHealthRecords.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling searchHealthRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/search/{physicianId}/{term}`.replace(`{${"term"}}`, encodeURIComponent(String(requestParameters.term))).replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HealthRecordsFromJSON));
    }

    /**
        Request Config Getter
     */
    searchHealthRecordsRequestOptionsRaw(requestParameters: SearchHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.term === null || requestParameters.term === undefined) {
            throw new runtime.RequiredError('term','Required parameter requestParameters.term was null or undefined when calling searchHealthRecords.');
        }

        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling searchHealthRecords.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling searchHealthRecords.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/search/{physicianId}/{term}`.replace(`{${"term"}}`, encodeURIComponent(String(requestParameters.term))).replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MultipleValuesPayloadToJSON(requestParameters.ids),
        };
        return responseConfig
    }

    /**
     * Searches the HR for patients on the practitioner\'s roster (Roles: physician)
     */
    async searchHealthRecords(requestParameters: SearchHealthRecordsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HealthRecords>> {
        const response = await this.searchHealthRecordsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Snoozes a medication reminder (Roles: patient)
     */
    async snoozeMedicationReminderRaw(requestParameters: SnoozeMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.duration === null || requestParameters.duration === undefined) {
            throw new runtime.RequiredError('duration','Required parameter requestParameters.duration was null or undefined when calling snoozeMedicationReminder.');
        }

        if (requestParameters.reminder === null || requestParameters.reminder === undefined) {
            throw new runtime.RequiredError('reminder','Required parameter requestParameters.reminder was null or undefined when calling snoozeMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder/snooze/{duration}`.replace(`{${"duration"}}`, encodeURIComponent(String(requestParameters.duration))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderNotificationToJSON(requestParameters.reminder),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    snoozeMedicationReminderRequestOptionsRaw(requestParameters: SnoozeMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.duration === null || requestParameters.duration === undefined) {
            throw new runtime.RequiredError('duration','Required parameter requestParameters.duration was null or undefined when calling snoozeMedicationReminder.');
        }

        if (requestParameters.reminder === null || requestParameters.reminder === undefined) {
            throw new runtime.RequiredError('reminder','Required parameter requestParameters.reminder was null or undefined when calling snoozeMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder/snooze/{duration}`.replace(`{${"duration"}}`, encodeURIComponent(String(requestParameters.duration))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderNotificationToJSON(requestParameters.reminder),
        };
        return responseConfig
    }

    /**
     * Snoozes a medication reminder (Roles: patient)
     */
    async snoozeMedicationReminder(requestParameters: SnoozeMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.snoozeMedicationReminderRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the health records information object (Roles: physician, admin)
     */
    async updateHealthRecordsInformationRaw(requestParameters: UpdateHealthRecordsInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthRecords>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updateHealthRecordsInformation.');
        }

        if (requestParameters.information === null || requestParameters.information === undefined) {
            throw new runtime.RequiredError('information','Required parameter requestParameters.information was null or undefined when calling updateHealthRecordsInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/information`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HealthRecordsInformationToJSON(requestParameters.information),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthRecordsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateHealthRecordsInformationRequestOptionsRaw(requestParameters: UpdateHealthRecordsInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updateHealthRecordsInformation.');
        }

        if (requestParameters.information === null || requestParameters.information === undefined) {
            throw new runtime.RequiredError('information','Required parameter requestParameters.information was null or undefined when calling updateHealthRecordsInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/information`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: HealthRecordsInformationToJSON(requestParameters.information),
        };
        return responseConfig
    }

    /**
     * Updates the health records information object (Roles: physician, admin)
     */
    async updateHealthRecordsInformation(requestParameters: UpdateHealthRecordsInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthRecords> {
        const response = await this.updateHealthRecordsInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a medication reminder (Roles: patient)
     */
    async updateMedicationReminderRaw(requestParameters: UpdateMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MedicationReminder>>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicationReminderFromJSON));
    }

    /**
        Request Config Getter
     */
    updateMedicationReminderRequestOptionsRaw(requestParameters: UpdateMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateMedicationReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder`,
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Updates a medication reminder (Roles: patient)
     */
    async updateMedicationReminder(requestParameters: UpdateMedicationReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MedicationReminder>> {
        const response = await this.updateMedicationReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * adds or removes a take from a medication reminder (Roles: patient)
     */
    async updateMedicationReminderTakeRaw(requestParameters: UpdateMedicationReminderTakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MedicationReminder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMedicationReminderTake.');
        }

        if (requestParameters.take === null || requestParameters.take === undefined) {
            throw new runtime.RequiredError('take','Required parameter requestParameters.take was null or undefined when calling updateMedicationReminderTake.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/medication-reminder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderTakeToJSON(requestParameters.take),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicationReminderFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateMedicationReminderTakeRequestOptionsRaw(requestParameters: UpdateMedicationReminderTakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMedicationReminderTake.');
        }

        if (requestParameters.take === null || requestParameters.take === undefined) {
            throw new runtime.RequiredError('take','Required parameter requestParameters.take was null or undefined when calling updateMedicationReminderTake.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/medication-reminder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MedicationReminderTakeToJSON(requestParameters.take),
        };
        return responseConfig
    }

    /**
     * adds or removes a take from a medication reminder (Roles: patient)
     */
    async updateMedicationReminderTake(requestParameters: UpdateMedicationReminderTakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MedicationReminder> {
        const response = await this.updateMedicationReminderTakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a menstruation event (Roles: patient)
     */
    async updateMenstruationDateRaw(requestParameters: UpdateMenstruationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MTUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenstruationDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling updateMenstruationDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/menstruation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MTDateToJSON(requestParameters.date),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MTUpdateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateMenstruationDateRequestOptionsRaw(requestParameters: UpdateMenstruationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenstruationDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling updateMenstruationDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/menstruation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MTDateToJSON(requestParameters.date),
        };
        return responseConfig
    }

    /**
     * Updates a menstruation event (Roles: patient)
     */
    async updateMenstruationDate(requestParameters: UpdateMenstruationDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MTUpdate> {
        const response = await this.updateMenstruationDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates multiple menstruation events (Roles: patient)
     */
    async updateMenstruationDatesRaw(requestParameters: UpdateMenstruationDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MTUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenstruationDates.');
        }

        if (requestParameters.dates === null || requestParameters.dates === undefined) {
            throw new runtime.RequiredError('dates','Required parameter requestParameters.dates was null or undefined when calling updateMenstruationDates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/menstruation/{id}/bulk`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.dates.map(MTDateToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MTUpdateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateMenstruationDatesRequestOptionsRaw(requestParameters: UpdateMenstruationDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenstruationDates.');
        }

        if (requestParameters.dates === null || requestParameters.dates === undefined) {
            throw new runtime.RequiredError('dates','Required parameter requestParameters.dates was null or undefined when calling updateMenstruationDates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/menstruation/{id}/bulk`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.dates.map(MTDateToJSON),
        };
        return responseConfig
    }

    /**
     * Updates multiple menstruation events (Roles: patient)
     */
    async updateMenstruationDates(requestParameters: UpdateMenstruationDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MTUpdate> {
        const response = await this.updateMenstruationDatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the menstruation tutorial data (Roles: patient)
     */
    async updateMenstruationTutorialDataRaw(requestParameters: UpdateMenstruationTutorialDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MTUpdate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenstruationTutorialData.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateMenstruationTutorialData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/menstruation/{id}/tutorial`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MTTutorialDataToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MTUpdateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateMenstruationTutorialDataRequestOptionsRaw(requestParameters: UpdateMenstruationTutorialDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMenstruationTutorialData.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling updateMenstruationTutorialData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/menstruation/{id}/tutorial`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MTTutorialDataToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Updates the menstruation tutorial data (Roles: patient)
     */
    async updateMenstruationTutorialData(requestParameters: UpdateMenstruationTutorialDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MTUpdate> {
        const response = await this.updateMenstruationTutorialDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a Prescription (Roles: physician)
     */
    async updatePrescriptionRaw(requestParameters: UpdatePrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Prescription>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updatePrescription.');
        }

        if (requestParameters.prescriptionId === null || requestParameters.prescriptionId === undefined) {
            throw new runtime.RequiredError('prescriptionId','Required parameter requestParameters.prescriptionId was null or undefined when calling updatePrescription.');
        }

        if (requestParameters.prescription === null || requestParameters.prescription === undefined) {
            throw new runtime.RequiredError('prescription','Required parameter requestParameters.prescription was null or undefined when calling updatePrescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/prescriptions/{prescriptionId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"prescriptionId"}}`, encodeURIComponent(String(requestParameters.prescriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionToJSON(requestParameters.prescription),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updatePrescriptionRequestOptionsRaw(requestParameters: UpdatePrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updatePrescription.');
        }

        if (requestParameters.prescriptionId === null || requestParameters.prescriptionId === undefined) {
            throw new runtime.RequiredError('prescriptionId','Required parameter requestParameters.prescriptionId was null or undefined when calling updatePrescription.');
        }

        if (requestParameters.prescription === null || requestParameters.prescription === undefined) {
            throw new runtime.RequiredError('prescription','Required parameter requestParameters.prescription was null or undefined when calling updatePrescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/prescriptions/{prescriptionId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"prescriptionId"}}`, encodeURIComponent(String(requestParameters.prescriptionId))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionToJSON(requestParameters.prescription),
        };
        return responseConfig
    }

    /**
     * Updates a Prescription (Roles: physician)
     */
    async updatePrescription(requestParameters: UpdatePrescriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Prescription> {
        const response = await this.updatePrescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a referral (Roles: physician, admin)
     */
    async updateReferralRaw(requestParameters: UpdateReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientDocument>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updateReferral.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateReferral.');
        }

        if (requestParameters.document === null || requestParameters.document === undefined) {
            throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling updateReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/health-records/{patientId}/referrals/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PatientDocumentToJSON(requestParameters.document),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientDocumentFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateReferralRequestOptionsRaw(requestParameters: UpdateReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling updateReferral.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateReferral.');
        }

        if (requestParameters.document === null || requestParameters.document === undefined) {
            throw new runtime.RequiredError('document','Required parameter requestParameters.document was null or undefined when calling updateReferral.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/health-records/{patientId}/referrals/{id}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientDocumentToJSON(requestParameters.document),
        };
        return responseConfig
    }

    /**
     * Updates a referral (Roles: physician, admin)
     */
    async updateReferral(requestParameters: UpdateReferralRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientDocument> {
        const response = await this.updateReferralRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AddOrEditHealthRecordPatientIssueFieldEnum = {
    Medications: 'medications',
    MedicalIssues: 'medical_issues',
    Allergies: 'allergies',
    Notes: 'notes',
    PreventativeScreenings: 'preventative_screenings'
} as const;
export type AddOrEditHealthRecordPatientIssueFieldEnum = typeof AddOrEditHealthRecordPatientIssueFieldEnum[keyof typeof AddOrEditHealthRecordPatientIssueFieldEnum];
/**
 * @export
 */
export const DeleteHealthRecordPatientIssueFieldEnum = {
    MedicalIssues: 'medical_issues',
    Allergies: 'allergies',
    Notes: 'notes',
    PreventativeScreenings: 'preventative_screenings'
} as const;
export type DeleteHealthRecordPatientIssueFieldEnum = typeof DeleteHealthRecordPatientIssueFieldEnum[keyof typeof DeleteHealthRecordPatientIssueFieldEnum];
/**
 * @export
 */
export const GetLatestMetricValueOriginEnum = {
    AppleHealth: 'Apple Health',
    AndroidHealth: 'Android Health',
    Lab: 'Lab',
    Practitioner: 'Practitioner'
} as const;
export type GetLatestMetricValueOriginEnum = typeof GetLatestMetricValueOriginEnum[keyof typeof GetLatestMetricValueOriginEnum];
/**
 * @export
 */
export const MarkHealthRecordReadTypeEnum = {
    Appointment: 'Appointment',
    AppointmentNote: 'AppointmentNote',
    Immunization: 'Immunization',
    Prescription: 'Prescription',
    PatientDocument: 'PatientDocument'
} as const;
export type MarkHealthRecordReadTypeEnum = typeof MarkHealthRecordReadTypeEnum[keyof typeof MarkHealthRecordReadTypeEnum];
