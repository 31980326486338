/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountInformation
 */
export interface AccountInformation {
    /**
     * 
     * @type {string}
     * @memberof AccountInformation
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformation
     */
    receive_push?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformation
     */
    recieve_voip?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformation
     */
    designated_guardian?: boolean;
}

/**
 * Check if a given object implements the AccountInformation interface.
 */
export function instanceOfAccountInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function AccountInformationFromJSON(json: any): AccountInformation {
    return AccountInformationFromJSONTyped(json, false);
}

export function AccountInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'receive_push': !exists(json, 'receive_push') ? undefined : json['receive_push'],
        'recieve_voip': !exists(json, 'recieve_voip') ? undefined : json['recieve_voip'],
        'designated_guardian': !exists(json, 'designated_guardian') ? undefined : json['designated_guardian'],
    };
}

export function AccountInformationToJSON(value?: AccountInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'receive_push': value.receive_push,
        'recieve_voip': value.recieve_voip,
        'designated_guardian': value.designated_guardian,
    };
}

