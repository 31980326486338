/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
} from './Shift';

/**
 * 
 * @export
 * @interface ShiftRequest
 */
export interface ShiftRequest {
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    clinic_id?: string;
    /**
     * 
     * @type {number}
     * @memberof ShiftRequest
     */
    date_requested?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftRequest
     */
    date_closed?: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    status?: ShiftRequestStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShiftRequest
     */
    reviewed_by?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    user_role?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftRequest
     */
    reply?: string;
    /**
     * 
     * @type {Array<Shift>}
     * @memberof ShiftRequest
     */
    replaced_shifts?: Array<Shift>;
    /**
     * 
     * @type {Array<Shift>}
     * @memberof ShiftRequest
     */
    shifts?: Array<Shift>;
}


/**
 * @export
 */
export const ShiftRequestStatusEnum = {
    Approved: 'Approved',
    Declined: 'Declined',
    Pending: 'Pending',
    PartiallyApproved: 'Partially Approved'
} as const;
export type ShiftRequestStatusEnum = typeof ShiftRequestStatusEnum[keyof typeof ShiftRequestStatusEnum];


/**
 * Check if a given object implements the ShiftRequest interface.
 */
export function instanceOfShiftRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShiftRequestFromJSON(json: any): ShiftRequest {
    return ShiftRequestFromJSONTyped(json, false);
}

export function ShiftRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'date_requested': !exists(json, 'date_requested') ? undefined : json['date_requested'],
        'date_closed': !exists(json, 'date_closed') ? undefined : json['date_closed'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'reviewed_by': !exists(json, 'reviewed_by') ? undefined : json['reviewed_by'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'user_role': !exists(json, 'user_role') ? undefined : json['user_role'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'reply': !exists(json, 'reply') ? undefined : json['reply'],
        'replaced_shifts': !exists(json, 'replaced_shifts') ? undefined : ((json['replaced_shifts'] as Array<any>).map(ShiftFromJSON)),
        'shifts': !exists(json, 'shifts') ? undefined : ((json['shifts'] as Array<any>).map(ShiftFromJSON)),
    };
}

export function ShiftRequestToJSON(value?: ShiftRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'clinic_id': value.clinic_id,
        'date_requested': value.date_requested,
        'date_closed': value.date_closed,
        'status': value.status,
        'reviewed_by': value.reviewed_by,
        'user_id': value.user_id,
        'user_role': value.user_role,
        'comments': value.comments,
        'reply': value.reply,
        'replaced_shifts': value.replaced_shifts === undefined ? undefined : ((value.replaced_shifts as Array<any>).map(ShiftToJSON)),
        'shifts': value.shifts === undefined ? undefined : ((value.shifts as Array<any>).map(ShiftToJSON)),
    };
}

