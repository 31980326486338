/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HelpdeskMessageContent
 */
export interface HelpdeskMessageContent {
    /**
     * 
     * @type {number}
     * @memberof HelpdeskMessageContent
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessageContent
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessageContent
     */
    from?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelpdeskMessageContent
     */
    to?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessageContent
     */
    staff_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HelpdeskMessageContent
     */
    email_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelpdeskMessageContent
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HelpdeskMessageContent
     */
    read_by?: Array<string>;
}

/**
 * Check if a given object implements the HelpdeskMessageContent interface.
 */
export function instanceOfHelpdeskMessageContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HelpdeskMessageContentFromJSON(json: any): HelpdeskMessageContent {
    return HelpdeskMessageContentFromJSONTyped(json, false);
}

export function HelpdeskMessageContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelpdeskMessageContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'staff_id': !exists(json, 'staff_id') ? undefined : json['staff_id'],
        'email_id': !exists(json, 'email_id') ? undefined : json['email_id'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'read_by': !exists(json, 'read_by') ? undefined : json['read_by'],
    };
}

export function HelpdeskMessageContentToJSON(value?: HelpdeskMessageContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'content': value.content,
        'from': value.from,
        'to': value.to,
        'staff_id': value.staff_id,
        'email_id': value.email_id,
        'attachments': value.attachments,
        'read_by': value.read_by,
    };
}

