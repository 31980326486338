/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Consultant } from './Consultant';
import {
    ConsultantFromJSON,
    ConsultantFromJSONTyped,
    ConsultantToJSON,
} from './Consultant';
import type { GTPAL } from './GTPAL';
import {
    GTPALFromJSON,
    GTPALFromJSONTyped,
    GTPALToJSON,
} from './GTPAL';
import type { HistoryIssue } from './HistoryIssue';
import {
    HistoryIssueFromJSON,
    HistoryIssueFromJSONTyped,
    HistoryIssueToJSON,
} from './HistoryIssue';
import type { LifestyleFactor } from './LifestyleFactor';
import {
    LifestyleFactorFromJSON,
    LifestyleFactorFromJSONTyped,
    LifestyleFactorToJSON,
} from './LifestyleFactor';
import type { PatientIssue } from './PatientIssue';
import {
    PatientIssueFromJSON,
    PatientIssueFromJSONTyped,
    PatientIssueToJSON,
} from './PatientIssue';
import type { PatientMedication } from './PatientMedication';
import {
    PatientMedicationFromJSON,
    PatientMedicationFromJSONTyped,
    PatientMedicationToJSON,
} from './PatientMedication';

/**
 * 
 * @export
 * @interface AppointmentNoteHealthRecords
 */
export interface AppointmentNoteHealthRecords {
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof AppointmentNoteHealthRecords
     */
    medical_issues?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof AppointmentNoteHealthRecords
     */
    allergies?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<PatientMedication>}
     * @memberof AppointmentNoteHealthRecords
     */
    medications?: Array<PatientMedication>;
    /**
     * 
     * @type {Array<LifestyleFactor>}
     * @memberof AppointmentNoteHealthRecords
     */
    lifestyle_factors?: Array<LifestyleFactor>;
    /**
     * 
     * @type {Array<HistoryIssue>}
     * @memberof AppointmentNoteHealthRecords
     */
    history?: Array<HistoryIssue>;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof AppointmentNoteHealthRecords
     */
    gtpal?: Array<PatientIssue>;
    /**
     * 
     * @type {GTPAL}
     * @memberof AppointmentNoteHealthRecords
     */
    gtpal_main?: GTPAL;
    /**
     * 
     * @type {Array<PatientIssue>}
     * @memberof AppointmentNoteHealthRecords
     */
    preventative_screenings?: Array<PatientIssue>;
    /**
     * 
     * @type {Array<Consultant>}
     * @memberof AppointmentNoteHealthRecords
     */
    consultants?: Array<Consultant>;
}

/**
 * Check if a given object implements the AppointmentNoteHealthRecords interface.
 */
export function instanceOfAppointmentNoteHealthRecords(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentNoteHealthRecordsFromJSON(json: any): AppointmentNoteHealthRecords {
    return AppointmentNoteHealthRecordsFromJSONTyped(json, false);
}

export function AppointmentNoteHealthRecordsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentNoteHealthRecords {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'medical_issues': !exists(json, 'medical_issues') ? undefined : ((json['medical_issues'] as Array<any>).map(PatientIssueFromJSON)),
        'allergies': !exists(json, 'allergies') ? undefined : ((json['allergies'] as Array<any>).map(PatientIssueFromJSON)),
        'medications': !exists(json, 'medications') ? undefined : ((json['medications'] as Array<any>).map(PatientMedicationFromJSON)),
        'lifestyle_factors': !exists(json, 'lifestyle_factors') ? undefined : ((json['lifestyle_factors'] as Array<any>).map(LifestyleFactorFromJSON)),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(HistoryIssueFromJSON)),
        'gtpal': !exists(json, 'gtpal') ? undefined : ((json['gtpal'] as Array<any>).map(PatientIssueFromJSON)),
        'gtpal_main': !exists(json, 'gtpal_main') ? undefined : GTPALFromJSON(json['gtpal_main']),
        'preventative_screenings': !exists(json, 'preventative_screenings') ? undefined : ((json['preventative_screenings'] as Array<any>).map(PatientIssueFromJSON)),
        'consultants': !exists(json, 'consultants') ? undefined : ((json['consultants'] as Array<any>).map(ConsultantFromJSON)),
    };
}

export function AppointmentNoteHealthRecordsToJSON(value?: AppointmentNoteHealthRecords | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'medical_issues': value.medical_issues === undefined ? undefined : ((value.medical_issues as Array<any>).map(PatientIssueToJSON)),
        'allergies': value.allergies === undefined ? undefined : ((value.allergies as Array<any>).map(PatientIssueToJSON)),
        'medications': value.medications === undefined ? undefined : ((value.medications as Array<any>).map(PatientMedicationToJSON)),
        'lifestyle_factors': value.lifestyle_factors === undefined ? undefined : ((value.lifestyle_factors as Array<any>).map(LifestyleFactorToJSON)),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(HistoryIssueToJSON)),
        'gtpal': value.gtpal === undefined ? undefined : ((value.gtpal as Array<any>).map(PatientIssueToJSON)),
        'gtpal_main': GTPALToJSON(value.gtpal_main),
        'preventative_screenings': value.preventative_screenings === undefined ? undefined : ((value.preventative_screenings as Array<any>).map(PatientIssueToJSON)),
        'consultants': value.consultants === undefined ? undefined : ((value.consultants as Array<any>).map(ConsultantToJSON)),
    };
}

