/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Pharmacy,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PharmacyFromJSON,
    PharmacyToJSON,
} from '../models';

export interface AddPharmacyRequest {
    pharmacy: Pharmacy;
}

export interface GetPharmaciesRequest {
    name?: string;
    limit?: number;
}

export interface RemovePharmacyRequest {
    id: string;
}

/**
 * 
 */
export class PharmaciesApi extends runtime.BaseAPI {

    /**
     * Creates a new Pharmacy (Roles: physician, admin)
     */
    async addPharmacyRaw(requestParameters: AddPharmacyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pharmacy>> {
        if (requestParameters.pharmacy === null || requestParameters.pharmacy === undefined) {
            throw new runtime.RequiredError('pharmacy','Required parameter requestParameters.pharmacy was null or undefined when calling addPharmacy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pharmacies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PharmacyToJSON(requestParameters.pharmacy),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPharmacyRequestOptionsRaw(requestParameters: AddPharmacyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.pharmacy === null || requestParameters.pharmacy === undefined) {
            throw new runtime.RequiredError('pharmacy','Required parameter requestParameters.pharmacy was null or undefined when calling addPharmacy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/pharmacies`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PharmacyToJSON(requestParameters.pharmacy),
        };
        return responseConfig
    }

    /**
     * Creates a new Pharmacy (Roles: physician, admin)
     */
    async addPharmacy(requestParameters: AddPharmacyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pharmacy> {
        const response = await this.addPharmacyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all Pharmacies (Roles: physician, admin, patient)
     */
    async getPharmaciesRaw(requestParameters: GetPharmaciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Pharmacy>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pharmacies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyFromJSON));
    }

    /**
        Request Config Getter
     */
    getPharmaciesRequestOptionsRaw(requestParameters: GetPharmaciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/pharmacies`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all Pharmacies (Roles: physician, admin, patient)
     */
    async getPharmacies(requestParameters: GetPharmaciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Pharmacy>> {
        const response = await this.getPharmaciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes a Pharmacy (Roles: physician, admin)
     */
    async removePharmacyRaw(requestParameters: RemovePharmacyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePharmacy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pharmacies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removePharmacyRequestOptionsRaw(requestParameters: RemovePharmacyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePharmacy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/pharmacies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a Pharmacy (Roles: physician, admin)
     */
    async removePharmacy(requestParameters: RemovePharmacyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePharmacyRaw(requestParameters, initOverrides);
    }

}
