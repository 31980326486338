/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClinicStatisticsNew,
  ErrorResponse,
  FinancialStatistics,
  StatisticsEvent,
} from '../models';
import {
    ClinicStatisticsNewFromJSON,
    ClinicStatisticsNewToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FinancialStatisticsFromJSON,
    FinancialStatisticsToJSON,
    StatisticsEventFromJSON,
    StatisticsEventToJSON,
} from '../models';

/**
 * 
 */
export class StatsApi extends runtime.BaseAPI {

    /**
     * Gets the financial stats (Roles: admin)
     */
    async getFinancialStatsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialStatistics>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/stats/financial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialStatisticsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getFinancialStatsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/stats/financial`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the financial stats (Roles: admin)
     */
    async getFinancialStats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialStatistics> {
        const response = await this.getFinancialStatsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the clinic\'s stats (Roles: admin)
     */
    async getNewClinicStatsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClinicStatisticsNew>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/stats/clinics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClinicStatisticsNewFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getNewClinicStatsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/stats/clinics`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the clinic\'s stats (Roles: admin)
     */
    async getNewClinicStats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClinicStatisticsNew> {
        const response = await this.getNewClinicStatsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the user sessions by platform (Roles: admin)
     */
    async getTechnologySessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StatisticsEvent>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/stats/technology/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StatisticsEventFromJSON));
    }

    /**
        Request Config Getter
     */
    getTechnologySessionsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/stats/technology/sessions`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user sessions by platform (Roles: admin)
     */
    async getTechnologySessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StatisticsEvent>> {
        const response = await this.getTechnologySessionsRaw(initOverrides);
        return await response.value();
    }

}
