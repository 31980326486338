import React, { useMemo } from 'react';
import AdminUtils from 'services/AdminUtils';
import PatientUtils from 'services/PatientUtils';
import PhysicianUtils from 'services/PhysicianUtils';
import Icon from 'components/Utils/Icon';
import DefaultTooltip from 'components/Utils/DefaultTooltip';
import avatarMale from 'assets/img/icons/avatar-male.svg';
import { useAppSelector } from 'careand-redux/store';
import MiscUtils from 'services/MiscUtils';

export const UserImage = ({
  user = {},
  userRole,
  size = 60,
  onClick = undefined,
  className = undefined,
  photo,
  extra,
  hideTooltip = false,
  defaultPhoto,
  ...props
}) => {
  const ownRole = useAppSelector((state) => state.authentication.role);

  const photoUrls = useMemo(() => {
    let photos = photo ? [photo, avatarMale] : getPhotoUrls(user, userRole, defaultPhoto);
    return photos.filter((photo) => !!photo);
  }, [photo, user, userRole, defaultPhoto]);

  const backgroundUrls = useMemo(() => {
    return getBackgroundUrls(photoUrls);
  }, [photoUrls]);

  const allowTooltip = !hideTooltip && ownRole !== 'patient' && photoUrls.length > 1;

  return (
    <>
      <DefaultTooltip
        open={allowTooltip ? undefined : false}
        classes={{
          tooltip: 'transparent-tooltip',
        }}
        title={
          allowTooltip && (
            <img
              src={photoUrls[0]}
              style={{ borderRadius: '15px', width: '200px', height: 'auto' }}
              alt="Profile"
            />
          )
        }
      >
        <div
          className={'center-cropped avatar ' + (className || '')}
          onClick={onClick}
          style={{
            backgroundImage: backgroundUrls,
            left: '0',
            top: '0',
            width: size + 'px',
            height: size + 'px',
            minHeight: size + 'px',
            minWidth: size + 'px',
            position: 'relative',
          }}
          {...props}
        >
          {extra}
        </div>
      </DefaultTooltip>
    </>
  );
};

function getBackgroundUrls(photos = []) {
  return photos
    .filter((url) => !!url)
    .map((url) => {
      return `url("${url}")`;
    })
    .join(',');
}

function getPhotoUrls(user, role, defaultPhoto) {
  let result = [];
  switch (role) {
    case 'patient':
      result = PatientUtils.getPhotoUrls(user);
      break;
    case 'physician':
      result = PhysicianUtils.getPhotoUrls(user);
      break;
    case 'admin':
      result = AdminUtils.getPhotoUrls(user);
      break;
    default:
      return [defaultPhoto || avatarMale];
  }
  return result.map((url) => {
    if (url && url.startsWith('data')) {
      return MiscUtils.dataUrlToBlobUrl(url);
    }
    return url;
  });
}

export const PhysicianImage = (props) => <UserImage userRole="physician" {...props} />;
export const AdminImage = (props) => <UserImage userRole="admin" {...props} />;
export const PatientImage = (props) => {
  const role = useAppSelector((state) => state.authentication.role);

  return (
    <UserImage
      userRole="patient"
      {...props}
      extra={
        <>
          {!props.hideAlert && role !== 'patient' && !!(props.user || {}).alert && (
            <Icon
              onClick={props.onClickedAlert}
              src={['fad', 'circle-exclamation']}
              className="red"
              showTooltip={true}
              alt={(props.user || {}).alert}
              width="20px"
              iconStyle={{
                '--fa-primary-color': 'white',
                '--fa-secondary-color': 'red',
                '--fa-secondary-opacity': 1.0,
              }}
              style={{
                position: 'absolute',
                left: '0',
                bottom: '-5px',
                zIndex: 1,
              }}
            />
          )}
          {!!props.showAlertEdit && role !== 'patient' && !(props.user || {}).alert && (
            <Icon
              onClick={props.onClickedAlert}
              src="check-circle"
              showTooltip={true}
              alt="No Alerts"
              width="20px"
              height="20px"
              className="clickable blue"
              style={{
                position: 'absolute',
                left: '0',
                bottom: '-5px',
                zIndex: 20,
                background: 'white',
                borderRadius: '50%',
              }}
            />
          )}
        </>
      }
    />
  );
};
export const ProfileImage = (props) => <UserImage {...props} />;
