import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";
import AuthenticationController from "components/Authentication/AuthenticationController";
import LoadingScreen from "components/Utils/LoadingScreen";
import config from "config/config.json";
import App from "scenes/App";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import MiscUtils from "services/MiscUtils";

import { store, persistor } from "careand-redux/store";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import socket from "websockets/socket";

import configVersion from "config/version.json";
import { disableReactDevTools } from "services/disableReactDevTools";
import { Portal } from "@material-ui/core";
import PatientUtils from "services/PatientUtils";

const PORTAL_VERSION = configVersion["client-version"];

const ErrorBoundary = MiscUtils.getBugsnagClient()
  .getPlugin("react")
  .createErrorBoundary(React);

const ENVIRONMENT = config.ENVIRONMENT;
const PATIENT = config.PATIENT || false;

if (ENVIRONMENT === "production" && import.meta.env.PROD)
  disableReactDevTools();

export const SOCKET = { instance: null };

if (ENVIRONMENT === "production") {
  ReactGA.initialize("UA-111406161-3");
  const tagManagerArgs = { gtmId: "GTM-K24PMTB" };
  TagManager.initialize(tagManagerArgs);
}

/*const instance = createInstance({
  urlBase: "https://analytics.careand.ca",
  siteId: 1,
  trackerUrl: "https://analytics.careand.ca/rainbow",
  srcUrl: "https://analytics.careand.ca/unicorn",
  disabled: ENVIRONMENT !== 'production' || !PATIENT,
  linkTracking: true,
});*/

const MATOMO_ENABLED = ENVIRONMENT === "production" && PATIENT;
const theme = createTheme();

const AppWrapper = (props) => {
  const [authenticated, setAuthenticated] = useState();

  function onAuthenticationChange(authenticated, userId) {
    setAuthenticated(authenticated);
  }

  useEffect(() => {
    if (MATOMO_ENABLED) {
      const url = "https://analytics.careand.ca/";
      window._paq = window._paq || [];
      PatientUtils.matomoPushInstruction('trackPageView');
      PatientUtils.matomoPushInstruction('enableLinkTracking');

      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = url + "unicorn";
      s.parentNode.insertBefore(g, s);

      PatientUtils.matomoPushInstruction("setTrackerUrl", url + "rainbow");
      PatientUtils.matomoPushInstruction("setSiteId", "1");
      PatientUtils.matomoPushInstruction("setDomains", ["*.careand.ca", "careand.ca"]);
      PatientUtils.matomoPushInstruction("enableCrossDomainLinking");
    }
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <BrowserRouter>
              <Portal>
                <ReduxToastr
                  timeOut={4000}
                  newestOnTop={false}
                  preventDuplicates
                  maxAnimationDelay={275}
                  position="bottom-right"
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  progressBar={true}
                  closeOnToastrClick={true}
                />
              </Portal>
              <AuthenticationController
                closeSocket={socket.close}
                openSocket={socket.open}
                version={PORTAL_VERSION}
                patient={PATIENT}
                onAuthenticationChange={onAuthenticationChange}
              />
              {authenticated !== undefined && (
                <App
                  authenticated={authenticated}
                  version={PORTAL_VERSION}
                  patient={PATIENT}
                  environment={ENVIRONMENT}
                />
              )}
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default AppWrapper;
