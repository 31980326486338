import _ from "lodash";
import { z } from "zod";

export const NativeMessageSchema = z.object({
  id: z.string(),
  replyTo: z.string().optional()
})

export type NativeMessage = z.infer<typeof NativeMessageSchema>

const generateId = () => _.uniqueId()

export const createMessage = () => ({
  id: generateId(),
} satisfies NativeMessage)

export const createReply = (from: NativeMessage) => ({
  ...createMessage(),
  replyTo: from.id
} satisfies NativeMessage)