/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface PhysicianContact
 */
export interface PhysicianContact {
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    specialty?: string;
    /**
     * 
     * @type {Address}
     * @memberof PhysicianContact
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    physician_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof PhysicianContact
     */
    alert?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhysicianContact
     */
    track_referrals?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PhysicianContact
     */
    unavailable_until?: number;
}

/**
 * Check if a given object implements the PhysicianContact interface.
 */
export function instanceOfPhysicianContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function PhysicianContactFromJSON(json: any): PhysicianContact {
    return PhysicianContactFromJSONTyped(json, false);
}

export function PhysicianContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhysicianContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'specialty': !exists(json, 'specialty') ? undefined : json['specialty'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'alert': !exists(json, 'alert') ? undefined : json['alert'],
        'track_referrals': !exists(json, 'track_referrals') ? undefined : json['track_referrals'],
        'unavailable_until': !exists(json, 'unavailable_until') ? undefined : json['unavailable_until'],
    };
}

export function PhysicianContactToJSON(value?: PhysicianContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'specialty': value.specialty,
        'address': AddressToJSON(value.address),
        'email': value.email,
        'phone': value.phone,
        'fax': value.fax,
        'physician_id': value.physician_id,
        'clinic_id': value.clinic_id,
        'search': value.search,
        'notes': value.notes,
        'alert': value.alert,
        'track_referrals': value.track_referrals,
        'unavailable_until': value.unavailable_until,
    };
}

