/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    sms_main?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    sms_linked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notifications_linked?: boolean;
}

/**
 * Check if a given object implements the NotificationSettings interface.
 */
export function instanceOfNotificationSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return NotificationSettingsFromJSONTyped(json, false);
}

export function NotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sms_main': !exists(json, 'sms_main') ? undefined : json['sms_main'],
        'sms_linked': !exists(json, 'sms_linked') ? undefined : json['sms_linked'],
        'notifications_linked': !exists(json, 'notifications_linked') ? undefined : json['notifications_linked'],
    };
}

export function NotificationSettingsToJSON(value?: NotificationSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sms_main': value.sms_main,
        'sms_linked': value.sms_linked,
        'notifications_linked': value.notifications_linked,
    };
}

