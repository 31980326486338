/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaxHistory } from './FaxHistory';
import {
    FaxHistoryFromJSON,
    FaxHistoryFromJSONTyped,
    FaxHistoryToJSON,
} from './FaxHistory';

/**
 * 
 * @export
 * @interface Fax
 */
export interface Fax {
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    author_role: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    author_id: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    filename: string;
    /**
     * 
     * @type {Array<FaxHistory>}
     * @memberof Fax
     */
    status_history?: Array<FaxHistory>;
    /**
     * 
     * @type {number}
     * @memberof Fax
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    clinic_id_status_archived?: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    clinic_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Fax
     */
    attempts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Fax
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof Fax
     */
    document_id?: string;
}

/**
 * Check if a given object implements the Fax interface.
 */
export function instanceOfFax(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "author_role" in value;
    isInstance = isInstance && "author_id" in value;
    isInstance = isInstance && "patient_id" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function FaxFromJSON(json: any): Fax {
    return FaxFromJSONTyped(json, false);
}

export function FaxFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fax {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'author_role': json['author_role'],
        'author_id': json['author_id'],
        'patient_id': json['patient_id'],
        'filename': json['filename'],
        'status_history': !exists(json, 'status_history') ? undefined : ((json['status_history'] as Array<any>).map(FaxHistoryFromJSON)),
        'date': json['date'],
        'to': json['to'],
        'status': json['status'],
        'clinic_id_status_archived': !exists(json, 'clinic_id_status_archived') ? undefined : json['clinic_id_status_archived'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'attempts': !exists(json, 'attempts') ? undefined : json['attempts'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'document_id': !exists(json, 'document_id') ? undefined : json['document_id'],
    };
}

export function FaxToJSON(value?: Fax | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'author_role': value.author_role,
        'author_id': value.author_id,
        'patient_id': value.patient_id,
        'filename': value.filename,
        'status_history': value.status_history === undefined ? undefined : ((value.status_history as Array<any>).map(FaxHistoryToJSON)),
        'date': value.date,
        'to': value.to,
        'status': value.status,
        'clinic_id_status_archived': value.clinic_id_status_archived,
        'title': value.title,
        'clinic_id': value.clinic_id,
        'attempts': value.attempts,
        'archived': value.archived,
        'provider': value.provider,
        'comments': value.comments,
        'document_id': value.document_id,
    };
}

