/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MedicationReminderTimezoneChange
 */
export interface MedicationReminderTimezoneChange {
    /**
     * 
     * @type {number}
     * @memberof MedicationReminderTimezoneChange
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicationReminderTimezoneChange
     */
    tz?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationReminderTimezoneChange
     */
    follow?: boolean;
}

/**
 * Check if a given object implements the MedicationReminderTimezoneChange interface.
 */
export function instanceOfMedicationReminderTimezoneChange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicationReminderTimezoneChangeFromJSON(json: any): MedicationReminderTimezoneChange {
    return MedicationReminderTimezoneChangeFromJSONTyped(json, false);
}

export function MedicationReminderTimezoneChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationReminderTimezoneChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'tz': !exists(json, 'tz') ? undefined : json['tz'],
        'follow': !exists(json, 'follow') ? undefined : json['follow'],
    };
}

export function MedicationReminderTimezoneChangeToJSON(value?: MedicationReminderTimezoneChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'tz': value.tz,
        'follow': value.follow,
    };
}

