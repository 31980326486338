/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MTEvent
 */
export interface MTEvent {
    /**
     * 
     * @type {string}
     * @memberof MTEvent
     */
    name: MTEventNameEnum;
    /**
     * 
     * @type {string}
     * @memberof MTEvent
     */
    value: string;
}


/**
 * @export
 */
export const MTEventNameEnum = {
    Bleeding: 'Bleeding',
    Pain: 'Pain',
    Intestinal: 'Intestinal',
    Sex: 'Sex',
    OvulationTest: 'OvulationTest',
    BasalTemperature: 'BasalTemperature',
    CervicalMucous: 'CervicalMucous'
} as const;
export type MTEventNameEnum = typeof MTEventNameEnum[keyof typeof MTEventNameEnum];


/**
 * Check if a given object implements the MTEvent interface.
 */
export function instanceOfMTEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function MTEventFromJSON(json: any): MTEvent {
    return MTEventFromJSONTyped(json, false);
}

export function MTEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MTEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
    };
}

export function MTEventToJSON(value?: MTEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
    };
}

