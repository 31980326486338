/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppointmentConcern } from './AppointmentConcern';
import {
    AppointmentConcernFromJSON,
    AppointmentConcernFromJSONTyped,
    AppointmentConcernToJSON,
} from './AppointmentConcern';

/**
 * 
 * @export
 * @interface QueueElement
 */
export interface QueueElement {
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    patient_id: string;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    physician_id?: string;
    /**
     * 
     * @type {number}
     * @memberof QueueElement
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    status?: QueueElementStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    room?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueueElement
     */
    priority?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    appointment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    concern_id?: string;
    /**
     * 
     * @type {AppointmentConcern}
     * @memberof QueueElement
     */
    concern?: AppointmentConcern;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    complaints?: string;
    /**
     * 
     * @type {number}
     * @memberof QueueElement
     */
    wait_until?: number;
    /**
     * 
     * @type {string}
     * @memberof QueueElement
     */
    wait_message?: string;
}


/**
 * @export
 */
export const QueueElementStatusEnum = {
    Waiting: 'waiting',
    CalledIn: 'called_in',
    Help: 'help',
    Clean: 'clean',
    Travelling: 'travelling'
} as const;
export type QueueElementStatusEnum = typeof QueueElementStatusEnum[keyof typeof QueueElementStatusEnum];


/**
 * Check if a given object implements the QueueElement interface.
 */
export function instanceOfQueueElement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "patient_id" in value;

    return isInstance;
}

export function QueueElementFromJSON(json: any): QueueElement {
    return QueueElementFromJSONTyped(json, false);
}

export function QueueElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueueElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patient_id': json['patient_id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'appointment_id': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'concern_id': !exists(json, 'concern_id') ? undefined : json['concern_id'],
        'concern': !exists(json, 'concern') ? undefined : AppointmentConcernFromJSON(json['concern']),
        'complaints': !exists(json, 'complaints') ? undefined : json['complaints'],
        'wait_until': !exists(json, 'wait_until') ? undefined : json['wait_until'],
        'wait_message': !exists(json, 'wait_message') ? undefined : json['wait_message'],
    };
}

export function QueueElementToJSON(value?: QueueElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'patient_id': value.patient_id,
        'physician_id': value.physician_id,
        'date': value.date,
        'status': value.status,
        'room': value.room,
        'priority': value.priority,
        'appointment_id': value.appointment_id,
        'clinic_id': value.clinic_id,
        'concern_id': value.concern_id,
        'concern': AppointmentConcernToJSON(value.concern),
        'complaints': value.complaints,
        'wait_until': value.wait_until,
        'wait_message': value.wait_message,
    };
}

