/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatisticsValue } from './StatisticsValue';
import {
    StatisticsValueFromJSON,
    StatisticsValueFromJSONTyped,
    StatisticsValueToJSON,
} from './StatisticsValue';

/**
 * 
 * @export
 * @interface StatisticsValues
 */
export interface StatisticsValues {
    /**
     * 
     * @type {string}
     * @memberof StatisticsValues
     */
    physician_id?: string;
    /**
     * 
     * @type {Array<StatisticsValue>}
     * @memberof StatisticsValues
     */
    values?: Array<StatisticsValue>;
}

/**
 * Check if a given object implements the StatisticsValues interface.
 */
export function instanceOfStatisticsValues(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatisticsValuesFromJSON(json: any): StatisticsValues {
    return StatisticsValuesFromJSONTyped(json, false);
}

export function StatisticsValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticsValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(StatisticsValueFromJSON)),
    };
}

export function StatisticsValuesToJSON(value?: StatisticsValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physician_id': value.physician_id,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(StatisticsValueToJSON)),
    };
}

