/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministeredDrugValue } from './AdministeredDrugValue';
import {
    AdministeredDrugValueFromJSON,
    AdministeredDrugValueFromJSONTyped,
    AdministeredDrugValueToJSON,
} from './AdministeredDrugValue';

/**
 * 
 * @export
 * @interface MedicalOrder
 */
export interface MedicalOrder {
    /**
     * 
     * @type {string}
     * @memberof MedicalOrder
     */
    text?: string;
    /**
     * 
     * @type {Array<AdministeredDrugValue>}
     * @memberof MedicalOrder
     */
    medications?: Array<AdministeredDrugValue>;
    /**
     * 
     * @type {string}
     * @memberof MedicalOrder
     */
    author_id: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalOrder
     */
    user_id?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalOrder
     */
    date: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalOrder
     */
    read_by?: Array<string>;
}

/**
 * Check if a given object implements the MedicalOrder interface.
 */
export function instanceOfMedicalOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "author_id" in value;
    isInstance = isInstance && "date" in value;

    return isInstance;
}

export function MedicalOrderFromJSON(json: any): MedicalOrder {
    return MedicalOrderFromJSONTyped(json, false);
}

export function MedicalOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'medications': !exists(json, 'medications') ? undefined : ((json['medications'] as Array<any>).map(AdministeredDrugValueFromJSON)),
        'author_id': json['author_id'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'date': json['date'],
        'read_by': !exists(json, 'read_by') ? undefined : json['read_by'],
    };
}

export function MedicalOrderToJSON(value?: MedicalOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'medications': value.medications === undefined ? undefined : ((value.medications as Array<any>).map(AdministeredDrugValueToJSON)),
        'author_id': value.author_id,
        'user_id': value.user_id,
        'date': value.date,
        'read_by': value.read_by,
    };
}

