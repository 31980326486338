/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CouponMetadata } from './CouponMetadata';
import {
    CouponMetadataFromJSON,
    CouponMetadataFromJSONTyped,
    CouponMetadataToJSON,
} from './CouponMetadata';
import type { InvoiceDiscount } from './InvoiceDiscount';
import {
    InvoiceDiscountFromJSON,
    InvoiceDiscountFromJSONTyped,
    InvoiceDiscountToJSON,
} from './InvoiceDiscount';
import type { InvoiceLine } from './InvoiceLine';
import {
    InvoiceLineFromJSON,
    InvoiceLineFromJSONTyped,
    InvoiceLineToJSON,
} from './InvoiceLine';
import type { PaymentPlan } from './PaymentPlan';
import {
    PaymentPlanFromJSON,
    PaymentPlanFromJSONTyped,
    PaymentPlanToJSON,
} from './PaymentPlan';

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    date?: number;
    /**
     * 
     * @type {InvoiceDiscount}
     * @memberof Invoice
     */
    discount?: InvoiceDiscount;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    total?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    closed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    description?: string;
    /**
     * 
     * @type {Array<InvoiceLine>}
     * @memberof Invoice
     */
    lines?: Array<InvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    tax_percent?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    tax?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    amount_due?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    amount_paid?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    amount_remaining?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    billing?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    hosted_invoice_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoice_pdf?: string;
    /**
     * 
     * @type {CouponMetadata}
     * @memberof Invoice
     */
    metadata?: CouponMetadata;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    period_start?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    period_end?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    subscription?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    subtotal?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    created?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    attempted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    attempt_count?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    charge?: string;
    /**
     * 
     * @type {PaymentPlan}
     * @memberof Invoice
     */
    plan?: PaymentPlan;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    paid_at?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    fee?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    refunded?: number;
}

/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'discount': !exists(json, 'discount') ? undefined : InvoiceDiscountFromJSON(json['discount']),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'paid': !exists(json, 'paid') ? undefined : json['paid'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'lines': !exists(json, 'lines') ? undefined : ((json['lines'] as Array<any>).map(InvoiceLineFromJSON)),
        'tax_percent': !exists(json, 'tax_percent') ? undefined : json['tax_percent'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'amount_due': !exists(json, 'amount_due') ? undefined : json['amount_due'],
        'amount_paid': !exists(json, 'amount_paid') ? undefined : json['amount_paid'],
        'amount_remaining': !exists(json, 'amount_remaining') ? undefined : json['amount_remaining'],
        'billing': !exists(json, 'billing') ? undefined : json['billing'],
        'hosted_invoice_url': !exists(json, 'hosted_invoice_url') ? undefined : json['hosted_invoice_url'],
        'invoice_pdf': !exists(json, 'invoice_pdf') ? undefined : json['invoice_pdf'],
        'metadata': !exists(json, 'metadata') ? undefined : CouponMetadataFromJSON(json['metadata']),
        'period_start': !exists(json, 'period_start') ? undefined : json['period_start'],
        'period_end': !exists(json, 'period_end') ? undefined : json['period_end'],
        'subscription': !exists(json, 'subscription') ? undefined : json['subscription'],
        'subtotal': !exists(json, 'subtotal') ? undefined : json['subtotal'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'attempted': !exists(json, 'attempted') ? undefined : json['attempted'],
        'attempt_count': !exists(json, 'attempt_count') ? undefined : json['attempt_count'],
        'charge': !exists(json, 'charge') ? undefined : json['charge'],
        'plan': !exists(json, 'plan') ? undefined : PaymentPlanFromJSON(json['plan']),
        'paid_at': !exists(json, 'paid_at') ? undefined : json['paid_at'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'refunded': !exists(json, 'refunded') ? undefined : json['refunded'],
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date,
        'discount': InvoiceDiscountToJSON(value.discount),
        'total': value.total,
        'paid': value.paid,
        'currency': value.currency,
        'closed': value.closed,
        'customer': value.customer,
        'description': value.description,
        'lines': value.lines === undefined ? undefined : ((value.lines as Array<any>).map(InvoiceLineToJSON)),
        'tax_percent': value.tax_percent,
        'tax': value.tax,
        'number': value.number,
        'amount_due': value.amount_due,
        'amount_paid': value.amount_paid,
        'amount_remaining': value.amount_remaining,
        'billing': value.billing,
        'hosted_invoice_url': value.hosted_invoice_url,
        'invoice_pdf': value.invoice_pdf,
        'metadata': CouponMetadataToJSON(value.metadata),
        'period_start': value.period_start,
        'period_end': value.period_end,
        'subscription': value.subscription,
        'subtotal': value.subtotal,
        'status': value.status,
        'created': value.created,
        'attempted': value.attempted,
        'attempt_count': value.attempt_count,
        'charge': value.charge,
        'plan': PaymentPlanToJSON(value.plan),
        'paid_at': value.paid_at,
        'fee': value.fee,
        'refunded': value.refunded,
    };
}

