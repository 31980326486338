/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetricTypeAppleHealth } from './MetricTypeAppleHealth';
import {
    MetricTypeAppleHealthFromJSON,
    MetricTypeAppleHealthFromJSONTyped,
    MetricTypeAppleHealthToJSON,
} from './MetricTypeAppleHealth';

/**
 * 
 * @export
 * @interface MetricType
 */
export interface MetricType {
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    units?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    aggregation_method?: MetricTypeAggregationMethodEnum;
    /**
     * 
     * @type {Array<MetricTypeAppleHealth>}
     * @memberof MetricType
     */
    apple_health_ids?: Array<MetricTypeAppleHealth>;
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    about?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetricType
     */
    types?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MetricType
     */
    type?: string;
}


/**
 * @export
 */
export const MetricTypeAggregationMethodEnum = {
    Sum: 'sum',
    Average: 'average'
} as const;
export type MetricTypeAggregationMethodEnum = typeof MetricTypeAggregationMethodEnum[keyof typeof MetricTypeAggregationMethodEnum];


/**
 * Check if a given object implements the MetricType interface.
 */
export function instanceOfMetricType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MetricTypeFromJSON(json: any): MetricType {
    return MetricTypeFromJSONTyped(json, false);
}

export function MetricTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'units': !exists(json, 'units') ? undefined : json['units'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'aggregation_method': !exists(json, 'aggregation_method') ? undefined : json['aggregation_method'],
        'apple_health_ids': !exists(json, 'apple_health_ids') ? undefined : ((json['apple_health_ids'] as Array<any>).map(MetricTypeAppleHealthFromJSON)),
        'about': !exists(json, 'about') ? undefined : json['about'],
        'types': !exists(json, 'types') ? undefined : json['types'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MetricTypeToJSON(value?: MetricType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'units': value.units,
        'reference': value.reference,
        'aggregation_method': value.aggregation_method,
        'apple_health_ids': value.apple_health_ids === undefined ? undefined : ((value.apple_health_ids as Array<any>).map(MetricTypeAppleHealthToJSON)),
        'about': value.about,
        'types': value.types,
        'type': value.type,
    };
}

