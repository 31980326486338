import { createListenerMiddleware } from "@reduxjs/toolkit"
import { deviceActions } from "careand-redux/slices/device"
import { useAppSelector } from "careand-redux/store";
import { useEffect } from "react";
import { SafeArea } from "services/Native/messages/Setup";

export const safeAreaListener = createListenerMiddleware()

safeAreaListener.startListening({
  actionCreator: deviceActions.safeAreaChanged,
  effect: (action) => {
    const safeArea = action.payload;
    updateDocumentSafeArea(safeArea)
  }
})

export function updateDocumentSafeArea(safeArea: Partial<SafeArea>) {
  (['top', 'bottom', 'left', 'right'] as const).forEach(key => {
    if (key in safeArea) {
      const value = safeArea[key]?.toFixed(0)
      document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`)
    }
  })
}

export function useAutoUpdateSafeAreas() {
  const safeArea = useAppSelector(state => state.device.safeArea)

  useEffect(() => {
    updateDocumentSafeArea(safeArea)
  }, [safeArea])
}
