/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministeredDrugValue } from './AdministeredDrugValue';
import {
    AdministeredDrugValueFromJSON,
    AdministeredDrugValueFromJSONTyped,
    AdministeredDrugValueToJSON,
} from './AdministeredDrugValue';

/**
 * 
 * @export
 * @interface AdministeredDrugHistory
 */
export interface AdministeredDrugHistory {
    /**
     * 
     * @type {number}
     * @memberof AdministeredDrugHistory
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministeredDrugHistory
     */
    author_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministeredDrugHistory
     */
    author_role?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministeredDrugHistory
     */
    action?: string;
    /**
     * 
     * @type {AdministeredDrugValue}
     * @memberof AdministeredDrugHistory
     */
    value?: AdministeredDrugValue;
}

/**
 * Check if a given object implements the AdministeredDrugHistory interface.
 */
export function instanceOfAdministeredDrugHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdministeredDrugHistoryFromJSON(json: any): AdministeredDrugHistory {
    return AdministeredDrugHistoryFromJSONTyped(json, false);
}

export function AdministeredDrugHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdministeredDrugHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'author_id': !exists(json, 'author_id') ? undefined : json['author_id'],
        'author_role': !exists(json, 'author_role') ? undefined : json['author_role'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'value': !exists(json, 'value') ? undefined : AdministeredDrugValueFromJSON(json['value']),
    };
}

export function AdministeredDrugHistoryToJSON(value?: AdministeredDrugHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'author_id': value.author_id,
        'author_role': value.author_role,
        'action': value.action,
        'value': AdministeredDrugValueToJSON(value.value),
    };
}

