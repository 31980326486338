import { createAction } from "@reduxjs/toolkit";
import { logout } from "./logout";
import type { AppDispatch, RootState } from "careand-redux/store";

const tokenExpiredAction = createAction('tokenExpired')

const tokenExpiredThunk = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const previousUser = getState()?.authentication.user?.id
  dispatch(tokenExpiredAction())
  dispatch(logout({ previousUserId: previousUser }))
}

tokenExpiredThunk.action = tokenExpiredAction

export const tokenExpired = tokenExpiredThunk
