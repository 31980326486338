/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Admin,
  AdminWithAuth,
  Authentication,
  BookingFlowOption,
  CPP,
  ClinicInfo,
  ErrorResponse,
  FaxConfirmation,
  HoursOperation,
  Patient,
  PatientWithAuth,
  Physician,
  PhysicianWithAuth,
  TestEmailEvent,
  TestSystemNotification,
} from '../models';
import {
    AdminFromJSON,
    AdminToJSON,
    AdminWithAuthFromJSON,
    AdminWithAuthToJSON,
    AuthenticationFromJSON,
    AuthenticationToJSON,
    BookingFlowOptionFromJSON,
    BookingFlowOptionToJSON,
    CPPFromJSON,
    CPPToJSON,
    ClinicInfoFromJSON,
    ClinicInfoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FaxConfirmationFromJSON,
    FaxConfirmationToJSON,
    HoursOperationFromJSON,
    HoursOperationToJSON,
    PatientFromJSON,
    PatientToJSON,
    PatientWithAuthFromJSON,
    PatientWithAuthToJSON,
    PhysicianFromJSON,
    PhysicianToJSON,
    PhysicianWithAuthFromJSON,
    PhysicianWithAuthToJSON,
    TestEmailEventFromJSON,
    TestEmailEventToJSON,
    TestSystemNotificationFromJSON,
    TestSystemNotificationToJSON,
} from '../models';

export interface AddLinkedRequest {
    id: string;
}

export interface DeleteAppointmentsRequest {
    physicianId: string;
}

export interface RemoveUserRequest {
    username: string;
}

export interface ResetHoursOfOperationRequest {
    userId?: string;
}

export interface SeedAdminWithDataRequest {
    data?: AdminWithAuth;
}

export interface SeedCPPRequest {
    patientId: string;
    cpp?: CPP;
}

export interface SeedCreditCardRequest {
    patientId: string;
    token: string;
}

export interface SeedPatientWithDataRequest {
    subscription?: string;
    creditcard?: string;
    data?: PatientWithAuth;
}

export interface SeedPhysicianWithDataRequest {
    shifts?: boolean;
    signature?: boolean;
    data?: PhysicianWithAuth;
}

export interface SeedShiftsRequest {
    physicianId: string;
}

export interface SeedSubscriptionRequest {
    patientId: string;
}

export interface SeedUserRequest {
    role: SeedUserRoleEnum;
    username: string;
    healthrecords?: boolean;
    shifts?: boolean;
    creditcard?: string;
    subscription?: string;
    clinic_id?: string;
    has_selected_primary?: boolean;
}

export interface SendEmailRequest {
    id: string;
}

export interface SendFaxConfirmationRequest {
    data: FaxConfirmation;
}

export interface SetHoursOfOperationRequest {
    hours: HoursOperation;
    userId?: string;
    clinicId?: string;
}

export interface TestErrorRequest {
    code: string;
}

/**
 * 
 */
export class TestApi extends runtime.BaseAPI {

    /**
     * Adds a linked account to a user (Roles: none)
     */
    async addLinkedRaw(requestParameters: AddLinkedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Authentication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addLinked.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/add-linked/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addLinkedRequestOptionsRaw(requestParameters: AddLinkedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addLinked.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/add-linked/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Adds a linked account to a user (Roles: none)
     */
    async addLinked(requestParameters: AddLinkedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Authentication> {
        const response = await this.addLinkedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cleans the active chats (Roles: none)
     */
    async cleanChatRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/clean-chat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    cleanChatRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/clean-chat`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Cleans the active chats (Roles: none)
     */
    async cleanChat(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cleanChatRaw(initOverrides);
    }

    /**
     * Cleans the test DB (Roles: none)
     */
    async cleanDBRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/clean-db`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    cleanDBRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/clean-db`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Cleans the test DB (Roles: none)
     */
    async cleanDB(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cleanDBRaw(initOverrides);
    }

    /**
     * Delete physician appointments (Roles: none)
     */
    async deleteAppointmentsRaw(requestParameters: DeleteAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling deleteAppointments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/clear-appointments/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteAppointmentsRequestOptionsRaw(requestParameters: DeleteAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling deleteAppointments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/clear-appointments/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Delete physician appointments (Roles: none)
     */
    async deleteAppointments(requestParameters: DeleteAppointmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAppointmentsRaw(requestParameters, initOverrides);
    }

    /**
     * To test if x-environment works (Roles: none)
     */
    async dudRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/dud`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    dudRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/dud`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * To test if x-environment works (Roles: none)
     */
    async dud(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dudRaw(initOverrides);
    }

    /**
     * Used to test email events (Roles: none)
     */
    async getEmailEventsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TestEmailEvent>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/email-events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TestEmailEventFromJSON));
    }

    /**
        Request Config Getter
     */
    getEmailEventsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/email-events`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Used to test email events (Roles: none)
     */
    async getEmailEvents(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TestEmailEvent>> {
        const response = await this.getEmailEventsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Used to test system notifications (Roles: none)
     */
    async getSystemNotificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TestSystemNotification>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/system-notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TestSystemNotificationFromJSON));
    }

    /**
        Request Config Getter
     */
    getSystemNotificationsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/system-notifications`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Used to test system notifications (Roles: none)
     */
    async getSystemNotifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TestSystemNotification>> {
        const response = await this.getSystemNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Removes a particular user (Roles: none)
     */
    async removeUserRaw(requestParameters: RemoveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling removeUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/clean-db/user/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removeUserRequestOptionsRaw(requestParameters: RemoveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling removeUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/clean-db/user/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a particular user (Roles: none)
     */
    async removeUser(requestParameters: RemoveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeUserRaw(requestParameters, initOverrides);
    }

    /**
     * Sets Care&\'s hours of operation (Roles: none)
     */
    async resetHoursOfOperationRaw(requestParameters: ResetHoursOfOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HoursOperation>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/reset-hours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HoursOperationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    resetHoursOfOperationRequestOptionsRaw(requestParameters: ResetHoursOfOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/reset-hours`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Sets Care&\'s hours of operation (Roles: none)
     */
    async resetHoursOfOperation(requestParameters: ResetHoursOfOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HoursOperation> {
        const response = await this.resetHoursOfOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Seeds the database with a specific admin (Roles: none)
     */
    async seedAdminWithDataRaw(requestParameters: SeedAdminWithDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Admin>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed/admin-with-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminWithAuthToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    seedAdminWithDataRequestOptionsRaw(requestParameters: SeedAdminWithDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed/admin-with-data`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AdminWithAuthToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Seeds the database with a specific admin (Roles: none)
     */
    async seedAdminWithData(requestParameters: SeedAdminWithDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Admin> {
        const response = await this.seedAdminWithDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Seeds a user with CPP data (Roles: none)
     */
    async seedCPPRaw(requestParameters: SeedCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling seedCPP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed-cpp/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CPPToJSON(requestParameters.cpp),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    seedCPPRequestOptionsRaw(requestParameters: SeedCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling seedCPP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed-cpp/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: CPPToJSON(requestParameters.cpp),
        };
        return responseConfig
    }

    /**
     * Seeds a user with CPP data (Roles: none)
     */
    async seedCPP(requestParameters: SeedCPPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.seedCPPRaw(requestParameters, initOverrides);
    }

    /**
     * Seeds clinics (Roles: none)
     */
    async seedClinicsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClinicInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed-clinics/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicInfoFromJSON));
    }

    /**
        Request Config Getter
     */
    seedClinicsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed-clinics/`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Seeds clinics (Roles: none)
     */
    async seedClinics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClinicInfo>> {
        const response = await this.seedClinicsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Seeds appointment concerns (Roles: none)
     */
    async seedConcernsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BookingFlowOption>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed-concerns/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookingFlowOptionFromJSON));
    }

    /**
        Request Config Getter
     */
    seedConcernsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed-concerns/`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Seeds appointment concerns (Roles: none)
     */
    async seedConcerns(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BookingFlowOption>> {
        const response = await this.seedConcernsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Seeds a user with a credit card (Roles: none)
     */
    async seedCreditCardRaw(requestParameters: SeedCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling seedCreditCard.');
        }

        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling seedCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed-card/{patientId}/{token}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    seedCreditCardRequestOptionsRaw(requestParameters: SeedCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling seedCreditCard.');
        }

        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling seedCreditCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed-card/{patientId}/{token}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))).replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Seeds a user with a credit card (Roles: none)
     */
    async seedCreditCard(requestParameters: SeedCreditCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.seedCreditCardRaw(requestParameters, initOverrides);
    }

    /**
     * Seeds the database with a specific patient (Roles: none)
     */
    async seedPatientWithDataRaw(requestParameters: SeedPatientWithDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Patient>> {
        const queryParameters: any = {};

        if (requestParameters.subscription !== undefined) {
            queryParameters['subscription'] = requestParameters.subscription;
        }

        if (requestParameters.creditcard !== undefined) {
            queryParameters['creditcard'] = requestParameters.creditcard;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed/patient-with-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientWithAuthToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    seedPatientWithDataRequestOptionsRaw(requestParameters: SeedPatientWithDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.subscription !== undefined) {
            queryParameters['subscription'] = requestParameters.subscription;
        }

        if (requestParameters.creditcard !== undefined) {
            queryParameters['creditcard'] = requestParameters.creditcard;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed/patient-with-data`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientWithAuthToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Seeds the database with a specific patient (Roles: none)
     */
    async seedPatientWithData(requestParameters: SeedPatientWithDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Patient> {
        const response = await this.seedPatientWithDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Seeds the database with a specific physician (Roles: none)
     */
    async seedPhysicianWithDataRaw(requestParameters: SeedPhysicianWithDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Physician>> {
        const queryParameters: any = {};

        if (requestParameters.shifts !== undefined) {
            queryParameters['shifts'] = requestParameters.shifts;
        }

        if (requestParameters.signature !== undefined) {
            queryParameters['signature'] = requestParameters.signature;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed/physician-with-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhysicianWithAuthToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhysicianFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    seedPhysicianWithDataRequestOptionsRaw(requestParameters: SeedPhysicianWithDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.shifts !== undefined) {
            queryParameters['shifts'] = requestParameters.shifts;
        }

        if (requestParameters.signature !== undefined) {
            queryParameters['signature'] = requestParameters.signature;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed/physician-with-data`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PhysicianWithAuthToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Seeds the database with a specific physician (Roles: none)
     */
    async seedPhysicianWithData(requestParameters: SeedPhysicianWithDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Physician> {
        const response = await this.seedPhysicianWithDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Seeds shifts for a physician (Roles: none)
     */
    async seedShiftsRaw(requestParameters: SeedShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling seedShifts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed-shifts/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    seedShiftsRequestOptionsRaw(requestParameters: SeedShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.physicianId === null || requestParameters.physicianId === undefined) {
            throw new runtime.RequiredError('physicianId','Required parameter requestParameters.physicianId was null or undefined when calling seedShifts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed-shifts/{physicianId}`.replace(`{${"physicianId"}}`, encodeURIComponent(String(requestParameters.physicianId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Seeds shifts for a physician (Roles: none)
     */
    async seedShifts(requestParameters: SeedShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.seedShiftsRaw(requestParameters, initOverrides);
    }

    /**
     * Seeds a user with a subscription (Roles: none)
     */
    async seedSubscriptionRaw(requestParameters: SeedSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling seedSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed-subscription/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    seedSubscriptionRequestOptionsRaw(requestParameters: SeedSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling seedSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed-subscription/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Seeds a user with a subscription (Roles: none)
     */
    async seedSubscription(requestParameters: SeedSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.seedSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Seeds the database with a user (Roles: none)
     */
    async seedUserRaw(requestParameters: SeedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Authentication>> {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling seedUser.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling seedUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.healthrecords !== undefined) {
            queryParameters['healthrecords'] = requestParameters.healthrecords;
        }

        if (requestParameters.shifts !== undefined) {
            queryParameters['shifts'] = requestParameters.shifts;
        }

        if (requestParameters.creditcard !== undefined) {
            queryParameters['creditcard'] = requestParameters.creditcard;
        }

        if (requestParameters.subscription !== undefined) {
            queryParameters['subscription'] = requestParameters.subscription;
        }

        if (requestParameters.clinic_id !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinic_id;
        }

        if (requestParameters.has_selected_primary !== undefined) {
            queryParameters['has_selected_primary'] = requestParameters.has_selected_primary;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/seed/{role}/{username}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    seedUserRequestOptionsRaw(requestParameters: SeedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling seedUser.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling seedUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.healthrecords !== undefined) {
            queryParameters['healthrecords'] = requestParameters.healthrecords;
        }

        if (requestParameters.shifts !== undefined) {
            queryParameters['shifts'] = requestParameters.shifts;
        }

        if (requestParameters.creditcard !== undefined) {
            queryParameters['creditcard'] = requestParameters.creditcard;
        }

        if (requestParameters.subscription !== undefined) {
            queryParameters['subscription'] = requestParameters.subscription;
        }

        if (requestParameters.clinic_id !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinic_id;
        }

        if (requestParameters.has_selected_primary !== undefined) {
            queryParameters['has_selected_primary'] = requestParameters.has_selected_primary;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/seed/{role}/{username}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))).replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Seeds the database with a user (Roles: none)
     */
    async seedUser(requestParameters: SeedUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Authentication> {
        const response = await this.seedUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used to test email templates (Roles: none)
     */
    async sendEmailRaw(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/email/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendEmailRequestOptionsRaw(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/email/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Used to test email templates (Roles: none)
     */
    async sendEmail(requestParameters: SendEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Receives a fax confirmation (Roles: none)
     */
    async sendFaxConfirmationRaw(requestParameters: SendFaxConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling sendFaxConfirmation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/send-fax-confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FaxConfirmationToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    sendFaxConfirmationRequestOptionsRaw(requestParameters: SendFaxConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling sendFaxConfirmation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/send-fax-confirmation`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: FaxConfirmationToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Receives a fax confirmation (Roles: none)
     */
    async sendFaxConfirmation(requestParameters: SendFaxConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendFaxConfirmationRaw(requestParameters, initOverrides);
    }

    /**
     * Sets the hours of operation (Roles: none)
     */
    async setHoursOfOperationRaw(requestParameters: SetHoursOfOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HoursOperation>> {
        if (requestParameters.hours === null || requestParameters.hours === undefined) {
            throw new runtime.RequiredError('hours','Required parameter requestParameters.hours was null or undefined when calling setHoursOfOperation.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/hours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HoursOperationToJSON(requestParameters.hours),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HoursOperationFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    setHoursOfOperationRequestOptionsRaw(requestParameters: SetHoursOfOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.hours === null || requestParameters.hours === undefined) {
            throw new runtime.RequiredError('hours','Required parameter requestParameters.hours was null or undefined when calling setHoursOfOperation.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/hours`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: HoursOperationToJSON(requestParameters.hours),
        };
        return responseConfig
    }

    /**
     * Sets the hours of operation (Roles: none)
     */
    async setHoursOfOperation(requestParameters: SetHoursOfOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HoursOperation> {
        const response = await this.setHoursOfOperationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Used to test clients with specific error messages (Roles: none)
     */
    async testErrorRaw(requestParameters: TestErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling testError.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/test/error/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    testErrorRequestOptionsRaw(requestParameters: TestErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling testError.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/test/error/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Used to test clients with specific error messages (Roles: none)
     */
    async testError(requestParameters: TestErrorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.testErrorRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const SeedUserRoleEnum = {
    Physician: 'physician',
    Patient: 'patient',
    Admin: 'admin',
    Phlebotomist: 'phlebotomist'
} as const;
export type SeedUserRoleEnum = typeof SeedUserRoleEnum[keyof typeof SeedUserRoleEnum];
