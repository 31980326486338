/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CallLog } from './CallLog';
import {
    CallLogFromJSON,
    CallLogFromJSONTyped,
    CallLogToJSON,
} from './CallLog';

/**
 * 
 * @export
 * @interface Call
 */
export interface Call {
    /**
     * 
     * @type {number}
     * @memberof Call
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof Call
     */
    date_end?: number;
    /**
     * 
     * @type {string}
     * @memberof Call
     */
    type?: CallTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Call
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Call
     */
    physician_id?: string;
    /**
     * 
     * @type {Array<CallLog>}
     * @memberof Call
     */
    logs?: Array<CallLog>;
}


/**
 * @export
 */
export const CallTypeEnum = {
    Phone: 'phone',
    Video: 'video'
} as const;
export type CallTypeEnum = typeof CallTypeEnum[keyof typeof CallTypeEnum];


/**
 * Check if a given object implements the Call interface.
 */
export function instanceOfCall(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CallFromJSON(json: any): Call {
    return CallFromJSONTyped(json, false);
}

export function CallFromJSONTyped(json: any, ignoreDiscriminator: boolean): Call {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'date_end': !exists(json, 'date_end') ? undefined : json['date_end'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'logs': !exists(json, 'logs') ? undefined : ((json['logs'] as Array<any>).map(CallLogFromJSON)),
    };
}

export function CallToJSON(value?: Call | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'date_end': value.date_end,
        'type': value.type,
        'patient_id': value.patient_id,
        'physician_id': value.physician_id,
        'logs': value.logs === undefined ? undefined : ((value.logs as Array<any>).map(CallLogToJSON)),
    };
}

