import {Task} from "generated/api";
import {api} from "careand-redux/api/ApiThunks";
import {createResettableSlice} from "careand-redux/utils/makeResettable";
import {unwrap, unwrapApi} from "careand-redux/utils/unwrap";
import PerformanceUtils from "services/PerformanceUtils";
import _ from 'lodash';

export interface TaskState {
  assigned: Task[],
  byId: Record<string, Task[] | undefined>
}

const initialState: TaskState = {
  assigned: [],
  byId: {
    none: []
  }
}

const slice = createResettableSlice({
  name: 'Tasks',
  initialState,
  reducers: {
    receiveUpdatedTask: unwrap(editOrAddTask)
  },
  extraReducers: builder => builder
    .addCase(api.Tasks.getTask.fulfilled, unwrapApi(editOrAddTask))
    .addCase(api.Tasks.getTasks.fulfilled, unwrapApi(editOrAddTasks))
    .addCase(api.Tasks.addTask.fulfilled, unwrapApi(editOrAddTask))
    .addCase(api.Tasks.getUnassignedTasks.fulfilled, unwrapApi(editOrAddTasks))
    .addCase(api.Tasks.getArchivedTasks.fulfilled, unwrapApi(editOrAddTasks))
    .addCase(api.Tasks.getAssignedTasks.fulfilled, unwrapApi((state, tasks) => {
      editOrAddTasks(state, tasks);
      state.assigned = tasks;
    }))
    .addCase(api.Tasks.getStaffTasks.fulfilled, unwrapApi(editOrAddTasks))
    .addCase(api.Tasks.getPatientTasks.fulfilled, unwrapApi(editOrAddTasks))
    .addCase(api.Tasks.updateTask.fulfilled, unwrapApi(editOrAddTask))
    .addCase(api.Tasks.addTaskComment.fulfilled, unwrapApi(addTaskWithAssigned))
    .addCase(api.Tasks.removeTaskComment.fulfilled, unwrapApi(addTaskWithAssigned))
    .addCase(api.Tasks.markTaskRead.fulfilled, unwrapApi(editOrAddTask))
    .addCase(api.Tasks.archiveTask.fulfilled, unwrapApi(handleNewlyArchivedTask))
})

function handleNewlyArchivedTask(state: TaskState, task: Task) {
  editOrAddTask(state, task);
  modifyAssigned(state, task);
}

function addTaskWithAssigned(state: TaskState, task: Task) {
  editOrAddTask(state, task);
  state.assigned = _.uniqBy([...state.assigned, task], 'id');
}

function editOrAddTasks(state: TaskState, tasks: Task[]) {
  tasks.forEach(task => editOrAddTask(state, task));
}

function editOrAddTask(state: TaskState, task: Task) {

  function helperInsertTask(userId: string) {
    if (!userId) return;
    state.byId[userId] = PerformanceUtils.upsert(task, state.byId[userId] ?? []);
  }

  clearTasks(state, task);

  modifyAssigned(state, task);
  modifyNone(state, task);

  helperInsertTask(task.patient_id!);
  helperInsertTask(task.assigned_user_id!);
}

function modifyNone(state: TaskState, task: Task) {
  if (task.assigned_user_id !== 'none' && PerformanceUtils.existsInArray(task, state.byId.none || []))
    PerformanceUtils.removeFromArrayInPlace(task, state.byId.none || []);
}

function modifyAssigned(state: TaskState, task: Task) {
  if (PerformanceUtils.existsInArray(task, state.assigned))
    PerformanceUtils.upsert(task, state.assigned);
}

function clearTasks(state: TaskState, task: Task) {
  Object.keys(state.byId).forEach(key => {
    PerformanceUtils.removeFromArrayInPlace(task, state.byId[key] ?? []);
  });
  PerformanceUtils.removeFromArrayInPlace(task, state.byId.none || []);
}

export const tasksActions = slice.actions;
export default slice.reducer;
