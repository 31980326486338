import React, { useEffect, useRef, useState } from 'react';
import ProgressMessage from 'components/Utils/ProgressMessage';
import Button from '@material-ui/core/Button';
import Icon from 'components/Utils/Icon';
import { useAppSelector } from 'careand-redux/store';
import DefaultConfirmationModal from 'components/Utils/DefaultConfirmationScreen';
import UnreadBadge from 'components/Common/UnreadBadge';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DefaultTextField from 'components/Utils/DefaultTextField';
import TextField from '@material-ui/core/TextField';
import DefaultSelectField from 'components/Utils/DefaultSelectField';
import DefaultAutoComplete from 'components/Utils/DefaultAutoComplete';
import DialogActions from '@material-ui/core/DialogActions';

const ICON_STYLE = { position: 'relative', placeItems: 'center' };

const DefaultButton = ({
  name,
  icon,
  fontAwesome,
  alt,
  onClick,
  style = {},
  hide = false,
  loading = false,
  disabled = false,
  className = 'white',
  confirmText,
  cancelLabel,
  title,
  confirmLabel,
  confirmationImage,
  confirmationIcon,
  fullHeight,
  onCancel,
  unreadCount,
  ...props
}) => {
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const buttonRef = useRef();

  if (loading) className += ' loading';

  useEffect(() => {
    //workaround for MUI v4 bug
    if (props.autoFocus && !hide) {
      setTimeout(() => {
        buttonRef.current.focus();
      }, 100);
    }
  }, [props.autoFocus, hide]);

  return hide ? null : (
    <>
      {confirmText ? (
        <ConfirmationDialogWrapper
          title={title || 'Confirmation Needed'}
          message={confirmText}
          text={confirmText}
          image={confirmationImage}
          icon={confirmationIcon}
          open={confirmationDialog}
          onConfirm={(event, input) => {
            onClick(input);
            setConfirmationDialog(false);
          }}
          inputFieldLabels={props.confirmationInputs}
          inputOptions={props.inputOptions}
          inputFieldLabel={props.inputFieldLabel}
          onCancel={() => {
            if (onCancel) onCancel();
            setConfirmationDialog(false);
          }}
          onClose={() => {
            setConfirmationDialog(false);
          }}
          confirmLabel={confirmLabel}
          cancelLabel={cancelLabel}
          fullHeight={fullHeight}
          classname="button-confirmation-dialog"
          {...props}
        />
      ) : null}
      <Button
        ref={props.buttonRef || buttonRef}
        variant="contained"
        key={name}
        id={(name + '-button').replace(/\s/g, '-')}
        type="submit"
        disabled={loading || disabled}
        style={{ borderRadius: '25px', ...style }}
        className={`default-button ${className}`}
        classes={{ disabled: 'button-disabled' }}
        onClick={confirmText ? () => setConfirmationDialog(true) : onClick}
        {...props}
        autoFocus={false}
      >
        {loading && <ProgressMessage />}
        {icon !== undefined ? (
          <>
            <Icon
              src={icon}
              fontAwesomeStyle={fontAwesome}
              width="15px"
              height="15px"
              style={ICON_STYLE}
              alt={alt}
              showTooltip={!!alt}
            />
            {!!name && <>&nbsp;</>}
          </>
        ) : null}
        <span>{name}</span>
        {unreadCount > 0 && (
          <div className="ml-1">
            <UnreadBadge className="ml-2" unreadCount={unreadCount} />
          </div>
        )}
      </Button>
    </>
  );
};

const ConfirmationDialogWrapper = (props) => {
  const ownRole = useAppSelector((state) => state.authentication.role);

  return ownRole === 'patient' ? (
    <DefaultConfirmationModal {...props} />
  ) : (
    <ConfirmationDialog {...props} />
  );
};

export const ConfirmationDialog = ({
  title,
  open,
  message,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  loadingState = false,
  inputFieldLabel,
  defaultInputValue = '',
  inputFieldLabels,
  inputFieldLabelValues,
  inputOptions,
  inputSelect,
  additionalButton = null,
  className,
  confirmClassName = 'blue',
  cancelClassName = 'red',
  onClose,
}) => {
  const [inputValue, setInputValue] = useState(!!inputFieldLabel ? defaultInputValue : undefined);
  const [inputValues, setInputValues] = useState(inputFieldLabelValues || {});

  let style = {};

  if (inputOptions) {
    style.height = '500px';
  }

  function onClickConfirm(event) {
    if (!!inputFieldLabels) onConfirm(event, inputValues);
    else onConfirm(event, inputValue);
  }

  function onChangeText(event) {
    setInputValue(event.target.value);
  }

  function onChangeAutoComplete(value) {
    setInputValue(value);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose || onCancel || onConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={style}
      className={className}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        {(inputFieldLabels || []).map((label) => {
          return (
            <DefaultTextField
              key={label.label}
              autoFocus={true}
              margin="dense"
              id="input"
              label={label.label}
              fullWidth
              multiline={label.multiline}
              value={inputValues[label.label]}
              onChange={(event) => {
                let value = event.target.value || '';
                setInputValues((prevState) => {
                  return {
                    ...prevState,
                    [label.label]: value,
                  };
                });
              }}
            />
          );
        })}
        {!!inputFieldLabel && (
          <>
            {!inputOptions ? (
              <TextField
                autoFocus={true}
                margin="dense"
                id="input"
                label={inputFieldLabel}
                fullWidth
                value={inputValue}
                onChange={onChangeText}
              />
            ) : (
              <>
                {inputSelect ? (
                  <DefaultSelectField
                    label={inputFieldLabel}
                    style={{ width: '100%', display: 'inline-block' }}
                    selectedMenuItemStyle={{ color: '#2693d1' }}
                    inputs={inputOptions}
                    value={inputValue}
                    onChange={(event) => {
                      onChangeAutoComplete(event.target.value);
                    }}
                  />
                ) : (
                  <DefaultAutoComplete
                    id="input"
                    style={{ width: '100%' }}
                    placeholder={inputFieldLabel}
                    input={{
                      value: inputValue,
                      onChange: onChangeAutoComplete,
                    }}
                    onUpdateInput={setInputValue}
                    dataSource={inputOptions}
                    popOut={true}
                  />
                )}
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <DefaultButton
          name={cancelLabel}
          className={cancelClassName}
          id="alert-dialog-cancel"
          onClick={onCancel}
          disabled={loadingState}
          hide={onCancel === undefined}
          style={{ width: '150px', marginRight: '20px' }}
        />
        {additionalButton}
        <DefaultButton
          autoFocus={true}
          className={confirmClassName}
          name={confirmLabel}
          id="alert-dialog-confirm"
          loading={loadingState}
          onClick={onClickConfirm}
          hide={onConfirm === undefined}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DefaultButton;
