/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Patient } from './Patient';
import {
    PatientFromJSON,
    PatientFromJSONTyped,
    PatientToJSON,
} from './Patient';
import type { PatientLoginPayload } from './PatientLoginPayload';
import {
    PatientLoginPayloadFromJSON,
    PatientLoginPayloadFromJSONTyped,
    PatientLoginPayloadToJSON,
} from './PatientLoginPayload';

/**
 * 
 * @export
 * @interface LinkedAccountLoginPayload
 */
export interface LinkedAccountLoginPayload {
    /**
     * 
     * @type {Patient}
     * @memberof LinkedAccountLoginPayload
     */
    parent?: Patient;
    /**
     * 
     * @type {PatientLoginPayload}
     * @memberof LinkedAccountLoginPayload
     */
    payload?: PatientLoginPayload;
}

/**
 * Check if a given object implements the LinkedAccountLoginPayload interface.
 */
export function instanceOfLinkedAccountLoginPayload(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LinkedAccountLoginPayloadFromJSON(json: any): LinkedAccountLoginPayload {
    return LinkedAccountLoginPayloadFromJSONTyped(json, false);
}

export function LinkedAccountLoginPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedAccountLoginPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parent': !exists(json, 'parent') ? undefined : PatientFromJSON(json['parent']),
        'payload': !exists(json, 'payload') ? undefined : PatientLoginPayloadFromJSON(json['payload']),
    };
}

export function LinkedAccountLoginPayloadToJSON(value?: LinkedAccountLoginPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parent': PatientToJSON(value.parent),
        'payload': PatientLoginPayloadToJSON(value.payload),
    };
}

