/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentTemplateLite
 */
export interface DocumentTemplateLite {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateLite
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateLite
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateLite
     */
    category_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateLite
     */
    clinic_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateLite
     */
    secondary_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateLite
     */
    is_pending?: boolean;
}

/**
 * Check if a given object implements the DocumentTemplateLite interface.
 */
export function instanceOfDocumentTemplateLite(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentTemplateLiteFromJSON(json: any): DocumentTemplateLite {
    return DocumentTemplateLiteFromJSONTyped(json, false);
}

export function DocumentTemplateLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTemplateLite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'category_id': !exists(json, 'category_id') ? undefined : json['category_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'secondary_name': !exists(json, 'secondary_name') ? undefined : json['secondary_name'],
        'is_pending': !exists(json, 'is_pending') ? undefined : json['is_pending'],
    };
}

export function DocumentTemplateLiteToJSON(value?: DocumentTemplateLite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'category_id': value.category_id,
        'clinic_id': value.clinic_id,
        'secondary_name': value.secondary_name,
        'is_pending': value.is_pending,
    };
}

