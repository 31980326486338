import { z } from 'zod'
import { createMessage, NativeMessageSchema } from './NativeMessage'

export const NotificationTokenUpdatedMessageSchema = z.object({
  type: z.literal('notificationTokenUpdated'),
  token: z.string().optional(),
  request: z.boolean().optional(),
  hasChanged: z.boolean().optional()
}).extend(NativeMessageSchema.shape)

export type NotificationTokenUpdatedMesssage = z.infer<typeof NotificationTokenUpdatedMessageSchema>

export const createNotificationTokenRequest = () => {
  return {
    ...createMessage(),
    type: 'notificationTokenUpdated',
    request: true
  } satisfies NotificationTokenUpdatedMesssage
}

export const NotificationTokenSentMessageSchema = z.object({
  type: z.literal('notificationTokenSent'),

  sent: z.boolean().optional(),
  request: z.boolean().optional()
}).extend(NativeMessageSchema.shape)

export const createNotificationTokenSentMessage = (data: { sent?: boolean, request?: boolean }) => {
  return {
    ...createMessage(),
    type: 'notificationTokenSent',
    ...data
  } satisfies z.infer<typeof NotificationTokenSentMessageSchema>
}