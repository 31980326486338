/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppointmentNote } from './AppointmentNote';
import {
    AppointmentNoteFromJSON,
    AppointmentNoteFromJSONTyped,
    AppointmentNoteToJSON,
} from './AppointmentNote';
import type { AppointmentNoteDraft } from './AppointmentNoteDraft';
import {
    AppointmentNoteDraftFromJSON,
    AppointmentNoteDraftFromJSONTyped,
    AppointmentNoteDraftToJSON,
} from './AppointmentNoteDraft';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';
import type { Prescription } from './Prescription';
import {
    PrescriptionFromJSON,
    PrescriptionFromJSONTyped,
    PrescriptionToJSON,
} from './Prescription';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
} from './Referral';

/**
 * 
 * @export
 * @interface InternPendingRecords
 */
export interface InternPendingRecords {
    /**
     * 
     * @type {Array<Prescription>}
     * @memberof InternPendingRecords
     */
    prescriptions?: Array<Prescription>;
    /**
     * 
     * @type {Array<PatientDocument>}
     * @memberof InternPendingRecords
     */
    documents?: Array<PatientDocument>;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof InternPendingRecords
     */
    referrals?: Array<Referral>;
    /**
     * 
     * @type {Array<AppointmentNoteDraft>}
     * @memberof InternPendingRecords
     */
    note_drafts?: Array<AppointmentNoteDraft>;
    /**
     * 
     * @type {Array<AppointmentNote>}
     * @memberof InternPendingRecords
     */
    notes?: Array<AppointmentNote>;
}

/**
 * Check if a given object implements the InternPendingRecords interface.
 */
export function instanceOfInternPendingRecords(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InternPendingRecordsFromJSON(json: any): InternPendingRecords {
    return InternPendingRecordsFromJSONTyped(json, false);
}

export function InternPendingRecordsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternPendingRecords {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prescriptions': !exists(json, 'prescriptions') ? undefined : ((json['prescriptions'] as Array<any>).map(PrescriptionFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(PatientDocumentFromJSON)),
        'referrals': !exists(json, 'referrals') ? undefined : ((json['referrals'] as Array<any>).map(ReferralFromJSON)),
        'note_drafts': !exists(json, 'note_drafts') ? undefined : ((json['note_drafts'] as Array<any>).map(AppointmentNoteDraftFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(AppointmentNoteFromJSON)),
    };
}

export function InternPendingRecordsToJSON(value?: InternPendingRecords | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prescriptions': value.prescriptions === undefined ? undefined : ((value.prescriptions as Array<any>).map(PrescriptionToJSON)),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(PatientDocumentToJSON)),
        'referrals': value.referrals === undefined ? undefined : ((value.referrals as Array<any>).map(ReferralToJSON)),
        'note_drafts': value.note_drafts === undefined ? undefined : ((value.note_drafts as Array<any>).map(AppointmentNoteDraftToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(AppointmentNoteToJSON)),
    };
}

