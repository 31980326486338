/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * 
 * @export
 * @interface XMPPChat
 */
export interface XMPPChat {
    /**
     * 
     * @type {string}
     * @memberof XMPPChat
     */
    room_name?: string;
    /**
     * 
     * @type {string}
     * @memberof XMPPChat
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof XMPPChat
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof XMPPChat
     */
    environment?: string;
    /**
     * 
     * @type {number}
     * @memberof XMPPChat
     */
    creation_date?: number;
    /**
     * 
     * @type {number}
     * @memberof XMPPChat
     */
    archive_on?: number;
    /**
     * 
     * @type {number}
     * @memberof XMPPChat
     */
    modification_date?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMPPChat
     */
    members?: Array<string>;
    /**
     * 
     * @type {Array<Message>}
     * @memberof XMPPChat
     */
    messages?: Array<Message>;
}

/**
 * Check if a given object implements the XMPPChat interface.
 */
export function instanceOfXMPPChat(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function XMPPChatFromJSON(json: any): XMPPChat {
    return XMPPChatFromJSONTyped(json, false);
}

export function XMPPChatFromJSONTyped(json: any, ignoreDiscriminator: boolean): XMPPChat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room_name': !exists(json, 'room_name') ? undefined : json['room_name'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'creation_date': !exists(json, 'creation_date') ? undefined : json['creation_date'],
        'archive_on': !exists(json, 'archive_on') ? undefined : json['archive_on'],
        'modification_date': !exists(json, 'modification_date') ? undefined : json['modification_date'],
        'members': !exists(json, 'members') ? undefined : json['members'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageFromJSON)),
    };
}

export function XMPPChatToJSON(value?: XMPPChat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_name': value.room_name,
        'patient_id': value.patient_id,
        'description': value.description,
        'environment': value.environment,
        'creation_date': value.creation_date,
        'archive_on': value.archive_on,
        'modification_date': value.modification_date,
        'members': value.members,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageToJSON)),
    };
}

