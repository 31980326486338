import avatar_female from "assets/img/icons/avatar-female.svg"
import avatar_male from "assets/img/icons/avatar-male.svg"
import background_profile from "assets/img/icons/background-profile.svg"
import circles_2 from "assets/img/icons/circles-2.svg"
import swipe_card_blue from "assets/img/icons/swipe-card-blue.svg"
import swipe_card_red from "assets/img/icons/swipe-card-red.svg"
import swipe_card from "assets/img/icons/swipe-card.svg"
import selected_map_marker from "assets/img/icons/selected-map-marker.svg"
import unselected_map_marker from "assets/img/icons/unselected-map-marker.svg"
import American_Express from "assets/img/icons/American Express.webp"
import Diners_Club from "assets/img/icons/Diners Club.webp"
import Discover from "assets/img/icons/Discover.webp"
import JCB from "assets/img/icons/JCB.webp"
import MasterCard from "assets/img/icons/MasterCard.webp"
import UnionPay from "assets/img/icons/UnionPay.webp"
import Visa from "assets/img/icons/Visa.webp"

export default {
  swipe_card_blue,
  swipe_card_red,
  swipe_card,
  unselected_map_marker,
  selected_map_marker,
  background_profile,
  avatar_female,
  avatar_male,
  circles_2,
  American_Express,
  Diners_Club,
  Discover,
  JCB,
  MasterCard,
  UnionPay,
  Visa,
};
