import { Admin } from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap';

export interface AdminsState {
  byId: Record<string, Admin | undefined>;
}

const initialState: AdminsState = {
  byId: {},
};

const slice = createResettableSlice({
  name: 'Admins',
  initialState,
  reducers: {
    receiveUpdatedAdmin: unwrap(addAdmin),
  },
  extraReducers: (builder) =>
    builder
      // alternatively:
      // .addCase(api.Admins.getAdmins.fulfilled, unwrapApi(forEach(addAdmin))
      .addCase(
        api.Admins.getAdmins.fulfilled,
        unwrapApi((state, admins) => {
          admins.forEach((admin) => {
            state.byId[admin.id!] = admin;
          });
        }),
      )
      .addCase(api.Admins.getAdmin.fulfilled, unwrapApi(addAdmin))
      .addCase(api.Admins.updateAdmin.fulfilled, unwrapApi(addAdmin)),
});

function addAdmin(state: AdminsState, admin: Admin) {
  state.byId[admin.id!] = admin;
}

export const adminActions = slice.actions;
export default slice.reducer;
