/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HealthDataValue
 */
export interface HealthDataValue {
    /**
     * 
     * @type {number}
     * @memberof HealthDataValue
     */
    date: number;
    /**
     * 
     * @type {number}
     * @memberof HealthDataValue
     */
    date2?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthDataValue
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof HealthDataValue
     */
    value2?: string;
}

/**
 * Check if a given object implements the HealthDataValue interface.
 */
export function instanceOfHealthDataValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function HealthDataValueFromJSON(json: any): HealthDataValue {
    return HealthDataValueFromJSONTyped(json, false);
}

export function HealthDataValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthDataValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'date2': !exists(json, 'date2') ? undefined : json['date2'],
        'value': json['value'],
        'value2': !exists(json, 'value2') ? undefined : json['value2'],
    };
}

export function HealthDataValueToJSON(value?: HealthDataValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'date2': value.date2,
        'value': value.value,
        'value2': value.value2,
    };
}

