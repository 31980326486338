/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CouponMetadata
 */
export interface CouponMetadata {
    /**
     * 
     * @type {string}
     * @memberof CouponMetadata
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponMetadata
     */
    summary?: string;
}

/**
 * Check if a given object implements the CouponMetadata interface.
 */
export function instanceOfCouponMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CouponMetadataFromJSON(json: any): CouponMetadata {
    return CouponMetadataFromJSONTyped(json, false);
}

export function CouponMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouponMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function CouponMetadataToJSON(value?: CouponMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'summary': value.summary,
    };
}

