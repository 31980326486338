import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import ProgressMessage from 'components/Utils/ProgressMessage';
import DefaultTooltip from 'components/Utils/DefaultTooltip';
import { ConfirmationDialog } from 'components/Utils/DefaultButton';
import icons from './icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = (props) => {

  const [confirmationDialog, setConfirmationDialog] = useState();

  let {
    loading = false,
    id,
    confirmText,
    src,
    activeSrc,
    active,
    fontAwesome,
    fontAwesomeStyle = 'far',
    showTooltip = false,
    tooltipPlace = 'top',
    alt,
    width,
    height,
    iconStyle = {},
    children,
  } = props;

  if (!width && !height) width = '15px';

  if (Array.isArray(src)) {
    fontAwesomeStyle = src[0];
    src = src[1];
  }

  const style = useMemo(() => {
    return getStyle(props.style, width, height);
  }, [props.style, width, height]);

  const clickable = props.onClick !== undefined;

  const className = useMemo(() => {
    return getClassName(props.className, loading, clickable, active);
  }, [props.className, loading, clickable, active]);

  const {description, iconId} = useMemo(() => {
    let description = getAlt(src || fontAwesome, alt);
    let iconId = id;
    if (description && !id) iconId = description.replace(/ /g, '_') + '-icon';
    return {description, iconId};
  }, [src, fontAwesome, alt, id]);

  const finalSrc = useMemo(() => {
    return getImage(src, activeSrc, active);
  }, [src, activeSrc, active]);

  function onClick(event) {
    if (props.onClick) props.onClick(event, props.src || props.fontAwesome);
  }

  if (!finalSrc && !fontAwesome) fontAwesome = src;

  return (
    <div className={className} style={style}>
      {!!confirmationDialog && confirmText ? (
        <ConfirmationDialog
          title="Confirmation Needed"
          message={confirmText}
          open={!!confirmationDialog}
          onConfirm={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onClick(confirmationDialog);
            setConfirmationDialog();
          }}
          onCancel={() => setConfirmationDialog()}
        />
      ) : null}
      {loading && <ProgressMessage
        show={loading}
        className="icon-loading"
        size={style.width || style.height}
      />}
      {!loading && showTooltip && (
        <DefaultTooltip
          arrow={true}
          placement={tooltipPlace}
          title={description}
          open={!showTooltip ? false : undefined}
        >
          <div style={fontAwesome ? undefined : { width: style.width, height: style.height }}>
            <IconImage
              id={iconId}
              description={description}
              finalSrc={finalSrc}
              fontAwesomeStyle={fontAwesomeStyle}
              fontAwesome={fontAwesome}
              style={style}
              iconStyle={iconStyle}
              confirmText={confirmText}
              setConfirmationDialog={setConfirmationDialog}
              onClick={onClick}
            />
            {children}
          </div>
        </DefaultTooltip>
      )}
      {!loading && !showTooltip && (
        <div style={fontAwesome ? undefined : {width: style.width, height: style.height}}>
          <IconImage
            id={iconId}
            description={description}
            finalSrc={finalSrc}
            fontAwesomeStyle={fontAwesomeStyle}
            fontAwesome={fontAwesome}
            style={style}
            iconStyle={iconStyle}
            confirmText={confirmText}
            setConfirmationDialog={setConfirmationDialog}
            onClick={onClick}
          />
          {children}
        </div>
      )}
    </div>
  );
};

const IconImage = ({
                     id,
                     style,
                     iconStyle,
                     description,
                     fontAwesomeStyle,
                     fontAwesome,
                     finalSrc,
                     confirmText,
                     setConfirmationDialog,
                     onClick
                   }) => {
  return (
    fontAwesome ? <FontAwesomeIcon
      id={id}
      style={{width: style.width, height: style.height, ...iconStyle}}
      alt={description}
      icon={[fontAwesomeStyle, fontAwesome]}
      onClick={
        confirmText
          ? (event) => {
            event.stopPropagation();
            event.preventDefault();
            setConfirmationDialog(event);
          }
          : onClick
      }
    /> : <img
      id={id}
      alt={description}
      src={finalSrc}
      style={{width: style.width, height: style.height}}
      onClick={
        confirmText
          ? (event) => {
            event.stopPropagation();
            event.preventDefault();
            setConfirmationDialog(event);
          }
          : onClick
      }
    />
  )
}

function getClassName(className, loading, clickable, active) {
  return `icon${className ? ` ${className}` : ""}${
    clickable ? " clickable" : ""
  }${active || loading ? " blue" : ""}`;
}

function getStyle(style = {}, width, height) {
  return width || height
    ? {
      ...style,
      minWidth: width,
      width,
      minHeight: height,
      height,
      lineHeight: height,
    }
    : style;
}

function getImage(src = "", activeSrc, active) {
  const srcStr = (active ? activeSrc || `${src}-blue` : src).replace(/-/g, "_");
  return icons[srcStr];
}

function getAlt(src = "", alt) {
  return (
    alt ||
    alts[src] ||
    src
      .replace(/-/g, " ")
      .replace("icon", "")
      .trim()
      .replace(/\b\w/g, (l) => l.toUpperCase())
  );
}

Icon.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  activeSrc: PropTypes.string,
  alt: PropTypes.string,
  extension: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  showTooltip: PropTypes.bool,
  tooltipPlace: PropTypes.string,
  confirmText: PropTypes.string,
};

export default Icon;

const alts = {
  'message-captions': 'Chat Shortcuts',
  'chart-line': 'Diagnostics',
  'users-medical': 'Practitioners',
  'user-doctor': 'Referrals',
  'circle-check': 'Tasks',
  prescription: 'Prescriptions',
  'vial-virus': 'Immunizations',
  'head-side-cough': 'Allergies',
  dumbbell: 'Lifestyle Factors',
  wallet: 'Billing',
  users: 'Patients',
  'calendar-clock': 'Appointments',
  house: 'Home',
  'box-archive': 'Archive',
  xmark: 'Close',
  trash: 'Remove',
  'inbox-full': 'Patient Communication',
  family: 'Linked Accounts',
  fax: 'Faxes',
  envelope: 'Emails',
  'files-medical': 'Documents',
  'house-blank': 'Home',
  'book-medical': 'Medical Issues',
  shuffle: 'Linked Accounts',
  pencil: 'Edit',
  star: 'Favorites',
  'list-ul': 'Timeline',
  'memo-pad': 'Encounter Note',
  'user-nurse-hair-long': 'Staff',
  plus: 'Add',
};
