import { configureStore, Reducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createTransform, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, PersistConfig } from 'redux-persist';
import persistReducer from 'redux-persist/lib/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import { rootReducer } from 'careand-redux/rootReducer';
import storage from 'redux-persist/lib/storage';
import { api } from './api/ApiThunks';
import { safeAreaListener } from './listeners/SafeAreaListener';
import { navigationListener } from './listeners/NavigationListener';
import { CombinedState } from 'redux';
import { NavigationState, Stack, Stacks } from './slices/ui/navigation';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import config from 'config/config.json';
import localforage from 'localforage';

type RootReducerState = typeof rootReducer extends Reducer<CombinedState<infer S>> ? S : never;

// Prevents navigation page persisted scrolls from being persisted on redux-persist
const removeNavigationScroll = createTransform((inboundState: unknown, key) => {
  if (key === 'navigation') {
    const state = _.cloneDeep(inboundState) as NavigationState;
    Object.values(Stacks).forEach((key) => {
      state.stacks[key as Stack].state.forEach((it) => {
        it.scroll = undefined;
      });
    });
    return state;
  }
  return inboundState;
});

const PERSIST_KEY = 'rootv4';

export async function purgeStorage() {
  await localforage.clear();
  await persistor.purge();
  await persistor.flush();
  console.log('Purging Storage');
}

const persistConfig: PersistConfig<RootReducerState> = {
  key: PERSIST_KEY,
  debug: false, //config.ENVIRONMENT === 'production' && import.meta.env.PROD,
  storage,
  whitelist: [
    'authentication',
    //'form',
    'patients',
    'booking',
    'registration',
    'payments',
    'device',
  ],
  transforms: [removeNavigationScroll],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const endpointsThatReturnBlob = _.compact(
  [
    ...[
      api.Documents.previewDocumentFromHtml,
      api.Documents.getDocumentFile,
      api.Documents.getDocumentThumbnail,
      api.Resource.getPhoto,
      api.HealthRecords.previewReferral,
      api.HealthRecords.previewPrescription,
      api.HealthRecords.exportImmunizations,
      api.HealthRecords.getNotesAttachment,
      api.HealthRecords.getNotesDraftAttachment,
      api.Physicians.getPhysicianSignature,
      api.Appointments.getBookingFlowOptionImage,
      api.Invoices.getInvoicePdf,
      api.Invoices.getReceiptPdf,
      api.Resource.getResource,
    ].map((it) => it.fulfilled),
  ].map((it) => it.type),
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          ...endpointsThatReturnBlob,
        ],
      },
    })
      .prepend(safeAreaListener.middleware)
      .prepend(navigationListener.middleware),
  devTools: !(config.ENVIRONMENT === 'production' && import.meta.env.PROD),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
