import { QueueElement } from "generated/api"
import { api } from "careand-redux/api/ApiThunks"
import { createResettableSlice } from "careand-redux/utils/makeResettable"
import { unwrap, unwrapApi } from 'careand-redux/utils/unwrap'
import PerformanceUtils from "services/PerformanceUtils"

export interface QueueState {
  elements: QueueElement[]
}

const initialState: QueueState = {
  elements: []
}

const slice = createResettableSlice({
  name: 'Queue',
  initialState,
  reducers: {
    receiveUpdatedQueueElement: unwrap((state, data: QueueElement) => {
      PerformanceUtils.upsert(data, state.elements)
    }),
    receiveRemovedQueueElement: unwrap((state, data: QueueElement) => {
      PerformanceUtils.removeFromArrayInPlace(data, state.elements)
    }),
  },
  extraReducers: builder => builder
    .addCase(api.Queue.getQueue.fulfilled, unwrap((state, data) => {
      state.elements = data
    }))
    .addCase(api.Queue.addQueueElement.fulfilled, unwrap((state, data) => {
      PerformanceUtils.upsert(data, state.elements)
    }))
    .addCase(api.Queue.updateQueueElement.fulfilled, unwrap((state, data) => {
      PerformanceUtils.upsert(data, state.elements)
    }))
    .addCase(api.Queue.deleteQueueElement.fulfilled, unwrapApi((state, data, req) => {
      PerformanceUtils.removeFromArrayInPlace({ id: req.id }, state.elements)
    }))
})

export const queueActions = slice.actions
export default slice.reducer
