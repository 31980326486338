/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetricType } from './MetricType';
import {
    MetricTypeFromJSON,
    MetricTypeFromJSONTyped,
    MetricTypeToJSON,
} from './MetricType';
import type { MetricValue } from './MetricValue';
import {
    MetricValueFromJSON,
    MetricValueFromJSONTyped,
    MetricValueToJSON,
} from './MetricValue';

/**
 * 
 * @export
 * @interface HealthRecordsMetricSummary
 */
export interface HealthRecordsMetricSummary {
    /**
     * 
     * @type {string}
     * @memberof HealthRecordsMetricSummary
     */
    patient_id?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthRecordsMetricSummary
     */
    origin?: string;
    /**
     * 
     * @type {MetricType}
     * @memberof HealthRecordsMetricSummary
     */
    metric?: MetricType;
    /**
     * 
     * @type {MetricValue}
     * @memberof HealthRecordsMetricSummary
     */
    last_value?: MetricValue;
    /**
     * 
     * @type {number}
     * @memberof HealthRecordsMetricSummary
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthRecordsMetricSummary
     */
    type?: string;
}

/**
 * Check if a given object implements the HealthRecordsMetricSummary interface.
 */
export function instanceOfHealthRecordsMetricSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HealthRecordsMetricSummaryFromJSON(json: any): HealthRecordsMetricSummary {
    return HealthRecordsMetricSummaryFromJSONTyped(json, false);
}

export function HealthRecordsMetricSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthRecordsMetricSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'metric': !exists(json, 'metric') ? undefined : MetricTypeFromJSON(json['metric']),
        'last_value': !exists(json, 'last_value') ? undefined : MetricValueFromJSON(json['last_value']),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function HealthRecordsMetricSummaryToJSON(value?: HealthRecordsMetricSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'patient_id': value.patient_id,
        'origin': value.origin,
        'metric': MetricTypeToJSON(value.metric),
        'last_value': MetricValueToJSON(value.last_value),
        'count': value.count,
        'type': value.type,
    };
}

