import React, { FC, ReactElement, ReactNode } from 'react';
import Icon from 'components/Utils/Icon';
import DefaultPatientModal from 'components/Utils/DefaultPatientModal';
import DefaultButton from 'components/Utils/DefaultButton';
import { AmpersandBlue } from 'components/Utils/LoadingScreen';
import bgSquares from 'assets/img/bg-squares.webp';

export interface DefaultConfirmationModalProps {
  open: boolean;
  title: string;
  loading?: boolean;
  loadingState?: boolean;
  text?: ReactNode;
  textElement?: ReactNode;
  icon?: string;
  fullHeight?: boolean;
  image?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  ampersand?: boolean;
  className?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  id?: string;
  extraButtons?: ReactNode;
}

const DefaultConfirmationModal: FC<DefaultConfirmationModalProps> = ({
  open,
  onCancel,
  onConfirm,
  title,
  icon,
  image,
  fullHeight = false,
  text,
  textElement,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  loading = false,
  loadingState = false,
  ampersand = false,
  className = 'select-confirmation-modal',
  id,
  extraButtons,
}) => {
  return (
    <DefaultPatientModal
      className={className}
      title={title}
      wrap={true}
      fullHeight={fullHeight}
      padding={false}
      id={id}
      content={
        <ConfirmationContent
          ampersand={ampersand}
          icon={icon}
          image={image}
          title={title}
          textElement={textElement}
          text={text}
          confirmLabel={confirmLabel}
          onConfirm={onConfirm}
          loading={loading || loadingState}
          onCancel={onCancel}
          cancelLabel={cancelLabel}
          extraButtons={extraButtons}
        />
      }
      isOpen={open}
      onRequestClose={onCancel}
    />
  );
};

export const ConfirmationContent: FC<Partial<DefaultConfirmationModalProps>> = ({
  ampersand,
  icon,
  image,
  title,
  textElement,
  text,
  confirmLabel,
  onConfirm,
  loading,
  onCancel,
  cancelLabel,
  extraButtons,
}) => (
  <div className="confirmation-content centered">
    {ampersand && <AmpersandBlue noRepeat={true} />}
    {icon && <Icon src={icon} width="100px" />}
    {image && <img alt={title} src={image} width="145px" />}
    <div className="desktop-only">
      <img src={bgSquares} className="bg-squares" width="223" />
    </div>
    <div className="content-text">{textElement || text}</div>
    {onConfirm && <DefaultButton name={confirmLabel} onClick={onConfirm} loading={loading} />}
    {onCancel && <DefaultButton name={cancelLabel} onClick={onCancel} className="light" />}
    {extraButtons}
  </div>
);

export default DefaultConfirmationModal;
