import React, {createContext, MutableRefObject, ReactNode, RefObject, useContext, useEffect, useRef} from "react";

/*
* IDEA:
* - <WithScrollRestoration/> provides context to its children
* - components use const ref = useScrollRestoration() to attach the ref to the component that needs the scroll to be restored
* - whenever ref's scroll is updated it's written to ScrollData.write
* - whenever the ref's component is mounted it will read ScrollData.read and apply the scroll offset
* - useNavStack() and NavigationSlice use this data to persist the scroll data
*
* TODO:
* - this idea could be abstracted to save all kinds of data into navigationStack
*/

export type ScrollData = {
  read: number,
  write: number
}

export const ScrollRestorationContext = createContext<MutableRefObject<ScrollData>>({current: {read: 0, write: 0}})

export const WithScrollRestoration = ({children}: { children?: ReactNode | ReactNode[] }) => {
  const value = useRef<ScrollData>({read: 0, write: 0})

  return (
    <ScrollRestorationContext.Provider value={value}>
      {children}
    </ScrollRestorationContext.Provider>
  )
}

/**
 * @returns ref to scrollable element
 */
export function useScrollRestoration<T extends HTMLElement>(): RefObject<T> {
  const ref = useRef<T>(null)
  const scroll = useContext(ScrollRestorationContext)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = scroll.current.read;
    }
    scroll.current.write = 0

    const update = (e: Event) => {
      const value = (e.currentTarget as HTMLElement)?.scrollTop ?? 0
      scroll.current.write = value
    }

    ref.current?.addEventListener('scroll', update)
  }, [ref.current])

  return ref
}
