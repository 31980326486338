/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PatientHandover,
  Shift,
  ShiftRequest,
  ShiftStats,
  ShiftTemplate,
  StringResource,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PatientHandoverFromJSON,
    PatientHandoverToJSON,
    ShiftFromJSON,
    ShiftToJSON,
    ShiftRequestFromJSON,
    ShiftRequestToJSON,
    ShiftStatsFromJSON,
    ShiftStatsToJSON,
    ShiftTemplateFromJSON,
    ShiftTemplateToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
} from '../models';

export interface AddClinicShiftTemplateRequest {
    template: ShiftTemplate;
}

export interface AddOrEditShiftsRequest {
    shifts: Array<Shift>;
    deleteOthers?: boolean;
}

export interface AddPatientHandoverRequest {
    handover: PatientHandover;
}

export interface AddRequestShiftsRequest {
    requestId: string;
}

export interface AddShiftRequestRequest {
    request: ShiftRequest;
}

export interface ApproveShiftRequestRequest {
    request: ShiftRequest;
}

export interface CancelShiftRequest {
    shiftId: string;
    reason: StringResource;
}

export interface DeleteShiftRequest {
    shiftId: string;
}

export interface GetClinicShiftsRequest {
    clinicId: string;
}

export interface GetDayShiftsRequest {
    clinicId: string;
    date: number;
    endDate?: number;
}

export interface GetShiftRequestsRequest {
    userId: string;
}

export interface GetShiftStatsRequest {
    userId: string;
    yearIndex?: number;
}

export interface GetUserNonWorkingShiftsRequest {
    userId: string;
    yearIndex?: number;
}

export interface RemoveClinicShiftTemplateRequest {
    id: string;
}

export interface RemovePatientHandoverRequest {
    id: string;
}

export interface RevertRequestShiftsRequest {
    requestId: string;
    shiftId?: string;
}

/**
 * 
 */
export class ShiftsApi extends runtime.BaseAPI {

    /**
     * Adds or edits a shift template (Roles: admin)
     */
    async addClinicShiftTemplateRaw(requestParameters: AddClinicShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShiftTemplate>> {
        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling addClinicShiftTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftTemplateToJSON(requestParameters.template),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftTemplateFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addClinicShiftTemplateRequestOptionsRaw(requestParameters: AddClinicShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling addClinicShiftTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/templates`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ShiftTemplateToJSON(requestParameters.template),
        };
        return responseConfig
    }

    /**
     * Adds or edits a shift template (Roles: admin)
     */
    async addClinicShiftTemplate(requestParameters: AddClinicShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShiftTemplate> {
        const response = await this.addClinicShiftTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds or edits a shift (Roles: admin, physician)
     */
    async addOrEditShiftsRaw(requestParameters: AddOrEditShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shift>>> {
        if (requestParameters.shifts === null || requestParameters.shifts === undefined) {
            throw new runtime.RequiredError('shifts','Required parameter requestParameters.shifts was null or undefined when calling addOrEditShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.deleteOthers !== undefined) {
            queryParameters['deleteOthers'] = requestParameters.deleteOthers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.shifts.map(ShiftToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftFromJSON));
    }

    /**
        Request Config Getter
     */
    addOrEditShiftsRequestOptionsRaw(requestParameters: AddOrEditShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.shifts === null || requestParameters.shifts === undefined) {
            throw new runtime.RequiredError('shifts','Required parameter requestParameters.shifts was null or undefined when calling addOrEditShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.deleteOthers !== undefined) {
            queryParameters['deleteOthers'] = requestParameters.deleteOthers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.shifts.map(ShiftToJSON),
        };
        return responseConfig
    }

    /**
     * Adds or edits a shift (Roles: admin, physician)
     */
    async addOrEditShifts(requestParameters: AddOrEditShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shift>> {
        const response = await this.addOrEditShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a patient handover (Roles: physician)
     */
    async addPatientHandoverRaw(requestParameters: AddPatientHandoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PatientHandover>> {
        if (requestParameters.handover === null || requestParameters.handover === undefined) {
            throw new runtime.RequiredError('handover','Required parameter requestParameters.handover was null or undefined when calling addPatientHandover.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/handovers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientHandoverToJSON(requestParameters.handover),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PatientHandoverFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addPatientHandoverRequestOptionsRaw(requestParameters: AddPatientHandoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.handover === null || requestParameters.handover === undefined) {
            throw new runtime.RequiredError('handover','Required parameter requestParameters.handover was null or undefined when calling addPatientHandover.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/handovers`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: PatientHandoverToJSON(requestParameters.handover),
        };
        return responseConfig
    }

    /**
     * Adds a patient handover (Roles: physician)
     */
    async addPatientHandover(requestParameters: AddPatientHandoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PatientHandover> {
        const response = await this.addPatientHandoverRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a request\'s shifts (Roles: admin, physician)
     */
    async addRequestShiftsRaw(requestParameters: AddRequestShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShiftRequest>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling addRequestShifts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/request/add-shifts/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftRequestFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addRequestShiftsRequestOptionsRaw(requestParameters: AddRequestShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling addRequestShifts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/request/add-shifts/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Adds a request\'s shifts (Roles: admin, physician)
     */
    async addRequestShifts(requestParameters: AddRequestShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShiftRequest> {
        const response = await this.addRequestShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a shift request (Roles: admin, physician)
     */
    async addShiftRequestRaw(requestParameters: AddShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShiftRequest>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling addShiftRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftRequestFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    addShiftRequestRequestOptionsRaw(requestParameters: AddShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling addShiftRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/request`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ShiftRequestToJSON(requestParameters.request),
        };
        return responseConfig
    }

    /**
     * Adds a shift request (Roles: admin, physician)
     */
    async addShiftRequest(requestParameters: AddShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShiftRequest> {
        const response = await this.addShiftRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approves a shift request (Roles: admin, physician)
     */
    async approveShiftRequestRaw(requestParameters: ApproveShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShiftRequest>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling approveShiftRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/request/approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShiftRequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftRequestFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    approveShiftRequestRequestOptionsRaw(requestParameters: ApproveShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling approveShiftRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/request/approve`,
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: ShiftRequestToJSON(requestParameters.request),
        };
        return responseConfig
    }

    /**
     * Approves a shift request (Roles: admin, physician)
     */
    async approveShiftRequest(requestParameters: ApproveShiftRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShiftRequest> {
        const response = await this.approveShiftRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a shift shifts and moves/cancels appointments (Roles: admin, physician)
     */
    async cancelShiftRaw(requestParameters: CancelShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Shift>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling cancelShift.');
        }

        if (requestParameters.reason === null || requestParameters.reason === undefined) {
            throw new runtime.RequiredError('reason','Required parameter requestParameters.reason was null or undefined when calling cancelShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/cancel/{shiftId}`.replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.reason),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    cancelShiftRequestOptionsRaw(requestParameters: CancelShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling cancelShift.');
        }

        if (requestParameters.reason === null || requestParameters.reason === undefined) {
            throw new runtime.RequiredError('reason','Required parameter requestParameters.reason was null or undefined when calling cancelShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/cancel/{shiftId}`.replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.reason),
        };
        return responseConfig
    }

    /**
     * Deletes a shift shifts and moves/cancels appointments (Roles: admin, physician)
     */
    async cancelShift(requestParameters: CancelShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Shift> {
        const response = await this.cancelShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a shift shifts (Roles: admin, physician)
     */
    async deleteShiftRaw(requestParameters: DeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling deleteShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/remove/{shiftId}`.replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    deleteShiftRequestOptionsRaw(requestParameters: DeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.shiftId === null || requestParameters.shiftId === undefined) {
            throw new runtime.RequiredError('shiftId','Required parameter requestParameters.shiftId was null or undefined when calling deleteShift.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/remove/{shiftId}`.replace(`{${"shiftId"}}`, encodeURIComponent(String(requestParameters.shiftId))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Deletes a shift shifts (Roles: admin, physician)
     */
    async deleteShift(requestParameters: DeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShiftRaw(requestParameters, initOverrides);
    }

    /**
     * Gets all the available clinic\'s shifts (Roles: admin, physician)
     */
    async getClinicShiftsRaw(requestParameters: GetClinicShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shift>>> {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getClinicShifts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/{clinicId}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftFromJSON));
    }

    /**
        Request Config Getter
     */
    getClinicShiftsRequestOptionsRaw(requestParameters: GetClinicShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getClinicShifts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/{clinicId}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all the available clinic\'s shifts (Roles: admin, physician)
     */
    async getClinicShifts(requestParameters: GetClinicShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shift>> {
        const response = await this.getClinicShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all the clinic\'s shifts for a particular day(Roles: admin, physician)
     */
    async getDayShiftsRaw(requestParameters: GetDayShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shift>>> {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getDayShifts.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDayShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/{clinicId}/{date}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftFromJSON));
    }

    /**
        Request Config Getter
     */
    getDayShiftsRequestOptionsRaw(requestParameters: GetDayShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getDayShifts.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDayShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/{clinicId}/{date}`.replace(`{${"clinicId"}}`, encodeURIComponent(String(requestParameters.clinicId))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all the clinic\'s shifts for a particular day(Roles: admin, physician)
     */
    async getDayShifts(requestParameters: GetDayShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shift>> {
        const response = await this.getDayShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets patient handovers (Roles: admin, physician)
     */
    async getPatientHandoversRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PatientHandover>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/handovers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientHandoverFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientHandoversRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/handovers`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets patient handovers (Roles: admin, physician)
     */
    async getPatientHandovers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PatientHandover>> {
        const response = await this.getPatientHandoversRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets pending shift requests (Roles: admin, physician)
     */
    async getPendingShiftRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShiftRequest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/request/pending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftRequestFromJSON));
    }

    /**
        Request Config Getter
     */
    getPendingShiftRequestsRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/request/pending`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets pending shift requests (Roles: admin, physician)
     */
    async getPendingShiftRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShiftRequest>> {
        const response = await this.getPendingShiftRequestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the user\'s shift requests (Roles: admin, physician)
     */
    async getShiftRequestsRaw(requestParameters: GetShiftRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShiftRequest>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getShiftRequests.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/request/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftRequestFromJSON));
    }

    /**
        Request Config Getter
     */
    getShiftRequestsRequestOptionsRaw(requestParameters: GetShiftRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getShiftRequests.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/request/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user\'s shift requests (Roles: admin, physician)
     */
    async getShiftRequests(requestParameters: GetShiftRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShiftRequest>> {
        const response = await this.getShiftRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the user\'s shift stats (Roles: admin, physician)
     */
    async getShiftStatsRaw(requestParameters: GetShiftStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShiftStats>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getShiftStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.yearIndex !== undefined) {
            queryParameters['yearIndex'] = requestParameters.yearIndex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/stats/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftStatsFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getShiftStatsRequestOptionsRaw(requestParameters: GetShiftStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getShiftStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.yearIndex !== undefined) {
            queryParameters['yearIndex'] = requestParameters.yearIndex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/stats/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user\'s shift stats (Roles: admin, physician)
     */
    async getShiftStats(requestParameters: GetShiftStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShiftStats> {
        const response = await this.getShiftStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all the available shifts templates (Roles: admin, physician)
     */
    async getShiftTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShiftTemplate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftTemplateFromJSON));
    }

    /**
        Request Config Getter
     */
    getShiftTemplatesRequestOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/templates`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all the available shifts templates (Roles: admin, physician)
     */
    async getShiftTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShiftTemplate>> {
        const response = await this.getShiftTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the user\'s shift stats (Roles: admin, physician)
     */
    async getUserNonWorkingShiftsRaw(requestParameters: GetUserNonWorkingShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Shift>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserNonWorkingShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.yearIndex !== undefined) {
            queryParameters['yearIndex'] = requestParameters.yearIndex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/non-working/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShiftFromJSON));
    }

    /**
        Request Config Getter
     */
    getUserNonWorkingShiftsRequestOptionsRaw(requestParameters: GetUserNonWorkingShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserNonWorkingShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.yearIndex !== undefined) {
            queryParameters['yearIndex'] = requestParameters.yearIndex;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/non-working/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets the user\'s shift stats (Roles: admin, physician)
     */
    async getUserNonWorkingShifts(requestParameters: GetUserNonWorkingShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Shift>> {
        const response = await this.getUserNonWorkingShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a shift template (Roles: admin, physician)
     */
    async removeClinicShiftTemplateRaw(requestParameters: RemoveClinicShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeClinicShiftTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removeClinicShiftTemplateRequestOptionsRaw(requestParameters: RemoveClinicShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeClinicShiftTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Remove a shift template (Roles: admin, physician)
     */
    async removeClinicShiftTemplate(requestParameters: RemoveClinicShiftTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeClinicShiftTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a patient handovers (Roles: physician)
     */
    async removePatientHandoverRaw(requestParameters: RemovePatientHandoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePatientHandover.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/handovers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
        Request Config Getter
     */
    removePatientHandoverRequestOptionsRaw(requestParameters: RemovePatientHandoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removePatientHandover.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/handovers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Removes a patient handovers (Roles: physician)
     */
    async removePatientHandover(requestParameters: RemovePatientHandoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removePatientHandoverRaw(requestParameters, initOverrides);
    }

    /**
     * Reverts a request\'s shifts (Roles: admin, physician)
     */
    async revertRequestShiftsRaw(requestParameters: RevertRequestShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShiftRequest>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling revertRequestShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.shiftId !== undefined) {
            queryParameters['shiftId'] = requestParameters.shiftId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shifts/request/revert-shifts/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShiftRequestFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    revertRequestShiftsRequestOptionsRaw(requestParameters: RevertRequestShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling revertRequestShifts.');
        }

        const queryParameters: any = {};

        if (requestParameters.shiftId !== undefined) {
            queryParameters['shiftId'] = requestParameters.shiftId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/shifts/request/revert-shifts/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Reverts a request\'s shifts (Roles: admin, physician)
     */
    async revertRequestShifts(requestParameters: RevertRequestShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShiftRequest> {
        const response = await this.revertRequestShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
