/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Appointment } from './Appointment';
import {
    AppointmentFromJSON,
    AppointmentFromJSONTyped,
    AppointmentToJSON,
} from './Appointment';
import type { AppointmentConcernModal } from './AppointmentConcernModal';
import {
    AppointmentConcernModalFromJSON,
    AppointmentConcernModalFromJSONTyped,
    AppointmentConcernModalToJSON,
} from './AppointmentConcernModal';
import type { AppointmentConcernRestriction } from './AppointmentConcernRestriction';
import {
    AppointmentConcernRestrictionFromJSON,
    AppointmentConcernRestrictionFromJSONTyped,
    AppointmentConcernRestrictionToJSON,
} from './AppointmentConcernRestriction';
import type { BookingOptionFee } from './BookingOptionFee';
import {
    BookingOptionFeeFromJSON,
    BookingOptionFeeFromJSONTyped,
    BookingOptionFeeToJSON,
} from './BookingOptionFee';

/**
 * 
 * @export
 * @interface AppointmentConcern
 */
export interface AppointmentConcern {
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentConcern
     */
    minutes: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentConcern
     */
    types?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    only_subscribers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    only_primary_physician?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    ai_prompt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    skip_reason?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    reason_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    allow_empty_reason?: boolean;
    /**
     * 
     * @type {AppointmentConcernModal}
     * @memberof AppointmentConcern
     */
    modal?: AppointmentConcernModal;
    /**
     * 
     * @type {Array<BookingOptionFee>}
     * @memberof AppointmentConcern
     */
    fees?: Array<BookingOptionFee>;
    /**
     * 
     * @type {Appointment}
     * @memberof AppointmentConcern
     */
    appointment_data?: Appointment;
    /**
     * 
     * @type {Array<AppointmentConcernRestriction>}
     * @memberof AppointmentConcern
     */
    restrictions?: Array<AppointmentConcernRestriction>;
    /**
     * 
     * @type {number}
     * @memberof AppointmentConcern
     */
    above_age_months?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentConcern
     */
    below_age_months?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    gender?: AppointmentConcernGenderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    annual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentConcern
     */
    not_first_appointment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    supertype?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentConcern
     */
    unable_to_book_message?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentConcern
     */
    skills?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentConcern
     */
    physician_types?: Array<string>;
}


/**
 * @export
 */
export const AppointmentConcernGenderEnum = {
    Male: 'Male',
    Female: 'Female'
} as const;
export type AppointmentConcernGenderEnum = typeof AppointmentConcernGenderEnum[keyof typeof AppointmentConcernGenderEnum];


/**
 * Check if a given object implements the AppointmentConcern interface.
 */
export function instanceOfAppointmentConcern(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "minutes" in value;

    return isInstance;
}

export function AppointmentConcernFromJSON(json: any): AppointmentConcern {
    return AppointmentConcernFromJSONTyped(json, false);
}

export function AppointmentConcernFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentConcern {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'text': json['text'],
        'minutes': json['minutes'],
        'types': !exists(json, 'types') ? undefined : json['types'],
        'only_subscribers': !exists(json, 'only_subscribers') ? undefined : json['only_subscribers'],
        'only_primary_physician': !exists(json, 'only_primary_physician') ? undefined : json['only_primary_physician'],
        'ai_prompt': !exists(json, 'ai_prompt') ? undefined : json['ai_prompt'],
        'skip_reason': !exists(json, 'skip_reason') ? undefined : json['skip_reason'],
        'reason_text': !exists(json, 'reason_text') ? undefined : json['reason_text'],
        'allow_empty_reason': !exists(json, 'allow_empty_reason') ? undefined : json['allow_empty_reason'],
        'modal': !exists(json, 'modal') ? undefined : AppointmentConcernModalFromJSON(json['modal']),
        'fees': !exists(json, 'fees') ? undefined : ((json['fees'] as Array<any>).map(BookingOptionFeeFromJSON)),
        'appointment_data': !exists(json, 'appointment_data') ? undefined : AppointmentFromJSON(json['appointment_data']),
        'restrictions': !exists(json, 'restrictions') ? undefined : ((json['restrictions'] as Array<any>).map(AppointmentConcernRestrictionFromJSON)),
        'above_age_months': !exists(json, 'above_age_months') ? undefined : json['above_age_months'],
        'below_age_months': !exists(json, 'below_age_months') ? undefined : json['below_age_months'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'annual': !exists(json, 'annual') ? undefined : json['annual'],
        'not_first_appointment': !exists(json, 'not_first_appointment') ? undefined : json['not_first_appointment'],
        'supertype': !exists(json, 'supertype') ? undefined : json['supertype'],
        'unable_to_book_message': !exists(json, 'unable_to_book_message') ? undefined : json['unable_to_book_message'],
        'skills': !exists(json, 'skills') ? undefined : json['skills'],
        'physician_types': !exists(json, 'physician_types') ? undefined : json['physician_types'],
    };
}

export function AppointmentConcernToJSON(value?: AppointmentConcern | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'disabled': value.disabled,
        'text': value.text,
        'minutes': value.minutes,
        'types': value.types,
        'only_subscribers': value.only_subscribers,
        'only_primary_physician': value.only_primary_physician,
        'ai_prompt': value.ai_prompt,
        'skip_reason': value.skip_reason,
        'reason_text': value.reason_text,
        'allow_empty_reason': value.allow_empty_reason,
        'modal': AppointmentConcernModalToJSON(value.modal),
        'fees': value.fees === undefined ? undefined : ((value.fees as Array<any>).map(BookingOptionFeeToJSON)),
        'appointment_data': AppointmentToJSON(value.appointment_data),
        'restrictions': value.restrictions === undefined ? undefined : ((value.restrictions as Array<any>).map(AppointmentConcernRestrictionToJSON)),
        'above_age_months': value.above_age_months,
        'below_age_months': value.below_age_months,
        'gender': value.gender,
        'annual': value.annual,
        'not_first_appointment': value.not_first_appointment,
        'supertype': value.supertype,
        'unable_to_book_message': value.unable_to_book_message,
        'skills': value.skills,
        'physician_types': value.physician_types,
    };
}

