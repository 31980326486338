/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhysicianRoster
 */
export interface PhysicianRoster {
    /**
     * 
     * @type {string}
     * @memberof PhysicianRoster
     */
    physician_id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PhysicianRoster
     */
    patients: Array<string>;
}

/**
 * Check if a given object implements the PhysicianRoster interface.
 */
export function instanceOfPhysicianRoster(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "physician_id" in value;
    isInstance = isInstance && "patients" in value;

    return isInstance;
}

export function PhysicianRosterFromJSON(json: any): PhysicianRoster {
    return PhysicianRosterFromJSONTyped(json, false);
}

export function PhysicianRosterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhysicianRoster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'physician_id': json['physician_id'],
        'patients': json['patients'],
    };
}

export function PhysicianRosterToJSON(value?: PhysicianRoster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'physician_id': value.physician_id,
        'patients': value.patients,
    };
}

