import { z } from 'zod';
import { createMessage, NativeMessageSchema } from './NativeMessage';
import { StoredUserSchema } from './StoredUsers';

// RequestOTPCodeMessage
// App sends token or username or id
// Device replies with code
export const RequestOTPCodeMessageSchema = z
  .object({
    type: z.literal('requestOTPCode'),
    token: z.string().optional(),
    username: z.string().optional(),
    userId: z.string().optional(),
    code: z.string().optional(),
  })
  .extend(NativeMessageSchema.shape);
export type RequestOTPCodeMessage = z.infer<typeof RequestOTPCodeMessageSchema>;

export const createRequestOTPCodeMessage = (data: { token?: string; username?: string }) =>
  ({
    ...createMessage(),
    type: 'requestOTPCode',
    ...data,
  }) satisfies RequestOTPCodeMessage;
