/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MTCycle } from './MTCycle';
import {
    MTCycleFromJSON,
    MTCycleFromJSONTyped,
    MTCycleToJSON,
} from './MTCycle';
import type { MTDate } from './MTDate';
import {
    MTDateFromJSON,
    MTDateFromJSONTyped,
    MTDateToJSON,
} from './MTDate';
import type { MTProfile } from './MTProfile';
import {
    MTProfileFromJSON,
    MTProfileFromJSONTyped,
    MTProfileToJSON,
} from './MTProfile';
import type { MTTutorialData } from './MTTutorialData';
import {
    MTTutorialDataFromJSON,
    MTTutorialDataFromJSONTyped,
    MTTutorialDataToJSON,
} from './MTTutorialData';

/**
 * 
 * @export
 * @interface MTUpdate
 */
export interface MTUpdate {
    /**
     * 
     * @type {Array<MTCycle>}
     * @memberof MTUpdate
     */
    cycles?: Array<MTCycle>;
    /**
     * 
     * @type {Array<MTDate>}
     * @memberof MTUpdate
     */
    dates?: Array<MTDate>;
    /**
     * 
     * @type {MTProfile}
     * @memberof MTUpdate
     */
    profile?: MTProfile;
    /**
     * 
     * @type {MTTutorialData}
     * @memberof MTUpdate
     */
    tutorial_data?: MTTutorialData;
}

/**
 * Check if a given object implements the MTUpdate interface.
 */
export function instanceOfMTUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MTUpdateFromJSON(json: any): MTUpdate {
    return MTUpdateFromJSONTyped(json, false);
}

export function MTUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MTUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cycles': !exists(json, 'cycles') ? undefined : ((json['cycles'] as Array<any>).map(MTCycleFromJSON)),
        'dates': !exists(json, 'dates') ? undefined : ((json['dates'] as Array<any>).map(MTDateFromJSON)),
        'profile': !exists(json, 'profile') ? undefined : MTProfileFromJSON(json['profile']),
        'tutorial_data': !exists(json, 'tutorial_data') ? undefined : MTTutorialDataFromJSON(json['tutorial_data']),
    };
}

export function MTUpdateToJSON(value?: MTUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cycles': value.cycles === undefined ? undefined : ((value.cycles as Array<any>).map(MTCycleToJSON)),
        'dates': value.dates === undefined ? undefined : ((value.dates as Array<any>).map(MTDateToJSON)),
        'profile': MTProfileToJSON(value.profile),
        'tutorial_data': MTTutorialDataToJSON(value.tutorial_data),
    };
}

