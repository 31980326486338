/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlogPost
 */
export interface BlogPost {
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    image_main?: string;
    /**
     * 
     * @type {string}
     * @memberof BlogPost
     */
    image_thumbnail?: string;
    /**
     * 
     * @type {number}
     * @memberof BlogPost
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof BlogPost
     */
    date_updated?: number;
}

/**
 * Check if a given object implements the BlogPost interface.
 */
export function instanceOfBlogPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function BlogPostFromJSON(json: any): BlogPost {
    return BlogPostFromJSONTyped(json, false);
}

export function BlogPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlogPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'content': json['content'],
        'image_main': !exists(json, 'image_main') ? undefined : json['image_main'],
        'image_thumbnail': !exists(json, 'image_thumbnail') ? undefined : json['image_thumbnail'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'date_updated': !exists(json, 'date_updated') ? undefined : json['date_updated'],
    };
}

export function BlogPostToJSON(value?: BlogPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'summary': value.summary,
        'content': value.content,
        'image_main': value.image_main,
        'image_thumbnail': value.image_thumbnail,
        'date': value.date,
        'date_updated': value.date_updated,
    };
}

