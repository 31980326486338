import { z } from "zod"
import { createMessage, NativeMessageSchema } from "./NativeMessage"

/**
 * This schema is used for both the request and the response
 * webapp sends request with credentials being undefined and app returns a request with the credentials set
 */
export const RequestCredentialsMessageSchema = z.object({
  type: z.literal('requestCredentials'),
  store: z.boolean().optional(),
  credentials: z.object({
    username: z.string(),
    password: z.string()
  }).optional()
}).extend(NativeMessageSchema.shape)

export type RequestCredentialsMessage = z.infer<typeof RequestCredentialsMessageSchema>

export const createRequestCredentialsMessage = () => ({
  ...createMessage(),
  type: 'requestCredentials',
} satisfies RequestCredentialsMessage)

export const createStoreCredentialsMessage = (credentials: RequestCredentialsMessage['credentials']) => ({
  ...createMessage(),
  type: 'requestCredentials',
  store: true,
  credentials
} satisfies RequestCredentialsMessage)