/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicationReminderTimezoneChange } from './MedicationReminderTimezoneChange';
import {
    MedicationReminderTimezoneChangeFromJSON,
    MedicationReminderTimezoneChangeFromJSONTyped,
    MedicationReminderTimezoneChangeToJSON,
} from './MedicationReminderTimezoneChange';

/**
 * 
 * @export
 * @interface MedicationTrackerSettings
 */
export interface MedicationTrackerSettings {
    /**
     * 
     * @type {boolean}
     * @memberof MedicationTrackerSettings
     */
    sms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationTrackerSettings
     */
    parent?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicationTrackerSettings
     */
    dismiss_medications?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationTrackerSettings
     */
    show_new_tracking_card?: boolean;
    /**
     * 
     * @type {Array<MedicationReminderTimezoneChange>}
     * @memberof MedicationTrackerSettings
     */
    timezone_changes?: Array<MedicationReminderTimezoneChange>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicationTrackerSettings
     */
    follow_linked_accounts?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MedicationTrackerSettings
     */
    dismissed_follow_tz_card?: boolean;
}

/**
 * Check if a given object implements the MedicationTrackerSettings interface.
 */
export function instanceOfMedicationTrackerSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicationTrackerSettingsFromJSON(json: any): MedicationTrackerSettings {
    return MedicationTrackerSettingsFromJSONTyped(json, false);
}

export function MedicationTrackerSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicationTrackerSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sms': !exists(json, 'sms') ? undefined : json['sms'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'dismiss_medications': !exists(json, 'dismiss_medications') ? undefined : json['dismiss_medications'],
        'show_new_tracking_card': !exists(json, 'show_new_tracking_card') ? undefined : json['show_new_tracking_card'],
        'timezone_changes': !exists(json, 'timezone_changes') ? undefined : ((json['timezone_changes'] as Array<any>).map(MedicationReminderTimezoneChangeFromJSON)),
        'follow_linked_accounts': !exists(json, 'follow_linked_accounts') ? undefined : json['follow_linked_accounts'],
        'dismissed_follow_tz_card': !exists(json, 'dismissed_follow_tz_card') ? undefined : json['dismissed_follow_tz_card'],
    };
}

export function MedicationTrackerSettingsToJSON(value?: MedicationTrackerSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sms': value.sms,
        'parent': value.parent,
        'dismiss_medications': value.dismiss_medications,
        'show_new_tracking_card': value.show_new_tracking_card,
        'timezone_changes': value.timezone_changes === undefined ? undefined : ((value.timezone_changes as Array<any>).map(MedicationReminderTimezoneChangeToJSON)),
        'follow_linked_accounts': value.follow_linked_accounts,
        'dismissed_follow_tz_card': value.dismissed_follow_tz_card,
    };
}

