import { Action, createAction, ThunkAction } from '@reduxjs/toolkit';
import { resetAction } from 'careand-redux/utils/makeResettable';
import { Device } from 'services/Native/Device';
import { navigationActions, Stacks } from 'careand-redux/slices/ui/navigation';
import { removePushToken } from 'services/updatePushToken';
import config from 'config/config.json';
import { type AppDispatch, type RootState } from 'careand-redux/store';
import { goToLoginIfNeeded } from 'thunks/patients';

const isPatient = !!(config as any).PATIENT;

type LogoutPayload =
  | {
      previousUserId?: string;
      isManuallyTriggered?: boolean;
    }
  | undefined;

const logoutAction = createAction<LogoutPayload>('logout');

const logoutThunk =
  (payload?: LogoutPayload): ThunkAction<void, unknown, unknown, Action<any>> =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (Device.isMobile()) {
      removePushToken();
      Device.storeCredentials(undefined);
      if (payload?.isManuallyTriggered) {
        Device.requestSetActiveUserId(undefined);
      }
    }
    dispatch(resetAction()); // resets all resettable slices
    dispatch(logoutAction(payload)); // dispatches logoutAction
    dispatch(navigationActions.resetState());
    if (!isPatient) {
      dispatch(goToLoginIfNeeded())
    }
    const forms = getState().form;
    const formNames = Object.keys(forms);
    dispatch(destroyReduxFormAction(formNames));
  };

logoutThunk.action = logoutAction;

/**
 * Logout thunk and action
 * can be dispatched: `dispatch(logout())`
 * or listened in a slice: `builder.addCase(logout.action, () => { ... })`
 */
export const logout: typeof logoutThunk & { action: typeof logoutAction } = logoutThunk;

function destroyReduxFormAction(forms: string[]) {
  return {
    type: '@@redux-form/DESTROY',
    meta: {
      form: forms,
    },
  };
}
