import { PayloadAction } from '@reduxjs/toolkit';
import {
  Address,
  Appointment,
  AppointmentConcern,
  AppointmentSlot,
  BookingFlowOption,
} from 'generated/api';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import moment from 'moment-timezone';

export interface BookingState {
  option?: BookingFlowOption;
  concern?: AppointmentConcern;
  appointment: Partial<Appointment>;
  mustShowWarningMessage: boolean;
  bookedAppointmentId?: string;
}

const initialState: BookingState = {
  appointment: {
    complaints: '',
    notes: '',
  },
  mustShowWarningMessage: true,
};

const slice = createResettableSlice({
  name: 'Booking',
  initialState,
  reducers: {
    bookingSuccess: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...initialState,
        appointment: {
          ...initialState.appointment,
          patient_id: state.appointment?.patient_id,
        },
        mustShowWarningMessage: state.mustShowWarningMessage,
        bookedAppointmentId: action.payload,
      };
    },
    reset: (state) => {
      return { ...initialState, mustShowWarningMessage: state.mustShowWarningMessage };
    },
    bookingProfileChanged: (
      state,
      action: PayloadAction<{ patientId: string; clinicId?: string }>,
    ) => {
      state.appointment = {
        patient_id: action.payload.patientId,
        clinic_id: action.payload.clinicId,
      };
    },
    bookingFlowOptionChanged: (state, action: PayloadAction<BookingFlowOption>) => {
      state.option = action.payload;
    },
    warningMessageWasShown: (state) => {
      state.mustShowWarningMessage = false;
    },
    bookingFlowConcernChanged: (state, action: PayloadAction<AppointmentConcern>) => {
      const concern = action.payload;
      state.concern = concern;
      state.appointment = {
        ...state.appointment,
        ...concern.appointment_data,
        concern_id: concern.id,
      };
    },
    reasonChanged: (state, action: PayloadAction<string>) => {
      state.appointment.complaints = action.payload;
      state.appointment.date = moment().unix();
    },
    homeAppointmentNotesChanged: (
      state,
      action: PayloadAction<{ address: Address; notes: string }>,
    ) => {
      state.appointment.notes = action.payload.notes;
      state.appointment.address = action.payload.address;
    },
    clinicAndPractitionerChanged: (
      state,
      action: PayloadAction<{ clinicId: string; physicianId?: string }>,
    ) => {
      state.appointment.clinic_id = action.payload.clinicId;
      state.appointment.physician_id = action.payload.physicianId;
    },
    timeSlotSelected: (
      state,
      action: PayloadAction<{ slot: AppointmentSlot; physicianId: string }>,
    ) => {
      state.appointment.date = action.payload.slot.start;
      state.appointment.physician_id = action.payload.physicianId;
    },
  },
});

export const bookingActions = slice.actions;
export default slice.reducer;
