/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MicrosoftNotificationData } from './MicrosoftNotificationData';
import {
    MicrosoftNotificationDataFromJSON,
    MicrosoftNotificationDataFromJSONTyped,
    MicrosoftNotificationDataToJSON,
} from './MicrosoftNotificationData';

/**
 * 
 * @export
 * @interface MicrosoftNotification
 */
export interface MicrosoftNotification {
    /**
     * 
     * @type {Array<MicrosoftNotificationData>}
     * @memberof MicrosoftNotification
     */
    value?: Array<MicrosoftNotificationData>;
}

/**
 * Check if a given object implements the MicrosoftNotification interface.
 */
export function instanceOfMicrosoftNotification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MicrosoftNotificationFromJSON(json: any): MicrosoftNotification {
    return MicrosoftNotificationFromJSONTyped(json, false);
}

export function MicrosoftNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MicrosoftNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : ((json['value'] as Array<any>).map(MicrosoftNotificationDataFromJSON)),
    };
}

export function MicrosoftNotificationToJSON(value?: MicrosoftNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value === undefined ? undefined : ((value.value as Array<any>).map(MicrosoftNotificationDataToJSON)),
    };
}

