/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MTEvent } from './MTEvent';
import {
    MTEventFromJSON,
    MTEventFromJSONTyped,
    MTEventToJSON,
} from './MTEvent';

/**
 * 
 * @export
 * @interface MTDate
 */
export interface MTDate {
    /**
     * 
     * @type {string}
     * @memberof MTDate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MTDate
     */
    date_string?: string;
    /**
     * 
     * @type {number}
     * @memberof MTDate
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof MTDate
     */
    patient_id?: string;
    /**
     * 
     * @type {Array<MTEvent>}
     * @memberof MTDate
     */
    events: Array<MTEvent>;
}

/**
 * Check if a given object implements the MTDate interface.
 */
export function instanceOfMTDate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function MTDateFromJSON(json: any): MTDate {
    return MTDateFromJSONTyped(json, false);
}

export function MTDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MTDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date_string': !exists(json, 'date_string') ? undefined : json['date_string'],
        'date': json['date'],
        'patient_id': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'events': ((json['events'] as Array<any>).map(MTEventFromJSON)),
    };
}

export function MTDateToJSON(value?: MTDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date_string': value.date_string,
        'date': value.date,
        'patient_id': value.patient_id,
        'events': ((value.events as Array<any>).map(MTEventToJSON)),
    };
}

