/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MicrosoftNotificationData
 */
export interface MicrosoftNotificationData {
    /**
     * 
     * @type {string}
     * @memberof MicrosoftNotificationData
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftNotificationData
     */
    subscriptionExpirationDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftNotificationData
     */
    clientState?: string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftNotificationData
     */
    changeType?: string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftNotificationData
     */
    resource?: string;
    /**
     * 
     * @type {object}
     * @memberof MicrosoftNotificationData
     */
    resourceData?: object;
}

/**
 * Check if a given object implements the MicrosoftNotificationData interface.
 */
export function instanceOfMicrosoftNotificationData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MicrosoftNotificationDataFromJSON(json: any): MicrosoftNotificationData {
    return MicrosoftNotificationDataFromJSONTyped(json, false);
}

export function MicrosoftNotificationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MicrosoftNotificationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
        'subscriptionExpirationDateTime': !exists(json, 'subscriptionExpirationDateTime') ? undefined : json['subscriptionExpirationDateTime'],
        'clientState': !exists(json, 'clientState') ? undefined : json['clientState'],
        'changeType': !exists(json, 'changeType') ? undefined : json['changeType'],
        'resource': !exists(json, 'resource') ? undefined : json['resource'],
        'resourceData': !exists(json, 'resourceData') ? undefined : json['resourceData'],
    };
}

export function MicrosoftNotificationDataToJSON(value?: MicrosoftNotificationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptionId': value.subscriptionId,
        'subscriptionExpirationDateTime': value.subscriptionExpirationDateTime,
        'clientState': value.clientState,
        'changeType': value.changeType,
        'resource': value.resource,
        'resourceData': value.resourceData,
    };
}

