/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaxProvider } from './FaxProvider';
import {
    FaxProviderFromJSON,
    FaxProviderFromJSONTyped,
    FaxProviderToJSON,
} from './FaxProvider';

/**
 * 
 * @export
 * @interface ClinicConfiguration
 */
export interface ClinicConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    diagnostics_graph_token?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    diagnostics_email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    lifelabs_contract?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    vital_monitors?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    twilio_fax?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    fax_cover?: string;
    /**
     * 
     * @type {FaxProvider}
     * @memberof ClinicConfiguration
     */
    fax_provider?: FaxProvider;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfiguration
     */
    dynacare_credentials?: string;
}

/**
 * Check if a given object implements the ClinicConfiguration interface.
 */
export function instanceOfClinicConfiguration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ClinicConfigurationFromJSON(json: any): ClinicConfiguration {
    return ClinicConfigurationFromJSONTyped(json, false);
}

export function ClinicConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'diagnostics_graph_token': !exists(json, 'diagnostics_graph_token') ? undefined : json['diagnostics_graph_token'],
        'diagnostics_email': !exists(json, 'diagnostics_email') ? undefined : json['diagnostics_email'],
        'lifelabs_contract': !exists(json, 'lifelabs_contract') ? undefined : json['lifelabs_contract'],
        'vital_monitors': !exists(json, 'vital_monitors') ? undefined : json['vital_monitors'],
        'twilio_fax': !exists(json, 'twilio_fax') ? undefined : json['twilio_fax'],
        'fax_cover': !exists(json, 'fax_cover') ? undefined : json['fax_cover'],
        'fax_provider': !exists(json, 'fax_provider') ? undefined : FaxProviderFromJSON(json['fax_provider']),
        'dynacare_credentials': !exists(json, 'dynacare_credentials') ? undefined : json['dynacare_credentials'],
    };
}

export function ClinicConfigurationToJSON(value?: ClinicConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'diagnostics_graph_token': value.diagnostics_graph_token,
        'diagnostics_email': value.diagnostics_email,
        'lifelabs_contract': value.lifelabs_contract,
        'vital_monitors': value.vital_monitors,
        'twilio_fax': value.twilio_fax,
        'fax_cover': value.fax_cover,
        'fax_provider': FaxProviderToJSON(value.fax_provider),
        'dynacare_credentials': value.dynacare_credentials,
    };
}

