import { Physician } from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrap } from 'careand-redux/utils/unwrap';

export interface PhysiciansState {
  byId: Record<string, Physician | undefined>;
}

const initialState: PhysiciansState = {
  byId: {},
};

const slice = createResettableSlice({
  name: 'Physicians',
  initialState,
  reducers: {
    receiveUpdatedPhysician: unwrap(insert),
  },
  extraReducers: (builder) =>
    builder
      .addCase(api.Physicians.getPhysicians.fulfilled, unwrap(insert))
      .addCase(api.Physicians.updatePhysician.fulfilled, unwrap(insert))
      .addCase(api.Physicians.addPhysicianSignature.fulfilled, unwrap(insert)),
});

function insert(state: PhysiciansState, payload: Physician | Physician[]) {
  if (Array.isArray(payload)) {
    payload.forEach((it) => {
      state.byId[it.id] = it;
    });
  } else {
    state.byId[payload.id] = payload;
  }
}

export const practitionerActions = slice.actions;
export default slice.reducer;
