/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrescriptionMedication } from './PrescriptionMedication';
import {
    PrescriptionMedicationFromJSON,
    PrescriptionMedicationFromJSONTyped,
    PrescriptionMedicationToJSON,
} from './PrescriptionMedication';

/**
 * 
 * @export
 * @interface FavoritePrescriptionMedication
 */
export interface FavoritePrescriptionMedication {
    /**
     * 
     * @type {string}
     * @memberof FavoritePrescriptionMedication
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoritePrescriptionMedication
     */
    physician_id?: string;
    /**
     * 
     * @type {PrescriptionMedication}
     * @memberof FavoritePrescriptionMedication
     */
    value?: PrescriptionMedication;
}

/**
 * Check if a given object implements the FavoritePrescriptionMedication interface.
 */
export function instanceOfFavoritePrescriptionMedication(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FavoritePrescriptionMedicationFromJSON(json: any): FavoritePrescriptionMedication {
    return FavoritePrescriptionMedicationFromJSONTyped(json, false);
}

export function FavoritePrescriptionMedicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoritePrescriptionMedication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'physician_id': !exists(json, 'physician_id') ? undefined : json['physician_id'],
        'value': !exists(json, 'value') ? undefined : PrescriptionMedicationFromJSON(json['value']),
    };
}

export function FavoritePrescriptionMedicationToJSON(value?: FavoritePrescriptionMedication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'physician_id': value.physician_id,
        'value': PrescriptionMedicationToJSON(value.value),
    };
}

