import { PayloadAction } from '@reduxjs/toolkit';
import { ClinicPaymentPlan, CreditCard, PaymentPlan, Subscription } from 'generated/api';
import { api } from 'careand-redux/api/ApiThunks';
import { createResettableSlice } from 'careand-redux/utils/makeResettable';
import { unwrapApi } from 'careand-redux/utils/unwrap';
import PerformanceUtils from 'services/PerformanceUtils';

export interface PaymentsState {
  creditCards: Record<string, CreditCard[] | undefined>;
  subscriptions: Record<string, Subscription | undefined>;
  plans: PaymentPlan[];
  clinic_payment_plans: ClinicPaymentPlan[];
  subscriptionFlow: SubscriptionFlow;
}

export interface SubscriptionFlow {
  plan?: PaymentPlan;
  signature?: string;
}

const initialState: PaymentsState = {
  creditCards: {},
  subscriptions: {},
  plans: [],
  clinic_payment_plans: [],
  subscriptionFlow: {},
};

const slice = createResettableSlice({
  name: 'Payments',
  initialState,
  reducers: {
    saveSubscriptionPlan: (state, action: PayloadAction<PaymentPlan>) => {
      state.subscriptionFlow.plan = action.payload;
      state.subscriptionFlow.signature = undefined;
    },
    saveSubscriptionSignature: (state, action: PayloadAction<string>) => {
      state.subscriptionFlow.signature = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(api.Payments.getAllPaymentPlans.fulfilled, unwrapApi(setClinicPaymentPlans))
      .addCase(api.Payments.editPaymentPlans.fulfilled, unwrapApi(setClinicPaymentPlans))
      .addCase(api.Payments.addDefaultPermission.fulfilled, unwrapApi(setClinicPaymentPlans))
      .addCase(api.Payments.removeDefaultPermission.fulfilled, unwrapApi(setClinicPaymentPlans))
      .addCase(api.Payments.applyPromoCode.fulfilled, unwrapApi(setPaymentPlans))
      .addCase(api.Payments.getPaymentPlans.fulfilled, unwrapApi(setPaymentPlans))
      .addCase(
        api.Payments.getUserSubscription.fulfilled,
        unwrapApi((state, subscription, req) => {
          addSubscription(state, req.userId, subscription);
        }),
      )
      .addCase(
        api.Payments.createSubscription.fulfilled,
        unwrapApi((state, { subscription }, req) => {
          addSubscription(state, req.patientId, subscription);
        }),
      )
      .addCase(
        api.Payments.unsubscribe.fulfilled,
        unwrapApi((state, subscription, req) => {
          addSubscription(state, req.userId, subscription);
        }),
      )
      .addCase(
        api.Payments.unsubscribePatient.fulfilled,
        unwrapApi((state, subscription, req) => {
          addSubscription(state, req.userId, subscription);
        }),
      )
      .addCase(
        api.Payments.retryPayment.fulfilled,
        unwrapApi((state, subscription, req) => {
          addSubscription(state, req.patientId, subscription);
        }),
      )
      .addCase(
        api.Payments.getCreditCards.fulfilled,
        unwrapApi((state, creditCards, req) => {
          setUserCreditCards(state, req.patientId, creditCards);
        }),
      )
      .addCase(
        api.Payments.deleteCreditCard.fulfilled,
        unwrapApi((state, _, { cardId, patientId }) => {
          const userCards = PerformanceUtils.removeFromArray(
            { id: cardId },
            state.creditCards[patientId],
          );
          setUserCreditCards(state, patientId, userCards);
        }),
      )
      .addCase(
        api.Payments.makeCreditCardDefault.fulfilled,
        unwrapApi((state, cards, req) => {
          setUserCreditCards(state, req.patientId, cards);
        }),
      )
      .addCase(
        api.Payments.addCreditCard.fulfilled,
        unwrapApi((state, cards, req) => {
          setUserCreditCards(state, req.patientId, cards);
        }),
      )
      .addCase(
        api.Authentication.patientLogin.fulfilled,
        unwrapApi((state, patientLoginPayload) => {
          if (patientLoginPayload.user?.id && patientLoginPayload.subscription) {
            addSubscription(state, patientLoginPayload.user.id, patientLoginPayload.subscription);
          }
        }),
      ),
});

function setPaymentPlans(state: PaymentsState, plans: PaymentPlan[]) {
  state.plans = plans;
}

function setClinicPaymentPlans(state: PaymentsState, plans: ClinicPaymentPlan[]) {
  state.clinic_payment_plans = plans;
}

function addSubscription(state: PaymentsState, patientId: string, subscription: Subscription) {
  state.subscriptions[patientId] = subscription;
}

function setUserCreditCards(state: PaymentsState, patientId: string, cards: CreditCard[]) {
  state.creditCards[patientId] = cards;
}

export const paymentActions = slice.actions;
export default slice.reducer;
