/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MinistryOHIP } from './MinistryOHIP';
import {
    MinistryOHIPFromJSON,
    MinistryOHIPFromJSONTyped,
    MinistryOHIPToJSON,
} from './MinistryOHIP';

/**
 * 
 * @export
 * @interface PatientRegisterPayload
 */
export interface PatientRegisterPayload {
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    verification_code: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    email: string;
    /**
     * 
     * @type {MinistryOHIP}
     * @memberof PatientRegisterPayload
     */
    ministry_ohip: MinistryOHIP;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    preferred_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    preferred_pronoun?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    track_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRegisterPayload
     */
    clinic_id?: string;
}

/**
 * Check if a given object implements the PatientRegisterPayload interface.
 */
export function instanceOfPatientRegisterPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "verification_code" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "ministry_ohip" in value;

    return isInstance;
}

export function PatientRegisterPayloadFromJSON(json: any): PatientRegisterPayload {
    return PatientRegisterPayloadFromJSONTyped(json, false);
}

export function PatientRegisterPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientRegisterPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': json['phone'],
        'verification_code': json['verification_code'],
        'username': json['username'],
        'password': json['password'],
        'email': json['email'],
        'ministry_ohip': MinistryOHIPFromJSON(json['ministry_ohip']),
        'preferred_name': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'preferred_pronoun': !exists(json, 'preferred_pronoun') ? undefined : json['preferred_pronoun'],
        'track_id': !exists(json, 'track_id') ? undefined : json['track_id'],
        'clinic_id': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
    };
}

export function PatientRegisterPayloadToJSON(value?: PatientRegisterPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'verification_code': value.verification_code,
        'username': value.username,
        'password': value.password,
        'email': value.email,
        'ministry_ohip': MinistryOHIPToJSON(value.ministry_ohip),
        'preferred_name': value.preferred_name,
        'preferred_pronoun': value.preferred_pronoun,
        'track_id': value.track_id,
        'clinic_id': value.clinic_id,
    };
}

