/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Admin,
  ErrorResponse,
} from '../models';
import {
    AdminFromJSON,
    AdminToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GetAdminRequest {
    id: string;
}

export interface GetAdminsRequest {
    search?: string;
    clinicId?: string;
}

export interface UpdateAdminRequest {
    id: string;
    admin: Admin;
}

/**
 * 
 */
export class AdminsApi extends runtime.BaseAPI {

    /**
     * Returns the Admin with the specified id (Roles: admin, patient, physician)
     */
    async getAdminRaw(requestParameters: GetAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Admin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/admins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    getAdminRequestOptionsRaw(requestParameters: GetAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/admins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns the Admin with the specified id (Roles: admin, patient, physician)
     */
    async getAdmin(requestParameters: GetAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Admin> {
        const response = await this.getAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the Admins (Roles: admin, patient, physician)
     */
    async getAdminsRaw(requestParameters: GetAdminsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Admin>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/admins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminFromJSON));
    }

    /**
        Request Config Getter
     */
    getAdminsRequestOptionsRaw(requestParameters: GetAdminsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinicId'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/admins`,
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Returns all the Admins (Roles: admin, patient, physician)
     */
    async getAdmins(requestParameters: GetAdminsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Admin>> {
        const response = await this.getAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the Admin with the specified id (Roles: admin)
     */
    async updateAdminRaw(requestParameters: UpdateAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Admin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAdmin.');
        }

        if (requestParameters.admin === null || requestParameters.admin === undefined) {
            throw new runtime.RequiredError('admin','Required parameter requestParameters.admin was null or undefined when calling updateAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/admins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminToJSON(requestParameters.admin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    updateAdminRequestOptionsRaw(requestParameters: UpdateAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAdmin.');
        }

        if (requestParameters.admin === null || requestParameters.admin === undefined) {
            throw new runtime.RequiredError('admin','Required parameter requestParameters.admin was null or undefined when calling updateAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/admins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: AdminToJSON(requestParameters.admin),
        };
        return responseConfig
    }

    /**
     * Updates the Admin with the specified id (Roles: admin)
     */
    async updateAdmin(requestParameters: UpdateAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Admin> {
        const response = await this.updateAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
