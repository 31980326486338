/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdministeredDrug } from './AdministeredDrug';
import {
    AdministeredDrugFromJSON,
    AdministeredDrugFromJSONTyped,
    AdministeredDrugToJSON,
} from './AdministeredDrug';
import type { Appointment } from './Appointment';
import {
    AppointmentFromJSON,
    AppointmentFromJSONTyped,
    AppointmentToJSON,
} from './Appointment';
import type { AppointmentNote } from './AppointmentNote';
import {
    AppointmentNoteFromJSON,
    AppointmentNoteFromJSONTyped,
    AppointmentNoteToJSON,
} from './AppointmentNote';
import type { HealthRecords } from './HealthRecords';
import {
    HealthRecordsFromJSON,
    HealthRecordsFromJSONTyped,
    HealthRecordsToJSON,
} from './HealthRecords';
import type { HealthRecordsInformation } from './HealthRecordsInformation';
import {
    HealthRecordsInformationFromJSON,
    HealthRecordsInformationFromJSONTyped,
    HealthRecordsInformationToJSON,
} from './HealthRecordsInformation';
import type { Immunization } from './Immunization';
import {
    ImmunizationFromJSON,
    ImmunizationFromJSONTyped,
    ImmunizationToJSON,
} from './Immunization';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';
import type { Prescription } from './Prescription';
import {
    PrescriptionFromJSON,
    PrescriptionFromJSONTyped,
    PrescriptionToJSON,
} from './Prescription';
import type { PrescriptionRefill } from './PrescriptionRefill';
import {
    PrescriptionRefillFromJSON,
    PrescriptionRefillFromJSONTyped,
    PrescriptionRefillToJSON,
} from './PrescriptionRefill';
import type { Referral } from './Referral';
import {
    ReferralFromJSON,
    ReferralFromJSONTyped,
    ReferralToJSON,
} from './Referral';
import type { ReferralRequest } from './ReferralRequest';
import {
    ReferralRequestFromJSON,
    ReferralRequestFromJSONTyped,
    ReferralRequestToJSON,
} from './ReferralRequest';

/**
 * 
 * @export
 * @interface CPP
 */
export interface CPP {
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CPP
     */
    appointments?: Array<Appointment>;
    /**
     * 
     * @type {Array<AppointmentNote>}
     * @memberof CPP
     */
    notes?: Array<AppointmentNote>;
    /**
     * 
     * @type {Array<Immunization>}
     * @memberof CPP
     */
    immunizations?: Array<Immunization>;
    /**
     * 
     * @type {Array<AdministeredDrug>}
     * @memberof CPP
     */
    administered_drugs?: Array<AdministeredDrug>;
    /**
     * 
     * @type {Array<Prescription>}
     * @memberof CPP
     */
    prescriptions?: Array<Prescription>;
    /**
     * 
     * @type {Array<PatientDocument>}
     * @memberof CPP
     */
    documents?: Array<PatientDocument>;
    /**
     * 
     * @type {HealthRecords}
     * @memberof CPP
     */
    health_records?: HealthRecords;
    /**
     * 
     * @type {Array<Referral>}
     * @memberof CPP
     */
    referrals?: Array<Referral>;
    /**
     * 
     * @type {Array<PrescriptionRefill>}
     * @memberof CPP
     */
    refills?: Array<PrescriptionRefill>;
    /**
     * 
     * @type {Array<ReferralRequest>}
     * @memberof CPP
     */
    referral_requests?: Array<ReferralRequest>;
    /**
     * 
     * @type {HealthRecordsInformation}
     * @memberof CPP
     */
    information?: HealthRecordsInformation;
}

/**
 * Check if a given object implements the CPP interface.
 */
export function instanceOfCPP(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CPPFromJSON(json: any): CPP {
    return CPPFromJSONTyped(json, false);
}

export function CPPFromJSONTyped(json: any, ignoreDiscriminator: boolean): CPP {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointments': !exists(json, 'appointments') ? undefined : ((json['appointments'] as Array<any>).map(AppointmentFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(AppointmentNoteFromJSON)),
        'immunizations': !exists(json, 'immunizations') ? undefined : ((json['immunizations'] as Array<any>).map(ImmunizationFromJSON)),
        'administered_drugs': !exists(json, 'administered_drugs') ? undefined : ((json['administered_drugs'] as Array<any>).map(AdministeredDrugFromJSON)),
        'prescriptions': !exists(json, 'prescriptions') ? undefined : ((json['prescriptions'] as Array<any>).map(PrescriptionFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(PatientDocumentFromJSON)),
        'health_records': !exists(json, 'health_records') ? undefined : HealthRecordsFromJSON(json['health_records']),
        'referrals': !exists(json, 'referrals') ? undefined : ((json['referrals'] as Array<any>).map(ReferralFromJSON)),
        'refills': !exists(json, 'refills') ? undefined : ((json['refills'] as Array<any>).map(PrescriptionRefillFromJSON)),
        'referral_requests': !exists(json, 'referral_requests') ? undefined : ((json['referral_requests'] as Array<any>).map(ReferralRequestFromJSON)),
        'information': !exists(json, 'information') ? undefined : HealthRecordsInformationFromJSON(json['information']),
    };
}

export function CPPToJSON(value?: CPP | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointments': value.appointments === undefined ? undefined : ((value.appointments as Array<any>).map(AppointmentToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(AppointmentNoteToJSON)),
        'immunizations': value.immunizations === undefined ? undefined : ((value.immunizations as Array<any>).map(ImmunizationToJSON)),
        'administered_drugs': value.administered_drugs === undefined ? undefined : ((value.administered_drugs as Array<any>).map(AdministeredDrugToJSON)),
        'prescriptions': value.prescriptions === undefined ? undefined : ((value.prescriptions as Array<any>).map(PrescriptionToJSON)),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(PatientDocumentToJSON)),
        'health_records': HealthRecordsToJSON(value.health_records),
        'referrals': value.referrals === undefined ? undefined : ((value.referrals as Array<any>).map(ReferralToJSON)),
        'refills': value.refills === undefined ? undefined : ((value.refills as Array<any>).map(PrescriptionRefillToJSON)),
        'referral_requests': value.referral_requests === undefined ? undefined : ((value.referral_requests as Array<any>).map(ReferralRequestToJSON)),
        'information': HealthRecordsInformationToJSON(value.information),
    };
}

