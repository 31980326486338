/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceToken } from './DeviceToken';
import {
    DeviceTokenFromJSON,
    DeviceTokenFromJSONTyped,
    DeviceTokenToJSON,
} from './DeviceToken';

/**
 * 
 * @export
 * @interface TestSystemNotification
 */
export interface TestSystemNotification {
    /**
     * 
     * @type {string}
     * @memberof TestSystemNotification
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSystemNotification
     */
    user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSystemNotification
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof TestSystemNotification
     */
    date?: number;
    /**
     * 
     * @type {DeviceToken}
     * @memberof TestSystemNotification
     */
    token?: DeviceToken;
}

/**
 * Check if a given object implements the TestSystemNotification interface.
 */
export function instanceOfTestSystemNotification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TestSystemNotificationFromJSON(json: any): TestSystemNotification {
    return TestSystemNotificationFromJSONTyped(json, false);
}

export function TestSystemNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestSystemNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'token': !exists(json, 'token') ? undefined : DeviceTokenFromJSON(json['token']),
    };
}

export function TestSystemNotificationToJSON(value?: TestSystemNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'user_id': value.user_id,
        'message': value.message,
        'date': value.date,
        'token': DeviceTokenToJSON(value.token),
    };
}

