import { useSwipe } from 'hooks/useSwipe';
import _ from 'lodash';
import React, { ReactNode, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon';
import './style.scss';
import { getPopupSwipeHandler } from './swipeHandler';

type HeaderStyle = 'default' | 'simple';

export type DefaultPopupProps = {
  id?: string;
  title?: string;
  children?: ReactNode | ReactNode[];
  openHandler: [boolean, (value: boolean) => void];
  className?: string;
  headerStyle?: HeaderStyle;
  headerChildren?: ReactNode;
  disableSwipeToClose?: boolean;
};

const DefaultPopup = (props: DefaultPopupProps) => {
  return <InteractivePopup {...props} />;
};

const InteractivePopup = ({
  title,
  children,
  id,
  openHandler,
  className,
  headerStyle = 'default',
  headerChildren,
  disableSwipeToClose,
}: DefaultPopupProps) => {
  const [open, setOpen] = openHandler;
  const { swipeableElement, swipeHandler } = useSwipe({
    key: open,
    delta: [undefined, 30],
    activationRegion: { maxY: 70 },
    extraCondition: () => !disableSwipeToClose,
  });
  const popup = useRef<HTMLDivElement>(null);
  const bg = useRef<HTMLDivElement>(null);

  const onClickBackdrop = () => {
    setOpen(false);
  };

  useEffect(() => {
    swipeHandler.current = getPopupSwipeHandler(swipeableElement, bg, popup, setOpen);
  }, []);

  let finalClassName = 'default-popup';
  if (className) finalClassName += ` ${className}`;

  return (
    <CSSTransition
      nodeRef={popup}
      in={open ?? false}
      classNames={{
        appear: 'appear',
        appearActive: 'active',
        enter: 'enter',
        enterActive: 'active',
        enterDone: 'enter done',
        exit: 'exit',
        exitActive: 'active',
        exitDone: 'exit done',
      }}
      timeout={200}
      mountOnEnter
      unmountOnExit
      appear
    >
      <div id={id} className={finalClassName} ref={popup}>
        <div className="backdrop" ref={bg} onClick={onClickBackdrop} />
        <div className="popup-content" ref={swipeableElement}>
          <div className="header" data-header-style={headerStyle}>
            <Icon src="chevron-down" height="20px" onClick={() => setOpen(false)} />
            <span>{title}</span>
            {headerChildren}
          </div>
          <div className="default-popup-body">{children}</div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default DefaultPopup;
