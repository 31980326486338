/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DrugbankProductConceptRegions
 */
export interface DrugbankProductConceptRegions {
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProductConceptRegions
     */
    us?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProductConceptRegions
     */
    canada?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DrugbankProductConceptRegions
     */
    eu?: boolean;
}

/**
 * Check if a given object implements the DrugbankProductConceptRegions interface.
 */
export function instanceOfDrugbankProductConceptRegions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DrugbankProductConceptRegionsFromJSON(json: any): DrugbankProductConceptRegions {
    return DrugbankProductConceptRegionsFromJSONTyped(json, false);
}

export function DrugbankProductConceptRegionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrugbankProductConceptRegions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'us': !exists(json, 'us') ? undefined : json['us'],
        'canada': !exists(json, 'canada') ? undefined : json['canada'],
        'eu': !exists(json, 'eu') ? undefined : json['eu'],
    };
}

export function DrugbankProductConceptRegionsToJSON(value?: DrugbankProductConceptRegions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'us': value.us,
        'canada': value.canada,
        'eu': value.eu,
    };
}

