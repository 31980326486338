/* eslint-disable no-restricted-syntax */
import { BaseAPI } from "generated/api";
import * as apis from 'generated/api/apis';

// UTILITIES
export type RemoveSuffix<T extends string, Suffix extends string> = T extends `${infer Prefix}${Suffix}` ? Prefix : T
export type KeysByRemovingSuffix<T, Suffix extends string> = { [key in keyof T]: key extends `${infer KEY}${Suffix}` ? KEY : key }[keyof T]
export type KeysThatEndInSuffix<T, Suffix extends string> = { [key in keyof T]: key extends `${infer _}${Suffix}` ? key : never }[keyof T]
export type IsString<T> = T extends string ? T : never
export type PromiseResult<T extends Promise<any>> = T extends Promise<infer S> ? S : never
export type FunctionLike<T> = T extends (...args: any) => any ? T : never
// =====

export type AllApiKeys = KeysThatEndInSuffix<typeof apis, 'Api'>
export type AllApis = Pick<typeof apis, AllApiKeys>
export type ApiKey = AllApiKeys
export type SimpleApiKey = RemoveSuffix<ApiKey, 'Api'>

// removes TS access to anything but apis
const onlyApis: AllApis = apis

// SimplifiedApi
// same object as apis but removing all 'Api' suffix from names
export type SimplifiedApi = { [key in SimpleApiKey]: AllApis[`${key}Api`] }
const generateSimplifedApis = (): SimplifiedApi => {
  const newObj: Record<string, any> = {}
  const simpleKeys = Object.keys(onlyApis)
    .filter((it) => it.endsWith('Api'))
    .map((it) => it.slice(0, it.length - 'Api'.length))
  for (const simpleKey of simpleKeys) {
    const originalKey = `${simpleKey}Api` as AllApiKeys
    newObj[simpleKey] = onlyApis[originalKey]
  }
  return newObj as SimplifiedApi
}

export const simplifiedApi = generateSimplifedApis()

export type Api<Tag extends SimpleApiKey> = AllApis[`${Tag}Api`]
export type ApiInstance<Tag extends SimpleApiKey> = Api<Tag>['prototype']
export type ApiMethods<Tag extends SimpleApiKey> = Omit<Api<Tag>['prototype'], keyof BaseAPI>
export type ApiOperations<Tag extends SimpleApiKey> = Omit<ApiMethods<Tag>, KeysThatEndInSuffix<ApiMethods<Tag>, 'Raw'>>
export type RawApiOperations<Tag extends SimpleApiKey> = Pick<ApiMethods<Tag>, KeysThatEndInSuffix<ApiMethods<Tag>, 'Raw'>>
export type RawApiOperation<Tag extends SimpleApiKey, OpId extends OperationId<Tag>> = `${OpId}Raw` extends keyof RawApiOperations<Tag> ? RawApiOperations<Tag>[`${OpId}Raw`] : never
export type OperationId<Tag extends SimpleApiKey> = keyof ApiOperations<Tag> & string
export type OperationFunction<Tag extends SimpleApiKey, OpId extends OperationId<Tag>> = FunctionLike<ApiOperations<Tag>[OpId]>
export type RawOperationFunction<Tag extends SimpleApiKey, OpId extends OperationId<Tag>> = FunctionLike<RawApiOperation<Tag, OpId>>
export type OpFirstParam<Fn extends (...args: any) => any> = Parameters<Fn>[1] extends undefined
  ? undefined
  : NonNullable<Parameters<Fn>[0]>
export type ResponseType<Tag extends SimpleApiKey, OpId extends OperationId<Tag>> = Awaited<ReturnType<OperationFunction<Tag, OpId>>>
