/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DrugbankIngredientDrug } from './DrugbankIngredientDrug';
import {
    DrugbankIngredientDrugFromJSON,
    DrugbankIngredientDrugFromJSONTyped,
    DrugbankIngredientDrugToJSON,
} from './DrugbankIngredientDrug';
import type { DrugbankIngredientStrength } from './DrugbankIngredientStrength';
import {
    DrugbankIngredientStrengthFromJSON,
    DrugbankIngredientStrengthFromJSONTyped,
    DrugbankIngredientStrengthToJSON,
} from './DrugbankIngredientStrength';

/**
 * 
 * @export
 * @interface DrugbankIngredient
 */
export interface DrugbankIngredient {
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredient
     */
    drugbank_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DrugbankIngredient
     */
    name?: string;
    /**
     * 
     * @type {DrugbankIngredientDrug}
     * @memberof DrugbankIngredient
     */
    drug?: DrugbankIngredientDrug;
    /**
     * 
     * @type {DrugbankIngredientStrength}
     * @memberof DrugbankIngredient
     */
    strength?: DrugbankIngredientStrength;
    /**
     * 
     * @type {DrugbankIngredientDrug}
     * @memberof DrugbankIngredient
     */
    exact_ingredient?: DrugbankIngredientDrug;
}

/**
 * Check if a given object implements the DrugbankIngredient interface.
 */
export function instanceOfDrugbankIngredient(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DrugbankIngredientFromJSON(json: any): DrugbankIngredient {
    return DrugbankIngredientFromJSONTyped(json, false);
}

export function DrugbankIngredientFromJSONTyped(json: any, ignoreDiscriminator: boolean): DrugbankIngredient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'drugbank_id': !exists(json, 'drugbank_id') ? undefined : json['drugbank_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'drug': !exists(json, 'drug') ? undefined : DrugbankIngredientDrugFromJSON(json['drug']),
        'strength': !exists(json, 'strength') ? undefined : DrugbankIngredientStrengthFromJSON(json['strength']),
        'exact_ingredient': !exists(json, 'exact_ingredient') ? undefined : DrugbankIngredientDrugFromJSON(json['exact_ingredient']),
    };
}

export function DrugbankIngredientToJSON(value?: DrugbankIngredient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'drugbank_id': value.drugbank_id,
        'name': value.name,
        'drug': DrugbankIngredientDrugToJSON(value.drug),
        'strength': DrugbankIngredientStrengthToJSON(value.strength),
        'exact_ingredient': DrugbankIngredientDrugToJSON(value.exact_ingredient),
    };
}

