import React, { CSSProperties } from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import './style.scss';

const ProgressMessage: React.FC<{
  message?: string;
  topLeft?: boolean;
  topRight?: boolean;
  show?: boolean;
  size?: CircularProgressProps['size'];
  thickness?: CircularProgressProps['thickness'];
  className?: string;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
}> = ({
  message,
  size,
  topLeft,
  topRight,
  show = true,
  thickness,
  className,
  style,
  wrapperStyle,
}) =>
  ((show ?? true) && (
    <div className={getClassName(topLeft, topRight, className)} style={style}>
      <div className="wrapper" style={wrapperStyle}>
        <CircularProgress size={size || 20} thickness={thickness} className="circular-progress" />
        {message !== undefined && <div>{message}</div>}
      </div>
    </div>
  )) ||
  null;

function getClassName(topLeft?: boolean, topRight?: boolean, overrideClassName = '') {
  let className = 'progress-message';
  if (topLeft) className += ' top-left';
  else if (topRight) className += ' top-right';
  if (overrideClassName) className += ` ${overrideClassName}`;
  return className;
}

export default React.memo(ProgressMessage);
