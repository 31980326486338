import CalendarUtils from './CalendarUtils';
import PerformanceUtils from 'services/PerformanceUtils';
import moment from 'moment-timezone';
import StaffUtils from 'services/StaffUtils';
import PatientUtils from 'services/PatientUtils';
import PhysicianUtils from 'services/PhysicianUtils';
import { PhysicianPhysicianTypeEnum } from 'generated/api';

const DEFAULT_ARRAY = [];

export const APPOINTMENT_STATUS = {
  booked: 'Booked',
  scheduled: 'Scheduled',
  checked_in: 'Checked-in',
  no_show: 'No-show',
  started: 'Started',
  cancelled: 'Cancelled',
  completed: 'Completed',
};

const APPOINTMENT_TYPES = {
  video: 'Video',
  home: 'Home',
  clinic: 'Clinic',
  blocked: 'Blocked',
  lab: 'Lab',
};

const TELEMEDICINE_METHODS = {
  phone: 'Phone',
  video: 'Video',
};

class AppointmentUtils {
  getBookedById(patient, bubble, appointments) {
    //this CPP chat was opened for an appointment
    //that was booked by someone else
    if (bubble && bubble.booked_by !== undefined) return bubble.booked_by;

    let mostRecentAppointment = this.getMostRecent(appointments) || {};
    return mostRecentAppointment.booked_by;
  }

  calculateDuration(appointment, unitOfTime = 'minutes') {
    let start = moment.unix(
      appointment.date_called_in || appointment.date_checked_in || appointment.date,
    );
    let end = moment.unix(appointment.date_finished || appointment.date_end);

    if (end) {
      return end.diff(start, unitOfTime);
    }
  }

  appointmentsToEvents(appointments, patients, physicians = {}, showActualTime = false) {
    if (!appointments) return [];

    const TYPES = this.getAppointmentTypes();

    return appointments
      .map((appointment) => {
        const patient = patients[appointment.patient_id];

        let date = appointment.date;

        const { primary_physician } = patient || {};
        const primaryPhysician = physicians[primary_physician];

        if (showActualTime) date = appointment.date_called_in || appointment.date;

        let startDate,
          endDate,
          text = '';
        let patientName = PatientUtils.getName(patient, undefined, false, false);

        if (date) {
          startDate = moment.unix(date);
          text = CalendarUtils.format(date, 'h:mm A') + ' ' + text;

          let duration = this.getDuration(appointment);
          endDate = moment(startDate).clone().add(duration, 'hours');

          if (appointment.date_end) {
            let date2 = appointment.date_end;

            if (showActualTime) date2 = appointment.date_finished || appointment.date_end;

            endDate = moment.unix(date2);
            if (appointment.type === TYPES.blocked) {
              text = `${CalendarUtils.format(date, 'h:mm A')}-${CalendarUtils.format(
                appointment.date_end,
                'h:mm A',
              )}`;
            }
          }

          if (appointment.blocked_minutes_before)
            startDate.add(-appointment.blocked_minutes_before, 'minute');
          if (appointment.blocked_minutes_after)
            endDate.add(appointment.blocked_minutes_after, 'minute');

          startDate = startDate.toDate();
          endDate = endDate.toDate();
        }

        if (appointment.type === TYPES.blocked) {
          let reason = appointment.complaints || 'Time Off';
          text += `: ${reason}`;
        } else {
          text += patientName;
        }

        let title = this.getConcernComplaintsText(appointment) || text;

        if (patient) title = `Patient: ${PatientUtils.getName(patient)}\n\n${title}`;

        if (primaryPhysician && patient.is_subscribed !== false)
          title = `Primary NP: ${StaffUtils.getName(primaryPhysician)}\n${title}`;

        return {
          type: 'appointment',
          tooltipText: title,
          text,
          patientName,
          patient,
          patient_id: appointment.patient_id,
          appointment: {
            ...appointment,
            edited: undefined,
          },
          edited: appointment.edited,
          draggable: appointment.draggable !== undefined ? appointment.draggable : true,
          editable: appointment.editable !== undefined ? appointment.editable : true,
          start: startDate,
          end: endDate,
          appointment_id: appointment.id,
        };
      })
      .filter((event) => event.start !== undefined);
  }

  getAppointmentStatus() {
    return APPOINTMENT_STATUS;
  }

  getAppointmentTypes() {
    return APPOINTMENT_TYPES;
  }

  getTelemedicineMethod() {
    return TELEMEDICINE_METHODS;
  }

  getStatusHistory(appointment, physicians = {}, admins = {}, patient) {
    let lastStatus, lastType, lastConcern, lastPhysicianId, lastAppointmentDate;

    return (appointment?.status_history || [])
      .filter((item) => !!item.date)
      .map((item) => {
        let {
          by_role = '',
          by_id,
          status,
          physician_id,
          appointment_date,
          date,
          concern,
          appointment_type,
        } = item;

        let byWhom = '';

        if (by_role.toLowerCase() === 'system') {
          byWhom = 'Care& System';
        } else if (by_role !== 'patient') {
          byWhom = 'Staff';
          let staff = physicians[by_id] || admins[by_id];
          if (staff) {
            byWhom = `${StaffUtils.getName(staff)} (Staff)`;
          }
        } else {
          byWhom = 'Patient';
          if (patient) {
            byWhom = `${PatientUtils.getName(patient)} (Patient)`;
          }
        }

        const dateChanged = CalendarUtils.format(date, 'DD/MM/YY h:mm A');
        let result;

        if (!physician_id && !appointment_date) {
          result = `${byWhom} at ${dateChanged}\n${status}`;
        } else {
          const appointmentDate = CalendarUtils.format(appointment_date, 'DD/MM/YY h:mm A');
          const physician = physicians[physician_id];
          result = `${byWhom} at ${dateChanged}\n`;
          if (status !== lastStatus) result += `Status: ${status} `;
          if (concern !== lastConcern) result += `Concern: ${concern} `;
          if (appointment_type !== lastType) result += `Type: ${appointment_type} `;
          if (appointment_date !== lastAppointmentDate) result += `Date: ${appointmentDate} `;
          if (physician && physician_id !== lastPhysicianId)
            result += `NP: ${PhysicianUtils.getName(physician)}`;
          result += '\n';
        }

        lastType = appointment_type;
        lastConcern = concern;
        lastStatus = status;
        lastAppointmentDate = appointment_date;
        lastPhysicianId = physician_id;
        return result;
      })
      .join('\n');
  }

  getAvailablePhysicians(
    physicians = {},
    shifts = [],
    clinicId,
    patientId,
    concern,
    currentPhysicianId,
  ) {
    const result = [];
    const shiftUserIds = new Set(shifts.map(({ user_id }) => user_id));

    for (const id in physicians) {
      const physician = physicians[id];

      if (!StaffUtils.isActiveUser(physician)) continue;

      const {
        clinic_id,
        intern_of,
        blacklisted_patients = [],
        physician_type = PhysicianPhysicianTypeEnum.NursePractitioner,
      } = physician;

      // Check if physician is the current physician and should be added regardless of other filters
      if (id === currentPhysicianId) {
        result.push(physician);
        continue;
      }

      // Skip if physician is an intern
      if (intern_of) continue;

      // Check if physician's clinic matches or is in the shifts list
      if (clinic_id !== clinicId && !shiftUserIds.has(id)) continue;

      // Check if patient is not blacklisted
      if (blacklisted_patients.includes(patientId)) continue;

      // Check if physician's type matches the concern's required types
      if (concern?.physician_types && !concern.physician_types.includes(physician_type)) continue;

      result.push(physician);
    }

    return result;
  }

  getPatientIcon(appointment = {}) {
    if ((appointment.concern_text || '').toLowerCase().includes('covid')) return 'virus';
    else if (
      appointment.type === this.getAppointmentTypes().video &&
      appointment.telemedicine_method === this.getTelemedicineMethod().phone
    )
      return 'phone';
    else if (appointment.type === this.getAppointmentTypes().video) return 'video';
    else if (appointment.type === this.getAppointmentTypes().home) return 'house';
    else if (appointment.type === this.getAppointmentTypes().clinic) return 'location-dot';
    else if (appointment.type === this.getAppointmentTypes().lab) return 'syringe';
    return 'calendar-clock';
  }

  getIcon(appointment = {}) {
    if (appointment.fee) return 'dollar';
    if ((appointment.concern_text || '').toLowerCase().includes('covid')) return 'virus';
    else if (
      appointment.type === this.getAppointmentTypes().video &&
      appointment.telemedicine_method === this.getTelemedicineMethod().phone
    )
      return 'phone';
    else if (appointment.type === this.getAppointmentTypes().video) return 'video';
    else if (appointment.type === this.getAppointmentTypes().home) return 'truck-medical';
    else if (appointment.type === this.getAppointmentTypes().clinic) return 'hospital';
    else if (appointment.type === this.getAppointmentTypes().lab) return 'syringe';
    return 'calendar-clock';
  }

  getDuration(appointment) {
    // in hours
    if (appointment.date_end) {
      let minutes = moment.unix(appointment.date_end).diff(moment.unix(appointment.date), 'minute');
      return minutes / 60.0;
    }

    const { video, home, clinic, blocked } = this.getAppointmentTypes();

    if (appointment.type === video) return 30.0 / 60.0;
    else if (appointment.type === clinic) return 15.0 / 60.0;
    else if (appointment.type === blocked) return 30.0 / 60.0;
    else if (appointment.type === home) return 30.0 / 60.0;

    return 15.0 / 60.0;
  }

  calculateEndDate(appointment, durationInHours) {
    return moment.unix(appointment.date).add(durationInHours, 'hour').unix();
  }

  getConcernText(appointment) {
    let result = '';

    if (appointment.concern_text) result += appointment.concern_text;
    if (appointment.concern_minutes) result += ` (${appointment.concern_minutes} min)`;

    return result;
  }

  isActive(appointment) {
    let { cancelled, completed, no_show } = this.getAppointmentStatus();
    return (
      appointment.status !== cancelled &&
      appointment.status !== completed &&
      appointment.status !== no_show
    );
  }

  isScheduledClinic(appointment) {
    return appointment.type === this.getAppointmentTypes().clinic && !appointment.asap;
  }

  isBlockedSlot(appointment) {
    return appointment.type === this.getAppointmentTypes().blocked;
  }

  filterByStatus(appointments = [], status) {
    return appointments.filter((appointment) => appointment.status !== status);
  }

  getAppointmentDescription(appointment = {}, includeStatus = false) {
    let description = undefined;

    if (this.isPhoneAppointment(appointment)) description = 'Phone';
    else {
      switch (appointment.type) {
        case this.getAppointmentTypes().clinic:
          description = 'Clinic';
          break;
        case this.getAppointmentTypes().video:
          description = 'Video';
          break;
        case this.getAppointmentTypes().blocked:
          description = 'Time Off';
          break;
        default:
          description = appointment.type;
      }
    }

    if (includeStatus && !!appointment.status) description += ` (${appointment.status})`;

    return description;
  }

  isPhoneAppointment(appointment = {}) {
    return (
      appointment.type === this.getAppointmentTypes().video &&
      appointment.telemedicine_method === this.getTelemedicineMethod().phone
    );
  }

  isVideoAppointment(appointment = {}) {
    return (
      appointment.type === this.getAppointmentTypes().video &&
      appointment.telemedicine_method === this.getTelemedicineMethod().video
    );
  }

  getMissingPatients(appointments, patients) {
    let patientsToGet = {};
    let patientsToGetIds = [];

    appointments.forEach((appointment) => {
      if (appointment.patient_id && !patients[appointment.patient_id]) {
        if (!patientsToGet[appointment.patient_id]) {
          patientsToGet[appointment.patient_id] = true;
          patientsToGetIds.push(appointment.patient_id);
        }
      }
    });

    return patientsToGetIds.length === 0 ? DEFAULT_ARRAY : patientsToGetIds.slice(0, 50);
  }

  getMostRecent(appointments = []) {
    if (appointments.length === 0) return undefined;

    let mostRecent = appointments[0];

    appointments.forEach((appointment) => {
      if (mostRecent.date_booking < appointment.date_booking) mostRecent = appointment;
    });

    return mostRecent;
  }

  getUpcomingAppointments(appointments = []) {
    let filtered = appointments.filter((appointment) => this.isActive(appointment));
    return PerformanceUtils.sortBy(filtered, 'date', true);
  }

  getUpcomingAppointment(appointments = []) {
    let upcoming = this.getUpcomingAppointments(appointments);
    let now = moment().unix();
    let upcomingFuture = upcoming.filter(
      (appointment) => (appointment.date_end || appointment.date) >= now,
    );

    upcomingFuture = PerformanceUtils.sortBy(upcomingFuture, ['date'], false);

    return upcomingFuture[0];
  }

  getPastAppointments(appointments = []) {
    let filtered = appointments.filter((appointment) => !this.isActive(appointment));
    return PerformanceUtils.sortBy(filtered);
  }

  isClinicAppointment(appointment = {}) {
    return (
      appointment.type === this.getAppointmentTypes().clinic ||
      appointment.type === this.getAppointmentTypes().lab
    );
  }

  isLabAppointment(appointment = {}) {
    return appointment.type === this.getAppointmentTypes().lab;
  }

  isHomeAppointment(appointment = {}) {
    return appointment.type === this.getAppointmentTypes().home;
  }

  isTelemedicineAppointment(appointment = {}) {
    return appointment.type === this.getAppointmentTypes().video;
  }

  findAvailableSlot(slots, appointment) {
    let chosenSlot = undefined;
    appointment.date = moment.unix(appointment.date).second(0).unix();
    slots.forEach((slot) => {
      let { start, physicians } = slot;
      if (!chosenSlot && appointment.date === start && physicians.length > 0) {
        chosenSlot = slot;
      }
    });
    return chosenSlot;
  }

  responseNeedsConfirmation(status) {
    return status === 402 || status === 409;
  }

  getConcernComplaintsText(appointment = {}) {
    let text = [];

    if (appointment.concern_text) text.push(this.getConcernText(appointment));

    if (appointment.complaints) {
      let prepend =
        appointment.type === this.getAppointmentTypes().blocked ? 'Reason' : 'Complaints';
      text = [...text, '', `${prepend}:`, appointment.complaints];
    }

    if (appointment.notes) {
      text = [...text, '', 'Notes:', appointment.notes];
    }

    return text.join('\n');
  }

  isQuestionnaireOk(questionnaire) {
    if (!questionnaire) return true;

    return (
      questionnaire.questions.find(
        (question) => (question.answer || '').toLowerCase() === 'yes',
      ) === undefined
    );
  }

  getScheduleAppointments(allAppointments, physicians, date, showCancelled, clinicId) {
    let appointments = { ...(allAppointments || {}) };

    if (physicians.length > 0) {
      appointments = filterPhysiciansAppointmentEvents(appointments, physicians);
    } else {
      appointments = getAllAppointments(appointments);
    }

    appointments = appointments.filter((appointment) => {
      return CalendarUtils.isSameDay(moment.unix(appointment.date), date);
    });

    if (!showCancelled) {
      appointments = this.filterByStatus(appointments, this.getAppointmentStatus().cancelled);
      appointments = this.filterByStatus(appointments, this.getAppointmentStatus().no_show);
    }

    if (clinicId) {
      appointments = appointments.filter((appointment) => appointment.clinic_id === clinicId);
    }
    return appointments;
  }
}

function getAllAppointments(appointments) {
  let result = {};

  for (let key in appointments) {
    if (appointments[key]) {
      if (appointments[key].filter !== undefined) {
        result = {
          ...result,
          ...PerformanceUtils.arrayToObject(appointments[key]),
        };
      }
    }
  }
  return PerformanceUtils.objectToArray(result);
}

function filterPhysiciansAppointmentEvents(
  appointments,
  selectedPhysicians,
  allowUndefined = false,
) {
  let result = {};

  for (let key in appointments) {
    if (appointments[key] !== undefined) {
      if (appointments[key].filter !== undefined) {
        let filtered = appointments[key].filter((appointment) => {
          if (allowUndefined && appointment.physician_id === undefined) return true;
          let hasIntern = !!appointment.intern_of;
          let physician = selectedPhysicians.find(
            (physician) => physician.id === appointment.physician_id,
          );
          let intern = selectedPhysicians.find(
            (physician) => physician.id === appointment.intern_of,
          );

          return !!physician || (hasIntern && !!intern);
        });
        result = {
          ...result,
          ...PerformanceUtils.arrayToObject(filtered),
        };
      }
    }
  }
  return PerformanceUtils.objectToArray(result);
}

export default new AppointmentUtils();
