/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageAttributes
 */
export interface MessageAttributes {
    /**
     * 
     * @type {string}
     * @memberof MessageAttributes
     */
    author_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageAttributes
     */
    author_role?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageAttributes
     */
    media_saved?: boolean;
}

/**
 * Check if a given object implements the MessageAttributes interface.
 */
export function instanceOfMessageAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MessageAttributesFromJSON(json: any): MessageAttributes {
    return MessageAttributesFromJSONTyped(json, false);
}

export function MessageAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author_id': !exists(json, 'author_id') ? undefined : json['author_id'],
        'author_role': !exists(json, 'author_role') ? undefined : json['author_role'],
        'media_saved': !exists(json, 'media_saved') ? undefined : json['media_saved'],
    };
}

export function MessageAttributesToJSON(value?: MessageAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author_id': value.author_id,
        'author_role': value.author_role,
        'media_saved': value.media_saved,
    };
}

