import { z } from 'zod';
import { createMessage, NativeMessageSchema } from './NativeMessage';

export const SafeStoredUserSchema = z.object({
  username: z.string(),
  userId: z.string(),
  userType: z.union([z.literal('patient'), z.literal('admin'), z.literal('physician')]),
  code: z.string().optional(),
  secondsUntilNextCode: z.number().optional(),
});
export type SafeStoredUser = z.infer<typeof SafeStoredUserSchema>;

// RequestStoredUsers
export const RequestStoredUsersMessageSchema = z
  .object({
    type: z.literal('requestStoredUsers'),
    users: z.array(SafeStoredUserSchema).optional(),
  })
  .extend(NativeMessageSchema.shape);
export type RequestStoredUsersMessage = z.infer<typeof RequestStoredUsersMessageSchema>;

export const createRequestStoredUsersMessage = () =>
  ({
    ...createMessage(),
    type: 'requestStoredUsers',
  }) satisfies RequestStoredUsersMessage;

export const StoredUserSchema = z.object({
  username: z.string(),
  password: z.string(),
  userId: z.string(),
  otp: z.string().optional(),
  userType: SafeStoredUserSchema.shape.userType,
});
export type StoredUser = z.infer<typeof StoredUserSchema>;

// RequestStoreUser
export const RequestStoreUserMessageSchema = z
  .object({
    type: z.literal('requestStoreUser'),
    user: StoredUserSchema.optional(),
    overwrite: z.boolean().optional(),
    deleteUserId: z.string().optional(),
  })
  .extend(NativeMessageSchema.shape);
export type RequestStoreUserMessage = z.infer<typeof RequestStoreUserMessageSchema>;

export const createRequestStoreUserMessage = (
  user: StoredUser | undefined,
  overwrite: boolean | undefined,
) =>
  ({
    ...createMessage(),
    type: 'requestStoreUser',
    user,
    overwrite,
  }) satisfies RequestStoreUserMessage;

export const createRequestDeleteUserMessage = (userId: string) =>
  ({
    ...createMessage(),
    type: 'requestStoreUser',
    deleteUserId: userId,
  }) satisfies RequestStoreUserMessage;

// RequestStoredUserCredentials
export const RequestStoredUserCredentialsMessageSchema = z
  .object({
    type: z.literal('requestStoredUserCredentials'),
    userId: z.string().optional(),
    response: StoredUserSchema.optional(),
  })
  .extend(NativeMessageSchema.shape);
export type RequestStoredUserCredentialsMessage = z.infer<
  typeof RequestStoredUserCredentialsMessageSchema
>;

export const createRequestStoredUserCredentialsMessage = (userId: string) =>
  ({
    ...createMessage(),
    type: 'requestStoredUserCredentials',
    userId,
  }) satisfies RequestStoredUserCredentialsMessage;

// Store ActiveUserId
export const RequestActiveUserIdMessageSchema = z
  .object({
    type: z.literal('requestActiveUserId'),
    userId: z.string().optional(),
    store: z.boolean().optional(),
  })
  .extend(NativeMessageSchema.shape);
export type RequestActiveUserIdMessage = z.infer<typeof RequestActiveUserIdMessageSchema>;

export const createRequestActiveUserIdMessage = (userId: string | undefined, store: boolean) =>
  ({
    ...createMessage(),
    type: 'requestActiveUserId',
    userId,
    store,
  }) satisfies RequestActiveUserIdMessage;
