/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HealthMetric } from './HealthMetric';
import {
    HealthMetricFromJSON,
    HealthMetricFromJSONTyped,
    HealthMetricToJSON,
} from './HealthMetric';
import type { HistoryIssueValue } from './HistoryIssueValue';
import {
    HistoryIssueValueFromJSON,
    HistoryIssueValueFromJSONTyped,
    HistoryIssueValueToJSON,
} from './HistoryIssueValue';
import type { LifestyleFactorValue } from './LifestyleFactorValue';
import {
    LifestyleFactorValueFromJSON,
    LifestyleFactorValueFromJSONTyped,
    LifestyleFactorValueToJSON,
} from './LifestyleFactorValue';
import type { PatientIssueValue } from './PatientIssueValue';
import {
    PatientIssueValueFromJSON,
    PatientIssueValueFromJSONTyped,
    PatientIssueValueToJSON,
} from './PatientIssueValue';

/**
 * 
 * @export
 * @interface AIFeedbackRecords
 */
export interface AIFeedbackRecords {
    /**
     * 
     * @type {Array<HealthMetric>}
     * @memberof AIFeedbackRecords
     */
    metrics?: Array<HealthMetric>;
    /**
     * 
     * @type {Array<HistoryIssueValue>}
     * @memberof AIFeedbackRecords
     */
    history?: Array<HistoryIssueValue>;
    /**
     * 
     * @type {Array<PatientIssueValue>}
     * @memberof AIFeedbackRecords
     */
    allergies?: Array<PatientIssueValue>;
    /**
     * 
     * @type {Array<LifestyleFactorValue>}
     * @memberof AIFeedbackRecords
     */
    lifestyle_factors?: Array<LifestyleFactorValue>;
    /**
     * 
     * @type {Array<PatientIssueValue>}
     * @memberof AIFeedbackRecords
     */
    medical_issues?: Array<PatientIssueValue>;
}

/**
 * Check if a given object implements the AIFeedbackRecords interface.
 */
export function instanceOfAIFeedbackRecords(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AIFeedbackRecordsFromJSON(json: any): AIFeedbackRecords {
    return AIFeedbackRecordsFromJSONTyped(json, false);
}

export function AIFeedbackRecordsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIFeedbackRecords {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': !exists(json, 'metrics') ? undefined : ((json['metrics'] as Array<any>).map(HealthMetricFromJSON)),
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(HistoryIssueValueFromJSON)),
        'allergies': !exists(json, 'allergies') ? undefined : ((json['allergies'] as Array<any>).map(PatientIssueValueFromJSON)),
        'lifestyle_factors': !exists(json, 'lifestyle_factors') ? undefined : ((json['lifestyle_factors'] as Array<any>).map(LifestyleFactorValueFromJSON)),
        'medical_issues': !exists(json, 'medical_issues') ? undefined : ((json['medical_issues'] as Array<any>).map(PatientIssueValueFromJSON)),
    };
}

export function AIFeedbackRecordsToJSON(value?: AIFeedbackRecords | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': value.metrics === undefined ? undefined : ((value.metrics as Array<any>).map(HealthMetricToJSON)),
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(HistoryIssueValueToJSON)),
        'allergies': value.allergies === undefined ? undefined : ((value.allergies as Array<any>).map(PatientIssueValueToJSON)),
        'lifestyle_factors': value.lifestyle_factors === undefined ? undefined : ((value.lifestyle_factors as Array<any>).map(LifestyleFactorValueToJSON)),
        'medical_issues': value.medical_issues === undefined ? undefined : ((value.medical_issues as Array<any>).map(PatientIssueValueToJSON)),
    };
}

