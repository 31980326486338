/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhysicianPreferences
 */
export interface PhysicianPreferences {
    /**
     * 
     * @type {string}
     * @memberof PhysicianPreferences
     */
    template_open_mode?: PhysicianPreferencesTemplateOpenModeEnum;
}


/**
 * @export
 */
export const PhysicianPreferencesTemplateOpenModeEnum = {
    Pin: 'pin',
    Expand: 'expand'
} as const;
export type PhysicianPreferencesTemplateOpenModeEnum = typeof PhysicianPreferencesTemplateOpenModeEnum[keyof typeof PhysicianPreferencesTemplateOpenModeEnum];


/**
 * Check if a given object implements the PhysicianPreferences interface.
 */
export function instanceOfPhysicianPreferences(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PhysicianPreferencesFromJSON(json: any): PhysicianPreferences {
    return PhysicianPreferencesFromJSONTyped(json, false);
}

export function PhysicianPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhysicianPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'template_open_mode': !exists(json, 'template_open_mode') ? undefined : json['template_open_mode'],
    };
}

export function PhysicianPreferencesToJSON(value?: PhysicianPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'template_open_mode': value.template_open_mode,
    };
}

