/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Fax,
  MultipleDocumentPayload,
  StringResource,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FaxFromJSON,
    FaxToJSON,
    MultipleDocumentPayloadFromJSON,
    MultipleDocumentPayloadToJSON,
    StringResourceFromJSON,
    StringResourceToJSON,
} from '../models';

export interface ArchiveFaxRequest {
    id: string;
}

export interface FaxMultipleDocumentsRequest {
    patientId: string;
    documentId?: string;
    data?: MultipleDocumentPayload;
}

export interface GetFaxesByStatusRequest {
    status: GetFaxesByStatusStatusEnum;
}

export interface GetPatientFaxesRequest {
    patientId: string;
}

export interface ResendFaxRequest {
    id: string;
    number?: string;
    comments?: StringResource;
}

export interface SendFileFaxRequest {
    patientId: string;
    fax: string;
    title: string;
    ignoreCoverPage?: boolean;
    file?: Blob;
}

/**
 * 
 */
export class FaxApi extends runtime.BaseAPI {

    /**
     * Archives a fax (Roles: admin, physician)
     */
    async archiveFaxRaw(requestParameters: ArchiveFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fax>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveFax.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/fax/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaxFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    archiveFaxRequestOptionsRaw(requestParameters: ArchiveFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveFax.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/fax/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Archives a fax (Roles: admin, physician)
     */
    async archiveFax(requestParameters: ArchiveFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fax> {
        const response = await this.archiveFaxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends multiple files by fax (Roles: physician, admin)
     */
    async faxMultipleDocumentsRaw(requestParameters: FaxMultipleDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fax>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling faxMultipleDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/fax/patient/{patientId}/multiple`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleDocumentPayloadToJSON(requestParameters.data),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaxFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    faxMultipleDocumentsRequestOptionsRaw(requestParameters: FaxMultipleDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling faxMultipleDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/fax/patient/{patientId}/multiple`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: MultipleDocumentPayloadToJSON(requestParameters.data),
        };
        return responseConfig
    }

    /**
     * Sends multiple files by fax (Roles: physician, admin)
     */
    async faxMultipleDocuments(requestParameters: FaxMultipleDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fax> {
        const response = await this.faxMultipleDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets faxes by status (Roles: admin, physician)
     */
    async getFaxesByStatusRaw(requestParameters: GetFaxesByStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Fax>>> {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling getFaxesByStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/fax/status/{status}`.replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FaxFromJSON));
    }

    /**
        Request Config Getter
     */
    getFaxesByStatusRequestOptionsRaw(requestParameters: GetFaxesByStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling getFaxesByStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/fax/status/{status}`.replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets faxes by status (Roles: admin, physician)
     */
    async getFaxesByStatus(requestParameters: GetFaxesByStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Fax>> {
        const response = await this.getFaxesByStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets all patient faxes (Roles: admin, physician)
     */
    async getPatientFaxesRaw(requestParameters: GetPatientFaxesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Fax>>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPatientFaxes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/fax/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FaxFromJSON));
    }

    /**
        Request Config Getter
     */
    getPatientFaxesRequestOptionsRaw(requestParameters: GetPatientFaxesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling getPatientFaxes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/fax/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'GET' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
        };
        return responseConfig
    }

    /**
     * Gets all patient faxes (Roles: admin, physician)
     */
    async getPatientFaxes(requestParameters: GetPatientFaxesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Fax>> {
        const response = await this.getPatientFaxesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resends a fax (Roles: admin, physician)
     */
    async resendFaxRaw(requestParameters: ResendFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fax>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resendFax.');
        }

        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/fax/{id}/resend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.comments),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaxFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    resendFaxRequestOptionsRaw(requestParameters: ResendFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resendFax.');
        }

        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }


        const responseConfig = {
            path: `/fax/{id}/resend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: StringResourceToJSON(requestParameters.comments),
        };
        return responseConfig
    }

    /**
     * Resends a fax (Roles: admin, physician)
     */
    async resendFax(requestParameters: ResendFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fax> {
        const response = await this.resendFaxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sends a file by fax (Roles: physician, admin)
     */
    async sendFileFaxRaw(requestParameters: SendFileFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Fax>> {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling sendFileFax.');
        }

        if (requestParameters.fax === null || requestParameters.fax === undefined) {
            throw new runtime.RequiredError('fax','Required parameter requestParameters.fax was null or undefined when calling sendFileFax.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling sendFileFax.');
        }

        const queryParameters: any = {};

        if (requestParameters.ignoreCoverPage !== undefined) {
            queryParameters['ignoreCoverPage'] = requestParameters.ignoreCoverPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.fax !== undefined) {
            formParams.append('fax', requestParameters.fax as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        const response = await this.request({
            path: `/fax/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FaxFromJSON(jsonValue));
    }

    /**
        Request Config Getter
     */
    sendFileFaxRequestOptionsRaw(requestParameters: SendFileFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): runtime.RequestOpts {
        if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
            throw new runtime.RequiredError('patientId','Required parameter requestParameters.patientId was null or undefined when calling sendFileFax.');
        }

        if (requestParameters.fax === null || requestParameters.fax === undefined) {
            throw new runtime.RequiredError('fax','Required parameter requestParameters.fax was null or undefined when calling sendFileFax.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling sendFileFax.');
        }

        const queryParameters: any = {};

        if (requestParameters.ignoreCoverPage !== undefined) {
            queryParameters['ignoreCoverPage'] = requestParameters.ignoreCoverPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.fax !== undefined) {
            formParams.append('fax', requestParameters.fax as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }


        const responseConfig = {
            path: `/fax/patient/{patientId}`.replace(`{${"patientId"}}`, encodeURIComponent(String(requestParameters.patientId))),
            method: 'POST' as runtime.HTTPMethod,
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        };
        return responseConfig
    }

    /**
     * Sends a file by fax (Roles: physician, admin)
     */
    async sendFileFax(requestParameters: SendFileFaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Fax> {
        const response = await this.sendFileFaxRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetFaxesByStatusStatusEnum = {
    Submitted: 'Submitted',
    Sent: 'Sent',
    Queued: 'Queued',
    SendingFailed: 'SendingFailed'
} as const;
export type GetFaxesByStatusStatusEnum = typeof GetFaxesByStatusStatusEnum[keyof typeof GetFaxesByStatusStatusEnum];
