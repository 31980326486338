/* tslint:disable */
/* eslint-disable */
/**
 * Care& API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentEditActionRotationsInner } from './DocumentEditActionRotationsInner';
import {
    DocumentEditActionRotationsInnerFromJSON,
    DocumentEditActionRotationsInnerFromJSONTyped,
    DocumentEditActionRotationsInnerToJSON,
} from './DocumentEditActionRotationsInner';
import type { PatientDocument } from './PatientDocument';
import {
    PatientDocumentFromJSON,
    PatientDocumentFromJSONTyped,
    PatientDocumentToJSON,
} from './PatientDocument';

/**
 * 
 * @export
 * @interface DocumentEditAction
 */
export interface DocumentEditAction {
    /**
     * 
     * @type {Array<number>}
     * @memberof DocumentEditAction
     */
    pages: Array<number>;
    /**
     * 
     * @type {Array<DocumentEditActionRotationsInner>}
     * @memberof DocumentEditAction
     */
    rotations?: Array<DocumentEditActionRotationsInner>;
    /**
     * 
     * @type {PatientDocument}
     * @memberof DocumentEditAction
     */
    document?: PatientDocument;
    /**
     * 
     * @type {string}
     * @memberof DocumentEditAction
     */
    action: DocumentEditActionActionEnum;
}


/**
 * @export
 */
export const DocumentEditActionActionEnum = {
    Delete: 'delete',
    Add: 'add',
    Rotation: 'rotation'
} as const;
export type DocumentEditActionActionEnum = typeof DocumentEditActionActionEnum[keyof typeof DocumentEditActionActionEnum];


/**
 * Check if a given object implements the DocumentEditAction interface.
 */
export function instanceOfDocumentEditAction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "action" in value;

    return isInstance;
}

export function DocumentEditActionFromJSON(json: any): DocumentEditAction {
    return DocumentEditActionFromJSONTyped(json, false);
}

export function DocumentEditActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentEditAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pages': json['pages'],
        'rotations': !exists(json, 'rotations') ? undefined : ((json['rotations'] as Array<any>).map(DocumentEditActionRotationsInnerFromJSON)),
        'document': !exists(json, 'document') ? undefined : PatientDocumentFromJSON(json['document']),
        'action': json['action'],
    };
}

export function DocumentEditActionToJSON(value?: DocumentEditAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pages': value.pages,
        'rotations': value.rotations === undefined ? undefined : ((value.rotations as Array<any>).map(DocumentEditActionRotationsInnerToJSON)),
        'document': PatientDocumentToJSON(value.document),
        'action': value.action,
    };
}

